import React, { useState, useEffect } from 'react';
import Ratings from '../Ratings';
import styled from 'styled-components';

import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import DiamondIcon from '@mui/icons-material/Diamond';
import VerifiedIcon from '@mui/icons-material/Verified';
import Property from './property';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import { Link, useNavigate } from 'react-router-dom';

const PropertyCard2 = ({
  property,
  setSelectedPlace,
  addToWishlist,
  onHover,
  onLeave,
  removeFromWishlist,
}) => {
  const cleaningFee = property?.property_price_policy
    ? parseInt(property?.property_price_policy?.cleaningFee)
    : 0;
  const totalCost = property?.perNightPrice + cleaningFee;

  const [isInWishlist, setIsInWishlist] = useState(property?.wishList);

  return (
    <PropertyCard2Styled>
      <div
        className={`isting-item1 single_list_card selectedprop `}
        style={{
          width: '100% !important',
        }}
        onMouseEnter={onHover}
        // onMouseLeave={onLeave}
      >
        <article className='geodir-category-listing fl-wrap'>
          <div
            className='geodir-category-img fl-wrap'
            style={{ background: 'black' }}
          >
            <Link to={`/property-detail/${property?.id}`}>
              <button className='noborder'>
                <a
                  className='geodir-category-img_item'
                  style={{ cursor: 'pointer' }}
                >
                  <img
                    src={
                      property?.thumbnailUrl === undefined
                        ? require('../../assets/images/all/3.jpg')
                        : property?.thumbnailUrl
                    }
                    alt=''
                  />
                  <div className='overlay'></div>
                </a>
              </button>
            </Link>

            {/* <a
                onClick={(e) => {
                  e.preventDefault();
                  addToWishlist(property?.id);
                }}
                className='geodir_save-btn'
              >
                <FavoriteOutlinedIcon
                  style={{
                    fontSize: 25,
                    color: '#bcbcbc',
                    verticalAlign: 'middle',
                  }}
                />
              </a> */}
            {/* <a className="permiumBucket">
              <DiamondIcon
                style={{
                  fontSize: 25,
                  color: '#0094fe',
                  verticalAlign: 'middle',
                }}
              />
            </a> */}
            {property?.wishList !== undefined && (
              <>
                {isInWishlist ? (
                  <a
                    onClick={() => {
                      removeFromWishlist(property?.id);
                      setIsInWishlist(!isInWishlist);
                    }}
                    className='geodir_save-btn'
                    style={{ cursor: 'pointer' }}
                  >
                    <FavoriteOutlinedIcon
                      style={{
                        fontSize: 25,
                        color: '#ac1b1b',
                        verticalAlign: 'middle',
                      }}
                    />
                  </a>
                ) : (
                  <a
                    onClick={() => {
                      addToWishlist(property?.id);
                      setIsInWishlist(!isInWishlist);
                    }}
                    className='geodir_save-btn'
                    style={{ cursor: 'pointer' }}
                  >
                    <FavoriteBorderIcon
                      style={{
                        fontSize: 25,
                        color: '#ac1b1b',
                        verticalAlign: 'middle',
                      }}
                    />
                  </a>
                )}
              </>
            )}
          </div>
          <div className='geodir-category-content fl-wrap'>
            <div className=' fixed-location'>
              <a
                className='single-map-item'
                style={{ textDecoration: 'none', marginBottom: 10 }}
              >
                <i className='fas fa-map-marker-alt'></i>
                <span style={{ textAlign: 'left' }}>{property?.city},</span>
                <span style={{ textAlign: 'left' }}>{property?.country}</span>
              </a>
            </div>
            <h3 className='title-sin_item'>
              <Link to={`/property-detail/${property?.id}`}>
                <a style={{ textDecoration: 'none' }}>{property?.title}</a>
              </Link>

              {/* <div className="geodir-category-location"> */}

              {/* </div> */}
            </h3>
            {/* <div className="p-id" style={{ display: 'inline-block' }}> */}
            <p style={{ paddingTop: 0 }}>Property ID: {property?.code}</p>
            {/* </div> */}
            <div
              style={{
                textAlign: 'initial',
                paddingTop: 0,
                paddingBottom: 5,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Ratings rating={property?.property_rating?.averageRating} />
              <span style={{ marginLeft: 2, color: '#c2c1c5', fontSize: 12 }}>
                (
                {property?.property_rating
                  ? parseInt(property?.property_rating?.averageRating)?.toFixed(
                      1
                    )
                  : 0}
                ) (
                {property?.property_rating
                  ? property?.property_rating?.reviewCount
                  : 0}{' '}
                Reviews)
              </span>
            </div>

            <div className='geodir-category-content-details'>
              <ul>
                <li>
                  <i className='fal fa-bed'></i>
                  <span>{property?.property_capacity?.noOfBedrooms}</span>
                </li>
                <li>
                  <i className='fal fa-bath'></i>
                  <span>{property?.property_capacity?.noOfBathrooms}</span>
                </li>
                <li>
                  <i className='fal fa-users'></i>
                  <span>
                    {property?.property_capacity?.noOfAdults +
                      property?.property_capacity?.noOfChildren}{' '}
                  </span>
                </li>
              </ul>
            </div>
            <div className='geodir-category-footer'>
              <div className='geodir-category-content_price'>
                <small>{process.env.REACT_APP_CURRENCY} </small>{' '}
                {property?.perNightPrice?.toLocaleString('en-US')}
                <span
                  style={{ color: 'rgba(255, 255, 255, 0.7)', paddingTop: 3 }}
                  color='white'
                >
                  <small>Night</small>
                </span>
              </div>
              <div className='geodir-category-content_price'>
                <small>{process.env.REACT_APP_CURRENCY} </small>{' '}
                {totalCost ? parseInt(totalCost)?.toLocaleString('en-US') : 0}
                <span
                  style={{ color: 'rgba(255, 255, 255, 0.7)', paddingTop: 3 }}
                >
                  <small>Inc. cleaning fee</small>
                </span>
              </div>
            </div>
          </div>
        </article>
      </div>
    </PropertyCard2Styled>
  );
};

export default PropertyCard2;

const PropertyCard2Styled = styled.div`
  .listing-item1 {
    width: 100% !important;
  }
`;
