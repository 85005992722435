import { Divider, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Card from '../../../../components/dasboard/card';
import CheckBoxLabel from '../../../../components/form/checkbox';
import { EditorState, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML, convertFromHTML } from 'draft-convert';
import { createPropertyCustomHouseRuleApi } from '../../../../services/properties/createPropertyCustomHouseRule';
import Buttons from '../../../../components/form/button';

const HouseRules = ({ houseRulesValues, handleCheckbox, propertyPolicy }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [convertedContent, setConvertedContent] = useState('');
  const [customHouseRuleError, setCustomHouseRuleError] = React.useState('');

  const handleEditorChange = (state) => {
    if (customHouseRuleError) {
      setCustomHouseRuleError('');
    }
    setEditorState(state);
    convertContentToHTML();
  };
  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  };
  useEffect(() => {
    if (propertyPolicy?.customHouseRules) {
      setEditorState(
        EditorState.createWithContent(
          convertFromHTML(propertyPolicy?.customHouseRules)
        )
      );
    }
  }, [propertyPolicy]);
  const onSubmitCustomHouseRule = async () => {
    if (propertyPolicy?.customHouseRules === null) {
      if (editorState.getCurrentContent().getPlainText().length <= 0) {
        setCustomHouseRuleError('Please add some text!');
      } else {
        const data = {
          propertyId: propertyPolicy?.propertyId,
          customHouseRules: convertedContent,
        };
        const response = await createPropertyCustomHouseRuleApi(data);
      }
    }
  };
  return (
    <>
      <div className='dasboard-widget-title fl-wrap'>
        <h5>
          <i className></i>
          All House Rules
        </h5>
      </div>

      <div className='dasboard-widget-box fl-wrap'>
        <div>
          <Grid container>
            <Grid item xs={12}>
              {houseRulesValues?.map((houseRulesValue, i) => {
                return (
                  <>
                    <Typography
                      variant={'h6'}
                      style={{
                        paddingTop: 25,
                        paddingBottom: 5,
                        textAlign: 'initial',
                      }}
                    >
                      {houseRulesValue.title}
                    </Typography>
                    <Typography
                      style={{
                        textAlign: 'initial',
                        fontSize: 15
                      }}
                    >
                      {houseRulesValue.description}
                    </Typography>
                    <Grid
                      container
                      rowSpacing={{ xs: 1, sm: 1, md: 1 }}
                      columnSpacing={{ xs: 1, sm: 1, md: 1 }}
                      style={{ textAlign: 'left' }}
                    >
                      {houseRulesValue?.houseRules?.map((houseRule, j) => {
                        return (
                          <Grid item xs={4}>
                            <CheckBoxLabel
                              label={houseRule?.title}
                              value={houseRule?.title}
                              checked={houseRule?.check_status}
                              onChange={(e) =>
                                handleCheckbox(houseRule.id, i, j, e)
                              }
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                    <Divider style={{ paddingBottom: 30 }} />
                  </>
                );
              })}
              <Grid>
                <Typography
                  variant={'h6'}
                  style={{
                    paddingTop: 25,
                    paddingBottom: 5,
                    textAlign: 'initial',
                  }}
                >
                  Additional Rules
                </Typography>
                <p style={{ fontSize: '14px' }}>
                  You can include any requirements around safety concerns,
                  shared space rules and your community regulations.{' '}
                </p>
                <Editor
                  editorState={editorState && editorState}
                  id={'additionalRules'}
                  name={'additionalRules'}
                  onEditorStateChange={handleEditorChange && handleEditorChange}
                  wrapperClassName='wrapper--class'
                  editorClassName='editor--class'
                  toolbarClassName='toolbar--class'
                />
                {customHouseRuleError && (
                  <p style={{ color: 'red' }}>{customHouseRuleError}</p>
                )}
              </Grid>
              <Grid item xs={12} md={12}>
                <Buttons
                  title={'Save'}
                  isLoading={false}
                  onSubmit={onSubmitCustomHouseRule}
                  style={{
                    width: '20%',
                    margin: '10px 0px',
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default HouseRules;
