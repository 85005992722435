import React, { useState, useRef } from 'react';
import { Grid, TextField, Box, Typography, Button } from '@mui/material';
import axios from 'axios';
import { API_URL } from '../../config';
import { Toast } from 'primereact/toast';
const payment = [
  {
    cardType: 'Visa',
    expiry: '07/24',
    last4Digit: '8238',
    icon: '/payment/visa.png',
    lastUsed: 'Thu, Mar 23 2022',
  },
  {
    cardType: 'Discover',
    expiry: '02/23',
    last4Digit: '1234',
    icon: '/payment/gPay.png',
    lastUsed: 'Never Used',
  },
];
const CheckoutForm = ({ bookingId, users, stripeAmount }) => {
  const toast = useRef(null);
  const [name, setName] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [expMonth, setExpMonth] = useState('');
  const [expYear, setExpYear] = useState('');
  const [cvcNo, setCvcNo] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [country, setCountry] = useState('');
  const handleSubmit = async (event) => {
    event.preventDefault();
    const dataPayment = {
      stripeAmount: stripeAmount,
      name: name,
      number: cardNumber,
      exp_month: expMonth,
      exp_year: expYear,
      address_country: country,
      booking_id: '11',
      user_id: '8',
      address_zip: zipCode,
      cvc: cvcNo,
    };
    const url = API_URL + 'payment/booking/by-stripe';
    axios.post(url, dataPayment).then((res) => {
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Your payment has been made successfully!',
        life: 3000,
      });
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Toast ref={toast} position="bottom-right" />
        <Box sx={{ borderBottom: '1px solid #545861', my: 2 }}>
          <Typography>Payments Details</Typography>
        </Box>
        {/* 
                <Box>
                    {
                        payment.map((item) => (
                            <List sx={{ p: 1 }}>
                                <ListItem
                                    secondaryAction={
                                        <img edge="end" src={item.icon} alt="" />
                                    }
                                    sx={{ borderBottom: '1px solid #545861', my: 2 }}
                                >
                                    <ListItemAvatar>
                                        <Radio />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={`Ending In:....${item.last4Digit}`}
                                        secondary={`Last time used:  ${item.lastUsed}`}
                                    />
                                </ListItem>
                            </List>
                        ))
                    }

                </Box> */}

        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={12} sm={6} md={6}>
            <Typography sx={{ textAlign: 'left' }}>Card Number</Typography>
            <TextField
              sx={{ width: '100%' }}
              id="cardNumber"
              number
              required
              placeholder="4242424242424242"
              value={cardNumber}
              onChange={(e) => {
                setCardNumber(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography sx={{ textAlign: 'left' }}>Card Holder Name</Typography>
            <TextField
              sx={{ width: '100%' }}
              id="name"
              required
              placeholder="Card Holder Name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Typography sx={{ textAlign: 'left' }}>Expiration Month</Typography>
            <TextField
              sx={{ width: '100%' }}
              id="name"
              required
              number
              placeholder="03"
              value={expMonth}
              onChange={(e) => {
                setExpMonth(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Typography sx={{ textAlign: 'left' }}>Expiration Years</Typography>
            <TextField
              sx={{ width: '100%' }}
              id="expYear"
              required
              number
              placeholder="10"
              value={expYear}
              onChange={(e) => {
                setExpYear(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Typography sx={{ textAlign: 'left' }}>CVC</Typography>
            <TextField
              sx={{ width: '100%' }}
              id="cvcNo"
              required
              number
              placeholder="10"
              value={cvcNo}
              onChange={(e) => {
                setCvcNo(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography sx={{ textAlign: 'left' }}>Country Name</Typography>
            <TextField
              sx={{ width: '100%' }}
              id="country"
              number
              required
              placeholder="Country Name"
              value={country}
              onChange={(e) => {
                setCountry(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography sx={{ textAlign: 'left' }}>Zip Code</Typography>
            <TextField
              sx={{ width: '100%' }}
              id="zipCode"
              number
              required
              placeholder="Zip Code"
              value={zipCode}
              onChange={(e) => {
                setZipCode(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Button sx={{ my: 2 }} type="submit" variant="contained">
              Pay
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default CheckoutForm;
