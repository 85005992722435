import { Button, Divider, Modal, Paper, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { Link, useNavigate } from 'react-router-dom';
import { fbAuthMobileApi } from '../../../services/Authentication/facebookAuthMobileApi';
import Logo from '../../../assets/images/logo.png';
import { socialLoginApi } from '../../../services/Authentication/SocialLogin';
import changeUserPassApi from '../../../services/User/changePassword';
import createPasswordApi from '../../../services/User/createPassword';
import InputPass from '../../../components/form/inputpass';
import * as yup from 'yup';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  p: 4,
  textAlign: 'start',
};
const FacebookNew = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useNavigate();
  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    accessToken: '',
    imageUrl: '',
  });
  const responseFacebook = async (response) => {
    const fbAuthRes = await socialLoginApi({
      email: response?.email,
      firstName: response?.name.split(' ')[0],
      lastName: response?.name.split(' ')[1],
      accessToken: response?.accessToken,
      platform: 'facebook',
    });
    if (fbAuthRes?.data?.payload?.existingUser) {
      localStorage.setItem('userToken', fbAuthRes?.data?.payload?.token?.token);
      localStorage.setItem('user', true);

      localStorage.setItem(
        'userData',
        JSON.stringify({
          email: fbAuthRes?.data?.payload?.token?.user?.email,
          firstName: fbAuthRes?.data?.payload?.token?.user?.firstName,
          lastName: fbAuthRes?.data?.payload?.token?.user?.lastName,
          id: fbAuthRes?.data?.payload?.token?.user?.id,
          provider: 'facebook',
          username: fbAuthRes?.data?.payload?.token?.user?.username,
          isHost: fbAuthRes?.data?.payload?.token?.user?.isHost,
          verified: fbAuthRes?.data?.payload?.token?.user?.verified,
          isFacebookVerified:
            fbAuthRes?.data?.payload?.token?.user?.isFacebookVerified,
          isGoogleVerified:
            fbAuthRes?.data?.payload?.token?.user?.isGoogleVerified,
          isDiamondHost: fbAuthRes?.data?.payload?.token?.user?.isDiamondHost,
        })
      );
      enqueueSnackbar('Successfully logged in.', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
      history('/');
    } else {
      handleOpen();
      setData({
        email: response?.email,
        firstName: response?.name.split(' ')[0],
        lastName: response?.name.split(' ')[1],
        accessToken: response?.accessToken,
        profilePicture: response.picture.data.url,
      });
    }
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const formik = useFormik({
    initialValues: {
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      accessToken: data?.accessToken,
      password: '',
      imageUrl: data?.imageUrl,
    },
    enableReinitialize: true,
    validationSchema: () => {
      return yup.object({
        firstName: yup.string().required('Please add your First Name.'),
        lastName: yup.string().required('Please add your Last Name.'),
        password: yup.string().required('Please create a password.'),
      });
    },
    onSubmit: async (values) => {
      const fbAuthRes = await socialLoginApi({
        email: values?.email,
        firstName: values?.firstName,
        lastName: values?.lastName,
        accessToken: values?.accessToken,
        platform: 'facebook',
      });

      localStorage.setItem('userToken', fbAuthRes?.data?.payload?.token?.token);
      localStorage.setItem('user', true);

      localStorage.setItem(
        'userData',
        JSON.stringify({
          email: fbAuthRes?.data?.payload?.token?.user?.email,
          firstName: fbAuthRes?.data?.payload?.token?.user?.firstName,
          lastName: fbAuthRes?.data?.payload?.token?.user?.lastName,
          id: fbAuthRes?.data?.payload?.token?.user?.id,
          provider: 'facebook',
          username: fbAuthRes?.data?.payload?.token?.user?.username,
          isHost: fbAuthRes?.data?.payload?.token?.user?.isHost,
          verified: fbAuthRes?.data?.payload?.token?.user?.verified,
          isFacebookVerified:
            fbAuthRes?.data?.payload?.token?.user?.isFacebookVerified,
          isGoogleVerified:
            fbAuthRes?.data?.payload?.token?.user?.isGoogleVerified,
          isDiamondHost: fbAuthRes?.data?.payload?.token?.user?.isDiamondHost,
        })
      );
      const uid = fbAuthRes?.data?.payload?.token?.user?.id;
      await createPasswordApi(
        {
          password: values.password,
          confirmPassword: values.password,
        },
        uid
      );
      enqueueSnackbar('Successfully logged in.', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
      history('/');
    },
  });
  return (
    <div>
      <FacebookLogin
        appId='381535427375487'
        autoLoad={false}
        fields='name,email,picture'
        callback={responseFacebook}
        render={(renderProps) => (
          <>
            <div className='social-media-login'>
              <button onClick={renderProps.onClick}>
                <i
                  style={{ fontSize: 22 }}
                  className='fa fa-brand fa-facebook'
                ></i>
              </button>
            </div>
          </>
        )}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              '& > :not(style)': {
                m: 1,
                width: 600,
                height: 600,
              },
            }}
          >
            <Paper
              elevation={3}
              style={{
                maxHeight: 900,
                overflow: 'auto',
                padding: 20,
                background: '#1B182B',
              }}
            >
              <img
                src={Logo}
                alt='Logo'
                srcset=''
                width={100}
                height={50}
                style={{
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  marginBottom: '5px',
                }}
              />
              <h5 style={{ textAlign: 'center' }}>Finish signing up</h5>
              <Divider sx={{ marginBottom: 3 }} />
              <Box
                sx={{
                  width: 600,
                  maxWidth: '100%',
                }}
              >
                <TextField
                  fullWidth
                  placeholder='Name'
                  id='fullWidth'
                  name='firstName'
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                />
              </Box>
              <Box
                sx={{
                  width: 600,
                  maxWidth: '100%',
                }}
              >
                <TextField
                  fullWidth
                  placeholder='lastname'
                  name='lastName'
                  id='fullWidth'
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                />
                <span>
                  Make sure it matches the name on your government ID.
                </span>
              </Box>
              <Box
                sx={{
                  width: 600,
                  maxWidth: '100%',
                  marginTop: 3,
                }}
              >
                <TextField
                  fullWidth
                  placeholder='Email'
                  id='fullWidth'
                  name='email'
                  value={formik.values.email}
                  disabled
                />
                <span>We'll email you trip confirmations and receipts.</span>
              </Box>
              <p style={{ textAlign: 'center', marginTop: 10 }}>
                This info came from Facebook.
              </p>
              <Box
                sx={{
                  width: 600,
                  maxWidth: '100%',
                  marginTop: 3,
                }}
              >
                <InputPass
                  sx={{ mb: 3, fontSize: '14px', position: 'relative' }}
                  id={'password'}
                  name={'password'}
                  label={'Password'}
                  onChange={formik.handleChange}
                />
                <span>Add Your Password</span>
              </Box>
              <p style={{ marginTop: 20, color: '#fff' }}>
                By selecting Agree and continue, I agree to Staywo{' '}
                <Link to={'/terms-and-conditions'}>Terms of Service</Link>,{' '}
                <Link to={'/terms-and-conditions'}>
                  Payments Terms of Service,
                </Link>{' '}
                and Nondiscrimination Policy and acknowledge the Privacy Policy.
              </p>
              <Box style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  variant='contained'
                  onClick={formik.handleSubmit}
                  fullWidth
                >
                  Agree And Countinue
                </Button>
              </Box>
            </Paper>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default FacebookNew;
