import { Divider, Grid, Typography } from "@mui/material";
import React from "react";
import Card from "../../../../components/dasboard/card";
import CheckBoxLabel from "../../../../components/form/checkbox";

const SafetyGuest = ({ safetyConsiderationValues, handleCheckbox }) => {
  return (
    <>
      <div className="dasboard-widget-title fl-wrap">
        <h5>
          <i className></i>
          All Safety Guest
        </h5>
      </div>

      <div className="dasboard-widget-box fl-wrap">
        <div>
          <Grid container>
            <Grid item xs={12}>
              {safetyConsiderationValues?.map((safetyConsiderationValue, i) => {
                return (
                  <>
                    <Typography
                      variant={"h6"}
                      style={{
                        paddingTop: 25,
                        paddingBottom: 5,
                        textAlign: "initial",
                      }}
                    >
                     {safetyConsiderationValue?.title}
                    </Typography>
                    <Typography
                      style={{
                        textAlign: "initial",
                        fontSize:13
                      }}
                    >
                     {safetyConsiderationValue?.description}
                    </Typography>
                     
                    <Grid
                      container
                      rowSpacing={{ xs: 1, sm: 1, md: 1 }}
                      columnSpacing={{ xs: 1, sm: 1, md: 1 }}
                      style={{ textAlign: "left" }}
                    >

                      {safetyConsiderationValue?.safetyConsideration?.map((safetyConsideration, j) => {
                    return (
                      <Grid item xs={4}>
                        <CheckBoxLabel
                          label={safetyConsideration?.title}
                          value={safetyConsideration?.title}
                          checked={safetyConsideration?.check_status}
                          onChange={(e) => handleCheckbox(safetyConsideration.id,i,j, e)}
                        />
                      </Grid>
                    );
                  })}
                    </Grid>
                    <Divider style={{ paddingBottom: 30 }} />
                  </>
                );
              })}
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default SafetyGuest;
