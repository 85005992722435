import React, { useState, useEffect, useRef } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Typographyy from "../../../components/typography";
import { Grid } from "@mui/material";
import { IoIosArrowBack } from "react-icons/io";
import { Typography } from "@mui/material";
import { BiUserPlus } from "react-icons/bi";
import { Skeleton } from 'primereact/skeleton';


import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { Link } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";

import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";

import { BiLock } from "react-icons/bi";

export default function ChooseId() {
  const [row, setRow] = React.useState(false);

  const [value, setValue] = React.useState();
  const [selectedValue, setSelectedValue] = React.useState("mal");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const [inputValue, setInputValue] = React.useState("");

  const [lazyItems, setLazyItems] = useState([]);
  const [lazyLoading, setLazyLoading] = useState(false);
  const [selectedCity1, setSelectedCity1] = useState(null);
  const [selectedCity2, setSelectedCity2] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedGroupedCity, setSelectedGroupedCity] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItem2, setSelectedItem2] = useState(null);

  let loadLazyTimeout = useRef(null);

  const cities = [
    { name: "New York", code: "NY" },
    { name: "Rome", code: "RM" },
    { name: "London", code: "LDN" },
    { name: "Istanbul", code: "IST" },
    { name: "Paris", code: "PRS" },
  ];

  const countries = [
    { name: "Australia", code: "AU" },
    { name: "Brazil", code: "BR" },
    { name: "China", code: "CN" },
    { name: "Egypt", code: "EG" },
    { name: "France", code: "FR" },
    { name: "Germany", code: "DE" },
    { name: "India", code: "IN" },
    { name: "Japan", code: "JP" },
    { name: "Spain", code: "ES" },
    { name: "United States", code: "US" },
  ];

  const groupedCities = [
    {
      label: "Germany",
      code: "DE",
      items: [
        { label: "Berlin", value: "Berlin" },
        { label: "Frankfurt", value: "Frankfurt" },
        { label: "Hamburg", value: "Hamburg" },
        { label: "Munich", value: "Munich" },
      ],
    },
    {
      label: "USA",
      code: "US",
      items: [
        { label: "Chicago", value: "Chicago" },
        { label: "Los Angeles", value: "Los Angeles" },
        { label: "New York", value: "New York" },
        { label: "San Francisco", value: "San Francisco" },
      ],
    },
    {
      label: "Japan",
      code: "JP",
      items: [
        { label: "Kyoto", value: "Kyoto" },
        { label: "Osaka", value: "Osaka" },
        { label: "Tokyo", value: "Tokyo" },
        { label: "Yokohama", value: "Yokohama" },
      ],
    },
  ];

  const items = Array.from({ length: 100000 }).map((_, i) => ({
    label: `Item #${i}`,
    value: i,
  }));

  useEffect(() => {
    setLazyItems(Array.from({ length: 100000 }));
    setLazyLoading(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onCityChange = (e) => {
    setSelectedCity1(e.value);
  };

  const onCityChange2 = (e) => {
    setSelectedCity2(e.value);
  };

  const onCountryChange = (e) => {
    setSelectedCountry(e.value);
  };

  const onGroupedCityChange = (e) => {
    setSelectedGroupedCity(e.value);
  };

  const onItemChange = (e) => {
    setSelectedItem(e.value);
  };

  const onLazyItemChange = (e) => {
    setSelectedItem2(e.value);
  };

  const onLazyLoad = (event) => {
    setLazyLoading(true);

    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }

    //imitate delay of a backend call
    loadLazyTimeout = setTimeout(() => {
      const { first, last } = event;
      const _lazyItems = [...lazyItems];

      for (let i = first; i < last; i++) {
        _lazyItems[i] = { label: `Item #${i}`, value: i };
      }

      setLazyItems(_lazyItems);
      setLazyLoading(false);
    }, Math.random() * 1000 + 250);
  };
  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="country-item country-item-value">
          <img
            alt={option.name}
            src="images/flag/flag_placeholder.png"
            onError={(e) =>
              (e.target.src =
                "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
            }
            className={`flag flag-${option.code.toLowerCase()}`}
          />
          <div>{option.name}</div>
        </div>
      );
    }
    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="country-item">
        <img
          alt={option.name}
          src="images/flag/flag_placeholder.png"
          onError={(e) =>
            (e.target.src =
              "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
          }
          className={`flag flag-${option.code.toLowerCase()}`}
        />
        <div>{option.name}</div>
      </div>
    );
  };

  const groupedItemTemplate = (option) => {
    return (
      <div className="flex align-items-center country-item">
        <img
          alt={option.label}
          src="images/flag/flag_placeholder.png"
          onError={(e) =>
            (e.target.src =
              "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
          }
          className={`flag flag-${option.code.toLowerCase()}`}
        />
        <div>{option.label}</div>
      </div>
    );
  };

  return (
    <Grid container>
      <Grid item sm={12} md={6} className="chooseIdmain">
        <Typography
          className="text-left"
          style={{ color: "white", margintop: "20px", marginBottom: "20px" }}
          variant="h4"
        >
          choose id type to add
        </Typography>
        <div className="dropdown-demo">
          <div className="card">
            <Dropdown
              value={selectedCountry}
              options={countries}
              onChange={onCountryChange}
              optionLabel="name"
              filter
              showClear
              filterBy="name"
              placeholder="Select a Country"
              valueTemplate={selectedCountryTemplate}
              itemTemplate={countryOptionTemplate}
              style={{
                background: 'transparent',
                padding: '6px 5px',
              }}
            />
          </div>
        </div>

        {/* <Autocomplete
          value={value}
          onChange={() => setValue()}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          id=""
          options={options}
          style={{ width: "100%" }}
          renderInput={(params) => (
            <TextField {...params} label="Controllable" />
          )}
        /> */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "105%",
            paddingTop: "25px",
          }}
        >
          <p style={{ fontWight: "bold", color: "#ffffff" }}>Passport</p>

          <FormControlLabel
            name="user"
            value="female"
            control={
              <Radio
                checked={selectedValue === "mal"}
                onChange={handleChange}
                value="mal"
                name="radio-buttons"
                inputProps={{ "aria-label": "A" }}
              />
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "105%",
            paddingTop: "10px",
          }}
        >
          <p style={{ fontWight: "bold", color: "#ffffff" }}>Identity Card</p>

          <FormControlLabel
            control={
              <Radio
                checked={selectedValue === "fem"}
                onChange={handleChange}
                value="fem"
                name="radio-buttons"
                inputProps={{ "aria-label": "A" }}
              />
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "105%",
            paddingTop: "10px",
          }}
        >
          <p style={{ fontWight: "bold", color: "#ffffff" }}>
            My Id type is'nt listed
          </p>

          <FormControlLabel
            name="identity"
            value="oth"
            control={
              <Radio
                checked={selectedValue === "oth"}
                onChange={handleChange}
                value="oth"
                name="radio-buttons"
                inputProps={{ "aria-label": "A" }}
              />
            }
          />
        </div>
        <br />
        <br />
        <Typography style={{ fontSize: "16px", color: "#ffffff" }}>
          <BiLock style={{ fontSize: "16px" }} /> We Aim to keep the data you
          share private,secure
        </Typography>
        <br />
        <br />

        <br />

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <IoIosArrowBack style={{ fontSize: "22px", color: "white" }} />
            <button
              style={{
                fontSize: "20px",
                background: "transparent",
                border: "none",
                color: "#ffffff",
              }}
            >
              Back{" "}
            </button>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              style={{ padding: "10px", fontSize: "15px" }}
              variant="contained"
            >
              <BiUserPlus
                style={{ fontSize: "22px", color: "white", marginRight: "3px" }}
              />
              Continue
            </Button>
          </div>
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        sx={{
          paddingLeft: "60px",
          paddingRight: "60px",

          textAlign: "center",
        }}
        className="manpad"
      >
        <div
          style={{
            border: "1px solid white",
            borderRadius: "10px",
            padding: "10px",
          }}
        >
          <div sx={{ padding: 3, textAlign: "center" }}>
            <Link to="">
              <Typographyy
                variant="h5"
                sx={{
                  color: "white",
                  marginTop: 2,
                  textDecoration: "underline",
                }}
                bold="bold"
                text="Your Privacy"
              />
            </Link>

            <Typography
              style={{
                color: "white",
                padding: "2px",
                fontSize: "18px",
                textAlign: "center",
              }}
            >
              We aim to keep the data you share during this process private,
              safe, and secure. Learn more in our
            </Typography>

            <Link to="">
              <Typographyy
                variant="h5"
                sx={{
                  color: "white",
                  padding: "2px",

                  textDecoration: "underline",
                }}
                bold="bold"
                text="Privacy Policy"
              />
            </Link>
            <Link to="">
              <Typographyy
                variant="h5"
                sx={{
                  color: "white",
                  padding: "2px",

                  textDecoration: "underline",
                }}
                bold="bold"
                text="How this works"
              />
            </Link>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
