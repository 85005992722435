/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import Card from '../../../components/dasboard/card';
import InfoBar from '../../../components/dasboard/infoBar';
import Sidebar from '../../../components/dasboard/sidebar';
import Header from '../../../components/header';
import { getPropertyByIdApi } from '../../../services/properties/getProperty';
import { getPropertyApi } from '../../../services/properties/getProperty';
import SkeletonLoader from '../../../components/skeletonLoader';
import { useParams } from 'react-router-dom';
import Tabs from '../PropertyInsight/Tabs';

const PropertyInsight = () => {
  const { id } = useParams();
  const [properties, setProperties] = useState([]);
  const [propertyDetail, setPropertyDetail] = useState({});
  const [filteredProperty, setFilteredProperty] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [basicFirst, setBasicFirst] = useState(0);
  const [basicRows, setBasicRows] = useState(10);
  const [filterToggle, setFilterToggle] = useState(false);
  const [tab, setTab] = useState();
  const [review, setReview] = useState(false);
  const [opportunities, setOpportunities] = useState(false);
  // const [value, setValue] = (React.useState < Date) | (null > new Date());
  const [value, setValue] = React.useState(0);

  const handleChange = () => {
    setValue(newValue);
  };
  const onBasicPageChange = (event) => {
    setBasicFirst(event.first);
    setBasicRows(event.rows);
  };

  function handleSearch(value) {
    if (value === '') {
      setFilteredProperty(properties);
      setFilterToggle(false);
    } else {
      const filtered = properties.filter((property) => {
        return property.title.toLowerCase().includes(value.toLowerCase());
      });
      setFilterToggle(true);
      setFilteredProperty(filtered);
    }
  }

  const getPropertyListings = async () => {
    try {
      const response = await getPropertyApi();
      if (response.status === 200) {
        setProperties(response.data.data);
        setFilteredProperty(response.data.data);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  // get getPropertyDetail
  const getPropertyDetail = async () => {
    try {
      const response = await getPropertyByIdApi(id);
      if (response.status === 200) {
        setPropertyDetail(response.data.data);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPropertyListings();
    getPropertyDetail();
  }, []);

  const items = [
    { label: 'Opportunities' },
    { label: 'Reviews' },
    { label: 'Views' },
    { label: 'Earning' },
    { label: 'Diamon Host' },
    { label: 'Cleaning' },
  ];

  return (
    <>
      <Header />
      <div id='wrapper'>
        <div className='dashboard-menu-overlay' />
        <Sidebar page={'My Listing'} />
        <div className='dashboard-content'>
          <div className='dashboard-menu-btn color-bg'>
            <span>
              <i className='fas fa-bars' />
            </span>
            Dasboard Menu
          </div>
          {/* {isOpen && <Dropdown/>} */}
          <InfoBar label={'INSIGHT'} />
          <div className='dasboard-wrapper fl-wrap no-pag'>
            <Card
              withIcon
              title={'Insight -'}
              subtitle={propertyDetail.title}
              code={propertyDetail.code}
            >
              <Tabs propertyDetail={propertyDetail} />
            </Card>

            {/* <container>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Basic example"
                    value={value}
                    onChange={(newValue) => {
                      setValue(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </container> */}

            {/* <container>
                <container>
                  <Card>
                    <item className="reviewh">All Listing</item>
                    <Grid container>
                      <button className="btnReview">All</button>
                      <i className="fal fa-star" id="icon"></i>
                      <i className="fal fa-star" id="icon"></i>
                      <i className="fal fa-star" id="icon"></i>
                      <i className="fal fa-star" id="icon"></i>
                      <i className="fal fa-star" id="icon"></i>
                    </Grid>
                  </Card>
                </container>
                <container>
                  <Card>
                    <Grid container spacing={2}>
                      <Grid item xs={2} md={6} className="reviewboxitem1">
                        <item className="reviewText">Text</item>
                        <item className="reviewText2">
                          Entire house in sacramento
                        </item>
                        <item className="reviewText">
                          Overallrating
                          <i className="fal fa-star" id="icon"></i>
                          <i className="fal fa-star" id="icon"></i>
                          <i className="fal fa-star" id="icon"></i>
                          <i className="fal fa-star" id="icon"></i>
                          <i className="fal fa-star" id="icon"></i>
                        </item>
                      </Grid>
                      <Grid item xs={2} md={6} className="reviewboxitem">
                        <item className="itemreview">
                          <img src={Resource1} className="reviewbox" alt="" />
                          <Link href="#">Reading </Link>
                        </item>
                        <item></item>
                      </Grid>
                    </Grid>
                  </Card>
                </container>
                <container>
                  <Card>
                    <Grid container spacing={2}>
                      <Grid item xs={2} md={6} className="reviewboxitem1">
                        <item className="reviewText">Text</item>
                        <item className="reviewText2">
                          Entire house in sacramento
                        </item>
                        <item className="reviewText">
                          Overallrating
                          <i className="fal fa-star" id="icon"></i>
                          <i className="fal fa-star" id="icon"></i>
                          <i className="fal fa-star" id="icon"></i>
                          <i className="fal fa-star" id="icon"></i>
                          <i className="fal fa-star" id="icon"></i>
                        </item>
                      </Grid>
                      <Grid item xs={2} md={6} className="reviewboxitem">
                        <item className="itemreview">
                          <img src={Resource1} className="reviewbox" alt="" />
                          <Link href="#">Reading </Link>
                        </item>
                        <item></item>
                      </Grid>
                    </Grid>
                  </Card>
                </container>{" "}
                <container>
                  <Card>
                    <Grid container spacing={2}>
                      <Grid item xs={2} md={6} className="reviewboxitem1">
                        <item className="reviewText">Text</item>
                        <item className="reviewText2">
                          Entire house in sacramento
                        </item>
                        <item className="reviewText">
                          Overallrating
                          <i className="fal fa-star" id="icon"></i>
                          <i className="fal fa-star" id="icon"></i>
                          <i className="fal fa-star" id="icon"></i>
                          <i className="fal fa-star" id="icon"></i>
                          <i className="fal fa-star" id="icon"></i>
                        </item>
                      </Grid>
                      <Grid item xs={2} md={6} className="reviewboxitem">
                        <item className="itemreview">
                          <img src={Resource1} className="reviewbox" alt="" />
                          <Link href="#">Reading </Link>
                        </item>
                        <item></item>
                      </Grid>
                    </Grid>
                  </Card>
                </container>
              </container> */}

            {/* <Grid container value="active">
                <Grid container>
                  <Grid item xs={8}>
                    <item6 className="item">
                      <img src={Image} className="imgtop" alt="" /> Beautifull
                      Home
                    </item6>
                  </Grid>
                </Grid>
                <Grid container className="container1">
                  <Grid item xs={8}>
                    <item className="item1">
                      Travel is changing. We’re here to help.
                    </item>
                  </Grid>
                  <Grid item xs={5} className="item2">
                    <item></item>
                  </Grid>
                  <Grid item xs={4} className="itemtxt">
                    <item className="itemtext1">
                      Right now travel to your area is limited. Learn ways to
                      navigate recent changes by exploring the resources below.
                    </item>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={8}>
                    <item className="itemtext3">Resources for hosting now</item>
                  </Grid>
                </Grid>

                <Grid container className="container2" spacing={4}>
                  <Grid item xs={4} md={4}>
                    <item>
                      <img src={Resource1} alt="" className="resourceimg" />
                    </item>
                    <item className="resourcetext">
                      Why it’s smart to offer flexible cancellations right now
                    </item>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <item>
                      <img src={Resource2} alt="" className="resourceimg" />
                    </item>
                    <item className="resourcetext">
                      Getting started with Airbnb’s cleaning protocol
                    </item>
                  </Grid>{" "}
                  <Grid item xs={4} md={4}>
                    <item>
                      <img src={Resource3} alt="" className="resourceimg" />
                    </item>
                    <item className="resourcetext">
                      The do's and don’ts of providing self check-in
                    </item>
                  </Grid>{" "}
                  <Grid item xs={4} md={4}>
                    <item>
                      <img src={Resource4} alt="" className="resourceimg" />
                    </item>
                    <item className="resourcetext">
                      What you need to know about hosting families and pets Why
                    </item>
                  </Grid>{" "}
                  <Grid item xs={4} md={4}>
                    <item>
                      <img src={Resource5} alt="" className="resourceimg" />
                    </item>
                    <item className="resourcetext">
                      How to make your space comfortable for remote workers
                    </item>
                  </Grid>{" "}
                  <Grid item xs={4} md={4}>
                    <item>
                      <img src={Resource6} alt="" className="resourceimg" />
                    </item>
                    <item className="resourcetext">
                      The best amenities to offer right now
                    </item>
                  </Grid>
                </Grid>
              </Grid> */}

            {/* {isLoading === true ? (
                <Loader />
              ) : (
                <>
                  {properties.length === 0 ? (
                    <Grid container>
                      <Grid item xs={12}>
                        <ErrorMessage />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      container
                      rowSpacing={{ xs: 1, sm: 1, md: 1 }}
                      columnSpacing={{ xs: 1, sm: 1, md: 2 }}
                    >
                      {filterToggle
                        ? filteredProperty.map((property) => {
                            return (
                              <Grid item xs={12} sm={6} md={12}>
                                <Property property={property} />
                              </Grid>
                            );
                          })
                        : filteredProperty
                            ?.slice(basicFirst, basicRows + basicFirst)
                            .map((property) => {
                              return (
                                <Grid item xs={12} sm={6} md={12}>
                                  <Property property={property} />
                                </Grid>
                              );
                            })}
                    </Grid>
                  )}
                </> */}
            {/* )} */}

            {/* <Paginator
                first={basicFirst}
                rows={basicRows}
                totalRecords={properties?.length}
                rowsPerPageOptions={[5, 10, 20, 30]}
                onPageChange={onBasicPageChange}
                style={{ backgroundColor: "#1b182b" }}
              ></Paginator> */}
          </div>
        </div>
        <div className='dashbard-bg gray-bg' />
      </div>
    </>
  );
};

const Loader = () => {
  return (
    <Grid
      container
      rowSpacing={{ xs: 1, sm: 1, md: 2 }}
      columnSpacing={{ xs: 1, sm: 1, md: 2 }}
    >
      <Grid item xs={12} sm={6} md={6}>
        <SkeletonLoader height={200} />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <SkeletonLoader height={200} />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <SkeletonLoader height={200} />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <SkeletonLoader height={200} />
      </Grid>
    </Grid>
  );
};

export default PropertyInsight;
