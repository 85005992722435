import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { Divider, Grid } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import ChildModal from "./ChildModal";
import { Link } from "@mui/material";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import SwitchToogle from "../switchToggle/SwitchToogle";
import { updatePropertyStatus } from "../../services/properties/updateStatus";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  color: "text.primary",
  backgroundColor: "#1b182b",
  textAlign: "start",
  boxShadow: 24,
  p: 3,
  borderRadius: 5,
};

export default function ListedStatus({ responce, propertyId, status }) {
  const [value, setValue] = React.useState(status);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    if (status) {
      setValue(status);
    }
  }, []);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleData = async () => {
    if (!value) {
      return;
    }
    const data = await updatePropertyStatus(userData.id, propertyId, value);
    handleClose();
  };
  const ListingDisclaimer = {
    LearnMoreTitle: "What do the different statuses mean?",
    title: "Unlist, or List your listing",
    detail: `If you want to stop receiving new reservations and remove your listing from search results, you have options. Choose a way to manage your listing that works for you `,
    unListed:
      "Guests can’t book your listing or find it in search results.Unlist it until you’re ready to receive reservations again",
    listed:
      "Guests can find your listing in search results and request or book available dates.",
  };
  return (
    <div>
      <div>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={10}>
            <Typography style={{ marginLeft: 10, marginTop: 10 }}>
              {value ? value : "Listed"}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <ModeEditOutlineIcon
              style={{ cursor: "pointer" }}
              onClick={handleOpen}
            />
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <Button onClick={handleClose} sx={{ display: "block" }}>
                {" "}
                <CloseIcon />{" "}
              </Button>
              <Typography
                id="transition-modal-title"
                variant="h5"
                component="h2"
              >
                Listing status
              </Typography>

              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{ m: 2 }}
              >
                <Grid item xs={12}>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={value}
                      defaultValue={status}
                      name="radio-buttons-group"
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="LISTED"
                        control={<Radio color="success" />}
                        label={"Listed"}
                      />

                      <Typography
                        style={{ fontSize: 13, marginLeft: 30, marginTop: -10 }}
                      >
                        Guests can find your listing in search results and
                        request or book available dates.
                      </Typography>
                      <FormControlLabel
                        value="UNLISTED"
                        control={<Radio color="error" />}
                        label={<ChildModal data={value} />}
                      />
                      <Typography
                        style={{ fontSize: 13, marginLeft: 30, marginTop: -10 }}
                      >
                        {
                          "Guests can’t book your listing or find it in search results"
                        }
                      </Typography>
                    </RadioGroup>
                    <Divider />
                    <SwitchToogle data={ListingDisclaimer} />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        variant="contained"
                        id="srt-general-modal-logout-button"
                        style={{
                          textAlign: "end",
                          marginTop: 40,
                          cursor: "pointer",
                          marginRight: 30,
                          backgroundColor: "red",
                        }}
                        onClick={() => setOpen(false)}
                      >
                        Close
                      </Button>
                      <Button
                        variant="contained"
                        id="srt-general-modal-logout-button"
                        style={{
                          textAlign: "end",
                          marginTop: 40,
                          cursor: "pointer",
                        }}
                        onClick={handleData}
                      >
                        Save
                      </Button>
                    </div>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </Fade>
        </Modal>
      </div>
    </div>
  );
}
