import React, { useRef, useState } from 'react';
import styled from 'styled-components';

// prime react
import { Calendar } from 'primereact/calendar';
import { Button } from '@mui/material';

//Simple date picker component
const DatePickerExplore = ({
  handleDateStart,
  handleDateEnd,
  handlers,
  basePrice,
  startDate,
  endDate,
}) => {
  const [value, setValue] = useState(
    startDate && endDate ? [new Date(startDate), new Date(endDate)] : []
  );
  const cal = useRef(null);
  const dateTemplate = (date) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '10rem',
        }}
      >
        <div style={{ fontSize: '18px' }}>{date.day}</div>
        {basePrice && (
          <>
            <br />
            <span
              style={{
                fontSize: '0.75em',
              }}
            >
              {process.env.REACT_APP_CURRENCY}{basePrice}
            </span>
          </>
        )}
      </div>
    );
  };
  const handleClose = () => {
    cal.current.hide();
  };
  return (
    <DatePickerContainer>
      <Calendar
        // showIcon
        ref={cal}
        id='range'
        minDate={new Date()}
        value={value}
        style={{ fontSize: '25px' }}
        dateFormat='dd M yy'
        onChange={(e) => {
          setValue(e.value);
          handlers && handleDateStart(e.value[0]);
          handlers && handleDateEnd(e.value[1]);
        }}
        selectionMode='range'
        readOnlyInput
        placeholder='Select a Date Range'
        dateTemplate={dateTemplate}
        numberOfMonths={2}
        showButtonBar
        onClearButtonClick={() => {
          setValue([]);
        }}
        footerTemplate={() => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              padding: '12px',
              width: '100%',
            }}
          >
            <Button
              disabled={value?.length > 0 ? false : true}
              variant='contained'
              onClick={handleClose}
            >
              Apply
            </Button>
          </div>
        )}
      />
    </DatePickerContainer>
  );
};

const DatePickerContainer = styled.div`
  height: 100%;

  .p-calendar input {
    text-align: center;
  }

  .p-calendar .p-inputtext {
    background: none;
    color: #fff;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    text-align: 'center';
    font-size: 14px;
    line-height: 1.4375em;
    border-color: #4d545f;
    min-height: 50px;

    &:hover {
      border-color: #4d545f;
    }
    &:focus {
      box-shadow: none;
      /* border-color: #326fd1; */
      border: 2px solid;
    }
  }

  .p-calendar {
    width: 100%;
    height: 100%;
    font-size: 25px;
  }
  .p-highlight {
    color: #0095f8 !important;
  }
  #datepicker {
    font-size: 25px;
  }
`;

export default DatePickerExplore;
