import React from "react";
import styled from "styled-components";

export const NavSections = ({ propertyDetail }) => {
  return (
    <div className="scroll-nav-wrap">
      <NavSectionStyled className="scroll-nav scroll-init fixed-column_menu-init">
        <ul className="no-list-style">
          <li>
            <a className="act-scrlink" href="#sec1">
              <i className="fal fa-home-lg-alt"></i>
            </a>
            <span>Main</span>
          </li>

          <li>
            <a href="#sec2">
              <i className="fal fa-image"></i>
            </a>
            <span>Gallery</span>
          </li>

          <li>
            <a href="#sec3">
              <i className="fal fa-info"></i>{" "}
            </a>
            <span>Details</span>
          </li>
          {/* {propertyDetail?.youtubeVideoLink !== "" ? (
            <li>
              <a href="#sec5">
                <i className="fal fa-video"></i>
              </a>
              <span>Video</span>
            </li>
          ) : null} */}
          <li>
            <a href="#sec6">
              <i className="fal fa-map-pin"></i>
            </a>
            <span>Location</span>
          </li>

          <li>
            <a href="#sec7">
              <i className="fal fa-comment-alt-lines"></i>
            </a>
            <span>Reviews</span>
          </li>
        </ul>
        <div className="progress-indicator">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 34 34">
            <circle
              cx="16"
              cy="16"
              r="15.9155"
              className="progress-bar__background"
            />
            <circle
              cx="16"
              cy="16"
              r="15.9155"
              className="progress-bar__progress js-progress-bar"
            />
          </svg>
        </div>
      </NavSectionStyled>
    </div>
  );
};

const NavSectionStyled = styled.nav`
  /* on midddle on page  */

  z-index: 1000;
  position: fixed;
  top: 29rem !important;
  margin-left:import { className } from '../../../../node_modules/.staging/@sinonjs/commons-7aa12af6/types/index.d';
 0px;
  width: 70px;
  left: 51.2031px;
`;
