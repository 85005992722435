import { API_URL } from '../../config';
import { axiosPrivate } from '../../api/axios';

export const initiateChat = async (data) => {
  try {
    const response = await axiosPrivate.post(API_URL + 'chat', data, {});
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      return response;
    }
  }
};

export const getAllContacts = async (data) => {
  try {
    const response = await axiosPrivate.get(API_URL + 'chat');
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      return response;
    }
  }
};

export const getUsersChats = async (id) => {
  try {
    const response = await axiosPrivate.get(API_URL + `chat/${id}`);
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      return response;
    }
  }
};
