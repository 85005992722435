import * as yup from 'yup';

export const InviteGuestValidationSchema = yup.object({
  email: yup
    .string()
    .required('Email is required')
    .email('Please enter a valid email')
    .max(150, 'Email must be less than 150 characters'),
  firstName: yup
    .string()
    .required('First name is required')
    .max(50, 'First name should be less than 50 characters'),
  lastName: yup
    .string()
    .required('Last name is required')
    .max(50, 'Last name should be less than 50 characters'),
  startDate: yup.string().required('Start date is required'),
  endDate: yup.string().nullable().required('End date is required'),
  status: yup.string().required('Status is required is required'),
  noOfGuest: yup.number().min(1, 'Number of guest is required'),
  address1: yup.string().max(180, 'Address should be less than 180 characters'),
  address2: yup.string().max(180, 'Address should be less than 180 characters'),
  city: yup.string().max(50, 'City should be less than 50 characters'),
});
