import React, { useState, useEffect } from 'react';
import { Galleria } from 'primereact/galleria';
import styled from 'styled-components';

export const DetailPageCarousel = ({ propertyDetail }) => {
  const [images, setImages] = useState(null);

  useEffect(() => {
    setImages(propertyDetail);
  }, []);

  const responsiveOptions = [
    {
      breakpoint: '1024px',
      numVisible: 5,
    },
    {
      breakpoint: '768px',
      numVisible: 3,
    },
    {
      breakpoint: '560px',
      numVisible: 1,
    },
  ];

  const itemTemplate = (item) => {
    return (
      <img
        style={{
          height: '450px',
          width: '100%',
          objectFit: 'contain',
          backgroundColor: 'rgb(27, 24, 43)',
        }}
        src={item?.locationUrl}
        alt={item?.alt}
      />
    );
  };

  const thumbnailTemplate = (item) => {
    return (
      <img
        style={{
          height: '100px',
        }}
        src={item?.locationUrl}
        alt={item.alt}
      />
    );
  };

  return (
    <CarouselStyled>
      <div className='card'>
        <Galleria
          value={images}
          responsiveOptions={responsiveOptions}
          numVisible={5}
          item={itemTemplate}
          thumbnail={thumbnailTemplate}
          autoPlay
          transitionInterval={5000}
          circular
        />
      </div>
    </CarouselStyled>
  );
};

const CarouselStyled = styled.div`
  .p-galleria .p-galleria-thumbnail-container {
    background: rgb(27, 24, 43);
    padding: 1rem 0.25rem;
  }
`;
