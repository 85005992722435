import React, { useEffect, useState, useRef } from "react";
import Input from "../form/input";
import { Divider, Grid, List } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";

export const GuestCount = (props) => {
  const limit =
    props.propertyDetail?.propertyCapacity?.noOfAdults +
    props.propertyDetail?.propertyCapacity?.noOfChildren;
  const AllLimits = props.propertyDetail?.propertyCapacity;

  const [guestSelect, setGuestSelect] = useState(false);
  const menuRef = useRef();
  const [guestCount, setGuestCount] = useState({
    adults: 0,
    children: 0,
    infants: 0,
    pets: 0,
  });

  useEffect(() => {
    props.handleChange &&
      props.handleChange(guestCount.adults + guestCount.children, guestCount);
  }, [guestCount]);

  useEffect(() => {
    window.addEventListener("mousedown", (e) => {
      if (!menuRef?.current?.contains(e.target)) {
        setGuestSelect(false);
      }
      return window.removeEventListener("mousedown", (e) => {
        if (!menuRef?.current?.contains(e.target)) {
          setGuestSelect(false);
        }
      });
    });
  }, [guestCount]);

  return (
    <>
      <Input
        value={
          guestCount.adults + guestCount.children < 1
            ? ""
            : `${guestCount.adults + guestCount.children} ${
                guestCount.adults + guestCount.children > 1 ? "guests" : "guest"
              }`
        }
        label={"Select # of Guests"}
        sx={{
          fontSize: "14px",
          textAlign: "center",
        }}
        onClick={() => setGuestSelect(!guestSelect)}
        onChange={() => {
          props.handleChange([guestCount.adults + guestCount.children]);
        }}
      />

      {guestSelect && (
        <ul
          id='guestDD'
          style={{
            position: "absolute",
            zIndex: 9999,
            color: "#fff",
            minWidth: "250px",
          }}
          ref={menuRef}
        >
          <ListItems
            primary={"Adults"}
            secondary={"Age 13+"}
            adult
            value={guestCount.adults}
            handleAddClick={() =>
              setGuestCount({
                ...guestCount,
                adults: !(guestCount.adults + guestCount.children >= limit)
                  ? guestCount.adults + 1
                  : guestCount.adults,
              })
            }
            handleRemoveClick={() =>
              setGuestCount({ ...guestCount, adults: guestCount.adults - 1 })
            }
          />
          <ListItems
            primary={"Children"}
            secondary={"Ages 2–12"}
            value={guestCount.children}
            handleAddClick={() =>
              setGuestCount({
                ...guestCount,
                children: !(guestCount.adults + guestCount.children >= limit)
                  ? guestCount.children + 1
                  : guestCount.children,
              })
            }
            handleRemoveClick={() =>
              setGuestCount({
                ...guestCount,
                children: guestCount.children - 1,
              })
            }
          />
          <ListItems
            primary={"Infants"}
            secondary={"Under 2"}
            value={guestCount.infants}
            handleAddClick={() =>
              setGuestCount({
                ...guestCount,
                infants: !(guestCount.infants >= AllLimits?.noOfInfants)
                  ? guestCount.infants + 1
                  : !AllLimits?.noOfInfants
                  ? 0
                  : guestCount.infants,
              })
            }
            handleRemoveClick={() =>
              setGuestCount({
                ...guestCount,
                infants: guestCount.infants - 1,
              })
            }
          />
          <ListItems
            primary={"Pets"}
            secondary={"Bringing a service animal?"}
            value={guestCount.pets}
            handleAddClick={() =>
              setGuestCount({
                ...guestCount,
                pets: !(guestCount.pets >= AllLimits?.noOfPets)
                  ? guestCount.pets + 1
                  : !AllLimits?.noOfPets
                  ? 0
                  : guestCount.pets,
              })
            }
            handleRemoveClick={() =>
              setGuestCount({
                ...guestCount,
                pets: guestCount.pets - 1,
              })
            }
          />
        </ul>
      )}
    </>
  );
};

const style = {
  width: "100%",
  bgcolor: "#1f2d40",
  padding: 0,
};

const ListItems = ({
  adult,
  primary,
  secondary,
  handleAddClick,
  handleRemoveClick,
  value,
}) => {
  return (
    <List dense sx={style} component='nav' aria-label='mailbox folders'>
      <ListItem
        secondaryAction={
          <Grid container justifyContent='center' alignItems='center'>
            <Grid item>
              <IconButton
                aria-label='remove'
                onClick={() => {
                  if (value > 0) handleRemoveClick();
                }}
                edge='end'
              >
                <RemoveOutlinedIcon />
              </IconButton>
            </Grid>
            <Grid
              item
              style={{
                textAlign: "center",
                marginLeft: "20px",
                marginRight: "8px",
              }}
            >
              <span>{value}</span>
            </Grid>
            <Grid item>
              <IconButton aria-label='add' onClick={handleAddClick} edge='end'>
                <AddOutlinedIcon />
              </IconButton>
            </Grid>
          </Grid>
        }
      >
        <ListItemText primary={primary} secondary={secondary} />
      </ListItem>
      <Divider />
    </List>
  );
};

export default ListItems;
