import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import CircularProgress from "@mui/material/CircularProgress";
import { propertyPreview } from "../../services/SpecialOffer/propertyPreview";
import moment from "moment";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import DatePickers from "../form/datePicker";
import { useSnackbar } from "notistack";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: "80%",
  color: "text.primary",
  backgroundColor: "#1b182b",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
  textAlign: "start",
  overflow: "auto",
};

export default function SpecialOfferModal({
  isOpen,
  guest,
  propertyOwner,
  property,
  handleOpen,
  handleClose,
  isLoading,
  submitSpecialOfferFormSuccess,
}) {

  // TOAST FOR ERROR MESSAGES
  const { enqueueSnackbar } = useSnackbar();

  // PROPERTY PREVIEW VALUES
  const [propertyPreviewData, setPropertyPreviewData] = useState(null);

  // FORM VALUES
  const [numOfGuests, setNumOfGuests] = useState(1);
  const [checkinDate, setCheckinDate] = useState("");
  const [checkoutDate, setCheckoutDate] = useState("");
  const [subtotal, setSubtotal] = useState("0");

  // ERROR VALUES
  const [subtotalErrorText, setSubtotalErrorText] = useState("");
  const [generalErrorText, setGeneralErrorText] = useState("");

  // PROPERTY PREVIEW CALLBACK DATA
  useEffect(() => {
    (async (_) => {
      if (validatorWithoutError()) {
        const formData = {
          propertyId: propertyOwner?.id,
          nights: daysCounter(checkinDate, checkoutDate),
          pets: 0,
          guests: numOfGuests,
          unit: "NIGHT",
          startDate: checkinDate,
          endDate: checkoutDate,
        };
        const preview = await propertyPreview(formData);
        setPropertyPreviewData(preview?.data);
        setGeneralErrorText("");
      }
    })();
  }, [numOfGuests, checkinDate, checkoutDate, subtotal]);

  // OFFER SUBMIT HANDLER
  const submitOffer = () => {
    if (validator()) {
      const formData = {
        guestId: guest?.id,
        propertyId: propertyOwner?.id,
        startDate: checkinDate,
        endDate: checkoutDate,
        overAllPrice: Number(subtotal),
        totalNoOfGuests: numOfGuests,
        adults: 0,
        childrens: 0,
        pets: 0,
        infants: 0,
        validThrough: checkoutDate,
        cleaningFee: propertyPreviewData?.cleaningFee,
      };
      submitSpecialOfferFormSuccess(formData);
    }
  };

  // VALIDATOR
  const validator = () => {
    if (
      checkinDate?.toString().trim().length === 0 ||
      checkoutDate?.toString().trim().length === 0
    ) {
      enqueueSnackbar("Please select checkin and checkout date", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      return;
    }
    if (subtotal.trim().length === 0 || subtotal == "0") {
      enqueueSnackbar("Please fill subtotal amount", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      return;
    }
    return true;
  };

  // VALIDATOR WITHOUT ERROR
  const validatorWithoutError = () => {
    if (
      checkinDate?.toString().trim().length === 0 ||
      checkoutDate?.toString().trim().length === 0 ||
      subtotal.trim().length === 0 ||
      subtotal == "0"
    ) {
      return false;
    }
    return true;
  };

  const getRoundedValue = (num) => Math.round(num * 100) / 100;

  // SPECIAL OFFER PREVIEW UI
  const PreviewUI = () => {
    const perNightPrice = getRoundedValue(
      propertyPreviewData?.hostEarning / daysCounter(checkinDate, checkoutDate)
    );
    return (
      <Box sx={{ mt: 2 }}>
        <Typography variant="h5" component="h4" sx={{ mb: 1 }}>
          Your guest will pay
        </Typography>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ mt: 2 }}
        >
          {/* VIEW ONE START */}
          <Grid item xs={6}>
            <Typography variant="h6" component="h4" sx={{ opacity: 0.6 }}>
              {`${process.env.REACT_APP_CURRENCY}${perNightPrice || ""} x ${daysCounter(
                checkinDate || "",
                checkoutDate || ""
              )} nights`}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Typography variant="h6" component="h4" sx={{ opacity: 0.6 }}>
              {`${process.env.REACT_APP_CURRENCY}${propertyPreviewData?.hostEarning || ""}`}
            </Typography>
          </Grid>
          {/* VIEW ONE END */}

          {/* VIEW TWO START */}
          <Grid item xs={6}>
            <Typography variant="h6" component="h4" sx={{ opacity: 0.6 }}>
              {`Service fee (host)`}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Typography variant="h6" component="h4" sx={{ opacity: 0.6 }}>
              {`${process.env.REACT_APP_CURRENCY}${propertyPreviewData?.serviceFee || ""}`}
            </Typography>
          </Grid>
          {/* VIEW TWO END */}
        </Grid>
        <hr style={{ marginTop: 8, marginBottom: 8 }} />
        {/* VIEW THREE START */}
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <Typography variant="h6" component="h4" sx={{ opacity: 0.6 }}>
              {`Total ($)`}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Typography variant="h6" component="h4" sx={{ opacity: 0.6 }}>
              {`${process.env.REACT_APP_CURRENCY}${propertyPreviewData?.total || ""}`}
            </Typography>
          </Grid>
          {/* VIEW THREE END */}
        </Grid>
      </Box>
    );
  };

  // SPECIAL OFFER FORM UI
  const SpecialOfferForm = () => {
    return (
      <Box sx={style}>
        <Button onClick={handleClose} sx={{ display: "block" }}>
          {" "}
          <CloseIcon />{" "}
        </Button>
        <Typography variant="h5" component="h4" sx={{ mb: 1 }}>
          Send {guest?.firstName} a special offer
        </Typography>
        <Typography>
          {guest?.firstName} will have 24 hours to book. In the meantime, your
          calendar will remain open.
        </Typography>

        {/* FORM  */}
        <FormControl sx={{ marginTop: 2, width: "100%" }} variant="standard">
          <Typography variant="h6" component="h4" sx={{ mb: 1 }}>
            Listing
          </Typography>

          {/* DISABLED LISTING FIELD */}
          <TextField
            fullWidth
            label=""
            value={property?.title}
            id="fullWidth"
            disabled
          />

          {/* CHECKIN AND CHECKOUT DATE PICKER */}
          <Box
            sx={{
              width: "100%",
              mt: 3,
            }}
          >
            <Typography variant="h6" component="h4" sx={{ mb: 1 }}>
              Select checkin & checkout dates
            </Typography>
            {property && (
              <Box sx={{ width: "100%" }}>
                <DatePickers
                  placeholder={"Checkin Date - Checkout Date"}
                  basePrice={propertyOwner?.perNightPrice || ""}
                  handlers
                  handleDateStart={(value) => setCheckinDate(value)}
                  handleDateEnd={(value) => setCheckoutDate(value)}
                  id={propertyOwner?.id}
                />
              </Box>
            )}
          </Box>
          {/* GUEST INPUT FIELD */}
          <FormControl sx={{ width: "100%", mt: 3 }}>
            <InputLabel id="demo-simple-select-helper-label">Guest</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={numOfGuests}
              label="Guest"
              onChange={(e) => setNumOfGuests(e.target.value)}
            >
              {Array.apply(null, { length: 10 }).map((item, index) => (
                <MenuItem value={index + 1} key={index}>
                  {index + 1}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* AMOUNT INPUT FIELD */}
          <FormControl fullWidth sx={{ width: "100%", mt: 3 }}>
            <TextField
              id="outlined-adornment-amount"
              type="number"
              value={subtotal}
              onChange={(e) => setSubtotal(e.target.value)}
              label="Subtotal"
              error={!!subtotalErrorText}
              helperText={subtotalErrorText}
            />
          </FormControl>
          <Box sx={{ mt: 1 }}>
            <span>
              Enter a subtotal that includes any cleaning or extra guest fees.
              This won’t include service fees or applicable taxes.
            </span>
          </Box>
          {generalErrorText != "" && (
            <Box sx={{ mt: 1 }}>
              <Alert severity="error">{generalErrorText}</Alert>
            </Box>
          )}
        </FormControl>

        {/* PREVIEW UI*/}
        {propertyPreviewData && (
          <Box sx={{ mt: 5 }}>
            <hr />
            {PreviewUI()}
          </Box>
        )}
        {/* SUBMIT BUTTON */}
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 5 }}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Button
              variant="contained"
              id="srt-general-modal-logout-button"
              className="ModalButton ModalButton__Success_logout"
              onClick={submitOffer}
            >
              Send special offer
            </Button>
          )}
        </Box>
      </Box>
    );
  };

  // DAYS COUNTER
  const daysCounter = (startDate, endDate) => {
    let parseStart = moment(endDate).format("YYYY-MM-DD"); // 2018-03-10
    let parseEnd = moment(startDate).format("YYYY-MM-DD"); // 2018-03-15
    let start = moment(parseStart, "YYYY-MM-DD");
    let end = moment(parseEnd, "YYYY-MM-DD");

    //Difference in number of days
    const duration = moment.duration(start.diff(end)).asDays();
    return duration;
  };

  return (
    <div>
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={isOpen}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={isOpen}>{SpecialOfferForm()}</Fade>
        </Modal>
      </div>
    </div>
  );
}
