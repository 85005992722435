import { API_URL } from "../../config";
import axios from "axios";

export const addPropertyApi = async (data) => {
  try {
    const response = await axios.post(API_URL + "property", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log("Bad Response of property : ", response);
      return response;
    }
  }
};
