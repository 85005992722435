export const propertyDetailCommercialUpdated = {
  id: 8,
  title: 'New Property Update',
  code: 'PK-SD-000003',
  description: 'It was not working previously',
  interactionWithGuest: 'Find the key under the mattress',
  wifiUsername: 'staywoprop',
  wifiPassword: 'staywoprop123',
  address: 'B-912, F. C. Area',
  direction: 'Take right from Taj Mahal and first right from the first street',
  guestManual: "Don't use this manual its fake",
  checkInInstruction: 'Turn on the main switch on the left of the main door',
  wifiDetails: null,
  zipCode: '75950',
  country: 'Pakistan',
  state: 'Punjab',
  city: 'Lahore',
  youtubeVideoLink: 'https://www.youtube.com/watch?v=zWrAn6NGCfA',
  price: 1500,
  perNightPrice: 1500,
  thumbnailUrl:
    'https://staywobucket.s3.amazonaws.com/0b4f0e50-2677-4cb8-8ab7-e96efc994713.webp1660101269',
  propertyType: 'Commercial',
  lng: '64.8101',
  lat: '73.00281',
  status: 'PENDING',
  isChecked: false,
  recommended: false,
  inspiredDestination: false,
  createdOn: '2022-08-04T17:43:14.252Z',
  customUrl: 'url_not_updated',
  spaceTypeId: 2,
  areaId: 2,
  spaceId: 2,
  userId: 5,
  rooms: [
    {
      title: 'Room, 1 King Bed, Non Smoking',
      roomId: 1,
      roomAmenities: [
        {
          id: 9,
          propertyId: 8,
          amenityId: 5,
          amenity: {
            id: 5,
            title: 'Paid Breakfast',
            icon: null,
          },
        },
        {
          id: 10,
          propertyId: 8,
          amenityId: 5,
          amenity: {
            id: 5,
            title: 'Wifi',
            icon: null,
          },
        },
        {
          id: 11,
          propertyId: 8,
          amenityId: 5,
          amenity: {
            id: 5,
            title: 'Free Parking',
            icon: null,
          },
        },
        {
          id: 12,
          propertyId: 8,
          amenityId: 5,
          amenity: {
            id: 5,
            title: 'Paid Parking',
            icon: null,
          },
        },
        {
          id: 13,
          propertyId: 8,
          amenityId: 5,
          amenity: {
            id: 5,
            title: 'Room Service',
            icon: null,
          },
        },
        {
          id: 14,
          propertyId: 8,
          amenityId: 5,
          amenity: {
            id: 5,
            title: 'Free Breakfast',
            icon: null,
          },
        },
      ],
      roomPerNightPrice: 12000,
      roomImages: [
        {
          id: 6,
          locationUrl:
            'https://staywobucket.s3.us-west-1.amazonaws.com/Screen+Shot+2022-07-28+at+11.53.35+PM.png1659634994',
          propertyId: 8,
        },
        {
          id: 10,
          locationUrl:
            'https://staywobucket.s3.us-west-1.amazonaws.com/e79ce8dd-dd60-486c-bec0-c3ac64171f25.webp1660100849',
          propertyId: 8,
        },
        {
          id: 11,
          locationUrl:
            'https://staywobucket.s3.us-west-1.amazonaws.com/eeff3a37-cc40-4d4f-bde7-306c1f64222e.webp1660100849',
          propertyId: 8,
        },
        {
          id: 12,
          locationUrl:
            'https://staywobucket.s3.us-west-1.amazonaws.com/f46586bd-708f-4db6-bf39-8efe74c27f21.jpeg1660100849',
          propertyId: 8,
        },
        {
          id: 13,
          locationUrl:
            'https://staywobucket.s3.us-west-1.amazonaws.com/e79ce8dd-dd60-486c-bec0-c3ac64171f25.webp1660101253',
          propertyId: 8,
        },
        {
          id: 14,
          locationUrl:
            'https://staywobucket.s3.us-west-1.amazonaws.com/eeff3a37-cc40-4d4f-bde7-306c1f64222e.webp1660101253',
          propertyId: 8,
        },
        {
          id: 15,
          locationUrl:
            'https://staywobucket.s3.us-west-1.amazonaws.com/f46586bd-708f-4db6-bf39-8efe74c27f21.jpeg1660101253',
          propertyId: 8,
        },
        {
          id: 16,
          locationUrl:
            'https://staywobucket.s3.us-west-1.amazonaws.com/e79ce8dd-dd60-486c-bec0-c3ac64171f25.webp1660101278',
          propertyId: 8,
        },
        {
          id: 17,
          locationUrl:
            'https://staywobucket.s3.us-west-1.amazonaws.com/eeff3a37-cc40-4d4f-bde7-306c1f64222e.webp1660101278',
          propertyId: 8,
        },
        {
          id: 18,
          locationUrl:
            'https://staywobucket.s3.us-west-1.amazonaws.com/f46586bd-708f-4db6-bf39-8efe74c27f21.jpeg1660101278',
          propertyId: 8,
        },
      ],
      roomPricePolicy: [{ cleaningFee: 400 }],
      roomPolicy: { id: 1, cancellationPolicy: '1' },
      noOfRooms: 12,
    },
    {
      title: 'Executive Room, 1 Double Bed, Smoking',
      roomId: 2,
      roomAmenities: [
        {
          id: 9,
          propertyId: 8,
          amenityId: 5,
          amenity: {
            id: 5,
            title: 'Paid Breakfast',
            icon: null,
          },
        },
        {
          id: 10,
          propertyId: 8,
          amenityId: 5,
          amenity: {
            id: 5,
            title: 'Wifi',
            icon: null,
          },
        },
        {
          id: 11,
          propertyId: 8,
          amenityId: 5,
          amenity: {
            id: 5,
            title: 'Free Parking',
            icon: null,
          },
        },
        {
          id: 12,
          propertyId: 8,
          amenityId: 5,
          amenity: {
            id: 5,
            title: 'Paid Parking',
            icon: null,
          },
        },
        {
          id: 13,
          propertyId: 8,
          amenityId: 5,
          amenity: {
            id: 5,
            title: 'Room Service',
            icon: null,
          },
        },
        {
          id: 14,
          propertyId: 8,
          amenityId: 5,
          amenity: {
            id: 5,
            title: 'Free Breakfast',
            icon: null,
          },
        },
      ],
      roomPerNightPrice: 15000,
      roomImages: [
        {
          id: 6,
          locationUrl:
            'https://staywobucket.s3.us-west-1.amazonaws.com/Screen+Shot+2022-07-28+at+11.53.35+PM.png1659634994',
          propertyId: 8,
        },
        {
          id: 10,
          locationUrl:
            'https://staywobucket.s3.us-west-1.amazonaws.com/e79ce8dd-dd60-486c-bec0-c3ac64171f25.webp1660100849',
          propertyId: 8,
        },
        {
          id: 11,
          locationUrl:
            'https://staywobucket.s3.us-west-1.amazonaws.com/eeff3a37-cc40-4d4f-bde7-306c1f64222e.webp1660100849',
          propertyId: 8,
        },
        {
          id: 12,
          locationUrl:
            'https://staywobucket.s3.us-west-1.amazonaws.com/f46586bd-708f-4db6-bf39-8efe74c27f21.jpeg1660100849',
          propertyId: 8,
        },
        {
          id: 13,
          locationUrl:
            'https://staywobucket.s3.us-west-1.amazonaws.com/e79ce8dd-dd60-486c-bec0-c3ac64171f25.webp1660101253',
          propertyId: 8,
        },
        {
          id: 14,
          locationUrl:
            'https://staywobucket.s3.us-west-1.amazonaws.com/eeff3a37-cc40-4d4f-bde7-306c1f64222e.webp1660101253',
          propertyId: 8,
        },
        {
          id: 15,
          locationUrl:
            'https://staywobucket.s3.us-west-1.amazonaws.com/f46586bd-708f-4db6-bf39-8efe74c27f21.jpeg1660101253',
          propertyId: 8,
        },
        {
          id: 16,
          locationUrl:
            'https://staywobucket.s3.us-west-1.amazonaws.com/e79ce8dd-dd60-486c-bec0-c3ac64171f25.webp1660101278',
          propertyId: 8,
        },
        {
          id: 17,
          locationUrl:
            'https://staywobucket.s3.us-west-1.amazonaws.com/eeff3a37-cc40-4d4f-bde7-306c1f64222e.webp1660101278',
          propertyId: 8,
        },
        {
          id: 18,
          locationUrl:
            'https://staywobucket.s3.us-west-1.amazonaws.com/f46586bd-708f-4db6-bf39-8efe74c27f21.jpeg1660101278',
          propertyId: 8,
        },
      ],
      roomPricePolicy: [{ cleaningFee: 400 }],
    },
    {
      title: 'Suite, 1 King Bed, Smoking',
      roomId: 3,
      roomAmenities: [
        {
          id: 9,
          propertyId: 8,
          amenityId: 5,
          amenity: {
            id: 5,
            title: 'Paid Breakfast',
            icon: null,
          },
        },
        {
          id: 10,
          propertyId: 8,
          amenityId: 5,
          amenity: {
            id: 5,
            title: 'Wifi',
            icon: null,
          },
        },
        {
          id: 11,
          propertyId: 8,
          amenityId: 5,
          amenity: {
            id: 5,
            title: 'Free Parking',
            icon: null,
          },
        },
        {
          id: 12,
          propertyId: 8,
          amenityId: 5,
          amenity: {
            id: 5,
            title: 'Paid Parking',
            icon: null,
          },
        },
        {
          id: 13,
          propertyId: 8,
          amenityId: 5,
          amenity: {
            id: 5,
            title: 'Room Service',
            icon: null,
          },
        },
        {
          id: 14,
          propertyId: 8,
          amenityId: 5,
          amenity: {
            id: 5,
            title: 'Free Breakfast',
            icon: null,
          },
        },
      ],
      roomPerNightPrice: 19500,
      roomImages: [
        {
          id: 6,
          locationUrl:
            'https://staywobucket.s3.us-west-1.amazonaws.com/Screen+Shot+2022-07-28+at+11.53.35+PM.png1659634994',
          propertyId: 8,
        },
        {
          id: 10,
          locationUrl:
            'https://staywobucket.s3.us-west-1.amazonaws.com/e79ce8dd-dd60-486c-bec0-c3ac64171f25.webp1660100849',
          propertyId: 8,
        },
        {
          id: 11,
          locationUrl:
            'https://staywobucket.s3.us-west-1.amazonaws.com/eeff3a37-cc40-4d4f-bde7-306c1f64222e.webp1660100849',
          propertyId: 8,
        },
        {
          id: 12,
          locationUrl:
            'https://staywobucket.s3.us-west-1.amazonaws.com/f46586bd-708f-4db6-bf39-8efe74c27f21.jpeg1660100849',
          propertyId: 8,
        },
        {
          id: 13,
          locationUrl:
            'https://staywobucket.s3.us-west-1.amazonaws.com/e79ce8dd-dd60-486c-bec0-c3ac64171f25.webp1660101253',
          propertyId: 8,
        },
        {
          id: 14,
          locationUrl:
            'https://staywobucket.s3.us-west-1.amazonaws.com/eeff3a37-cc40-4d4f-bde7-306c1f64222e.webp1660101253',
          propertyId: 8,
        },
        {
          id: 15,
          locationUrl:
            'https://staywobucket.s3.us-west-1.amazonaws.com/f46586bd-708f-4db6-bf39-8efe74c27f21.jpeg1660101253',
          propertyId: 8,
        },
        {
          id: 16,
          locationUrl:
            'https://staywobucket.s3.us-west-1.amazonaws.com/e79ce8dd-dd60-486c-bec0-c3ac64171f25.webp1660101278',
          propertyId: 8,
        },
        {
          id: 17,
          locationUrl:
            'https://staywobucket.s3.us-west-1.amazonaws.com/eeff3a37-cc40-4d4f-bde7-306c1f64222e.webp1660101278',
          propertyId: 8,
        },
        {
          id: 18,
          locationUrl:
            'https://staywobucket.s3.us-west-1.amazonaws.com/f46586bd-708f-4db6-bf39-8efe74c27f21.jpeg1660101278',
          propertyId: 8,
        },
      ],
      roomPricePolicy: [{ cleaningFee: 400 }],
    },
  ],
  area: {
    id: 2,
    title: 'A Shared Place',
    code: 'fa fa-home',
  },
  user: {
    id: 5,
    firstName: 'Tobibur',
    lastName: 'Rohman',
    middleName: '',
    verified: false,
    description: '<p>I&#x27;m a web application developer.</p>',
    isPhoneNumberConfirmed: false,
    profilePicture:
      'https://staywobucket.s3.us-west-1.amazonaws.com/Photo_two.png1660647691',
    createdOn: '2022-08-03T06:23:41.744Z',
  },
  space: {
    id: 2,
    title: 'House',
    code: 'fa fa-home',
  },
  spaceType: {
    id: 2,
    spaceId: 1,
    title: 'Condo',
    code: 'fa fa-home',
  },
  propertyCapacity: {
    id: 3,
    noOfAdults: 1,
    noOfChildren: 1,
    noOfBeds: 1,
    noOfBedrooms: 1,
    noOfBathrooms: 1,
    noOfPets: null,
    noOfInfants: null,
    isPrivateBathroom: true,
    propertyId: 8,
  },
  propertyAmenities: [
    {
      id: 9,
      propertyId: 8,
      amenityId: 5,
      amenity: {
        id: 5,
        title: 'Paid Breakfast',
        icon: null,
      },
    },
  ],
  propertyPricePolicy: [
    {
      id: 1,
      weeklyNightPrice: '8000',
      monthlyNightPrice: '1500',
      customLengthOfStayDiscount: '5000',
      cleaningFee: '300',
      petFee: '500',
      extraGuestFee: '500',
      weekendNightPrice: '8000',
      minimumStay: '1',
      maximumStay: '28',
      createdOn: '2022-08-16T17:58:39.542Z',
      modifiedOn: '2022-08-16T17:58:39.542Z',
      propertyId: 8,
      isDeleted: false,
      isActive: true,
    },
  ],
  propertyPolicy: {
    id: 4,
    cancellationPolicy: '1',
    instantBooking: true,
    governmentIdRequired: true,
    preBookingMessageRequired: true,
    guestPhotoRequirement: true,
    checkInWindow: '03:00 PM',
    checkoutWindow: '12:00 PM',
    propertyId: 8,
  },
  propertyFacility: [],
  propertySafetyConsideration: [
    {
      id: 1,
      createdOn: '2022-08-05T08:32:12.318Z',
      modifiedOn: '2022-08-05T08:32:12.318Z',
      propertyId: 8,
      safetyConsiderationId: 1,
      safetyConsideration: {
        id: 1,
        title: 'Pool',
        description: null,
        icon: 'fa fa-home',
        isActive: true,
        createdOn: '2022-08-02T22:44:12.976Z',
        modifiedOn: '2022-08-02T22:44:12.976Z',
        DeletedOn: null,
        safetyConsiderationGroupId: 1,
        isChecked: false,
      },
    },
  ],
  propertyHouseRule: [
    {
      id: 1,
      createdOn: '2022-08-05T08:32:25.809Z',
      modifiedOn: '2022-08-05T08:32:25.809Z',
      propertyId: 8,
      houseRuleId: 1,
      houseRule: {
        id: 1,
        title: 'Pool',
        description: null,
        icon: 'fa fa-home',
        isActive: true,
        createdOn: '2022-08-02T22:44:12.976Z',
        modifiedOn: '2022-08-04T14:36:41.703Z',
        deletedOn: null,
        houseRuleGroupId: 1,
        isChecked: false,
      },
    },
  ],
  propertyAccessibility: [],
  propertySecurityFeature: [],
  propertyNearbyActivity: [],
  propertyImages: [
    {
      id: 6,
      locationUrl:
        'https://staywobucket.s3.us-west-1.amazonaws.com/Screen+Shot+2022-07-28+at+11.53.35+PM.png1659634994',
      propertyId: 8,
    },
    {
      id: 10,
      locationUrl:
        'https://staywobucket.s3.us-west-1.amazonaws.com/e79ce8dd-dd60-486c-bec0-c3ac64171f25.webp1660100849',
      propertyId: 8,
    },
    {
      id: 11,
      locationUrl:
        'https://staywobucket.s3.us-west-1.amazonaws.com/eeff3a37-cc40-4d4f-bde7-306c1f64222e.webp1660100849',
      propertyId: 8,
    },
    {
      id: 12,
      locationUrl:
        'https://staywobucket.s3.us-west-1.amazonaws.com/f46586bd-708f-4db6-bf39-8efe74c27f21.jpeg1660100849',
      propertyId: 8,
    },
    {
      id: 13,
      locationUrl:
        'https://staywobucket.s3.us-west-1.amazonaws.com/e79ce8dd-dd60-486c-bec0-c3ac64171f25.webp1660101253',
      propertyId: 8,
    },
    {
      id: 14,
      locationUrl:
        'https://staywobucket.s3.us-west-1.amazonaws.com/eeff3a37-cc40-4d4f-bde7-306c1f64222e.webp1660101253',
      propertyId: 8,
    },
    {
      id: 15,
      locationUrl:
        'https://staywobucket.s3.us-west-1.amazonaws.com/f46586bd-708f-4db6-bf39-8efe74c27f21.jpeg1660101253',
      propertyId: 8,
    },
    {
      id: 16,
      locationUrl:
        'https://staywobucket.s3.us-west-1.amazonaws.com/e79ce8dd-dd60-486c-bec0-c3ac64171f25.webp1660101278',
      propertyId: 8,
    },
    {
      id: 17,
      locationUrl:
        'https://staywobucket.s3.us-west-1.amazonaws.com/eeff3a37-cc40-4d4f-bde7-306c1f64222e.webp1660101278',
      propertyId: 8,
    },
    {
      id: 18,
      locationUrl:
        'https://staywobucket.s3.us-west-1.amazonaws.com/f46586bd-708f-4db6-bf39-8efe74c27f21.jpeg1660101278',
      propertyId: 8,
    },
  ],
  chats: [
    {
      id: 165,
      user_1: 66,
      user_2: 5,
      createdAt: '2022-08-16T05:03:19.029Z',
      updatedAt: '2022-08-16T05:03:19.029Z',
      messages: [
        {
          id: 430,
          senderId: 5,
          content: 'asyh',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T05:16:49.601Z',
          updatedAt: '2022-08-16T05:16:49.601Z',
        },
        {
          id: 431,
          senderId: 66,
          content: 'hey there?',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T05:17:06.319Z',
          updatedAt: '2022-08-16T05:17:06.319Z',
        },
        {
          id: 432,
          senderId: 5,
          content: 'asd',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T05:17:14.997Z',
          updatedAt: '2022-08-16T05:17:14.997Z',
        },
        {
          id: 433,
          senderId: 5,
          content: 'asd',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T05:17:23.384Z',
          updatedAt: '2022-08-16T05:17:23.384Z',
        },
        {
          id: 434,
          senderId: 66,
          content: 'asds',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T05:17:28.615Z',
          updatedAt: '2022-08-16T05:17:28.615Z',
        },
        {
          id: 435,
          senderId: 66,
          content: 'Mesageeeee',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T05:22:05.020Z',
          updatedAt: '2022-08-16T05:22:05.020Z',
        },
        {
          id: 436,
          senderId: 5,
          content: 'new messageee',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T05:22:19.231Z',
          updatedAt: '2022-08-16T05:22:19.231Z',
        },
        {
          id: 437,
          senderId: 66,
          content: 'hiiii',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T05:31:12.217Z',
          updatedAt: '2022-08-16T05:31:12.217Z',
        },
        {
          id: 438,
          senderId: 66,
          content: 'asdsd',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T05:31:22.378Z',
          updatedAt: '2022-08-16T05:31:22.378Z',
        },
        {
          id: 439,
          senderId: 5,
          content: 'ass',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T05:31:31.786Z',
          updatedAt: '2022-08-16T05:31:31.786Z',
        },
        {
          id: 440,
          senderId: 5,
          content: 'asd',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T05:31:40.644Z',
          updatedAt: '2022-08-16T05:31:40.644Z',
        },
        {
          id: 441,
          senderId: 5,
          content: 'asdadsa',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T05:31:51.483Z',
          updatedAt: '2022-08-16T05:31:51.483Z',
        },
        {
          id: 442,
          senderId: 66,
          content: 'sada',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T05:31:57.756Z',
          updatedAt: '2022-08-16T05:31:57.756Z',
        },
        {
          id: 443,
          senderId: 66,
          content: 'asdsada',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T05:32:06.554Z',
          updatedAt: '2022-08-16T05:32:06.554Z',
        },
        {
          id: 444,
          senderId: 5,
          content: 'asda',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T05:32:14.928Z',
          updatedAt: '2022-08-16T05:32:14.928Z',
        },
        {
          id: 445,
          senderId: 66,
          content: 'asda',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T05:32:21.454Z',
          updatedAt: '2022-08-16T05:32:21.454Z',
        },
        {
          id: 446,
          senderId: 5,
          content: 'asdsa',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T05:32:28.598Z',
          updatedAt: '2022-08-16T05:32:28.598Z',
        },
        {
          id: 447,
          senderId: 66,
          content: 'asad',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T05:32:37.019Z',
          updatedAt: '2022-08-16T05:32:37.019Z',
        },
        {
          id: 448,
          senderId: 5,
          content: 'asdad',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T05:32:41.605Z',
          updatedAt: '2022-08-16T05:32:41.605Z',
        },
        {
          id: 449,
          senderId: 5,
          content: 'asadsa',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T05:33:06.976Z',
          updatedAt: '2022-08-16T05:33:06.976Z',
        },
        {
          id: 450,
          senderId: 66,
          content: '\n/??',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T05:33:13.682Z',
          updatedAt: '2022-08-16T05:33:13.682Z',
        },
        {
          id: 451,
          senderId: 66,
          content: 'asdas',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T05:33:19.712Z',
          updatedAt: '2022-08-16T05:33:19.712Z',
        },
        {
          id: 452,
          senderId: 5,
          content: 'hello wworlddd',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T05:51:36.954Z',
          updatedAt: '2022-08-16T05:51:36.954Z',
        },
        {
          id: 453,
          senderId: 66,
          content: 'aaaa',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T05:51:43.774Z',
          updatedAt: '2022-08-16T05:51:43.774Z',
        },
        {
          id: 454,
          senderId: 5,
          content: 'aasklaj',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T05:51:51.355Z',
          updatedAt: '2022-08-16T05:51:51.355Z',
        },
        {
          id: 455,
          senderId: 66,
          content: 'asdasd',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T05:51:57.307Z',
          updatedAt: '2022-08-16T05:51:57.307Z',
        },
        {
          id: 456,
          senderId: 66,
          content: 'asdsad',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T05:52:00.421Z',
          updatedAt: '2022-08-16T05:52:00.421Z',
        },
        {
          id: 457,
          senderId: 66,
          content: 'tou',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T05:52:08.537Z',
          updatedAt: '2022-08-16T05:52:08.537Z',
        },
        {
          id: 458,
          senderId: 5,
          content: 'asd',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T06:10:05.801Z',
          updatedAt: '2022-08-16T06:10:05.801Z',
        },
        {
          id: 459,
          senderId: 66,
          content: 'asdsa',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T06:10:31.205Z',
          updatedAt: '2022-08-16T06:10:31.205Z',
        },
        {
          id: 460,
          senderId: 66,
          content: 'yo there?',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T06:12:12.755Z',
          updatedAt: '2022-08-16T06:12:12.755Z',
        },
        {
          id: 461,
          senderId: 66,
          content: 'lasdjak',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T06:21:16.016Z',
          updatedAt: '2022-08-16T06:21:16.016Z',
        },
        {
          id: 462,
          senderId: 5,
          content: 'asd',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T07:02:33.192Z',
          updatedAt: '2022-08-16T07:02:33.192Z',
        },
        {
          id: 463,
          senderId: 5,
          content: 'yhh',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T07:02:39.603Z',
          updatedAt: '2022-08-16T07:02:39.603Z',
        },
        {
          id: 464,
          senderId: 5,
          content: 'yahskalks',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T07:13:47.625Z',
          updatedAt: '2022-08-16T07:13:47.625Z',
        },
        {
          id: 465,
          senderId: 66,
          content: 'alsdkjsald',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T07:14:07.528Z',
          updatedAt: '2022-08-16T07:14:07.528Z',
        },
        {
          id: 466,
          senderId: 66,
          content: 'asdaslkjdks',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T07:32:43.024Z',
          updatedAt: '2022-08-16T07:32:43.024Z',
        },
        {
          id: 467,
          senderId: 66,
          content: 'lasdkjlsa',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T07:32:46.151Z',
          updatedAt: '2022-08-16T07:32:46.151Z',
        },
        {
          id: 468,
          senderId: 66,
          content: 'lasdjlksa',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T07:32:49.603Z',
          updatedAt: '2022-08-16T07:32:49.603Z',
        },
        {
          id: 469,
          senderId: 66,
          content: 'yalalla',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T07:33:06.896Z',
          updatedAt: '2022-08-16T07:33:06.896Z',
        },
        {
          id: 470,
          senderId: 5,
          content: 'aysudkas',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T07:33:25.423Z',
          updatedAt: '2022-08-16T07:33:25.423Z',
        },
        {
          id: 471,
          senderId: 5,
          content: 'aksdajkdjald',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T07:34:02.587Z',
          updatedAt: '2022-08-16T07:34:02.587Z',
        },
        {
          id: 472,
          senderId: 5,
          content: 'asd',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T07:34:09.044Z',
          updatedAt: '2022-08-16T07:34:09.044Z',
        },
        {
          id: 473,
          senderId: 66,
          content: 'laskdjal',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T07:34:34.477Z',
          updatedAt: '2022-08-16T07:34:34.477Z',
        },
        {
          id: 474,
          senderId: 5,
          content: 'lelelleele',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T07:40:49.740Z',
          updatedAt: '2022-08-16T07:40:49.740Z',
        },
        {
          id: 475,
          senderId: 66,
          content: 'lsakjdla',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T07:41:39.255Z',
          updatedAt: '2022-08-16T07:41:39.255Z',
        },
        {
          id: 476,
          senderId: 5,
          content: 'yallsk',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T07:41:58.206Z',
          updatedAt: '2022-08-16T07:41:58.206Z',
        },
        {
          id: 477,
          senderId: 66,
          content: '1',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T07:42:18.833Z',
          updatedAt: '2022-08-16T07:42:18.833Z',
        },
        {
          id: 478,
          senderId: 66,
          content: '2',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T07:42:28.517Z',
          updatedAt: '2022-08-16T07:42:28.517Z',
        },
        {
          id: 479,
          senderId: 66,
          content: '3',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T07:42:39.454Z',
          updatedAt: '2022-08-16T07:42:39.454Z',
        },
        {
          id: 480,
          senderId: 66,
          content: '4',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T07:42:46.085Z',
          updatedAt: '2022-08-16T07:42:46.085Z',
        },
        {
          id: 481,
          senderId: 5,
          content: '5',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T07:42:57.517Z',
          updatedAt: '2022-08-16T07:42:57.517Z',
        },
        {
          id: 482,
          senderId: 5,
          content: '6',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T07:44:23.524Z',
          updatedAt: '2022-08-16T07:44:23.524Z',
        },
        {
          id: 483,
          senderId: 5,
          content: '7',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T07:44:28.823Z',
          updatedAt: '2022-08-16T07:44:28.823Z',
        },
        {
          id: 484,
          senderId: 66,
          content: '8',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T07:46:32.436Z',
          updatedAt: '2022-08-16T07:46:32.436Z',
        },
        {
          id: 485,
          senderId: 66,
          content: 'new test1',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T07:51:36.806Z',
          updatedAt: '2022-08-16T07:51:36.806Z',
        },
        {
          id: 486,
          senderId: 66,
          content: 'new test2',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T07:53:45.666Z',
          updatedAt: '2022-08-16T07:53:45.666Z',
        },
        {
          id: 487,
          senderId: 66,
          content: 'new test2',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T07:53:54.703Z',
          updatedAt: '2022-08-16T07:53:54.703Z',
        },
        {
          id: 488,
          senderId: 5,
          content: 'new test3',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T07:54:31.974Z',
          updatedAt: '2022-08-16T07:54:31.974Z',
        },
        {
          id: 489,
          senderId: 5,
          content: 'new test2',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T07:54:40.500Z',
          updatedAt: '2022-08-16T07:54:40.500Z',
        },
        {
          id: 490,
          senderId: 66,
          content: 'new test2',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T07:54:54.457Z',
          updatedAt: '2022-08-16T07:54:54.457Z',
        },
        {
          id: 491,
          senderId: 5,
          content: 'new test2',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T07:55:26.180Z',
          updatedAt: '2022-08-16T07:55:26.180Z',
        },
        {
          id: 492,
          senderId: 66,
          content: 'new test2',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T07:55:32.124Z',
          updatedAt: '2022-08-16T07:55:32.124Z',
        },
        {
          id: 493,
          senderId: 66,
          content: 'askdljsad',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T08:25:18.117Z',
          updatedAt: '2022-08-16T08:25:18.117Z',
        },
        {
          id: 494,
          senderId: 5,
          content: 'alskdjsal',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T08:25:31.982Z',
          updatedAt: '2022-08-16T08:25:31.982Z',
        },
        {
          id: 495,
          senderId: 66,
          content: 'h',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T08:27:58.858Z',
          updatedAt: '2022-08-16T08:27:58.858Z',
        },
        {
          id: 496,
          senderId: 66,
          content: 'hi',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T08:28:20.179Z',
          updatedAt: '2022-08-16T08:28:20.179Z',
        },
        {
          id: 497,
          senderId: 66,
          content: 'hel',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T08:29:39.342Z',
          updatedAt: '2022-08-16T08:29:39.342Z',
        },
        {
          id: 498,
          senderId: 66,
          content: 'hi',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T08:33:13.393Z',
          updatedAt: '2022-08-16T08:33:13.393Z',
        },
        {
          id: 499,
          senderId: 66,
          content: 'hello',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T08:33:52.306Z',
          updatedAt: '2022-08-16T08:33:52.306Z',
        },
        {
          id: 500,
          senderId: 66,
          content: 'hell',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T08:35:16.593Z',
          updatedAt: '2022-08-16T08:35:16.593Z',
        },
        {
          id: 501,
          senderId: 66,
          content: 'hi how r u',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T08:37:18.948Z',
          updatedAt: '2022-08-16T08:37:18.948Z',
        },
        {
          id: 502,
          senderId: 66,
          content: 'hey',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T08:37:39.989Z',
          updatedAt: '2022-08-16T08:37:39.989Z',
        },
        {
          id: 503,
          senderId: 66,
          content: 'hello',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T08:46:14.036Z',
          updatedAt: '2022-08-16T08:46:14.036Z',
        },
        {
          id: 504,
          senderId: 66,
          content: 'hii',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T08:47:20.459Z',
          updatedAt: '2022-08-16T08:47:20.459Z',
        },
        {
          id: 505,
          senderId: 66,
          content: 'aasd',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T08:48:11.949Z',
          updatedAt: '2022-08-16T08:48:11.949Z',
        },
        {
          id: 506,
          senderId: 66,
          content: 'why',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T08:50:30.282Z',
          updatedAt: '2022-08-16T08:50:30.282Z',
        },
        {
          id: 507,
          senderId: 66,
          content: 'salkdjal',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T08:58:08.059Z',
          updatedAt: '2022-08-16T08:58:08.059Z',
        },
        {
          id: 508,
          senderId: 66,
          content: 'assks',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T08:58:36.343Z',
          updatedAt: '2022-08-16T08:58:36.343Z',
        },
        {
          id: 509,
          senderId: 66,
          content: 'okkkkzzzz',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T09:00:14.665Z',
          updatedAt: '2022-08-16T09:00:14.665Z',
        },
        {
          id: 510,
          senderId: 66,
          content: 'testing socket',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T09:02:19.435Z',
          updatedAt: '2022-08-16T09:02:19.435Z',
        },
        {
          id: 511,
          senderId: 66,
          content: 'yyyyyy',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T09:04:36.584Z',
          updatedAt: '2022-08-16T09:04:36.584Z',
        },
        {
          id: 512,
          senderId: 66,
          content: 'hashhss',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T09:09:22.674Z',
          updatedAt: '2022-08-16T09:09:22.674Z',
        },
        {
          id: 513,
          senderId: 66,
          content: 'heleloooo',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T09:10:35.219Z',
          updatedAt: '2022-08-16T09:10:35.219Z',
        },
        {
          id: 514,
          senderId: 66,
          content: 'asljdhsakjd',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T09:15:22.139Z',
          updatedAt: '2022-08-16T09:15:22.139Z',
        },
        {
          id: 515,
          senderId: 66,
          content: 'askjhd',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T09:20:10.821Z',
          updatedAt: '2022-08-16T09:20:10.821Z',
        },
        {
          id: 516,
          senderId: 66,
          content: 'ksajd',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T09:20:22.581Z',
          updatedAt: '2022-08-16T09:20:22.581Z',
        },
        {
          id: 517,
          senderId: 66,
          content: 'alskdjl',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T09:20:34.595Z',
          updatedAt: '2022-08-16T09:20:34.595Z',
        },
        {
          id: 518,
          senderId: 66,
          content: 'yehehe',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T09:23:14.686Z',
          updatedAt: '2022-08-16T09:23:14.686Z',
        },
        {
          id: 519,
          senderId: 66,
          content: 'yahsh',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T09:23:21.637Z',
          updatedAt: '2022-08-16T09:23:21.637Z',
        },
        {
          id: 520,
          senderId: 66,
          content: 'loooo',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T09:23:30.243Z',
          updatedAt: '2022-08-16T09:23:30.243Z',
        },
        {
          id: 521,
          senderId: 5,
          content: 'yahshd',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T09:24:06.703Z',
          updatedAt: '2022-08-16T09:24:06.703Z',
        },
        {
          id: 522,
          senderId: 66,
          content: 'aksd',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T09:24:14.490Z',
          updatedAt: '2022-08-16T09:24:14.490Z',
        },
        {
          id: 523,
          senderId: 66,
          content: '123',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T09:24:19.012Z',
          updatedAt: '2022-08-16T09:24:19.012Z',
        },
        {
          id: 524,
          senderId: 5,
          content: 'aysdh',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T09:24:24.534Z',
          updatedAt: '2022-08-16T09:24:24.534Z',
        },
        {
          id: 525,
          senderId: 5,
          content: 'Hello',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T10:01:10.697Z',
          updatedAt: '2022-08-16T10:01:10.697Z',
        },
        {
          id: 526,
          senderId: 5,
          content: 'Hi',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T10:01:20.230Z',
          updatedAt: '2022-08-16T10:01:20.230Z',
        },
        {
          id: 527,
          senderId: 5,
          content: 'Hel',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T10:01:26.460Z',
          updatedAt: '2022-08-16T10:01:26.460Z',
        },
        {
          id: 528,
          senderId: 66,
          content: 'hi',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T10:02:22.336Z',
          updatedAt: '2022-08-16T10:02:22.336Z',
        },
        {
          id: 529,
          senderId: 5,
          content: 'asdf',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T10:45:32.318Z',
          updatedAt: '2022-08-16T10:45:32.318Z',
        },
        {
          id: 530,
          senderId: 66,
          content: 'new',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T10:46:01.013Z',
          updatedAt: '2022-08-16T10:46:01.013Z',
        },
        {
          id: 531,
          senderId: 66,
          content: 'hi',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T11:02:37.815Z',
          updatedAt: '2022-08-16T11:02:37.815Z',
        },
        {
          id: 532,
          senderId: 66,
          content: 'asdlk',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T11:33:10.003Z',
          updatedAt: '2022-08-16T11:33:10.003Z',
        },
        {
          id: 533,
          senderId: 66,
          content: 'askd',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T11:33:34.869Z',
          updatedAt: '2022-08-16T11:33:34.869Z',
        },
        {
          id: 534,
          senderId: 5,
          content: 'asad',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T11:51:45.497Z',
          updatedAt: '2022-08-16T11:51:45.497Z',
        },
        {
          id: 535,
          senderId: 66,
          content: 'sdf',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T11:54:59.676Z',
          updatedAt: '2022-08-16T11:54:59.676Z',
        },
        {
          id: 536,
          senderId: 66,
          content: 'oaksd',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T11:55:13.654Z',
          updatedAt: '2022-08-16T11:55:13.654Z',
        },
        {
          id: 537,
          senderId: 66,
          content: 'yahsldk',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T11:55:46.599Z',
          updatedAt: '2022-08-16T11:55:46.599Z',
        },
        {
          id: 538,
          senderId: 66,
          content: 'there?',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T14:40:37.807Z',
          updatedAt: '2022-08-16T14:40:37.807Z',
        },
        {
          id: 539,
          senderId: 5,
          content: 'yes?',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T14:40:53.907Z',
          updatedAt: '2022-08-16T14:40:53.907Z',
        },
        {
          id: 540,
          senderId: 66,
          content: 'can we talk?',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T14:41:09.828Z',
          updatedAt: '2022-08-16T14:41:09.828Z',
        },
        {
          id: 541,
          senderId: 66,
          content: 'yuiiaoosi',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T14:56:06.422Z',
          updatedAt: '2022-08-16T14:56:06.422Z',
        },
        {
          id: 542,
          senderId: 66,
          content: 'rn?',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-16T14:56:28.128Z',
          updatedAt: '2022-08-16T14:56:28.128Z',
        },
        {
          id: 543,
          senderId: 66,
          content: 'thereee?',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-17T05:21:25.365Z',
          updatedAt: '2022-08-17T05:21:25.365Z',
        },
        {
          id: 544,
          senderId: 5,
          content: 'yess',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-17T05:29:35.429Z',
          updatedAt: '2022-08-17T05:29:35.429Z',
        },
        {
          id: 545,
          senderId: 66,
          content: '?',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-17T05:30:57.297Z',
          updatedAt: '2022-08-17T05:30:57.297Z',
        },
        {
          id: 546,
          senderId: 5,
          content: 'alsk',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-17T05:31:18.897Z',
          updatedAt: '2022-08-17T05:31:18.897Z',
        },
        {
          id: 547,
          senderId: 5,
          content: 'chat connected',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-17T05:31:46.683Z',
          updatedAt: '2022-08-17T05:31:46.683Z',
        },
        {
          id: 548,
          senderId: 66,
          content: 'yes',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-17T05:32:01.800Z',
          updatedAt: '2022-08-17T05:32:01.800Z',
        },
        {
          id: 549,
          senderId: 66,
          content: 'again test',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-17T05:33:24.240Z',
          updatedAt: '2022-08-17T05:33:24.240Z',
        },
        {
          id: 550,
          senderId: 66,
          content: 'ok',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-17T05:35:12.698Z',
          updatedAt: '2022-08-17T05:35:12.698Z',
        },
        {
          id: 551,
          senderId: 5,
          content: 'you there?',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-17T05:36:20.952Z',
          updatedAt: '2022-08-17T05:36:20.952Z',
        },
        {
          id: 552,
          senderId: 66,
          content: 'test',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-17T05:54:08.766Z',
          updatedAt: '2022-08-17T05:54:08.766Z',
        },
        {
          id: 553,
          senderId: 5,
          content: 'Hi',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-17T06:28:49.337Z',
          updatedAt: '2022-08-17T06:28:49.337Z',
        },
        {
          id: 554,
          senderId: 5,
          content: 'Hi',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-17T06:30:41.599Z',
          updatedAt: '2022-08-17T06:30:41.599Z',
        },
        {
          id: 555,
          senderId: 5,
          content: 'Hello',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-17T06:33:21.832Z',
          updatedAt: '2022-08-17T06:33:21.832Z',
        },
        {
          id: 556,
          senderId: 5,
          content: 'test',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-17T06:45:35.522Z',
          updatedAt: '2022-08-17T06:45:35.522Z',
        },
        {
          id: 557,
          senderId: 5,
          content: 'sdf',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-17T06:50:27.580Z',
          updatedAt: '2022-08-17T06:50:27.580Z',
        },
        {
          id: 558,
          senderId: 5,
          content: 'hello',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-17T06:53:00.338Z',
          updatedAt: '2022-08-17T06:53:00.338Z',
        },
        {
          id: 559,
          senderId: 5,
          content: 'hi',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-17T06:53:23.976Z',
          updatedAt: '2022-08-17T06:53:23.976Z',
        },
        {
          id: 560,
          senderId: 5,
          content: 'again',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-17T07:01:24.581Z',
          updatedAt: '2022-08-17T07:01:24.581Z',
        },
        {
          id: 561,
          senderId: 66,
          content: 'Hi',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-17T07:01:52.906Z',
          updatedAt: '2022-08-17T07:01:52.906Z',
        },
        {
          id: 562,
          senderId: 5,
          content: 's',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-17T07:01:59.730Z',
          updatedAt: '2022-08-17T07:01:59.730Z',
        },
        {
          id: 563,
          senderId: 66,
          content: 'asdf',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-17T07:02:08.581Z',
          updatedAt: '2022-08-17T07:02:08.581Z',
        },
        {
          id: 564,
          senderId: 5,
          content: 'sfsd',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-17T07:02:16.064Z',
          updatedAt: '2022-08-17T07:02:16.064Z',
        },
        {
          id: 565,
          senderId: 66,
          content: 'asdfasdf',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-17T07:02:29.005Z',
          updatedAt: '2022-08-17T07:02:29.005Z',
        },
        {
          id: 566,
          senderId: 5,
          content: 'asdfasdf',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-17T07:02:49.240Z',
          updatedAt: '2022-08-17T07:02:49.240Z',
        },
        {
          id: 567,
          senderId: 66,
          content: 'Hi',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-17T07:04:54.482Z',
          updatedAt: '2022-08-17T07:04:54.482Z',
        },
        {
          id: 569,
          senderId: 66,
          content: 'Hi Amjad bhai',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-19T19:54:59.980Z',
          updatedAt: '2022-08-19T19:54:59.980Z',
        },
        {
          id: 570,
          senderId: 66,
          content: 'Hi',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-19T20:01:21.401Z',
          updatedAt: '2022-08-19T20:01:21.401Z',
        },
        {
          id: 571,
          senderId: 5,
          content: 'sd',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-19T20:01:30.142Z',
          updatedAt: '2022-08-19T20:01:30.142Z',
        },
        {
          id: 572,
          senderId: 66,
          content: 'asdfasdf',
          media: null,
          deletedBy: null,
          isSeen: false,
          createdAt: '2022-08-19T20:01:48.877Z',
          updatedAt: '2022-08-19T20:01:48.877Z',
        },
      ],
    },
    {
      id: 166,
      user_1: 84,
      user_2: 5,
      createdAt: '2022-08-16T14:10:54.548Z',
      updatedAt: '2022-08-16T14:10:54.548Z',
      messages: [],
    },
  ],
  propertyLike: 2,
  propertyView: 1,
};
