import React, { useState, useEffect } from "react";
import { getPropertyByIdApi } from "../../../services/properties/getProperty";
import { useParams } from "react-router";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Rating,
  Typography,
} from "@mui/material";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import { TextField } from "@mui/material";
import { Toast } from "primereact/toast";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Buttons from "../../../components/form/button";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import NightlightRoundIcon from "@mui/icons-material/NightlightRound";
import GroupIcon from "@mui/icons-material/Group";
import PaymentIcon from "@mui/icons-material/Payment";
import DiscountIcon from "@mui/icons-material/Discount";
import PaymentsIcon from "@mui/icons-material/Payments";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import { getCancellationPolicyById } from "../../../services/properties/getCancellationPolicy";
import getUserCardApi from "../../../services/User/getUserCard";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import { useFormik } from "formik";
import { bookingByStripeApi } from "../../../services/booking/bookingByStripe";
import { addBookingApi } from "../../../services/booking/addBooking";
import Select from "react-select";
// Icons
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PetsIcon from "@mui/icons-material/Pets";
import ChildCareIcon from "@mui/icons-material/ChildCare";
import { FaCcMastercard, FaCcVisa } from "react-icons/fa";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { getUserWalletApi } from "../../../services/wallet/getUserWallet";
import { options } from "./../../../constants/countries";
import { calculateBookingPriceApi } from "../../../services/booking/calculateBookingPrice";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import { getGSTValueApi } from "../../../services/system/getGSTValue";
import { getServiceValueApi } from "../../../services/system/getServiceFeeValue";
import Dialouge from "../../../components/modal/CancellationModal";
import * as yup from "yup";
import { confirmPaymentAndAddBookingApi } from "../../../services/booking/confirmPaymentAndAddBooking";
import DatePickers from "../../../components/form/datePicker";
import DatePickersBookingCheckout from "../../../components/form/datePickerForEditDatesBookingCheckout";
import { GuestCount } from "../../../components/shared/GuestCount";
import { ModifyBookingGuestCount } from "../../../components/shared/ModifyBookingGuestCount";
import { BookingCheckoutGuestCount } from "../../../components/shared/BookingCheckoutGuestCount";
import { updateBookingApi } from "../../../services/booking/updateBookingStatus";

const BookingCheckout = () => {
  //States
  const [property, setProperty] = useState({});
  const [useCreditCard, setUseCreditCard] = useState(false);
  const [editDates, setEditDates] = useState(false);
  const [editGuests, setEditGuests] = useState(false);
  const [cancellationPolicy, setCancellationPolicy] = useState({});
  const [walletLessAmountError, setWalletLessAmountError] = useState(false);
  const [isLoadingConfirmAndPay, setIsLoadingConfirmAndPay] = useState(false);
  const [showDialogSaveAndPayLater, setShowDialogSaveAndPayLater] =
    useState(false);
  const [showDialogConfirmAndPay, setShowDialogConfirmAndPay] = useState(false);
  const [showDialogSendBookingRequest, setShowDialogSendBookingRequest] =
    useState(false);
  const [showDialogRejectInvitation, setShowDialogRejectInvitation] =
    useState(false);
  const [showDialogAcceptInvitation, setShowDialogAcceptInvitation] =
    useState(false);
  const [isLoadingSaveAndPayLater, setIsLoadingSaveAndPayLater] =
    useState(false);
  const [isLoadingSendBookingRequest, setIsLoadingSendBookingRequest] =
    useState(false);
  const [useWallet, setUseWallet] = useState(true);
  const [userCards, setUserCards] = useState([]);
  const [policyCheck, setPolicyCheck] = useState(false);
  const [card, setCard] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [wallet, setWallet] = useState({});
  const [values, setValues] = useState({});
  const [gst, setGST] = React.useState(0);
  const [serviceFee, setServiceFee] = React.useState(0);
  //coupons
  const [couponText, setCouponText] = useState("");
  const [couponCode, setCouponCode] = useState("");
  //Scroll to top on render
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  //Getting the search url
  const search = window.location.search;

  //User Data from local storage
  const userData = JSON.parse(localStorage.getItem("userData")) || null;

  // Booking Info
  const startDate = new URLSearchParams(search).get("startDate");
  const endDate = new URLSearchParams(search).get("endDate");
  const checkInTime = new URLSearchParams(search).get("checkInTime");
  const checkOutTime = new URLSearchParams(search).get("checkOutTime");

  // Query Parametres Get
  const noOfGuests = new URLSearchParams(search).get("noOfGuests");
  const noOfPets = new URLSearchParams(search).get("noOfPets");
  const noOfInfants = new URLSearchParams(search).get("noOfInfants");
  const noOfAdults = new URLSearchParams(search).get("noOfAdults");
  const noOfChildren = new URLSearchParams(search).get("noOfChildren");
  const ownerId = new URLSearchParams(search).get("ownerId");
  const bookingId = new URLSearchParams(search).get("bookingId");
  const code = new URLSearchParams(search).get("code");
  const { id } = useParams();
  const toast = React.useRef();

  //Navigate Function Setup
  const navigate = useNavigate();

  //Check if active card is empty
  const noActiveCard = Object.keys(card).length === 0;

  //Get Property Details Call
  useEffect(() => {
    const getProperty = async () => {
      const prp = await getPropertyByIdApi(id);
      getCancellationPolicy(prp.data.data.id);
      setProperty(prp.data.data);
    };
    getProperty();
  }, [id]);

  //Coupon Dialog Handle Open & close functions
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //Get Cancellation Policy API call
  const getCancellationPolicy = async (id) => {
    const cp = await getCancellationPolicyById(id);
    setCancellationPolicy(cp);
  };

  //Get User Cards
  useEffect(() => {
    const getUserCards = async () => {
      const res = await getUserCardApi(userData?.id);
      let temp = [];
      res?.data.map((item) => {
        return temp.push({
          value: item,
          label: item.last4digit,
          brand: item.brand,
        });
      });
      setUserCards(temp);
    };
    getUserCards();
  }, [userData?.id]);

  //Check If the property includes instant booking.
  const isInstantBook = property?.instantBooking;

  //FORMIK
  const formik = useFormik({
    initialValues: {
      number: "",
      name: "",
      exp_month: "",
      exp_year: "",
      cvc: "",
      user_id: userData?.id,
      use_save_card: false,
      address1: "",
      address2: "",
      city: "",
      state: "",
      zipcode: "",
      country: "",
    },
    enableReinitialize: true,
    ...(noActiveCard &&
      useCreditCard && {
        validationSchema: () => {
          return yup.object({
            name: yup.string().required("Please enter your name!"),
            number: yup
              .string()
              .min(16, "Card number must be only 16 characters")
              .max(16, "Card number must be only 16 characters")
              .required("Please enter your card number!"),
            exp_month: yup
              .string()
              .required("Please enter your card expiry month!"),
            exp_year: yup
              .string()
              .required("Please enter your card expiry year!"),
            cvc: yup
              .string()
              .min(3, "CVC must be at least 3 characters!")
              .max(4, "CVC can be only 4 characters!")
              .required("Please enter your card CVC!"),
            address1: yup.string().required("Street Address is required!"),
            address2: yup.string().required("Apt or suite number is required!"),
            city: yup.string().required("City is required!"),
            zipcode: yup.string().required("Zip Code is required!"),
            state: yup.string().required("State is required!"),
          });
        },
      }),
    onSubmit: async () => {
      if (!useWallet && !useCreditCard && noActiveCard) {
        setShowDialogConfirmAndPay(false);
        toast.current.show({
          severity: "info",
          summary: "No payment method selected",
          detail: "Please select a payment method to proceed your booking!",
          life: 10000,
        });
      } else {
        //Cancellation policy terms check and condition for creating booking if not exists!
        if (policyCheck) {
          bookingByStripe();
        } else {
          setShowDialogConfirmAndPay(false);
          toast.current.show({
            severity: "info",
            summary: "You are not agreed to cancellation policy!",
            detail: "Please accept the cancellation policy terms to proceed.",
            life: 10000,
          });
        }
      }
    },
  });

  //For creating new booking and charging the user!
  const bookingByStripe = async () => {
    //If user only wants to be charged through wallet
    if (useWallet && noActiveCard && !useCreditCard) {
      setIsLoadingConfirmAndPay(true);
      const response = await confirmPaymentAndAddBookingApi({
        user_id: formik.values.user_id,
        paymentMethod: "WALLET",
        ownerId: property?.user?.id,
        guestId: parseInt(userData?.id),
        propertyId: parseInt(id),
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
        noOfGuest: parseInt(noOfGuests),
        noOfAdults: parseInt(noOfAdults),
        noOfChildren: parseInt(noOfChildren),
        noOfInfants: parseInt(noOfInfants),
        noOfPets: parseInt(noOfPets),
        bookingType: "NORMAL",
      });
      if (response.status === 200) {
        setIsLoadingConfirmAndPay(false);
        setShowDialogConfirmAndPay(false);
        toast.current.show({
          severity: "success",
          summary: "Booking Done",
          detail: "Your booking is created successfully!",
          life: 10000,
        });
        setTimeout(() => {
          navigate(`/booking-done/${response?.data?.data?.id}`);
        }, 2000);
      } else {
        setIsLoadingConfirmAndPay(false);
        setShowDialogConfirmAndPay(false);
        toast.current.show({
          severity: "error",
          summary: "Something's wrong!",
          detail: `${response?.data?.error?.error}`,
          life: 10000,
        });
      }
    } else if (noActiveCard && !useWallet && !useCreditCard) {
      setIsLoadingConfirmAndPay(false);
      setShowDialogConfirmAndPay(false);
      toast.current.show({
        severity: "error",
        summary: "No Payment method selected!",
        detail: "Please select a payment method to continue.",
        life: 10000,
      });
    }
    //If user only wants to be charged through exisiting card
    else if (card && !useWallet && !useCreditCard) {
      setIsLoadingConfirmAndPay(true);
      const response = await confirmPaymentAndAddBookingApi({
        user_id: formik.values.user_id,
        card_id: card?.value?.id,
        paymentMethod: "CARD",
        ownerId: property?.user?.id,
        guestId: parseInt(userData?.id),
        propertyId: parseInt(id),
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
        noOfGuest: parseInt(noOfGuests),
        noOfAdults: parseInt(noOfAdults),
        noOfChildren: parseInt(noOfChildren),
        noOfInfants: parseInt(noOfInfants),
        noOfPets: parseInt(noOfPets),
        bookingType: "NORMAL",
      });
      if (response.status === 200) {
        setIsLoadingConfirmAndPay(false);
        setShowDialogConfirmAndPay(false);
        toast.current.show({
          severity: "success",
          summary: "Booking Done",
          detail: "Your booking is created successfully!",
          life: 10000,
        });
        setTimeout(() => {
          navigate(`/booking-done/${response?.data?.data?.id}`);
        }, 2000);
      } else {
        setIsLoadingConfirmAndPay(false);
        setShowDialogConfirmAndPay(false);
        toast.current.show({
          severity: "error",
          summary: "Something's wrong!",
          detail: `${response?.data?.error?.error}`,
          life: 10000,
        });
      }
    }
    //If user only wants to be charged by adding new card
    else if (useCreditCard && !useWallet && noActiveCard) {
      setIsLoadingConfirmAndPay(true);
      const response = await confirmPaymentAndAddBookingApi({
        name: formik.values.name,
        number: formik.values.number,
        exp_month: formik.values.exp_month,
        exp_year: formik.values.exp_year,
        cvc: formik.values.cvc,
        user_id: formik.values.user_id,
        address1: formik.values.address1,
        address2: formik.values.address2,
        city: formik.values.city,
        state: formik.values.state,
        zipcode: formik.values.zipcode,
        country: formik.values.country?.name,
        paymentMethod: "CARD",
        ownerId: property?.user?.id,
        guestId: parseInt(userData?.id),
        propertyId: parseInt(id),
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
        noOfGuest: parseInt(noOfGuests),
        noOfAdults: parseInt(noOfAdults),
        noOfChildren: parseInt(noOfChildren),
        noOfInfants: parseInt(noOfInfants),
        noOfPets: parseInt(noOfPets),
        bookingType: "NORMAL",
      });
      if (response.status === 200) {
        setIsLoadingConfirmAndPay(false);
        setShowDialogConfirmAndPay(false);
        toast.current.show({
          severity: "success",
          summary: "Booking Done",
          detail: "Your booking is created successfully!",
          life: 10000,
        });
        setTimeout(() => {
          navigate(`/booking-done/${response?.data?.data?.id}`);
        }, 2000);
      } else {
        setIsLoadingConfirmAndPay(false);
        setShowDialogConfirmAndPay(false);
        toast.current.show({
          severity: "error",
          summary: "Something's wrong!",
          detail: `${response?.data?.error?.error}`,
          life: 10000,
        });
      }
    }
    //If user wants to be charged by through wallet + exisiting card
    else if (useWallet && Object.keys(card).length > 0 && !useCreditCard) {
      setIsLoadingConfirmAndPay(true);
      const response = await confirmPaymentAndAddBookingApi({
        user_id: formik.values.user_id,
        card_id: card?.value?.id,
        paymentMethod: "CARD_PLUS_WALLET",
        bookingType: "NORMAL",
        ownerId: property?.user?.id,
        guestId: parseInt(userData?.id),
        propertyId: parseInt(id),
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
        noOfGuest: parseInt(noOfGuests),
        noOfAdults: parseInt(noOfAdults),
        noOfChildren: parseInt(noOfChildren),
        noOfInfants: parseInt(noOfInfants),
        noOfPets: parseInt(noOfPets),
      });
      if (response.status === 200) {
        setIsLoadingConfirmAndPay(false);
        setShowDialogConfirmAndPay(false);
        toast.current.show({
          severity: "success",
          summary: "Booking Done",
          detail: "Your booking is created successfully!",
          life: 10000,
        });
        setTimeout(() => {
          navigate(`/booking-done/${response?.data?.data?.id}`);
        }, 2000);
      } else {
        setIsLoadingConfirmAndPay(false);
        setShowDialogConfirmAndPay(false);
        toast.current.show({
          severity: "error",
          summary: "Something's wrong!",
          detail: `${response?.data?.error?.error}`,
          life: 10000,
        });
      }
    }
    //If user wants to be charged through wallet + by adding new card
    else if (useWallet && useCreditCard && noActiveCard) {
      setIsLoadingConfirmAndPay(true);
      const response = await confirmPaymentAndAddBookingApi({
        name: formik.values.name,
        number: formik.values.number,
        exp_month: formik.values.exp_month,
        exp_year: formik.values.exp_year,
        cvc: formik.values.cvc,
        user_id: formik.values.user_id,
        paymentMethod: "CARD_PLUS_WALLET",
        address1: formik.values.address1,
        address2: formik.values.address2,
        city: formik.values.city,
        state: formik.values.state,
        zipcode: formik.values.zipcode,
        country: formik.values.country?.name,
        ownerId: property?.user?.id,
        guestId: parseInt(userData?.id),
        propertyId: parseInt(id),
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
        noOfGuest: parseInt(noOfGuests),
        noOfAdults: parseInt(noOfAdults),
        noOfChildren: parseInt(noOfChildren),
        noOfInfants: parseInt(noOfInfants),
        noOfPets: parseInt(noOfPets),
        bookingType: "NORMAL",
      });
      if (response.status === 200) {
        setIsLoadingConfirmAndPay(false);
        setShowDialogConfirmAndPay(false);
        toast.current.show({
          severity: "success",
          summary: "Booking Done",
          detail: "Your booking is created successfully!",
          life: 10000,
        });
        setTimeout(() => {
          navigate(`/booking-done/${response?.data?.data?.id}`);
        }, 2000);
      } else {
        setIsLoadingConfirmAndPay(false);
        setShowDialogConfirmAndPay(false);
        toast.current.show({
          severity: "error",
          summary: "Something's wrong!",
          detail: `${response?.data?.error?.error}`,
          life: 10000,
        });
      }
    }
  };

  //Save and Pay later (creates a booking only) function
  const createBookingAndSaveForLater = async () => {
    if (!useWallet && !useCreditCard && noActiveCard) {
      setShowDialogSaveAndPayLater(false);
      toast.current.show({
        severity: "info",
        summary: "No payment method selected",
        detail: "Please select a payment method to proceed your booking!",
        life: 10000,
      });
    } else {
      if (policyCheck) {
        setIsLoadingSaveAndPayLater(true);
        const res = await addBookingApi(
          {
            guestId: parseInt(userData?.id),
            propertyId: parseInt(id),
            startDate: moment(startDate),
            endDate: moment(endDate),
            noOfGuest: parseInt(noOfGuests),
            noOfAdults: parseInt(noOfAdults),
            noOfChildren: parseInt(noOfChildren),
            noOfInfants: parseInt(noOfInfants),
            noOfPets: parseInt(noOfPets),
            ownerId: property?.user?.id,
          },
          parseInt(ownerId)
        );
        if (res.status === 200) {
          setIsLoadingSaveAndPayLater(false);
          setShowDialogSaveAndPayLater(false);
          toast.current.show({
            severity: "success",
            summary: "Booking Created and Saved!",
            detail: "Booking Created and saved for later!",
            life: 10000,
          });
          setTimeout(() => {
            navigate(`/booking-done/${res?.data?.data?.id}`);
          }, 2000);
        } else {
          setIsLoadingSaveAndPayLater(false);
          setShowDialogSaveAndPayLater(false);
          toast.current.show({
            severity: "error",
            summary: "Something's wrong!",
            detail: `${res?.data?.error?.error}`,
            life: 10000,
          });
        }
      } else {
        setShowDialogSaveAndPayLater(false);
        toast.current.show({
          severity: "info",
          summary: "You are not agreed to cancellation policy!",
          detail: "Please accept the cancellation policy terms to proceed.",
          life: 10000,
        });
      }
    }
  };
  //Send request to the Host and doesnt charge the user!
  const sendBookingRequestToTheHost = async () => {
    if (!useWallet && !useCreditCard && noActiveCard) {
      setShowDialogSendBookingRequest(false);
      toast.current.show({
        severity: "info",
        summary: "No payment method selected",
        detail: "Please select a payment method to proceed your booking!",
        life: 10000,
      });
    } else {
      if (policyCheck) {
        //If user only wants to be charged through wallet
        if (useWallet && noActiveCard && !useCreditCard) {
          setIsLoadingSendBookingRequest(true);
          const response = await addBookingApi(
            {
              user_id: formik.values.user_id,
              paymentMethod: "WALLET",
              ownerId: property?.user?.id,
              guestId: parseInt(userData?.id),
              propertyId: parseInt(id),
              startDate: moment(startDate).format("YYYY-MM-DD"),
              endDate: moment(endDate).format("YYYY-MM-DD"),
              noOfGuest: parseInt(noOfGuests),
              noOfAdults: parseInt(noOfAdults),
              noOfChildren: parseInt(noOfChildren),
              noOfInfants: parseInt(noOfInfants),
              noOfPets: parseInt(noOfPets),
              bookingType: "NORMAL",
            },
            property?.user?.id
          );
          if (response.status === 200) {
            setIsLoadingConfirmAndPay(false);
            setShowDialogSendBookingRequest(false);
            toast.current.show({
              severity: "success",
              summary: "Booking Done",
              detail: "Your booking is created successfully!",
              life: 10000,
            });
            setTimeout(() => {
              navigate(`/booking-done/${response?.data?.data?.id}`);
            }, 2000);
          } else {
            setIsLoadingSendBookingRequest(false);
            setShowDialogSendBookingRequest(false);
            toast.current.show({
              severity: "error",
              summary: "Something's wrong!",
              detail: `${response?.data?.error?.error}`,
              life: 10000,
            });
          }
        } else if (noActiveCard && !useWallet && !useCreditCard) {
          setIsLoadingConfirmAndPay(false);
          setShowDialogSendBookingRequest(false);
          toast.current.show({
            severity: "error",
            summary: "No Payment method selected!",
            detail: "Please select a payment method to continue.",
            life: 10000,
          });
        }
        //If user only wants to be charged through exisiting card
        else if (card && !useWallet && !useCreditCard) {
          setIsLoadingSendBookingRequest(true);
          const response = await addBookingApi(
            {
              user_id: formik.values.user_id,
              card_id: card?.value?.id,
              paymentMethod: "CARD",
              ownerId: property?.user?.id,
              guestId: parseInt(userData?.id),
              propertyId: parseInt(id),
              startDate: moment(startDate).format("YYYY-MM-DD"),
              endDate: moment(endDate).format("YYYY-MM-DD"),
              noOfGuest: parseInt(noOfGuests),
              noOfAdults: parseInt(noOfAdults),
              noOfChildren: parseInt(noOfChildren),
              noOfInfants: parseInt(noOfInfants),
              noOfPets: parseInt(noOfPets),
              bookingType: "NORMAL",
            },
            property?.user?.id
          );
          if (response.status === 200) {
            setIsLoadingSendBookingRequest(false);
            setShowDialogSendBookingRequest(false);
            toast.current.show({
              severity: "success",
              summary: "Booking Done",
              detail: "Your booking is created successfully!",
              life: 10000,
            });
            setTimeout(() => {
              navigate(`/booking-done/${response?.data?.data?.id}`);
            }, 2000);
          } else {
            setIsLoadingSendBookingRequest(false);
            setShowDialogSendBookingRequest(false);
            toast.current.show({
              severity: "error",
              summary: "Something's wrong!",
              detail: `${response?.data?.error?.error}`,
              life: 10000,
            });
          }
        }
        //If user only wants to be charged by adding new card
        else if (useCreditCard && !useWallet && noActiveCard) {
          setIsLoadingSendBookingRequest(true);
          const response = await addBookingApi(
            {
              name: formik.values.name,
              number: formik.values.number,
              exp_month: formik.values.exp_month,
              exp_year: formik.values.exp_year,
              cvc: formik.values.cvc,
              user_id: formik.values.user_id,
              address1: formik.values.address1,
              address2: formik.values.address2,
              city: formik.values.city,
              state: formik.values.state,
              zipcode: formik.values.zipcode,
              country: formik.values.country?.name,
              paymentMethod: "CARD",
              ownerId: property?.user?.id,
              guestId: parseInt(userData?.id),
              propertyId: parseInt(id),
              startDate: moment(startDate).format("YYYY-MM-DD"),
              endDate: moment(endDate).format("YYYY-MM-DD"),
              noOfGuest: parseInt(noOfGuests),
              noOfAdults: parseInt(noOfAdults),
              noOfChildren: parseInt(noOfChildren),
              noOfInfants: parseInt(noOfInfants),
              noOfPets: parseInt(noOfPets),
              bookingType: "NORMAL",
            },
            property?.user?.id
          );
          if (response.status === 200) {
            setIsLoadingSendBookingRequest(false);
            setShowDialogSendBookingRequest(false);
            toast.current.show({
              severity: "success",
              summary: "Booking Done",
              detail: "Your booking is created successfully!",
              life: 10000,
            });
            setTimeout(() => {
              navigate(`/booking-done/${response?.data?.data?.id}`);
            }, 2000);
          } else {
            setIsLoadingSendBookingRequest(false);
            setShowDialogSendBookingRequest(false);
            toast.current.show({
              severity: "error",
              summary: "Something's wrong!",
              detail: `${response?.data?.error?.error}`,
              life: 10000,
            });
          }
        }
        //If user wants to be charged by through wallet + exisiting card
        else if (useWallet && Object.keys(card).length > 0 && !useCreditCard) {
          setIsLoadingSendBookingRequest(true);
          const response = await addBookingApi(
            {
              user_id: formik.values.user_id,
              card_id: card?.value?.id,
              paymentMethod: "CARD_PLUS_WALLET",
              bookingType: "NORMAL",
              ownerId: property?.user?.id,
              guestId: parseInt(userData?.id),
              propertyId: parseInt(id),
              startDate: moment(startDate).format("YYYY-MM-DD"),
              endDate: moment(endDate).format("YYYY-MM-DD"),
              noOfGuest: parseInt(noOfGuests),
              noOfAdults: parseInt(noOfAdults),
              noOfChildren: parseInt(noOfChildren),
              noOfInfants: parseInt(noOfInfants),
              noOfPets: parseInt(noOfPets),
            },
            property?.user?.id
          );
          if (response.status === 200) {
            setIsLoadingSendBookingRequest(false);
            setShowDialogSendBookingRequest(false);
            toast.current.show({
              severity: "success",
              summary: "Booking Done",
              detail: "Your booking is created successfully!",
              life: 10000,
            });
            setTimeout(() => {
              navigate(`/booking-done/${response?.data?.data?.id}`);
            }, 2000);
          } else {
            setIsLoadingSendBookingRequest(false);
            setShowDialogSendBookingRequest(false);
            toast.current.show({
              severity: "error",
              summary: "Something's wrong!",
              detail: `${response?.data?.error?.error}`,
              life: 10000,
            });
          }
        }
        //If user wants to be charged through wallet + by adding new card
        else if (useWallet && useCreditCard && noActiveCard) {
          setIsLoadingSendBookingRequest(true);
          const response = await addBookingApi(
            {
              name: formik.values.name,
              number: formik.values.number,
              exp_month: formik.values.exp_month,
              exp_year: formik.values.exp_year,
              cvc: formik.values.cvc,
              user_id: formik.values.user_id,
              paymentMethod: "CARD_PLUS_WALLET",
              address1: formik.values.address1,
              address2: formik.values.address2,
              city: formik.values.city,
              state: formik.values.state,
              zipcode: formik.values.zipcode,
              country: formik.values.country?.name,
              ownerId: property?.user?.id,
              guestId: parseInt(userData?.id),
              propertyId: parseInt(id),
              startDate: moment(startDate).format("YYYY-MM-DD"),
              endDate: moment(endDate).format("YYYY-MM-DD"),
              noOfGuest: parseInt(noOfGuests),
              noOfAdults: parseInt(noOfAdults),
              noOfChildren: parseInt(noOfChildren),
              noOfInfants: parseInt(noOfInfants),
              noOfPets: parseInt(noOfPets),
              bookingType: "NORMAL",
            },
            property?.user?.id
          );
          if (response.status === 200) {
            setIsLoadingSendBookingRequest(false);
            setShowDialogSendBookingRequest(false);
            toast.current.show({
              severity: "success",
              summary: "Booking Done",
              detail: "Your booking is created successfully!",
              life: 10000,
            });
            setTimeout(() => {
              navigate(`/booking-done/${response?.data?.data?.id}`);
            }, 2000);
          } else {
            setIsLoadingSendBookingRequest(false);
            setShowDialogSendBookingRequest(false);
            toast.current.show({
              severity: "error",
              summary: "Something's wrong!",
              detail: `${response?.data?.error?.error}`,
              life: 10000,
            });
          }
        }
      } else {
        setShowDialogSendBookingRequest(false);
        toast.current.show({
          severity: "info",
          summary: "You are not agreed to cancellation policy!",
          detail: "Please accept the cancellation policy terms to proceed.",
          life: 10000,
        });
      }
    }
  };

  //Get User Wallet Api
  useEffect(() => {
    const getWallet = async () => {
      const res = await getUserWalletApi(userData?.id);
      if (res.status === 404 || res.status === 401 || res.status === 403) {
        setWallet({});
      } else {
        setWallet(res);
      }
    };
    getWallet();
  }, [userData?.id]);

  //Set use wallet to false if wallet doesnt exist or wallet amount is 0
  useEffect(() => {
    if (parseInt(wallet?.amount ? wallet?.amount : 0) <= 0) {
      setUseWallet(false);
    } else if (parseInt(wallet?.amount ? wallet?.amount : 0) > 0) {
      setUseWallet(true);
    }
  }, [wallet?.amount]);

  //Country options dropdown templates
  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className='country-item country-item-value'>
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className='country-item'>
        <div>{option.name}</div>
      </div>
    );
  };

  //Check If User wallet amount is less than total amount and show prompt
  useEffect(() => {
    if (parseInt(wallet?.amount ? wallet?.amount : 0) < values?.grossAmount) {
      setWalletLessAmountError(
        "Your wallet has insufficient balance for this payment. Use a card instead or select a card to pay the rest of the amount."
      );
    } else {
      setWalletLessAmountError("");
    }
  }, [wallet?.amount, values?.grossAmount]);

  //Set wallet usage func
  const setUseWalletFunc = () => {
    if (wallet && parseInt(wallet?.amount) > 0) {
      setUseWallet(!useWallet);
    } else {
      setWalletLessAmountError(
        "You dont have enough balance in wallet! Please consider topping up the wallet!"
      );
    }
  };

  //set credit card add usage
  const setUseCreditCardFunc = () => {
    setUseCreditCard(!useCreditCard);
    setCard({});
  };

  //Calculate booking prices
  React.useEffect(() => {
    const getBookingCalculatedPrcie = async () => {
      const response = await calculateBookingPriceApi({
        propertyId: id,
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
        noOfAdults: parseInt(noOfAdults),
        noOfChildren: parseInt(noOfChildren),
        noOfInfants: parseInt(noOfInfants),
        noOfPets: parseInt(noOfPets),
        guestId: userData?.id,
        validate: false,
        ...(couponCode !== "" && { couponCode: couponCode }),
      });
      setValues(response.data.data);
    };
    getBookingCalculatedPrcie();
  }, [
    id,
    noOfAdults,
    noOfPets,
    noOfChildren,
    noOfInfants,
    startDate,
    endDate,
    couponCode,
    userData?.id,
  ]);

  //Custom styles for dropdown
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: "black",
      fontSize: "16px",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      const color = "#fff";
      return { ...provided, opacity, transition, color };
    },
  };

  //Get and set GST & serviceFee Value from server
  React.useEffect(() => {
    const getGSTValue = async () => {
      let gstVal = await getGSTValueApi();
      setGST(gstVal);
    };
    const getServiceFeeValue = async () => {
      let serviceFeeVal = await getServiceValueApi();
      setServiceFee(serviceFeeVal);
    };
    getGSTValue();
    getServiceFeeValue();
  }, []);

  const editFormik = useFormik({
    initialValues: {
      startDate: startDate,
      endDate: endDate,
      noOfAdults: parseInt(noOfAdults),
      noOfChildren: parseInt(noOfChildren),
      noOfGuests: parseInt(noOfGuests),
      noOfPets: parseInt(noOfPets),
      noOfInfants: parseInt(noOfInfants),
    },
    validationSchema: yup.object({
      startDate: yup.string().required("start date is required."),
      endDate: yup.string().required("end date is required."),
      noOfGuests: yup
        .number()
        .min(1)
        .required("Please select number of guests!"),
    }),
    enableReinitialize: true,
    onSubmit: (values) => {
      navigate(
        `/book/${property?.id}?startDate=${values.startDate}&endDate=${values.endDate}&noOfGuests=${values.noOfGuests}&noOfInfants=${values.noOfInfants}&noOfPets=${values.noOfPets}&noOfAdults=${values.noOfAdults}&noOfChildren=${values.noOfChildren}&checkInTime=${checkInTime}&ownerId=${property?.user?.id}&propertyAddress=${property?.address}&code=${property?.code}&checkOutTime=${checkOutTime}`
      );
      setEditDates(false);
      setEditGuests(false);
    },
  });

  const handleGuestCountChange = async (value, count) => {
    editFormik.setFieldValue("noOfGuests", value);
    editFormik.setFieldValue("noOfAdults", count.adults);
    editFormik.setFieldValue("noOfChildren", count.children);
    editFormik.setFieldValue("noOfInfants", count.infants);
    editFormik.setFieldValue("noOfPets", count.pets);
  };

  // reject booking invitation
  const rejectBookingInvitation = async () => {
    const response = await updateBookingApi(bookingId, {
      bookingStatus: "rejected",
    });
    if (response.status === 200) {
      setShowDialogRejectInvitation(false);
      toast.current.show({
        severity: "info",
        summary: "Booking Invitation Rejected",
        detail: "PBooking Invitation Rejected",
        life: 10000,
      });
      setTimeout(() => {
        navigate("/");
      }, 1500);
    } else {
      setShowDialogRejectInvitation(false);
      toast.current.show({
        severity: "error",
        summary: "Cannot reject booking invitation request",
        detail: "Cannot reject booking invitation request",
        life: 10000,
      });
    }
  };
  // reject booking invitation
  const acceptBookingInvitation = async () => {
    if (policyCheck) {
      //If user only wants to be charged through wallet
      if (useWallet && noActiveCard && !useCreditCard) {
        const response = await updateBookingApi(bookingId, {
          user_id: formik.values.user_id,
          paymentMethod: "WALLET",
          bookingStatus: "accepted",
        });
        if (response.status === 200) {
          setShowDialogAcceptInvitation(false);
          toast.current.show({
            severity: "success",
            summary: "Booking Done",
            detail: "Your booking is created successfully!",
            life: 10000,
          });
          setTimeout(() => {
            navigate(`/booking-done/${response?.data?.id}`);
          }, 2000);
        } else {
          setShowDialogAcceptInvitation(false);

          toast.current.show({
            severity: "error",
            summary: "Something's wrong!",
            detail: "Sorry couldn't create your booking!",
            life: 10000,
          });
        }
      } else if (noActiveCard && !useWallet && !useCreditCard) {
        setShowDialogAcceptInvitation(false);

        toast.current.show({
          severity: "error",
          summary: "No Payment method selected!",
          detail: "Please select a payment method to continue.",
          life: 10000,
        });
      }
      //If user only wants to be charged through exisiting card
      else if (card && !useWallet && !useCreditCard) {
        const response = await updateBookingApi(bookingId, {
          user_id: formik.values.user_id,
          card_id: card?.value?.id,
          paymentMethod: "CARD",
          bookingStatus: "accepted",
        });
        if (response.status === 200) {
          setShowDialogAcceptInvitation(false);

          toast.current.show({
            severity: "success",
            summary: "Booking Done",
            detail: "Your booking is created successfully!",
            life: 10000,
          });
          setTimeout(() => {
            navigate(`/booking-done/${response?.data?.id}`);
          }, 2000);
        } else {
          setShowDialogAcceptInvitation(false);

          toast.current.show({
            severity: "error",
            summary: "Something's wrong!",
            detail: "Sorry couldn't create your booking!",
            life: 10000,
          });
        }
      }
      //If user only wants to be charged by adding new card
      else if (useCreditCard && !useWallet && noActiveCard) {
        const response = await updateBookingApi(bookingId, {
          name: formik.values.name,
          number: formik.values.number,
          exp_month: formik.values.exp_month,
          exp_year: formik.values.exp_year,
          cvc: formik.values.cvc,
          user_id: formik.values.user_id,
          address1: formik.values.address1,
          address2: formik.values.address2,
          city: formik.values.city,
          state: formik.values.state,
          zipcode: formik.values.zipcode,
          country: formik.values.country?.name,
          paymentMethod: "CARD",
          bookingStatus: "accepted",
        });
        if (response.status === 200) {
          setShowDialogAcceptInvitation(false);

          toast.current.show({
            severity: "success",
            summary: "Booking Done",
            detail: "Your booking is created successfully!",
            life: 10000,
          });
          setTimeout(() => {
            navigate(`/booking-done/${response?.data?.id}`);
          }, 2000);
        } else {
          setShowDialogAcceptInvitation(false);

          toast.current.show({
            severity: "error",
            summary: "Something's wrong!",
            detail: "Sorry couldn't create your booking!",
            life: 10000,
          });
        }
      }
      //If user wants to be charged by through wallet + exisiting card
      else if (useWallet && Object.keys(card).length > 0 && !useCreditCard) {
        const response = await updateBookingApi(bookingId, {
          user_id: formik.values.user_id,
          card_id: card?.value?.id,
          paymentMethod: "CARD_PLUS_WALLET",
          bookingStatus: "accepted",
        });
        if (response.status === 200) {
          setShowDialogAcceptInvitation(false);

          toast.current.show({
            severity: "success",
            summary: "Booking Done",
            detail: "Your booking is created successfully!",
            life: 10000,
          });
          setTimeout(() => {
            navigate(`/booking-done/${response?.data?.id}`);
          }, 2000);
        } else {
          setShowDialogAcceptInvitation(false);

          toast.current.show({
            severity: "error",
            summary: "Something's wrong!",
            detail: "Sorry couldn't create your booking!",
            life: 10000,
          });
        }
      }
      //If user wants to be charged through wallet + by adding new card
      else if (useWallet && useCreditCard && noActiveCard) {
        const response = await updateBookingApi(bookingId, {
          name: formik.values.name,
          number: formik.values.number,
          exp_month: formik.values.exp_month,
          exp_year: formik.values.exp_year,
          cvc: formik.values.cvc,
          user_id: formik.values.user_id,
          paymentMethod: "CARD_PLUS_WALLET",
          address1: formik.values.address1,
          address2: formik.values.address2,
          city: formik.values.city,
          state: formik.values.state,
          zipcode: formik.values.zipcode,
          country: formik.values.country?.name,
          bookingStatus: "accepted",
        });
        if (response.status === 200) {
          setShowDialogAcceptInvitation(false);

          toast.current.show({
            severity: "success",
            summary: "Booking Done",
            detail: "Your booking is created successfully!",
            life: 10000,
          });
          setTimeout(() => {
            navigate(`/booking-done/${response?.data?.id}`);
          }, 2000);
        } else {
          setShowDialogAcceptInvitation(false);

          toast.current.show({
            severity: "error",
            summary: "Something's wrong!",
            detail: "Sorry couldn't create your booking!",
            life: 10000,
          });
        }
      }
    } else {
      toast.current.show({
        severity: "info",
        summary: "You are not agreed to cancellation policy!",
        detail: "Please accept the cancellation policy terms to proceed.",
        life: 10000,
      });
    }
  };

  React.useEffect(() => {
    if (!values?.validCoupon) {
      setTimeout(() => {
        setCouponCode("");
        setCouponText("");
      }, 10000);
    }
  }, [values?.validCoupon]);
  return (
    <div className='main'>
      <Toast ref={toast} position='bottom-right' />
      <ConfirmationDialog
        message='Are you sure you want to proceed?'
        header='Confirmation'
        icon='pi pi-exclamation-triangle'
        open={showDialogSaveAndPayLater}
        onHide={() => setShowDialogSaveAndPayLater(false)}
        onConfirm={createBookingAndSaveForLater}
        confirmButtonLoading={isLoadingSaveAndPayLater}
        confirmButtonColor='success'
      />
      <ConfirmationDialog
        message='Are you sure you want to proceed?'
        header='Confirmation'
        icon='pi pi-exclamation-triangle'
        open={showDialogConfirmAndPay}
        onHide={() => setShowDialogConfirmAndPay(false)}
        onConfirm={formik.handleSubmit}
        confirmButtonLoading={isLoadingConfirmAndPay}
        confirmButtonColor='success'
      />
      <ConfirmationDialog
        message='Are you sure you want to proceed?'
        header='Confirmation'
        icon='pi pi-exclamation-triangle'
        open={showDialogSendBookingRequest}
        onHide={() => setShowDialogSendBookingRequest(false)}
        onConfirm={sendBookingRequestToTheHost}
        confirmButtonLoading={isLoadingSendBookingRequest}
        confirmButtonColor='success'
      />
      <ConfirmationDialog
        message='Are you sure you want to reject the booking invitation?'
        header='Confirmation'
        icon='pi pi-exclamation-triangle'
        open={showDialogRejectInvitation}
        onHide={() => setShowDialogRejectInvitation(false)}
        onConfirm={rejectBookingInvitation}
        confirmButtonLoading={false}
      />
      <ConfirmationDialog
        message='Are you sure you want to accept the booking invitation?'
        header='Confirmation'
        icon='pi pi-exclamation-triangle'
        open={showDialogAcceptInvitation}
        onHide={() => setShowDialogAcceptInvitation(false)}
        onConfirm={acceptBookingInvitation}
        confirmButtonLoading={false}
      />
      <div className='wrapper'>
        <Grid container alignContent={"center"}>
          <Grid
            md={7}
            item
            sx={{
              padding: "50px",
            }}
          >
            <Box>
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: "25px",
                  fontFamily: "Jost",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ArrowBackIosIcon
                  sx={{
                    fontSize: "25px",
                    marginRight: "3px",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate(`/property-detail/${id}`)}
                />{" "}
                Confirm and Pay
              </Typography>
            </Box>
            {values?.errors?.length > 0 && (
              <Box
                md={12}
                lg={12}
                xl={12}
                style={{
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "center",
                  flexDirection: "column",
                  padding: "12px 25px",
                  border: "1px solid red",
                  color: "red",
                  borderRadius: "8px",
                  margin: "10px",
                  fontSize: "16px",
                }}
              >
                <ul style={{ listStyleType: "circle" }}>
                  {values?.errors?.length > 0 && (
                    <>
                      {values?.errors?.map((error, index) => {
                        return (
                          <li key={index} style={{ textAlign: "start" }}>
                            {error}
                          </li>
                        );
                      })}
                    </>
                  )}
                </ul>
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                color: "#fff",
                flexDirection: "column",
                marginLeft: "15px",
                border: "1px solid rgba(255, 255, 255, 0.1)",
                padding: "12px",
                borderRadius: "8px",
              }}
            >
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: "22px",
                  fontFamily: "Jost",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <LocationCityIcon
                  sx={{ fontSize: "22px", marginRight: "5px" }}
                />
                Your Trip Details
              </Typography>
              <Box>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "18px",
                    fontFamily: "Jost",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <CalendarMonthIcon
                    sx={{ fontSize: "18px", marginRight: "5px" }}
                  />
                  Dates
                </Typography>
                <Typography>
                  From{" "}
                  <span style={{ color: "#fff", margin: "0px 8px" }}>
                    {moment(startDate).format("DD MMM, YYYY")}
                  </span>
                  to
                  <span style={{ color: "#fff", margin: "0px 8px" }}>
                    {moment(endDate).format("DD MMM, YYYY")}
                  </span>{" "}
                  (Total {values?.noOfNights}{" "}
                  <span
                    style={{ display: "inline-flex", alignItems: "center" }}
                  >
                    nights
                    <NightlightRoundIcon
                      sx={{ fontSize: "18px", marginRight: "5px" }}
                    />
                  </span>
                  )
                  <span
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                      textDecoration: "underline",
                      color: "#fff",
                      fontWeight: "bold",
                      margin: "0px 15px",
                      cursor: "pointer",
                    }}
                    onClick={() => setEditDates(!editDates)}
                  >
                    Edit
                  </span>
                  {editDates && (
                    <>
                      <div style={{ margin: "5px 0px" }}>
                        <DatePickersBookingCheckout
                          basePrice={property.perNightPrice}
                          handlers
                          toast={toast}
                          startDate={editFormik.values.startDate}
                          propertyPricePolicy={property?.propertyPricePolicy}
                          endDate={editFormik.values.endDate}
                          propertyPolicy={property?.propertyPolicy}
                          handleDateStart={(value) =>
                            editFormik.setFieldValue("startDate", value)
                          }
                          handleDateEnd={(value) =>
                            editFormik.setFieldValue("endDate", value)
                          }
                          id={id}
                          saveChanges={editFormik.handleSubmit}
                        />
                        <small
                          id='username2-help'
                          className='p-error'
                          style={{
                            display: "block",
                            color: "red",
                            textAlign: "left",
                          }}
                        >
                          {editFormik.touched.startDate &&
                            editFormik.errors.startDate}{" "}
                          <br />
                          {editFormik.touched.endDate &&
                            editFormik.errors.endDate}
                        </small>
                      </div>
                      {/* <Buttons
                        isLoading={false}
                        title={'Save'}
                        style={{ width: '20%' }}
                        onSubmit={}
                      /> */}
                    </>
                  )}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "18px",
                    fontFamily: "Jost",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <AccessTimeFilledIcon
                    sx={{ fontSize: "18px", marginRight: "5px" }}
                  />
                  Check In
                </Typography>
                <Typography>
                  <span style={{ color: "#fff" }}>{checkInTime}</span>
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "18px",
                    fontFamily: "Jost",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <AccessTimeFilledIcon
                    sx={{ fontSize: "18px", marginRight: "5px" }}
                  />
                  Check Out
                </Typography>
                <Typography>
                  <span style={{ color: "#fff" }}>{checkOutTime}</span>{" "}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "18px",
                    fontFamily: "Jost",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <GroupIcon sx={{ fontSize: "22px", marginRight: "5px" }} />
                  Guests
                </Typography>
                <Typography>
                  {noOfGuests > 0 ? (
                    <>
                      <span style={{ color: "#fff" }}>{noOfGuests}</span> guests
                      ({noOfAdults} adults, {noOfChildren} children,{" "}
                      {noOfInfants} infants, {noOfPets} pets)
                    </>
                  ) : (
                    <>
                      <span style={{ color: "#fff" }}>{noOfGuests}</span> guest
                    </>
                  )}
                  <span
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                      textDecoration: "underline",
                      color: "#fff",
                      fontWeight: "bold",
                      margin: "0px 15px",
                      cursor: "pointer",
                    }}
                    onClick={() => setEditGuests(!editGuests)}
                  >
                    Edit
                  </span>
                  {editGuests && (
                    <>
                      <div style={{ margin: "5px 0px" }}>
                        <BookingCheckoutGuestCount
                          propertyDetail={property}
                          handleChange={handleGuestCountChange}
                          adults={editFormik.values.noOfAdults}
                          children={editFormik.values.noOfChildren}
                          pets={editFormik.values.noOfPets}
                          infants={editFormik.values.noOfInfants}
                        />
                        <small
                          id='username2-help'
                          className='p-error'
                          style={{
                            display: "block",
                            color: "red",
                            textAlign: "left",
                          }}
                        >
                          {editFormik.touched.noOfGuests &&
                            editFormik.errors.noOfGuests}
                        </small>
                      </div>
                      <Buttons
                        isLoading={false}
                        title={"Save"}
                        style={{ width: "20%" }}
                        onSubmit={editFormik.handleSubmit}
                      />
                    </>
                  )}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                color: "#fff",
                flexDirection: "column",
                marginLeft: "15px",
                border: "1px solid rgba(255, 255, 255, 0.1)",
                padding: "12px",
                borderRadius: "8px",
                marginTop: "15px",
              }}
            >
              <Box>
                <>
                  <form>
                    <Toast ref={toast} position='bottom-right' />
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: "22px",
                        fontFamily: "Jost",
                        display: "flex",
                        alignItems: "center",
                        paddingTop: "15px",
                        paddingBottom: "15px",
                      }}
                    >
                      <PaymentIcon
                        sx={{ fontSize: "22px", marginRight: "5px" }}
                      />{" "}
                      Pay With
                    </Typography>
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: "22px",
                        fontFamily: "Jost",
                        display: "flex",
                        alignItems: "center",
                        // margin: '12px 0px',
                        paddingTop: "12px",
                        borderTop: "1px solid rgba(255, 255, 255, 0.1)",
                      }}
                    >
                      Your Wallet
                    </Typography>
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "25px",
                            color: "#fff",
                          }}
                        >
                          <PaymentsIcon
                            style={{
                              marginRight: "16px",
                              fontSize: "18px",
                              color: "#fff",
                            }}
                          />
                          <span
                            style={{
                              display: "inline-flex",
                              alignItems: "center",
                              color: "rgba(255, 255, 255, 0.7)",
                            }}
                          >
                            {process.env.REACT_APP_CURRENCY}{wallet?.amount
                              ? wallet?.amount?.toLocaleString("en-US")
                              : 0}{" "}
                            
                            <AddCircleIcon
                              style={{
                                marginRight: "16px",
                                fontSize: "25px",
                                color: "rgba(255, 255, 255, 0.7)",
                                cursor: "pointer",
                                marginLeft: "10px",
                              }}
                              onClick={() => navigate("/wallet/top-up")}
                            />
                          </span>
                        </Typography>
                      </Box>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={useWallet ? true : false}
                              value={useWallet}
                              onChange={setUseWalletFunc}
                            />
                          }
                          label='Use your wallet.'
                        />
                      </FormGroup>
                      {walletLessAmountError && (
                        <Typography
                          sx={{
                            color: "yellow",
                            fontSize: "16px",
                            fontFamily: "Jost",
                            display: "flex",
                            alignItems: "center",
                            marginTop: "12px",
                            fontWeight: "400",
                          }}
                        >
                          <span
                            style={{
                              color: "#fff",
                              fontWeight: "bold",
                              marginRight: "4px",
                            }}
                          >
                            Info:
                          </span>{" "}
                          {walletLessAmountError}
                        </Typography>
                      )}
                    </Box>

                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: "22px",
                        fontFamily: "Jost",
                        display: "flex",
                        alignItems: "center",
                        paddingTop: "12px",
                        borderTop: "1px solid rgba(255, 255, 255, 0.1)",
                        marginTop: "12px",
                      }}
                    >
                      Your Card
                    </Typography>
                    {userCards?.length > 0 ? (
                      <div style={{ width: "50%" }}>
                        <Select
                          className='override-select-edit'
                          placeholder='Select Card'
                          options={userCards}
                          value={
                            card.value == undefined
                              ? { label: "Select Card", value: "" }
                              : card
                          }
                          onChange={(option) => setCard(option)}
                          styles={customStyles}
                          formatOptionLabel={(item) => (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {item?.brand === "Visa" && (
                                <FaCcVisa
                                  style={{
                                    marginRight: "8px",
                                    fontSize: "25px",
                                  }}
                                />
                              )}
                              {item?.brand === "MasterCard" && (
                                <FaCcMastercard
                                  style={{
                                    marginRight: "8px",
                                    fontSize: "25px",
                                  }}
                                />
                              )}

                              {item?.brand}
                              <span
                                style={{ marginLeft: "10px" }}
                              >{`${item?.label}`}</span>
                            </div>
                          )}
                        />
                      </div>
                    ) : (
                      <Typography
                        sx={{
                          color: "white",
                          fontSize: "16px",
                          fontFamily: "Jost",
                          display: "flex",
                          alignItems: "center",
                          marginTop: "12px",
                          fontWeight: "400",
                        }}
                      >
                        You don't have any cards! Add one by clicking below.
                      </Typography>
                    )}
                    <Grid
                      container
                      spacing={{ xs: 2, md: 3 }}
                      columns={{ xs: 4, sm: 8, md: 12 }}
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          onClick={setUseCreditCardFunc}
                          sx={{
                            cursor: "pointer",
                            color: "#fff",
                            textDecoration: "underline",
                          }}
                        >
                          {useCreditCard
                            ? "Use Saved Credit Card"
                            : "Add Credit Card"}
                        </Typography>
                      </Grid>

                      {useCreditCard && (
                        <>
                          <Grid item xs={12}>
                            <Typography
                              sx={{
                                color: "#fff",
                                fontSize: "22px",
                                fontFamily: "Jost",
                                display: "flex",
                                alignItems: "center",
                                paddingTop: "12px",
                                borderTop: "1px solid rgba(255, 255, 255, 0.1)",
                                marginTop: "12px",
                              }}
                            >
                              Card Details
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography sx={{ textAlign: "left" }}>
                              Card Number
                            </Typography>
                            <TextField
                              sx={{ width: "100%" }}
                              id='number'
                              name='number'
                              number
                              required
                              placeholder='4242424242424242'
                              value={formik.values.number}
                              onChange={formik.handleChange}
                            />
                            {formik.touched.number &&
                              Boolean(formik.errors.number) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: "block",
                                    color: "red",
                                    textAlign: "left",
                                  }}
                                >
                                  {formik.errors.number}
                                </small>
                              )}
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography sx={{ textAlign: "left" }}>
                              Card Holder Name
                            </Typography>
                            <TextField
                              sx={{ width: "100%" }}
                              id='name'
                              name='name'
                              required
                              placeholder='Card Holder Name'
                              value={formik.values.name}
                              onChange={formik.handleChange}
                            />
                            {formik.touched.name &&
                              Boolean(formik.errors.name) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: "block",
                                    color: "red",
                                    textAlign: "left",
                                  }}
                                >
                                  {formik.errors.name}
                                </small>
                              )}
                          </Grid>
                          <Grid item xs={12} sm={4} md={4}>
                            <Typography sx={{ textAlign: "left" }}>
                              Expiration Month
                            </Typography>
                            <TextField
                              sx={{ width: "100%" }}
                              id='exp_month'
                              name='exp_month'
                              required
                              number
                              placeholder='03'
                              value={formik.values.exp_month}
                              onChange={formik.handleChange}
                            />
                            {formik.touched.exp_month &&
                              Boolean(formik.errors.exp_month) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: "block",
                                    color: "red",
                                    textAlign: "left",
                                  }}
                                >
                                  {formik.errors.exp_month}
                                </small>
                              )}
                          </Grid>
                          <Grid item xs={12} sm={4} md={4}>
                            <Typography sx={{ textAlign: "left" }}>
                              Expiration Years
                            </Typography>
                            <TextField
                              sx={{ width: "100%" }}
                              id='exp_year'
                              name='exp_year'
                              required
                              number
                              placeholder='10'
                              value={formik.values.exp_year}
                              onChange={formik.handleChange}
                            />
                            {formik.touched.exp_year &&
                              Boolean(formik.errors.exp_year) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: "block",
                                    color: "red",
                                    textAlign: "left",
                                  }}
                                >
                                  {formik.errors.exp_year}
                                </small>
                              )}
                          </Grid>
                          <Grid item xs={12} sm={4} md={4}>
                            <Typography sx={{ textAlign: "left" }}>
                              CVC
                            </Typography>
                            <TextField
                              sx={{ width: "100%" }}
                              id='cvc'
                              name='cvc'
                              required
                              number
                              placeholder='10'
                              value={formik.values.cvc}
                              onChange={formik.handleChange}
                            />
                            {formik.touched.cvc &&
                              Boolean(formik.errors.cvc) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: "block",
                                    color: "red",
                                    textAlign: "left",
                                  }}
                                >
                                  {formik.errors.cvc}
                                </small>
                              )}
                          </Grid>

                          <Grid item xs={12}>
                            <Typography
                              sx={{
                                color: "#fff",
                                fontSize: "22px",
                                fontFamily: "Jost",
                                display: "flex",
                                alignItems: "center",
                                paddingTop: "12px",
                                borderTop: "1px solid rgba(255, 255, 255, 0.1)",
                                marginTop: "12px",
                              }}
                            >
                              Billing Address
                            </Typography>
                          </Grid>

                          <Grid item xs={12}>
                            <Typography sx={{ textAlign: "left" }}>
                              Street Address
                            </Typography>
                            <TextField
                              sx={{ width: "100%" }}
                              id='address1'
                              name='address1'
                              required
                              placeholder='Street Address'
                              value={formik.values.address1}
                              onChange={formik.handleChange}
                            />
                            {formik.touched.address1 &&
                              Boolean(formik.errors.address1) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: "block",
                                    color: "red",
                                    textAlign: "left",
                                  }}
                                >
                                  {formik.errors.address1}
                                </small>
                              )}
                          </Grid>
                          <Grid item xs={12}>
                            <Typography sx={{ textAlign: "left" }}>
                              Apt or suite number
                            </Typography>
                            <TextField
                              sx={{ width: "100%" }}
                              id='address2'
                              name='address2'
                              required
                              type='text'
                              placeholder='Enter Suite Number'
                              value={formik.values.address2}
                              onChange={formik.handleChange}
                            />
                            {formik.touched.address2 &&
                              Boolean(formik.errors.address2) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: "block",
                                    color: "red",
                                    textAlign: "left",
                                  }}
                                >
                                  {formik.errors.address2}
                                </small>
                              )}
                          </Grid>
                          <Grid item xs={12}>
                            <Typography sx={{ textAlign: "left" }}>
                              City
                            </Typography>
                            <TextField
                              sx={{ width: "100%" }}
                              id='city'
                              name='city'
                              required
                              placeholder='City'
                              value={formik.values.city}
                              onChange={formik.handleChange}
                            />
                            {formik.touched.city &&
                              Boolean(formik.errors.city) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: "block",
                                    color: "red",
                                    textAlign: "left",
                                  }}
                                >
                                  {formik.errors.city}
                                </small>
                              )}
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography sx={{ textAlign: "left" }}>
                              State
                            </Typography>
                            <TextField
                              sx={{ width: "100%" }}
                              id='state'
                              name='state'
                              required
                              placeholder='State'
                              value={formik.values.state}
                              onChange={formik.handleChange}
                            />
                            {formik.touched.state &&
                              Boolean(formik.errors.state) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: "block",
                                    color: "red",
                                    textAlign: "left",
                                  }}
                                >
                                  {formik.errors.state}
                                </small>
                              )}
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography sx={{ textAlign: "left" }}>
                              Zip Code
                            </Typography>
                            <TextField
                              sx={{ width: "100%" }}
                              id='zipcode'
                              name='zipcode'
                              required
                              type='number'
                              placeholder='Zip Code'
                              value={formik.values.zipcode}
                              onChange={formik.handleChange}
                            />
                            {formik.touched.zipcode &&
                              Boolean(formik.errors.zipcode) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: "block",
                                    color: "red",
                                    textAlign: "left",
                                  }}
                                >
                                  {formik.errors.zipcode}
                                </small>
                              )}
                          </Grid>
                          <Grid item xs={12}>
                            <Dropdown
                              value={formik.values.country}
                              options={options}
                              onChange={formik.handleChange}
                              optionLabel='name'
                              filter
                              showClear
                              filterBy='name'
                              name='country'
                              placeholder='Country/region'
                              valueTemplate={selectedCountryTemplate}
                              itemTemplate={countryOptionTemplate}
                              style={{
                                width: "100%",
                                background: "transparent",
                                padding: "6px 5px",
                              }}
                            />
                            {formik.touched.country &&
                              Boolean(formik.errors.country) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: "block",
                                    color: "red",
                                    textAlign: "left",
                                  }}
                                >
                                  {formik.errors.country}
                                </small>
                              )}
                          </Grid>
                        </>
                      )}

                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        sx={{ textAlign: "start" }}
                      >
                        <div
                          style={{
                            color: "#fff",
                            fontSize: "16px",
                            textDecoration: "underline",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                          }}
                          onClick={handleClickOpen}
                        >
                          <DiscountIcon
                            sx={{ fontSize: "16px", marginRight: "5px" }}
                          />{" "}
                          Enter a coupon
                        </div>
                      </Grid>
                    </Grid>
                  </form>
                </>
              </Box>
            </Box>
            {cancellationPolicy && (
              <Box
                sx={{
                  display: "flex",
                  color: "#fff",
                  flexDirection: "column",
                  marginLeft: "15px",
                  border: "1px solid rgba(255, 255, 255, 0.1)",
                  padding: "12px",
                  borderRadius: "8px",
                  marginTop: "15px",
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "22px",
                    fontFamily: "Jost",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <CancelIcon sx={{ fontSize: "22px", marginRight: "5px" }} />{" "}
                  Cancellation Policy
                </Typography>
                <Typography>
                  Free cancellation before{" "}
                  {moment(startDate).format("DD MMM, YYYY")}.
                  <Dialouge data={moment(startDate).format("DD MMM, YYYY")} />
                </Typography>

                <Box>
                  <Typography>{cancellationPolicy?.description}</Typography>
                </Box>

                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={policyCheck}
                        value={policyCheck}
                        onChange={(e) => setPolicyCheck(e.target.checked)}
                      />
                    }
                    label={
                      <>
                        I accept the{" "}
                        <a
                          href='/terms-and-conditions'
                          target={"_blank"}
                          style={{
                            fontSize: "18px",
                            color: "#0095ff",
                            textDecoration: "underline",
                          }}
                        >
                          terms & conditions
                        </a>{" "}
                        for cancellation policy.
                      </>
                    }
                  />
                </FormGroup>
              </Box>
            )}
            {!bookingId && (
              <>
                {isInstantBook ? (
                  <Box
                    sx={{
                      marginTop: "15px",
                    }}
                  >
                    <Buttons
                      title={"Save & Pay for later"}
                      style={{
                        width: "40%",
                        marginRight: "12px",
                      }}
                      onSubmit={() => setShowDialogSaveAndPayLater(true)}
                      isLoading={false}
                      disabled={values?.errors?.length > 0}
                    />
                    <Buttons
                      isLoading={false}
                      title={"Confirm and Pay"}
                      style={{
                        width: "40%",
                      }}
                      onSubmit={() => setShowDialogConfirmAndPay(true)}
                      disabled={values?.errors?.length > 0}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      marginTop: "15px",
                    }}
                  >
                    <Buttons
                      isLoading={false}
                      title={"Send Booking Request"}
                      style={{
                        width: "40%",
                      }}
                      onSubmit={() => setShowDialogSendBookingRequest(true)}
                      disabled={values?.errors?.length > 0}
                    />
                  </Box>
                )}
              </>
            )}
            {bookingId && (
              <>
                <Box
                  sx={{
                    marginTop: "15px",
                  }}
                >
                  <Buttons
                    title={"Reject Invitation"}
                    style={{
                      width: "40%",
                      marginRight: "12px",
                      background: "red",
                    }}
                    onSubmit={() => setShowDialogRejectInvitation(true)}
                    isLoading={false}
                    disabled={values?.errors?.length > 0}
                  />
                  <Buttons
                    isLoading={false}
                    title={"Accept Invitation"}
                    style={{
                      width: "40%",
                    }}
                    onSubmit={() => setShowDialogAcceptInvitation(true)}
                    disabled={values?.errors?.length > 0}
                  />
                </Box>
              </>
            )}
          </Grid>
          <Grid
            md={5}
            item
            sx={{
              padding: "100px 25px",
            }}
          >
            <Box
              sx={{
                borderRadius: "8px",
                border: "1px solid rgba(255, 255, 255, 0.1)",
                padding: "20px",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Box>
                  <img
                    src={property?.thumbnailUrl}
                    alt='property_image'
                    width={150}
                    height={150}
                  />
                </Box>
                <Box sx={{ marginLeft: "10px" }}>
                  <Typography
                    sx={{ color: "#fff", fontFamily: "Jost", fontSize: "22px" }}
                  >
                    {property?.title && property?.title?.length > 30
                      ? property?.title?.slice(0, 30) + "..."
                      : property?.title?.slice(0, 30)}
                  </Typography>
                  <Typography sx={{ fontFamily: "Jost", fontSize: "14px" }}>
                    {property?.city}, {property?.country}
                  </Typography>
                  <Typography sx={{ fontFamily: "Jost", fontSize: "14px" }}>
                    Property ID: {code}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Jost",
                      fontSize: "16px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name='half-rating-read'
                      value={
                        property?.average_rating
                          ? property?.average_rating?.averageRating
                          : 0
                      }
                      precision={0.5}
                      readOnly
                      sx={{ marginRight: "4px" }}
                    />
                    (
                    {property?.average_rating
                      ? property?.average_rating?.averageRating?.toFixed(1)
                      : 0}
                    ) (
                    {property?.average_rating
                      ? ` ${property?.average_rating?.reviewCount} Reviews`
                      : " (0 Reviews)"}
                    )
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ marginTop: "10px" }}>
                <Typography
                  sx={{
                    color: "#fff",
                    fontFamily: "Jost",
                    fontSize: "25px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <PaymentsIcon sx={{ fontSize: "25px", marginRight: "5px" }} />{" "}
                  Price Details
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    sx={{
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <NightlightRoundIcon
                      sx={{ fontSize: "18px", marginRight: "5px" }}
                    />{" "}
                    Nightly Price x {values?.noOfNights} (nights)
                  </Typography>
                  <Typography>
                    {process.env.REACT_APP_CURRENCY}{" "}
                    {values?.totalNightsCharges?.toLocaleString("en-US")}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    sx={{
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    <CleaningServicesIcon
                      sx={{ fontSize: "18px", marginRight: "5px" }}
                    />{" "}
                    Cleaning Fee
                  </Typography>
                  <Typography>
                    {process.env.REACT_APP_CURRENCY}{" "}
                    {values?.cleaningFee?.toLocaleString("en-US")}{" "}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <PersonAddIcon
                      sx={{ fontSize: "18px", marginRight: "5px" }}
                    />{" "}
                    Additional Guests Fee
                  </Typography>
                  <Typography>
                    <span style={{ color: "#fff" }}>
                      {values?.totalExtraGuests}
                    </span>{" "}
                    x{" "}
                    <span style={{ color: "#fff" }}>
                      {process.env.REACT_APP_CURRENCY}{" "}
                      {values?.extraGuestFee?.toLocaleString("en-US")}
                    </span>{" "}
                    ={" "}
                    <span style={{ color: "#fff" }}>
                      {" "}
                      {process.env.REACT_APP_CURRENCY}{" "}
                      {values?.totalExtraGuestFee?.toLocaleString("en-US")}
                    </span>
                  </Typography>
                </Box>
                {property?.propertyCapacity?.noOfInfants > 0 ? (
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      sx={{
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <ChildCareIcon
                        sx={{ fontSize: "18px", marginRight: "5px" }}
                      />{" "}
                      Infant Fee
                    </Typography>
                    <Typography>No Fee</Typography>
                  </Box>
                ) : (
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      sx={{
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <ChildCareIcon
                        sx={{ fontSize: "18px", marginRight: "5px" }}
                      />{" "}
                      Infant Fee
                    </Typography>
                    <Typography>Not Allowed</Typography>
                  </Box>
                )}
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    sx={{
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <PetsIcon sx={{ fontSize: "18px", marginRight: "5px" }} />{" "}
                    Pet Fee
                  </Typography>
                  {property?.propertyCapacity?.noOfPets > 0 ? (
                    <Typography>
                      {values?.noOfPets} x {process.env.REACT_APP_CURRENCY}{" "}
                      {values?.petFee?.toLocaleString("en-US")} ={" "}
                      {values?.totalPetFee?.toLocaleString("en-US")}
                    </Typography>
                  ) : (
                    <Typography>Not Allowed</Typography>
                  )}
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    sx={{
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <AccountBalanceIcon
                      sx={{ fontSize: "18px", marginRight: "5px" }}
                    />{" "}
                    Service Fee ({serviceFee} %)
                  </Typography>
                  <Typography>
                    {process.env.REACT_APP_CURRENCY}{" "}
                    {values?.totalServiceCharges?.toLocaleString("en-US")}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    sx={{
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <AccountBalanceIcon
                      sx={{ fontSize: "18px", marginRight: "5px" }}
                    />{" "}
                    GST ({gst} %)
                  </Typography>
                  <Typography>
                    {process.env.REACT_APP_CURRENCY}{" "}
                    {values?.totalGSTFee?.toLocaleString("en-US")}
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: "19px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <DoneOutlineIcon
                      sx={{ fontSize: "19px", marginRight: "5px" }}
                    />{" "}
                    Total ( {process.env.REACT_APP_CURRENCY} )
                  </Typography>
                  <Typography sx={{ fontSize: "18px", color: "#fff" }}>
                    {process.env.REACT_APP_CURRENCY}{" "}
                    {values?.grossAmount?.toLocaleString("en-US")}
                  </Typography>
                </Box>
                {/* if coupons */}
                {couponCode && values?.validCoupon && (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "19px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <DoneOutlineIcon
                          sx={{ fontSize: "19px", marginRight: "5px" }}
                        />{" "}
                        Total Coupon Discount({couponCode})
                      </Typography>
                      <Typography sx={{ fontSize: "18px", color: "#fff" }}>
                        {process.env.REACT_APP_CURRENCY}{" "}
                        {values?.couponDiscount?.toLocaleString("en-US")}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "19px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <DoneOutlineIcon
                          sx={{ fontSize: "19px", marginRight: "5px" }}
                        />{" "}
                        Total Discounted Amount
                      </Typography>
                      <Typography sx={{ fontSize: "18px", color: "#fff" }}>
                        {process.env.REACT_APP_CURRENCY}{" "}
                        {values?.couponDiscount?.toLocaleString("en-US")}
                      </Typography>
                    </Box>
                  </>
                )}
              </Box>
            </Box>
            {useWallet && (
              <Box
                sx={{
                  borderRadius: "8px",
                  border: "1px solid rgba(255, 255, 255, 0.1)",
                  padding: "20px",
                  margin: "20px 0px",
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "22px",
                    fontFamily: "Jost",
                    display: "flex",
                    alignItems: "center",
                    paddingTop: "12px",
                  }}
                >
                  Charge through Wallet Calculations
                </Typography>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "25px",
                        color: "#fff",
                      }}
                    >
                      <PaymentsIcon
                        style={{
                          marginRight: "16px",
                          fontSize: "18px",
                          color: "#fff",
                        }}
                      />
                      <span
                        style={{
                          display: "inline-flex",
                          alignItems: "center",
                          color: "rgba(255, 255, 255, 0.7)",
                        }}
                      >
                        {process.env.REACT_APP_CURRENCY}{wallet?.amount
                          ? wallet?.amount?.toLocaleString("en-US")
                          : 0}{" "}
                        
                        <AddCircleIcon
                          style={{
                            marginRight: "16px",
                            fontSize: "25px",
                            color: "rgba(255, 255, 255, 0.7)",
                            cursor: "pointer",
                            marginLeft: "10px",
                          }}
                          onClick={() => navigate("/wallet/top-up")}
                        />
                      </span>
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ color: "#fff" }}>
                      Total Booking Charges
                    </Typography>
                    <Typography>
                      {process.env.REACT_APP_CURRENCY}{" "}
                      {values?.grossAmount?.toLocaleString("en-US")}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ color: "#fff" }}>
                      Wallet Remaining Amount
                    </Typography>
                    <Typography>
                      {process.env.REACT_APP_CURRENCY}{" "}
                      {wallet?.amount?.toLocaleString("en-US")} -{" "}
                      {process.env.REACT_APP_CURRENCY}{" "}
                      {values?.grossAmount?.toLocaleString("en-US")} ={" "}
                      {process.env.REACT_APP_CURRENCY}{" "}
                      {wallet?.amount - values?.grossAmount < 0
                        ? 0
                        : (wallet?.amount - values?.grossAmount).toLocaleString(
                            "en-US"
                          )}
                    </Typography>
                  </Box>
                  {wallet?.amount - values?.grossAmount < 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={{ color: "#fff" }}>
                        Remaining booking amount to be charged:
                      </Typography>
                      <Typography>
                        {process.env.REACT_APP_CURRENCY}{" "}
                        {wallet?.amount?.toLocaleString("en-US")} -{" "}
                        {process.env.REACT_APP_CURRENCY}{" "}
                        {values?.grossAmount?.toLocaleString("en-US")} ={" "}
                        {process.env.REACT_APP_CURRENCY}{" "}
                        {Math.abs(
                          wallet?.amount - values?.grossAmount
                        )?.toLocaleString("en-US")}
                      </Typography>
                    </Box>
                  )}{" "}
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Coupon</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin='dense'
              id='coupon'
              label='Coupon'
              type='coupon'
              value={couponText}
              fullWidth
              onChange={(e) => setCouponText(e.target.value)}
            />
            <Buttons
              isLoading={false}
              title={"Apply Coupon"}
              style={{ width: "80%", marginTop: "15px" }}
              onSubmit={() => {
                setCouponCode(couponText);
                setOpen(false);
              }}
            />
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default BookingCheckout;
