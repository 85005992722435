import React, { useRef } from 'react';
import Header from '../../../components/header';
import Sidebar from '../../../components/dasboard/sidebar';
import Notification from '../../../components/Notification';
import { AiFillAccountBook } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import AddPropertyPopup from '../../../components/Popup/AddPropertyPopup';
import { confirmDialog } from 'primereact/confirmdialog';
import userStatusUpdate from '../../../services/User/userStatus';
import { Toast } from 'primereact/toast';

const Notifications = () => {
  const navigate = useNavigate();
  const toast = useRef(null);

  const userData = JSON.parse(localStorage.getItem('userData')) || null;
  const userHost = JSON.parse(localStorage.getItem('userHost')) || null;

  const navigateToAddProperty = () => {
    if (!userData) {
      navigate('/login');
    }
    if (userHost) {
      navigate('/admin/add-property');
    } else if (!userHost) {
      openModal();
    }
  };

  const accept = () => {
    localStorage.setItem('userHost', true);
    navigate('/admin/add-property');
  };
  const reject = () => {};
  const openModal = () => {
    confirmDialog({
      message: <AddPropertyPopup />,
      header: 'Requirements for becoming a Host on Staywo.com',
      acceptLabel: 'Yes, I agree',
      rejectLabel: 'Disagree',
      accept,
      reject,
    });
  };

  return (
    <div>
      <Header />
      <Sidebar />
      <Toast ref={toast} position='bottom-right' />

      <div
        className='dashboard-list-box  fl-wrap'
        style={{
          width: '60vw',
          position: 'absolute',
          right: '10%',
          top: '15%',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <h3
          style={{ fontWeight: 'bold', color: 'white', marginBottom: '2.5rem' }}
        >
          Your Notifications
        </h3>

        <Notification
          link={'/admin/add-property'}
          navigateToAddProperty={navigateToAddProperty}
          text={'Add Property'}
          icon={<AiFillAccountBook />}
          date={'28 may 2020'}
        />
        <Notification
          link={'/'}
          text={'Book Property'}
          icon={<AiFillAccountBook />}
          date={'28 may 2020'}
        />
      </div>
    </div>
  );
};

export default Notifications;
