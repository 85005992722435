import { API_URL } from '../../config';
import axios from 'axios';

export const getPropertyDocumentsApi = async (id) => {
  try {
    const response = await axios.get(
      API_URL + `property-document/getDocumentByPropertyId/${id}`
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of getDocumentByPropertyId : ', response);
      return response;
    }
  }
};
