import React, { useEffect, useState, useRef } from 'react';
import Input from '../form/input';
import { Divider, Grid, List } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';

export const RoomsCount = (props) => {
  const [roomsSelect, setRoomsSelect] = useState(false);
  const menuRef = useRef();
  const [roomsCount, setRoomsCount] = useState({
    rooms: 0,
  });

  useEffect(() => {
    props.handleChange && props.handleChange(roomsCount.rooms, roomsCount);
  }, [roomsCount]);

  useEffect(() => {
    window.addEventListener('mousedown', (e) => {
      if (!menuRef.current.contains(e.target)) {
        setRoomsSelect(false);
      }
      return window.removeEventListener('mousedown', (e) => {
        if (!menuRef.current.contains(e.target)) {
          setRoomsSelect(false);
        }
      });
    });
  }, [roomsCount]);
  return (
    <>
      <Input
        value={
          roomsCount.rooms < 1
            ? ''
            : `${roomsCount.rooms} ${roomsCount.rooms > 1 ? 'rooms' : 'room'}`
        }
        label={'Select # of Rooms'}
        sx={{
          fontSize: '14px',
          textAlign: 'center',
        }}
        onClick={() => setRoomsSelect(!roomsSelect)}
        onChange={() => {
          props.handleChange([roomsCount.rooms]);
        }}
      />

      {roomsSelect && (
        <ul
          id="guestDD"
          style={{
            position: 'absolute',
            zIndex: 9999,
            color: '#fff',
            minWidth: '250px',
          }}
          ref={menuRef}
        >
          <ListItems
            primary={'Rooms'}
            secondary={'Select your number of rooms!'}
            value={roomsCount.rooms}
            handleAddClick={() =>
              setRoomsCount({
                ...roomsCount,
                rooms: roomsCount.rooms + 1,
              })
            }
            handleRemoveClick={() =>
              setRoomsCount({
                ...roomsCount,
                rooms: roomsCount.rooms - 1,
              })
            }
          />
        </ul>
      )}
    </>
  );
};

const style = {
  width: '100%',
  bgcolor: '#1f2d40',
  padding: 0,
};

const ListItems = ({
  adult,
  primary,
  secondary,
  handleAddClick,
  handleRemoveClick,
  value,
}) => {
  return (
    <List dense sx={style} component="nav" aria-label="mailbox folders">
      <ListItem
        secondaryAction={
          <Grid container justifyContent="center" alignItems="center">
            <Grid item>
              <IconButton
                disabled={adult ? value === 1 : value === 0}
                aria-label="remove"
                onClick={() => {
                  if (value > 0) handleRemoveClick();
                }}
                edge="end"
              >
                <RemoveOutlinedIcon />
              </IconButton>
            </Grid>
            <Grid
              item
              style={{
                textAlign: 'center',
                marginLeft: '20px',
                marginRight: '8px',
              }}
            >
              <span>{value}</span>
            </Grid>
            <Grid item>
              <IconButton aria-label="add" onClick={handleAddClick} edge="end">
                <AddOutlinedIcon />
              </IconButton>
            </Grid>
          </Grid>
        }
      >
        <ListItemText primary={primary} secondary={secondary} />
      </ListItem>
      <Divider />
    </List>
  );
};

export default ListItems;
