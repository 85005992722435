import React from 'react';

const Footer = () => {
  return (
    <footer className="main-footer fl-wrap">
      <div className="footer-inner fl-wrap">
        <div className="container">
          <div className="row mt-4">
            <div className="col-md-3">
              <div className="footer-widget fl-wrap">
                <div className="footer-widget-logo fl-wrap">
                  <img
                    src={require('../assets/images/logo.png')}
                    alt=""
                  />
                </div>
                <p>
                  We are a rental property B2C provider, where we connect hosts
                  with travelers to get the rental property no matter where they
                  go and make their travelling experience a bliss.
                </p>
                <div className="fw_hours fl-wrap">
                  <span>
                    Support Available<strong> 24/7</strong>
                  </span>
                </div>
              </div>
            </div>

            <div className="col-md-2">
              <div className="footer-widget fl-wrap">
                <div className="footer-widget-title fl-wrap">
                  <h4>Helpful links</h4>
                </div>
                <ul className="footer-list fl-wrap">
                  <li>
                    <a href="about.html">About Our Company</a>
                  </li>
                  <li>
                    <a href="blog.html">Explore</a>
                  </li>
                  <li>
                    <a href="pricing.html">Pricing Plans</a>
                  </li>
                  <li>
                    <a href="contacts.html">Contacts</a>
                  </li>
                  <li>
                    <a href="help.html">Help Center</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-4">
              <div className="footer-widget fl-wrap">
                <div className="footer-widget-title fl-wrap">
                  <h4>Contacts Info</h4>
                </div>
                <ul className="footer-contacts fl-wrap">
                  <li>
                    <span>
                      <i className="fal fa-envelope"></i> Mail :
                    </span>
                    <a href="mailto:staywo5g@gmail.com" target="_blank">
                      staywo5g@gmail.com
                    </a>
                  </li>
                  <li>
                    {' '}
                    <span>
                      <i className="fal fa-map-marker"></i> Adress :
                    </span>
                    <a href="#" target="_blank">
                      Sacramento CA 95824, USA
                    </a>
                  </li>
                  <li>
                    <span>
                      <i className="fal fa-phone"></i> Phone :
                    </span>
                    <a href="tel:+1 (916) 233 5033">+1 (916) 233 5033</a>
                  </li>
                </ul>
                <div className="footer-social fl-wrap">
                  <ul>
                    <li>
                      <a href="https://www.facebook.com/profile.php?id=100090126948188&mibextid=ZbWKwL" rel='noreferrer' target="_blank">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#https://www.youtube.com/@staywo" target="_blank">
                        <i className="fab fa-youtube"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://instagram.com/staywopakistan?igshid=ZDdkNTZiNTM=" rel='noreferrer' target="_blank">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                    {/* <li>
                      <a href="#" target="_blank">
                        <i className="fab fa-vk"></i>
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="footer-widget fl-wrap">
                <div className="footer-widget-title fl-wrap">
                  <h4>Download our API</h4>
                </div>
                <p>
                  Download the app today to make your first booking and improve
                  your traveling experience{' '}
                </p>
                <div className="api-links fl-wrap">
                  <a href="#" className="api-btn color-bg">
                    <i className="fab fa-apple"></i> App Store
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.staywo" target={"_blank"} className="api-btn color-bg">
                    <i className="fab fa-google-play"></i>
                    Play Market
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="sub-footer gray-bg fl-wrap">
        <div className="container">
          <div className="copyright">
            {' '}
            &#169; Staywo USA 2022 . All rights reserved.
          </div>
          <div className="subfooter-nav">
            <ul className="no-list-style">
              <li>
                <a href="/terms-and-conditions">Terms of use</a>
              </li>
              <li>
                <a href="/privacy-policy">Privacy Policy</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
