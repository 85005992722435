import React, { useState } from "react";
import Input from "../form/input";
import ListItems from "../listItems";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

export const AutoCompletePlacesAdvanced = ({
  formik,
  label,
  setMapCenter,
  setZoom,
  address,
  getPropertiesBySearch,
  mapCenter,
}) => {
  const {
    value,
    suggestions: { data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {},
    debounce: 300,
  });
  let lt;
  let lg;
  const handleSelect =
    ({ description }) =>
    () => {
      setValue(description, false);
      clearSuggestions();
      getGeocode({ address: description })
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          setMapCenter({
            lat: !isNaN(lat) ? +parseFloat(lat) : 0,
            lng: !isNaN(lng) ? +parseFloat(lng) : 0,
          });
          lt = lat;
          lg = lng;
        })
        .catch((error) => {
          console.log("😱 Error: ", error);
        });

      getGeocode({ address: description }).then((results) => {
        let GLOBE_WIDTH = 256;
        let west = results[0].geometry.bounds.getSouthWest().lng();
        let east = results[0].geometry.bounds.getNorthEast().lng();
        let angle = east - west;
        if (angle < 0) {
          angle += 360;
        }
        let zoom = Math.round(
          Math.log((860 * 360) / angle / GLOBE_WIDTH) / Math.LN2
        );
        setZoom(zoom);
        let city, state, country;
        for (let i = 0; i < results[0].address_components.length; i++) {
          for (
            let j = 0;
            j < results[0].address_components[i].types.length;
            j++
          ) {
            switch (results[0].address_components[i].types[j]) {
              case "locality":
                city = results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_1":
                state = results[0].address_components[i].long_name;
                break;
              case "country":
                country = results[0].address_components[i].long_name;
                break;
              default:
                break;
            }
          }
        }

        try {
          formik.values.state = state;
          formik.values.city = city;
          formik.values.country = country;
          getPropertiesBySearch(city, country, lt, lg);
        } catch (error) {
          console.log("Error: ", error);
        }
      });
    };

  const [isClick, setIsClick] = useState(false);
  const renderSuggestions = () =>
    data?.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <ListItems
          key={place_id}
          onClick={handleSelect(suggestion)}
          primary={main_text}
          secondary={secondary_text}
        />
      );
    });

  React.useEffect(() => {
    if (address) {
      setValue(address, false);
      getGeocode({ address: address })
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          setMapCenter({
            lat: !isNaN(lat) ? +parseFloat(lat) : 0,
            lng: !isNaN(lng) ? +parseFloat(lng) : 0,
          });
        })
        .catch((error) => {
          console.log("😱 Error: ", error);
        });

      getGeocode({ address: address }).then((results) => {
        let GLOBE_WIDTH = 256;
        let west = results[0].geometry.bounds.getSouthWest().lng();
        let east = results[0].geometry.bounds.getNorthEast().lng();
        let angle = east - west;
        if (angle < 0) {
          angle += 360;
        }
        let zoom = Math.round(
          Math.log((860 * 360) / angle / GLOBE_WIDTH) / Math.LN2
        );
        setZoom(zoom);
        let city, state, country;
        for (let i = 0; i < results[0].address_components.length; i++) {
          for (
            let j = 0;
            j < results[0].address_components[i].types.length;
            j++
          ) {
            switch (results[0].address_components[i].types[j]) {
              case "locality":
                city = results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_1":
                state = results[0].address_components[i].long_name;
                break;
              case "country":
                country = results[0].address_components[i].long_name;
                break;
              default:
                break;
            }
          }
        }

        try {
          formik.values.state = state;
          formik.values.city = city;
          formik.values.country = country;
        } catch (error) {
          console.log("Error: ", error);
        }
      });
    } else {
      setValue("", false);
    }
  }, [address]);
  return (
    <>
      <Input
        sx={{ fontSize: "14px", textAlign: "center" }}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          setIsClick(true);
        }}
        onClick={() => setIsClick(!isClick)}
        label={label}
      />
      {isClick && (
        <ul
          id='placesAutoComplete'
          style={{
            position: "absolute",
            zIndex: 9999,
            color: "#fff",
            width: "95%",
            fontSize: "14px",
          }}
        >
          {renderSuggestions()}
        </ul>
      )}
    </>
  );
};
