import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Header from '../../../components/header';
import Sidebar from '../../../components/dasboard/sidebar';
import { AiFillCreditCard } from 'react-icons/ai';
import { sliderClasses, Tab, Typography } from '@mui/material';
import { Box } from '@mui/system';
import Button from '@mui/material/Button';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { getBookingTransactionHistoryApi } from '../../../services/TransactionHistory/getBookingTransactionHistory';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import getVendorWithdrawalHistoryApi from '../../../services/Vendor/getVendorWithdrawalHistory';

const dashboardTabs = [
  { label: 'Upcoming', key: '1', slug: 'pending' },
  { label: 'Completed', key: '2', slug: 'done' },
];

const VendorWithdrawalHistory = () => {
  const [transactionHistory, setTransactionHistory] = React.useState();
  const [value, setValue] = React.useState('pending');

  const userData = JSON.parse(localStorage.getItem('userData')) || null;

  const filterTransactionHistoryData = (data) => {
    let values = data;
    let array = [];
    values?.reverse().forEach((value) => {
      let data = {
        withdrawalTransactionId: value?.withdrawalTransactionId,
        propertyTitle: value?.booking?.property?.title?.slice(0, 45),
        expectedWithdrawalDate: moment(value?.expectedWithdrawalDate).format(
          'DD MMM, YYYY'
        ),
        withdrawalAmount: `${process.env.REACT_APP_CURRENCY}${value?.withdrawalAmount}`,
        statusReason: value?.statusReason,
        bookingCode: value?.booking?.code,
      };
      array.push(data);
    });
    setTransactionHistory(array);
  };

  const getData = async (status) => {
    const data = {
      order: 'ASC',
      page: 1,
      take: 10,
      userId: userData?.id,
      status: status ? status : value,
    };
    const res = await getVendorWithdrawalHistoryApi(data);
    filterTransactionHistoryData(res.data);
  };

  const handleChange = async (event, newValue) => {
    setValue(newValue);
    getData(newValue);
  };

  React.useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <Header />
      <div id='wrapper'>
        <div className='dashboard-menu-overlay' />
        <Sidebar page={'Booking Transaction History'} />
        <div className='dashboard-content'>
          <div className='dasboard-wrapper fl-wrap no-pag'>
            <div>
              <div className='clearfix' />
              <div className='row'>
                <div className='col-md-12'>
                  <div
                    style={{
                      margin: '10px 0px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        color: 'white',
                        fontFamily: 'Jost',
                        fontSize: '20px',
                        fontWeight: '700',
                        paddingBottom: '0px',
                      }}
                    >
                      YOUR TRANSACTIONS / WITHDRAWALS
                    </Typography>
                  </div>
                  <div style={{ margin: '30px 0px' }}>
                    <TabContext value={value}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList
                          onChange={handleChange}
                          aria-label='lab API tabs example'
                        >
                          {dashboardTabs?.map((tab, i) => (
                            <Tab
                              sx={{ fontSize: '14px' }}
                              label={tab.label}
                              key={i}
                              value={tab.slug}
                              iconPosition='end'
                            />
                          ))}
                        </TabList>
                      </Box>

                      <TabPanel value='pending'>
                        <DataTable
                          responsiveLayout='scroll'
                          value={transactionHistory}
                          style={{ width: '100%', fontSize: '16px' }}
                        >
                          <Column
                            field='withdrawalTransactionId'
                            header='Transaction ID'
                          ></Column>
                          <Column
                            field='propertyTitle'
                            header='Property Title'
                          ></Column>
                          <Column
                            field='bookingCode'
                            header='Booking Code'
                          ></Column>
                          <Column
                            field='expectedWithdrawalDate'
                            header='Expected Withdrawal'
                          ></Column>
                          <Column
                            field='withdrawalAmount'
                            header='Amount'
                          ></Column>
                          <Column field='statusReason' header='Reason'></Column>
                        </DataTable>
                      </TabPanel>
                      <TabPanel value='done'>
                        <DataTable
                          responsiveLayout='scroll'
                          value={transactionHistory}
                          style={{ width: '100%', fontSize: '16px' }}
                        >
                          <Column
                            field='withdrawalTransactionId'
                            header='Transaction ID'
                          ></Column>
                          <Column
                            field='propertyTitle'
                            header='Property Title'
                          ></Column>
                          <Column
                            field='bookingCode'
                            header='Booking Code'
                          ></Column>
                          <Column
                            field='expectedWithdrawalDate'
                            header='Expected Withdrawal'
                          ></Column>
                          <Column
                            field='withdrawalAmount'
                            header='Amount'
                          ></Column>
                          <Column field='statusReason' header='Reason'></Column>
                        </DataTable>
                      </TabPanel>
                    </TabContext>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorWithdrawalHistory;
