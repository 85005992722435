import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const ImageCarousel = ({ propertyDetail }) => {
  return (
    <div style={{ width: '100%' }}>
      <Carousel
        interval={3000}
        dynamicHeight
        infiniteLoop
        stopOnHover
        showThumbs={true}
        showStatus={false}
        swipeable
        autoPlay
        showArrows={false}
      >
        {propertyDetail?.map((item) => (
          <div key={item.id}>
            <img src={item.fileURL} alt='' />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default ImageCarousel;
