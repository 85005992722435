import { API_URL } from "../../config";
import axios from "axios";

export const sendExtraPaymentMessageApi = async (
  message,
  userId,
  extraPayId
) => {
  try {
    const response = await axios.post(
      API_URL + `booking-extra-payment/send-message`,
      {
        message: message,
        userId: userId,
        bookingExtraPaymentId: extraPayId,
      }
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log(
        "Bad Response of booking-extra-payment/send-message : ",
        response
      );
      return response;
    }
  }
};
