import React, { useState } from 'react';
import styled from 'styled-components';

// prime react
import { Calendar } from 'primereact/calendar';
import './datepicker.css';

//Simple date picker component
const DatePickerHome = ({
  id,
  name,
  label,
  handleChange,
  helperText,
  formik,
  handleDateStart,
  handleDateEnd,
  handlers,
  basePrice,
  baseZIndex
}) => {
  const [value, setValue] = useState();

  const dateTemplate = (date) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '10rem',
        }}
      >
        <div style={{ fontSize: '18px' }}>{date.day}</div>
        {basePrice && (
          <>
            <br />
            <span
              style={{
                fontSize: '0.75em',
              }}
            >
              {process.env.REACT_APP_CURRENCY}{basePrice}
            </span>
          </>
        )}
      </div>
    );
  };

  return (
    <DatePickerContainer>
      <Calendar
        // showIcon
        baseZIndex={baseZIndex}
        id='range'
        minDate={new Date()}
        value={value}
        style={{ fontSize: '25px' }}
        onChange={(e) => {
          setValue(e.value);
          handlers && handleDateStart(e.value[0]);
          handlers && handleDateEnd(e.value[1]);
        }}
        selectionMode='range'
        readOnlyInput
        placeholder='Select a Date Range'
        dateTemplate={dateTemplate}
        numberOfMonths={2}
      />
    </DatePickerContainer>
  );
};

const DatePickerContainer = styled.div`
  height: 100%;

  .p-calendar input {
    text-align: center;
  }

  .p-calendar .p-inputtext {
    background: none;
    color: #fff;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    text-align: 'center';
    font-size: 14px;
    line-height: 1.4375em;
    min-height: 55px;

    &:hover {
      border-color: #fff;
    }
    &:focus {
      box-shadow: none;
      /* border-color: #326fd1; */
      border: 2px solid;
    }
  }

  .p-calendar {
    width: 100%;

    font-size: 25px;
  }
  .p-highlight {
    color: #0095f8 !important;
  }
  #datepicker {
    font-size: 25px;
  }
`;

export default DatePickerHome;
