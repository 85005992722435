import { API_URL } from '../../config';
import axios from 'axios';

export const walletTopUpApi = async (data) => {
  try {
    const response = await axios.post(API_URL + `wallet/top-up`, data);
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of get user wallet : ', response);
      return response;
    }
  }
};
