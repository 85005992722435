import {
  Box,
  Typography,
  TextareaAutosize,
  Grid,
  Rating,
  TextField,
  FormGroup,
  Checkbox,
} from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../../components/dasboard/sidebar";
import Header from "../../../components/header";
import { getBookingByIdApi } from "../../../services/booking/getBooking";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Buttons from "../../../components/form/button";
import FilledInput from "@mui/material/FilledInput";
import InputLabel from "@mui/material/InputLabel";
import { additionalPaymentsApi } from "../../../services/booking/additionalPayments";
import { useSnackbar } from "notistack";
import Upload from "../../../components/form/upload";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import NightlightRoundIcon from "@mui/icons-material/NightlightRound";
import moment from "moment";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import getUserCardApi from "../../../services/User/getUserCard";
import { getUserWalletApi } from "../../../services/wallet/getUserWallet";
import * as yup from "yup";
import { Dropdown } from "primereact/dropdown";
import { options } from "./../../../constants/countries";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import PaymentIcon from "@mui/icons-material/Payment";
import PaymentsIcon from "@mui/icons-material/Payments";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Select from "react-select";
import { FaCcMastercard, FaCcVisa } from "react-icons/fa";

const AdditionalPaymentsPage = () => {
  const userType = localStorage.getItem("userHost");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [booking, setBooking] = useState({});
  const toast = useRef(null);
  const { bookingId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const getBooking = async () => {
    const res = await getBookingByIdApi(bookingId);
    setBooking(res);
  };
  const navigate = useNavigate();
  useEffect(() => {
    getBooking();
  }, []);
  const [screen, setScreen] = useState(0);
  const [attachment, setAttachment] = useState([]);
  const [attachmentUrl, setAttachmentUrl] = useState([]);
  const [amountError, setAmountError] = useState();
  const nextScreen = () => {
    setScreen(screen + 1);
  };
  const prevScreen = () => {
    setScreen(screen - 1);
  };
  const payReason = useFormik({
    initialValues: {
      paymentReason: "DAMAGE",
    },
    enableReinitialize: true,
    onSubmit: () => {
      nextScreen();
    },
  });
  const payType = useFormik({
    initialValues: {
      paymentType: "EXTRASERVICE",
    },
    enableReinitialize: true,
    onSubmit: () => {
      nextScreen();
    },
  });
  const payment = useFormik({
    initialValues: {
      Amount: 0,
      Description: "",
      PaymentType: payType.values.paymentType,
      PaymentReason: payReason.values.paymentReason,
      bookingId: parseInt(bookingId),
      userId: parseInt(userData?.id),
      RequestFrom: !(userType === "true") ? "GUEST" : "HOST",
    },
    onSubmit: async (values) => {
      if (values.Amount <= 0) {
        setAmountError("Amount cannot be less than or equal to zero.");
        setDialog(false);
      } else {
        var formData = new FormData();
        formData.append("Amount", values.Amount);
        formData.append("Description", values.Description);
        formData.append("attachments", attachment);
        formData.append("bookingId", values.bookingId);
        formData.append("userId", values.userId);
        formData.append("PaymentType", values.PaymentType);
        formData.append("PaymentReason", values.PaymentReason);
        formData.append("RequestedFrom", values.RequestFrom);
        const res = await additionalPaymentsApi(formData);
        if (res.status === 201 || res.status === 200) {
          setDialog(false);
          enqueueSnackbar("Your request has been sent!", {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
          setTimeout(() => {
            navigate("/");
          }, 1500);
        } else {
          setDialog(false);
          enqueueSnackbar("There is an error sending request!", {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
        }
      }
    },
  });

  //========PAY STATES=======//
  const [useCreditCard, setUseCreditCard] = useState(false);
  const [walletLessAmountError, setWalletLessAmountError] = useState(false);
  const [useWallet, setUseWallet] = useState(true);
  const [userCards, setUserCards] = useState([]);
  const [card, setCard] = React.useState({});
  const [wallet, setWallet] = useState({});
  //Check if active card is empty
  const noActiveCard = Object.keys(card).length === 0;
  //Get User Cards
  useEffect(() => {
    const getUserCards = async () => {
      const res = await getUserCardApi(userData?.id);
      let temp = [];
      res?.data.map((item) => {
        return temp.push({
          value: item,
          label: item.last4digit,
          brand: item.brand,
        });
      });
      setUserCards(temp);
    };
    getUserCards();
  }, [userData?.id]);

  //Get User Wallet Api
  useEffect(() => {
    const getWallet = async () => {
      const res = await getUserWalletApi(userData?.id);
      if (res.status === 404 || res.status === 401 || res.status === 403) {
        setWallet({});
      } else {
        setWallet(res);
      }
    };
    getWallet();
  }, [userData?.id]);

  //Set use wallet to false if wallet doesnt exist or wallet amount is 0
  useEffect(() => {
    if (parseInt(wallet?.amount ? wallet?.amount : 0) <= 0) {
      setUseWallet(false);
    } else if (parseInt(wallet?.amount ? wallet?.amount : 0) > 0) {
      setUseWallet(true);
    }
  }, [wallet?.amount]);

  //Country options dropdown templates
  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className='country-item country-item-value'>
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className='country-item'>
        <div>{option.name}</div>
      </div>
    );
  };
  //Check If User wallet amount is less than total amount and show prompt
  useEffect(() => {
    if (parseInt(wallet?.amount ? wallet?.amount : 0) < payment.values.amount) {
      setWalletLessAmountError(
        "Your wallet has insufficient balance for this payment. Use a card instead or select a card to pay the rest of the amount."
      );
    } else {
      setWalletLessAmountError("");
    }
  }, [wallet?.amount, payment.values.amount]);

  useEffect(() => {
    if (attachment.length > 0) {
      const newAttachments = [];
      attachment.forEach((fPic) =>
        newAttachments.push(URL.createObjectURL(fPic))
      );
      setAttachmentUrl(newAttachments);
    }
  }, [attachment]);

  const onAttachmentChange = (e) => {
    setAttachment((data) => [...data, ...e.target.files]);
  };
  const clearAttachment = (imageSrc) => {
    const filterImage = attachmentUrl.filter((name) => name !== imageSrc);
    setAttachmentUrl(filterImage);
    const newImageUrls = [];
    filterImage.forEach((image) =>
      newImageUrls.push(URL.createObjectURL(image))
    );
    setAttachment(newImageUrls);
  };

  const [dialog, setDialog] = useState(false);

  //=========PAYMENTS=========//
  //FORMIK
  const formik = useFormik({
    initialValues: {
      number: "",
      name: "",
      exp_month: "",
      exp_year: "",
      cvc: "",
      user_id: userData?.id,
      use_save_card: false,
      address1: "",
      address2: "",
      city: "",
      state: "",
      zipcode: "",
      country: "",
    },
    enableReinitialize: true,
    ...(noActiveCard &&
      useCreditCard && {
        validationSchema: () => {
          return yup.object({
            name: yup.string().required("Please enter your name!"),
            number: yup
              .string()
              .min(16, "Card number must be only 16 characters")
              .max(16, "Card number must be only 16 characters")
              .required("Please enter your card number!"),
            exp_month: yup
              .string()
              .required("Please enter your card expiry month!"),
            exp_year: yup
              .string()
              .required("Please enter your card expiry year!"),
            cvc: yup
              .string()
              .min(3, "CVC must be at least 3 characters!")
              .max(4, "CVC can be only 4 characters!")
              .required("Please enter your card CVC!"),
            address1: yup.string().required("Street Address is required!"),
            address2: yup.string().required("Apt or suite number is required!"),
            city: yup.string().required("City is required!"),
            zipcode: yup.string().required("Zip Code is required!"),
            state: yup.string().required("State is required!"),
          });
        },
      }),
    onSubmit: async () => {},
  });
  //Set wallet usage func
  const setUseWalletFunc = () => {
    if (wallet && parseInt(wallet?.amount) > 0) {
      setUseWallet(!useWallet);
    } else {
      setWalletLessAmountError(
        "You dont have enough balance in wallet! Please consider topping up the wallet!"
      );
    }
  };

  //set credit card add usage
  const setUseCreditCardFunc = () => {
    setUseCreditCard(!useCreditCard);
    setCard({});
  };

  //Custom styles for dropdown
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: "black",
      fontSize: "16px",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      const color = "#fff";
      return { ...provided, opacity, transition, color };
    },
  };

  return (
    <section>
      <Header />
      <div id='wrapper'>
        <div className='dashboard-menu-overlay' />
        <Sidebar page={"Additional Payments"} />
        <ConfirmationDialog
          message='Are you sure you want to proceed?'
          header='Confirmation'
          icon='pi pi-exclamation-triangle'
          open={dialog}
          onHide={() => setDialog(false)}
          onConfirm={payment.handleSubmit}
          confirmButtonLoading={false}
          confirmButtonColor='success'
        />
        <div className='dashboard-content'>
          {(() => {
            switch (screen) {
              case 0:
                return (
                  <Box>
                    <Box
                      sx={{
                        width: "100%",
                        borderBottom: "1px solid #FFFFFF80",
                      }}
                    >
                      <Typography
                        sx={{
                          width: "100%",
                          fontSize: "25px",
                          fontFamily: "Jost",
                          color: "#fff",
                          padding: "10px 0px",
                        }}
                      >
                        Additional Payments
                        <div style={{ fontSize: "16px", color: "#878c9f" }}>
                          Reachout to staywo regarding extra fee or refund.
                        </div>
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        width: "100%",
                        padding: "1% 0px",
                      }}
                    >
                      <Typography
                        sx={{
                          width: "100%",
                          fontSize: "20px",
                          fontFamily: "Jost",
                          color: "#fff",
                          padding: "15px 0px",
                        }}
                      >
                        Request Money from
                      </Typography>
                      <Grid md={5} item>
                        <Box
                          sx={{
                            borderRadius: "8px",
                            border: "1px solid rgba(255, 255, 255, 0.1)",
                            padding: "20px",
                          }}
                        >
                          <Box sx={{ display: "flex" }}>
                            <Box>
                              <img
                                src={booking?.property?.thumbnailUrl}
                                alt='property_image'
                                width={150}
                                height={150}
                              />
                            </Box>
                            <Box sx={{ marginLeft: "10px" }}>
                              <Typography
                                sx={{
                                  color: "#fff",
                                  fontFamily: "Jost",
                                  fontSize: "22px",
                                }}
                              >
                                {booking?.property?.title &&
                                booking?.property?.title?.length > 30
                                  ? booking?.property?.title?.slice(0, 30) +
                                    "..."
                                  : booking?.property?.title?.slice(0, 30)}
                              </Typography>
                              <Typography
                                sx={{ fontFamily: "Jost", fontSize: "14px" }}
                              >
                                {booking?.property?.city},{" "}
                                {booking?.property?.country}
                              </Typography>
                              <Typography
                                sx={{ fontFamily: "Jost", fontSize: "14px" }}
                              >
                                Property ID: {booking?.property?.code}
                              </Typography>
                              <Typography
                                sx={{
                                  fontFamily: "Jost",
                                  fontSize: "16px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Rating
                                  name='half-rating-read'
                                  value={
                                    booking?.property?.average_rating
                                      ? booking?.property?.average_rating
                                          ?.averageRating
                                      : 0
                                  }
                                  precision={0.5}
                                  readOnly
                                  sx={{ marginRight: "4px" }}
                                />
                                (
                                {booking?.property?.average_rating
                                  ? parseInt(
                                      booking?.property?.average_rating
                                        ?.averageRating
                                    ).toFixed(1)
                                  : 0}
                                )
                                {booking?.property?.average_rating
                                  ? ` (${booking?.property?.average_rating?.reviewCount} Reviews)`
                                  : " (0 Reviews)"}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            width: "100%",
                            padding: "1% 0px",
                          }}
                        >
                          <Typography
                            sx={{
                              width: "100%",
                              fontSize: "20px",
                              fontFamily: "Jost",
                              color: "#fff",
                              padding: "15px 0px",
                            }}
                          >
                            Booking
                          </Typography>
                          <Grid md={5} item>
                            <Box
                              sx={{
                                borderRadius: "8px",
                                border: "1px solid rgba(255, 255, 255, 0.1)",
                                padding: "20px",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography
                                  sx={{ fontSize: "16px", color: "white" }}
                                >
                                  Booking nights
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {moment(booking?.startDate).format(
                                    "DD MMM YYYY"
                                  )}{" "}
                                  -{" "}
                                  {moment(booking?.endDate).format(
                                    "DD MMM YYYY"
                                  )}{" "}
                                  ({" "}
                                  <NightlightRoundIcon
                                    sx={{ fontSize: "15px", marginLeft: "5px" }}
                                  />{" "}
                                  {booking?.numberOfNights} night
                                  {booking?.numberOfNights > 1 ? "s" : ""})
                                </Typography>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    padding: "14px 0px",
                                  }}
                                >
                                  <Typography>
                                    <Typography
                                      sx={{ fontSize: "16px", color: "white" }}
                                    >
                                      Guests
                                    </Typography>
                                    <Typography sx={{ fontSize: "14px" }}>
                                      <span style={{ color: "#fff" }}>
                                        {booking?.totalGuests}
                                      </span>{" "}
                                      guests ({booking?.totalAdults} adults,{" "}
                                      {booking?.totalChildren} children,{" "}
                                      {booking?.totalInfants} infants,{" "}
                                      {booking?.totalPets} pets)
                                    </Typography>
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    paddingBottom: "14px",
                                  }}
                                >
                                  <Typography>
                                    <Typography
                                      sx={{ fontSize: "16px", color: "white" }}
                                    >
                                      Check In Time
                                    </Typography>
                                    <Typography sx={{ fontSize: "14px" }}>
                                      {
                                        booking?.property?.propertyPolicy
                                          ?.checkInWindow
                                      }
                                    </Typography>
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    paddingBottom: "14px",
                                  }}
                                >
                                  <Typography>
                                    <Typography
                                      sx={{ fontSize: "16px", color: "white" }}
                                    >
                                      Check Out
                                    </Typography>
                                    <Typography sx={{ fontSize: "14px" }}>
                                      {
                                        booking?.property?.propertyPolicy
                                          ?.checkoutWindow
                                      }
                                    </Typography>
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    paddingBottom: "14px",
                                  }}
                                >
                                  <Typography>
                                    <Typography
                                      sx={{ fontSize: "16px", color: "white" }}
                                    >
                                      Booking Date
                                    </Typography>
                                    <Typography sx={{ fontSize: "14px" }}>
                                      {moment(booking?.createdOn).format(
                                        "DD MMM YYYY"
                                      )}
                                    </Typography>
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    paddingBottom: "14px",
                                  }}
                                >
                                  <Typography>
                                    <Typography
                                      sx={{ fontSize: "16px", color: "white" }}
                                    >
                                      Confirmation Code
                                    </Typography>
                                    <Typography sx={{ fontSize: "14px" }}>
                                      {booking?.code}
                                    </Typography>
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "start",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "#fff",
                                      fontSize: "19px",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <DoneOutlineIcon
                                      sx={{
                                        fontSize: "19px",
                                        marginRight: "5px",
                                      }}
                                    />{" "}
                                    Total -
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "18px",
                                      color: "#fff",
                                      marginLeft: "12px",
                                    }}
                                  >
                                    {process.env.REACT_APP_CURRENCY}{" "}
                                    {booking?.grossAmount
                                      ? booking?.grossAmount?.toLocaleString(
                                          "en-US"
                                        )
                                      : 0}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                        </Box>
                      </Grid>
                      <Typography
                        sx={{
                          width: "100%",
                          fontSize: "20px",
                          fontFamily: "Jost",
                          color: "#fff",
                          padding: "15px 0px",
                        }}
                      >
                        What is this request for?
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "start",
                        }}
                      >
                        <FormControl>
                          <RadioGroup
                            aria-labelledby='demo-radio-buttons-group-label'
                            name={"paymentReason"}
                            onChange={payReason.handleChange}
                            value={payReason.values.paymentReason}
                            defaultValue={payReason.values.paymentReason}
                          >
                            <FormControlLabel
                              value='DAMAGE'
                              control={<Radio />}
                              label='Damage, Missing Items, or Unexpected Cleaning'
                              sx={{
                                color: "#878d9f",
                              }}
                            />

                            <FormControlLabel
                              value='ADDITIONAL_SERVICES'
                              control={<Radio />}
                              label='Extra Services'
                              sx={{
                                color: "#878d9f",
                              }}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "end",
                        }}
                      >
                        <Buttons
                          title={"Next"}
                          isLoading={false}
                          variant={"contained"}
                          onSubmit={nextScreen}
                          style={{ margin: "10px 0px", width: "40%" }}
                        />
                      </Box>
                    </Box>
                  </Box>
                );
              case 1:
                return (
                  <Box>
                    <Box
                      sx={{
                        width: "100%",
                        borderBottom: "1px solid #FFFFFF80",
                      }}
                    >
                      <Typography
                        sx={{
                          width: "100%",
                          fontSize: "25px",
                          fontFamily: "Jost",
                          color: "#fff",
                          padding: "10px 0px",
                        }}
                      >
                        Additional Payments
                        <div style={{ fontSize: "16px", color: "#878c9f" }}>
                          Reachout to staywo regarding extra fee or refund.
                        </div>
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        width: "100%",
                        padding: "1% 0px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          padding: "1% 0px",
                        }}
                      >
                        <Typography
                          sx={{
                            width: "100%",
                            fontSize: "20px",
                            fontFamily: "Jost",
                            color: "#fff",
                            padding: "15px 0px",
                          }}
                        >
                          Request Money from
                        </Typography>
                        <Grid md={5} item>
                          <Box
                            sx={{
                              borderRadius: "8px",
                              border: "1px solid rgba(255, 255, 255, 0.1)",
                              padding: "20px",
                            }}
                          >
                            <Box sx={{ display: "flex" }}>
                              <Box>
                                <img
                                  src={booking?.property?.thumbnailUrl}
                                  alt='property_image'
                                  width={150}
                                  height={150}
                                />
                              </Box>
                              <Box sx={{ marginLeft: "10px" }}>
                                <Typography
                                  sx={{
                                    color: "#fff",
                                    fontFamily: "Jost",
                                    fontSize: "22px",
                                  }}
                                >
                                  {booking?.property?.title &&
                                  booking?.property?.title?.length > 30
                                    ? booking?.property?.title?.slice(0, 30) +
                                      "..."
                                    : booking?.property?.title?.slice(0, 30)}
                                </Typography>
                                <Typography
                                  sx={{ fontFamily: "Jost", fontSize: "14px" }}
                                >
                                  {booking?.property?.city},{" "}
                                  {booking?.property?.country}
                                </Typography>
                                <Typography
                                  sx={{ fontFamily: "Jost", fontSize: "14px" }}
                                >
                                  Property ID: {booking?.property?.code}
                                </Typography>
                                <Typography
                                  sx={{
                                    fontFamily: "Jost",
                                    fontSize: "16px",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Rating
                                    name='half-rating-read'
                                    value={
                                      booking?.property?.average_rating
                                        ? booking?.property?.average_rating
                                            ?.averageRating
                                        : 0
                                    }
                                    precision={0.5}
                                    readOnly
                                    sx={{ marginRight: "4px" }}
                                  />
                                  (
                                  {booking?.property?.average_rating
                                    ? parseInt(
                                        booking?.property?.average_rating
                                          ?.averageRating
                                      ).toFixed(1)
                                    : 0}
                                  )
                                  {booking?.property?.average_rating
                                    ? ` (${booking?.property?.average_rating?.reviewCount} Reviews)`
                                    : " (0 Reviews)"}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          padding: "1% 0px",
                        }}
                      >
                        <Typography
                          sx={{
                            width: "100%",
                            fontSize: "20px",
                            fontFamily: "Jost",
                            color: "#fff",
                            padding: "15px 0px",
                          }}
                        >
                          Booking
                        </Typography>
                        <Grid md={5} item>
                          <Box
                            sx={{
                              borderRadius: "8px",
                              border: "1px solid rgba(255, 255, 255, 0.1)",
                              padding: "20px",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Typography
                                sx={{ fontSize: "16px", color: "white" }}
                              >
                                Booking nights
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {moment(booking?.startDate).format(
                                  "DD MMM YYYY"
                                )}{" "}
                                -{" "}
                                {moment(booking?.endDate).format("DD MMM YYYY")}{" "}
                                ({" "}
                                <NightlightRoundIcon
                                  sx={{ fontSize: "15px", marginLeft: "5px" }}
                                />{" "}
                                {booking?.numberOfNights} night
                                {booking?.numberOfNights > 1 ? "s" : ""})
                              </Typography>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  padding: "14px 0px",
                                }}
                              >
                                <Typography>
                                  <Typography
                                    sx={{ fontSize: "16px", color: "white" }}
                                  >
                                    Guests
                                  </Typography>
                                  <Typography sx={{ fontSize: "14px" }}>
                                    <span style={{ color: "#fff" }}>
                                      {booking?.totalGuests}
                                    </span>{" "}
                                    guests ({booking?.totalAdults} adults,{" "}
                                    {booking?.totalChildren} children,{" "}
                                    {booking?.totalInfants} infants,{" "}
                                    {booking?.totalPets} pets)
                                  </Typography>
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  paddingBottom: "14px",
                                }}
                              >
                                <Typography>
                                  <Typography
                                    sx={{ fontSize: "16px", color: "white" }}
                                  >
                                    Check In Time
                                  </Typography>
                                  <Typography sx={{ fontSize: "14px" }}>
                                    {
                                      booking?.property?.propertyPolicy
                                        ?.checkInWindow
                                    }
                                  </Typography>
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  paddingBottom: "14px",
                                }}
                              >
                                <Typography>
                                  <Typography
                                    sx={{ fontSize: "16px", color: "white" }}
                                  >
                                    Check Out
                                  </Typography>
                                  <Typography sx={{ fontSize: "14px" }}>
                                    {
                                      booking?.property?.propertyPolicy
                                        ?.checkoutWindow
                                    }
                                  </Typography>
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  paddingBottom: "14px",
                                }}
                              >
                                <Typography>
                                  <Typography
                                    sx={{ fontSize: "16px", color: "white" }}
                                  >
                                    Booking Date
                                  </Typography>
                                  <Typography sx={{ fontSize: "14px" }}>
                                    {moment(booking?.createdOn).format(
                                      "DD MMM YYYY"
                                    )}
                                  </Typography>
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  paddingBottom: "14px",
                                }}
                              >
                                <Typography>
                                  <Typography
                                    sx={{ fontSize: "16px", color: "white" }}
                                  >
                                    Confirmation Code
                                  </Typography>
                                  <Typography sx={{ fontSize: "14px" }}>
                                    {booking?.code}
                                  </Typography>
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "start",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#fff",
                                    fontSize: "19px",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <DoneOutlineIcon
                                    sx={{
                                      fontSize: "19px",
                                      marginRight: "5px",
                                    }}
                                  />{" "}
                                  Total -
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "18px",
                                    color: "#fff",
                                    marginLeft: "12px",
                                  }}
                                >
                                  {process.env.REACT_APP_CURRENCY}{" "}
                                  {booking?.grossAmount
                                    ? booking?.grossAmount?.toLocaleString(
                                        "en-US"
                                      )
                                    : 0}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                      </Box>
                      <Typography
                        sx={{
                          width: "100%",
                          fontSize: "20px",
                          fontFamily: "Jost",
                          color: "#fff",
                          padding: "15px 0px",
                        }}
                      >
                        What would you like to do?
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "start",
                        }}
                      >
                        <FormControl>
                          <RadioGroup
                            aria-labelledby='demo-radio-buttons-group-label'
                            name={"paymentType"}
                            onChange={payType.handleChange}
                            value={payType.values.paymentType}
                            defaultValue={payType.values.paymentType}
                          >
                            <FormControlLabel
                              value='EXTRASERVICE'
                              control={<Radio />}
                              label='Send Money'
                              sx={{
                                color: "#878d9f",
                              }}
                            />

                            <FormControlLabel
                              value='CLAIM'
                              control={<Radio />}
                              label='Request Money'
                              sx={{
                                color: "#878d9f",
                              }}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "end",
                        }}
                      >
                        <Buttons
                          title={"Back"}
                          isLoading={false}
                          variant={"outlined"}
                          onSubmit={prevScreen}
                          style={{ margin: "10px 4px", width: "20%" }}
                        />
                        <Buttons
                          title={"Next"}
                          isLoading={false}
                          variant={"contained"}
                          onSubmit={payType.handleSubmit}
                          style={{ margin: "10px 0px", width: "20%" }}
                        />
                      </Box>
                    </Box>
                  </Box>
                );
              case 2:
                return (
                  <Box>
                    <Box
                      sx={{
                        width: "100%",
                        borderBottom: "1px solid #FFFFFF80",
                      }}
                    >
                      <Typography
                        sx={{
                          width: "100%",
                          fontSize: "25px",
                          fontFamily: "Jost",
                          color: "#fff",
                          padding: "10px 0px",
                        }}
                      >
                        Additional Payments
                        <div style={{ fontSize: "16px", color: "#878c9f" }}>
                          Reachout to staywo regarding extra fee or refund.
                        </div>
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        width: "100%",
                        padding: "1% 0px",
                      }}
                    >
                      <Typography
                        sx={{
                          width: "100%",
                          fontSize: "20px",
                          fontFamily: "Jost",
                          color: "#fff",
                          padding: "15px 0px",
                        }}
                      >
                        {!(userType === "true")
                          ? payType.values.paymentType === "DAMAGE"
                            ? "How Much would you like to request from Host?"
                            : "How Much would you like to send to the Host?"
                          : payType.values.paymentType === "DAMAGE"
                          ? "How much would you like to request from the Guest?"
                          : "How much would you like to send to the Guest?"}
                      </Typography>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "start",
                          flexDirection: "column",
                          alignItems: "start",
                          textAlign: "start",
                        }}
                      >
                        <FormControl fullWidth sx={{ m: 1 }} variant='filled'>
                          <InputLabel htmlFor='amount'>Amount</InputLabel>
                          <FilledInput
                            id='Amount'
                            name='Amount'
                            value={payment.values.Amount}
                            onChange={payment.handleChange}
                            type='number'
                          />
                        </FormControl>
                        {amountError && (
                          <div style={{ fontSize: "14px", color: "red" }}>
                            {amountError}
                          </div>
                        )}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        width: "100%",
                        padding: "1% 0px",
                      }}
                    >
                      <Typography
                        sx={{
                          width: "100%",
                          fontSize: "20px",
                          fontFamily: "Jost",
                          color: "#fff",
                          padding: "15px 0px",
                        }}
                      >
                        Write a Message
                      </Typography>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "start",
                          flexDirection: "column",
                          alignItems: "start",
                          textAlign: "start",
                        }}
                      >
                        <TextareaAutosize
                          aria-label='Description'
                          id='Description'
                          name='Description'
                          value={payment.values.Description}
                          onChange={payment.handleChange}
                          minRows={8}
                          placeholder='Type here.'
                          style={{
                            width: "100%",
                            background: "none",
                            borderRadius: "8px",
                            padding: "8px",
                            color: "#fff",
                          }}
                        />
                      </Box>
                      <Box>
                        <Box
                          sx={{
                            width: "100%",
                          }}
                        >
                          <Typography
                            sx={{
                              width: "100%",
                              fontSize: "25px",
                              fontFamily: "Jost",
                              color: "#fff",
                              padding: "20px 0px",
                            }}
                          >
                            Attachments
                            <div style={{ fontSize: "16px", color: "#878c9f" }}>
                              Damage, Missing Items, or Unexpected Cleaning
                            </div>
                          </Typography>
                          <Upload
                            label={"Upload Attachments Images"}
                            handleChange={onAttachmentChange}
                            disabled={false}
                            multiple={true}
                          />
                          <div style={{ display: "inline" }}>
                            {attachmentUrl?.map((attSrc, i) => (
                              <div className='image-div' key={i}>
                                <img
                                  src={attSrc}
                                  style={{
                                    maxWidth: "100%",
                                    height: "100%",
                                  }}
                                  alt='attachment'
                                />
                                <div
                                  className='clear-button '
                                  onClick={() => clearAttachment(attSrc)}
                                >
                                  <i
                                    className='fa fa-times'
                                    style={{
                                      cursor: "pointer",
                                      color: "#2e94f7",
                                    }}
                                  ></i>
                                </div>
                              </div>
                            ))}
                          </div>
                        </Box>
                      </Box>
                      {/* payment  */}
                      {payType.values.paymentType === "EXTRASERVICE" && (
                        <Box
                          sx={{
                            display: "flex",
                            color: "#fff",
                            flexDirection: "column",
                            marginLeft: "15px",
                            border: "1px solid rgba(255, 255, 255, 0.1)",
                            padding: "12px",
                            borderRadius: "8px",
                            marginTop: "15px",
                          }}
                        >
                          <Box>
                            <>
                              <form>
                                <Toast ref={toast} position='bottom-right' />
                                <Typography
                                  sx={{
                                    color: "#fff",
                                    fontSize: "22px",
                                    fontFamily: "Jost",
                                    display: "flex",
                                    alignItems: "center",
                                    paddingTop: "15px",
                                    paddingBottom: "15px",
                                  }}
                                >
                                  <PaymentIcon
                                    sx={{
                                      fontSize: "22px",
                                      marginRight: "5px",
                                    }}
                                  />{" "}
                                  Pay With
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "#fff",
                                    fontSize: "22px",
                                    fontFamily: "Jost",
                                    display: "flex",
                                    alignItems: "center",
                                    // margin: '12px 0px',
                                    paddingTop: "12px",
                                    borderTop:
                                      "1px solid rgba(255, 255, 255, 0.1)",
                                  }}
                                >
                                  Your Wallet
                                </Typography>
                                <Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: "25px",
                                        color: "#fff",
                                      }}
                                    >
                                      <PaymentsIcon
                                        style={{
                                          marginRight: "16px",
                                          fontSize: "18px",
                                          color: "#fff",
                                        }}
                                      />
                                      <span
                                        style={{
                                          display: "inline-flex",
                                          alignItems: "center",
                                          color: "rgba(255, 255, 255, 0.7)",
                                        }}
                                      >
                                        {process.env.REACT_APP_CURRENCY}{wallet?.amount
                                          ? wallet?.amount?.toLocaleString(
                                              "en-US"
                                            )
                                          : 0}{" "}
                                        <AddCircleIcon
                                          style={{
                                            marginRight: "16px",
                                            fontSize: "25px",
                                            color: "rgba(255, 255, 255, 0.7)",
                                            cursor: "pointer",
                                            marginLeft: "10px",
                                          }}
                                          onClick={() =>
                                            navigate("/wallet/top-up")
                                          }
                                        />
                                      </span>
                                    </Typography>
                                  </Box>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={useWallet ? true : false}
                                          value={useWallet}
                                          onChange={setUseWalletFunc}
                                        />
                                      }
                                      label='Use your wallet.'
                                    />
                                  </FormGroup>
                                  {walletLessAmountError && (
                                    <Typography
                                      sx={{
                                        color: "yellow",
                                        fontSize: "16px",
                                        fontFamily: "Jost",
                                        display: "flex",
                                        alignItems: "center",
                                        marginTop: "12px",
                                        fontWeight: "400",
                                      }}
                                    >
                                      <span
                                        style={{
                                          color: "#fff",
                                          fontWeight: "bold",
                                          marginRight: "4px",
                                        }}
                                      >
                                        Info:
                                      </span>{" "}
                                      {walletLessAmountError}
                                    </Typography>
                                  )}
                                </Box>

                                <Typography
                                  sx={{
                                    color: "#fff",
                                    fontSize: "22px",
                                    fontFamily: "Jost",
                                    display: "flex",
                                    alignItems: "center",
                                    paddingTop: "12px",
                                    borderTop:
                                      "1px solid rgba(255, 255, 255, 0.1)",
                                    marginTop: "12px",
                                  }}
                                >
                                  Your Card
                                </Typography>
                                {userCards?.length > 0 ? (
                                  <div style={{ width: "50%" }}>
                                    <Select
                                      className='override-select-edit'
                                      placeholder='Select Card'
                                      options={userCards}
                                      value={
                                        card.value == undefined
                                          ? { label: "Select Card", value: "" }
                                          : card
                                      }
                                      onChange={(option) => setCard(option)}
                                      styles={customStyles}
                                      formatOptionLabel={(item) => (
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          {item?.brand === "Visa" && (
                                            <FaCcVisa
                                              style={{
                                                marginRight: "8px",
                                                fontSize: "25px",
                                              }}
                                            />
                                          )}
                                          {item?.brand === "MasterCard" && (
                                            <FaCcMastercard
                                              style={{
                                                marginRight: "8px",
                                                fontSize: "25px",
                                              }}
                                            />
                                          )}

                                          {item?.brand}
                                          <span
                                            style={{ marginLeft: "10px" }}
                                          >{`${item?.label}`}</span>
                                        </div>
                                      )}
                                    />
                                  </div>
                                ) : (
                                  <Typography
                                    sx={{
                                      color: "white",
                                      fontSize: "16px",
                                      fontFamily: "Jost",
                                      display: "flex",
                                      alignItems: "center",
                                      marginTop: "12px",
                                      fontWeight: "400",
                                    }}
                                  >
                                    You dont have any cards! Add one by clicking
                                    below.
                                  </Typography>
                                )}
                                <Grid
                                  container
                                  spacing={{ xs: 2, md: 3 }}
                                  columns={{ xs: 4, sm: 8, md: 12 }}
                                >
                                  <Grid item xs={12} sm={12} md={12}>
                                    <Typography
                                      onClick={setUseCreditCardFunc}
                                      sx={{
                                        cursor: "pointer",
                                        color: "#fff",
                                        textDecoration: "underline",
                                      }}
                                    >
                                      {useCreditCard
                                        ? "Use Saved Credit Card"
                                        : "Add Credit Card"}
                                    </Typography>
                                  </Grid>

                                  {useCreditCard && (
                                    <>
                                      <Grid item xs={12}>
                                        <Typography
                                          sx={{
                                            color: "#fff",
                                            fontSize: "22px",
                                            fontFamily: "Jost",
                                            display: "flex",
                                            alignItems: "center",
                                            paddingTop: "12px",
                                            borderTop:
                                              "1px solid rgba(255, 255, 255, 0.1)",
                                            marginTop: "12px",
                                          }}
                                        >
                                          Card Details
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12} sm={6} md={6}>
                                        <Typography sx={{ textAlign: "left" }}>
                                          Card Number
                                        </Typography>
                                        <TextField
                                          sx={{ width: "100%" }}
                                          id='number'
                                          name='number'
                                          number
                                          required
                                          placeholder='4242424242424242'
                                          value={formik.values.number}
                                          onChange={formik.handleChange}
                                        />
                                        {formik.touched.number &&
                                          Boolean(formik.errors.number) && (
                                            <small
                                              id='username2-help'
                                              className='p-error'
                                              style={{
                                                display: "block",
                                                color: "red",
                                                textAlign: "left",
                                              }}
                                            >
                                              {formik.errors.number}
                                            </small>
                                          )}
                                      </Grid>
                                      <Grid item xs={12} sm={6} md={6}>
                                        <Typography sx={{ textAlign: "left" }}>
                                          Card Holder Name
                                        </Typography>
                                        <TextField
                                          sx={{ width: "100%" }}
                                          id='name'
                                          name='name'
                                          required
                                          placeholder='Card Holder Name'
                                          value={formik.values.name}
                                          onChange={formik.handleChange}
                                        />
                                        {formik.touched.name &&
                                          Boolean(formik.errors.name) && (
                                            <small
                                              id='username2-help'
                                              className='p-error'
                                              style={{
                                                display: "block",
                                                color: "red",
                                                textAlign: "left",
                                              }}
                                            >
                                              {formik.errors.name}
                                            </small>
                                          )}
                                      </Grid>
                                      <Grid item xs={12} sm={4} md={4}>
                                        <Typography sx={{ textAlign: "left" }}>
                                          Expiration Month
                                        </Typography>
                                        <TextField
                                          sx={{ width: "100%" }}
                                          id='exp_month'
                                          name='exp_month'
                                          required
                                          number
                                          placeholder='03'
                                          value={formik.values.exp_month}
                                          onChange={formik.handleChange}
                                        />
                                        {formik.touched.exp_month &&
                                          Boolean(formik.errors.exp_month) && (
                                            <small
                                              id='username2-help'
                                              className='p-error'
                                              style={{
                                                display: "block",
                                                color: "red",
                                                textAlign: "left",
                                              }}
                                            >
                                              {formik.errors.exp_month}
                                            </small>
                                          )}
                                      </Grid>
                                      <Grid item xs={12} sm={4} md={4}>
                                        <Typography sx={{ textAlign: "left" }}>
                                          Expiration Years
                                        </Typography>
                                        <TextField
                                          sx={{ width: "100%" }}
                                          id='exp_year'
                                          name='exp_year'
                                          required
                                          number
                                          placeholder='10'
                                          value={formik.values.exp_year}
                                          onChange={formik.handleChange}
                                        />
                                        {formik.touched.exp_year &&
                                          Boolean(formik.errors.exp_year) && (
                                            <small
                                              id='username2-help'
                                              className='p-error'
                                              style={{
                                                display: "block",
                                                color: "red",
                                                textAlign: "left",
                                              }}
                                            >
                                              {formik.errors.exp_year}
                                            </small>
                                          )}
                                      </Grid>
                                      <Grid item xs={12} sm={4} md={4}>
                                        <Typography sx={{ textAlign: "left" }}>
                                          CVC
                                        </Typography>
                                        <TextField
                                          sx={{ width: "100%" }}
                                          id='cvc'
                                          name='cvc'
                                          required
                                          number
                                          placeholder='10'
                                          value={formik.values.cvc}
                                          onChange={formik.handleChange}
                                        />
                                        {formik.touched.cvc &&
                                          Boolean(formik.errors.cvc) && (
                                            <small
                                              id='username2-help'
                                              className='p-error'
                                              style={{
                                                display: "block",
                                                color: "red",
                                                textAlign: "left",
                                              }}
                                            >
                                              {formik.errors.cvc}
                                            </small>
                                          )}
                                      </Grid>

                                      <Grid item xs={12}>
                                        <Typography
                                          sx={{
                                            color: "#fff",
                                            fontSize: "22px",
                                            fontFamily: "Jost",
                                            display: "flex",
                                            alignItems: "center",
                                            paddingTop: "12px",
                                            borderTop:
                                              "1px solid rgba(255, 255, 255, 0.1)",
                                            marginTop: "12px",
                                          }}
                                        >
                                          Billing Address
                                        </Typography>
                                      </Grid>

                                      <Grid item xs={12}>
                                        <Typography sx={{ textAlign: "left" }}>
                                          Street Address
                                        </Typography>
                                        <TextField
                                          sx={{ width: "100%" }}
                                          id='address1'
                                          name='address1'
                                          required
                                          placeholder='Street Address'
                                          value={formik.values.address1}
                                          onChange={formik.handleChange}
                                        />
                                        {formik.touched.address1 &&
                                          Boolean(formik.errors.address1) && (
                                            <small
                                              id='username2-help'
                                              className='p-error'
                                              style={{
                                                display: "block",
                                                color: "red",
                                                textAlign: "left",
                                              }}
                                            >
                                              {formik.errors.address1}
                                            </small>
                                          )}
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Typography sx={{ textAlign: "left" }}>
                                          Apt or suite number
                                        </Typography>
                                        <TextField
                                          sx={{ width: "100%" }}
                                          id='address2'
                                          name='address2'
                                          required
                                          type='text'
                                          placeholder='Enter Suite Number'
                                          value={formik.values.address2}
                                          onChange={formik.handleChange}
                                        />
                                        {formik.touched.address2 &&
                                          Boolean(formik.errors.address2) && (
                                            <small
                                              id='username2-help'
                                              className='p-error'
                                              style={{
                                                display: "block",
                                                color: "red",
                                                textAlign: "left",
                                              }}
                                            >
                                              {formik.errors.address2}
                                            </small>
                                          )}
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Typography sx={{ textAlign: "left" }}>
                                          City
                                        </Typography>
                                        <TextField
                                          sx={{ width: "100%" }}
                                          id='city'
                                          name='city'
                                          required
                                          placeholder='City'
                                          value={formik.values.city}
                                          onChange={formik.handleChange}
                                        />
                                        {formik.touched.city &&
                                          Boolean(formik.errors.city) && (
                                            <small
                                              id='username2-help'
                                              className='p-error'
                                              style={{
                                                display: "block",
                                                color: "red",
                                                textAlign: "left",
                                              }}
                                            >
                                              {formik.errors.city}
                                            </small>
                                          )}
                                      </Grid>
                                      <Grid item xs={12} sm={6}>
                                        <Typography sx={{ textAlign: "left" }}>
                                          State
                                        </Typography>
                                        <TextField
                                          sx={{ width: "100%" }}
                                          id='state'
                                          name='state'
                                          required
                                          placeholder='State'
                                          value={formik.values.state}
                                          onChange={formik.handleChange}
                                        />
                                        {formik.touched.state &&
                                          Boolean(formik.errors.state) && (
                                            <small
                                              id='username2-help'
                                              className='p-error'
                                              style={{
                                                display: "block",
                                                color: "red",
                                                textAlign: "left",
                                              }}
                                            >
                                              {formik.errors.state}
                                            </small>
                                          )}
                                      </Grid>
                                      <Grid item xs={12} sm={6}>
                                        <Typography sx={{ textAlign: "left" }}>
                                          Zip Code
                                        </Typography>
                                        <TextField
                                          sx={{ width: "100%" }}
                                          id='zipcode'
                                          name='zipcode'
                                          required
                                          type='number'
                                          placeholder='Zip Code'
                                          value={formik.values.zipcode}
                                          onChange={formik.handleChange}
                                        />
                                        {formik.touched.zipcode &&
                                          Boolean(formik.errors.zipcode) && (
                                            <small
                                              id='username2-help'
                                              className='p-error'
                                              style={{
                                                display: "block",
                                                color: "red",
                                                textAlign: "left",
                                              }}
                                            >
                                              {formik.errors.zipcode}
                                            </small>
                                          )}
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Dropdown
                                          value={formik.values.country}
                                          options={options}
                                          onChange={formik.handleChange}
                                          optionLabel='name'
                                          filter
                                          showClear
                                          filterBy='name'
                                          name='country'
                                          placeholder='Country/region'
                                          valueTemplate={
                                            selectedCountryTemplate
                                          }
                                          itemTemplate={countryOptionTemplate}
                                          style={{
                                            width: "100%",
                                            background: "transparent",
                                            padding: "6px 5px",
                                          }}
                                        />
                                        {formik.touched.country &&
                                          Boolean(formik.errors.country) && (
                                            <small
                                              id='username2-help'
                                              className='p-error'
                                              style={{
                                                display: "block",
                                                color: "red",
                                                textAlign: "left",
                                              }}
                                            >
                                              {formik.errors.country}
                                            </small>
                                          )}
                                      </Grid>
                                    </>
                                  )}
                                </Grid>
                              </form>
                            </>
                          </Box>
                        </Box>
                      )}
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "end",
                        }}
                      >
                        <Buttons
                          title={"Back"}
                          isLoading={false}
                          variant={"outlined"}
                          onSubmit={prevScreen}
                          style={{ margin: "10px 4px", width: "20%" }}
                        />

                        <Buttons
                          title={"Confirm"}
                          isLoading={false}
                          variant={"contained"}
                          onSubmit={() => setDialog(true)}
                          style={{ margin: "10px 0px", width: "20%" }}
                        />
                      </Box>
                    </Box>
                  </Box>
                );

              default:
                return null;
            }
          })()}
        </div>
        <div className='dashbard-bg gray-bg' />
      </div>
    </section>
  );
};

export default AdditionalPaymentsPage;
