import React, { useEffect, useRef, useState } from 'react';
import Header from '../../../components/header';
import Sidebar from '../../../components/dasboard/sidebar';
import InfoBar from '../../../components/dasboard/infoBar';
import BgInput from '../../../assets/images/bg/3.jpg';
import Input from '../../../components/form/input';
import { useFormik } from 'formik';
import updateProfileApi from '../../../services/User/updateUser';
import { Toast } from 'primereact/toast';
import getUserApi from '../../../services/User/getUser';
import changeUserPassApi from '../../../services/User/changePassword';
import { EditorState, Modifier } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML, convertFromHTML } from 'draft-convert';
import Upload from '../../../components/form/upload';
import { updateUserProfilePicture } from '../../../services/User/updateUserProfile';
import DUMMYIMAGE from '../../../assets/images/avatar/5.jpg';
import './editor.css';
import Buttons from '../../../components/form/button';
import * as yup from 'yup';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { Grid, TextField, Typography } from '@mui/material';
import { Dropdown } from 'primereact/dropdown';
import { options } from './../../../constants/countries';
import { Box } from '@mui/system';
import getUserAddressApi from '../../../services/User/getUserAddress';
import updateUserAddressApi from '../../../services/User/updateUserAddress';
import addUserAddressApi from '../../../services/User/addUserAddress';
import getUserEmergencyContactApi from '../../../services/User/getUserEmergencyContact';
import updateUserEmergencyContactApi from '../../../services/User/updateUserEmergencyContact';
import addUserEmergnecyContactApi from '../../../services/User/addUserEmergencyContact';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { langs } from '../../../constants/languages';

export default function EditProfilePage() {
  const userData = JSON.parse(localStorage.getItem('userData')) || null;
  const [user, setUser] = useState({});
  const [isLoading, setisLoading] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [convertedContent, setConvertedContent] = useState('');
  const [profileImage, setprofileImage] = useState([]);
  const [profileImageUrls, setprofileImageUrls] = useState([]);
  const [previewImage, setPreviewImage] = useState(false);
  const [validationType, setValidationType] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [address, setAddress] = useState({});
  const [emergencyContact, setEmergencyContact] = useState({});
  const [availableAddress, setAvailableAddress] = useState(false);
  const [availableEmergencyContact, setAvailableEmergencyContact] =
    useState(false);
  const [editEmergencyContact, setEditEmergencyContact] = useState(false);
  const [isLoadingAddress, setIsLoadingAddress] = useState(false);
  const [isLoadingEmergencyContact, setIsLoadingEmergencyContact] =
    useState(false);
  let maxLength = 1000;
  const textLength = editorState.getCurrentContent().getPlainText().length;
  const toast = useRef();

  const getUser = async () => {
    const data = await getUserApi(userData?.id);
    setEditorState(
      EditorState.createWithContent(
        convertFromHTML(data?.data?.description ? data?.data?.description : '')
      )
    );
    setUser(data.data);
  };

  const formik = useFormik({
    initialValues: {
      profilePicture: user?.profilePicture,
      firstName: user?.firstName,
      lastName: user?.lastName,
      middleName: user?.middleName,
      email: userData?.email,
      id: user?.id,
      username: user?.username,
      phoneNumber: user?.phoneNumber,
      description: convertedContent ? convertedContent : user?.description,
      gender: { value: userData?.gender, label: userData?.gender },
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      setisLoading(true);
      if (textLength > 1000) {
        setValidationType(true);
        setisLoading(false);
      } else {
        const response = await updateProfileApi({
          profilePicture: values.profilePicture,
          firstName: values.firstName,
          lastName: values.lastName,
          middleName: values.middleName,
          email: values.email,
          id: values.id,
          username: values.username,
          phoneNumber: values.phoneNumber,
          address: values.address,
          description: values.description,
          ...(Object.keys(values.gender).length > 0
            ? { gender: values.gender?.value }
            : ''),
        });
        let newObj = { ...userData };
        newObj['firstName'] = response?.data?.firstName;
        newObj['middleName'] = response?.data?.middleName;
        newObj['lastName'] = response?.data?.lastName;
        newObj['phoneNumber'] = response?.data?.phoneNumber;
        newObj['username'] = response?.data?.username;

        localStorage.setItem('userData', JSON.stringify(newObj));
        var uploadData = new FormData();
        setisLoading(false);
        for (let i = 0; i < profileImage.length; i++) {
          uploadData.append('file', profileImage[i]);
        }
        if (profileImage.length > 0) {
          const responsePicUpdate = await updateUserProfilePicture(
            user?.id,
            uploadData
          );
          let newObj = { ...userData };
          newObj['profilePicture'] =
            responsePicUpdate?.data?.data?.thumbnailUrl;
          localStorage.setItem('userData', JSON.stringify(newObj));
          setprofileImage([]);
        }
        try {
          if (isLoading) {
            toast.current.show({
              severity: 'success',
              summary: 'Updating...',
              detail: 'Your profile is being updated!',
              life: 10000,
            });
          }
          if (response?.status === true) {
            toast.current.show({
              severity: 'success',
              summary: 'Profile Updated!',
              detail: 'Your profile is updated successfully!',
              life: 10000,
            });
          } else {
            toast.current.show({
              severity: 'error',
              summary: 'Cannot update your profile!',
              detail: `${response.data.errors[0]}`,
              life: 10000,
            });
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
  });

  const handleEditorChange = (state) => {
    setValidationType(false);
    setEditorState(state);
    convertContentToHTML();
  };
  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  };
  const passFormik = useFormik({
    initialValues: {
      currentPassword: '',
      password: '',
      confirmPassword: '',
    },
    enableReinitialize: true,
    validationSchema: () => {
      return yup.object({
        currentPassword: yup
          .string()
          .required('Please add your current password!'),
        password: yup.string().required('Please add new password!'),
        confirmPassword: yup
          .string()
          .required('Please re-enter your new password.'),
      });
    },
    onSubmit: async (values) => {
      setisLoading(true);
      const response = await changeUserPassApi(values, userData?.id);
      setisLoading(false);
      if (response?.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Password Updated!',
          detail: 'Your password is updated successfully!',
          life: 10000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Cannot update your password!',
          detail: `${response?.data?.message}`,
          life: 10000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
    },
  });
  useEffect(() => {
    getUser();
  }, []);

  const onImageChange = (e) => {
    setprofileImage((data) => [...data, ...e.target.files]);
    setPreviewImage(true);
  };

  useEffect(() => {
    if (profileImage.length > 0) {
      const newThumbnailUrls = [];
      profileImage.forEach((image) =>
        newThumbnailUrls.push(URL.createObjectURL(image))
      );
      setprofileImageUrls(newThumbnailUrls);
    }
  }, [profileImage]);

  const handleBeforeInput = (val) => {
    if (val && textLength >= maxLength) {
      return 'handled';
    }
    return 'not-handled';
  };

  function handlePastedText(text, _, state) {
    const overflowChars =
      text.length + state.getCurrentContent().getPlainText().length - maxLength;

    if (overflowChars > 0) {
      if (text.length - overflowChars > 0) {
        const newContent = Modifier.insertText(
          state.getCurrentContent(),
          state.getSelection(),
          text.substring(0, text.length - overflowChars)
        );
        setEditorState(
          EditorState.push(state, newContent, 'insert-characters')
        );
      }
      return 'handled';
    } else {
      return 'not-handled';
    }
  }

  //gender options
  const genderOptions = [
    { value: 'MALE', label: 'MALE' },
    { value: 'FEMALE', label: 'FEMALE' },
  ];

  //custom styles for dropdown
  const customStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: '#292e3a',
      border: '1px solid rgba(255, 255, 255, 0.1)',
    }),
    option: (provided, state) => ({
      ...provided,
      color: 'black',
      fontSize: '16px',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      const color = '#fff';
      return {
        ...provided,
        opacity,
        transition,
        color,
      };
    },
  };

  //navigate
  let navigate = useNavigate();

  //Country options dropdown templates
  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className='country-item country-item-value'>
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className='country-item'>
        <div>{option.name}</div>
      </div>
    );
  };
  const selectedCountryCodeTemplate = (option, props) => {
    if (option) {
      return (
        <div className='country-item country-item-value'>
          <div>
            {option.name} ({option?.code})
          </div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };
  const countryCodeOptionTemplate = (option) => {
    return (
      <div className='country-item'>
        <div>
          {option.name} ({option?.code})
        </div>
      </div>
    );
  };

  //get user address
  React.useEffect(() => {
    const getAddress = async () => {
      const res = await getUserAddressApi(userData?.id);
      if (res?.status === 200) {
        setAddress(res?.data?.data);
        setAvailableAddress(true);
      } else {
        setAvailableAddress(false);
        setAddress({
          userId: 0,
          address: '',
          address2: '',
          city: '',
          state: '',
          zipCode: '',
          country: '',
        });
      }
    };
    getAddress();
  }, [user?.id]);
  //get user emergency contact
  React.useEffect(() => {
    const getEmergencyContact = async () => {
      const res = await getUserEmergencyContactApi(userData?.id);
      if (res?.status === 200) {
        setEmergencyContact(res?.data?.data);
        setAvailableEmergencyContact(true);
      } else {
        setAvailableEmergencyContact(false);
        setEmergencyContact({
          userId: 0,
          fullName: '',
          relation: '',
          email: '',
          phone: '',
        });
      }
    };
    getEmergencyContact();
  }, [user?.id]);

  //emergencyContactFormik
  const emergencyContactFormik = useFormik({
    initialValues: {
      userId: userData?.id,
      fullName: emergencyContact?.fullName,
      relation: emergencyContact?.relation,
      email: emergencyContact?.email,
      phone: emergencyContact?.phone,
      address: emergencyContact?.address,
      address2: emergencyContact?.address2,
      city: emergencyContact?.city,
      state: emergencyContact?.state,
      zipCode: emergencyContact?.zipCode,
      country: emergencyContact?.country,
      // preferredLanguage: emergencyContact?.preferredLanguage,
    },
    validationSchema: () => {
      return yup.object({
        fullName: yup.string().required('Full name field is required.!'),
        relation: yup.string().required('Relation is required!'),
        email: yup
          .string()
          .email('Please provide a valid email!')
          .required('Email is required!'),
        phone: yup.string().required('Phone is required!'),
        address: yup.string().required('Address field is required.!'),
        city: yup.string().required('City is required!'),
        state: yup.string().required('State is required!'),
        country: yup.object().required('Country is required!'),
        zipCode: yup
          .string()
          .matches('^[0-9]*$', 'Zip code should be numbers only!')
          .required('Zip code is required!'),
      });
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      setIsLoadingEmergencyContact(true);
      if (availableEmergencyContact) {
        const responseOfUpdateEmergnecyContact =
          await updateUserEmergencyContactApi(user?.id, values);
        if (responseOfUpdateEmergnecyContact.status === 200) {
          setIsLoadingEmergencyContact(false);
          toast.current.show({
            severity: 'success',
            summary: 'Emergency Contact updated successfully',
            detail: `Your Emergency Contact is updated successfully!`,
            life: 10000,
          });
        } else if (responseOfUpdateEmergnecyContact.status === 400) {
          setIsLoadingEmergencyContact(false);
          toast.current.show({
            severity: 'error',
            summary: 'Could not update your Emergency Contact.',
            detail: `Your Emergency Contact could not be updated.`,
            life: 10000,
          });
        }
      } else {
        const responseOfAddEmergencyContact = await addUserEmergnecyContactApi(
          values
        );
        if (responseOfAddEmergencyContact.status === 200) {
          setIsLoadingEmergencyContact(false);
          toast.current.show({
            severity: 'success',
            summary: 'Emergnecy Contact added successfully',
            detail: `Your Emergency Contact is added successfully!`,
            life: 10000,
          });
        } else if (responseOfAddEmergencyContact.status === 400) {
          setIsLoadingEmergencyContact(false);
          toast.current.show({
            severity: 'error',
            summary: 'Could not add your Emergency Contact.',
            detail: `Your Emergency Contact could not be added.`,
            life: 10000,
          });
        }
      }
    },
  });
  //addressFormik
  const addressFormik = useFormik({
    initialValues: {
      userId: userData?.id,
      address: address?.address,
      address2: address?.address2,
      city: address?.city,
      state: address?.state,
      zipCode: address?.zipCode,
      country: address?.country,
    },
    validationSchema: () => {
      return yup.object({
        address: yup.string().required('Address field is required.!'),
        city: yup.string().required('City is required!'),
        state: yup.string().required('State is required!'),
        country: yup.object().required('Country is required!'),
        zipCode: yup
          .string()
          .matches('^[0-9]*$', 'Zip code should be numbers only!')
          .required('Zip code is required!'),
      });
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      setIsLoadingAddress(true);
      if (availableAddress) {
        const responseOfUpdateUserAddress = updateUserAddressApi(
          user?.id,
          values
        );
        if (responseOfUpdateUserAddress.statusCode === 200) {
          setIsLoadingAddress(false);
          toast.current.show({
            severity: 'success',
            summary: 'Address updated successfully',
            detail: `Your address is updated successfully!`,
            life: 10000,
          });
        } else if (responseOfUpdateUserAddress.statusCode === 400) {
          setIsLoadingAddress(false);
          toast.current.show({
            severity: 'error',
            summary: 'Could not update your address.',
            detail: `Your address could not be updated.`,
            life: 10000,
          });
        }
      } else {
        const responseOfAddUserAddress = await addUserAddressApi(values);
        if (responseOfAddUserAddress.statusCode === 200) {
          setIsLoadingAddress(false);

          toast.current.show({
            severity: 'success',
            summary: 'Address added successfully',
            detail: `Your address is added successfully!`,
            life: 10000,
          });
        } else if (responseOfAddUserAddress.statusCode === 400) {
          setIsLoadingAddress(false);
          toast.current.show({
            severity: 'error',
            summary: 'Could not add your address.',
            detail: `Your address could not be added.`,
            life: 10000,
          });
        }
      }
    },
  });

  //is user govt id is verified or not or pending
  const govtIDVerified = userData?.verified;

  return (
    <>
      <Header />
      <div id='wrapper'>
        <div className='dashboard-menu-overlay' />
        <Sidebar page={'Add New'} />
        <div className='dashboard-content'>
          <div className='dashboard-menu-btn color-bg'>
            <span>
              <i className='fas fa-bars' />
            </span>
            Dasboard Menu
          </div>
          <Toast ref={toast} position='bottom-right' />

          <div>
            <InfoBar
              label={
                user?.code !== null
                  ? `Edit Profile - ${user?.firstName} ${user?.lastName} (${user?.code})`
                  : `Edit Profile - ${user?.firstName} ${user?.lastName}`
              }
            />
          </div>
          <div className='dasboard-wrapper fl-wrap no-pag'>
            <div className='row'>
              <form onSubmit={formik.handleSubmit}>
                <div className='col-md-7'>
                  <div className='dasboard-widget-title fl-wrap'>
                    <h5>
                      <i className='fas fa-user-circle'></i>Profile Picture
                    </h5>
                  </div>
                  <div className='dasboard-widget-box nopad-dash-widget-box fl-wrap'>
                    <div className='edit-profile-photo'>
                      <img
                        src={
                          previewImage
                            ? profileImageUrls[0]
                            : formik.values.profilePicture
                            ? formik.values.profilePicture
                            : DUMMYIMAGE
                        }
                        className='respimg'
                        alt=''
                      />

                      <div className='change-photo-btn'>
                        <div className='photoUpload'>
                          <Upload
                            label={'Upload Profile Image'}
                            handleChange={(e) => onImageChange(e)}
                            disabled={profileImage?.length >= 1 ? true : false}
                            multiple={false}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='bg-wrap bg-parallax-wrap-gradien'>
                      <img
                        className='bg par-elem'
                        src={BgInput}
                        data-scrollax="properties: { translateY: '30%' }"
                        alt='backgroundImage'
                      />
                    </div>
                  </div>
                  <div className='dasboard-widget-title fl-wrap'>
                    <h5>
                      <i className='fas fa-key'></i>Personal Info
                    </h5>
                  </div>
                  <div className='dasboard-widget-box fl-wrap'>
                    <div className='custom-form'>
                      <label>
                        First Name{' '}
                        <span className='dec-icon'>
                          <i className='far fa-user'></i>
                        </span>
                      </label>

                      <Input
                        type='text'
                        name={'firstName'}
                        id={'firstName'}
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                      />

                      <label>
                        Middle Name{' '}
                        <span className='dec-icon'>
                          <i className='far fa-user'></i>
                        </span>
                      </label>

                      <Input
                        type='text'
                        name={'middleName'}
                        id={'middleName'}
                        value={formik.values.middleName}
                        onChange={formik.handleChange}
                      />

                      <label>
                        Last Name{' '}
                        <span className='dec-icon'>
                          <i className='far fa-user'></i>
                        </span>
                      </label>

                      <Input
                        type='text'
                        name={'lastName'}
                        id={'lastName'}
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                      />

                      <label>
                        Gender
                        <span className='dec-icon'>
                          <i className='far fa-user'></i>
                        </span>
                      </label>
                      <Select
                        className='override-select-edit'
                        isSearchable={false}
                        placeholder='Not Specified'
                        options={genderOptions}
                        value={formik.values.gender}
                        name='gender'
                        id='gender'
                        onChange={(e) => {
                          formik.setFieldValue('gender', e);
                        }}
                        styles={customStyles}
                      />
                      <label>
                        Email Address{' '}
                        <span className='dec-icon'>
                          <i className='far fa-envelope'></i>
                        </span>
                      </label>

                      <Input
                        type='text'
                        name={'email'}
                        id={'email'}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        disabled={true}
                      />

                      <label>
                        Phone
                        <span className='dec-icon'>
                          <i className='far fa-phone'></i>{' '}
                        </span>
                      </label>
                      <Input
                        type='text'
                        name={'phoneNumber'}
                        id={'phoneNumber'}
                        value={formik.values.phoneNumber}
                        onChange={formik.handleChange}
                      />
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: '100%',
                        }}
                      >
                        <div>
                          <label>
                            Government ID
                            <span className='dec-icon'>
                              <i className='fas fa-lock'></i>{' '}
                            </span>
                          </label>
                          {govtIDVerified === false && (
                            <span
                              style={{
                                color: '#fff',
                                fontSize: '14px',
                                textAlign: 'left',
                              }}
                            >
                              Not provided
                            </span>
                          )}
                        </div>
                        {govtIDVerified === false && (
                          <div
                            style={{
                              color: '#fff',
                              fontSize: '16px',
                              textDecoration: 'underline',
                              margin: '10px',
                              cursor: 'pointer',
                            }}
                            onClick={() => navigate(`/admin/id-verification`)}
                          >
                            Add
                          </div>
                        )}
                        {govtIDVerified === true && (
                          <span
                            style={{
                              color: 'green',
                              fontSize: '14px',
                              textAlign: 'left',
                              fontWeight: 'bolder',
                              margin: '10px',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            Verified
                            <DoneOutlineIcon
                              sx={{ fontSize: '19px', marginLeft: '5px' }}
                            />{' '}
                          </span>
                        )}
                        {govtIDVerified === 'pending' && (
                          <span
                            style={{
                              color: 'yellow',
                              fontSize: '14px',
                              textAlign: 'left',
                              fontWeight: 'bolder',
                              margin: '10px',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            Pending Verification
                            <AccessTimeFilledIcon
                              sx={{ fontSize: '18px', marginLeft: '5px' }}
                            />
                          </span>
                        )}
                      </div>
                      {/* Address  */}
                      {!editAddress ? (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                          }}
                        >
                          <div>
                            <label>
                              Your Address
                              <span className='dec-icon'>
                                <i className='fas fa-map-marker'></i>{' '}
                              </span>
                            </label>
                            <span
                              style={{
                                color: '#fff',
                                fontSize: '14px',
                                textAlign: 'left',
                              }}
                            >
                              {Object.keys(address).length > 0 ? (
                                <>
                                  {address?.address?.slice(0, 45)},{' '}
                                  {address?.address2?.slice(0, 25)}
                                </>
                              ) : (
                                <>Not Provided</>
                              )}
                            </span>
                          </div>
                          <div
                            style={{
                              color: '#fff',
                              fontSize: '16px',
                              textDecoration: 'underline',
                              margin: '10px',
                              cursor: 'pointer',
                            }}
                            onClick={() => setEditAddress(!editAddress)}
                          >
                            Edit
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            margin: '25px 0px',
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'space-between',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Typography
                              sx={{ fontSize: '22px', color: '#fff' }}
                            >
                              Address
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: '16px',
                                color: '#fff',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                              }}
                              onClick={() => setEditAddress(!editAddress)}
                            >
                              Cancel
                            </Typography>
                          </Box>
                          <Grid item xs={12}>
                            <label>
                              Street Address
                              <span className='dec-icon'>
                                <i className='far fa-map-marker'></i>
                              </span>
                            </label>

                            <TextField
                              sx={{ width: '100%' }}
                              id='address'
                              name='address'
                              required
                              placeholder='Street Address'
                              value={addressFormik.values.address}
                              onChange={addressFormik.handleChange}
                              inputProps={{ maxLength: 150 }}
                            />
                            {addressFormik.touched.address &&
                              Boolean(addressFormik.errors.address) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: 'block',
                                    color: 'red',
                                    textAlign: 'left',
                                  }}
                                >
                                  {addressFormik.errors.address}
                                </small>
                              )}
                          </Grid>
                          <Grid item xs={12}>
                            <label>
                              Apt or suite number
                              <span className='dec-icon'>
                                <i className='far fa-map-marker'></i>
                              </span>
                            </label>

                            <TextField
                              sx={{ width: '100%' }}
                              id='address2'
                              name='address2'
                              required
                              type='text'
                              placeholder='Enter Suite Number'
                              value={addressFormik.values.address2}
                              onChange={addressFormik.handleChange}
                              inputProps={{ maxLength: 150 }}
                            />
                            {addressFormik.touched.address2 &&
                              Boolean(addressFormik.errors.address2) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: 'block',
                                    color: 'red',
                                    textAlign: 'left',
                                  }}
                                >
                                  {addressFormik.errors.address2}
                                </small>
                              )}
                          </Grid>
                          <Grid item xs={12}>
                            <label>
                              City
                              <span className='dec-icon'>
                                <i className='far fa-map-marker'></i>
                              </span>
                            </label>
                            <TextField
                              sx={{ width: '100%' }}
                              id='city'
                              name='city'
                              required
                              placeholder='City'
                              value={addressFormik.values.city}
                              onChange={addressFormik.handleChange}
                              inputProps={{ maxLength: 50 }}
                            />
                            {addressFormik.touched.city &&
                              Boolean(addressFormik.errors.city) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: 'block',
                                    color: 'red',
                                    textAlign: 'left',
                                  }}
                                >
                                  {addressFormik.errors.city}
                                </small>
                              )}
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <label>
                              State
                              <span className='dec-icon'>
                                <i className='far fa-map-marker'></i>
                              </span>
                            </label>
                            <TextField
                              sx={{ width: '100%' }}
                              id='state'
                              name='state'
                              required
                              placeholder='State'
                              value={addressFormik.values.state}
                              onChange={addressFormik.handleChange}
                              inputProps={{ maxLength: 50 }}
                            />
                            {addressFormik.touched.state &&
                              Boolean(addressFormik.errors.state) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: 'block',
                                    color: 'red',
                                    textAlign: 'left',
                                  }}
                                >
                                  {addressFormik.errors.state}
                                </small>
                              )}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{ marginBottom: '10px' }}
                          >
                            <label>
                              Zip Code
                              <span className='dec-icon'>
                                <i className='far fa-map-marker'></i>
                              </span>
                            </label>
                            <TextField
                              sx={{ width: '100%' }}
                              id='zipCode'
                              name='zipCode'
                              required
                              placeholder='Zip Code'
                              value={addressFormik.values.zipCode}
                              inputProps={{ maxLength: 6 }}
                              onChange={(e) => {
                                const newValue = e.target.value;
                                if (newValue.match('^[0-9]*$')) {
                                  addressFormik.setFieldValue(
                                    'zipCode',
                                    newValue
                                  );
                                }
                              }}
                            />
                            {addressFormik.touched.zipCode &&
                              Boolean(addressFormik.errors.zipCode) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: 'block',
                                    color: 'red',
                                    textAlign: 'left',
                                  }}
                                >
                                  {addressFormik.errors.zipCode}
                                </small>
                              )}
                          </Grid>
                          <Grid item xs={12}>
                            <label>
                              Country / Region
                              <span className='dec-icon'>
                                <i className='far fa-map-marker'></i>
                              </span>
                            </label>
                            <Dropdown
                              value={addressFormik.values.country}
                              options={options}
                              onChange={addressFormik.handleChange}
                              optionLabel='name'
                              filter
                              showClear
                              filterBy='name'
                              name='country'
                              placeholder='Country/region'
                              valueTemplate={selectedCountryTemplate}
                              itemTemplate={countryOptionTemplate}
                              style={{
                                width: '100%',
                                background: 'transparent',
                                padding: '6px 5px',
                              }}
                            />
                            {addressFormik.touched.country &&
                              Boolean(addressFormik.errors.country) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: 'block',
                                    color: 'red',
                                    textAlign: 'left',
                                  }}
                                >
                                  {addressFormik.errors.country}
                                </small>
                              )}
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <Buttons
                              title={'Save'}
                              isLoading={isLoadingAddress}
                              onSubmit={addressFormik.handleSubmit}
                              style={{
                                width: '20%',
                                margin: '10px 0px',
                              }}
                            />
                          </Grid>
                        </div>
                      )}
                      {/* Emergency contact  */}
                      {!editEmergencyContact ? (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                          }}
                        >
                          <div>
                            <label>
                              Emergency Contact
                              <span className='dec-icon'>
                                <i className='fas fa-lock'></i>{' '}
                              </span>
                            </label>
                            <span
                              style={{
                                color: '#fff',
                                fontSize: '14px',
                                textAlign: 'left',
                              }}
                            >
                              {Object.keys(emergencyContact).length > 0 ? (
                                <>
                                  {emergencyContact?.fullName} (
                                  {emergencyContact?.relation})
                                </>
                              ) : (
                                <>Not Provided</>
                              )}
                            </span>
                          </div>

                          <div
                            style={{
                              color: '#fff',
                              fontSize: '16px',
                              textDecoration: 'underline',
                              margin: '10px',
                              cursor: 'pointer',
                            }}
                            onClick={() =>
                              setEditEmergencyContact(!editEmergencyContact)
                            }
                          >
                            Add
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            margin: '25px 0px',
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'space-between',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Typography
                              sx={{ fontSize: '22px', color: '#fff' }}
                            >
                              Emergency Contact
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: '16px',
                                color: '#fff',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                              }}
                              onClick={() =>
                                setEditEmergencyContact(!editEmergencyContact)
                              }
                            >
                              Cancel
                            </Typography>
                          </Box>
                          <Grid item xs={12}>
                            <label>
                              Name
                              <span className='dec-icon'>
                                <i className='far fa-envelope'></i>
                              </span>
                            </label>
                            <TextField
                              sx={{ width: '100%' }}
                              id='fullName'
                              name='fullName'
                              required
                              placeholder='Name'
                              value={emergencyContactFormik.values.fullName}
                              onChange={emergencyContactFormik.handleChange}
                              inputProps={{ maxLength: 80 }}
                            />
                            {emergencyContactFormik.touched.fullName &&
                              Boolean(
                                emergencyContactFormik.errors.fullName
                              ) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: 'block',
                                    color: 'red',
                                    textAlign: 'left',
                                  }}
                                >
                                  {emergencyContactFormik.errors.fullName}
                                </small>
                              )}
                          </Grid>
                          <Grid item xs={12}>
                            <label>
                              Relationship
                              <span className='dec-icon'>
                                <i className='far fa-envelope'></i>
                              </span>
                            </label>
                            <TextField
                              sx={{ width: '100%' }}
                              id='relation'
                              name='relation'
                              required
                              type='text'
                              placeholder='Relationship'
                              value={emergencyContactFormik.values.relation}
                              onChange={emergencyContactFormik.handleChange}
                              inputProps={{ maxLength: 150 }}
                            />
                            {emergencyContactFormik.touched.relation &&
                              Boolean(
                                emergencyContactFormik.errors.relation
                              ) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: 'block',
                                    color: 'red',
                                    textAlign: 'left',
                                  }}
                                >
                                  {emergencyContactFormik.errors.relation}
                                </small>
                              )}
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <label>
                              Email
                              <span className='dec-icon'>
                                <i className='far fa-envelope'></i>
                              </span>
                            </label>
                            <TextField
                              sx={{ width: '100%' }}
                              id='email'
                              name='email'
                              required
                              placeholder='Email'
                              value={emergencyContactFormik.values.email}
                              onChange={emergencyContactFormik.handleChange}
                              inputProps={{ maxLength: 60 }}
                            />
                            {emergencyContactFormik.touched.email &&
                              Boolean(emergencyContactFormik.errors.email) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: 'block',
                                    color: 'red',
                                    textAlign: 'left',
                                  }}
                                >
                                  {emergencyContactFormik.errors.email}
                                </small>
                              )}
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <label>
                              Phone Number
                              <span className='dec-icon'>
                                <i className='far fa-envelope'></i>
                              </span>
                            </label>
                            <TextField
                              sx={{ width: '100%' }}
                              id='phone'
                              name='phone'
                              required
                              placeholder='Phone Number'
                              value={emergencyContactFormik.values.phone}
                              inputProps={{ maxLength: 11 }}
                              onChange={(e) => {
                                const newValue = e.target.value;
                                if (newValue.match('^[0-9]*$')) {
                                  emergencyContactFormik.setFieldValue(
                                    'phone',
                                    newValue
                                  );
                                }
                              }}
                            />
                            {emergencyContactFormik.touched.phone &&
                              Boolean(emergencyContactFormik.errors.phone) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: 'block',
                                    color: 'red',
                                    textAlign: 'left',
                                  }}
                                >
                                  {emergencyContactFormik.errors.phone}
                                </small>
                              )}
                          </Grid>
                          <Grid item xs={12}>
                            <label>
                              Street Address
                              <span className='dec-icon'>
                                <i className='far fa-map-marker'></i>
                              </span>
                            </label>

                            <TextField
                              sx={{ width: '100%' }}
                              id='address'
                              name='address'
                              required
                              placeholder='Street Address'
                              value={emergencyContactFormik.values.address}
                              onChange={emergencyContactFormik.handleChange}
                              inputProps={{ maxLength: 150 }}
                            />
                            {emergencyContactFormik.touched.address &&
                              Boolean(
                                emergencyContactFormik.errors.address
                              ) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: 'block',
                                    color: 'red',
                                    textAlign: 'left',
                                  }}
                                >
                                  {emergencyContactFormik.errors.address}
                                </small>
                              )}
                          </Grid>
                          <Grid item xs={12}>
                            <label>
                              Apt or suite number
                              <span className='dec-icon'>
                                <i className='far fa-map-marker'></i>
                              </span>
                            </label>

                            <TextField
                              sx={{ width: '100%' }}
                              id='address2'
                              name='address2'
                              required
                              type='text'
                              placeholder='Enter Suite Number'
                              value={emergencyContactFormik.values.address2}
                              onChange={emergencyContactFormik.handleChange}
                              inputProps={{ maxLength: 150 }}
                            />
                            {emergencyContactFormik.touched.address2 &&
                              Boolean(
                                emergencyContactFormik.errors.address2
                              ) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: 'block',
                                    color: 'red',
                                    textAlign: 'left',
                                  }}
                                >
                                  {emergencyContactFormik.errors.address2}
                                </small>
                              )}
                          </Grid>
                          <Grid item xs={12}>
                            <label>
                              City
                              <span className='dec-icon'>
                                <i className='far fa-map-marker'></i>
                              </span>
                            </label>
                            <TextField
                              sx={{ width: '100%' }}
                              id='city'
                              name='city'
                              required
                              placeholder='City'
                              value={emergencyContactFormik.values.city}
                              onChange={emergencyContactFormik.handleChange}
                              inputProps={{ maxLength: 50 }}
                            />
                            {emergencyContactFormik.touched.city &&
                              Boolean(emergencyContactFormik.errors.city) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: 'block',
                                    color: 'red',
                                    textAlign: 'left',
                                  }}
                                >
                                  {emergencyContactFormik.errors.city}
                                </small>
                              )}
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <label>
                              State
                              <span className='dec-icon'>
                                <i className='far fa-map-marker'></i>
                              </span>
                            </label>
                            <TextField
                              sx={{ width: '100%' }}
                              id='state'
                              name='state'
                              required
                              placeholder='State'
                              value={emergencyContactFormik.values.state}
                              onChange={emergencyContactFormik.handleChange}
                              inputProps={{ maxLength: 50 }}
                            />
                            {emergencyContactFormik.touched.state &&
                              Boolean(emergencyContactFormik.errors.state) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: 'block',
                                    color: 'red',
                                    textAlign: 'left',
                                  }}
                                >
                                  {emergencyContactFormik.errors.state}
                                </small>
                              )}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{ marginBottom: '10px' }}
                          >
                            <label>
                              Zip Code
                              <span className='dec-icon'>
                                <i className='far fa-map-marker'></i>
                              </span>
                            </label>
                            <TextField
                              sx={{ width: '100%' }}
                              id='zipCode'
                              name='zipCode'
                              required
                              placeholder='Zip Code'
                              value={emergencyContactFormik.values.zipCode}
                              inputProps={{ maxLength: 6 }}
                              onChange={(e) => {
                                const newValue = e.target.value;
                                if (newValue.match('^[0-9]*$')) {
                                  emergencyContactFormik.setFieldValue(
                                    'zipCode',
                                    newValue
                                  );
                                }
                              }}
                            />
                            {emergencyContactFormik.touched.zipCode &&
                              Boolean(
                                emergencyContactFormik.errors.zipCode
                              ) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: 'block',
                                    color: 'red',
                                    textAlign: 'left',
                                  }}
                                >
                                  {emergencyContactFormik.errors.zipCode}
                                </small>
                              )}
                          </Grid>
                          <Grid item xs={12}>
                            <label>
                              Country / Region
                              <span className='dec-icon'>
                                <i className='far fa-map-marker'></i>
                              </span>
                            </label>
                            <Dropdown
                              value={emergencyContactFormik.values.country}
                              options={options}
                              onChange={emergencyContactFormik.handleChange}
                              optionLabel='name'
                              filter
                              showClear
                              filterBy='name'
                              name='country'
                              placeholder='Country/region'
                              valueTemplate={selectedCountryTemplate}
                              itemTemplate={countryOptionTemplate}
                              style={{
                                width: '100%',
                                background: 'transparent',
                                padding: '6px 5px',
                              }}
                            />
                            {emergencyContactFormik.touched.country &&
                              Boolean(
                                emergencyContactFormik.errors.country
                              ) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: 'block',
                                    color: 'red',
                                    textAlign: 'left',
                                  }}
                                >
                                  {emergencyContactFormik.errors.country}
                                </small>
                              )}
                          </Grid>
                          {/* <Grid item xs={12}>
                            <label>
                              Preffered Language
                              <span className='dec-icon'>
                                <i className='far fa-map-marker'></i>
                              </span>
                            </label>
                            <Dropdown
                              value={
                                emergencyContactFormik.values.preferredLanguage
                              }
                              options={langs}
                              onChange={emergencyContactFormik.handleChange}
                              optionLabel='name'
                              filter
                              showClear
                              filterBy='name'
                              name='preferredLanguage'
                              placeholder='Preffered Language'
                              valueTemplate={selectedCountryTemplate}
                              itemTemplate={countryOptionTemplate}
                              style={{
                                width: '100%',
                                background: 'transparent',
                                padding: '6px 5px',
                              }}
                            />
                            {emergencyContactFormik.touched.preferredLanguage &&
                              Boolean(
                                emergencyContactFormik.errors.preferredLanguage
                              ) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: 'block',
                                    color: 'red',
                                    textAlign: 'left',
                                  }}
                                >
                                  {
                                    emergencyContactFormik.errors
                                      .preferredLanguage
                                  }
                                </small>
                              )}
                          </Grid> */}
                          <Grid item xs={12} md={12}>
                            <Buttons
                              title={'Save'}
                              isLoading={isLoadingEmergencyContact}
                              onSubmit={emergencyContactFormik.handleSubmit}
                              style={{
                                width: '20%',
                                margin: '10px 0px',
                              }}
                            />
                          </Grid>
                        </div>
                      )}

                      <label>About Me</label>
                      <div className='profileDescription'>
                        <Editor
                          handleBeforeInput={(val) => handleBeforeInput(val)}
                          editorState={editorState && editorState}
                          id={'description'}
                          name={'description'}
                          onEditorStateChange={
                            handleEditorChange && handleEditorChange
                          }
                          handlePastedText={(text, html, state) =>
                            handlePastedText(text, html, state)
                          }
                          wrapperClassName='wrapper--class'
                          editorClassName={
                            hasFocus ? 'active-editor-class' : 'editor--class'
                          }
                          toolbarClassName='toolbar--class'
                          onChange={formik.handleChange}
                          onFocus={() => setHasFocus(true)}
                          onBlur={() => setHasFocus(false)}
                        />
                      </div>

                      <span
                        style={{
                          color: validationType ? 'red' : 'white',
                          textAlign: 'left',
                          marginTop: '10px',
                        }}
                      >
                        {textLength}/1000{' '}
                        {validationType && 'Max character limit is 1000'}
                      </span>
                      <Buttons
                        title='Save Changes'
                        onSubmit={formik.handleSubmit}
                        isLoading={isLoading}
                        style={{ marginTop: '10px', borderRadius: '4px' }}
                      />
                    </div>
                  </div>
                </div>
              </form>
              <div className='col-md-5'>
                <div className='dasboard-widget-title dbt-mm fl-wrap'>
                  <h5>
                    <i class='fa-sharp fa-solid fa-shield-halved'></i>Why isn’t
                    my info shown here?
                  </h5>
                </div>
                <div className='dasboard-widget-box fl-wrap'>
                  <p>
                    We’re hiding some account details to protect your identity.
                  </p>
                </div>
                <div className='dasboard-widget-title dbt-mm fl-wrap'>
                  <h5>
                    <i class='fa-solid fa-lock'></i>Which details can be edited?
                  </h5>
                </div>
                <div className='dasboard-widget-box fl-wrap'>
                  <p>
                    Details Staywo uses to verify your identity can’t be
                    changed. Contact info and some personal details can be
                    edited, but we may ask you verify your identity the next
                    time you book or create a listing
                  </p>
                </div>
                <div className='dasboard-widget-title dbt-mm fl-wrap'>
                  <h5>
                    <i class='fa-solid fa-eye'></i>What info is shared with
                    others?
                  </h5>
                </div>
                <div className='dasboard-widget-box fl-wrap'>
                  <p>
                    Staywo only releases contact information for Hosts and
                    guests after a reservation is confirmed.
                  </p>
                </div>
              </div>
              {/* <div className='col-md-5'>
                <div className='dasboard-widget-title dbt-mm fl-wrap'>
                  <h5>
                    <i className='fas fa-key'></i>Change Password
                  </h5>
                </div>
                <div className='dasboard-widget-box fl-wrap'>
                  <form onSubmit={passFormik.handleSubmit}>
                    <div className='custom-form'>
                      <div className='pass-Input-wrap fl-wrap'>
                        <label>
                          Current Password
                          <span className='dec-icon'>
                            <i className='far fa-lock-open-alt'></i>
                          </span>
                        </label>
                        <Input
                          type='password'
                          className='pass-Input'
                          placeholder=''
                          value={passFormik.values.currentPassword}
                          name={'currentPassword'}
                          id={'currentPassword'}
                          onChange={passFormik.handleChange}
                        />
                        {passFormik.touched.currentPassword &&
                          Boolean(passFormik.errors.currentPassword) && (
                            <small
                              id='username2-help'
                              className='p-error'
                              style={{
                                display: 'block',
                                color: 'red',
                                textAlign: 'left',
                              }}
                            >
                              {passFormik.errors.currentPassword}
                            </small>
                          )}
                      </div>
                      <div className='pass-Input-wrap fl-wrap'>
                        <label>
                          New Password
                          <span className='dec-icon'>
                            <i className='far fa-lock-alt'></i>
                          </span>
                        </label>
                        <Input
                          type='password'
                          className='pass-Input'
                          placeholder=''
                          value={passFormik.values.password}
                          name={'password'}
                          id={'password'}
                          onChange={passFormik.handleChange}
                        />
                        {passFormik.touched.password &&
                          Boolean(passFormik.errors.password) && (
                            <small
                              id='username2-help'
                              className='p-error'
                              style={{
                                display: 'block',
                                color: 'red',
                                textAlign: 'left',
                              }}
                            >
                              {passFormik.errors.password}
                            </small>
                          )}
                      </div>
                      <div className='pass-Input-wrap fl-wrap'>
                        <label>
                          Confirm New Password
                          <span className='dec-icon'>
                            <i className='far fa-shield-check'></i>{' '}
                          </span>
                        </label>
                        <Input
                          type='password'
                          className='pass-Input'
                          placeholder=''
                          value={passFormik.values.confirmPassword}
                          name={'confirmPassword'}
                          id={'confirmPassword'}
                          onChange={passFormik.handleChange}
                        />
                        {passFormik.touched.confirmPassword &&
                          Boolean(passFormik.errors.confirmPassword) && (
                            <small
                              id='username2-help'
                              className='p-error'
                              style={{
                                display: 'block',
                                color: 'red',
                                textAlign: 'left',
                              }}
                            >
                              {passFormik.errors.confirmPassword}
                            </small>
                          )}
                      </div>
                      <Buttons
                        title=' Save Changes'
                        onSubmit={passFormik.handleSubmit}
                        isLoading={isLoading}
                        style={{ marginTop: '10px', borderRadius: '4px' }}
                      />
                    </div>
                  </form>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
