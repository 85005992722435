import React from "react";
import Grid from "@mui/material/Grid";
import Input from "../../../../components/form/input";
import Card from "../../../../components/dasboard/card";
import Dropdown from "../../../../components/form/dropdown";
import TextareaField from "../../../../components/form/textarea";
import Typography from "@mui/material/Typography";
const Price = ({ formik, inputValues, handleChangeInput, validationType }) => {
  return (
    <Card withIcon title={"Listing Price"} icon={"fa fa-credit-card"}>
      <Grid
        container
        rowSpacing={{ xs: 1, sm: 1, md: 2 }}
        columnSpacing={{ xs: 1, sm: 1, md: 2 }}
      >
        {/* <Grid item xs={12} sm={12} md={6}>
          <Typography>Length Of Stay (minimum 28 days)</Typography>
          <Input
            id={"price"}
            name={"price"}
            label={"Length Of Stay (minimum 28 days)"}
            type={"text"}
            onChange={handleChangeInput}
            value={inputValues.price}
            defaultValue={inputValues.price}
            // error={formik.touched.price && Boolean(formik.errors.price)}
            // helperText={formik.touched.price && formik.errors.price}
            minLength={1}
            min={0}
            step={0}
          />
          {validationType.open && validationType.type == "price" && (
            <p style={{ color: "red" }}>{validationType.message}</p>
          )}
        </Grid> */}
        <Grid item xs={12} sm={12} md={6}>
          <Typography>
            Per Night Price ({process.env.REACT_APP_CURRENCY})
          </Typography>
          <Typography style={{fontSize:13}}>Adjust your price based on demand.you can change it at any time</Typography>
          <Input
            id={"perNightPrice"}
            name={"perNightPrice"}
            label={"Per Night Price"}
            type={"number"}
            onChange={handleChangeInput}
            value={inputValues.perNightPrice}
            defaultValue={inputValues.perNightPrice}
            // error={
            //   formik.touched.perNightPrice &&
            //   Boolean(formik.errors.perNightPrice)
            // }
            // helperText={
            //   formik.touched.perNightPrice && formik.errors.perNightPrice
            // }
            showIcon={true}
            minLength={1}
            min={0}
            step={0}
          />
          {validationType.open && validationType.type == "perNightPrice" && (
            <p style={{ color: "red" }}>{validationType.message}</p>
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export default Price;
