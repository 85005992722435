import React from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import "./styles.css";

const minuteSeconds = 60;
const hourSeconds = minuteSeconds * 60;
const daySeconds = hourSeconds * 72;
// const daySeconds = hourSeconds * 24;

const timerProps = {
  isPlaying: true,
  size: 70,
  strokeWidth: 6,
};

const renderTime = (dimension, time) => {
  return (
    <div className="time-wrapper">
      <div className="time">{time}</div>
      <div>{dimension}</div>
    </div>
  );
};

// const getTimeSeconds = (time) => (minuteSeconds - time) | 0
// const getTimeMinutes = (time) => ((time % hourSeconds) / minuteSeconds) | 0
// const getTimeHours = (time) => ((time % daySeconds) / hourSeconds) | 0

// const getTimeDays = (time) => (time / daySeconds) | 0;
// const daysDuration = days * daySeconds;
// const days = Math.ceil(remainingTime / daySeconds);

export default function App({ createdOn }) {
  const currentDate = new Date().toISOString();

  const strtTime = new Date(currentDate).getTime();
  const enTime = new Date(createdOn).getTime();

  const minutesss = new Date(createdOn).getMinutes();

  const stratTime = new Date().getTime();
  const endTime = strtTime + enTime;

  const remainingTime = endTime - stratTime;
  const getTimeSeconds = (time) => {
    return Math.ceil(minuteSeconds - time) - 1;
  };
  const getTimeMinutes = (time) => Math.ceil(time / minuteSeconds) - 1;
  const getTimeHours = (time) => Math.ceil(time / hourSeconds) - 1;

  return (
    <div className="App">
      {/* <CountdownCircleTimer
        {...timerProps}
        colors='#7E2E84'
        duration={daysDuration}
        initialRemainingTime={remainingTime}
      >
        {({ elapsedTime, color }) => (
          <span style={{ color }}>
            {renderTime('days', getTimeDays(daysDuration - elapsedTime))}
          </span>
        )}
      </CountdownCircleTimer> */}
      <CountdownCircleTimer
        style={{ marginRight: "20px" }}
        {...timerProps}
        colors="#D14081"
        duration={daySeconds}
        initialRemainingTime={remainingTime % daySeconds}
        onComplete={(totalElapsedTime) => ({
          shouldRepeat: remainingTime - totalElapsedTime > hourSeconds,
        })}
      >
        {({ elapsedTime, color }) => (
          <span style={{ color }}>
            {renderTime("hrs", getTimeHours(daySeconds - elapsedTime))}
          </span>
        )}
      </CountdownCircleTimer>
      <CountdownCircleTimer
        {...timerProps}
        colors="#EF798A"
        duration={hourSeconds}
        initialRemainingTime={remainingTime % hourSeconds}
        // onComplete={(totalElapsedTime) => ({
        //   shouldRepeat: remainingTime - totalElapsedTime > minuteSeconds,
        // })}
      >
        {({ elapsedTime, color }) => (
          <span style={{ color }}>
            {renderTime("mins", getTimeMinutes(hourSeconds - elapsedTime))}
          </span>
        )}
      </CountdownCircleTimer>
      <CountdownCircleTimer
        {...timerProps}
        colors="#218380"
        duration={minuteSeconds}
        initialRemainingTime={remainingTime % minuteSeconds}
        onComplete={(totalElapsedTime) => ({
          shouldRepeat: remainingTime - totalElapsedTime > 0,
        })}
      >
        {({ elapsedTime, color }) => (
          <span style={{ color }}>
            {renderTime("secs", getTimeSeconds(elapsedTime))}
          </span>
        )}
      </CountdownCircleTimer>
    </div>
  );
}
