//

import { API_URL } from '../../config';
import axios from 'axios';
//https://api.staywo.com/rating/by-guest?page=1&property_id=4
export const getHostPropertiesReviewsApi = async (id, page) => {
  try {
    const response = await axios.get(
      API_URL + `rating/get-host-property-reviews?page=${page}&hostId=${id}`
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of guest review : ', response);
      return response;
    }
  }
};
