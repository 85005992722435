import React, { useState, useImperativeHandle } from 'react';
import { Dialog } from 'primereact/dialog';

const PropertySafetyConsiderationDialog = React.forwardRef(({}, ref) => {
  const [openAmenitiesDialog, setOpenAmenitiesDialog] = useState(false);
  const [dialogData, setDialogData] = useState(null);

  useImperativeHandle(ref, () => ({
    isVisible(params) {
      setDialogData(params);
      setOpenAmenitiesDialog(true);
    },
  }));

  const hideDialog = () => {
    setOpenAmenitiesDialog(false);
  };

  return (
    <Dialog
      visible={openAmenitiesDialog}
      style={{ width: '50%' }}
      header='All Nearby Safety Consideration'
      modal
      className='p-fluid'
      onHide={hideDialog}
    >
      <div className='listing-features'>
        <ul>
          {dialogData?.map((na) => {
            return (
              <li style={{ width: '100%' }}>
                <div>
                  {na?.safetyConsideration?.map((fc) => {
                    if (fc?.check_status) {
                      return (
                        <li>
                          <i className={fc?.icon}></i>
                          <a>{fc?.title}</a>
                        </li>
                      );
                    }
                  })}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </Dialog>
  );
});

export default PropertySafetyConsiderationDialog;
