import { API_URL } from '../../config';
import axios from 'axios';

// Create co host
export const createCoHostApi = async (data) => {
  try {
    const response = await axios.post(API_URL + 'co-host/createcohost', data);
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('bad Response of create co host : ', response);
      return response;
    }
  }
};

// Get co host
export const getCoHostApi = async (id) => {
  try {
    const response = await axios.get(API_URL + `co-host/getCohosts/${id}`);
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('bad Response of get co host : ', response);
      return response;
    }
  }
};

// Update Co host
export const updateCoHostApi = async (data) => {
  try {
    const response = await axios.get(
      API_URL + `co-host/updatePermissions`,
      data
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('bad Response of update co host : ', response);
      return response;
    }
  }
};

// Delete Co host
export const deleteCoHostApi = async (data) => {
  try {
    const response = await axios.delete(
      API_URL +
        `co-host/deleteCohost?propertyId=${data.propertyId}&userId=${data.userId}&cohostId=${data.cohostId}`
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('bad Response of Delete co host : ', response);
      return response;
    }
  }
};
