import React, { useState } from 'react';
import Input from '../form/input';
import getFAQApi from '../../services/FAQ/getFAQs';
import { useEffect } from 'react';

export const FAQContent = () => {
  const [faqData, setFaqData] = useState([]);
  const getFaqData = async () => {
    const data = await getFAQApi();
    if (data) {
      setFaqData(data);
    }
  };
  useEffect(() => {
    getFaqData();
  }, []);
  return (
    <section className="gray-bg small-padding ">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="box-widget fl-wrap fixed-column_menu-init">
              <div className="box-widget-content fl-wrap  ">
                <div className="box-widget-title fl-wrap">FAQ Navigation</div>
                <div className="faq-nav scroll-init fl-wrap">
                  <ul>
                    <li>
                      <a className="act-scrlink" href="#faq1">
                        Payments
                      </a>
                    </li>
                    <li>
                      <a href="#faq2">Suggestions</a>
                    </li>
                    <li>
                      <a href="#faq3">Reccomendations</a>
                    </li>
                    <li>
                      <a href="#faq4">Booking</a>
                    </li>
                    <li>
                      <a href="#faq5">Listing</a>
                    </li>
                  </ul>
                </div>
                <div className="search-widget fns fl-wrap">
                  <form action="#" className="fl-wrap custom-form">
                    <Input
                      name="se"
                      id="se"
                      type="text"
                      className="search"
                      placeholder="Keywords"
                      value=""
                    />
                    <button className="search-submit" id="submit_btn">
                      <i className="far fa-search"></i>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="list-single-main-container">
              {faqData?.map((faq, i) => {
                return (
                  <>
                    <div
                      className="list-single-main-item fl-wrap"
                      id={i}
                      key={i}
                    >
                      <div className="list-single-main-item-title big-lsmt fl-wrap">
                        <h3>{faq?.title}</h3>
                      </div>
                      {faq?.faqs.map((faq) => {
                        return (
                          <div className="accordion-lite-container fl-wrap">
                            <details>
                              <summary className="accordion-lite-header fl-wrap">
                                {faq?.question}
                                <i className="fas fa-plus"></i>
                              </summary>
                              <p>{faq?.answer}</p>
                            </details>
                          </div>
                        );
                      })}
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const FAQCard = () => {
  return (
    <div className="accordion-lite-container fl-wrap">
      <div className="accordion-lite-header fl-wrap">
        How long does the sending process take? <i className="fas fa-plus"></i>
      </div>
      <div className="accordion-lite_content fl-wrap">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas in
          pulvinar neque. Nulla finibus lobortis pulvinar. Donec a consectetur
          nulla. Nulla posuere sapien vitae lectus suscipit, et pulvinar nisi
          tincidunt. Aliquam erat volutpat. Curabitur convallis fringilla diam
          sed aliquam. Sed tempor iaculis massa faucibus feugiat. In fermentum
          facilisis massa, a consequat .
        </p>
      </div>
    </div>
  );
};
