import { Dashboard, MenuBook } from '@mui/icons-material'
import { Box, Grid, Paper, Typography } from '@mui/material'
import React from 'react'

const Clean = () => {
    return (
        <Grid container sx={{ mb: 4 }} spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={12} md={6} lg={6} sm={12}>
                <Paper elevation={3} sx={{ p: 2, bgcolor: '#292e3a', textAlign: 'left' }}>
                    <Dashboard sx={{ fontSize: '30px' }} />
                    <Typography sx={{ mt: 5, fontSize: '25px' }}>Cleaning overview</Typography>
                </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={6} sm={12}>
                <Paper elevation={3} sx={{ p: 2, bgcolor: '#292e3a', textAlign: 'left' }}>
                    <Dashboard sx={{ fontSize: '30px' }} />
                    <Typography sx={{ mt: 5, fontSize: '25px' }}>Cleaning checklists</Typography>
                </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={6} sm={12}>
                <Paper elevation={3} sx={{ p: 2, bgcolor: '#292e3a', textAlign: 'left' }}>
                    <Dashboard sx={{ fontSize: '30px' }} />
                    <Typography sx={{ mt: 5, fontSize: '25px' }}>Supplies and services</Typography>
                </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={6} sm={12}>
                <Paper elevation={3} sx={{ p: 2, bgcolor: '#292e3a', textAlign: 'left' }}>
                    <Dashboard sx={{ fontSize: '30px' }} />
                    <Typography sx={{ mt: 5, fontSize: '25px' }}>Resources</Typography>
                </Paper>
            </Grid>

            <Grid item xs={12} md={12} lg={12} sm={12}>
                <Typography>Getting started</Typography>
                <Paper elevation={3} sx={{ p: 4, bgcolor: '#292e3a', textAlign: 'left' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box sx={{ pr: 2 }}>
                            <Typography sx={{ fontSize: '18px', color: '#fff' }}>Learn cleaning techniques for your home</Typography>
                            <Typography sx={{ textAlign: 'justify', fontSize: '14px' }}>Learn more about the five-step cleaning process through this short video. We cover the cleaning basics and show you how it’s done.</Typography>
                        </Box>
                        <Box>
                            <img style={{ width: '300px', height: '200px' }} src="https://vision5000.meraki-it.pk/assets/img/1234.jpg" alt="" />
                        </Box>
                    </Box>
                </Paper>
            </Grid>

            <Grid item xs={12} md={6} lg={6} sm={12}>
                <Paper elevation={3} sx={{ p: 3, bgcolor: '#292e3a', textAlign: 'left' }}>
                    <Box>
                        <Box>
                            <img style={{ width: '300px', height: '200px' }} src="https://vision5000.meraki-it.pk/assets/img/1234.jpg" alt="" />
                        </Box>
                        <Box>
                            <Typography sx={{ fontSize: '16px', color: '#fff', mt: 2 }}>Learn cleaning techniques for your home</Typography>
                            <Typography sx={{ textAlign: 'justify', fontSize: '14px' }}>Learn more about the five-step cleaning process through this short video. We cover the cleaning basics and show you how it’s done.</Typography>
                        </Box>
                    </Box>
                </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={6} sm={12}>
                <Paper elevation={3} sx={{ p: 3, bgcolor: '#292e3a', textAlign: 'left' }}>
                    <Box>
                        <Box>
                            <img style={{ width: '300px', height: '200px' }} src="https://vision5000.meraki-it.pk/assets/img/1234.jpg" alt="" />
                        </Box>
                        <Box>
                            <Typography sx={{ fontSize: '15px', color: '#fff', mt: 2 }}>Learn cleaning techniques for your home</Typography>
                            <Typography sx={{ textAlign: 'justify', fontSize: '14px' }}>Learn more about the five-step cleaning process through this short video. We cover the cleaning basics and show you how it’s done.</Typography>
                        </Box>

                    </Box>
                </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={6} sm={12}>
                <Paper elevation={3} sx={{ p: 2, bgcolor: '#292e3a', textAlign: 'left' }}>
                    <Dashboard sx={{ fontSize: '30px' }} />
                    <Typography sx={{ mt: 2, fontSize: '18px' }}>Download the cleaning handbook</Typography>
                    <Typography>This comprehensive resource includes expert-backed guidance, safety tips, and checklists.</Typography>
                </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={6} sm={12}>
                <Paper elevation={3} sx={{ p: 2, bgcolor: '#292e3a', textAlign: 'left' }}>
                    <MenuBook sx={{ fontSize: '30px' }} />
                    <Typography sx={{ mt: 2, fontSize: '18px' }}>Download the cleaning handbook</Typography>
                    <Typography>This comprehensive resource includes expert-backed guidance, safety tips, and checklists.</Typography>
                </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={6} sm={12}>
                <Paper elevation={3} sx={{ p: 2, bgcolor: '#292e3a', textAlign: 'left' }}>
                    <MenuBook sx={{ fontSize: '30px' }} />
                    <Typography sx={{ mt: 2, fontSize: '18px' }}>Download the cleaning handbook</Typography>
                    <Typography>This comprehensive resource includes expert-backed guidance, safety tips, and checklists.</Typography>
                </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={6} sm={12}>
                <Paper elevation={3} sx={{ p: 2, bgcolor: '#292e3a', textAlign: 'left' }}>
                    <MenuBook sx={{ fontSize: '30px' }} />
                    <Typography sx={{ mt: 2, fontSize: '18px' }}>Download the cleaning handbook</Typography>
                    <Typography>This comprehensive resource includes expert-backed guidance, safety tips, and checklists.</Typography>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default Clean