import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Typographyy from "../../../components/typography";
import { Typography } from "@mui/material";
import Radio from "@mui/material/Radio";
import { BiUserPlus } from "react-icons/bi";

import { Box } from "@mui/system";
import Button from "@mui/material/Button";

import FormControlLabel from "@mui/material/FormControlLabel";

import { Link } from "react-router-dom";

import CardContent from "@mui/material/CardContent";

import Header from "../../../components/header";

export default function Retake() {
  return (
    <>
      <Header></Header>
      <Grid
        container
        className="gridpadding1"
        style={{ background: "#282c34" }}
      >
        <Grid 
           xs={12}
           sm={12}
           md={6}
           item
           sx={{
            paddingLeft: "20px",
            paddingRight: "20px",

            textAlign: "left",
          }}
          className="manpad"
        >

            <Typography variant="h3" style={{color:"white",fontWeight:"bold"}}>
                let's try again
            </Typography>
            <Typography variant="h4" style={{color:"white",marginTop:"20px"}}>
               Looks like we can't read your photo. Make sure that all the tet on your Id or passport page is visible and that it shows the picture of your face. This will help us match your Id to other info. 
            </Typography>

<div style={{paddingTop:"40px"}}>
    <img width="100%" height="230px" src="./images/identity.jpg"/>
</div>

        </Grid>
        
        
        
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          sx={{
            paddingLeft: "60px",
            paddingRight: "60px",

            textAlign: "center",
          }}
          className="manpad"
        >
          <div
            style={{
              border: "1px solid white",
              borderRadius: "10px",
              padding: "10px",
            }}
          >
            <div sx={{ padding: 3, textAlign: "center" }}>
              <Link to="">
                <Typographyy
                  variant="h5"
                  sx={{
                    color: "white",
                    marginTop: 2,
                    textDecoration: "underline",
                  }}
                  bold="bold"
                  text="Your Privacy"
                />
              </Link>

              <Typography
                style={{
                  color: "white",
                  padding: "2px",
                  fontSize: "18px",
                  textAlign: "center",
                }}
              >
                We aim to keep the data you share during this process private,
                safe, and secure. Learn more in our
              </Typography>

              <Link to="">
                <Typographyy
                  variant="h5"
                  sx={{
                    color: "white",
                    padding: "2px",

                    textDecoration: "underline",
                  }}
                  bold="bold"
                  text="Privacy Policy"
                />
              </Link>
              <Link to="">
                <Typographyy
                  variant="h5"
                  sx={{
                    color: "white",
                    padding: "2px",

                    textDecoration: "underline",
                  }}
                  bold="bold"
                  text="How this works"
                />
              </Link>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
