import React from 'react';
import List from '@mui/material/List';
import { Divider } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const style = {
  width: '100%',
  bgcolor: '#1b182b',
  padding: 0,
  color: 'white',
};

const ListItems = ({ primary, secondary, onClick }) => {
  return (
    <List dense sx={style} component="nav" aria-label="mailbox folders">
      <ListItem onClick={onClick} button>
        <ListItemText primary={primary} secondary={secondary} />
      </ListItem>
      <Divider />
    </List>
  );
};

export default ListItems;
