import { Box, CssBaseline, Grid, Link } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { authPassVerify } from '../../../services/Authentication/AuthPassVerify';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Input from '../../../components/form/input';
import Buttons from '../../../components/form/button';
import LOGO from '../../../assets/images/logo.png';
import { resetPasswordApi } from '../../../services/Authentication/resetPassword';
import * as yup from 'yup';
import { useFormik } from 'formik';
import InputPass from '../../../components/form/inputpass';
import { Toast } from 'primereact/toast';
const AuthVerifyPassword = () => {
  const { token } = useParams();
  const { email } = useParams();
  const toast = useRef(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const verifyAuthPass = async () => {
      await authPassVerify(token, email)
        .then(() => {})
        .catch(() => {
          navigate('/login');
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Unauthorized!',
            life: 3000,
          });
        });
    };
    verifyAuthPass();
  }, [token, email, navigate]);

  const history = useNavigate();

  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: () => {
      return yup.object({
        password: yup.string().required('Password is required!'),
      });
    },
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const response = await resetPasswordApi({
          email: email,
          token: token,
          newPassword: values.password,
        });
        if (response?.status === 200) {
          setIsLoading(false);
          toast.current.show({
            severity: 'success',
            summary: 'Password resetted ',
            detail: 'Password resetted successfully',
            life: 3000,
          });
          setIsLoading(false);
          history('/login');
        }
      } catch (err) {
        if (err?.data?.code === 401)
          toast.current.show({
            severity: 'success',
            summary: 'Couldnt reset your password!',
            detail: 'Your password coudlnt be resetted successfully!',
            life: 3000,
          });
        setIsLoading(false);
      }
    },
  });

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <div>
      <Toast ref={toast} position='bottom-right' />
      <Grid
        container
        component='main'
        sx={{ height: '100vh', overflow: 'hidden' }}
      >
        <CssBaseline />

        <Grid
          style={{ background: '#1b182b' }}
          item
          xs={12}
          sm={12}
          md={12}
          component={Paper}
          elevation={6}
          square
          justifyContent={'center'}
          display={'flex'}
        >
          <Grid xs={5} sm={5} md={5}>
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
              }}
            >
              <Grid item xs={12} justifyContent='center' display='flex'>
                <a href='/' className='logo-holder'>
                  <img
                    src={`${LOGO}`}
                    width='242px'
                    height='100px'
                    marginBottom='20px'
                    alt=''
                  />
                </a>
              </Grid>
              <Typography textAlign='center' component='h1' variant='h5'>
                <h4>Reset Password</h4>
              </Typography>
              <Box component='form' noValidate sx={{ mt: 5 }}>
                <InputPass
                  sx={{ mb: 12, fontSize: '14px', position: 'relative' }}
                  id={'password'}
                  name={'password'}
                  label={'New Password'}
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  defaultValue={formik.values.password}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />
                <Buttons
                  title='Reset Password'
                  loadingTitle={'Resetting Password'}
                  isLoading={isLoading}
                  style={{ height: 55, mb: 5, mt: 0, fontSize: 14 }}
                  onSubmit={formik.handleSubmit}
                />
                <Grid container justifyContent={'center'} alignItems={'center'}>
                  <Copyright
                    sx={{
                      mt: 2,
                      fontSize: '14px',
                      position: 'relative',
                      display: 'block',
                      marginTop: '33%',
                    }}
                  />
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

function Copyright(props) {
  return (
    <Typography
      variant='body2'
      color='text.secondary'
      align='center'
      {...props}
    >
      {'© Staywo Inc.USA 2022 . All rights reserved.2022.'}
      {/* {new Date().getFullYear()} */}
      {'.'}
    </Typography>
  );
}
export default AuthVerifyPassword;
