import React, { useState, useEffect } from 'react';
import Buttons from '../../../../components/form/button';
import Dropdown from '../../../../components/form/dropdown';
import Input from '../../../../components/form/input';
import TextareaField from '../../../../components/form/textarea';
import { EditorState, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
  getZipCode,
} from 'use-places-autocomplete';
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import {
  Divider,
  Grid,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from '@mui/material';
import ListItems from '../../../../components/listItems';
import useOnclickOutside from 'react-cool-onclickoutside';
import { convertToHTML, convertFromHTML } from 'draft-convert';
import ListedStatus from '../../../../components/Listingstatus/Dialouge';
import Select from 'react-select';
import './style.css';
import { useNavigate } from 'react-router-dom';

const ListingBasic = ({
  formik,
  spaceValues,
  spaceTypeValues,
  areaValues,
  isButtonLoading,
  spaceVal,
  setSpaceVal,
  getSpaceType,
  setSpaceTypeVal,
  spaceTypeVal,
  areaVal,
  setAreaVal,
  propertyType,
  setPropertyType,
  privateBath,
  setPrivateBath,
  validationType,
  editorState,
  handleEditorChange,
  propertyDescLength,
  setSpaceTypeValues,
  temporaryOptions,
  noOfBathrooms,
  setNoOfBathrooms,
  floodRelief,
  handleChangeFlood,
  mapCenter,
  setMapCenter,
  propertyId,
  propertyDetail,
}) => {
  let maxLength = 2000;

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });
  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const handleSelect =
    ({ description }) =>
    () => {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setValue(description, false);
      clearSuggestions();

      // Get latitude and longitude via utility functions
      let latitude = 0;
      let longitude = 0;
      getGeocode({ address: description })
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          latitude = lat;
          longitude = lng;
          setMapCenter({
            lat: lat,
            lng: lng,
          });
        })
        .catch((error) => {
          console.log('😱 Error: ', error);
        });
      let place_id = '';
      getGeocode({ address: description })
        // By default we use the "long_name" value from API response, you can tell the utility to use "short_name"
        // by setting the second parameter to "true"
        .then((results) => {
          let city, state, country;
          for (let i = 0; i < results[0].address_components.length; i++) {
            for (
              let j = 0;
              j < results[0].address_components[i].types.length;
              j++
            ) {
              switch (results[0].address_components[i].types[j]) {
                case 'locality':
                  city = results[0].address_components[i].long_name;
                  break;
                case 'administrative_area_level_1':
                  state = results[0].address_components[i].long_name;
                  break;
                case 'country':
                  country = results[0].address_components[i].long_name;
                  break;
                default:
                  break;
              }
            }
          }

          try {
            formik.setFieldValue('address', description);
            formik.setFieldValue('state', state);
            formik.setFieldValue('city', city);
            formik.setFieldValue('country', country);
            formik.setFieldValue('zipCode', getZipCode(results[0]));
            formik.setFieldValue('lat', latitude);
            formik.setFieldValue('lng', longitude);
          } catch (error) {
            console.log('Error: ', error);
          }
        });
    };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <ListItems
          key={place_id}
          onClick={handleSelect(suggestion)}
          primary={main_text}
          secondary={secondary_text}
        />
      );
    });

  const handleChange = (selectedOption) => {
    setSpaceVal(selectedOption);
    let temppppp = [...temporaryOptions];
    let ress = temppppp.findIndex((item) => {
      return item.type.value == selectedOption.value;
    });
    if (temppppp.length > 0) {
      if (ress == -1) {
        getSpaceType(selectedOption);
      } else {
        setSpaceTypeValues(temppppp[ress].data);
      }
    } else getSpaceType(selectedOption);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: 'black',
      fontSize: '16px',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      const color = '#fff';
      return { ...provided, opacity, transition, color };
    },
  };

  const onMapClicked = (props, map, e) => {
    setMapCenter({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  };

  const textLength = editorState.getCurrentContent().getPlainText().length;

  const getNewCoords = (coords) => {
    const { latLng } = coords;
    const lat = latLng.lat();
    const lng = latLng.lng();
    setMapCenter({ lat: lat, lng: lng });
    getGeocode({ location: { lat, lng } })
      .then((results) => {
        let city, state, country;
        for (let i = 0; i < results[0].address_components.length; i++) {
          for (
            let j = 0;
            j < results[0].address_components[i].types.length;
            j++
          ) {
            switch (results[0].address_components[i].types[j]) {
              case 'locality':
                city = results[0].address_components[i].long_name;
                break;
              case 'administrative_area_level_1':
                state = results[0].address_components[i].long_name;
                break;
              case 'country':
                country = results[0].address_components[i].long_name;
                break;
              default:
                break;
            }
          }
        }

        try {
          formik.setFieldValue('address', results[0]?.formatted_address);
          formik.setFieldValue('state', state);
          formik.setFieldValue('city', city);
          formik.setFieldValue('country', country);
          formik.setFieldValue('zipCode', getZipCode(results[0]));
          formik.setFieldValue('lat', lat);
          formik.setFieldValue('lng', lng);
          clearSuggestions();
          setValue(results[0]?.formatted_address, false);
        } catch (error) {
          console.log('Error: ', error);
        }
      })
      .catch((err) => console.log(err));
  };

  const navigate = useNavigate();
  return (
    <>
      <div className='dasboard-widget-title fl-wrap'>
        <h5>
          <i className></i>
          Basic Listing
        </h5>
      </div>

      <div className='dasboard-widget-box fl-wrap'>
        <div>
          <Typography
            sx={{ color: 'red', fontWeight: 'bold', fontSize: '18px' }}
          >
            Edit your property in Disaster management camp?
          </Typography>
          <RadioGroup
            aria-labelledby='demo-radio-buttons-group-label'
            name='floodRelief'
            onChange={handleChangeFlood}
            value={floodRelief}
          >
            <FormControlLabel
              value={true}
              control={<Radio />}
              label='Yes'
              sx={{ color: '#fff', fontWeight: 'bold' }}
            />
            <FormControlLabel
              value={false}
              control={<Radio />}
              label='No'
              sx={{ color: '#fff', fontWeight: 'bold' }}
            />
          </RadioGroup>

          <Typography
            variant={'h6'}
            sx={{ marginTop: '15px', paddingBottom: 3, textAlign: 'initial' }}
          >
            Property Description
          </Typography>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 1, md: 2 }}
            columnSpacing={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={12} sm={12} md={12}>
              <Typography>
                Property Title ({50 - formik.values?.title?.length} characters
                remaining out of 50)
              </Typography>
              <Input
                id={'title'}
                name={'title'}
                label={'Listing Title'}
                onChange={formik.handleChange}
                value={formik.values.title}
                defaultValue={formik.values.title}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
                maxLength={50}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>
                Property Description ({2000 - textLength} characters remaining
                out of {2000})
              </Typography>
              <Editor
                editorState={editorState && editorState}
                id={'description'}
                name={'description'}
                onEditorStateChange={handleEditorChange && handleEditorChange}
                wrapperClassName='wrapper--class'
                editorClassName='editor--class'
                toolbarClassName='toolbar--class'
                handleBeforeInput={(val) => {
                  if (val && textLength >= 1999) {
                    return 'handled';
                  }
                  return 'not-handled';
                }}
                handlePastedText={(val) => {
                  return val.length + textLength >= 1999;
                }}
              />
            </Grid>
          </Grid>
          <Divider sx={{ paddingTop: 3 }} />
          <Typography
            variant={'h6'}
            sx={{ paddingTop: 3, textAlign: 'initial' }}
          >
            Property Type{' '}
          </Typography>
          <Typography style={{ fontSize: 13 }}>
            Choose a property type that’s most like your place to set
            expectations for guests and help your listing appear in the right
            searches.
          </Typography>

          <Grid
            container
            rowSpacing={{ xs: 1, sm: 1, md: 2 }}
            columnSpacing={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={12} sm={12} md={4}>
              <Typography>Space</Typography>
              <Select
                className='override-select-edit'
                placeholder='Select Space'
                options={spaceValues}
                value={spaceVal}
                // menuIsOpen={true}
                onChange={handleChange}
                styles={customStyles}
              />
              {validationType.open && validationType.type == 'space' && (
                <p style={{ color: 'red' }}>{validationType.message}</p>
              )}

              {/* <Dropdown
                id={'spaceId'}
                name={'spaceId'}
                label={'Space'}
                options={spaceValues}
                onChange={formik.handleChange}
                value={formik.values.spaceId}
                error={formik.touched.spaceId && Boolean(formik.errors.spaceId)}
                helperText={formik.touched.spaceId && formik.errors.spaceId}
                defaultValue={formik.values.spaceId}
              /> */}
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Typography>Space Type</Typography>
              <Select
                className='override-select-edit'
                placeholder='Select Space Type'
                options={spaceTypeValues}
                value={spaceTypeVal}
                onChange={(option) => setSpaceTypeVal(option)}
                styles={customStyles}
              />
              {validationType.open && validationType.type == 'spaceType' && (
                <p style={{ color: 'red' }}>{validationType.message}</p>
              )}
              {/* <Dropdown
                id={"spaceTypeId"}
                name={"spaceTypeId"}
                label={"Space Type"}
                options={spaceTypeValues}
                onChange={formik.handleChange}
                value={formik.values.spaceTypeId}
                error={
                  formik.touched.spaceTypeId &&
                  Boolean(formik.errors.spaceTypeId)
                }
                helperText={
                  formik.touched.spaceTypeId && formik.errors.spaceTypeId
                }
                defaultValue={formik.values.spaceTypeId}
              /> */}
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Typography>Area</Typography>
              <Select
                className='override-select-edit'
                placeholder='Select Area'
                options={areaValues}
                value={areaVal}
                onChange={(option) => setAreaVal(option)}
                styles={customStyles}
              />
              {validationType.open && validationType.type == 'area' && (
                <p style={{ color: 'red' }}>{validationType.message}</p>
              )}
              {/* <Dropdown
                id={"areaId"}
                name={"areaId"}
                label={"Area"}
                options={areaValues}
                onChange={formik.handleChange}
                value={formik.values.areaId}
                error={formik.touched.areaId && Boolean(formik.errors.areaId)}
                helperText={formik.touched.areaId && formik.errors.areaId}
                defaultValue={formik.values.areaId}
              /> */}
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Typography>Property Type</Typography>
              <Select
                className='override-select-edit'
                placeholder='Select Property Type'
                options={[
                  { label: 'Private', value: 'Private' },
                  {
                    label: 'Commercial',
                    value: 'Commercial',
                  },
                ]}
                value={propertyType}
                onChange={(option) => setPropertyType(option)}
                styles={customStyles}
              />
              {validationType.open && validationType.type == 'propertyType' && (
                <p style={{ color: 'red' }}>{validationType.message}</p>
              )}
              {/* <Dropdown
                id={"propertyType"}
                name={"propertyType"}
                label={"Type"}
                options={[
                  { id: "Private", title: "Private" },
                  { id: "Commercial", title: "Commercial" },
                ]}
                defaultValue={formik.values.propertyType}
                onChange={formik.handleChange}
                value={formik.values.propertyType}
                error={
                  formik.touched.propertyType &&
                  Boolean(formik.errors.propertyType)
                }
                helperText={
                  formik.touched.propertyType && formik.errors.propertyType
                }
              /> */}
            </Grid>
          </Grid>
          <Divider sx={{ paddingTop: 3 }} />
          <Typography
            variant={'h6'}
            sx={{ paddingTop: 3, textAlign: 'initial' }}
          >
            Custom Link
          </Typography>
          <Typography sx={{ textAlign: 'initial', fontSize: 13 }}>
            A memorable and unique link can make it easy to share your listing
            on business cards, websites, or social media
          </Typography>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 1, md: 2 }}
            columnSpacing={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={12} sm={12} md={12}>
              <Input
                id={'customUrl'}
                name={'customUrl'}
                label={'Custom Link'}
                onChange={formik.handleChange}
                value={formik.values.customUrl}
                defaultValue={formik.values.customUrl}
                error={
                  formik.touched.customUrl && Boolean(formik.errors.customUrl)
                }
                helperText={formik.touched.customUrl && formik.errors.customUrl}
              />
            </Grid>
          </Grid>
          <Divider sx={{ paddingTop: 3 }} />
          <Typography
            variant={'h6'}
            sx={{ paddingBottom: 3, paddingTop: 3, textAlign: 'initial' }}
          >
            Guest Info
          </Typography>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 1, md: 2 }}
            columnSpacing={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={12} sm={12} md={4}>
              <Typography>Adults</Typography>
              <TextField
                variant='outlined'
                fullWidth
                type='number'
                name='noOfAdults'
                value={formik.values.noOfAdults}
                onChange={formik.handleChange}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 2);
                }}
                defaultValue={formik.values.noOfAdults}
                error={
                  formik.touched.noOfAdults && Boolean(formik.errors.noOfAdults)
                }
                helperText={
                  formik.touched.noOfAdults && formik.errors.noOfAdults
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Typography>Children</Typography>
              <TextField
                variant='outlined'
                fullWidth
                type='number'
                name='noOfChildren'
                value={formik.values.noOfChildren}
                onChange={formik.handleChange}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 2);
                }}
                defaultValue={formik.values.noOfChildren}
                error={
                  formik.touched.noOfChildren &&
                  Boolean(formik.errors.noOfChildren)
                }
                helperText={
                  formik.touched.noOfChildren && formik.errors.noOfChildren
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Typography>Infants</Typography>
              <TextField
                variant='outlined'
                fullWidth
                type='number'
                name='noOfInfants'
                value={formik.values.noOfInfants}
                onChange={formik.handleChange}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 2);
                }}
                defaultValue={formik.values.noOfInfants}
                error={
                  formik.touched.noOfInfants &&
                  Boolean(formik.errors.noOfInfants)
                }
                helperText={
                  formik.touched.noOfInfants && formik.errors.noOfInfants
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Typography>Pets</Typography>
              <TextField
                variant='outlined'
                fullWidth
                type='number'
                name='noOfPets'
                value={formik.values.noOfPets}
                onChange={formik.handleChange}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 2);
                }}
                defaultValue={formik.values.noOfPets}
                error={
                  formik.touched.noOfPets && Boolean(formik.errors.noOfPets)
                }
                helperText={formik.touched.noOfPets && formik.errors.noOfPets}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <Typography>Bedrooms</Typography>
              <TextField
                variant='outlined'
                fullWidth
                type='number'
                name='noOfBedrooms'
                value={formik.values.noOfBedrooms}
                onChange={formik.handleChange}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 2);
                }}
                defaultValue={formik.values.noOfBedrooms}
                error={
                  formik.touched.noOfBedrooms &&
                  Boolean(formik.errors.noOfBedrooms)
                }
                helperText={
                  formik.touched.noOfBedrooms && formik.errors.noOfBedrooms
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Typography>Bathrooms</Typography>
              <Input
                type={'number'}
                id={'noOfBathrooms'}
                name={'noOfBathrooms'}
                label={'Bathroom'}
                onChange={(e) => {
                  if (e.target.value <= 50) {
                    setNoOfBathrooms(e.target.value);
                  }
                }}
                value={noOfBathrooms}
                defaultValue={noOfBathrooms}
                // error={
                //   formik.touched.noOfBathrooms &&
                //   Boolean(formik.errors.noOfBathrooms)
                // }
                // helperText={
                //   formik.touched.noOfBathrooms && formik.errors.noOfBathrooms
                // }
                min={1}
                step={0.5}
              />
              {validationType.open &&
                validationType.type == 'noOfBathrooms' && (
                  <p style={{ color: 'red' }}>{validationType.message}</p>
                )}
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Typography>Beds</Typography>
              <TextField
                variant='outlined'
                fullWidth
                type='number'
                name='nOfBeds'
                value={formik.values.nOfBeds}
                onChange={formik.handleChange}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 2);
                }}
                defaultValue={formik.values.noOfBeds}
                error={
                  formik.touched.noOfBeds && Boolean(formik.errors.noOfBeds)
                }
                helperText={formik.touched.noOfBeds && formik.errors.noOfBeds}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Typography>Private Bathroom</Typography>
              <Select
                className='override-select-edit'
                placeholder='Private Bathroom'
                options={[
                  { label: 'Yes', value: true },
                  {
                    label: 'No',
                    value: false,
                  },
                ]}
                value={privateBath}
                onChange={(option) => setPrivateBath(option)}
                styles={customStyles}
              />
              {validationType.open && validationType.type == 'privateBath' && (
                <p style={{ color: 'red' }}>{validationType.message}</p>
              )}

              {/* <Dropdown
                id={"isPrivateBathroom"}
                name={"isPrivateBathroom"}
                label={"Private Bathroom"}
                options={[
                  { id: true, title: "Yes" },
                  { id: false, title: "No" },
                ]}
                onChange={formik.handleChange}
                value={formik.values.isPrivateBathroom}
                error={
                  formik.touched.isPrivateBathroom &&
                  Boolean(formik.errors.isPrivateBathroom)
                }
                helperText={
                  formik.touched.isPrivateBathroom &&
                  formik.errors.isPrivateBathroom
                }
                defaultValue={formik.values.isPrivateBathroom}
              /> */}
            </Grid>
          </Grid>
          <Divider sx={{ paddingTop: 3 }} />
          <Typography
            variant={'h6'}
            sx={{ paddingBottom: 3, paddingTop: 3, textAlign: 'initial' }}
          >
            Location
          </Typography>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={12}>
              <div ref={ref}>
                <Input
                  value={value}
                  onChange={handleInput}
                  disabled={!ready}
                  defaultValue={value}
                  label='Where is your property located?'
                />

                {status === 'OK' && (
                  <ul style={{ position: 'absolute', zIndex: 9999 }}>
                    {renderSuggestions()}
                  </ul>
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <div className='map-container' style={{ marginTop: 0 }}>
                <Typography>
                  Move the marker to provide exact location
                </Typography>
                <div id='singleMap' className='drag-map'>
                  <Map
                    google={window.google}
                    zoom={14}
                    className={'map'}
                    initialCenter={mapCenter}
                    center={mapCenter}
                    onClick={onMapClicked}
                  >
                    <Marker
                      position={mapCenter}
                      name={'Current location'}
                      draggable={true}
                      onDragend={(t, map, coord) => getNewCoords(coord)}
                    />
                  </Map>
                  {/* <Map
                    mapValues={[mapCenter]}
                    userLat={props.hostLocation?.latitude}
                    userLong={props.hostLocation?.longitude}
                  /> */}
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Typography
                variant={'h6'}
                sx={{ paddingTop: 3, textAlign: 'initial' }}
              >
                Address
              </Typography>
              <Typography style={{ fontSize: 12 }}>
                House name/number + street/road
              </Typography>
              <Input
                label={'Address'}
                name={'address'}
                id={'address'}
                onChange={formik.handleChange}
                value={formik.values.address || ''}
                defaultValue={formik.values.address}
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Typography>Country</Typography>
              <Input
                label={'Country'}
                name={'country'}
                id={'country'}
                onChange={formik.handleChange}
                value={formik.values.country || ''}
                defaultValue={formik.values.country}
                error={formik.touched.country && Boolean(formik.errors.country)}
                helperText={formik.touched.country && formik.errors.country}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Typography>City</Typography>

              <Input
                label={'City'}
                name={'city'}
                id={'city'}
                onChange={formik.handleChange}
                value={formik.values.city || ''}
                defaultValue={formik.values.city}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Typography>State</Typography>

              <Input
                label={'State'}
                name={'state'}
                id={'state'}
                onChange={formik.handleChange}
                value={formik.values.state || ''}
                defaultValue={formik.values.state}
                error={formik.touched.state && Boolean(formik.errors.state)}
                helperText={formik.touched.state && formik.errors.state}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Typography>Zip Code</Typography>

              {/* <Input
                label={"Zipcode"}
                name={"zipCode"}
                id={"zipCode"}
                onChange={formik.handleChange}
                value={formik.values.zipCode || ""}
                defaultValue={formik.values.zipCode}
                error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
                helperText={formik.touched.zipCode && formik.errors.zipCode}
              /> */}
              <TextField
                variant='outlined'
                fullWidth
                type='number'
                name='zipCode'
                value={formik.values.zipCode || ''}
                onChange={formik.handleChange}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 7);
                }}
                defaultValue={formik.values.zipCode}
                error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
                helperText={formik.touched.zipCode && formik.errors.zipCode}
              />
            </Grid>

            <Divider sx={{ paddingTop: 3, marginBottom: 3 }} />

            {!(propertyDetail?.status === 'PENDING') && (
              <Grid item xs={12} sm={12} md={12}>
                <Divider sx={{ paddingTop: 3, marginBottom: 3 }} />

                <Typography
                  variant={'h6'}
                  sx={{ paddingTop: 3, textAlign: 'initial' }}
                >
                  Listing Status
                </Typography>
                <ListedStatus
                  status={formik.initialValues.status}
                  propertyId={propertyId}
                />
              </Grid>
            )}
          </Grid>

          <Divider sx={{ paddingTop: 2, marginBottom: 3 }} />
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 1, md: 2 }}
            columnSpacing={{ xs: 1, sm: 1, md: 2 }}
          >
            <Typography
              variant={'h6'}
              sx={{ paddingTop: 3, textAlign: 'initial' }}
            >
              Property Documents
            </Typography>
            <Grid item xs={12} sm={12} md={2}>
              <Buttons
                title={'Manage Documents'}
                isLoading={isButtonLoading}
                onSubmit={() =>
                  navigate(`/admin/manage-property-documents/${propertyId}`)
                }
              />
            </Grid>
          </Grid>
          <Divider sx={{ paddingTop: 2, marginBottom: 3 }} />
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 1, md: 2 }}
            columnSpacing={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={12} sm={12} md={2}>
              <Buttons
                title='Save'
                isLoading={isButtonLoading}
                onSubmit={formik.handleSubmit}
                loadingTitle={'Saving'}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
})(ListingBasic);
