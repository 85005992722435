import React, { useState, useEffect,useRef } from 'react';
import InfoBar from '../../../components/dasboard/infoBar';
import Sidebar from '../../../components/dasboard/sidebar';
import Header from '../../../components/header';
import { Box, InputLabel, Typography, TextField } from '@mui/material';
import { options } from '../../../constants/countries';
import { Dropdown } from 'primereact/dropdown';
import Buttons from '../../../components/form/button';
import { addBankDetail } from '../../../services/BankDetail/AddBankAccount';
import { getBankDetail } from '../../../services/BankDetail/GetBankDetail';
import { Toast } from 'primereact/toast';


const AddBankAccountPage = () => {
  const userData = JSON.parse(localStorage.getItem('userData'));
  const toast = useRef();


  const [selectedCountry, setSelectedCountry] = useState({});
  const onCountryChange = (e) => {
    setSelectedCountry(e.value);
  };
  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="country-item country-item-value">
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };
  const countryOptionTemplate = (option) => {
    return (
      <div className="country-item">
        <div>{option.name}</div>
      </div>
    );
  };
  const [Data, setData] = useState({
    bankName: "",
    branchName: "",
    accountNumber: "",
    swiftCode: "",
    IBAN: "",


  });
  const userId = userData.id;
  let country = selectedCountry.name;
  let countryCode = selectedCountry.code;
  const handleChange = (e) => {
    setData((s) => ({ ...s, [e.target.name]: e.target.value }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const userBankDetail = ({ ...Data, country, countryCode,userId });

    const bankDetail = await addBankDetail(userBankDetail);
    if ( bankDetail.status == 200) {
      toast.current.show({
        severity: 'success',
        summary: 'Bank Detail Added successfully!',
        life: 10000,
      });

    }
    if ( bankDetail.status == 401) {
      toast.current.show({
        severity: 'error',
        summary: 'Error During Add Bank Detail!',
        life: 10000,
      });

    }
  }
  const getUserBankDetail = async () => {
    const bankDetail = await getBankDetail(userData.id);
    if ( bankDetail.status == 403) {
      toast.current.show({
        severity: 'error',
        summary: 'Enter Your Bank Detail!',
        life: 10000,
      });

    }
    setData(bankDetail.data);
    const name = bankDetail.data.country;
    const code = bankDetail.data.countryCode;
    setSelectedCountry({ name, code });
   

  }
  useEffect(() => {
    getUserBankDetail();
  }, [])
  return (
    <>
      <Toast ref={toast} position='bottom-right' />

      <Header />
      <div id="wrapper">
        <div className="dashboard-menu-overlay" />
        <Sidebar page={'My Earnings'} />
        <div className="dashboard-content">
          <div className="dashboard-menu-btn color-bg">
            <span>
              <i className="fas fa-bars" />
            </span>
            Add Bank Account
          </div>
          <InfoBar label={'Add Bank Account'} />
          <div className="dasboard-wrapper fl-wrap no-pag">
            <div>
              <div className="clearfix" />
              <div className="row">
                <div className="col-md-8">
                  <div
                    className="dasboard-content fl-wrap"
                    style={{ margin: '10px 0px' }}
                  >
                    <Box sx={{ p: 3 }}>
                      <Box sx={{ display: 'flex' }}>
                        <Typography
                          sx={{
                            marginRight: '5px',
                            fontSize: '22px',
                            color: '',
                          }}
                        ></Typography>
                        <Typography sx={{ fontSize: '22px', color: '#fff' }}>
                          Enter your bank details.
                        </Typography>
                      </Box>
                      <Box>
                        <InputLabel
                          htmlFor="amount"
                          sx={{ margin: '10px 0px' }}
                        >
                          Select your country.
                        </InputLabel>
                        <Dropdown
                          value={selectedCountry}
                          options={options}
                          onChange={onCountryChange}
                          optionLabel="name"
                          filter
                          showClear
                          filterBy="name"
                          placeholder="Select region/country."
                          valueTemplate={selectedCountryTemplate}
                          itemTemplate={countryOptionTemplate}
                          style={{
                            width: '100%',
                            background: 'transparent',
                            padding: '6px 5px',
                          }}
                        />
                      </Box>
                      <Box>
                        <InputLabel
                          htmlFor="amount"
                          sx={{ margin: '10px 0px' }}
                        >
                          Enter your bank name here.
                        </InputLabel>
                        <TextField
                          id="outlined-basic"
                          label="Bank Name"
                          name='bankName'
                          value={Data.bankName}
                          variant="outlined"
                          fullWidth
                          onChange={handleChange}
                        />
                      </Box>
                      <Box>
                        <InputLabel
                          htmlFor="amount"
                          sx={{ margin: '10px 0px' }}
                        >
                          Enter your bank branch name here.
                        </InputLabel>
                        <TextField
                          id="outlined-basic"
                          label="Bank Branch Name"
                          name="branchName"
                          value={Data.branchName}
                          variant="outlined"
                          fullWidth
                          onChange={handleChange}
                        />
                      </Box>
                      <Box>
                        <InputLabel
                          htmlFor="amount"
                          sx={{ margin: '10px 0px' }}
                        >
                          Enter your bank account number here.
                        </InputLabel>
                        <TextField
                          id="outlined-basic"
                          label="Bank Account Number"
                          name="accountNumber"
                          value={Data.accountNumber}
                          variant="outlined"
                          fullWidth
                          onChange={handleChange}
                        />
                      </Box>
                      <Box>
                        <InputLabel
                          htmlFor="amount"
                          sx={{ margin: '10px 0px' }}
                        >
                          Enter SWIFT Code here.
                        </InputLabel>
                        <TextField
                          id="outlined-basic"
                          label="SWIFT Code"
                          name="swiftCode"
                          value={Data.swiftCode}
                          variant="outlined"
                          fullWidth
                          onChange={handleChange}
                        />
                      </Box>
                      <Box>
                        <InputLabel
                          htmlFor="amount"
                          sx={{ margin: '10px 0px' }}
                        >
                          Enter IBAN Code here.
                        </InputLabel>
                        <TextField
                          id="outlined-basic"
                          label="IBAN"
                          name="IBAN"
                          value={Data.IBAN}
                          variant="outlined"
                          fullWidth
                          onChange={handleChange}
                        />
                      </Box>
                    </Box>
                    <Box>
                      <Buttons
                        isLoading={false}
                        title={'Add Bank'}
                        style={{ width: '50%', margin: '15px 0px' }}
                        onSubmit={handleSubmit}
                      />
                    </Box>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddBankAccountPage;
