import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Input from '../../components/form/input';
import CssBaseline from '@mui/material/CssBaseline';
import Buttons from '../../components/form/button';
import BACKGROUND from '../../assets/images/forgot.png';
import { ForgetPasswordEmailValidationSchema } from './ForgetPasswordEmailValidationSchema';
import LOGO from '../../assets/images/logo.png';
import { Toast } from 'primereact/toast';
import { forgetPassword } from '../../services/Authentication/ForgetPassword';

function Copyright(props) {
  return (
    <Typography
      variant='body2'
      color='text.secondary'
      align='center'
      {...props}
    >
      {'© Staywo Inc.USA 2022 . All rights reserved.2022.'}
      {/* {new Date().getFullYear()} */}
      {'.'}
    </Typography>
  );
}

const PhoneNumberLoginPage = () => {
  const history = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: () => {
      return ForgetPasswordEmailValidationSchema;
    },
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const response = await forgetPassword(values?.email);
        if (response?.status === 200) {
          setIsLoading(false);
          toast.current.show({
            severity: 'success',
            summary: 'Password reset email sent successfully.',
            detail:
              'Password reset email sent successfully, please check your email!',
            life: 3000,
          });
          setIsLoading(false);
          history('/login');
        }
      } catch (err) {
        if (err?.data?.code === 401)
          toast.current.show({
            severity: 'success',
            summary: 'Couldnt send Password reset email send.',
            detail: 'Password reset email cannot be sent.',
            life: 3000,
          });
        setIsLoading(false);
      }
    },
  });

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  const toast = React.useRef(null);
  return (
    <>
      <Toast ref={toast} position='bottom-right' />
      <Grid
        container
        component='main'
        sx={{ height: '100vh', overflow: 'hidden' }}
      >
        <CssBaseline />

        <Grid
          style={{ background: '#1b182b' }}
          item
          xs={12}
          sm={8}
          md={4}
          component={Paper}
          elevation={6}
          square
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'left',
            }}
          >
            <Grid item xs={12} justifyContent='center' display='flex'>
              <a href='/' className='logo-holder'>
                <img
                  src={`${LOGO}`}
                  width='242px'
                  height='100px'
                  marginBottom='20px'
                  alt=''
                />
              </a>
            </Grid>
            <Typography textAlign='center' component='h1' variant='h5'>
              <h4>Forget Password</h4>
            </Typography>
            <Box component='form' noValidate sx={{ mt: 5 }}>
              <Input
                sx={{ mb: 3, fontSize: '14px', marginBottom: 8 }}
                id={'email'}
                name={'email'}
                label={'Enter your Email'}
                onChange={formik.handleChange}
                value={formik.values.email}
                defaultValue={formik.values.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <Buttons
                title='Email Confirmation'
                loadingTitle={'Email Confirming'}
                isLoading={isLoading}
                style={{ height: 55, mb: 5, mt: 0, fontSize: 14 }}
                onSubmit={formik.handleSubmit}
              />
              <Grid container justifyContent={'center'} alignItems={'center'}>
                <Grid item>
                  <Link
                    onClick={() => history('/login')}
                    variant='body2'
                    style={{ fontSize: '14px', cursor: 'pointer' }}
                  >
                    {'Need to use other login options?'}
                  </Link>
                </Grid>
                <Copyright
                  sx={{
                    mt: 2,
                    fontSize: '14px',
                    position: 'relative',
                    display: 'block',
                    marginTop: '33%',
                  }}
                />
              </Grid>
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={false}
          sm={4}
          md={8}
          sx={{
            backgroundImage: `url(${BACKGROUND})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light'
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
      </Grid>
    </>
  );
};

export default PhoneNumberLoginPage;
