import React, { useEffect, useState, useRef } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import { ReserveCard } from "./ReserveCard";

import moment from "moment";
import { Button } from "primereact/button";

import Header from "../../../components/header";
import Ratings from "../../../components/Ratings";
import Buttons from "../../../components/form/button";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  getPropertyByIdApi,
  getPropertyHouseRulesById,
  getPropertyMoreDetailsById,
} from "../../../services/properties/getProperty";
import YoutubeVideo from "../../../components/youtubeVideo";
import PropertyDetailGoogleMap from "../../../components/propertyDetailGoogleMap";
import { DetailPageCarousel } from "../../../components/shared/DetailPageCarousel";
import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Rating,
  Typography,
} from "@mui/material";
import { NavSections } from "./NavSections";
import { Dialog } from "primereact/dialog";
import { TabView, TabPanel } from "primereact/tabview";
import { AiFillStar } from "react-icons/ai";
import DUMMYAVATAR from "../../../assets/images/avatar/5.jpg";
import { Markup } from "interweave";
import ShowMoreText from "react-show-more-text";
import "./style.css";
import { Tooltip } from "primereact/tooltip";

import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { propertyDetailCommercialUpdated } from "../../../FakeData/commercialroomsproperties";
import CommercialRoomCard from "../../../components/CommercialProperties/CommercialRoomCard";
import { RoomsReserveCard } from "../../../components/CommercialProperties/RoomsReserveCard";
import { createPropertyViewApi } from "../../../services/properties/createPropertyView";
import SingleReview from "../../Admin-Panel/Review/SingleReview";
import { getPropertyViewsApi } from "../../../services/properties/getPropertyViews";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import { getHostReviewApi } from "../../../services/review/getHostReview";
import PropertyAmentiesDialog from "../../../components/PropertyAmentiesDialog";
import PropertyAllCriteria from "../../../components/PropertyAllCriteria";
import { getCancellationPolicyByPropertyId } from "../../../services/properties/getCancellationPolicy";
import AboutModal from "../../../components/modal/AboutModal";
import PropertyFacilitiessDialog from "../../../components/propertyFacilitiesDialog";
import PropertyNearbyActivityDialog from "../../../components/propertyNearbyActivityDialog";
import PropertyAccessibilitiesDialog from "../../../components/propertyAccessibiltiesDialog";
import PropertySafetyConsiderationDialog from "../../../components/propertySafetyConsiderationDialog";
import PropertySafetyFeaturesDialog from "../../../components/propertySafetyFeaturesDialog";
import PropertySecurityFeaturesDialog from "../../../components/propertySecurityFeaturesDialog";
import PropertyHouseRulesDialog from "../../../components/propertyHouseRulesDialog";
import { Toast } from "primereact/toast";
import { set } from "date-fns/esm";
import Pagination from "../../../components/Pagination/Pagination";
import { getPropertyAllReviewsApi } from "../../../services/review/getPropertyAllReviews";

const title = {
  titleOne: "Booking Protection Guarantee",
  titleTwo: "Check-In Guarantee",
  titleThree: "Get-What-You-Booked Guarantee",
  titleFour: "24-hour Safety Line",
};
const Detail = {
  DetailOne:
    "In the unlikely event a Host needs to cancel your booking within 30 days of check-in, we’ll find you a similar or better home, or we’ll refund you.",
  DetailTwo:
    "If you can’t check into your home and the Host cannot resolve the issue, we’ll find you a similar or better home for the length of your original stay, or we’ll refund you.",
  DetailThree:
    "If at any time during your stay you find your listing isn't as advertised—for example, the refrigerator stops working and your Host can’t easily fix it, or it has fewer bedrooms than listed—you'll have three days to report it and we’ll find you a similar or better home, or we’ll refund you.",
  DetailFour:
    "If you ever feel unsafe, you’ll get priority access to specially-trained safety agents, day or night.",
};
const PropertyDetailWithoutLogin = (props) => {
  const [propertyDetail, setPropertyDetail] = useState({});
  const [propertyHouseRules, setPropertyHouseRules] = useState([]);
  const [cancellationPolicy, setCancellationPolicy] = useState({});
  const [isSelect, setIsSelect] = useState(false);
  const [userDetail, setUserDetail] = useState({});
  const [guestReview, setGuestReview] = useState([]);
  const [hostReview, setHostReview] = useState([]);
  const [propertyExtraDetails, setPropertyExtraDetails] = useState({});

  const toast = useRef(null);

  const { id } = useParams();

  const getPropertyDetail = async () => {
    const response = await getPropertyByIdApi(id);
    if (response.status === 200) {
      setPropertyDetail(response.data.data);
      setUserDetail(response.data.data.user);
      const cancellationPolicyResponse =
        await getCancellationPolicyByPropertyId(id);
      setCancellationPolicy(
        cancellationPolicyResponse?.data?.cancellationPolicyId
      );
    }
  };
  const getPropertyHouseRules = async () => {
    const response = await getPropertyHouseRulesById(id);
    if (response.status === 200) {
      setPropertyHouseRules(response.data.data);
    }
  };

  const getPropertyMoreDetails = async () => {
    const response = await getPropertyMoreDetailsById(id);
    if (response.status === 200) {
      setPropertyExtraDetails(response.data.data);
    }
  };

  const getHostReview = async () => {
    const hr = await getHostReviewApi(id);
    setHostReview(hr);
  };

  useEffect(() => {
    getPropertyDetail();
    getHostReview();
    getPropertyHouseRules();
    getPropertyMoreDetails();
  }, [id]);

  const StyledCounter = styled.div`
    .thumbs-wrapper {
      margin: 0px !important;
    }
    .thumb {
      height: 130px !important;
      padding: 0px !important;
      margin: 0px !important;
    }
    .thumb {
      width: 20% !important;
    }

    img {
      height: 100%;
    }
    .p-galleria-thumbnail-item-content {
      overflow: hidden;
    }
  `;
  const [ip, setIp] = useState();
  const [propertyViews, setPropertyViews] = useState();
  const getIp = async () => {
    fetch(process.env.REACT_APP_IP_URL)
      .then((response) => response.json())
      .then((jsonResponse) => setIp(jsonResponse.ip));
  };

  const userData = JSON.parse(localStorage.getItem("userData"));

  useEffect(() => {
    const getPropertyViews = async () => {
      const pv = await getPropertyViewsApi(id);
      setPropertyViews(pv.data);
    };
    getIp();
    const createPropertyView = async () => {
      await createPropertyViewApi({
        propertyId: id,
        userId: userData?.id,
        ip: ip,
      });
    };
    setTimeout(() => {
      createPropertyView();
    }, 10000);
    getPropertyViews();
  }, [ip, id, userData?.id]);

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
      <Header />
      <Toast ref={toast} position='bottom-right' />

      <div id='wrapper'>
        <Jumbotron
          propertyDetail={propertyDetail}
          propertyViews={propertyViews}
          guestReview={guestReview}
          gst={props.gst}
        />
        {/* <Breadcrumbs /> */}
        <div
          className='gray-bg small-padding fl-wrap'
        // onClick={() => setIsSelect(!isSelect)}
        >
          <div className='container'>
            <div className='row'>
              <div className='col-md-8'>
                <div className='list-single-main-wrapper fl-wrap'>
                  <NavSections />
                  {propertyDetail?.propertyImages?.length > 0 ? (
                    <StyledCounter id='sec2'>
                      <DetailPageCarousel
                        propertyDetail={propertyDetail?.propertyImages}
                      />
                    </StyledCounter>
                  ) : null}
                  <Info
                    propertyDetail={propertyDetail}
                    propertyDetailCommercialUpdated={
                      propertyDetailCommercialUpdated
                    }
                    gst={props.gst}
                  />
                  {propertyDetail?.youtubeVideoLink === "" ? null : (
                    <Video link={propertyDetail?.youtubeVideoLink} />
                  )}
                  <Features
                    propertyDetail={propertyDetail}
                    propertyExtraDetails={propertyExtraDetails}
                    propertyHouseRules={propertyHouseRules}
                  />
                  <PropertyMap
                    lat={propertyDetail?.lat}
                    lng={propertyDetail?.lng}
                    address={propertyDetail?.address}
                  />

                  {/* Things to know */}
                  <ThingsToKnow
                    propertyDetail={propertyDetail}
                    cancellationPolicy={cancellationPolicy}
                  />

                  {/* Host Details  */}
                  <HostDetails
                    propertyDetail={propertyDetail}
                    guestReview={guestReview}
                  />

                  {/* <Reviews /> */}

                  <Reviews
                    guestReview={guestReview}
                    propertyDetail={propertyDetail}
                  />

                  {/* end */}
                </div>
              </div>

              <div
                className='col-md-4'
                style={{
                  height: "350vh",
                }}
              >
                <Profile user={userDetail} hostReview={hostReview} />

                {/* Reserve Card  */}
                {propertyDetail?.propertyType === "Commercial" ? (
                  <RoomsReserveCard
                    id={id}
                    isSelect={isSelect}
                    propertyDetail={propertyDetail}
                  />
                ) : (
                  <ReserveCard
                    id={id}
                    isSelect={isSelect}
                    propertyDetail={propertyDetail}
                    toast={toast}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Profile = (user, hostReview) => {
  const [showMore, setShowMore] = useState(false);
  let total = 0;
  let totalLength = hostReview?.items?.length;
  for (let i = 0; i < hostReview?.items?.length; i++) {
    total += hostReview?.items[i].over_all;
  }
  let totalRatingHost = total / totalLength;

  return (
    <ProfileStyled>
      <div className='box-widget fl-wrap'>
        <div className='profile-widget'>
          <div className='profile-widget-header color-bg smpar fl-wrap'>
            <div
              className='box-widget-menu-btn smact'
              onClick={() => setShowMore(!showMore)}
            >
              {/* <IconButton onClick={() => setShowMore(!showMore)}> */}
              {/* <i className="far fa-ellipsis-h"></i> */}
              {/* </IconButton> */}
            </div>
            <div
              class={
                showMore
                  ? "show-more-snopt-tooltip bxwt show-more-snopt-tooltip_vis"
                  : "show-more-snopt-tooltip bxwt"
              }
            >
              <a href='#'>
                {" "}
                <i className='fas fa-comment-alt'></i> Write a review
              </a>
              <a href='#'>
                {" "}
                <i className='fas fa-exclamation-triangle'></i> Report{" "}
              </a>
            </div>
            <div
              className='profile-widget-card'
              style={{ backgroundColor: "#096bb1" }}
            >
              {/* <img
                src="/diamond.png"
                alt="diamond_icon"
                width={35}
                height={35}
                style={{ position: 'absolute', left: '8px', top: '-2px' }}
              /> */}
              <div className='profile-widget-image'>
                <a href={`/about-host/${user?.user?.id}`}>
                  <img
                    src={
                      user?.user.profilePicture
                        ? user?.user.profilePicture
                        : DUMMYAVATAR
                    }
                    alt=''
                  />
                </a>
              </div>
              <div className='profile-widget-header-title'>
                <h4 style={{ margin: 0 }}>
                  <a
                    href={`/about-host/${user?.user.id}`}
                    style={{
                      color: "#fff",
                      fontSize: "20px",
                    }}
                  >
                    {user?.user.firstName} {user?.user.lastName}
                  </a>
                </h4>
                <div className='clearfix'></div>
                <div className='property_detail_rating pwh_counter'>
                  <h5
                    style={{
                      color: "#fff",
                      fontSize: "15px",
                      lineHeight: 1.8,
                      margin: "5px 0px",
                      float: "left",
                    }}
                  >
                    Host
                  </h5>
                  <div
                    className='listing-rating'
                    style={{ top: -2, float: "right", width: "200px" }}
                  >
                    <Ratings rating={totalRatingHost ? totalRatingHost : 0} />
                    <span
                      style={{
                        float: "left",
                        marginTop: "10px",
                        color: "white",
                        marginLeft: "8px",
                      }}
                    >
                      {" "}
                      ({totalLength ? totalLength : 0})
                    </span>
                  </div>
                </div>
                <div className='clearfix'></div>
                <div
                  className='pwh_counter'
                  style={{
                    color: "#fff",
                    fontSize: "15px",
                  }}
                >
                  Member Since:{" "}
                  {moment(user?.user.createdOn).format("DD MMM YYYY")}
                </div>
              </div>
            </div>
          </div>
          <div className='profile-widget-content fl-wrap'>
            <div className='contats-list fl-wrap' style={{ fontSize: 16 }}>
              <Markup
                content={user?.user.description}
                className='content-markup'
              />
            </div>
          </div>
        </div>
      </div>
    </ProfileStyled>
  );
};

const ProfileStyled = styled.div`
  .profile-widget-header {
    padding: 0;
    padding-bottom: 45px;
  }
  .profile-widget-card {
    width: 100%;
    background: none;
  }
`;

const Jumbotron = ({ propertyDetail, guestReview, propertyViews, gst }) => {
  const [displayResponsive, setDisplayResponsive] = useState(false);
  const [position, setPosition] = useState("center");
  const [displayPosition, setDisplayPosition] = useState(false);

  const [activeIndex1, setActiveIndex1] = useState(1);
  const [activeIndex2, setActiveIndex2] = useState(0);

  const dialogFuncMap = {
    displayPosition: setDisplayPosition,
    displayResponsive: setDisplayResponsive,
  };

  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);

    if (position) {
      setPosition(position);
    }
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };
  let total = 0;
  let totalLength = guestReview?.items?.length;
  for (let i = 0; i < guestReview?.items?.length; i++) {
    total += guestReview?.items[i].over_all;
  }
  let totalRating = total / totalLength;

  return (
    <section
      className='hidden-section   single-hero-section'
      data-scrollax-parent='true'
      id='sec1'
    >
      <div
        className='bg-wrap bg-parallax-wrap-gradien'
        style={{ height: "70vh" }}
      >
        <img
          className='bg par-elem'
          src={propertyDetail?.thumbnailUrl}
          data-scrollax="properties: { translateY: '30%' }"
          alt='property_thumbnail'
          style={{ opacity: 0.4 }}
        />
      </div>
      <div className='container'>
        <div className='list-single-header-item no-bg-list_sh fl-wrap'>
          <div className='row'>
            <div className='col-md-12'>
              <h1>
                {propertyDetail.title}
                <span
                  className='verified-badge tolt'
                  data-microtip-position='bottom'
                  data-tooltip='Verified'
                >
                  <CheckCircleOutlineIcon style={{ fontSize: 30 }} />
                </span>
                {propertyDetail?.instantBooking === true && (
                  <span
                    className='verified-badge tolt'
                    data-microtip-position='bottom'
                    data-tooltip='Instant Booking'
                  >
                    <ElectricBoltIcon
                      style={{ fontSize: 30, color: "#0095FF" }}
                    />
                  </span>
                )}
              </h1>

              <div className='property_detail_header geodir-category-location fl-wrap'>
                <a
                  style={{
                    textDecoration: "none",
                    fontSize: "16px",
                    marginTop: "-3px",
                  }}
                  href='#'
                >
                  <i className='fas fa-map-marker-alt'></i>{" "}
                  {propertyDetail?.address?.split(",")[1] +
                    ", " +
                    propertyDetail?.address?.split(",")[2]}
                </a>
                <div className='listing-rating' style={{ top: -2 }}>
                  <Ratings
                    rating={
                      propertyDetail?.average_rating
                        ? propertyDetail?.average_rating?.averageRating
                        : 0
                    }
                    style={{ float: "left" }}
                  />
                  <span
                    style={{
                      fontSize: "16px",
                      color: "white",
                      float: "left",
                      width: "150px",
                    }}
                  >
                    (
                    {propertyDetail?.average_rating
                      ? parseInt(
                        propertyDetail?.average_rating?.averageRating
                      )?.toFixed(1)
                      : 0}
                    ) (
                    {propertyDetail?.average_rating
                      ? propertyDetail?.average_rating?.reviewCount
                      : 0}{" "}
                    Reviews)
                  </span>
                </div>
              </div>

              <div className='property_detail_header geodir-category-location fl-wrap'>
                <a
                  style={{ textDecoration: "none", fontSize: "16px" }}
                  href='#'
                >
                  <i className='fas fa-id-badge'></i> Property ID #{" "}
                  {propertyDetail?.code}
                </a>
              </div>

              <div className='share-holder hid-share'>
                <Button
                  label='Share'
                  icon='pi pi-external-link'
                  onClick={() => onClick("displayResponsive")}
                  style={{ width: "10rem", height: "3rem", fontSize: "15px" }}
                />
                <Dialog
                  header='Share'
                  visible={displayResponsive}
                  onHide={() => onHide("displayResponsive")}
                  breakpoints={{ "960px": "75vw" }}
                  style={{ width: "50vw", fontSize: "15px" }}
                >
                  <p>
                    <TabView>
                      <TabPanel header='Facebook' style={{ fontSize: "15px" }}>
                        <div style={{ background: "transparent" }}>
                          <FacebookShareButton url={window.location.href}>
                            <div
                              style={{
                                margin: "0 16px",
                              }}
                            >
                              Share this property on Facebook:{" "}
                              <p
                                style={{
                                  background: "#585858",
                                  display: "inline",
                                  padding: "4px",
                                  borderRadius: "6px",
                                }}
                              >
                                {window.location.href}
                              </p>
                            </div>
                          </FacebookShareButton>
                        </div>
                      </TabPanel>
                      <TabPanel header='Whatsapp' style={{ fontSize: "15px" }}>
                        <div style={{ background: "transparent" }}>
                          <WhatsappShareButton url={window.location.href}>
                            <div
                              style={{
                                margin: "0 16px",
                              }}
                            >
                              Share this property on Whatsapp:{" "}
                              <p
                                style={{
                                  background: "#585858",
                                  display: "inline",
                                  padding: "4px",
                                  borderRadius: "6px",
                                }}
                              >
                                {window.location.href}
                              </p>
                            </div>
                          </WhatsappShareButton>
                        </div>
                      </TabPanel>
                      <TabPanel header='Twitter' style={{ fontSize: "15px" }}>
                        <div style={{ background: "transparent" }}>
                          <TwitterShareButton url={window.location.href}>
                            <div
                              style={{
                                margin: "0 16px",
                              }}
                            >
                              Share this property on Twitter:{" "}
                              <p
                                style={{
                                  background: "#585858",
                                  display: "inline",
                                  padding: "4px",
                                  borderRadius: "6px",
                                }}
                              >
                                {window.location.href}
                              </p>
                            </div>
                          </TwitterShareButton>
                        </div>
                      </TabPanel>
                    </TabView>
                  </p>
                </Dialog>

                <div className='share-container isShare'></div>
              </div>
            </div>
          </div>

          <div className='list-single-header-footer fl-wrap'>
            <div
              className='list-single-header-price'
              data-propertyprise='50500'
              style={{ fontSize: 22 }}
            >
              <strong>Per Night Price:</strong>
              {process.env.REACT_APP_CURRENCY}{" "}
              {propertyDetail.perNightPrice &&
                propertyDetail?.perNightPrice?.toLocaleString("en-US")}
            </div>
            <div
              className='list-single-header-price'
              data-propertyprise='50500'
              style={{ fontSize: 22, paddingLeft: "10px" }}
            >
              <strong>Total Price:</strong>
              {process.env.REACT_APP_CURRENCY}{" "}
              {propertyDetail?.perNightPrice +
                parseInt(propertyDetail?.propertyPricePolicy?.cleaningFee) &&
                (
                  propertyDetail?.perNightPrice +
                  parseInt(propertyDetail?.propertyPricePolicy?.cleaningFee)
                )?.toLocaleString("en-US")}
              <Tooltip target='.custom-tooltip' position='top'>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <span>- It does not include GST</span>
                  <span>- It does not include Service Fee</span>
                  <span>- It does not include any other Government Fee</span>
                </div>
              </Tooltip>
              <small
                style={{ color: "white", fontSize: 15, marginLeft: "6px" }}
              >
                (Including cleaning fee{" "}
                <i
                  style={{ color: "#0095ff" }}
                  className='fas fa-circle-info custom-tooltip'
                ></i>{" "}
                )
              </small>
            </div>
            <div className='list-single-stats'>
              <ul className='no-list-style'>
                <li>
                  <span className='viewed-counter' style={{ fontSize: 15 }}>
                    <i className='fas fa-eye'></i> Viewed -{" "}
                    {propertyViews ? propertyViews : 0}{" "}
                  </span>
                </li>
                <li>
                  <span className='bookmark-counter' style={{ fontSize: 15 }}>
                    <i className='fas fa-heart'></i> Bookmark -{" "}
                    {propertyDetail?.propertyLike?.length > 0
                      ? propertyDetail?.propertyLike
                      : 0}{" "}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
const ImageGallery = ({ propertyDetail }) => {
  return (
    <div className='image-box-grid'>
      <ImageList sx={{ width: 700 }} cols={4} rowHeight={164}>
        {propertyDetail?.propertyImages?.map((file) => (
          <ImageListItem key={file?.locationUrl}>
            <img
              src={`${file?.locationUrl}?w=164&h=164&fit=crop&auto=format`}
              srcSet={`${file?.locationUrl}?w=164&h=164&fit=crop&auto=format&dpr=22x`}
              // alt={item.title}
              loading='lazy'
              alt='alt'
            />
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  );
};
const HostDetails = ({ propertyDetail, guestReview }) => {
  let host = propertyDetail?.user;

  return (
    <div
      className='list-single-main-item fl-wrap'
      style={{ backgroundColor: "#1b182b" }}
    >
      <div className='list-single-main-item-title'>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          <div
            className='show-reg-form dashboard-submenu-open'
            style={{
              borderLeft: "none",
              alignSelf: "left",
            }}
          >
            <a href={`/about-host/${host?.id}`}>
              <img
                src={host?.profilePicture ? host?.profilePicture : DUMMYAVATAR}
                width='50'
                height='50'
                alt='alt'
              />
            </a>
          </div>
          <Typography
            sx={{
              fontSize: "20px",
              color: "#878c9f",
              fontFamily: "Jost",
              margin: "0px 15px",
            }}
          >
            Hosted By{" "}
            <a href={`/about-host/${host?.id}`}>
              <span style={{ color: "#fff" }}>
                {host?.firstName} {host?.lastName}
              </span>
            </a>
            <Typography
              sx={{
                fontSize: "14px",
                color: "#878c9f",
                fontFamily: "Jost",
              }}
            >
              Joined On {""}
              <span style={{ color: "#fff" }}>
                {moment(host?.createdOn).format("DD MMM YYYY")}
              </span>
            </Typography>
          </Typography>
          {/* <Box sx={{ margin: '0px 10%' }}>
            <Link to={`/contact-host/${propertyDetail?.id}`}>
              <Buttons title="Contact Host" isLoading={false} />
            </Link>
          </Box> */}
        </Box>

        <Box>
          <Typography sx={{ fontSize: "16px", margin: "14px 0" }}>
            {host?.description ? (
              <Markup content={host?.description} />
            ) : (
              "Host shared no details"
            )}
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              fontSize: "14px",
              color: "#878c9f",
              fontFamily: "Jost",
              display: "flex",
              alignItems: "center",
            }}
          >
            {/* <AiFillStar
              style={{
                color: '#ff2400',
                fontSize: '14px',
                marginRight: '4px',
              }}
            /> */}
            {/* <span style={{ color: '#fff' }}>9</span> &nbsp; Reviews */}
          </Typography>
        </Box>
      </div>
      <PropertyAllCriteria
        guestReview={guestReview}
        id={propertyDetail?.id}
        reviewCount={propertyDetail?.average_rating?.reviewCount}
      />
    </div>
  );
};

const Info = ({ propertyDetail, propertyDetailCommercialUpdated, gst }) => {
  return (
    <>
      <div className='list-single-facts fl-wrap'>
        <div className='inline-facts-wrap'>
          <div className='inline-facts'>
            <i className='fal fa-home'></i>
            <h6 style={{ fontSize: "16px" }}>Type</h6>
            <span style={{ fontSize: "14px" }}>
              {propertyDetail?.area ? propertyDetail?.area?.title : <>&nbsp;</>}
            </span>
          </div>
        </div>
        <div className='inline-facts-wrap'>
          <div className='inline-facts'>
            <i className='fal fa-users'></i>
            <h6 style={{ fontSize: "16px" }}> Accomodation</h6>
            <span style={{ fontSize: "14px" }}>
              {propertyDetail?.propertyCapacity?.noOfAdults +
                propertyDetail?.propertyCapacity?.noOfChildren}{" "}
              Guest
            </span>
          </div>
        </div>
        <div className='inline-facts-wrap'>
          <div className='inline-facts'>
            <i className='fal fa-bed'></i>
            <h6 style={{ fontSize: "16px" }}>Bedrooms</h6>
            <span style={{ fontSize: "14px" }}>
              {propertyDetail?.propertyCapacity?.noOfBedrooms} Bedrooms /{" "}
              {propertyDetail?.propertyCapacity?.noOfBeds} Beds
            </span>
          </div>
        </div>
        <div className='inline-facts-wrap'>
          <div className='inline-facts'>
            <i className='fal fa-bath'></i>
            <h6 style={{ fontSize: "16px" }}>Bathrooms</h6>
            <span style={{ fontSize: "14px" }}>
              {propertyDetail?.propertyCapacity?.noOfBathrooms}
            </span>
          </div>
        </div>
      </div>
      <div className='list-single-main-container fl-wrap' id='sec3'>
        <div className='list-single-main-item fl-wrap'>
          <div className='list-single-main-item-title'>
            <h3>About This Listing</h3>
            <ShowMoreText
              lines={5}
              more='Show more'
              less='Show less'
              className='content-css'
              anchorClass='show-more-less-clickable'
              expanded={false}
              truncatedEndingComponent={"... "}
            >
              <Markup content={propertyDetail?.description} />
            </ShowMoreText>
            <p style={{ color: "white", marginTop: "15px" }}>
              Every booking includes free protection from Host cancellations,
              listing inaccuracies, and other issues like trouble checking in.
            </p>
            <AboutModal data={title} summary={Detail} />
          </div>
          <div
            className='list-single-main-item_content fl-wrap'
            style={{ color: "#fff", textAlign: "start", fontSize: "16px" }}
          ></div>
        </div>
        {propertyDetail?.propertyType === "Commercial" && (
          <CommercialRoomsSection
            propertyDetailCommercialUpdated={propertyDetailCommercialUpdated}
            gst={gst}
          />
        )}
        <div className='list-single-main-item fl-wrap'>
          <div className='list-single-main-item-title'>
            <h3>Details</h3>
          </div>
          <div className='list-single-main-item_content fl-wrap'>
            <div className='details-list'>
              <ul>
                <li style={{ fontSize: "14px" }}>
                  <span style={{ color: "#0095FF", fontSize: "16px" }}>
                    Property ID:
                  </span>
                  {propertyDetail?.code}
                </li>
                <li style={{ fontSize: "14px" }}>
                  <span style={{ color: "#0095FF", fontSize: "16px" }}>
                    Space:
                  </span>
                  {propertyDetail?.space?.title}
                </li>
                <li style={{ fontSize: "14px" }}>
                  <span style={{ color: "#0095FF", fontSize: "16px" }}>
                    Space Type:
                  </span>
                  {propertyDetail?.spaceType?.title}
                </li>
                <li style={{ fontSize: "14px" }}>
                  <span style={{ color: "#0095FF", fontSize: "16px" }}>
                    Bedrooms:
                  </span>
                  {propertyDetail?.propertyCapacity?.noOfBedrooms}
                </li>
                <li style={{ fontSize: "14px" }}>
                  <span style={{ color: "#0095FF", fontSize: "16px" }}>
                    Bathrooms:
                  </span>
                  {propertyDetail?.propertyCapacity?.noOfBathrooms}
                </li>
                <li style={{ fontSize: "14px" }}>
                  <span style={{ color: "#0095FF", fontSize: "16px" }}>
                    Beds:
                  </span>
                  {propertyDetail?.propertyCapacity?.noOfBeds}
                </li>
                <li style={{ fontSize: "14px" }}>
                  <span style={{ color: "#0095FF", fontSize: "16px" }}>
                    Private Bathroom:
                  </span>
                  {propertyDetail?.propertyCapacity?.isPrivateBathroom
                    ? "Yes"
                    : "No"}
                </li>
                <li style={{ fontSize: "14px" }}>
                  <span style={{ color: "#0095FF", fontSize: "16px" }}>
                    Price:
                  </span>{" "}
                  {process.env.REACT_APP_CURRENCY}{" "}
                  {propertyDetail?.perNightPrice?.toLocaleString("en-US")}/night
                </li>
                <li style={{ fontSize: "14px" }}>
                  <span style={{ color: "#0095FF", fontSize: "16px" }}>
                    Type:
                  </span>
                  {propertyDetail?.space?.title}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const CommercialRoomsSection = ({ propertyDetailCommercialUpdated, gst }) => {
  return (
    <div
      className='list-single-main-item fl-wrap'
      id='sec5'
      style={{ backgroundColor: "#1b182b" }}
    >
      <div className='list-single-main-item-title'>
        <h3>Rooms</h3>
      </div>
      {propertyDetailCommercialUpdated?.rooms?.map((room) => {
        return <CommercialRoomCard room={room} gst={gst} />;
      })}
    </div>
  );
};
const Video = ({ link }) => {
  return (
    <div
      className='list-single-main-item fl-wrap'
      id='sec5'
      style={{ backgroundColor: "#1b182b" }}
    >
      <div className='list-single-main-item-title'>
        <h3>Video</h3>
      </div>
      <div className='list-single-main-item_content fl-wrap'>
        <div className='video-box fl-wrap'>
          <YoutubeVideo link={link} />
        </div>
      </div>
    </div>
  );
};

const Features = ({
  propertyDetail,
  propertyHouseRules,
  propertyExtraDetails,
}) => {
  const dialogRef = useRef();
  const fcdialogRef = useRef();
  const nadialogRef = useRef();
  const accssdialogRef = useRef();
  const sddialogRef = useRef();
  const secfialogRef = useRef();
  const safialogRef = useRef();
  const houseRuleRef = useRef();

  const {
    amenityGroups,
    accessibilityGroups,
    facilityGroups,
    nearbyActivityGroups,
    safetyConsiderationGroups,
    safetyFeatureGroups,
    securityFeatureGroups,
  } = propertyExtraDetails;

  const [facilityAvailable, setFacilityAvailable] = React.useState(false);
  const [houseRulesAvailable, setHouseRulesAvailable] = React.useState(false);
  const [securityFeaturesAvailable, setSecurityFeaturesAvailable] =
    React.useState(false);
  const [safetyFeaturesAvailable, setSafetyFeaturesAvailable] =
    React.useState(false);
  const [safetyConsiderationAvailable, setSafetyConsiderationAvailable] =
    React.useState(false);
  const [accessibilitesAvailable, setAccessibilitesAvailable] =
    React.useState(false);
  const [nearbyActivityAvailable, setNearbyActivityAvailable] =
    React.useState(false);

  React.useEffect(() => {
    //facility
    facilityGroups?.map((facilities) => {
      return (
        <li style={{ width: "100%" }}>
          <div>
            {facilities?.facilities?.slice(0, 10).forEach((fc) => {
              if (fc?.check_status) {
                setFacilityAvailable(true);
              }
            })}
          </div>
        </li>
      );
    });

    //nearby activity
    nearbyActivityGroups?.map((data) => {
      return (
        <li style={{ width: "100%" }}>
          <div>
            {data?.nearbyActivity?.slice(0, 10).forEach((fc) => {
              if (fc?.check_status) {
                setNearbyActivityAvailable(true);
              }
            })}
          </div>
        </li>
      );
    });

    //accessibility
    accessibilityGroups?.map((data) => {
      return (
        <li style={{ width: "100%" }}>
          <div>
            {data?.accessibility?.slice(0, 10).forEach((fc) => {
              if (fc?.check_status) {
                setAccessibilitesAvailable(true);
              }
            })}
          </div>
        </li>
      );
    });

    //safety consideration
    safetyConsiderationGroups?.map((data) => {
      return (
        <li style={{ width: "100%" }}>
          <div>
            {data?.safetyConsideration?.slice(0, 10).forEach((fc) => {
              if (fc?.check_status) {
                setSafetyConsiderationAvailable(true);
              }
            })}
          </div>
        </li>
      );
    });

    //safety features
    safetyFeatureGroups?.map((data) => {
      return (
        <li style={{ width: "100%" }}>
          <div>
            {data?.safetyFeature?.slice(0, 10).forEach((fc) => {
              if (fc?.check_status) {
                setSafetyFeaturesAvailable(true);
              }
            })}
          </div>
        </li>
      );
    });
    //security features
    securityFeatureGroups?.map((data) => {
      return (
        <li style={{ width: "100%" }}>
          <div>
            {data?.securityFeature?.slice(0, 10).forEach((fc) => {
              if (fc?.check_status) {
                return setSecurityFeaturesAvailable(true);
              }
            })}
          </div>
        </li>
      );
    });
    //house rule
    propertyHouseRules?.map((data) => {
      return (
        <li style={{ width: "100%" }}>
          <div>
            {data?.houseRules?.slice(0, 10).forEach((fc) => {
              if (fc?.check_status) {
                return setHouseRulesAvailable(true);
              }
            })}
          </div>
        </li>
      );
    });
  });

  return (
    <div
      className='list-single-main-item fl-wrap'
      style={{ backgroundColor: "#1b182b" }}
    >
      <div className='list-single-main-item-title'>
        <h3>Amenities</h3>
      </div>
      <div className='list-single-main-item_content fl-wrap'>
        <div className='listing-features'>
          <ul>
            {amenityGroups?.map((amenity) => (
              <li>
                <a>
                  <i className={amenity?.code} style={{ fontSize: "20px" }}></i>
                  <span style={{ fontSize: "20px" }}> {amenity.title}</span>
                </a>
                <ul style={{ marginLeft: "15px", marginTop: "8px" }}>
                  {amenity?.amenities?.map((ame) => (
                    <li style={{ width: "100%" }}>
                      <a>
                        {/* <i className={amenity?.amenity?.icon}></i> */}
                        <img
                          class='amenity-icons'
                          src={ame?.icon}
                          alt=''
                        />{" "}
                        {ame.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
            {/* {propertyDetail?.propertyAmenities?.slice(0, 10).map((amenity) => {
              return (
                <li>
                  <a>
                    <i className={amenity?.amenity?.icon}></i>
                    <img
                      class="amenity-icons"
                      src={amenity?.amenity?.icon}
                      alt=""
                    />{" "}
                    {amenity?.amenity?.title}
                  </a>
                </li>
              );
            })} */}
          </ul>
          <Buttons
            style={{ height: 50, margin: "20px 0px" }}
            title={`Show all ${amenityGroups?.length} amenities`}
            onSubmit={() => {
              dialogRef.current.isVisible(amenityGroups);
            }}
            isLoading={false}
          />
        </div>
      </div>
      <PropertyAmentiesDialog ref={dialogRef} />
      {facilityAvailable && (
        <>
          <div
            className='list-single-main-item-title'
            style={{ marginTop: "15px" }}
          >
            <h3>Facilities</h3>
          </div>
          <div className='list-single-main-item_content fl-wrap'>
            <div className='listing-features'>
              <ul>
                {facilityGroups?.map((facilities) => {
                  return (
                    <li style={{ width: "100%" }}>
                      <div>
                        {facilities?.facilities?.slice(0, 10).map((fc) => {
                          if (fc?.check_status) {
                            return (
                              <li>
                                <i className={fc?.icon}></i>
                                <a>{fc?.title}</a>
                              </li>
                            );
                          }
                        })}
                      </div>
                    </li>
                  );
                })}
              </ul>
              <Buttons
                style={{ height: 50, margin: "20px 0px" }}
                title={`Show all facillities`}
                onSubmit={() => {
                  fcdialogRef.current.isVisible(facilityGroups);
                }}
                isLoading={false}
              />
            </div>
          </div>
          <PropertyFacilitiessDialog ref={fcdialogRef} />
        </>
      )}

      {nearbyActivityAvailable && (
        <>
          <div
            className='list-single-main-item-title'
            style={{ marginTop: "15px" }}
          >
            <h3>Nearby Activities</h3>
          </div>
          <div className='list-single-main-item_content fl-wrap'>
            <div className='listing-features'>
              <ul>
                {nearbyActivityGroups?.map((data) => {
                  return (
                    <li style={{ width: "100%" }}>
                      <div>
                        {data?.nearbyActivity?.slice(0, 10).map((fc) => {
                          if (fc?.check_status) {
                            return (
                              <li>
                                <i className={fc?.icon}></i>
                                <a>{fc?.title}</a>
                              </li>
                            );
                          }
                        })}
                      </div>
                    </li>
                  );
                })}
              </ul>
              <Buttons
                style={{ height: 50, margin: "20px 0px" }}
                title={`Show all nearby activities`}
                onSubmit={() => {
                  nadialogRef.current.isVisible(nearbyActivityGroups);
                }}
                isLoading={false}
              />
            </div>
          </div>
          <PropertyNearbyActivityDialog ref={nadialogRef} />
        </>
      )}

      {accessibilitesAvailable && (
        <>
          <div
            className='list-single-main-item-title'
            style={{ marginTop: "15px" }}
          >
            <h3>Accessibilities</h3>
          </div>
          <div className='list-single-main-item_content fl-wrap'>
            <div className='listing-features'>
              <ul>
                {accessibilityGroups?.map((data) => {
                  return (
                    <li style={{ width: "100%" }}>
                      <div>
                        {data?.accessibility?.slice(0, 10).map((fc) => {
                          if (fc?.check_status) {
                            return (
                              <li>
                                <i className={fc?.icon}></i>
                                <a>{fc?.title}</a>
                              </li>
                            );
                          }
                        })}
                      </div>
                    </li>
                  );
                })}
              </ul>
              <Buttons
                style={{ height: 50, margin: "20px 0px" }}
                title={`Show all acessibilties`}
                onSubmit={() => {
                  accssdialogRef.current.isVisible(accessibilityGroups);
                }}
                isLoading={false}
              />
            </div>
          </div>
          <PropertyAccessibilitiesDialog ref={accssdialogRef} />
        </>
      )}
      {safetyConsiderationAvailable && (
        <>
          <div
            className='list-single-main-item-title'
            style={{ marginTop: "15px" }}
          >
            <h3>Safety Consideration</h3>
          </div>
          <div className='list-single-main-item_content fl-wrap'>
            <div className='listing-features'>
              <ul>
                {safetyConsiderationGroups?.map((data) => {
                  return (
                    <li style={{ width: "100%" }}>
                      <div>
                        {data?.safetyConsideration?.slice(0, 10).map((fc) => {
                          if (fc?.check_status) {
                            return (
                              <li>
                                <i className={fc?.icon}></i>
                                <a>{fc?.title}</a>
                              </li>
                            );
                          }
                        })}
                      </div>
                    </li>
                  );
                })}
              </ul>
              <Buttons
                style={{ height: 50, margin: "20px 0px" }}
                title={`Show all Safety Considerations`}
                onSubmit={() => {
                  sddialogRef.current.isVisible(safetyConsiderationGroups);
                }}
                isLoading={false}
              />
            </div>
          </div>
          <PropertySafetyConsiderationDialog ref={sddialogRef} />
        </>
      )}
      {safetyFeaturesAvailable && (
        <>
          <div
            className='list-single-main-item-title'
            style={{ marginTop: "15px" }}
          >
            <h3>Safety Features</h3>
          </div>
          <div className='list-single-main-item_content fl-wrap'>
            <div className='listing-features'>
              <ul>
                {safetyFeatureGroups?.map((data) => {
                  return (
                    <li style={{ width: "100%" }}>
                      <div>
                        {data?.safetyFeature?.slice(0, 10).map((fc) => {
                          if (fc?.check_status) {
                            return (
                              <li>
                                <i className={fc?.icon}></i>
                                <a>{fc?.title}</a>
                              </li>
                            );
                          }
                        })}
                      </div>
                    </li>
                  );
                })}
              </ul>
              <Buttons
                style={{ height: 50, margin: "20px 0px" }}
                title={`Show all Safety Features`}
                onSubmit={() => {
                  safialogRef.current.isVisible(safetyFeatureGroups);
                }}
                isLoading={false}
              />
            </div>
          </div>
          <PropertySafetyFeaturesDialog ref={safialogRef} />
        </>
      )}
      {securityFeaturesAvailable && (
        <>
          <div
            className='list-single-main-item-title'
            style={{ marginTop: "15px" }}
          >
            <h3>Security Features</h3>
          </div>
          <div className='list-single-main-item_content fl-wrap'>
            <div className='listing-features'>
              <ul>
                {securityFeatureGroups?.map((data) => {
                  return (
                    <li style={{ width: "100%" }}>
                      <div>
                        {data?.securityFeature?.slice(0, 10).map((fc) => {
                          if (fc?.check_status) {
                            return (
                              <li>
                                <i className={fc?.icon}></i>
                                <a>{fc?.title}</a>
                              </li>
                            );
                          }
                        })}
                      </div>
                    </li>
                  );
                })}
              </ul>
              <Buttons
                style={{ height: 50, margin: "20px 0px" }}
                title={`Show all Security Features`}
                onSubmit={() => {
                  secfialogRef.current.isVisible(securityFeatureGroups);
                }}
                isLoading={false}
              />
            </div>
          </div>
          <PropertySecurityFeaturesDialog ref={secfialogRef} />
        </>
      )}
      {houseRulesAvailable && (
        <>
          <div
            className='list-single-main-item-title'
            style={{ marginTop: "15px" }}
          >
            <h3>House Rules</h3>
          </div>
          <div className='list-single-main-item_content fl-wrap'>
            <div className='listing-features'>
              <ul>
                {propertyHouseRules?.map((data) => {
                  return (
                    <li style={{ width: "100%" }}>
                      <div>
                        {data?.houseRules?.slice(0, 10).map((fc) => {
                          if (fc?.check_status) {
                            return (
                              <li>
                                <i className={fc?.icon}></i>
                                <a>{fc?.title}</a>
                              </li>
                            );
                          }
                        })}
                      </div>
                    </li>
                  );
                })}
              </ul>
              <Buttons
                style={{ height: 50, margin: "20px 0px" }}
                title={`Show all House Rules`}
                onSubmit={() => {
                  houseRuleRef.current.isVisible(propertyHouseRules);
                }}
                isLoading={false}
              />
            </div>
          </div>
          <PropertyHouseRulesDialog ref={houseRuleRef} />
        </>
      )}
    </div>
  );
};

const PropertyMap = ({ lat, lng, address }) => {
  let mapCenter = {
    lat: lat,
    lng: lng,
    address: address,
  };
  return (
    <div
      id='sec6'
      className='list-single-main-item fl-wrap'
      style={{ backgroundColor: "#1b182b" }}
    >
      <div className='list-single-main-item-title'>
        <h3>Location Map</h3>
      </div>
      <div className='map-container mapC_vis mapC_vis2'>
        <div style={{ height: 400 }} className='drag-map'>
          <PropertyDetailGoogleMap mapValues={mapCenter} />
        </div>
      </div>
    </div>
  );
};

const Reviews = ({ propertyDetail }) => {
  const [guestReview, setGuestReview] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [customFirstReview, setCustomFirstReview2] = useState(0);
  const [customRowsReview, setCustomRowsReview2] = useState(12);
  const getPropertyReviews = async (page) => {
    const res = await getPropertyAllReviewsApi(propertyDetail?.id, page);
    setGuestReview(res.data.data);
    setTotalRecords(res?.data?.data?.meta?.totalItems);
  };
  React.useEffect(() => {
    if (propertyDetail?.id) {
      getPropertyReviews(0);
    }
  }, [propertyDetail?.id]);

  const handlePageClick = (event) => {
    console.log(event.page);
    setCustomFirstReview2(event.first);
    setCustomRowsReview2(event.rows);
    getPropertyReviews(event.page + 1);
  };
  const dropdownOptions = [
    { label: 12, value: 12 },
    { label: 24, value: 24 },
    { label: 36, value: 36 },
    { label: 48, value: 48 },
  ];
  return (
    <div
      className='list-single-main-item fl-wrap'
      id='sec7'
      style={{ backgroundColor: "#1b182b" }}
    >
      {guestReview?.items?.length > 0 ? (
        <>
          <div className='list-single-main-item-title'>
            <h3>
              Reviews <span>{guestReview?.items?.length}</span>
            </h3>
          </div>
          <div className='list-single-main-item_content fl-wrap'>
            <div className='reviews-comments-wrap fl-wrap'>
              <div className='review-total'>
                <span className='review-number blue-bg'>
                  {propertyDetail?.average_rating?.averageRating
                    ? parseInt(
                      propertyDetail?.average_rating?.averageRating
                    ).toFixed(1)
                    : 0}
                </span>
                <div
                  className='listing-rating'
                  data-starrating2='{totalRating}'
                >
                  <Rating
                    name='half-rating-read'
                    value={
                      propertyDetail?.average_rating?.averageRating
                        ? propertyDetail?.average_rating?.averageRating
                        : 0
                    }
                    precision={0.5}
                    readOnly
                  />
                </div>
              </div>
              {guestReview?.items?.map((item, index) => (
                <SingleReview key={index} item={item} />
              ))}
            </div>
            <Pagination
              totalRecords={totalRecords}
              onPageChange={handlePageClick}
              dropdownOptions={dropdownOptions}
              customFirst={customFirstReview}
              customRows={customRowsReview}
            />
          </div>
        </>
      ) : (
        <Typography sx={{ textAlign: "center" }}>
          No Reviews are Available!
        </Typography>
      )}
    </div>
  );
};

const ThingsToKnow = ({ propertyDetail, cancellationPolicy }) => {
  console.log("details:", propertyDetail)
  return (
    <div
      className='list-single-main-item fl-wrap'
      id='sec8'
      style={{ backgroundColor: "#1b182b" }}
    >
      <div className='list-single-main-item-title'>
        <h3>Things to Know</h3>
      </div>
      <Grid container>
        <Grid item xs={12} md={6}>
          <div className='list-single-main-item_content fl-wrap thingsToKnow'>
            <h6>House Rules</h6>
            <div className='listing-features fl-wrap'>
              <ul>
                <li>
                  <a>
                    <i class='fas fa-clock'></i> Check-in:{" "}
                    {propertyDetail?.propertyPolicy?.checkInWindow}
                  </a>
                </li>
                <li>
                  <a>
                    <i class='fas fa-clock'></i> Check-out:{" "}
                    {propertyDetail?.propertyPolicy?.checkoutWindow}
                  </a>
                </li>
                <li>
                  <a>
                    <i class='far fa-cat'></i>{" "}
                    {propertyDetail?.propertyCapacity?.noOfPets > 0
                      ? "Pets Are Allowed"
                      : "Pets Are Not Allowed"}
                  </a>
                </li>
                <li>
                  <a>
                    <i class='far fa-baby-carriage'></i>{" "}
                    {propertyDetail?.propertyCapacity?.noOfInfants > 0
                      ? "Infants Are Allowed"
                      : "Infants Are Not Allowed"}
                  </a>
                </li>
                {
                  propertyDetail?.propertyPolicy?.customHouseRules != null  &&
                  <li>
                  <a>
                    <i class='far fa-baby-carriage'></i>{" "}
                    {propertyDetail?.propertyPolicy?.customHouseRules}
                  </a>
                </li>

                }

              </ul>
            </div>
          </div>
        </Grid>
        {cancellationPolicy && (
          <Grid item xs={12} md={6}>
            <div className='list-single-main-item_content fl-wrap thingsToKnow'>
              <h6>Cancellation Policy</h6>
              <div className='listing-features fl-wrap'>
                <ul>
                  <li>
                    <a className='cancellationPolicyTitle'>
                      {cancellationPolicy?.title}
                    </a>
                  </li>
                  <li style={{ width: "100%" }}>
                    <a style={{ fontWeight: "400" }}>
                      - {cancellationPolicy?.description}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default PropertyDetailWithoutLogin;
