import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import Timer from '../../../components/BookingComponents/Timer';
import { Toast } from 'primereact/toast';
import ViewCancel from './ViewCancel';

const CancelRequest = ({ booking }) => {
  const toast = useRef(null);
  const { title, thumbnailUrl, address, status, id } = booking?.property;
  const [hovered, setHovered] = useState(false);
  const [open, setOpen] = React.useState(false);
  // const [close, setClose] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  return (
    <div className='dashboard-listings-item fl-wrap'>
      <Toast ref={toast} position='bottom-right' />
      <div className='dashboard-listings-item_img'>
        <div className='bg-wrap1'>
          <img className='bg' src={thumbnailUrl} />
        </div>
      </div>
      <ViewCancel
        open={open}
        handleClose={handleClose}
        booking={booking}
        scroll={scroll}
        descriptionElementRef={descriptionElementRef}
      />
      <div className='dashboard-listings-item_content'>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <h4>
              <Tooltip title={title}>
                <Link to={`/property-detail/${booking?.id}`}>
                  {title.length > 40 ? (
                    <a
                      onMouseOver={(e) =>
                        (e.target.style.textOverflow = 'ellipsis')
                      }
                    >
                      {title.substring(0, 40)}...
                    </a>
                  ) : (
                    <a
                      onMouseOver={(e) =>
                        (e.target.style.textOverflow = 'ellipsis')
                      }
                    >
                      {title}
                    </a>
                  )}
                </Link>
              </Tooltip>
            </h4>
            <div className='geodir-category-location'>
              <a style={{ textDecoration: 'none' }}>
                <i className='fas fa-map-marker-alt'></i>{' '}
                <span
                  style={{
                    fontSize: 12,
                    color: 'white',
                    lineHeight: 0.8,
                    color: '#bbbbbb',
                  }}
                >
                  {address}
                  {status}
                </span>
              </a>
            </div>
            <div className='clearfix' style={{ paddingBottom: 10 }}></div>
            <div className='property-id'>
              <a style={{ textAlign: 'left', color: 'white', float: 'left' }}>
                Booking ID # {booking.id}
              </a>
            </div>
          </div>
          <div>
            <Timer createdOn={booking.createdOn} />
          </div>
        </div>
        <div
          className='dashboard-listings-item_opt'
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <span className='viewed-counter' style={{ fontSize: 13 }}>
            {/* <i className='fas fa-eye' style={{ paddingRight: 5 }}></i> Viewed -{' '} */}
          </span>
          <ul>
            <li>
              <div
                state={{ booking: booking }}
                style={
                  !hovered
                    ? {
                        padding: '16px 10px',
                        color: '#0095ff',
                        cursor: 'pointer',
                      }
                    : {
                        padding: '16px 10px',
                        color: '#0095ff',
                        cursor: 'pointer',
                        background: '#292e3a',
                      }
                }
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                onClick={handleClickOpen('body')}
              >
                View
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CancelRequest;
