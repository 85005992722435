import React, { useCallback, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import Buttons from '../form/button';
import moment from 'moment';
import { Rating, Typography } from '@mui/material';

import { Toast } from 'primereact/toast';
import ReservationDetail from '../../screens/Website/ReservationDetails/ReservationDetail';
import { updateBookingApi } from '../../services/booking/updateBookingStatus';
import CustomerTimer from './CustomTimer';
import { useNavigate } from 'react-router-dom';
import ReactCanvasConfetti from 'react-canvas-confetti';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const date = new Date().toISOString();

const Booking = ({
  booking,
  getPropertyListings,
  confirm1,
  commissionFee,
  pageType,
}) => {
  const navigate = useNavigate();
  const toast = useRef(null);
  const [hovered, setHovered] = useState(false);
  const [guestReview, setGuestReview] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const [isLoadingAccept, setIsLoadingAccept] = useState(false);
  const [isLoadingDecline, setIsLoadingDecline] = useState(false);

  let total = 0;
  let totalLength = guestReview?.items?.length;
  for (let i = 0; i < guestReview?.items?.length; i++) {
    total += guestReview?.items[i].over_all;
  }
  let totalRating = total / totalLength;

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  const canvasStyles = {
    position: 'fixed',
    pointerEvents: 'none',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 9999,
  };
  const refAnimationInstance = useRef(null);
  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);
  const makeShot = useCallback((particleRatio, opts) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio),
      });
  }, []);

  const fire = () => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55,
    });

    makeShot(0.2, {
      spread: 60,
    });

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  };

  const accept = async (id, status) => {
    status === 'accepted'
      ? setIsLoadingAccept(true)
      : setIsLoadingDecline(true);
    try {
      const response = await updateBookingApi(id, {
        bookingStatus: status,
      });
      if (response.status === 200) {
        // setLoading(false);
        toast.current.show({
          severity: 'info',
          summary: 'Booking Accepted',
          life: 3000,
        });
        status === 'accepted'
          ? setIsLoadingAccept(false)
          : setIsLoadingDecline(false);
        setOpen(false);
        getPropertyListings();
        fire();
        setTimeout(() => {
          navigate('/admin/current-reservations');
        }, 2000);
      } else {
        status === 'accepted'
          ? setIsLoadingAccept(false)
          : setIsLoadingDecline(false);
        setOpen(false);
        toast.current.show({
          severity: 'warn',
          summary: 'Not Accepted',
          detail: 'there seems to be an error right now',
          life: 3000,
        });
      }
    } catch (err) {
      // setLoading(false);
      status === 'accepted'
        ? setIsLoadingAccept(false)
        : setIsLoadingDecline(false);
      toast.current.show({
        severity: 'warn',
        summary: 'Not Accepted',
        detail: 'there seems to be an error right now',
        life: 3000,
      });
    }
  };

  let userHost = JSON.parse(localStorage.getItem('userHost'));
  return (
    <div className='dashboard-listings-item fl-wrap'>
      <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
      <Toast ref={toast} position='bottom-right' />
      <div className='dashboard-listings-item_img'>
        <div className='bg-wrap1'>
          <img className='bg' src={booking?.property.thumbnailUrl} />
        </div>
        {/* <div className="overlay"></div>
        <Link to={`/property-detail/${booking?.id}`}>
          <a
            className="color-bg"
            style={{ textDecoration: "none", color: "white", fontSize: 13 }}
          >
            View
          </a>
        </Link> */}
      </div>
      <div className='dashboard-listings-item_content'>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <h4>
              <Tooltip title={booking?.property.title}>
                <Link to={`/property-detail/${booking?.id}`}>
                  {booking?.property.title.length > 40 ? (
                    <a
                      onMouseOver={(e) =>
                        (e.target.style.textOverflow = 'ellipsis')
                      }
                    >
                      {booking?.property.title.substring(0, 40)}...
                    </a>
                  ) : (
                    <a
                      onMouseOver={(e) =>
                        (e.target.style.textOverflow = 'ellipsis')
                      }
                    >
                      {booking?.property.title}
                    </a>
                  )}
                </Link>
              </Tooltip>
            </h4>
            <div
              className='geodir-category-location'
              style={{ marginBottom: '2%' }}
            >
              <a style={{ textDecoration: 'none' }}>
                <i className='fas fa-map-marker-alt'></i>{' '}
                <span
                  style={{
                    fontSize: '12px',
                    color: '#bbbbbb',
                  }}
                >
                  {booking?.property.address.slice(0, 50) + '...'}
                  <div></div>
                </span>
              </a>
            </div>
            <div style={{ textAlign: 'start' }}>
              <div>
                <a
                  style={{
                    textAlign: 'left',
                    color: 'green',
                    fontWeight: 'bold',
                    fontSize: '16px',
                  }}
                >
                  {booking?.bookingStatus === 'pending' ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {' '}
                      <AccessTimeFilledIcon
                        sx={{
                          fontSize: '18px',
                          color: '#fff',
                          marginRight: '5px',
                        }}
                      />
                      <span>Awaiting your approval!</span>
                    </div>
                  ) : booking?.bookingStatus === 'accepted' ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {' '}
                      <AccessTimeFilledIcon
                        sx={{
                          fontSize: '18px',
                          color: '#fff',
                          marginRight: '5px',
                        }}
                      />
                      <span>Pending guest payment.</span>
                    </div>
                  ) : booking?.bookingStatus === 'confirmed' ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {' '}
                      <CheckCircleIcon
                        sx={{
                          fontSize: '18px',
                          color: '#fff',
                          marginRight: '5px',
                        }}
                      />
                      <span>Booking confirmed.</span>
                    </div>
                  ) : (
                    ''
                  )}
                </a>
              </div>
              <div>
                <a style={{ textAlign: 'start', color: 'white' }}>
                  Property ID # {booking?.property.code}
                </a>
              </div>

              <div>
                <a style={{ textAlign: 'start', color: 'white' }}>
                  Booking ID # {booking?.code}
                </a>
              </div>
              <div>
                <Typography
                  sx={{
                    fontFamily: 'Jost',
                    fontSize: '16px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Rating
                    name='half-rating-read'
                    value={
                      booking?.property?.average_rating
                        ? booking?.property?.average_rating?.averageRating
                        : 0
                    }
                    precision={0.5}
                    readOnly
                    sx={{ marginRight: '4px' }}
                  />
                  (
                  {booking?.property?.average_rating
                    ? parseInt(
                        booking?.property?.average_rating?.averageRating
                      )?.toFixed(1)
                    : 0}
                  )
                  {booking?.property?.average_rating
                    ? ` (${booking?.property?.average_rating?.reviewCount} Reviews)`
                    : ' (0 Reviews)'}
                </Typography>
              </div>

              <div>
                <a style={{ textAlign: 'start', color: 'white' }}>
                  {booking?.startDate && booking?.endDate && (
                    <>
                      From {moment(booking?.startDate).format('DD MMM YYYY')} to{' '}
                      {moment(booking?.endDate).format('DD MMM YYYY')}
                    </>
                  )}
                </a>
              </div>
              <div>
                <a
                  style={{
                    textAlign: 'start',
                    color: 'white',
                    fontSize: '18px',
                  }}
                >
                  {process.env.REACT_APP_CURRENCY}{' '}
                  {booking?.grossAmount?.toLocaleString('en-US')}
                </a>
              </div>
            </div>
          </div>
          <div>
            <CustomerTimer createdOn={booking.createdOn} />
          </div>
        </div>

        <div
          className='dashboard-listings-item_opt'
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          {/* <span className='viewed-counter' style={{ fontSize: 13 }}>
            <i className='fas fa-eye' style={{ paddingRight: 5 }}></i> Viewed -{' '}
            {0}{' '}
          </span> */}

          <div
            style={{
              display: 'flex',
            }}
          >
            {/* <Buttons
              isLoading={false}
              onSubmit={() =>
                updateBookingApi(booking.booking_property.id, 'reserved')
              }
            >
              <i className='pi pi-check '></i>
            </Buttons>
            <Buttons
              isLoading={false}
            />
            <Buttons isLoading={false} title="Accept" />
            <Buttons
              isLoading={false}
              title="Reject"
              style={{
                backgroundColor: 'red',
                marginLeft: '10px',
              }}
              onSubmit={() =>
                updateBookingApi(booking.booking_property.id, 'rejected')
              }
            >
              <i className='pi pi-times'></i>
            </Buttons> */}
            {/* <Buttons
              isLoading={false}
              onSubmit={() => confirm1(booking)}
            >
              {'View Details'}
            </Buttons> */}
          </div>
          <ul>
            <li
              style={{
                padding: '16px 10px',
                color: '#0095ff',
                cursor: 'pointer',
              }}
              isLoading={false}
              onClick={handleClickOpen('body')}
            >
              View
            </li>
            {/* <li>
              <a>
                <i className="far fa-edit"></i>
              </a>
            </li>

            <li>
              <a>
                <i className="far fa-trash-alt"></i>
              </a>
            </li> */}
          </ul>
        </div>
      </div>
      <ReservationDetail
        open={open}
        handleClose={handleClose}
        booking={booking}
        scroll={scroll}
        descriptionElementRef={descriptionElementRef}
        header='Confirmation'
        type='host'
        accept={accept}
        isLoadingAccept={isLoadingAccept}
        isLoadingDecline={isLoadingDecline}
        commissionFee={commissionFee}
        detailType={pageType}
      />
    </div>
  );
};

export default Booking;
