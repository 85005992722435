import React from 'react'
import { Box, Typography } from '@mui/material'
const AddPropertyPopup = () => {
    return (
        <Box sx={{ width: '600px' }}>
            <Typography>Host requirements</Typography>
            <Typography sx={{ fontSize: '14px', textAlign: 'justify' }}>
                To help create comfortable, reliable stays for guests, we ask all Hosts to meet these requirements for each of their listings:Be responsive: Keep your response rate high by replying to inquiries and trip requests within 24 hours Accept trip requests: Make guests feel welcome by accepting requests whenever you’re available Avoid canceling on guests: Take cancellations seriously and try to avoid them—they’re a huge inconvenience Maintain a high overall rating: Guests expect a consistent level of quality, no matter where they book Find out how you’re doing in each of these areas by checking on your Performance, which is tracked against the average for all Hosts on Airbnb. Places that consistently fall below average could be subject to penalties.
            </Typography>
            <Typography>Essential amenities</Typography>
            <Typography sx={{ fontSize: '14px', textAlign: 'justify' }}>We strongly encourage you to provide essential amenities in all your places. These are items that guests consider important for a comfortable stay and include toilet paper, towels, linens, pillows, and hand and body soap.</Typography>
        </Box>
    )
}

export default AddPropertyPopup