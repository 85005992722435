import { API_URL } from "../../config";
import axios from "axios";

export const otpVerificationApi = async (data) => {
  try {
    const response = await axios.post(
      API_URL + "sms/check-verification-code",
      data
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log("Otp Verification Error Response : ", response);
      throw response;
    }
  }
};

export const emailVerification = async (data) => {
  try {
    const response = await axios.post(
      API_URL + "sms/initiate-verification",
      data
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log("Email Error Response : ", response);
      throw response;
    }
  }
};
