import { API_URL } from '../../config'
import axios from 'axios'

export const getBookingGuestAwaitingPaymentApi = async (page = 0, status = 'accepted') => {
  const userData = JSON.parse(localStorage.getItem('userData')) || null
  try {
    const response = await axios.get(
      API_URL +
      `booking/all?guestId=${userData.id}&page=${page}&bookingStatus=${status}`
    )
    return response
  } catch (error) {
    if (error) {
      const { response } = error
      console.log('Bad Response of booking : ', response)
      return response
    }
  }
}
