import * as React from 'react';
import { Box, Tab } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Image from '../../../assets/images/all/small/opportunities-beauty-home.jpeg';
import '../../../screens/Admin-Panel/PropertyInsight/Styles.css';
import Ratings from '../../../components/Ratings';
import Opportunities from '../../../components/PropertyInsight/Opportunities';
import Reviews from '../../../components/PropertyInsight/Reviews';
import Earning from '../../../components/PropertyInsight/Earning';
import Views from '../../../components/PropertyInsight/Views';
import Clean from '../../../components/PropertyInsight/Clean';
import Daimondhost from '../../../components/PropertyInsight/Daimondhost';

export default function LabTabs({ propertyDetail }) {
  const [rating, setRating] = React.useState('1');
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label='lab API tabs example'>
            <Tab sx={{ fontSize: '14px' }} label='Opportunities' value='1' />
            <Tab sx={{ fontSize: '14px' }} label='Reviews' value='2' />
            <Tab sx={{ fontSize: '14px' }} label='Earning' value='3' />
            <Tab sx={{ fontSize: '14px' }} label='Views' value='4' />
            <Tab sx={{ fontSize: '14px' }} label='Cleaing' value='5' />
            <Tab sx={{ fontSize: '14px' }} label='Daimond Host' value='6' />
          </TabList>
        </Box>
        <TabPanel value='1'>
          <Opportunities />
        </TabPanel>
        <TabPanel id='insight-review' value='2'>
          <Reviews propertyDetail={propertyDetail} />
        </TabPanel>
        <TabPanel value='3'>
          <Earning propertyDetail={propertyDetail} />
        </TabPanel>
        <TabPanel value='4'>
          <Views propertyDetail={propertyDetail} />
        </TabPanel>
        <TabPanel value='5'>
          <Clean />
        </TabPanel>
        <TabPanel value='6'>
          <Daimondhost />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
