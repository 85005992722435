import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { validationSchema } from './SignupPageValidationSchema';
import { postRegistrationApi } from '../../../services/Authentication/Registration';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';

import Input from '../../../components/form/input';
import Buttons from '../../../components/form/button';
import BACKGROUND from '../../../assets/images/sign_pic.jpg';
import LOGO from '../../../assets/images/logo.png';
import { Checkbox, FormControlLabel } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import InputPass from '../../../components/form/inputpass';
import { Toast } from 'primereact/toast';
function Copyright(props) {
  return (
    <Typography
      variant='body2'
      color='text.secondary'
      align='center'
      {...props}
    >
      {'© Staywo Inc.USA 2022 . All rights reserved.'}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const Signup = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const history = useNavigate();
  const [checked, setChecked] = useState(false);
  const [eyeSee, setEyeSee] = useState(false);
  const toast = React.useRef(null);
  const formik = useFormik({
    initialValues: {
      username: '',
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      password: '',
      confirmEmail: '',
    },
    validationSchema: () => {
      return validationSchema;
    },
    onSubmit: async (values) => {
      if (checked) {
        if (values.email === values.confirmEmail) {
          setIsLoading(true);
          const response = await postRegistrationApi(values);
          if (response?.status === 200) {
            setIsLoading(false);
            history('/login', { replace: true });
            enqueueSnackbar(
              `Registeration Successfull, We've sent you an email, Please check your email to activate your account.`,
              {
                variant: 'success',
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                },
                autoHideDuration: 15000,
              }
            );
          } else {
            setIsLoading(false);
            toast.current.show({
              severity: 'error',
              summary: 'Cannot create your account.',
              detail: `${response.response.data.message[1].message}`,
              life: 3000,
            });
          }
        } else {
          formik.errors.confirmEmail = 'Emails does not match!';
        }
      } else {
        toast.current.show({
          severity: 'info',
          summary: 'Did you accept our terms & conditions?',
          detail: 'Please accept our terms & conditions to continue!',
          life: 3000,
        });
      }
    },
  });

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <Grid container component='main' sx={{ height: '100vh' }}>
      <Toast ref={toast} position='bottom-right' />

      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={8}
        sx={{
          backgroundImage: `url(${BACKGROUND})`,
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light'
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Grid
        style={{ background: '#1b182b' }}
        item
        xs={12}
        sm={8}
        md={4}
        component={Paper}
        elevation={6}
        square
      >
        <Box
          sx={{
            my: 6,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
          }}
        >
          <Grid item xs={12} display='flex' justifyContent='center'>
            <a href='/' className='logo-holder'>
              <img src={`${LOGO}`} width='242px' height='100px' alt='' />
            </a>
          </Grid>
          <Typography textAlign='center' component='h1' variant='h5'>
            <h4>Signup</h4>
          </Typography>
          <Box component='form' noValidate sx={{ mt: 3 }}>
            {/* <Typography className='signupFormLabel'>Username</Typography> */}
            {/* <Input
              sx={{ mb: 2, fontSize: '14px' }}
              id={'username'}
              name={'username'}
              label={'Username'}
              onChange={formik.handleChange}
              value={formik.values.username}
              defaultValue={formik.values.username}
              error={formik.touched.username && Boolean(formik.errors.username)}
              helperText={formik.touched.username && formik.errors.username}
            /> */}
            <Typography className='signupFormLabel'>First Name</Typography>
            <Input
              sx={{ mb: 2, fontSize: '14px', marginTop: '5px' }}
              id={'firstName'}
              name={'firstName'}
              label={'First Name'}
              onChange={formik.handleChange}
              value={formik.values.firstName}
              defaultValue={formik.values.firstName}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
            />
            <Typography className='signupFormLabel'>Middle Name</Typography>
            <Input
              sx={{ mb: 2, fontSize: '14px', marginTop: '5px' }}
              id={'middleName'}
              name={'middleName'}
              label={'Middle Name'}
              onChange={formik.handleChange}
              value={formik.values.middleName}
              defaultValue={formik.values.middleName}
              error={
                formik.touched.middleName && Boolean(formik.errors.middleName)
              }
              helperText={formik.touched.middleName && formik.errors.middleName}
            />
            <Typography className='signupFormLabel'>Last Name</Typography>
            <Input
              sx={{ mb: 2, fontSize: '14px', marginTop: '5px' }}
              id={'lastName'}
              name={'lastName'}
              label={'Last Name'}
              onChange={formik.handleChange}
              value={formik.values.lastName}
              defaultValue={formik.values.lastName}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
            <Typography className='signupFormLabel'>Email Address</Typography>
            <Input
              sx={{ mb: 2, fontSize: '14px', marginTop: '5px' }}
              id={'email'}
              name={'email'}
              label={'Email Address'}
              onChange={formik.handleChange}
              value={formik.values.email}
              defaultValue={formik.values.email}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <Typography className='signupFormLabel'>
              Confirm Email Address
            </Typography>
            <Input
              sx={{ mb: 2, fontSize: '14px', marginTop: '5px' }}
              id={'confirmEmail'}
              name={'confirmEmail'}
              label={'Confirm Email Address'}
              onChange={formik.handleChange}
              value={formik.values.confirmEmail}
              defaultValue={formik.values.confirmEmail}
              error={
                formik.touched.confirmEmail &&
                Boolean(formik.errors.confirmEmail)
              }
              helperText={
                formik.touched.confirmEmail && formik.errors.confirmEmail
              }
            />
            {/* <Typography className='signupFormLabel'>Mobile Number</Typography>
            <Input
              sx={{ mb: 2, fontSize: '14px', marginTop: '5px' }}
              id={'phoneNumber'}
              name={'phoneNumber'}
              label={'Mobile Number'}
              onChange={formik.handleChange}
              value={formik.values.phoneNumber}
              defaultValue={formik.values.phoneNumber}
              error={
                formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
              }
              helperText={
                formik.touched.phoneNumber && formik.errors.phoneNumber
              }
            /> */}
            <Box>
              <Typography className='signupFormLabel'>Password</Typography>
              <InputPass
                sx={{ mb: 3, fontSize: '14px', position: 'relative' }}
                id={'password'}
                name={'password'}
                label={'Password'}
                onChange={formik.handleChange}
                value={formik.values.password}
                defaultValue={formik.values.password}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '16px',
                margin: '12px 0px',
              }}
            >
              <Checkbox
                checked={checked}
                onChange={(e) => setChecked(e.target.checked)}
              />
              I accept the Staywo{' '}
              <a
                href='/terms-and-conditions'
                target={'_blank'}
                style={{
                  fontSize: '18px',
                  color: '#0095ff',
                  textDecoration: 'underline',
                  marginLeft: '8px',
                }}
              >
                Terms & Conditions.
              </a>{' '}
            </Box>
            <Buttons
              title='Sign Up'
              loadingTitle={'Signing Up'}
              isLoading={isLoading}
              style={{
                height: 55,
                mb: 0,
                mt: 3,
                fontSize: '14px',
                marginTop: '5px',
              }}
              onSubmit={formik.handleSubmit}
            />
            <Grid
              container
              justifyContent={'center'}
              alignItems={'center'}
              style={{ height: 55, mb: 0, mt: 3 }}
            >
              <Grid item>
                <Link
                  href='/login'
                  variant='body2'
                  style={{ fontSize: '14px' }}
                >
                  {'Already have an account? Sign In'}
                </Link>
              </Grid>
            </Grid>

            <Copyright sx={{ mt: 1, fontSize: '14px' }} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Signup;
