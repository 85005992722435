import React, { useState } from 'react'
import { Box, Grid, Typography, Tab, TextField, MenuItem, Paper, TableContainer, Table, TableBody, TableRow, TableCell, TableHead } from '@mui/material'
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Dashboard } from '@mui/icons-material'
import Resource1 from "../../assets/images/all/small/resource1.jpeg";
import Resource2 from "../../assets/images/all/small/resource2.jpeg";
import Resource3 from "../../assets/images/all/small/resource3.jpeg";
import Resource4 from "../../assets/images/all/small/resource4.jpeg";
import Resource5 from "../../assets/images/all/small/resource5.jpeg";
import Resource6 from "../../assets/images/all/small/resource6.jpeg";
const Daimondhost = () => {
    const [value, setValue] = useState("1");
    const [dateTime, setDateTime] = React.useState('EUR');
    const handleTab = (event, newValue) => {
        setValue(newValue);
    };
    const handleChange = (event) => {
        setDateTime(event.target.value);
    };
    const dateTimes = [
        {
            value: 'Jan 01,2022 - Dec 31,2022',
            label: 'Jan 01,2022 - Dec 31,2022',
        },
        {
            value: 'Jan 01,2021 - Dec 31,2021',
            label: 'Jan 01,2021 - Dec 31,2021',
        },
        {
            value: 'Jan 01,2020 - Dec 31,2020',
            label: 'Jan 01,2020 - Dec 31,2020',
        },
        {
            value: 'Jan 01,2019 - Dec 31,2019',
            label: 'Jan 01,2019 - Dec 31,2019',
        },
        {
            value: 'Jan 01,2018 - Dec 31,2018',
            label: 'Jan 01,2018 - Dec 31,2018',
        },
        {
            value: 'Jan 01,2017 - Dec 31,2017',
            label: 'Jan 01,2017 - Dec 31,2017',
        },
        {
            value: 'Jan 01,2016 - Dec 31,2016',
            label: 'Jan 01,2016 - Dec 31,2016',
        },
        {
            value: 'Jan 01,2015 - Dec 31,2015',
            label: 'Jan 01,2015 - Dec 31,2015',
        },
        {
            value: 'Jan 01,2014 - Dec 31,2014',
            label: 'Jan 01,2014 - Dec 31,2014',
        },
    ];
    return (
        <>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList onChange={handleTab} aria-label="lab API tabs example">
                        <Tab sx={{ fontSize: '14px' }} label="Diamond Host" value="1" />
                        <Tab sx={{ fontSize: '14px' }} label="Basic Requirements" value="2" />
                    </TabList>
                </Box>
                <TabPanel value="1">
                    <Grid container sx={{ mb: 4 }} spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        <Grid item xs={12} md={12} lg={12} sm={12}>
                            <Box>
                                <Typography sx={{ fontSize: '30px' }}>You're a Diamond Host!</Typography>
                                <Typography>Keep up the outstanding hospitality to get Diamond Host status again on Apr 1, 2021. Learn more about the program</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4} sm={12}>
                            <Box>
                                <Typography sx={{ fontSize: '16px', mb: 1 }}>Your Diamond Host stats</Typography>
                                <TextField
                                    select
                                    label="Your Diamond Host stats"
                                    value={dateTime}
                                    onChange={handleChange}
                                    sx={{ fontSize: '25px', display: 'flex', justifyContent: 'flex-end', mb: 2 }}
                                >
                                    {dateTimes.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <Typography sx={{ fontSize: '14px', textAlign: 'justify' }}>Every 3 months, we check if you’ve met the Diamond Host criteria for the past year. If you do, you’ll maintain your Diamond Host status.</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4} sm={12}>
                            <Paper elevation={3} sx={{ p: 2, bgcolor: '#292e3a', textAlign: 'left' }}>
                                <Typography sx={{ fontSize: '20px' }}>99%</Typography>
                                <Typography>Response rate</Typography>
                                <Typography>Criteria: 99%</Typography>
                                <Typography>Doing great!</Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4} sm={12}>
                            <Paper elevation={3} sx={{ p: 2, bgcolor: '#292e3a', textAlign: 'left' }}>
                                <Typography sx={{ fontSize: '20px' }}>99%</Typography>
                                <Typography>Response rate</Typography>
                                <Typography>Criteria: 99%</Typography>
                                <Typography>Doing great!</Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6} sm={12}>
                            <Paper elevation={3} sx={{ p: 2, bgcolor: '#292e3a', textAlign: 'left' }}>
                                <Typography sx={{ fontSize: '20px' }}>99%</Typography>
                                <Typography>Response rate</Typography>
                                <Typography>Criteria: 99%</Typography>
                                <Typography>Doing great!</Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6} sm={12}>
                            <Paper elevation={3} sx={{ p: 2, bgcolor: '#292e3a', textAlign: 'left' }}>
                                <Typography sx={{ fontSize: '20px' }}>99%</Typography>
                                <Typography>Response rate</Typography>
                                <Typography>Criteria: 99%</Typography>
                                <Typography>Doing great!</Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Box container>
                        <Typography sx={{ fontSize: '20px' }}>Your Diamond Host rewards.</Typography>
                    </Box>
                    <Grid container sx={{ mb: 4 }} spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        <Grid item xs={12} md={4} lg={4} sm={12}>
                            <Paper elevation={3} sx={{ p: 2, bgcolor: '#292e3a', textAlign: 'left' }}>
                                <Dashboard sx={{ fontSize: '20px' }} />
                                <Typography sx={{ mt: 2, fontSize: '20px' }}>$100 coupon</Typography>
                                <Typography>As an extra celebration for earning Diamond Host status 4 times in a row, you get a $100 coupon for any stay or experience.</Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4} sm={12}>
                            <Paper elevation={3} sx={{ p: 2, bgcolor: '#292e3a', textAlign: 'left' }}>
                                <Dashboard sx={{ fontSize: '20px' }} />
                                <Typography sx={{ mt: 2, fontSize: '20px' }}>$100 coupon</Typography>
                                <Typography>As an extra celebration for earning Diamond Host status 4 times in a row, you get a $100 coupon for any stay or experience.</Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4} sm={12}>
                            <Paper elevation={3} sx={{ p: 2, bgcolor: '#292e3a', textAlign: 'left' }}>
                                <Dashboard sx={{ fontSize: '20px' }} />
                                <Typography sx={{ mt: 2, fontSize: '20px' }}>$100 coupon</Typography>
                                <Typography>As an extra celebration for earning Diamond Host status 4 times in a row, you get a $100 coupon for any stay or experience.</Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4} sm={12}>
                            <Paper elevation={3} sx={{ p: 2, bgcolor: '#292e3a', textAlign: 'left' }}>
                                <Dashboard sx={{ fontSize: '20px' }} />
                                <Typography sx={{ mt: 2, fontSize: '20px' }}>$100 coupon</Typography>
                                <Typography>As an extra celebration for earning Diamond Host status 4 times in a row, you get a $100 coupon for any stay or experience.</Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4} sm={12}>
                            <Paper elevation={3} sx={{ p: 2, bgcolor: '#292e3a', textAlign: 'left' }}>
                                <Dashboard sx={{ fontSize: '20px' }} />
                                <Typography sx={{ mt: 2, fontSize: '20px' }}>$100 coupon</Typography>
                                <Typography>As an extra celebration for earning Diamond Host status 4 times in a row, you get a $100 coupon for any stay or experience.</Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4} sm={12}>
                            <Paper elevation={3} sx={{ p: 2, bgcolor: '#292e3a', textAlign: 'left' }}>
                                <Dashboard sx={{ fontSize: '20px' }} />
                                <Typography sx={{ mt: 2, fontSize: '20px' }}>$100 coupon</Typography>
                                <Typography>As an extra celebration for earning Diamond Host status 4 times in a row, you get a $100 coupon for any stay or experience.</Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid container sx={{ mb: 4 }}>
                        <Typography sx={{ fontSize: '20px' }}>Resources for hosting now</Typography>
                    </Grid>
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        <Grid item xs={12} sm={12} lg={6} md={6}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <img src={Resource1} alt="" className="resourceimg" />
                                <Typography>Why it’s smart to offer flexible cancellations right now</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={6} md={6}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <img src={Resource2} alt="" className="resourceimg" />
                                <Typography>Why it’s smart to offer flexible cancellations right now</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={6} md={6}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <img src={Resource3} alt="" className="resourceimg" />
                                <Typography>Why it’s smart to offer flexible cancellations right now</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={6} md={6}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <img src={Resource4} alt="" className="resourceimg" />
                                <Typography>Why it’s smart to offer flexible cancellations right now</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={6} md={6}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <img src={Resource5} alt="" className="resourceimg" />
                                <Typography>Why it’s smart to offer flexible cancellations right now</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={6} md={6}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <img src={Resource6} alt="" className="resourceimg" />
                                <Typography>Why it’s smart to offer flexible cancellations right now</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value="2">
                    <Grid container sx={{ mb: 2 }} spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        <Grid item xs={12} md={6} lg={6} sm={12}>
                            <Typography sx={{ fontSize: '30px' }}>Basic requirements</Typography>
                            <Typography>Nice work, You’ve got the basics down.</Typography>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6} sm={12}>
                            <Typography sx={{ fontSize: '20px', mb: 1 }}>For listing</Typography>
                            <TextField
                                select
                                label="Your Diamond Host stats"
                                value={dateTime}
                                onChange={handleChange}
                                sx={{ fontSize: '25px', display: 'flex', justifyContent: 'flex-end', mb: 2 }}
                            >
                                {dateTimes.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} sm={12}>
                        <TableContainer sx={{ bgcolor: '#292e3a', }} component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ fontSize: '16px' }}>Requirement</TableCell>
                                        <TableCell sx={{ fontSize: '16px' }}>You</TableCell>
                                        <TableCell sx={{ fontSize: '16px' }}>Target</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell sx={{ fontSize: '14px' }}>Overall rating</TableCell>
                                        <TableCell sx={{ fontSize: '14px' }}>0.00</TableCell>
                                        <TableCell sx={{ fontSize: '14px' }}>4.7</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ fontSize: '14px' }}>Overall rating</TableCell>
                                        <TableCell sx={{ fontSize: '14px' }}>0.00</TableCell>
                                        <TableCell sx={{ fontSize: '14px' }}>4.7</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ fontSize: '14px' }}>Overall rating</TableCell>
                                        <TableCell sx={{ fontSize: '14px' }}>0.00</TableCell>
                                        <TableCell sx={{ fontSize: '14px' }}>4.7</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell sx={{ fontSize: '14px' }}>Response rate</TableCell>
                                        <TableCell sx={{ fontSize: '14px' }}>99%</TableCell>
                                        <TableCell sx={{ fontSize: '14px' }}>90%</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </TabPanel>
            </TabContext>
        </>

    )
}

export default Daimondhost