import React, { useEffect, useState } from 'react';
import { Grid, Box, Tab } from '@mui/material';
import { TabMenu } from 'primereact/tabmenu';
import { Paginator } from 'primereact/paginator';
import Card from '../../../components/dasboard/card';
import InfoBar from '../../../components/dasboard/infoBar';
import Sidebar from '../../../components/dasboard/sidebar';
import Buttons from '../../../components/form/button';
import Input from '../../../components/form/input';
import Header from '../../../components/header';
import Property from '../../../components/mainComponents/property';
import { getPropertyApi } from '../../../services/properties/getProperty';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ErrorMessage from '../../../components/errorMessage';
import SkeletonLoader from '../../../components/skeletonLoader';
import getBookingGuestAPI from '../../../services/booking/getBookingGuest';
import SingleWithTimer from '../../../components/Tripis/SingleWithTimer';
import { getCommissionFee } from './../../../services/commissionFee/CommissionFee';

const PendingTripsPage = () => {
  const { pendingTripsAPI } = getBookingGuestAPI();
  const [properties, setProperties] = useState([]);
  const [filteredProperty, setFilteredProperty] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [basicFirst, setBasicFirst] = useState(0);
  const [basicRows, setBasicRows] = useState(10);
  const [filterToggle, setFilterToggle] = useState(false);
  const [value, setValue] = React.useState('Active');
  const [activePropLength, setActivePropLength] = useState([]);
  const [expiredPropLength, setExpiredPropLength] = useState([]);
  const [commissionFee, setCommissionFee]  = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onBasicPageChange = (event) => {
    setBasicFirst(event.first);
    setBasicRows(event.rows);
  };

  const getReturnValues = (countDown) => {
    // calculate time left
    const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

    return [days, hours, minutes, seconds];
  };

  const getPropertyListings = async () => {
    setIsLoading(true);
    try {
      const response = await pendingTripsAPI();
      if (response.status === 200) {
        let activeArr = [];
        let expiredArr = [];
        const THREE_DAYS_IN_MS = 3 * 24 * 60 * 60 * 1000;
        response.data?.data.map((item) => {
          const NOW_IN_MS = new Date(item.createdOn).getTime();
          const dateTimeAfterThreeDays = NOW_IN_MS + THREE_DAYS_IN_MS;
          let newwtimeee = dateTimeAfterThreeDays - new Date().getTime();
          const [days, hours, minutes, seconds] = getReturnValues(newwtimeee);
          if (days + hours + minutes + seconds <= 0) {
            expiredArr.push(item);
          } else {
            activeArr.push(item);
          }
        });
        setExpiredPropLength(expiredArr.length);
        setActivePropLength(activeArr.length);
        if (value == 'Active') {
          setProperties(activeArr);
          setFilteredProperty(activeArr);
        } else {
          setProperties(expiredArr);
          setFilteredProperty(expiredArr);
        }
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPropertyListings();
  }, [value]);

  useEffect(() => {
    getCommissionFee().then((res)=>{
      setCommissionFee(res.data.data[0].commission_percentage);
    }).catch((err)=>{
    })
  }, []);

  return (
    <>
      <Header />
      <div id='wrapper'>
        <div className='dashboard-menu-overlay' />
        <Sidebar page={'Pending Trips'} />
        {/* <Sidebar page={"My Listing"} /> */}
        <div className='dashboard-content'>
          <div className='dashboard-menu-btn color-bg'>
            <span>
              <i className='fas fa-bars' />
            </span>
            Dasboard Menu
          </div>
          {/* {isOpen && <Dropdown/>} */}
            <InfoBar label={'Pending Trips'} />

            <Card>
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList
                      onChange={handleChange}
                      aria-label='lab API tabs example'
                    >
                      <Tab
                        sx={{ fontSize: '14px' }}
                        label='Active'
                        value='Active'
                        icon={
                          <div
                            style={{
                              background: '#0095ff',
                              borderRadius: '50%',
                              alignItems: 'center',
                              justifyContent: 'center',
                              padding: '0 5px',
                            }}
                          >
                            <p
                              style={{
                                paddingBottom: 0,
                                color: '#fff',
                              }}
                            >
                              {activePropLength}
                            </p>
                          </div>
                        }
                        iconPosition='end'
                      />
                      <Tab
                        sx={{ fontSize: '14px' }}
                        label='Expired'
                        value='Expired'
                        icon={
                          <div
                            style={{
                              background: '#0095ff',
                              borderRadius: '50%',
                              alignItems: 'center',
                              justifyContent: 'center',
                              padding: '0 5px',
                            }}
                          >
                            <p
                              style={{
                                paddingBottom: 0,
                                color: '#fff',
                              }}
                            >
                              {expiredPropLength}
                            </p>
                          </div>
                        }
                        iconPosition='end'
                      />
                    </TabList>
                  </Box>

                  <TabPanel value='Active'>
                    {isLoading ? (
                      <Loader />
                    ) : (
                      <>
                        {properties?.length === 0 ? (
                          <Grid container>
                            <Grid item xs={12}>
                              <ErrorMessage />
                            </Grid>
                          </Grid>
                        ) : (
                          <Grid
                            container
                            rowSpacing={{ xs: 1, sm: 1, md: 1 }}
                            columnSpacing={{ xs: 1, sm: 1, md: 2 }}
                          >
                            {filterToggle
                              ? filteredProperty.map((booking) => {
                                  return (
                                    <Grid item xs={12} sm={6} md={12}>
                                      <SingleWithTimer statusType='pending' booking={booking} commissionFee={commissionFee} />
                                    </Grid>
                                  );
                                })
                              : filteredProperty
                                  ?.slice(basicFirst, basicRows + basicFirst)
                                  .map((booking) => {
                                    return (
                                      <Grid item xs={12} sm={6} md={12}>
                                        <SingleWithTimer statusType='pending' booking={booking} commissionFee={commissionFee} />
                                      </Grid>
                                    );
                                  })}
                          </Grid>
                        )}
                      </>
                    )}

                    <Card>
                      <Paginator
                        first={basicFirst}
                        rows={basicRows}
                        totalRecords={properties?.length}
                        rowsPerPageOptions={[5, 10, 20, 30]}
                        onPageChange={onBasicPageChange}
                      ></Paginator>
                    </Card>
                  </TabPanel>
                  <TabPanel value='Expired'>
                    {isLoading ? (
                      <Loader />
                    ) : (
                      <>
                        {properties?.length === 0 ? (
                          <Grid container>
                            <Grid item xs={12}>
                              <ErrorMessage />
                            </Grid>
                          </Grid>
                        ) : (
                          <Grid
                            container
                            rowSpacing={{ xs: 1, sm: 1, md: 1 }}
                            columnSpacing={{ xs: 1, sm: 1, md: 2 }}
                          >
                            {filterToggle
                              ? filteredProperty.map((booking) => {
                                  return (
                                    <Grid item xs={12} sm={6} md={12}>
                                      <SingleWithTimer statusType='pending' booking={booking} commissionFee={commissionFee} />
                                    </Grid>
                                  );
                                })
                              : filteredProperty
                                  ?.slice(basicFirst, basicRows + basicFirst)
                                  .map((booking) => {
                                    return (
                                      <Grid item xs={12} sm={6} md={12}>
                                        <SingleWithTimer statusType='pending' booking={booking} commissionFee={commissionFee} />
                                      </Grid>
                                    );
                                  })}
                          </Grid>
                        )}
                      </>
                    )}

                    <Card>
                      <Paginator
                        first={basicFirst}
                        rows={basicRows}
                        totalRecords={properties?.length}
                        rowsPerPageOptions={[5, 10, 20, 30]}
                        onPageChange={onBasicPageChange}
                      ></Paginator>
                    </Card>
                  </TabPanel>
                </TabContext>
              </Box>
            </Card>
          </div>
        <div className='dashbard-bg gray-bg' />
      </div>
    </>
  );
};

const Loader = () => {
  return (
    <Grid
      container
      rowSpacing={{ xs: 1, sm: 1, md: 2 }}
      columnSpacing={{ xs: 1, sm: 1, md: 2 }}
    >
      <Grid item xs={12} sm={6} md={6}>
        <SkeletonLoader height={200} />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <SkeletonLoader height={200} />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <SkeletonLoader height={200} />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <SkeletonLoader height={200} />
      </Grid>
    </Grid>
  );
};

export default PendingTripsPage;
