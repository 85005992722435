import React from "react";
import Typography from "@mui/material/Typography";

export default function Typographyy(props) {
  const { text, variant, margintop, bold } = props;
  return (

    <Typography
      variant={variant}
      sx={{ marginTop: `${margintop}px`, color: "#ffffff", fontWeight: bold }}
    >
      {text}
    </Typography>
    
  );
}
