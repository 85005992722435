import React from 'react';
import { Grid } from '@mui/material';
import { Typography, Button, Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import PayoutModal from '../../../components/payoutmodal/PayoutModal';
export default function Payouts() {
  return (
    <>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        style={{ marginTop: 30 }}
      >
        <Grid item xs={12}>
          <Typography
            variant='h5'
            component='h4'
            style={{ color: 'white', textAlign: 'start' }}
          >
            How you get paid
          </Typography>
          <p>
            You can send your money to one or more payout methods. To manage
            your payout method(s) or assign a taxpayer, use the edit menu next
            to each payout method.
          </p>
        </Grid>
        <Grid item xs={8}>
          <Divider />
        </Grid>
        <Grid item xs={6}>
          <p style={{ marginTop: 20 }}>{`Prepaid MasterCard`}</p>
        </Grid>
        <Grid item xs={2}>
          <Button style={{ marginTop: 10 }} variant='outlined'>
            <PayoutModal />
          </Button>
        </Grid>
        <Grid item xs={8}>
          <Divider />
        </Grid>
      </Grid>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        style={{ marginTop: 10 }}
      >
        <Grid item>
          <Button variant='contained'>
            <Link style={{ color: 'white' }} to='/admin/bank-account'>
              Add Payout Method
            </Link>
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
