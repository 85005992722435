import { API_URL } from "../../config";
import axios from "axios";

export const addToWishlistApi = async (userId, propertyId) => {
  try {
    const response = await axios.post(API_URL + `wishlist/add`, {
      userId: userId,
      propertyId: propertyId,
    });
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log("Bad Response of property : ", response);
      return response;
    }
  }
};
export const removeFromWishlistApi = async (userId, propertyId) => {
  try {
    const response = await axios.put(API_URL + `wishlist/update`, {
      userId: userId,
      propertyId: propertyId,
    });
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log("Bad Response of property : ", response);
      return response;
    }
  }
};
