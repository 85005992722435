import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { Toast } from 'primereact/toast';
import { Paginator } from 'primereact/paginator';
import Card from '../../../components/dasboard/card';
import InfoBar from '../../../components/dasboard/infoBar';
import Sidebar from '../../../components/dasboard/sidebar';
import Header from '../../../components/header';
import getBookingOwnerAPI from '../../../services/booking/getBookingOwner';
import ErrorMessage from '../../../components/errorMessage';
import SkeletonLoader from '../../../components/skeletonLoader';
import { useRef } from 'react';
import SingleWithoutTimer from '../../../components/BookingComponents/SingleWithoutTimer';
import { updateBookingApi } from '../../../services/booking/updateBookingStatus';
import ReservationDetail from './../../Website/ReservationDetails/ReservationDetail';
import { getCommissionFee } from './../../../services/commissionFee/CommissionFee';

const UpcomingReservation = () => {
  const { getConfirmedData } = getBookingOwnerAPI();
  const [properties, setProperties] = useState([]);
  const [filteredProperty, setFilteredProperty] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [basicFirst, setBasicFirst] = useState(0);
  const [basicRows, setBasicRows] = useState(10);
  const [filterToggle, setFilterToggle] = useState(false);
  const [bookingDetails, setBookingDetails] = useState(null);
  const [open, setOpen] = useState(false);
  const [commissionFee, setCommissionFee] = useState(0);

  const onBasicPageChange = (event) => {
    setBasicFirst(event.first);
    setBasicRows(event.rows);
  };

  function handleSearch(value) {
    if (value === '') {
      setFilteredProperty(properties);
      setFilterToggle(false);
    } else {
      const filtered = properties.filter((property) => {
        return property?.booking_property.title
          .toLowerCase()
          .includes(value.toLowerCase());
      });
      setFilterToggle(true);
      setFilteredProperty(filtered);
    }
  }

  const accept = async (id) => {
    try {
      const response = await updateBookingApi(id, 'accepted');
      if (response.status === 200) {
        // setLoading(false);
        toast.current.show({
          severity: 'info',
          summary: 'Booking Accepted',
          life: 3000,
        });
      }
    } catch (err) {
      // setLoading(false);
      toast.current.show({
        severity: 'warn',
        summary: 'Not Accepted',
        detail: 'there seems to be an error right now',
        life: 3000,
      });
    }
  };

  const getPropertyListings = async () => {
    try {
      const response = await getConfirmedData();
      if (response.status === 200) {
        setProperties(response.data?.data);
        setFilteredProperty(response.data?.data);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getPropertyListings();
    getCommissionFee()
      .then((res) => {
        setCommissionFee(res.data.data[0].commission_percentage);
      })
      .catch((err) => {});
  }, []);

  const handleClickOpen = (item) => {
    setOpen(true);
    setBookingDetails(item);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const toast = useRef(null);

  return (
    <>
      <Header />
      <Toast ref={toast} position='bottom-right' />
      <div id='wrapper'>
        <div className='dashboard-menu-overlay' />
        <Sidebar page={'Upcoming Reservations'} />
        <div className='dashboard-content'>
          <div className='dashboard-menu-btn color-bg'>
            <span>
              <i className='fas fa-bars' />
            </span>
            Dasboard Menu
          </div>
          {/* {isOpen && <Dropdown/>} */}
          <InfoBar label={'Upcoming Reservations'} />
          <div className='dasboard-wrapper fl-wrap no-pag'>
            {isLoading === true ? (
              <Loader />
            ) : (
              <>
                {properties?.length === 0 ? (
                  <Grid container>
                    <Grid item xs={12}>
                      <ErrorMessage />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    container
                    rowSpacing={{ xs: 1, sm: 1, md: 1 }}
                    columnSpacing={{ xs: 1, sm: 1, md: 2 }}
                  >
                    {filterToggle
                      ? filteredProperty.map((booking) => {
                          return (
                            <Grid item xs={12} sm={6} md={12}>
                              <SingleWithoutTimer
                                booking={booking}
                                accept={() => accept(booking.id)}
                                setBookingDetails={setBookingDetails}
                                handleClickOpen={handleClickOpen}
                              />
                            </Grid>
                          );
                        })
                      : filteredProperty
                          ?.slice(basicFirst, basicRows + basicFirst)
                          .map((booking) => {
                            return (
                              <Grid item xs={12} sm={6} md={12}>
                                <SingleWithoutTimer
                                  booking={booking}
                                  accept={() => accept(booking.id)}
                                  setBookingDetails={setBookingDetails}
                                  handleClickOpen={handleClickOpen}
                                />
                              </Grid>
                            );
                          })}
                  </Grid>
                )}
              </>
            )}

            <Card>
              <Paginator
                first={basicFirst}
                rows={basicRows}
                totalRecords={properties?.length}
                rowsPerPageOptions={[5, 10, 20, 30]}
                onPageChange={onBasicPageChange}
              ></Paginator>
            </Card>
          </div>
        </div>
        <div className='dashbard-bg gray-bg' />
      </div>
      <ReservationDetail
        open={open}
        handleClose={handleClose}
        booking={bookingDetails}
        // booking={booking}
        scroll={'body'}
        descriptionElementRef={descriptionElementRef}
        header='Confirmation'
        type='host'
        showInstruction={true}
        accept={accept}
        commissionFee={commissionFee}
        detailType='UPCOMING'
      />
    </>
  );
};

const Loader = () => {
  return (
    <Grid
      container
      rowSpacing={{ xs: 1, sm: 1, md: 2 }}
      columnSpacing={{ xs: 1, sm: 1, md: 2 }}
    >
      <Grid item xs={12} sm={6} md={6}>
        <SkeletonLoader height={200} />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <SkeletonLoader height={200} />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <SkeletonLoader height={200} />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <SkeletonLoader height={200} />
      </Grid>
    </Grid>
  );
};

export default UpcomingReservation;
