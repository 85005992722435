import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import ElectricBoltSharpIcon from '@mui/icons-material/ElectricBoltSharp';

const Card = (props) => {
  const greenCircle = () => {
    return (
      <div
        style={{
          width: 10,
          height: 10,
          borderRadius: 10,
          marginRight: 8,
          marginTop: 7,
          backgroundColor: '#00c853',
        }}
      ></div>
    );
  };
  const redCircle = () => {
    return (
      <div
        style={{
          width: 10,
          height: 10,
          borderRadius: 10,
          marginRight: 8,
          marginTop: 7,
          backgroundColor: 'red',
        }}
      ></div>
    );
  };

  return (
    <>
      {props.withIcon && (
        <div
          className='dasboard-widget-title fl-wrap'
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <h5>
            <i className={props.icon}></i> {props.title && props.title}{' '}
            {props.subtitle}
            {props.code && (
              <span style={{ fontSize: '15px', color: '#fff' }}>
                ({props.code})
              </span>
            )}
          </h5>
          <span>
            

            <div style={{ display: 'flex' }}>
              {props.propertyId ? (
                <Typography
                  variant='outlined'
                  style={{
                    display: 'flex',
                    fontSize: 16,
                    textDecoration: 'underline',
                    color: 'white',
                  }}
                >
                  {props.status === "LISTED" ? greenCircle():redCircle()}
                  <Link
                    style={{
                      color: 'white',
                      textDecoration: 'underline',
                      fontSize: 16,
                      fontWeight: 'bolder',
                    }}
                    to={`/property-detail/${props.propertyId}`}
                  >
                    {props.status}
                  </Link>
               
                </Typography>
              ) : (
                ''
              )}
              {props?.propertyId && (
                <span
                  style={{
                    fontSize: 16,
                    display: 'flex',

                    textDecoration: 'underline',
                    color: 'white',
                    marginLeft: 15,
                    cursor: 'pointer',
                  }}
                >
                  <span style={{ fontSize: 16, marginRight: 3 }}>
                    <ElectricBoltSharpIcon
                      sx={{ color: '#efaa27', fontSize: 18 }}
                    />
                  </span>
                  <b>{props.instantBooking===true? "Instant Book On" :"Instant Book Off"}</b>
                </span>
              )}  {props.propertyId ? (
                <Typography
                  variant='outlined'
                  style={{
                    display: 'flex',
                    fontSize: 16,
                    textDecoration: 'underline',
                    color: 'white',
                    marginLeft:10
                  }}
                >
                  <Link
                    style={{
                      color: 'white',
                      textDecoration: 'underline',
                      fontSize: 16,
                      fontWeight: 'bolder',
                    }}
                    to={`/property-detail/${props.propertyId}`}
                  >
                    Preview
                  </Link>
                </Typography>
              ) : (
                ''
              )}
            </div>
          </span>
        </div>
      )}

      <div className='dasboard-widget-box fl-wrap'>
        <div style={{ marginTop: 0, marginBottom: 0 }}>{props.children}</div>
      </div>
    </>
  );
};

export default Card;
