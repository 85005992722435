import { API_URL } from '../../config';
import { axiosPrivate } from '../../api/axios';

const getVendorWithdrawalHistoryApi = async (data) => {
  try {
    const response = await axiosPrivate.post(
      API_URL + `vendor-withdrawal/get-pending-withdrawals`,
      data
    );
    return response.data;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('bad response of get vendor-withdrawal: ', response);
      return response;
    }
  }
};
export default getVendorWithdrawalHistoryApi;
