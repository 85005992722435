import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
  getZipCode,
} from "use-places-autocomplete";
import { GoogleApiWrapper, Map, Marker } from "google-maps-react";
import Input from "../../../../components/form/input";
import Card from "../../../../components/dasboard/card";
import ListItems from "../../../../components/listItems";
import useOnclickOutside from "react-cool-onclickoutside";

const Location = (props) => {
  const { inputValues, setInputValues, handleChangeInput, validationType } =
    props;
  const [mapCenter, setMapCenter] = useState({
    lat: props.hostLocation ? props.hostLocation.latitude : 24.8840604,
    lng: props.hostLocation ? props.hostLocation.longitude : 67.034556,
    address: "",
  });

  useEffect(() => {
    setMapCenter({
      lat: props.hostLocation ? props.hostLocation.latitude : 10.26664,
      lng: props.hostLocation ? props.hostLocation.longitude : -75.46663,
      address: "",
    });
  }, [props.hostLocation]);

  const onMapClicked = (props, map, e) => {
    setMapCenter({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  };

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });
  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const handleSelect =
    ({ description }) =>
    () => {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setValue(description, false);
      clearSuggestions();

      // Get latitude and longitude via utility functions
      let latitude = 0;
      let longitude = 0;
      getGeocode({ address: description })
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          latitude = lat;
          longitude = lng;
          localStorage.setItem(
            "userLocation",
            JSON.stringify({
              latitude: lat,
              longitude: lng,
            })
          );
          props.setHostLocation({
            latitude: lat,
            longitude: lng,
          });
        })
        .catch((error) => {
          console.log("😱 Error: ", error);
        });
      getGeocode({ address: description })
        // By default we use the "long_name" value from API response, you can tell the utility to use "short_name"
        // by setting the second parameter to "true"
        .then((results) => {
          let city, state, country;
          for (let i = 0; i < results[0].address_components.length; i++) {
            for (
              let j = 0;
              j < results[0].address_components[i].types.length;
              j++
            ) {
              switch (results[0].address_components[i].types[j]) {
                case "locality":
                  city = results[0].address_components[i].long_name;
                  break;
                case "administrative_area_level_1":
                  state = results[0].address_components[i].long_name;
                  break;
                case "country":
                  country = results[0].address_components[i].long_name;
                  break;
                default:
                  break;
              }
            }
          }

          try {
            setInputValues({
              ...inputValues,
              lat: latitude,
              lng: longitude,
              address: description,
              state: state,
              city: city,
              country: country,
              zipCode: getZipCode(results[0]),
            });
          } catch (error) {
            console.log("Error: ", error);
          }
        });
    };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <ListItems
          key={place_id}
          onClick={handleSelect(suggestion)}
          primary={main_text}
          secondary={secondary_text}
        />
      );
    });

  const getNewCoords = (coords) => {
    const { latLng } = coords;
    const lat = latLng.lat();
    const lng = latLng.lng();
    setMapCenter({ lat: lat, lng: lng });
    getGeocode({ location: { lat, lng } })
      .then((results) => {
        let city, state, country;
        for (let i = 0; i < results[0].address_components.length; i++) {
          for (
            let j = 0;
            j < results[0].address_components[i].types.length;
            j++
          ) {
            switch (results[0].address_components[i].types[j]) {
              case "locality":
                city = results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_1":
                state = results[0].address_components[i].long_name;
                break;
              case "country":
                country = results[0].address_components[i].long_name;
                break;
              default:
                break;
            }
          }
        }

        try {
          setInputValues({
            ...inputValues,
            lat: lat,
            lng: lng,
            address: results[0]?.formatted_address,
            state: state,
            city: city,
            country: country,
            zipCode: getZipCode(results[0]),
          });
          clearSuggestions();
          setValue(results[0]?.formatted_address, false);
        } catch (error) {
          console.log("Error: ", error);
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <Card withIcon title={"Location"} icon={"fa fa-map-marker"}>
      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid item xs={12}>
          <div ref={ref}>
            <Typography>Where is your property located?</Typography>
            <Input
              value={value}
              onChange={handleInput}
              disabled={!ready}
              label='Where is your property located?'
              defaultValue={value}
            />
            {status === "OK" && (
              <ul style={{ position: "absolute", zIndex: 9999 }}>
                {renderSuggestions()}
              </ul>
            )}
          </div>
          <div className='map-container'>
            <Typography>Move the marker to provide exact location</Typography>
            <div id='singleMap' className='drag-map'>
              <Map
                google={window.google}
                zoom={14}
                className={"map"}
                initialCenter={mapCenter}
                center={mapCenter}
                onClick={onMapClicked}
              >
                <Marker
                  position={mapCenter}
                  name={"Current location"}
                  draggable={true}
                  onDragend={(t, map, coord) => getNewCoords(coord)}
                />
              </Map>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Typography>Address</Typography>
          <Input
            label={"Address"}
            name={"address"}
            id={"address"}
            onChange={handleChangeInput}
            value={inputValues.address || ""}
            defaultValue={inputValues.address}
          />
          {validationType.open && validationType.type == "address" && (
            <p style={{ color: "red" }}>{validationType.message}</p>
          )}
          <Typography style={{ fontSize: 13, marginTop: 3 }}>
            House name/number + street/road
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Typography>Country</Typography>
          <Input
            label={"Country"}
            name={"country"}
            id={"country"}
            onChange={handleChangeInput}
            value={inputValues.country || ""}
            defaultValue={inputValues.country}
          />
          {validationType.open && validationType.type == "country" && (
            <p style={{ color: "red" }}>{validationType.message}</p>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Typography>City</Typography>
          <Input
            label={"City"}
            name={"city"}
            id={"city"}
            onChange={handleChangeInput}
            value={inputValues.city || ""}
            defaultValue={inputValues.city}
          />
          {validationType.open && validationType.type == "city" && (
            <p style={{ color: "red" }}>{validationType.message}</p>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Typography>State</Typography>
          <Input
            label={"State"}
            name={"state"}
            id={"state"}
            onChange={handleChangeInput}
            value={inputValues.state || ""}
            defaultValue={inputValues.state}
          />
          {validationType.open && validationType.type == "state" && (
            <p style={{ color: "red" }}>{validationType.message}</p>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Typography>Zipcode</Typography>
          <Input
            label={"Zipcode"}
            name={"zipCode"}
            id={"zipCode"}
            onChange={handleChangeInput}
            value={inputValues.zipCode || ""}
            defaultValue={inputValues.zipCode}
          />
          {validationType.open && validationType.type == "zipCode" && (
            <p style={{ color: "red" }}>{validationType.message}</p>
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
})(Location);
