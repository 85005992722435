import { API_URL } from "../../config";
import axios from "axios";

export const getCohostProperty = async (userId) => {
  try {
    const response = await axios.get(
      API_URL + `co-host/getcohostproperties/${userId}`
    );
    return response.data;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log("Bad Response of get Cohost Propertise  : ", response);
      return response;
    }
  }
};
