import React, { useState, useRef } from "react";
// import Buttons from './form/button'
import LOGO from "../assets/images/logo.png";
import USERIMAGE from "../assets/images/avatar/5.jpg";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import styled from "styled-components";
import userStatusUpdate from "../services/User/userStatus";
import { Toast } from "primereact/toast";
import { confirmDialog } from "primereact/confirmdialog";
import AddPropertyPopup from "./Popup/AddPropertyPopup";
import { useEffect } from "react";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import axios from "axios";
import { API_URL } from "../config";

const Header = ({ user, showHeader }) => {
  // const [userInfo, setUserInfo] = useState({});
  
  const toast = useRef(null);
  const navigate = useNavigate();
  const userType = localStorage.getItem("userHost") || false;
  let userData;
  if (JSON.parse(localStorage.getItem("userData")) === undefined) {
    userData = null;
  } else {
    userData = JSON.parse(localStorage.getItem("userData")) || null;
  }
  const userHost = JSON.parse(localStorage.getItem("userHost")) || null;

  // const getUser = async () => {
  //   const user = await getUserApi(userData?.email);
  //   setUserInfo(user.data);
  // };
  // useEffect(() => {
  //   getUser();
  // }, []);

  const addNotification = JSON.parse(sessionStorage.getItem("addNotification"));
  const bookNotification = JSON.parse(
    sessionStorage.getItem("bookNotification")
  );

  const [showNav, setShowNav] = useState(false);
  const [addPropertyNotification, setAddPropertyNotification] =
    useState(addNotification);
  const [bookPropertyNotification, setBookPropertyNotification] =
    useState(bookNotification);
  //history object

  useEffect(() => {
    if (addNotification == null) setAddPropertyNotification(true);
    else setAddPropertyNotification(addNotification);
    if (bookNotification == null) setBookPropertyNotification(true);
    else setBookPropertyNotification(addNotification);
  }, []);

  const history = useNavigate();
  const { pathname } = useLocation();
  const params = useParams();

  const accept = async () => {
    let res = await userStatusUpdate();
    if (res.status === 200) {
      localStorage.setItem("userHost", true);
      let data = userData;
      data.isHost = true;
      localStorage.setItem("userData", JSON.stringify(data));
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "User made host successfully",
        life: 3000,
      });
      history("/admin/property-listing");
    } else {
      toast.current.show({
        severity: "info",
        summary: "Already a host!",
        detail: "You are already a host!",
        life: 3000,
      });
    }
  };
  const reject = () => {};
  const openModal = () => {
    confirmDialog({
      message: <AddPropertyPopup />,
      header: "Requirements for becoming a Host on Staywo.com",
      acceptLabel: "Yes, I agree",
      rejectLabel: "Disagree",
      accept,
      reject,
    });
  };

  // const navigateToAddProperty = () => {
  //   if (!userData) {
  //     navigate('/login');
  //   } else if (userHost) {
  //     navigate('/admin/add-property');
  //   } else if (!userHost) {
  //     openModal();
  //   }
  // };
  // const navigateToPropertyListing = () => {
  //   if (!userData) {
  //     navigate('/login');
  //   } else if (userData?.isHost) {
  //     navigate('/property-listing?floodRelief=true');
  //   } else if (!userData?.isHost) {
  //     openModalNotification();
  //   }
  // };

  const acceptNotification = () => {
    localStorage.setItem("userHost", true);
    navigate("/admin/add-property");
  };
  const rejectNotification = () => {};
  const openModalNotification = () => {
    confirmDialog({
      message: <AddPropertyPopup />,
      header: "Requirements for becoming a Host on Staywo.com",
      acceptLabel: "Yes, I agree",
      rejectLabel: "Disagree",
      acceptNotification,
      rejectNotification,
    });
  };

  // !JSON.parse(localStorage.getItem("user"))
  // ? "/login"

  // : "/admin/add-property"

  const navigateToAddProperty = () => {
    if (!JSON.parse(localStorage.getItem("user"))) {
      navigate(`/login`);
    } else {
      if (!userHost) {
        userType === "true"
          ? localStorage.setItem("userHost", false)
          : localStorage.setItem("userHost", true);
        navigate(`/admin/add-property`);
      } else {
        navigate(`/admin/add-property`);
      }
    }
  };

  const handleIsShow = () => {
    const urls = [
      "/",
      "/contact",
      "/support",
      "/login",
      "/privacypolicy",
      "/terms-and-conditions",
      "/property-listing",
      `/property-detail/${params?.id}`,
    ];
    return urls.includes(pathname) ? false : true;
  };

  const checkConditionAction = () => {
    if (
      JSON.parse(localStorage.getItem("userBankInfo")) === false ||
      JSON.parse(localStorage.getItem("userData"))?.verified === false ||
      JSON.parse(localStorage.getItem("property_inprogress"))?.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleUserIsBankInfo = async () => {
    if (
      JSON.parse(localStorage.getItem("userHost")) &&
      localStorage.getItem("userBankInfo") === null
    ) {
      const res = await axios.get(
        API_URL +
          `user-bank-details/user/${
            JSON.parse(localStorage.getItem("userData"))?.id
          }`
      );

      if (res.status === 200) {
        localStorage.setItem("userBankInfo", res.data.bankName ? true : false);
      }
    }
  };

  const getUserPropertyActions = async () => {
    if (
      JSON.parse(localStorage.getItem("userHost")) &&
      localStorage.getItem("property_inprogress") === null
    ) {
      const res = await axios.get(
        API_URL +
          `property/basics-infos?&userId=${
            JSON.parse(localStorage.getItem("userData"))?.id
          }&status=INPROGRESS`
      );
      if (res.status === 200) {
        localStorage.setItem(
          "property_inprogress",
          JSON.stringify(res.data.data)
        );
      }
    }
  };

  React.useEffect(() => {
    handleUserIsBankInfo();
    getUserPropertyActions();
  }, []);

  const handleAction = () => {
    history("/admin/actions");
  };

  return (
    <>
      <header className="main-header">
        <Toast ref={toast} position="bottom-right" />
        <div style={{ padding: "5px 50px" }}>
          <div className="logo-holder">
            <a href="/">
              <img src={LOGO} alt="" />
            </a>
          </div>

          {userData?.isHost === true ? (
            <div className="show-reg-form-button">
              <AddPropertyButton
                style={{ textDecoration: "none", cursor: "pointer" }}
                onClick={navigateToAddProperty}
                className="add-list "
              >
                <i className="fal fa-plus" />
                <span>Add Property</span>
              </AddPropertyButton>
            </div>
          ) : (
            <AddPropertyButton
              style={{ textDecoration: "none", cursor: "pointer" }}
              // href={
              //   !JSON.parse(localStorage.getItem('user'))
              //     ? '/login'
              //     : '/admin/property-listing'
              // }
              className="add-list"
              onClick={() => {
                if (!JSON.parse(localStorage.getItem("user"))) {
                  navigate("/login");
                } else {
                  openModal();
                }
              }}
            >
              <i className="fal fa-plus" />
              <span>Become a host</span>
            </AddPropertyButton>
          )}
          {!JSON.parse(localStorage.getItem("user")) ? (
            <a href="/login">
              <div className="show-reg-form">
                <i className="fas fa-user"></i>
                <span style={{ fontSize: 15 }}>Sign In</span>
              </div>
            </a>
          ) : (
            <>
              <div
                className="show-reg-form dashboard-submenu-open"
                onClick={() => setShowNav(!showNav)}
              >
                <img
                  src={
                    userData?.profilePicture
                      ? userData?.profilePicture
                      : USERIMAGE
                  }
                  alt={`${userData?.firstName} ${userData?.lastName}`}
                />
              </div>
              <div className="header-opt-modal novis_header-mod">
                <div className="header-opt-modal-container hopmc_init">
                  <div className="header-opt-modal-item lang-item fl-wrap">
                    <h4>
                      Language: <span>EN</span>
                    </h4>
                    <div className="header-opt-modal-list fl-wrap">
                      <ul>
                        <li>
                          <a href="#" className="current-lan" data-lantext="EN">
                            English
                          </a>
                        </li>
                        <li>
                          <a href="#" data-lantext="FR">
                            Franais
                          </a>
                        </li>
                        <li>
                          <a href="#" data-lantext="ES">
                            Espaol
                          </a>
                        </li>
                        <li>
                          <a href="#" data-lantext="DE">
                            Deutsch
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="header-opt-modal-item currency-item fl-wrap">
                    <h4>
                      Currency: <span>USD</span>
                    </h4>
                    <div className="header-opt-modal-list fl-wrap">
                      <ul>
                        <li>
                          <a
                            href="#"
                            className="current-lan"
                            data-lantext="USD"
                          >
                            USD
                          </a>
                        </li>
                        <li>
                          <a href="#" data-lantext="EUR">
                            EUR
                          </a>
                        </li>
                        <li>
                          <a href="#" data-lantext="GBP">
                            GBP
                          </a>
                        </li>
                        <li>
                          <a href="#" data-lantext="RUR">
                            RUR
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              {showNav ? (
                <div className="dashboard-submenu">
                  <div className="dashboard-submenu-title fl-wrap">
                    Welcome ,
                    <span>
                      {userData?.firstName} {userData?.lastName}
                    </span>
                  </div>
                  <ul>
                    {/* <li>
                      <Link to="/admin/dashboard">
                        <i className="fal fa-chart-line"></i> Dashboard
                      </Link>
                    </li> */}
                    {userType === "true" ? (
                      <li>
                        <Link to="/admin/add-property">
                          <i className="fal fa-file-plus"></i> Add Listing
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}
                    <li>
                      <Link
                        to="/admin/account"
                        style={{ textDecoration: "none", cursor: "pointer" }}
                      >
                        <i className="fal fa-user-edit"></i>Account Settings
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/admin/messages"
                        style={{ textDecoration: "none", cursor: "pointer" }}
                      >
                        <i className="fal fa-comment-alt-lines"></i>Messages
                      </Link>
                    </li>
                  </ul>
                  <a
                    className="color-bg db_log-out"
                    onClick={() => {
                      localStorage.clear();
                      history("/login");
                    }}
                  >
                    <i className="far fa-power-off"></i> Log Out
                  </a>
                </div>
              ) : null}
              {userData && !userData?.isHost ? (
                ""
              ) : userType === "true" ? (
                <div
                  className="show-reg-form"
                  onClick={() => {
                    userType === "true"
                      ? localStorage.setItem("userHost", false)
                      : localStorage.setItem("userHost", true);
                    setTimeout(() => {
                      navigate("/");
                    }, 1500);
                  }}
                >
                  <i
                  // className="fas fa-random"
                  >
                    <svg
                      width="20"
                      height="13"
                      viewBox="0 0 26 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_110_2)">
                        <path
                          d="M19.0097 0.219315L22.3151 3.58929L18.9451 6.89472L17.952 5.88222L19.5936 4.27215L7.69127 4.15706C6.51833 4.14572 5.55483 5.09076 5.54349 6.26371C5.53215 7.43665 6.47719 8.40015 7.65013 8.41149L18.7116 8.51845C20.6665 8.53735 22.2416 10.1431 22.2227 12.0981C22.2038 14.053 20.598 15.6281 18.6431 15.6092L6.74075 15.4941L8.35086 17.1356L7.3384 18.1287L4.033 14.7587L7.40295 11.4532L8.39602 12.4657L6.75447 14.0759L18.6568 14.191C19.8297 14.2023 20.7932 13.2573 20.8046 12.0843C20.8159 10.9114 19.8709 9.94789 18.6979 9.93655L7.63642 9.82959C5.68151 9.81069 4.10644 8.2049 4.12535 6.24996C4.14425 4.29501 5.75007 2.71998 7.70498 2.73888L19.6073 2.85397L17.9972 1.21246L19.0097 0.219315Z"
                          fill="#fff"
                        />
                      </g>
                      <circle
                        r="2"
                        transform="matrix(-1 0 0 1 24 3.99988)"
                        fill="#FA1414"
                      />
                      <circle
                        r="2"
                        transform="matrix(-1 0 0 1 2 14.9999)"
                        fill="#FA1414"
                      />
                      <defs>
                        <clipPath id="clip0_110_2">
                          <rect
                            width="18.1732"
                            height="18.1732"
                            fill="white"
                            transform="matrix(0.00966894 -0.999953 -0.999953 -0.00966894 22.1723 18.348)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </i>
                  <Link
                    to="/"
                    className=""
                    style={{
                      textDecoration: "none",
                      cursor: "pointer",
                      fontSize: 15,
                      color: "inherit",
                    }}
                  >
                    <span style={{ fontSize: 15 }}>Switch to Traveler</span>
                  </Link>
                </div>
              ) : (
                <div
                  className="show-reg-form"
                  onClick={() => {
                    userType === "true"
                      ? localStorage.setItem("userHost", false)
                      : localStorage.setItem("userHost", true);

                    window.location.reload();
                  }}
                >
                  <i
                  // className="fas fa-random"
                  >
                    <svg
                      width="20"
                      height="13"
                      viewBox="0 0 26 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_110_2)">
                        <path
                          d="M19.0097 0.219315L22.3151 3.58929L18.9451 6.89472L17.952 5.88222L19.5936 4.27215L7.69127 4.15706C6.51833 4.14572 5.55483 5.09076 5.54349 6.26371C5.53215 7.43665 6.47719 8.40015 7.65013 8.41149L18.7116 8.51845C20.6665 8.53735 22.2416 10.1431 22.2227 12.0981C22.2038 14.053 20.598 15.6281 18.6431 15.6092L6.74075 15.4941L8.35086 17.1356L7.3384 18.1287L4.033 14.7587L7.40295 11.4532L8.39602 12.4657L6.75447 14.0759L18.6568 14.191C19.8297 14.2023 20.7932 13.2573 20.8046 12.0843C20.8159 10.9114 19.8709 9.94789 18.6979 9.93655L7.63642 9.82959C5.68151 9.81069 4.10644 8.2049 4.12535 6.24996C4.14425 4.29501 5.75007 2.71998 7.70498 2.73888L19.6073 2.85397L17.9972 1.21246L19.0097 0.219315Z"
                          fill="#fff"
                        />
                      </g>
                      <circle
                        r="2"
                        transform="matrix(-1 0 0 1 24 3.99988)"
                        fill="#FA1414"
                      />
                      <circle
                        r="2"
                        transform="matrix(-1 0 0 1 2 14.9999)"
                        fill="#FA1414"
                      />
                      <defs>
                        <clipPath id="clip0_110_2">
                          <rect
                            width="18.1732"
                            height="18.1732"
                            fill="white"
                            transform="matrix(0.00966894 -0.999953 -0.999953 -0.00966894 22.1723 18.348)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </i>
                  <Link
                    to="/admin/add-property"
                    style={{
                      textDecoration: "none",
                      cursor: "pointer",
                      fontSize: 15,
                      color: "inherit",
                    }}
                  >
                    <span style={{ fontSize: 15 }}>Switch to Host</span>
                  </Link>
                </div>
              )}
            </>
          )}

          <div className="nav-holder main-menu">
            <nav>
              <ul className="no-list-style">
                <li></li>
                <li>
                  <a
                    style={{
                      textDecoration: "none",
                      cursor: "pointer",
                      border: "2px solid #0096ff",
                      borderRadius: "25px",
                      paddingBottom: "7px",
                      paddingTop: "7px",
                    }}
                    // onClick={navigateToPropertyListing}
                    href="/property-listing?floodRelief=true"
                  >
                    <img src={"https://cdn3.iconfinder.com/data/icons/o-shaped-flag-1/128/O_shaped_asian_flag-28-512.png"} width={"30px"} /> <img src={"https://cdn.countryflags.com/thumbs/syria/flag-round-250.png"} width={"24px"} /> Disaster Management
                  </a>
                </li>
                <li>
                  <Link
                    to="/"
                    style={{
                      textDecoration: "none",
                      cursor: "pointer",
                      color: pathname === "/" ? "#0095ff" : "#fff",
                    }}
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    to="/property-listing"
                    style={{
                      textDecoration: "none",
                      cursor: "pointer",
                      color:
                        pathname === "/property-listing" ? "#0095ff" : "#fff",
                    }}
                  >
                    Explore
                  </Link>
                </li>
                <li>
                  <div className="header-opt-modal novis_header-mod">
                    <div className="header-opt-modal-container hopmc_init">
                      <div className="header-opt-modal-item lang-item fl-wrap">
                        <h4>
                          Language: <span>EN</span>
                        </h4>
                        <div className="header-opt-modal-list fl-wrap">
                          <ul>
                            <li>
                              <a
                                href="#"
                                className="current-lan"
                                data-lantext="EN"
                              >
                                English
                              </a>
                            </li>
                            <li>
                              <a href="#" data-lantext="FR">
                                Franais
                              </a>
                            </li>
                            <li>
                              <a href="#" data-lantext="ES">
                                Espaol
                              </a>
                            </li>
                            <li>
                              <a href="#" data-lantext="DE">
                                Deutsch
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="header-opt-modal-item currency-item fl-wrap">
                        <h4>
                          Currency: <span>USD</span>
                        </h4>
                        <div className="header-opt-modal-list fl-wrap">
                          <ul>
                            <li>
                              <a
                                href="#"
                                className="current-lan"
                                data-lantext="USD"
                              >
                                USD
                              </a>
                            </li>
                            <li>
                              <a href="#" data-lantext="EUR">
                                EUR
                              </a>
                            </li>
                            <li>
                              <a href="#" data-lantext="GBP">
                                GBP
                              </a>
                            </li>
                            <li>
                              <a href="#" data-lantext="RUR">
                                RUR
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <a style={{ textDecoration: "none" }}>
                    Help<i className="fa fa-caret-down"></i>
                  </a>
                  <ul>
                    <li>
                      <Link
                        to="/contact"
                        style={{
                          textDecoration: "none",
                          cursor: "pointer",
                          color: pathname === "/contact" ? "#0095ff" : "#fff",
                        }}
                      >
                        Contact Us
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/support"
                        style={{
                          textDecoration: "none",
                          cursor: "pointer",
                          color: pathname === "/" ? "#0095ff" : "#fff",
                        }}
                      >
                        Support
                      </Link>
                    </li>
                    <li>
                      <a
                        style={{ textDecoration: "none", cursor: "pointer" }}
                        href="https://api.whatsapp.com/send?phone=923430505458&text=Welcome%20to%20Staywo,%20How%20can%20we%20help%20you?"
                        target="_blank"
                      >
                        Whatsapp
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>

          {handleIsShow() && checkConditionAction() && (
            <div className="action_button_area">
              <button onClick={handleAction} className="action_button">
                <span>Action required</span> <WarningRoundedIcon />
              </button>
            </div>
          )}
        </div>
      </header>
    </>
  );
};

export default Header;

const AddPropertyButton = styled.a`
  float: right;
  padding: 0 20px !important;
  height: 40px !important;
  line-height: 40px;
  color: #fff;
  border-radius: 4px;
  font-weight: 600;
  position: relative;
  top: 15px;
  font-size: 12px;
  margin-left: 34px;
  background: #0095ff;

  @media (max-width: 768px) {
    background: none;
    margin-top: 5px;
    padding-right: 0px !important;
  }
`;
