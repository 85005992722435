import { Backdrop } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { verifyEmail } from '../../../services/User/verifyEmail';

const AuthVerifyEmail = () => {
  const { token } = useParams();
  const { email } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const verifyAuthPass = async () => {
      await verifyEmail(token, email)
        .then(() => {
          navigate('/login?verified=true');
        })
        .catch(() => navigate('/login?verified=false'));
    };
    verifyAuthPass();
  }, [token, email]);
  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      ></Backdrop>
    </div>
  );
};

export default AuthVerifyEmail;
