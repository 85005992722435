import React from "react";
import Map from "../map";
import ContactForm from "./ContactForm";

export const ContactContent = () => {
  return (
    <section className="gray-bg small-padding">
      <div className="container">
        <div className="row">
          {/* <!-- services-item --> */}
          <div className="col-md-4">
            <div className="services-item fl-wrap">
              <i className="fal fa-envelope"></i>
              <h4>
                Our Mails <span>01</span>
              </h4>
              <p>We look forward to hearing from you and being of any help. </p>
              <a href="mailto:staywo5g@gmail.com" className="serv-link sl-b">
                staywo5g@gmail.com
              </a>
            </div> 
          </div>
          {/* <!-- services-item  end--> */}
          {/* <!-- services-item --> */}
          <div className="col-md-4">
            <div className="services-item fl-wrap">
              <i className="fal fa-phone-rotary"></i>
              <h4>
                Our Phones<span>02</span>
              </h4>
              <p>Staywo is happy to answer questions by the phone. </p>
              <a href="tel:+1 (916) 233 5033" className="serv-link sl-b">
                +1 (916) 233 5033
              </a>
            </div>
          </div>
          {/* <!-- services-item  end--> */}
          {/* <!-- services-item --> */}
          <div className="col-md-4">
            <div className="services-item fl-wrap">
              <i className="fal fa-map-marked"></i>
              <h4>
                Our Address <span>03</span>
              </h4>
              <a href="#" className="serv-link sl-b">
                P.O. Box 246863<br/> Sacramento CA 95824<br/> USA
              </a>
            </div>
          </div>
          {/* <!-- services-item  end-->								 */}
        </div>
        <div className="clearfix"></div>
        <div className="contacts-opt fl-wrap">
          <div className="contact-social">
            <span className="cs-title">Find us on: </span>
            <ul>
              <li>
                <a href="https://www.facebook.com/profile.php?id=100090126948188&mibextid=ZbWKwL" target="_blank">
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a href="#https://www.youtube.com/@staywo" target="_blank">
                  <i className="fab fa-youtube"></i>
                </a>
              </li>
              <li>
                <a href="https://instagram.com/staywopakistan?igshid=ZDdkNTZiNTM=" target="_blank">
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
              {/* <li>
                <a href="#" target="_blank">
                  <i className="fab fa-vk"></i>
                </a>
              </li> */}
            </ul>
          </div>
          <a href="#" className="btn small-btn float-btn color-bg cf_btn">
            Write Mesagge
          </a>
          {/* <div className="contact-notifer">
            Or visit our <a href="help.html"> help page</a>
          </div> */}
        </div>

        <ContactForm/>

        {/* <!--box-widget  -->			 */}
        {/* <div className="box-widget">
          <div className="box-widget-title single_bwt fl-wrap   ">
            Office Location
          </div>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas in
            pulvinar neque. Nulla finibus lobortis pulvinar. Donec a consectetur
            nulla.
          </p>
          {/* <!--box-widget end--> */}
        {/* </div> */}
        {/* <!--box-widget--> */}
        {/* <div className="box-widget fl-wrap">
          <div className="map-widget contacts-map fl-wrap">
            <PropertyMap />
          </div>
        </div> */}
        {/* <!--box-widget end --> 									 */}
      </div>
    </section>
  );
};

const PropertyMap = ({ lat, lng, address }) => {
  let mapCenter = [
    {
      lat: lat,
      lng: lng,
      address: address,
    },
  ];

  return (
    <div className="map-container mapC_vis mapC_vis2">
      <div style={{ height: 250 }} className="drag-map">
        <Map mapValues={mapCenter} />
      </div>
    </div>
  );
};
