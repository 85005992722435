import { API_URL } from '../../config';
import { axiosPrivate } from '../../api/axios';
const addUserAddressApi = async (data) => {
  try {
    const response = await axiosPrivate.post(API_URL + `user-address`, data);
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('bad response of pass address add: ', response);
      return response;
    }
  }
};
export default addUserAddressApi;
