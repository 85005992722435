import * as yup from 'yup';

//Basic Info Validation
export const BasicInfoValidationSchema = yup.object({
  // spaceId: yup.string().required('Space is required'),
  // spaceTypeId: yup.string().required('Space type is required'),
  // propertyType: yup.string().required('Type is required'),
  title: yup.string().required('Listing title is required'),
  flood: yup.boolean(),
  // description: yup.string().required('Listing description is required'),
});

//Detail Validation
export const DetailValidationSchema = yup.object({
  noOfAdults: yup
    .number()
    .required('Number of adults is required')
    .min(1, 'Number should be greater then 1'),
  noOfChildren: yup
    .number()
    .required('Number of children is required')
    .min(0, 'Number should be greater then 1'),
  noOfBedrooms: yup
    .number()
    .required('Number of bedrooms is required')
    .min(1, 'Number should be greater then 1'),
  noOfBathrooms: yup
    .number()
    .required('Number of bathrooms is required')
    .min(1, 'Number should be greater then 1'),
  noOfBeds: yup
    .string()
    .min(1, 'Number should be greater then 1')
    .required('Number of beds is required'),
  isPrivateBathroom: yup
    .string()
    .required('Private bathroom field is required.'),
});

//Location Validation
export const LocationValidationSchema = yup.object({
  // zipCode: yup.string().required('Zipcode is required'),
  address: yup.string().required('Address is required'),
  country: yup.string().required('Country is required'),
  state: yup.string().required('State is required'),
  city: yup.string().required('City is required'),
});

//Media Validation
export const MediaValidationSchema = yup.object({
  thumbnail: yup.array().of(
    yup.object().shape({
      name: yup.string().required('thumbnail is required'),
      // Rest of your amenities object properties
    })
  ),
});

//Price Validation
export const PriceValidationScheme = yup.object({
  perNightPrice: yup
    .number()
    .required('Per night price is required')
    .min(1, 'Price should be at least 5'),
});
