import { Grid } from '@mui/material';
import Accessibility from '../Miscellaneous/Accessibility';
import Facility from '../Miscellaneous/Facility';
import NearbyActivity from '../Miscellaneous/NearbyActivity';
import SafetyFeature from '../Miscellaneous/SafetyFeature';
import SecurityFeature from '../Miscellaneous/SecurityFeature';
const Miscellaneous = ({
  handleCheckbox,
  getFacility,
  getAccessibility,
  getNearbyActivity,
  getSecurityFeature,
  getSafetyFeature,
  selectAccessibilties,
  selectFacilities,
  selectSecurityFeature,
  selectNearbyActivity,
  selectSafetyFeature,
}) => {
  return (
    <>
      <div className='dasboard-widget-title fl-wrap'>
        <h5>
          <i className></i>
          All Miscellaneous
        </h5>
      </div>
      <div className='dasboard-widget-box fl-wrap'>
        <div>
          <Grid container>
            <Accessibility
              handleCheckbox={selectAccessibilties}
              getAccessibility={getAccessibility}
            />
            <Facility
              handleCheckbox={selectFacilities}
              getFacility={getFacility}
            />
            <SecurityFeature
              handleCheckbox={selectSecurityFeature}
              getSecurityFeature={getSecurityFeature}
            />
            <NearbyActivity
              handleCheckbox={selectNearbyActivity}
              getNearbyActivity={getNearbyActivity}
            />
            <SafetyFeature
              handleCheckbox={selectSafetyFeature}
              getSafetyFeature={getSafetyFeature}
            />
          </Grid>
        </div>
      </div>
    </>
  );
};
export default Miscellaneous;
