import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Input from '../../../../components/form/input';
import Card from '../../../../components/dasboard/card';
import Typography from '@mui/material/Typography';
import { Editor } from 'react-draft-wysiwyg';
import './textarea.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Box } from '@mui/system';
import { Checkbox, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import Select from 'react-select';

const BasicInfo = ({
  spaceValues,
  spaceTypeValues,
  areaValues,
  formik,
  editorState,
  handleEditorChange,
  spaceVal,
  setSpaceVal,
  getSpaceType,
  setSpaceTypeVal,
  spaceTypeVal,
  validationType,
  areaVal,
  setAreaVal,
  propertyType,
  setPropertyType,
  convertedContent,
  setSpaceTypeValues,
  temporaryOptions,
  inputValues,
  handleChangeInput,
}) => {
  const [propertyDescLentgth, setPropertyDescLength] = useState(0);

  const handleChange = (selectedOption) => {
    setSpaceVal(selectedOption);
    let temppppp = [...temporaryOptions];
    let ress = temppppp.findIndex((item) => {
      return item.type.value == selectedOption.value;
    });
    if (temppppp.length > 0) {
      if (ress == -1) {
        getSpaceType(selectedOption);
      } else {
        setSpaceTypeValues(temppppp[ress].data);
      }
    } else getSpaceType(selectedOption);
  };
  let maxLength = 1999;
  const textLength = editorState.getCurrentContent().getPlainText().length;
  const handleBeforeInput = (val) => {
    if (textLength <= maxLength) {
      return 'not-handled';
    } else {
      return 'handled';
    }
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: 'black',
      fontSize: '16px',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      const color = '#fff';
      return { ...provided, opacity, transition, color };
    },
  };

  React.useEffect(() => {
    if (convertedContent) {
      let cDesc = convertedContent
        ?.replace('<p>', '')
        .replace('</p>', '')
        .replace('&amp;', '');
      setPropertyDescLength(cDesc?.length);
    }
  }, [convertedContent]);

  return (
    <>
      <Card withIcon title={'Property Detail'} icon={'fa fa-info'}>
        <Box width="100%" />
        <Grid style={{ display: 'block', padding: '18px' }}>
          <Typography
            sx={{ color: 'red', fontWeight: 'bold', fontSize: '18px' }}
          >
            Add your property in Disaster management camp?
          </Typography>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="floodRelief"
            onChange={handleChangeInput}
            value={inputValues.floodRelief}
          >
            <FormControlLabel
              value={true}
              control={<Radio />}
              label="Yes"
              sx={{ color: '#fff', fontWeight: 'bold' }}
            />
            <FormControlLabel
              value={false}
              control={<Radio />}
              label="No"
              sx={{ color: '#fff', fontWeight: 'bold' }}
            />
          </RadioGroup>
          {validationType.open && validationType.type == 'floodRelief' && (
              <p style={{ color: 'red' }}>{validationType.message}</p>
            )}
        </Grid>
        <Grid
          container
          rowSpacing={{ xs: 1, sm: 1, md: 2 }}
          columnSpacing={{ xs: 1, sm: 1, md: 2 }}
        >
          <Grid item xs={12} sm={12} md={4}>
            <Select
              className="override-select"
              placeholder="Select Space"
              options={spaceValues}
              value={spaceVal}
              onChange={handleChange}
              styles={customStyles}
            />
            {validationType.open && validationType.type == 'space' && (
              <p style={{ color: 'red' }}>{validationType.message}</p>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Select
              className="override-select"
              placeholder="Select Space Type"
              options={spaceTypeValues}
              value={spaceTypeVal}
              onChange={(option) => setSpaceTypeVal(option)}
              styles={customStyles}
            />
            {validationType.open && validationType.type == 'spaceType' && (
              <p style={{ color: 'red' }}>{validationType.message}</p>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Select
              className="override-select"
              placeholder="Select Area"
              options={areaValues}
              value={areaVal}
              onChange={(option) => setAreaVal(option)}
              styles={customStyles}
            />
            {validationType.open && validationType.type == 'area' && (
              <p style={{ color: 'red' }}>{validationType.message}</p>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Select
              className="override-select"
              placeholder="Select Property Type"
              options={[
                { label: 'Private', value: 'Private' },
                {
                  label: 'Commercial',
                  value: 'Commercial',
                },
              ]}
              value={propertyType}
              styles={customStyles}
              onChange={(option) => setPropertyType(option)}
            />
            {validationType.open && validationType.type == 'propertyType' && (
              <p style={{ color: 'red' }}>{validationType.message}</p>
            )}
          </Grid>
        </Grid>
      </Card>
      <Card>
        <Grid
          container
          rowSpacing={{ xs: 1, sm: 1, md: 2 }}
          columnSpacing={{ xs: 1, sm: 1, md: 2 }}
        >
          <Grid item xs={12} sm={12} md={12}>
            <Typography>
              Listing Title ({50 - inputValues.title.length} characters
              remaining out of 50)
            </Typography>
            <Typography style={{fontSize:12}}>Your listing title should highlight what makes your place special. Review listing title guidelines.</Typography>
            <Input
              id={'title'}
              name={'title'}
              label={`Listing Title`}
              onChange={handleChangeInput}
              value={inputValues.title}
              maxLength={50}
            />
            {validationType.open && validationType.type == 'title' && (
              <p style={{ color: 'red' }}>{validationType.message}</p>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography>
              Property Description ({2000 - textLength} characters
              remaining out of {2000}){' '}
            </Typography>
            <Typography style={{fontSize:12}}>Give guests a sense of what it’s like to stay at your place, including why they’ll love staying there.</Typography>
            <Editor
              editorState={editorState && editorState}
              id={'description'}
              name={'description'}
              onEditorStateChange={handleEditorChange && handleEditorChange}
              wrapperClassName="wrapper--class"
              editorClassName="editor--class"
              toolbarClassName="toolbar--class"
              placeholder={'Property Description'}
              stripPastedStyles={true}
              handleBeforeInput={(val) => handleBeforeInput(val)}
              handlePastedText={(val) => {
                const textLength = editorState
                  .getCurrentContent()
                  .getPlainText().length;
                return val.length + textLength >= maxLength;
              }}
            />

            {formik.touched.description && Boolean(formik.errors.description) && (
              <small
                id="username2-help"
                className="p-error"
                style={{
                  display: 'block',
                  color: 'red',
                  textAlign: 'left',
                  marginBottom: '10px',
                }}
              >
                {formik.touched.description && formik.errors.description}
              </small>
            )}
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default BasicInfo;
