//yup and Formik are used for validations
import * as yup from 'yup'

export const ForgetPasswordValidationSchema = yup.object({
  password: yup.string().required('Password is required'),
  confirmPassword: yup.string().when('password', {
    is: (val) => (val && val.length > 0 ? true : false),
    then: yup
      .string()
      .oneOf([yup.ref('password')], 'Both password need to be the same'),
  }),
})
