import React, { useState } from 'react';
import InfoBar from '../../../components/dasboard/infoBar';
import Sidebar from '../../../components/dasboard/sidebar';
import Header from '../../../components/header';
import { Box, InputLabel, Typography, TextField } from '@mui/material';
import Buttons from '../../../components/form/button';
import { useFormik } from 'formik';
import { addCardApi } from '../../../services/wallet/addCard';
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { AiOutlineLeft } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { options } from './../../../constants/countries';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const AddCardPage = () => {
  const userData = JSON.parse(localStorage.getItem('userData'));
  const [loading, setLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState({});
  const toast = React.useRef(null);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      name: '',
      number: '',
      exp_month: '',
      exp_year: '',
      cvc: '',
      user_id: userData?.id,
      address1: '',
      address2: '',
      zipcode: '',
      city: '',
      state: '',
    },
    validationSchema: () => {
      return yup.object({
        name: yup.string().required('Please enter your name!'),
        number: yup
          .string()
          .min(16)
          .max(16)
          .required('Please enter your card number!'),
        exp_month: yup
          .string()
          .required('Please enter your card expiry month!'),
        exp_year: yup.string().required('Please enter your card expiry year!'),
        cvc: yup.string().min(3).max(4).required('Please enter your card CVC!'),
        address1: yup.string().required('Street Address is required!'),
        address2: yup.string().required('Apt or suite number is required!'),
        city: yup.string().required('City is required!'),
        zipcode: yup.string().required('Zip Code is required!'),
        state: yup.string().required('State is required!'),
      });
    },
    onSubmit: async (values) => {
      setLoading(true);
      const data = { ...values, country: selectedCountry?.name };
      const res = await addCardApi(data);
      if (res.status === 200 || res.status === 201) {
        setLoading(false);
        toast.current.show({
          severity: 'success',
          summary: 'Card Added Successfully!',
          detail: 'Your card is added successfully!',
          life: 10000,
        });
        setTimeout(() => {
          navigate(`/admin/your-cards`);
        }, 2000);
      } else {
        setLoading(false);
        toast.current.show({
          severity: 'error',
          summary: 'Cannot add your card!',
          detail: `${res?.data?.message}`,
          life: 10000,
        });
      }
    },
  });

  //Country options dropdown templates
  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className='country-item country-item-value'>
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className='country-item'>
        <div>{option.name}</div>
      </div>
    );
  };
  return (
    <>
      <Toast ref={toast} position='bottom-right' />
      <Header />
      <div id='wrapper'>
        <div className='dashboard-menu-overlay' />
        <Sidebar page={'My Earnings'} />
        <div className='dashboard-content'>
          <div className='dashboard-menu-btn color-bg'>
            <span>
              <i className='fas fa-bars' />
            </span>
            Add Card
          </div>
          <ArrowBackIosIcon
            onClick={() => navigate(-1)}
            style={{ display: 'flex', color: 'white', cursor: 'pointer' }}
          />

          <InfoBar label={'Add Card'} />

          <div className='dasboard-wrapper fl-wrap no-pag'>
            <div>
              <div className='clearfix' />
              <div className='row'>
                <div className='col-md-8'>
                  <div
                    className='dasboard-content fl-wrap'
                    style={{ margin: '10px 0px' }}
                  >
                    <Box sx={{ p: 3 }}>
                      <Box>
                        <Box
                          sx={{
                            padding: '25px 0px',
                          }}
                        >
                          <Link
                            to='/admin/your-cards'
                            style={{
                              display: 'flex',
                              justifyContent: 'start',
                              alignItems: 'center',
                              flexDirection: 'row-reverse',
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <i
                                className='fal fa-credit-card'
                                style={{
                                  marginRight: '8px',
                                  fontSize: '20px',
                                  color: '#0096ff',
                                }}
                              ></i>
                              <div
                                style={{
                                  fontSize: '18px',
                                  color: 'white',
                                }}
                              >
                                Checkout Your Added Cards
                              </div>
                            </Box>
                            <AiOutlineLeft
                              style={{
                                fontSize: '18px',
                                color: '#fff',
                                marginRight: '6px',
                              }}
                            />
                          </Link>
                        </Box>
                      </Box>
                      <Box sx={{ display: 'flex' }}>
                        <Typography
                          sx={{
                            marginRight: '5px',
                            fontSize: '22px',
                            color: '',
                          }}
                        ></Typography>
                        <Typography sx={{ fontSize: '22px', color: '#fff' }}>
                          Enter your card details.
                        </Typography>
                      </Box>

                      <Box>
                        <InputLabel
                          htmlFor='amount'
                          sx={{ margin: '10px 0px' }}
                        >
                          Enter your name here. (As appear on your card)
                        </InputLabel>
                        <TextField
                          id='outlined-basic'
                          variant='outlined'
                          fullWidth
                          name='name'
                          placeholder='Name'
                          value={formik.values.name}
                          onChange={formik.handleChange}
                        />
                        {formik.touched.name && Boolean(formik.errors.name) && (
                          <small
                            id='username2-help'
                            className='p-error'
                            style={{
                              display: 'block',
                              color: 'red',
                              textAlign: 'left',
                            }}
                          >
                            {formik.touched.name && formik.errors.name}
                          </small>
                        )}
                      </Box>
                      <Box>
                        <InputLabel
                          htmlFor='amount'
                          sx={{ margin: '10px 0px' }}
                        >
                          Enter your card number here.
                        </InputLabel>
                        <TextField
                          id='outlined-basic'
                          placeholder='Card Number (16 Characters)'
                          variant='outlined'
                          fullWidth
                          name='number'
                          type='number'
                          value={formik.values.number}
                          onChange={formik.handleChange}
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 16);
                          }}
                        />
                        {formik.touched.number &&
                          Boolean(formik.errors.number) && (
                            <small
                              id='username2-help'
                              className='p-error'
                              style={{
                                display: 'block',
                                color: 'red',
                                textAlign: 'left',
                              }}
                            >
                              {formik.touched.number && formik.errors.number}
                            </small>
                          )}
                      </Box>
                      <Box>
                        <InputLabel
                          htmlFor='amount'
                          sx={{ margin: '10px 0px' }}
                        >
                          Enter your card's expiry month here.
                        </InputLabel>
                        <TextField
                          id='outlined-basic'
                          placeholder='Expiry Month (eg: 20)'
                          variant='outlined'
                          fullWidth
                          type='number'
                          name='exp_month'
                          value={formik.values.exp_month}
                          onChange={formik.handleChange}
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 2);
                          }}
                        />
                        {formik.touched.exp_month &&
                          Boolean(formik.errors.exp_month) && (
                            <small
                              id='username2-help'
                              className='p-error'
                              style={{
                                display: 'block',
                                color: 'red',
                                textAlign: 'left',
                              }}
                            >
                              {formik.touched.exp_month &&
                                formik.errors.exp_month}
                            </small>
                          )}
                      </Box>
                      <Box>
                        <InputLabel
                          htmlFor='amount'
                          sx={{ margin: '10px 0px' }}
                        >
                          Enter your Card's expiry year here.
                        </InputLabel>
                        <TextField
                          id='outlined-basic'
                          placeholder='Expiry Year (eg: 2025)'
                          variant='outlined'
                          fullWidth
                          type='number'
                          name='exp_year'
                          value={formik.values.exp_year}
                          onChange={formik.handleChange}
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 4);
                          }}
                        />
                        {formik.touched.exp_year &&
                          Boolean(formik.errors.exp_year) && (
                            <small
                              id='username2-help'
                              className='p-error'
                              style={{
                                display: 'block',
                                color: 'red',
                                textAlign: 'left',
                              }}
                            >
                              {formik.touched.exp_year &&
                                formik.errors.exp_year}
                            </small>
                          )}
                      </Box>
                      <Box>
                        <InputLabel
                          htmlFor='amount'
                          sx={{ margin: '10px 0px' }}
                        >
                          Enter your card's CVC here.
                        </InputLabel>
                        <TextField
                          id='outlined-basic'
                          placeholder='CVC (eg: 1234)'
                          variant='outlined'
                          fullWidth
                          type='number'
                          name='cvc'
                          value={formik.values.cvc}
                          onChange={formik.handleChange}
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 4);
                          }}
                        />
                        {formik.touched.cvc && Boolean(formik.errors.cvc) && (
                          <small
                            id='username2-help'
                            className='p-error'
                            style={{
                              display: 'block',
                              color: 'red',
                              textAlign: 'left',
                            }}
                          >
                            {formik.touched.cvc && formik.errors.cvc}
                          </small>
                        )}
                      </Box>
                      <Typography
                        sx={{
                          fontSize: '22px',
                          color: '#fff',
                          margin: '15px 0px',
                        }}
                      >
                        Billing Address
                      </Typography>
                      <Box>
                        <InputLabel
                          htmlFor='amount'
                          sx={{ margin: '10px 0px' }}
                        >
                          Enter your Street Address here.
                        </InputLabel>
                        <TextField
                          id='outlined-basic'
                          placeholder='Street Address'
                          variant='outlined'
                          fullWidth
                          name='address1'
                          value={formik.values.address1}
                          onChange={formik.handleChange}
                        />
                        {formik.touched.address1 &&
                          Boolean(formik.errors.address1) && (
                            <small
                              id='username2-help'
                              className='p-error'
                              style={{
                                display: 'block',
                                color: 'red',
                                textAlign: 'left',
                              }}
                            >
                              {formik.touched.address1 &&
                                formik.errors.address1}
                            </small>
                          )}
                      </Box>
                      <Box>
                        <InputLabel
                          htmlFor='amount'
                          sx={{ margin: '10px 0px' }}
                        >
                          Enter your Apt or Suite Number here.
                        </InputLabel>
                        <TextField
                          id='outlined-basic'
                          placeholder='Apt Or Suite Number'
                          variant='outlined'
                          fullWidth
                          name='address2'
                          value={formik.values.address2}
                          onChange={formik.handleChange}
                        />
                        {formik.touched.address2 &&
                          Boolean(formik.errors.address2) && (
                            <small
                              id='username2-help'
                              className='p-error'
                              style={{
                                display: 'block',
                                color: 'red',
                                textAlign: 'left',
                              }}
                            >
                              {formik.touched.address2 &&
                                formik.errors.address2}
                            </small>
                          )}
                      </Box>
                      <Box>
                        <InputLabel
                          htmlFor='amount'
                          sx={{ margin: '10px 0px' }}
                        >
                          Enter your city here.
                        </InputLabel>
                        <TextField
                          id='outlined-basic'
                          placeholder='City'
                          variant='outlined'
                          fullWidth
                          name='city'
                          value={formik.values.city}
                          onChange={formik.handleChange}
                        />
                        {formik.touched.city && Boolean(formik.errors.city) && (
                          <small
                            id='username2-help'
                            className='p-error'
                            style={{
                              display: 'block',
                              color: 'red',
                              textAlign: 'left',
                            }}
                          >
                            {formik.touched.city && formik.errors.city}
                          </small>
                        )}
                      </Box>
                      <Box>
                        <InputLabel
                          htmlFor='amount'
                          sx={{ margin: '10px 0px' }}
                        >
                          Enter your state here.
                        </InputLabel>
                        <TextField
                          id='outlined-basic'
                          placeholder='State'
                          variant='outlined'
                          fullWidth
                          name='state'
                          value={formik.values.state}
                          onChange={formik.handleChange}
                        />
                        {formik.touched.state &&
                          Boolean(formik.errors.state) && (
                            <small
                              id='username2-help'
                              className='p-error'
                              style={{
                                display: 'block',
                                color: 'red',
                                textAlign: 'left',
                              }}
                            >
                              {formik.touched.state && formik.errors.state}
                            </small>
                          )}
                      </Box>
                      <Box>
                        <InputLabel
                          htmlFor='amount'
                          sx={{ margin: '10px 0px' }}
                        >
                          Enter your zip code here.
                        </InputLabel>
                        <TextField
                          id='outlined-basic'
                          placeholder='Zip Code (eg: 12345)'
                          variant='outlined'
                          fullWidth
                          name='zipcode'
                          type='number'
                          value={formik.values.zipcode}
                          onChange={formik.handleChange}
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 5);
                          }}
                        />
                        {formik.touched.zipcode &&
                          Boolean(formik.errors.zipcode) && (
                            <small
                              id='username2-help'
                              className='p-error'
                              style={{
                                display: 'block',
                                color: 'red',
                                textAlign: 'left',
                              }}
                            >
                              {formik.touched.zipcode && formik.errors.zipcode}
                            </small>
                          )}
                      </Box>
                      <Box>
                        <InputLabel
                          htmlFor='amount'
                          sx={{ margin: '10px 0px' }}
                        >
                          Select your country
                        </InputLabel>
                        <Dropdown
                          value={selectedCountry}
                          options={options}
                          onChange={(e) => setSelectedCountry(e.value)}
                          optionLabel='name'
                          filter
                          showClear
                          filterBy='name'
                          name='country'
                          placeholder='Country/region'
                          valueTemplate={selectedCountryTemplate}
                          itemTemplate={countryOptionTemplate}
                          style={{
                            width: '100%',
                            background: '#1b182b',
                            borderColor: '#504d5c',
                            padding: '4.5px 0',
                          }}
                        />
                        {formik.touched.country &&
                          Boolean(formik.errors.country) && (
                            <small
                              id='username2-help'
                              className='p-error'
                              style={{
                                display: 'block',
                                color: 'red',
                                textAlign: 'left',
                              }}
                            >
                              {formik.errors.country}
                            </small>
                          )}
                      </Box>
                    </Box>
                    <Box>
                      <Buttons
                        isLoading={loading}
                        title={'Add Card'}
                        style={{ width: '50%', margin: '15px 0px' }}
                        onSubmit={formik.handleSubmit}
                      />
                    </Box>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCardPage;
