import React, { useState, useEffect, useRef } from 'react';
import { useSnackbar } from 'notistack';
import Header from '../../../components/header';
import ReservationDetailCard from '../../../components/ReservationDetailCard/ReservationDetailCard';
import SpecialOfferModal from '../../../components/modal/SpecialOfferModal';
import { getAllContacts, getUsersChats } from '../../../services/Chat/chat';
import moment from 'moment';
import io from 'socket.io-client';
import ProgressBar from 'react-animated-progress-bar';
import { SOCKET_URL } from '../../../config';
import { createSpecialOffer } from '../../../services/SpecialOffer/createSpecialOffer';
import ChatItem from './ChatItem';
import { useParams } from 'react-router-dom';
import { ImAttachment } from 'react-icons/im';
import { IoIosChatboxes } from 'react-icons/io';
import { Toast } from 'primereact/toast';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Grid, Typography } from '@mui/material';
import { getChatTemplatesApi } from '../../../services/Chat/getChatTemplates';
import Buttons from '../../../components/form/button';
import { borderRadius } from '@mui/system';

let socket = null;
export default function MessagesPage() {
  const { enqueueSnackbar } = useSnackbar();
  const [chatData, setChatData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const userData = JSON.parse(localStorage.getItem('userData'));
  const [msgVal, setMsgVal] = useState('');
  const [allMessages, setAllMessage] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [msgsLoading, setMsgsLoading] = useState(false);
  const [latestMsg, setLatestMsg] = useState(null);
  const [data, setdata] = useState({});
  const [isOpenSpecialOfferModal, setIsOpenSpecialOfferModal] = useState(false);
  const [isSpecialOfferFormLoading, setIsSpecialOfferFormLoading] =
    useState(false);
  const { id } = useParams();
  const [messageImages, setMessageImages] = useState([]);
  const [messageImagesUrls, setMessageImagesUrls] = useState([]);
  const [msgFileImages, setMsgFileImages] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  const [msgsCountHandler, setMsgsCountHandler] = useState({
    count: 0,
    chatId: null,
  });

  const isHost = userData?.id === data?.propertyOwner?.id;

  const divRef = useRef(null);

  useEffect(() => {
    socket = io(SOCKET_URL, {
      forceNew: true,
      cors: {
        origin: '*',
      },
      transports: ['websocket'],
    });
  }, []);

  // callback socket room connector when user will click on particular chat
  useEffect(() => {
    roomEmitter();
  }, [selectedUser]);

  const handleSetNewMsgs = (result) => {
    let tempArr = [...allMessages, result];
    setAllMessage(tempArr);
  };

  useEffect(() => {
    if (latestMsg !== null) handleSetNewMsgs(latestMsg);
  }, [latestMsg]);

  useEffect(() => {
    setIsLoading(true);
    getAllContacts()
      .then((res) => {
        setIsLoading(false);
        const tempChat = removeDuplicates(res.data.data);
        const removeDupUsers = removeSameUsers(tempChat);
        setChatData(removeDupUsers);
        setTimeout(() => {
          const element = document.getElementById('scroll');
          element.scrollTo({
            top: divRef.current.offsetTop,
            behavior: 'smooth',
          });
        }, 1000);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (chatData?.length > 0 && id) {
      const selectedItem = chatData.find((item) => {
        return item.user_1 == id;
      });
      if (selectedItem) {
        handleGetUserChat(selectedItem);
      } else {
        handleGetUserChat(chatData[0]);
      }
    } else if (chatData?.length > 0) {
      handleGetUserChat(chatData[0]);
    }
  }, [chatData]);

  const handleGetUserChat = (item) => {
    setSelectedUser(item);
    setMsgsLoading(true);
    getUsersChats(item.id)
      .then((res) => {
        // const chat = chatData.filter((user) => user.id === res.data.id);
        setdata(res.data);
        setMsgsLoading(false);
        setAllMessage(res.data.messages);
        setMsgsCountHandler({
          count: 0,
          chatId: null,
        });
        setTimeout(() => {
          const element = document.getElementById('scroll');
          element.scrollTo({
            top: divRef.current.offsetTop,
            behavior: 'smooth',
          });
        }, 1000);
      })
      .catch((err) => {
        setMsgsLoading(false);
      });
  };

  // REFACTORING ROOM MESSAGE EMITTER FOR SOCKET
  const roomEmitter = () => {
    if (selectedUser?.id) {
      socket.on(`message-in-chat-${selectedUser?.id}`, (res) => {
        setMsgVal('');
        setMessageImages([]);
        setMessageImagesUrls([]);
        setLatestMsg(res);
        setTimeout(() => {
          const element = document.getElementById('scroll');
          element.scrollTo({
            top: divRef.current.offsetTop,
            behavior: 'smooth',
          });
        }, 100);
        // const newCount = msgsCountHandler.count + 1;
        const newCount = 1;
        const showUserId = selectedUser?.id;

        setMsgsCountHandler({
          count: newCount,
          chatId: showUserId,
        });
      });
    }
  };

  // get uniques chat data
  const removeDuplicates = (arr) => {
    return arr?.filter(
      (v, i, a) =>
        a.findIndex((v2) =>
          ['user_1', 'user_2'].every((k) => v2[k] === v[k])
        ) === i
    );
  };

  // remove same user repeat from chat array
  const removeSameUsers = (arr) => {
    return arr?.filter((item) => item.user_1 !== item.user_2);
  };

  // HANDLE SPECIAL OFFER MODAL
  const openSpecialOfferModal = () => {
    setIsOpenSpecialOfferModal(true);
  };

  // HANDLE SUBMIT SPECIAL DATA FORM
  const submitSpecialOffer = async (formData) => {
    setIsSpecialOfferFormLoading(true);
    const apiResponse = await createSpecialOffer(formData);
    if (apiResponse) {
      setIsSpecialOfferFormLoading(false);
    } else {
      enqueueSnackbar('Create offer api request fail', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
      setIsSpecialOfferFormLoading(false);
    }
  };

  // TOP DATE FORMATTER
  const renderDateOnTop = (date) => {
    //here we were subtracting our date from current time which will be in milliseconds
    const dateDifferenceInTime =
      new Date().getTime() - new Date(date).getTime();

    // conerting milli seconds to days
    // (1000 milliseconds * (60 seconds * 60 minutes) * 24 hours)
    const dateDifferenceInDays = dateDifferenceInTime / (1000 * 60 * 60 * 24);
    //After returning in particular formats as of our convinent
    if (dateDifferenceInDays < 1) {
      return 'Today';
    } else if (dateDifferenceInDays < 2) {
      return 'Yesterday'; // just YesterDay
    } else if (dateDifferenceInDays <= 7) {
      return moment(date).format('dddd'); //like monday , tuesday , wednesday ....
    } else {
      return moment(date).format('MMM d, YYYY'); // if it was more than a week before it will returns as like December 2,2022
    }
  };

  // GROUPING MESSAGES DATE WISE
  const messgaeGrouping = (chats) => {
    const groups = chats.reduce((groups, item) => {
      let data;
      if (item?.messageType === 'message') {
        data = renderDateOnTop(item.createdAt);
      }
      if (!groups[data]) {
        groups[data] = [];
      }
      groups[data].push(item);
      return groups;
    }, {});
    return groups;
  };

  let toast = useRef(null);
  let handleAttachmentChange = (e) => {
    let imageUrls = [];
    let messageImages = [];
    let fileImages = [];
    if (e.target.files.length > 2) {
      toast.current.show({
        severity: 'info',
        summary: 'Images limit exceeded',
        detail: 'You can only select 2 images maximum.',
        life: 10000,
      });
      return;
    } else {
      Object.values(e.target.files).forEach((file) => {
        fileImages.push(file);
        var fileReader = new FileReader();
        var imageFile = file;
        const imageSrc = URL.createObjectURL(file);
        imageUrls.push(imageSrc);
        fileReader.readAsDataURL(imageFile);
        fileReader.onload = function (fileLoadedEvent) {
          var srcData = fileLoadedEvent.target.result;
          messageImages.push(srcData);
        };
      });
      setMessageImagesUrls(imageUrls);
      setMessageImages(messageImages);
      setMsgFileImages(fileImages);
    }
  };
  const clearMessageImages = (index) => {
    let imgs = [...messageImages];
    imgs.splice(index, 1);
    setMessageImages(imgs);
    let imgsUrls = [...messageImagesUrls];
    imgsUrls.splice(index, 1);
    setMessageImagesUrls(imgsUrls);
  };

  const handleSendMessage = () => {
    if (msgVal == '') {
      enqueueSnackbar('Please enter message to send', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    } else {
      const messageData = [
        {
          senderId: userData?.id,
          chatId: selectedUser.id,
          content: msgVal,
          filenames: msgFileImages?.map((image) => {
            return image.name;
          }),
        },
        ...messageImages,
      ];

      let payload = {
        message: messageData,
      };

      socket.emit('incoming-message', payload.message);
      roomEmitter();
    }
  };

  const [chatTemplates, setChatTemplates] = useState([]);
  React.useEffect(() => {
    (async () => {
      let response = await getChatTemplatesApi();
      setChatTemplates(response.data?.data);
    })();
  }, []);

  return (
    <>
      <Toast ref={toast} position='bottom-right' />
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
        sx={{
          '& .MuiPaper-root': {
            overflowX: 'hidden',
          },
        }}
      >
        <DialogTitle
          id='scroll-dialog-title'
          sx={{
            fontFamily: 'Jost',
            fontSize: '25px',
            borderBottom: '0.5px solid #0095ff',
            background: '#17212f',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography sx={{ fontFamily: 'Jost', fontSize: '25px' }}>
              Quick Replies
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent
          dividers={scroll === 'paper'}
          sx={{ height: '90vh', width: '32vw', background: '#1c172b' }}
        >
          <Grid conatiner>
            {chatTemplates?.map((template) => {
              return (
                <Grid
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{
                    margin: '20px 0px',
                    padding: '8px',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Typography
                    sx={{ fontFamily: 'Jost', fontSize: '20px', color: '#fff' }}
                  >
                    {template?.templateKey}
                  </Typography>
                  <Typography sx={{ fontFamily: 'Jost', fontSize: '16px' }}>
                    {template?.templateValue}
                  </Typography>
                  <Buttons
                    title={'Use this reply'}
                    isLoading={false}
                    style={{ width: '50%' }}
                    variant='outlined'
                    onSubmit={() => {
                      setMsgVal(template?.templateValue);
                      setOpen(false);
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            fontFamily: 'Jost',
            fontSize: '25px',
            background: '#17212f',
          }}
        >
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <Header />
      <div id='wrapper'>
        <div className='dashboard-menu-overlay' />
        <div className='dashboard-content'>
          <div className='dashboard-menu-btn color-bg'>
            <span>
              <i className='fas fa-bars' />
            </span>
            Dashboard Menu
          </div>

          <div style={{ position: 'absolute', left: 0, zIndex: -1 }}>
            <div>
              <ReservationDetailCard
                dialogContentStyle={{ width: '50vh' }}
                data={data}
                isHost={isHost}
                openSpecialOfferModal={(guest) => {
                  openSpecialOfferModal(guest);
                }}
              />
            </div>
            <SpecialOfferModal
              guest={selectedUser?.guest}
              propertyOwner={userData}
              property={data?.property}
              isOpen={isOpenSpecialOfferModal}
              isLoading={isSpecialOfferFormLoading}
              handleOpen={() => {
                setIsOpenSpecialOfferModal(true);
              }}
              handleClose={() => {
                setIsOpenSpecialOfferModal(false);
              }}
              submitSpecialOfferFormSuccess={(formData) =>
                submitSpecialOffer(formData)
              }
            />
          </div>
          {/* messages Section */}
          <div
            className='dasboard-wrapper fl-wrap no-pag'
            style={{
              width: '70vw',
              position: 'absolute',
              right: '2rem',
              top: 0,
            }}
          >
            <div className='dashboard-list-box fl-wrap'>
              <div className='dasboard-widget-title fl-wrap'>
                <h5>
                  <i className='fas fa-comment-alt'></i>
                  {userData?.id === selectedUser?.guest?.id ? (
                    <>
                      {selectedUser?.propertyOwner?.firstName ||
                        'No chats open'}
                    </>
                  ) : (
                    <>{selectedUser?.guest?.firstName || 'No chats open'}</>
                  )}

                  {/* <span> ( +3 New ) </span> */}
                </h5>
                <a
                  href='#'
                  className='mark-btn  tolt'
                  data-microtip-position='bottom'
                  data-tooltip='Mark all as read'
                >
                  <i className='far fa-comment-alt-check'></i>{' '}
                </a>
              </div>
              <div className='chat-wrapper fl-wrap'>
                <div
                  className='chat-box'
                  style={{ height: '100%', overflow: 'auto' }}
                >
                  <div
                    className='chat-box-scroll fl-wrap full-height'
                    data-simplebar='init'
                  >
                    <div
                      id='scroll'
                      className='fl-wrap'
                      style={{ height: '100%', overflowY: 'auto' }}
                    >
                      {msgsLoading ? (
                        <div
                          style={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <ProgressBar
                            width='230'
                            trackWidth='13'
                            percentage='99'
                            fontColor='gray'
                          />
                        </div>
                      ) : allMessages?.length > 0 ? (
                        Object.entries(messgaeGrouping(allMessages)).map(
                          (item, index) => {
                            return (
                              <>
                                <div
                                  key={index}
                                  style={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'center',
                                    alignContent: 'center',
                                    marginTop: 10,
                                    marginBottom: 10,
                                  }}
                                >
                                  <p
                                    style={{
                                      fontWeight: 'bolder',
                                      width: '50%',
                                      paddingTop: 7,
                                      textAlign: 'center',
                                      borderRadius: 10,
                                      color: '#717171',
                                    }}
                                  >
                                    {(item[0] !== 'undefined' && item[0]) || ''}
                                  </p>
                                </div>
                                <div
                                  key={index}
                                  style={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'center',
                                    alignContent: 'center',
                                    marginTop: 10,
                                    marginBottom: 10,
                                  }}
                                >
                                  <p
                                    style={{
                                      fontWeight: 'bolder',
                                      width: '100%',
                                      paddingTop: 7,
                                      textAlign: 'center',
                                      borderRadius: 10,
                                      color: '#717171',
                                    }}
                                  >
                                    {item[1]?.map((notification, i) => {
                                      if (
                                        notification?.messageType ===
                                        'notification'
                                      )
                                        return (
                                          <div
                                            style={{
                                              background: '#292e3a',
                                              color: 'white',
                                              padding: '6px',
                                              margin: '6px 0px',
                                              borderRadius: '12px',
                                            }}
                                            key={i}
                                          >
                                            {notification?.text}
                                          </div>
                                        );
                                    })}
                                  </p>
                                </div>
                                {item[1]?.map((item, i) => (
                                  <>
                                    {(item?.messageType === 'message' ||
                                      !item?.messageType) && (
                                      <ChatItem
                                        key={i}
                                        item={item}
                                        selectedUser={selectedUser}
                                        userData={userData}
                                      />
                                    )}
                                  </>
                                ))}
                              </>
                            );
                          }
                        )
                      ) : (
                        <div
                          style={{
                            color: '#fff',
                            marginTop: '50px',
                            height: '300px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '20px',
                          }}
                        >
                          Message not found !!
                        </div>
                      )}
                      <div ref={divRef} />
                    </div>
                  </div>
                </div>
                {selectedUser !== null && (
                  <>
                    <div className='chat-widget_input'>
                      <div style={{ position: 'relative', width: '100%' }}>
                        <div style={{ position: 'absolute', top: -100 }}>
                          {messageImagesUrls?.map((image, i) => {
                            return (
                              <span style={{ position: 'relative' }}>
                                <img
                                  src={image}
                                  alt={i}
                                  width={100}
                                  height={100}
                                />
                                <div
                                  // className='clear-button '
                                  onClick={() => clearMessageImages(i)}
                                  style={{
                                    position: 'absolute',
                                    zIndex: 9999,
                                    top: -40,
                                    right: 5,
                                  }}
                                >
                                  <i
                                    className='fa fa-times'
                                    style={{
                                      cursor: 'pointer',
                                      color: '#2e94f7',
                                    }}
                                  ></i>
                                </div>
                              </span>
                            );
                          })}
                        </div>
                        <textarea
                          placeholder='Type Message'
                          style={{ color: '#ffffffb3' }}
                          onChange={(e) => setMsgVal(e.target.value)}
                          onKeyDown={(e) =>
                            e.key == 'Enter' ? handleSendMessage() : null
                          }
                          value={msgVal}
                        >
                          {msgVal}
                        </textarea>
                        <div
                          style={{
                            position: 'absolute',
                            right: '15%',
                            zIndex: 9999,
                            marginTop: '20px',
                          }}
                        >
                          <label htmlFor='attachment'>
                            <ImAttachment
                              style={{
                                fontSize: '22px',
                                color: '#fff',
                                cursor: 'pointer',
                              }}
                            />
                          </label>
                          <input
                            type='file'
                            accept='image/*'
                            id='attachment'
                            name='attachment'
                            placeholder='Upload'
                            multiple={true}
                            onChange={handleAttachmentChange}
                            value=''
                            style={{ display: 'none' }}
                          />
                        </div>
                        <div
                          style={{
                            position: 'absolute',
                            right: '20%',
                            zIndex: 9999,
                            marginTop: '20px',
                          }}
                          onClick={handleClickOpen('body')}
                        >
                          <IoIosChatboxes
                            style={{
                              fontSize: '22px',
                              color: '#fff',
                              cursor: 'pointer',
                            }}
                          />
                        </div>
                      </div>
                      <button
                        type='submit'
                        className='color-bg'
                        onClick={() => handleSendMessage()}
                      >
                        <i className='fal fa-paper-plane'></i>
                      </button>
                    </div>
                  </>
                )}
                <div className='chat-contacts'>
                  {isLoading ? (
                    <div
                      style={{
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <ProgressBar
                        width='230'
                        trackWidth='13'
                        percentage='99'
                        fontColor='gray'
                      />
                    </div>
                  ) : (
                    chatData?.map((item, index) => {
                      return (
                        <a
                          key={index}
                          className='chat-contacts-item chat-contacts-item_active'
                          href='#'
                          onClick={() => handleGetUserChat(item)}
                        >
                          <div className='dashboard-message-avatar'>
                            {/* Refactor image conditions */}
                            {item.user_1 == userData.id ? (
                              <img
                                src={
                                  item.propertyOwner.profilePicture ||
                                  require('../../../assets/images/avatar/5.jpg')
                                }
                                alt=''
                              />
                            ) : (
                              <img
                                src={
                                  item.guest.profilePicture ||
                                  require('../../../assets/images/avatar/5.jpg')
                                }
                                alt=''
                              />
                            )}

                            {msgsCountHandler.chatId === item.id ? (
                              <div className='message-counter'>
                                {msgsCountHandler.count}
                              </div>
                            ) : null}
                          </div>
                          <div className='chat-contacts-item-text'>
                            {/* <p>{booking?.bookingStatus}</p> */}
                            <h6
                              style={{
                                textAlign: 'left',
                                color: '#fff',
                                fontSize: '12px',
                              }}
                            >
                              {item.user_1 == userData.id ? (
                                <>
                                  {item?.bookingId?.bookingStatus ===
                                    'completed' && <>Past Trip</>}
                                  {item?.bookingId?.bookingStatus ===
                                    'confirmed' && <>Upcoming Trip</>}
                                  {item?.bookingId?.bookingStatus ===
                                    'ongoing' && <>Ongoing Trip</>}
                                  {item?.bookingId?.bookingStatus ===
                                    'pending' && <>Inquiry</>}
                                  {item?.bookingId?.bookingStatus ===
                                    'cancelled  ' && <>Cancelled Trip</>}
                                </>
                              ) : (
                                <>
                                  {item?.bookingId?.bookingStatus ===
                                    'completed' && <>Past Guest</>}
                                  {item?.bookingId?.bookingStatus ===
                                    'confirmed' && <>Upcoming Guest</>}
                                  {item?.bookingId?.bookingStatus ===
                                    'ongoing' && <>Ongoing Reservation</>}
                                  {item?.bookingId?.bookingStatus ===
                                    'pending' && <>Inquiry</>}
                                  {item?.bookingId?.bookingStatus ===
                                    'cancelled  ' && <>Cancelled Reservation</>}
                                </>
                              )}
                            </h6>
                            <h4 style={{ fontSize: '14px' }}>
                              {item.user_1 == userData.id ? (
                                <>
                                  {item?.propertyOwner?.firstName}{' '}
                                  {item?.propertyOwner?.lastName}
                                </>
                              ) : (
                                <>
                                  {item.guest.firstName} {item.guest.lastName}
                                </>
                              )}
                            </h4>
                            {item?.bookingId && (
                              <h6
                                style={{
                                  textAlign: 'left',
                                  color: '#fff',
                                  fontSize: '12px',
                                }}
                              >
                                {moment(item?.booking?.startDate).format(
                                  'DD MMM, YYYY'
                                )}{' '}
                                -{' '}
                                {moment(item?.booking?.endDate).format(
                                  'DD MMM, YYYY'
                                )}
                              </h6>
                            )}
                            {item?.property && (
                              <h6
                                style={{
                                  textAlign: 'left',
                                  color: '#fff',
                                  fontSize: '12px',
                                }}
                              >
                                {item?.property?.title?.slice(0, 40)}
                              </h6>
                            )}
                            <span style={{ margin: '4px 0px' }}>
                              {/* 27 Dec 2018 */}
                              {item.messages.length > 0 ? (
                                moment(item?.messages[0].createdAt).format(
                                  'DD MMM YYYY'
                                ) === moment().format('DD MMM YYYY') ? (
                                  <>
                                    {moment(item?.messages[0].createdAt).format(
                                      'hh:mm a'
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {moment(item?.messages[0].createdAt).format(
                                      'DD MMM'
                                    )}
                                    ,
                                    {moment(item?.messages[0].createdAt).format(
                                      'hh:mm a'
                                    )}
                                  </>
                                )
                              ) : null}
                            </span>
                            <p style={{ fontSize: '12px' }}>
                              {item.messages.length > 0
                                ? item?.messages[0].text
                                : null}
                            </p>
                          </div>
                        </a>
                      );
                    })
                  )}
                </div>
              </div>
            </div>
          </div>
          {/*  */}
        </div>
      </div>
    </>
  );
}
