import React from 'react';
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';

const Buttons = ({
  onSubmit,
  title,
  style,
  loadingTitle,
  isLoading,
  color,
  variant,
  children,
}) => {
  return (
    <>
      {isLoading === false ? (
        <Button
          variant={variant === undefined ? 'contained' : variant}
          sx={{
            borderRadius: '2px',
            color: '#fff',
            padding: '10px 20px',
            fontSize: '18px',
            fontWeight: '400',
            letterSpacing: '0.5px',
            textTransform: 'Capitalize',
            ...style,
          }}
          color={color}
          fullWidth
          onClick={onSubmit}
        >
          {title}
          {children}
        </Button>
      ) : (
        <LoadingButton
          loading
          variant="contained"
          fullWidth
          disabled
          sx={{
            borderRadius: '2px',
            color: '#fff',
            padding: '10px 20px',
            fontSize: '11px',
            fontWeight: '600',
            letterSpacing: '0.5px',
            textTransform: 'Capitalize',
            ...style,
          }}
          loadingPosition="end"
        >
          {loadingTitle}
        </LoadingButton>
      )}
    </>
  );
};

export default Buttons;
