import { API_URL } from '../../config';
import axios from 'axios';

export const getServiceValueApi = async () => {
  try {
    const response = await axios.get(
      API_URL + 'system-configuration/get-column?column=service_fee'
    );
    return response.data.data[0].service_fee;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('bad Response of get Service Fee : ', response);
      return response;
    }
  }
};
