import { makeStyles } from '@mui/styles';

const dashboardStyles = makeStyles((theme) => ({
  reservationsCountSection: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    '& >h6': {
      color: '#ffff',
      fontSize: '20px',
    },
    '& >p': {
      color: '#ffff',
      fontSize: '16px',
      textDecoration: 'underline',
    },
  },

  nextStepHead: {
    marginBottom: '10px !important',
    fontWeight: '600 !important',
    fontSize: '18px !important',
  },

  cardsStyle: {
    background: '#292e3a',
    padding: '15px 10px',
    borderRadius: '10px',
    height: '250px',
    position: 'relative',
  },
  titleOne: {
    color: '#fff',
    fontSize: '16px !important',
    marginTop: '12px !important',
  },
  titleTwo: {
    fontSize: '16px !important',
    marginBottom: '12px !important',
  },
  titleThree: {
    fontSize: '16px !important',
    marginTop: '12px !important',
    color: '#ffffff7d',
  },
}));

export default dashboardStyles;
