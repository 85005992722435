import React, { useEffect, useState } from "react";
import { Grid, Box, Tab } from "@mui/material";
import Card from "../../../components/dasboard/card";
import InfoBar from "../../../components/dasboard/infoBar";
import Sidebar from "../../../components/dasboard/sidebar";
import Buttons from "../../../components/form/button";
import Input from "../../../components/form/input";
import Header from "../../../components/header";
import Property from "../../../components/mainComponents/property";
import {
  getPropertyUsers,
  getPropertyCountByStatus,
} from "../../../services/properties/getProperty";
import ErrorMessage from "../../../components/errorMessage";
import SkeletonLoader from "../../../components/skeletonLoader";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Pagination from "../../../components/Pagination/Pagination";
import { getCohostProperty } from "../../../services/CohostPropertise/CohostProperty";

const dropdownOptions = [
  { label: 10, value: 10 },
  { label: 20, value: 20 },
  { label: 50, value: 50 },
];

const MyPropertyListPage = () => {
  const [value, setValue] = React.useState("LISTED");
  const [properties, setProperties] = useState([]);
  const [filteredProperty, setFilteredProperty] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [filterToggle, setFilterToggle] = useState(false);

  const [customFirst, setCustomFirst2] = useState(0);
  const [customRows, setCustomRows2] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [listedCount, setListedCount] = useState(0);
  const [UnlistedCount, setUnListedCount] = useState(0);
  const [inProgressCount, setInProgressCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);
  const [suspendedCount, setSuspendedCount] = useState(0);
  const [coHostCount, setCoHostCount] = useState(0);

  const [coHostprop, setCoHostProp] = useState([]);

  const propertyTabs = [
    { label: "Listed", key: "1", slug: "LISTED" },
    { label: "Unlisted", key: "2", slug: "UNLISTED" },
    { label: "In progress", key: "3", slug: "INPROGRESS" },
    { label: "Pending", key: "4", slug: "PENDING" },
    { label: "Suspended", key: "5", slug: "SUSPENDED" },
  ];
  let userData;
  if (JSON.parse(localStorage.getItem("userData")) == undefined) {
    userData = null;
  } else {
    userData = JSON.parse(localStorage.getItem("userData")) || null;
  }

  const getCounts = async (setState, status) => {
    let url = `userId=${userData.id}&status=${status}`;
    let result = await getPropertyCountByStatus(url);
    setState(result.data.total);
  };

  useEffect(() => {
    let url = `page=0&limit=10&user_id=${userData.id}&status=${value}`;
    getPropertyListings(url);
    getCounts(setListedCount, "LISTED");
    getCounts(setUnListedCount, "UNLISTED");
    getCounts(setInProgressCount, "INPROGRESS");
    getCounts(setPendingCount, "PENDING");
    getCounts(setSuspendedCount, "SUSPENDED");
  }, []);

  const handleChange = (event, newValue) => {
    setProperties([]);
    setFilteredProperty([]);
    setValue(newValue);
    setCustomFirst2(0);
    setCustomRows2(10);
    let url = `page=0&limit=2&user_id=${userData.id}&status=${newValue}`;
    getPropertyListings(url);
  };

  function handleSearch(value) {
    if (value === "") {
      setFilteredProperty(properties);
      setFilterToggle(false);
    } else {
      const filtered = properties.filter((property) => {
        return property?.title.toLowerCase().includes(value.toLowerCase());
      });
      setFilterToggle(true);
      setFilteredProperty(filtered);
    }
  }

  const handlePageClick = (event) => {
    setCustomFirst2(event.first);
    setCustomRows2(event.rows);
    let url = `page=${event.page}&limit=${event.rows}&user_id=${userData.id}&status=${value}`;
    getPropertyListings(url);
  };

  const getPropertyListings = async (url) => {
    let tempArr = [];
    try {
      setIsLoading(true);
      const response = await getPropertyUsers(url);
      let responceCoHost = await getCohostProperty(userData.id);
      setCoHostProp(responceCoHost);
      setCoHostCount(responceCoHost.length);
      setTotalRecords(response?.data?.data?.meta?.itemCount);
      if (response.status === 200) {
        if (response?.data?.data?.items?.length > 0) {
          await Promise.all(
            response?.data?.data?.items?.map(async (item, ind) => {
              tempArr.push({
                ...item,
                totalReviews: 0,
                totalRating: 0,
              });
            })
          );
          setProperties(tempArr);
          setFilteredProperty(tempArr);
        } else {
          setProperties([]);
          setFilteredProperty([]);
        }
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Header />
      <div id="wrapper">
        <div className="dashboard-menu-overlay" />
        <Sidebar page={"My Listing"} />
        <div className="dashboard-content">
          <div className="dashboard-menu-btn color-bg">
            <span>
              <i className="fas fa-bars" />
            </span>
            Dasboard Menu
          </div>
          <InfoBar label={"YOUR LISTING"} />
          <div className="dasboard-wrapper fl-wrap no-pag">
            <Card>
              <Grid
                container
                rowSpacing={{ xs: 1, sm: 1, md: 2 }}
                columnSpacing={{ xs: 1, sm: 1, md: 2 }}
              >
                <Grid item xs={12} sm={7} md={9}>
                  <Input
                    label={"Search"}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={5} md={3}>
                  <Buttons
                    isLoading={false}
                    title={"Search "}
                    style={{ height: 53 }}
                    onSubmit={() => handleSearch(searchValue)}
                  />
                </Grid>
              </Grid>
            </Card>

            <Card>
              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                    >
                      <Tab
                        sx={{ fontSize: "14px" }}
                        label="Listed"
                        value="LISTED"
                        icon={
                          <div
                            style={{
                              // display: value == tab.key ? "flex" : "none",
                              background: "#0095ff",
                              borderRadius: "50%",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: "0 5px",
                            }}
                          >
                            <p
                              style={{
                                paddingBottom: 0,
                                color: "#fff",
                              }}
                            >
                              {listedCount}
                            </p>
                          </div>
                        }
                        iconPosition="end"
                      />
                      <Tab
                        sx={{ fontSize: "14px" }}
                        label="Unlisted"
                        value="UNLISTED"
                        icon={
                          <div
                            style={{
                              // display: value == tab.key ? "flex" : "none",
                              background: "#0095ff",
                              borderRadius: "50%",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: "0 5px",
                            }}
                          >
                            <p
                              style={{
                                paddingBottom: 0,
                                color: "#fff",
                              }}
                            >
                              {UnlistedCount}
                            </p>
                          </div>
                        }
                        iconPosition="end"
                      />
                      <Tab
                        sx={{ fontSize: "14px" }}
                        label="In Progress"
                        value="INPROGRESS"
                        icon={
                          <div
                            style={{
                              // display: value == tab.key ? "flex" : "none",
                              background: "#0095ff",
                              borderRadius: "50%",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: "0 5px",
                            }}
                          >
                            <p
                              style={{
                                paddingBottom: 0,
                                color: "#fff",
                              }}
                            >
                              {inProgressCount}
                            </p>
                          </div>
                        }
                        iconPosition="end"
                      />
                      <Tab
                        sx={{ fontSize: "14px" }}
                        label="Pending"
                        value="PENDING"
                        icon={
                          <div
                            style={{
                              // display: value == tab.key ? "flex" : "none",
                              background: "#0095ff",
                              borderRadius: "50%",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: "0 5px",
                            }}
                          >
                            <p
                              style={{
                                paddingBottom: 0,
                                color: "#fff",
                              }}
                            >
                              {pendingCount}
                            </p>
                          </div>
                        }
                        iconPosition="end"
                      />
                      <Tab
                        sx={{ fontSize: "14px" }}
                        label="Suspended"
                        value="SUSPENDED"
                        icon={
                          <div
                            style={{
                              // display: value == tab.key ? "flex" : "none",
                              background: "#0095ff",
                              borderRadius: "50%",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: "0 5px",
                            }}
                          >
                            <p
                              style={{
                                paddingBottom: 0,
                                color: "#fff",
                              }}
                            >
                              {suspendedCount}
                            </p>
                          </div>
                        }
                        iconPosition="end"
                      />
                      {coHostCount > 0 ? (
                        <Tab
                          sx={{ fontSize: "14px" }}
                          label="Cohost"
                          value="COHOST"
                          icon={
                            <div
                              style={{
                                // display: value == tab.key ? "flex" : "none",
                                background: "#0095ff",
                                borderRadius: "50%",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: "0 5px",
                              }}
                            >
                              <p
                                style={{
                                  paddingBottom: 0,
                                  color: "#fff",
                                }}
                              >
                                {coHostCount}
                              </p>
                            </div>
                          }
                          iconPosition="end"
                        />
                      ) : (
                        ""
                      )}
                    </TabList>
                  </Box>
                  {propertyTabs?.map((tabPanel, i) => (
                    <TabPanel value={tabPanel.slug}>
                      {isLoading === true ? (
                        <Loader />
                      ) : (
                        <>
                          {properties.length === 0 ? (
                            <Grid container>
                              <Grid item xs={12}>
                                <ErrorMessage />
                              </Grid>
                            </Grid>
                          ) : (
                            <Grid
                              container
                              rowSpacing={{ xs: 1, sm: 1, md: 1 }}
                              columnSpacing={{ xs: 1, sm: 1, md: 2 }}
                            >
                              {filterToggle
                                ? filteredProperty.map((property) => {
                                    return (
                                      <Grid item xs={12} sm={6} md={12}>
                                        <Property property={property} />
                                      </Grid>
                                    );
                                  })
                                : filteredProperty
                                    // ?.slice(
                                    //   basicFirst,
                                    //   basicRows + basicFirst
                                    // )
                                    .map((property) => {
                                      return (
                                        <Grid item xs={12} sm={6} md={12}>
                                          <Property property={property} />
                                        </Grid>
                                      );
                                    })}
                            </Grid>
                          )}
                        </>
                      )}
                      {!isLoading ? (
                        <Pagination
                          totalRecords={totalRecords}
                          customFirst={customFirst}
                          customRows={customRows}
                          onPageChange={handlePageClick}
                          dropdownOptions={dropdownOptions}
                        />
                      ) : null}
                      {/* <ReactPaginate
                          className='custom-pagination'
                          breakLabel='...'
                          nextLabel='>'
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={10}
                          pageCount={totalPages}
                          previousLabel='<'
                          renderOnZeroPageCount={null}
                        /> */}
                    </TabPanel>
                  ))}
                  <TabPanel value={"COHOST"}>
                    {coHostprop.map((property) => {
                      return (
                        <Grid item xs={12} sm={6} md={12}>
                          <Property property={property} />
                        </Grid>
                      );
                    })}
                  </TabPanel>
                </TabContext>
              </Box>
            </Card>
            {/* {isLoading === true ? (
                <Loader />
              ) : (
                <>
                  {properties.length === 0 ? (
                    <Grid container>
                      <Grid item xs={12}>
                        <ErrorMessage />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      container
                      rowSpacing={{ xs: 1, sm: 1, md: 1 }}
                      columnSpacing={{ xs: 1, sm: 1, md: 2 }}
                    >
                      {filterToggle
                        ? filteredProperty.map((property) => {
                          return (
                            <Grid item xs={12} sm={6} md={12}>
                              <Property property={property} />
                            </Grid>
                          )
                        })
                        : filteredProperty
                          ?.slice(basicFirst, basicRows + basicFirst)
                          .map((property) => {
                            return (
                              <Grid item xs={12} sm={6} md={12}>
                                <Property property={property} />
                              </Grid>
                            )
                          })}
                    </Grid>
                  )}
                </>
              )}
              <Paginator
                first={basicFirst}
                rows={basicRows}
                totalRecords={properties?.length}
                rowsPerPageOptions={[5, 10, 20, 30]}
                onPageChange={onBasicPageChange}
                style={{ backgroundColor: '#1b182b' }}
              ></Paginator> */}
          </div>
        </div>
        <div className="dashbard-bg gray-bg" />
      </div>
    </>
  );
};

const Loader = () => {
  return (
    <Grid
      container
      rowSpacing={{ xs: 1, sm: 1, md: 2 }}
      columnSpacing={{ xs: 1, sm: 1, md: 2 }}
    >
      <Grid item xs={12} sm={6} md={6}>
        <SkeletonLoader height={200} />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <SkeletonLoader height={200} />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <SkeletonLoader height={200} />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <SkeletonLoader height={200} />
      </Grid>
    </Grid>
  );
};

export default MyPropertyListPage;
