import React from "react";
import Ratings from "../Ratings";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";

const Property = ({ property, fromAction }) => {
  const { title, id, thumbnailUrl, city, country, state } = property;
  return (
    <div className='dashboard-listings-item fl-wrap'>
      <div className='dashboard-listings-item_img'>
        <div className='bg-wrap1'>
          <img className='bg' src={thumbnailUrl} />
        </div>
        <div className='overlay'></div>
        <Link to={`/property-detail/${id}`}>View</Link>
      </div>
      <div className='dashboard-listings-item_content'>
        <h4>
          <Tooltip title={title}>
            <Link to={`/property-detail/${id}`}>
              {title?.length > 40 ? (
                <a
                  onMouseOver={(e) =>
                    (e.target.style.textOverflow = "ellipsis")
                  }
                >
                  {title.substring(0, 40)}...
                </a>
              ) : (
                <a
                  onMouseOver={(e) =>
                    (e.target.style.textOverflow = "ellipsis")
                  }
                >
                  {title}
                </a>
              )}
            </Link>
          </Tooltip>
        </h4>
        <div className='geodir-category-location'>
          <a style={{ textDecoration: "none" }}>
            <i className='fas fa-map-marker-alt'></i>{" "}
            <span
              style={{
                fontSize: 12,
                color: "white",
                lineHeight: 1.8,
                color: "#bbbbbb",
              }}
            >
              {city}, {state}, {country}
            </span>
          </a>
        </div>
        <div className='property-id'>
          <a style={{ textAlign: "left", color: "white", float: "left" }}>
            Property ID # {property.code}
          </a>
        </div>
        <div className='clearfix' style={{ paddingBottom: 10 }}></div>
        <div
          style={{
            textAlign: "initial",
            display: "flex",
            alignItems: "center",
            color: "#fff",
            fontFamily: "Jost",
            fontSize: "16px",
          }}
        >
          <Ratings
            rating={
              property?.average_rating
                ? property?.average_rating?.averageRating
                : 0
            }
          />
          (
          {property?.average_rating
            ? parseInt(property?.average_rating?.averageRating).toFixed(1)
            : 0}
          )
          {property?.average_rating
            ? ` (${property?.average_rating?.reviewCount} Reviews)`
            : " (0 Reviews)"}
        </div>
        <div className='dashboard-listings-item_opt'>
          {property?.status !== "PENDING" && (
            <span className='viewed-counter' style={{ fontSize: 13 }}>
              <i className='fas fa-eye' style={{ paddingRight: 5 }}></i> Viewed
              - {0}{" "}
            </span>
          )}
          {property?.status == "LISTED" && (
            <ul>
              <li>
                <Tooltip title={"Insight"}>
                  <Link to={`/admin/property-insight/${property?.id}`}>
                    <i className='far fa-chart-bar'></i>
                  </Link>
                </Tooltip>
              </li>
              <li>
                <Tooltip title={"Co Hosts"}>
                  <Link
                    to={`/admin/co-hosts/${property?.id}?propertyTitle=${property?.title}&propertyCode=${property?.code}`}
                  >
                    <i className='far fa-user'></i>
                  </Link>
                </Tooltip>
              </li>
              <li>
                <Tooltip title={"Calender"}>
                  <Link
                    style={{ cursor: "pointer" }}
                    to={`/admin/calendar/${property?.id}?title=${property?.title}&code=${property?.code}`}
                  >
                    <i className='far fa-calendar-alt'></i>
                  </Link>
                </Tooltip>
              </li>
              <li>
                <Tooltip title={"Edit Property"}>
                  <a>
                    <Link to={`/admin/edit-property/${property?.id}`}>
                      <i className='far fa-edit'></i>
                    </Link>
                  </a>
                </Tooltip>
              </li>
              <li>
                <Tooltip title={"Deactivate"}>
                  <a>
                    <i className='far fa-trash-alt'></i>
                  </a>
                </Tooltip>
              </li>
            </ul>
          )}
          {property?.status == "PENDING" && (
            <ul>
              <li>
                <Tooltip title={"Edit Property"}>
                  <a>
                    <Link to={`/admin/edit-property/${property?.id}`}>
                      <i className='far fa-edit'></i>
                    </Link>
                  </a>
                </Tooltip>
              </li>
            </ul>
          )}

          {(fromAction || property?.status === "INPROGRESS") && (
            <ul>
              <li>
                <Tooltip title={"Edit Property"}>
                  <a
                    href={`/admin/edit-property/${property?.id}`}
                    style={{ padding: "0 18px" }}
                  >
                    <i className='far fa-edit'></i>
                  </a>
                </Tooltip>
              </li>
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default Property;
