import axios from 'axios';
import { axiosPrivate } from '../../api/axios';
import { API_URL } from '../../config';

export const updateUserProfilePicture = async (id, uploadData) => {
  try {
    const response = await axiosPrivate.put(
      API_URL + `users/updateprofilepicture/${id}`,
      uploadData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of edit user profile pic : ', response);
      return response;
    }
  }
};
