import * as React from 'react';
import Slider from '@mui/material/Slider';
import { Typography } from '@mui/material';

function valuetext(value) {
  return `${value}°C`;
}

const minDistance = 0;

const SliderField = ({ priceRange, setPriceRange }) => {
  const handleChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 100 - minDistance);
        setPriceRange([clamped, clamped + minDistance]);
      } else {
        const clamped = Math.max(newValue[1], minDistance);
        setPriceRange([clamped - minDistance, clamped]);
      }
    } else {
      setPriceRange(newValue);
    }
  };

  const valueLabelFormat = (value) => {
    return `${process.env.REACT_APP_CURRENCY} ${value?.toLocaleString(
      'en-US'
    )}`;
  };

  return (
    <>
      <Typography
        style={{ paddingBottom: 10 }}
        variant="h6"
        color={'#c3c3c3'}
        textAlign={'left'}
        sx={{ fontSize: '14px', marginRight: '12px' }}
      >
        Price Range
      </Typography>
      <Slider
        getAriaLabel={() => 'Minimum distance shift'}
        value={priceRange}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        valueLabelFormat={valueLabelFormat}
        disableSwap
        min={0}
        max={99999}
      />
    </>
  );
};

export default SliderField;
