import { API_URL } from '../../config';
import { axiosPrivate } from '../../api/axios';

export const ActivationStatus = async () => {
  try {
    const response = await axiosPrivate.put(
      API_URL + `users/updateDeactivationStatus`
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;

      console.log('Activation Status is status code', response.data.statusCode);
      console.log('Activation   Error Response : ', response.data.message[1]);
      throw response;
    }
  }
};

export const GuestContribution = async () => {
  try {
    const response = await axiosPrivate.put(
      API_URL + `users/updateAcceptTipStatus`
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;

      console.log('Acc status', response.data.statusCode);
      console.log('Acc status   Error  : ', response.data.message[1]);
      throw response;
    }
  }
};
