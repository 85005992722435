import { Box, Grid, Rating } from '@mui/material';
import { Star } from '@mui/icons-material';
import React, { useState } from 'react';
import { getPropertyAverageRatingsApi } from '../services/review/getPropertyOverAllRatings';
const PropertyAllCriteria = ({ id, reviewCount }) => {
  const [ratings, setRatings] = useState({});
  React.useEffect(() => {
    const getAllRatings = async () => {
      const res = await getPropertyAverageRatingsApi(id);
      setRatings(res.data.data && res.data.data[0]);
    };
    if (id) {
      getAllRatings();
    }
  }, [id]);

  return (
    <div style={{ marginBottom: '20px', width: '93.5%' }}>
      <div
        style={{
          textAlign: 'left',
          marginBottom: '20px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Star style={{ color: 'white', fontSize: '20px' }} />
        <span style={{ color: 'white', fontSize: '20px', marginLeft: '10px' }}>
          {`${
            ratings?.over_all_average
              ? parseInt(ratings?.over_all_average).toFixed(1)
              : 0
          } - ${reviewCount ? reviewCount : 0} Reviews`}
        </span>{' '}
      </div>

      <Grid container justifyContent='space-between'>
        <Grid item xs={12} md={5}>
          <Grid container justifyContent='space-between' alignItems='center'>
            <Grid item xs={3} style={{ textAlign: 'left' }}>
              <span style={{ color: 'white', fontSize: '16px' }}>
                Cleanliness
              </span>
            </Grid>
            <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
              <Box
                component='div'
                sx={{
                  '& .p-slider': {
                    backgroundColor: 'white',
                    width: '100px',
                    '& .p-slider-handle': {
                      display: 'none',
                    },
                  },
                }}
              >
                <Rating
                  name='half-rating-read'
                  value={
                    ratings?.cleanliness_average
                      ? ratings?.cleanliness_average
                      : 0
                  }
                  precision={0.5}
                  readOnly
                />
              </Box>
              <span
                style={{ color: 'white', fontSize: '16px', marginLeft: '15px' }}
              >
                {ratings?.cleanliness_average
                  ? parseInt(ratings?.cleanliness_average).toFixed(1)
                  : 0}
              </span>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent='space-between'
            alignItems='center'
            marginTop={2}
          >
            <Grid item xs={3} style={{ textAlign: 'left' }}>
              <span style={{ color: 'white', fontSize: '16px' }}>
                Communication
              </span>
            </Grid>
            <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
              <Box
                component='div'
                sx={{
                  '& .p-slider': {
                    backgroundColor: 'white',
                    width: '100px',
                    '& .p-slider-handle': {
                      display: 'none',
                    },
                  },
                }}
              >
                <Rating
                  name='half-rating-read'
                  value={
                    ratings?.communication_average
                      ? ratings?.communication_average
                      : 0
                  }
                  precision={0.5}
                  readOnly
                />
              </Box>
              <span
                style={{ color: 'white', fontSize: '16px', marginLeft: '15px' }}
              >
                {ratings?.communication_average
                  ? parseInt(ratings?.communication_average).toFixed(1)
                  : 0}
              </span>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent='space-between'
            alignItems='center'
            marginTop={2}
          >
            <Grid item xs={3} style={{ textAlign: 'left' }}>
              <span style={{ color: 'white', fontSize: '16px' }}>Check-in</span>
            </Grid>
            <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
              <Box
                component='div'
                sx={{
                  '& .p-slider': {
                    backgroundColor: 'white',
                    width: '100px',
                    '& .p-slider-handle': {
                      display: 'none',
                    },
                  },
                }}
              >
                <Rating
                  name='half-rating-read'
                  value={
                    ratings?.check_in_average ? ratings?.check_in_average : 0
                  }
                  precision={0.5}
                  readOnly
                />
              </Box>
              <span
                style={{ color: 'white', fontSize: '16px', marginLeft: '15px' }}
              >
                {ratings?.check_in_average
                  ? parseInt(ratings?.check_in_average).toFixed(1)
                  : 0}
              </span>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={5}>
          <Grid container justifyContent='space-between' alignItems='center'>
            <Grid item xs={4} style={{ textAlign: 'left' }}>
              <span style={{ color: 'white', fontSize: '16px' }}>Accuracy</span>
            </Grid>
            <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
              <Box
                component='div'
                sx={{
                  '& .p-slider': {
                    backgroundColor: 'white',
                    width: '100px',
                    '& .p-slider-handle': {
                      display: 'none',
                    },
                  },
                }}
              >
                <Rating
                  name='half-rating-read'
                  value={
                    ratings?.accuracy_average
                      ? parseInt(ratings?.accuracy_average).toFixed(1)
                      : 0
                  }
                  precision={0.5}
                  readOnly
                />
              </Box>
              <span
                style={{ color: 'white', fontSize: '16px', marginLeft: '15px' }}
              >
                {ratings?.accuracy_average
                  ? parseInt(ratings?.accuracy_average).toFixed(1)
                  : 0}
              </span>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent='space-between'
            marginTop={2}
            alignItems='center'
          >
            <Grid item xs={3} style={{ textAlign: 'left' }}>
              <span style={{ color: 'white', fontSize: '16px' }}>Location</span>
            </Grid>
            <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
              <Box
                component='div'
                sx={{
                  '& .p-slider': {
                    backgroundColor: 'white',
                    width: '100px',
                    '& .p-slider-handle': {
                      display: 'none',
                    },
                  },
                }}
              >
                <Rating
                  name='half-rating-read'
                  value={
                    ratings?.location_average ? ratings?.location_average : 0
                  }
                  precision={0.5}
                  readOnly
                />
              </Box>
              <span
                style={{ color: 'white', fontSize: '16px', marginLeft: '15px' }}
              >
                {ratings?.location_average
                  ? parseInt(ratings?.location_average).toFixed(1)
                  : 0}
              </span>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent='space-between'
            marginTop={2}
            alignItems='center'
          >
            <Grid item xs={3} style={{ textAlign: 'left' }}>
              <span style={{ color: 'white', fontSize: '16px' }}>Value</span>
            </Grid>
            <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
              <Box
                component='div'
                sx={{
                  '& .p-slider': {
                    backgroundColor: 'white',
                    width: '100px',
                    '& .p-slider-handle': {
                      display: 'none',
                    },
                  },
                }}
              >
                <Rating
                  name='half-rating-read'
                  value={ratings?.value_average ? ratings?.value_average : 0}
                  precision={0.5}
                  readOnly
                />
              </Box>
              <span
                style={{ color: 'white', fontSize: '16px', marginLeft: '15px' }}
              >
                {ratings?.value_average
                  ? parseInt(ratings?.value_average).toFixed(1)
                  : 0}
              </span>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default PropertyAllCriteria;
