import React from "react";
import { Link } from "react-router-dom";

const Notification = ({
  link,
  icon,
  text,
  id,
  date,
  navigateToAddProperty,
}) => {
  return (
    <div style={{ width: "100%" }}>
      <div onClick={() => navigateToAddProperty()}>
        <div className="dashboard-list fl-wrap">
          <div className="dashboard-message">
            <span className="close-dashboard-item color-bg">
              <i className="fal fa-times"></i>
            </span>
            <div className="main-dashboard-message-icon color-bg">{icon}</div>
            <div className="main-dashboard-message-text">
              <p>{text}</p>
            </div>
            <div className="main-dashboard-message-time">
              <i className="fal fa-calendar-week"></i> {date}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
