import React, { useState } from 'react'
import { Grid } from '@mui/material'
import { MdClose } from 'react-icons/md';
import {
    Typography,

} from '@mui/material';
import { Box } from '@mui/material';
export default function SwitchToogle({ data }) {
    const [editPreparationTime, setEditPreparationTime] = useState("")
    return (
        <div>
            <Grid
                container
                rowSpacing={{ xs: 1, sm: 1, md: 2 }}
                columnSpacing={{ xs: 1, sm: 1, md: 2 }}
                sx={{ margin: '20px 0px' }}
            >
                {!editPreparationTime ? (
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                    >
                        {/* <Box>
                  <Typography sx={{ color: '#fff', fontSize: '18px' }}>
                    Learn more
                  </Typography>
                </Box> */}
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: '13px',
                                    textDecoration: 'underline',
                                    color: '#fff',
                                    cursor: 'pointer',
                                    marginTop: "5px"
                                }}
                                onClick={() => setEditPreparationTime(!editPreparationTime)}
                            >
                                {data.LearnMoreTitle}
                            </Typography>
                        </Box>
                    </Grid>
                ) : (
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        sx={{ border: '1px solid gray', borderRadius: '8px', marginTop: "20px", marginLeft: "9px" }}
                    >
                        <Box

                        >
                            <MdClose
                                style={{
                                    fontSize: '22px',
                                    color: '#fff',
                                    cursor: 'pointer',
                                    fontWeight: 'bolder',
                                    margin: '3px',
                                }}
                                onClick={() => setEditPreparationTime(!editPreparationTime)}
                            />

                        </Box>
                        <Box>
                            <Typography variant="h6">
                                {data.title}
                            </Typography>
                            <Typography>{data.detail}</Typography>
                            <li>{data.unListed}</li>
                            <li>{data.listed}</li>
                        </Box>

                    </Grid>
                )}
            </Grid>

        </div>
    )
}
