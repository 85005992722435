import React from 'react';
import Grid from '@mui/material/Grid';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

export default function Tile(props) {
  const { value } = props;
  return (
    <Grid item xs={4} sm={4} md={4}>
      <Link style={{ textDecoration: 'none' }} to={value.link}>
        <Card
          sx={{
            maxWidth: 450,
            height: 250,
            borderRadius: 3,
            background: '#1b182b',
          }}
        >
          <CardContent sx={{ textAlign: 'left', marginTop: '10px' }}>
            {value.icon}
            <Typography
              sx={{
                textAlign: 'left',
                marginTop: '20px',
                fontSize: '25px',
                color: '#fff',
                fontFamily: 'Jost',
              }}
            >
              {value.title}{' '}
            </Typography>
            <Typography
              sx={{ marginTop: '10px', fontFamily: 'Jost', fontSize: '18px' }}
            >
              {value.desc}
            </Typography>
          </CardContent>
        </Card>
      </Link>
    </Grid>
  );
}
