import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { Paginator } from 'primereact/paginator';
import Card from '../../../components/dasboard/card';
import InfoBar from '../../../components/dasboard/infoBar';
import Sidebar from '../../../components/dasboard/sidebar';
import Buttons from '../../../components/form/button';
import Input from '../../../components/form/input';
import Header from '../../../components/header';
import ErrorMessage from '../../../components/errorMessage';
import SkeletonLoader from '../../../components/skeletonLoader';
import { getBookingGuestAwaitingPaymentApi } from '../../../services/booking/getAwaitingPayment';
import CancelRequest from './CancelRequest';

const PendingCancellationRequest = () => {
  const [properties, setProperties] = useState([]);
  const [filteredProperty, setFilteredProperty] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [filterToggle, setFilterToggle] = useState(false);
  const [basicFirst, setBasicFirst] = useState(0);
  const [basicRows, setBasicRows] = useState(10);
  const onBasicPageChange = (event) => {
    setBasicFirst(event.first);
    setBasicRows(event.rows);
  };
  function handleSearch(value) {
    if (value === '') {
      setFilteredProperty(properties);
      setFilterToggle(false);
    } else {
      const filtered = properties.filter((property) => {
        return property?.booking_property.title
          .toLowerCase()
          .includes(value.toLowerCase());
      });
      setFilterToggle(true);
      setFilteredProperty(filtered);
    }
  }
  const getPropertyListings = async () => {
    try {
      const response = await getBookingGuestAwaitingPaymentApi();
      if (response.status === 200) {
        setProperties(response.data?.data);
        setFilteredProperty(response.data?.data);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getPropertyListings();
  }, []);

  return (
    <>
      <Header />
      <div id='wrapper'>
        <div className='dashboard-menu-overlay' />
        <Sidebar page={'Pending Cancellation Request'} />
        <div className='dashboard-content'>
          <div className='dashboard-menu-btn color-bg'>
            <span>
              <i className='fas fa-bars' />
            </span>
            Dasboard Menu
          </div>
          {/* {isOpen && <Dropdown/>} */}

          <div className='container dasboard-container'>
            <InfoBar label={'Pending Cancellation Request'} />
            <div className='dasboard-wrapper fl-wrap no-pag'>
              <Card>
                <Grid
                  container
                  rowSpacing={{ xs: 1, sm: 1, md: 2 }}
                  columnSpacing={{ xs: 1, sm: 1, md: 2 }}
                >
                  <Grid item xs={12} sm={7} md={9}>
                    <Input
                      label={'Search'}
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5} md={3}>
                    <Buttons
                      isLoading={false}
                      title={'Search '}
                      style={{ height: 53 }}
                      onSubmit={() => handleSearch(searchValue)}
                    />
                  </Grid>
                </Grid>
              </Card>
              {isLoading === true ? (
                <Loader />
              ) : (
                <>
                  {properties?.length === 0 ? (
                    <Grid container>
                      <Grid item xs={12}>
                        <ErrorMessage />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      container
                      rowSpacing={{ xs: 1, sm: 1, md: 1 }}
                      columnSpacing={{ xs: 1, sm: 1, md: 2 }}
                    >
                      {filterToggle
                        ? filteredProperty.map((booking) => {
                            return (
                              <>
                                <Grid item xs={12} sm={6} md={12}>
                                  <CancelRequest booking={booking} />
                                </Grid>
                              </>
                            );
                          })
                        : filteredProperty
                            ?.slice(basicFirst, basicRows + basicFirst)
                            .map((booking) => {
                              return (
                                <>
                                  <Grid item xs={12} sm={6} md={12}>
                                    <CancelRequest booking={booking} />
                                  </Grid>
                                </>
                              );
                            })}
                    </Grid>
                  )}
                </>
              )}

              <Card>
                <Paginator
                  first={basicFirst}
                  rows={basicRows}
                  totalRecords={properties?.length}
                  rowsPerPageOptions={[5, 10, 20, 30]}
                  onPageChange={onBasicPageChange}
                ></Paginator>
              </Card>
            </div>
          </div>
        </div>
        <div className='dashbard-bg gray-bg' />
      </div>
    </>
  );
};

const Loader = () => {
  return (
    <Grid
      container
      rowSpacing={{ xs: 1, sm: 1, md: 2 }}
      columnSpacing={{ xs: 1, sm: 1, md: 2 }}
    >
      <Grid item xs={12} sm={6} md={6}>
        <SkeletonLoader height={200} />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <SkeletonLoader height={200} />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <SkeletonLoader height={200} />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <SkeletonLoader height={200} />
      </Grid>
    </Grid>
  );
};

export default PendingCancellationRequest;
