export const FakeCities = [
  {
    images: require("../assets/images/istanbul.jpg"),
    title: "Istanbul",
  },
  {
    images: require("../assets/images/konya.jpg"),
    title: "Konya",
  },
  {
    images: require("../assets/images/ankara.jpg"),
    title: "Ankara",
  },
  {
    images: require("../assets/images/anatalya.jpg"),
    title: "Antalya",
  },
  {
    images: require("../assets/images/izmir.jpg"),
    title: "Izmir",
  },
  {
    images: require("../assets/images/busra.jpg"),
    title: "Busra",
  },
];
