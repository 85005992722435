import React from 'react'
import { Grid, Typography, Box } from '@mui/material'
import Resource1 from "../../assets/images/all/small/resource1.jpeg";
import Resource2 from "../../assets/images/all/small/resource2.jpeg";
import Resource3 from "../../assets/images/all/small/resource3.jpeg";
import Resource4 from "../../assets/images/all/small/resource4.jpeg";
import Resource5 from "../../assets/images/all/small/resource5.jpeg";
import Resource6 from "../../assets/images/all/small/resource6.jpeg";
const Opportunities = () => {
    return (
        <Grid container value="active">
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item xs={12} md={12} lg={12} sm={12}>
                    <Typography variant="h4" sx={{ color: '#fff', textAlign: 'left', fontWeight: '600' }}>Travel is changing. We’re here to help.</Typography>
                    <Box sx={{ width: '50%', borderTop: '1px solid #919099', my: 3 }}>
                        <Typography sx={{ fontSize: '16px', mt: 3, textAlign: 'justify' }}>Right now travel to your area is limited. Learn ways to
                            navigate recent changes by exploring the resources below.</Typography>
                    </Box>
                </Grid>
            </Grid>
            <Grid container sx={{ mb: 4 }}>
                <Typography variant="h4" sx={{ color: '#fff' }}>Resources for hosting now</Typography>
            </Grid>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item xs={12} sm={12} lg={6} md={6}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <img src={Resource1} alt="" className="resourceimg" />
                        <p className="resourceText">Why it’s smart to offer flexible cancellations right now</p>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} lg={6} md={6}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <img src={Resource2} alt="" className="resourceimg" />
                        <p className="resourceText">Getting started with Airbnb’s cleaning protocol</p>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} lg={6} md={6}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <img src={Resource3} alt="" className="resourceimg" />
                        <p className="resourceText">The do's and don’ts of providing self check-in</p>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} lg={6} md={6}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <img src={Resource4} alt="" className="resourceimg" />
                        <p className="resourceText">What you need to know about hosting families and pets</p>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} lg={6} md={6}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <img src={Resource5} alt="" className="resourceimg" />
                        <p className="resourceText">How to make your space comfortable for remote workers</p>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} lg={6} md={6}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <img src={Resource6} alt="" className="resourceimg" />
                        <p className="resourceText">The best amenities to offer right now</p>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Opportunities