import React, { useState, useRef } from "react";
import styled from "styled-components";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";

import DatePickers from "../../../components/form/datePicker";
import Buttons from "../../../components/form/button";
import { addBookingApi } from "../../../services/booking/addBooking";
import { GuestCount } from "../../../components/shared/GuestCount";
import { Grid } from "@mui/material";
import BasicInfo from "../../Admin-Panel/AddPropertyPage/addPropertiesSections/basicInfo";
import { RadioButton } from "primereact/radiobutton";

export const ReserveCardContactHost = ({
  id,
  isSelect,
  propertyDetail,
  formik,
  onClick,
}) => {
  const [loading, setLoading] = useState(false);

  const userData = JSON.parse(localStorage.getItem("userData")) || null;
  const [displayResponsive, setDisplayResponsive] = useState(false);
  const [position, setPosition] = useState("center");
  const [displayPosition, setDisplayPosition] = useState(false);
  const user = localStorage.getItem("user");
  const userToken = localStorage.getItem("userToken");
  const dialogFuncMap = {
    displayPosition: setDisplayPosition,
    displayResponsive: setDisplayResponsive,
  };

  const [report, setReport] = useState(null);
  const [screen, setScreen] = useState(0);
  const toast = useRef(null);
  return (
    <ReserveCardStyled>
      <Toast ref={toast} position='bottom-right' />
      <div className='box-widget fl-wrap'>
        <div className='box-widget-fixed-init fl-wrap output' id='sec-contact'>
          <div className='box-widget-title fl-wrap box-widget-title-color color-bg'>
            Reserve
          </div>
          <div className='box-widget-content fl-wrap'>
            <div className='custom-form'>
              <div className='row'>
                <div
                  className='col-md-12'
                  style={{
                    textAlign: "left",
                  }}
                >
                  <label className='label-field'>Check In/ Check Out</label>

                  <DatePickers
                    basePrice={propertyDetail?.perNightPrice}
                    handlers
                    toast={toast}
                    propertyPolicy={propertyDetail?.propertyPolicy}
                    propertyPricePolicy={propertyDetail?.propertyPricePolicy}
                    handleDateStart={(value) =>
                      formik.setFieldValue("startDate", value)
                    }
                    handleDateEnd={(value) =>
                      formik.setFieldValue("endDate", value)
                    }
                    id={id}
                  />
                </div>
              </div>
              <div
                className='row'
                style={{
                  textAlign: "left",
                  marginTop: "20px",
                }}
              >
                <div className='col-md-12'>
                  <label className='label-field'>Persons</label>
                  <GuestCount
                    isSelect={isSelect}
                    handleChange={(value, count) => {
                      formik.setFieldValue("noOfGuest", value);
                      formik.setFieldValue("noOfAdults", count.adults);
                      formik.setFieldValue("noOfChildren", count.children);
                      formik.setFieldValue("noOfInfants", count.infants);
                      formik.setFieldValue("noOfPets", count.pets);
                    }}
                  />
                </div>
              </div>

              <Buttons
                style={{ height: 50, marginTop: "20px" }}
                title={"submit"}
                onSubmit={formik.handleSubmit}
                // onSubmit={formik.handleSubmit}
                isLoading={loading}
              />

              <small
                id='username2-help'
                className='p-error'
                style={{
                  display: "block",
                  color: "red",
                  textAlign: "left",
                }}
              >
                {formik.touched.startDate && formik.errors.startDate} <br />
                {formik.touched.endDate && formik.errors.endDate}
              </small>
            </div>
          </div>
        </div>
      </div>
      {/* report text */}
      <div
        className='box-widget fl-wrap'
        style={{
          marginTop: "20px",
        }}
      >
        <i className='fal fa-flag' style={{ color: "white" }}></i>
        <div
          onClick={() => onClick("displayResponsive")}
          style={{
            fontSize: "14px",
            color: "#fff",
            marginBottom: "0px",
            textAlign: "center",
            textDecorationLine: "underline",
            background: "none",
            display: "inline",
            margin: "0px 8px",
            cursor: "pointer",
          }}
        >
          Report this listing
        </div>
      </div>
    </ReserveCardStyled>
  );
};

const ReserveCardStyled = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: -160px;
  width: 100%;

  .label-field {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 10px;
    display: block;
  }
`;
