import { API_URL } from "../../config";
import axios from "../../api/axios";

export const getExtraPaymentMessagesApi = async (extraPayId) => {
  try {
    const response = await axios.get(
      API_URL + `booking-extra-payment/fetch-messages/${extraPayId}`
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log(
        "Bad Response of booking-extra-payment/fetch-messages : ",
        response
      );
      return response;
    }
  }
};
