import { Rating, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import SingleReview from '../../screens/Admin-Panel/Review/SingleReview';
import { getGuestReviewApi } from '../../services/review/getGuestReview';

const Reviews = ({ propertyDetail }) => {
  const { id } = propertyDetail;
  const [guestReview, setGuestReview] = useState([]);

  let total = 0;
  let totalLength = guestReview?.items?.length;
  for (let i = 0; i < guestReview?.items?.length; i++) {
    total += guestReview?.items[i].over_all;
  }
  let totalRating = total / totalLength;

  useEffect(() => {
    const getGuestReview = async () => {
      const res = await getGuestReviewApi(id);
      setGuestReview(res.data.data);
    };
    getGuestReview();
  }, [id]);

  return (
    <div
      className='list-single-main-item fl-wrap'
      id='sec7'
      style={{ backgroundColor: '#1b182b' }}
    >
      {guestReview?.items?.length > 0 ? (
        <>
          <div className='list-single-main-item-title'>
            <h3>
              Reviews <span>{guestReview?.items?.length}</span>
            </h3>
          </div>
          <div className='list-single-main-item_content fl-wrap'>
            <div className='reviews-comments-wrap fl-wrap'>
              <div className='review-total'>
                <span className='review-number blue-bg'>
                  {totalRating ? parseInt(totalRating).toFixed(1) : 0}
                </span>
                <div
                  className='listing-rating'
                  data-starrating2='{totalRating}'
                >
                  <Rating
                    name='half-rating-read'
                    value={totalRating ? totalRating : 0}
                    precision={0.5}
                    readOnly
                  />
                </div>
              </div>
              {guestReview?.items?.map((item, index) => (
                <SingleReview key={index} item={item} />
              ))}
            </div>
          </div>
        </>
      ) : (
        <Typography sx={{ textAlign: 'center' }}>
          No Review are Available
        </Typography>
      )}
    </div>
  );
};

export default Reviews;
