import { Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import Card from '../../../../components/dasboard/card';
import CheckBoxLabel from '../../../../components/form/checkbox';

const AmenitiesEditProfile = ({ amenitiesValues, handleCheckbox }) => {
  return (
    <>
      <div className='dasboard-widget-title fl-wrap'>
        <h5>
          <i className></i>
          All Amenities
        </h5>
      </div>

      <div className='dasboard-widget-box fl-wrap'>
        <div>
          <Grid container>
            <Grid item xs={12}>
              {amenitiesValues?.map((amenity, i) => {
                return (
                  <>
                    <Typography
                      variant={'h6'}
                      style={{
                        paddingTop: 25,
                        paddingBottom: 5,
                        textAlign: 'initial',
                      }}
                    >
                      {amenity?.title}
                    </Typography>
                    <Typography
                      style={{
                        textAlign: 'initial',
                        fontSize:15
                      }}
                    >
                      {amenity?.description}
                    </Typography>


                    <Grid
                      container
                      rowSpacing={{ xs: 1, sm: 1, md: 1 }}
                      columnSpacing={{ xs: 1, sm: 1, md: 1 }}
                      style={{ textAlign: 'left' }}
                    >
                      {amenity?.amenities?.map((amentityCheckbox, j) => {
                        return (
                          <Grid
                            item
                            xs={4}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              '& .MuiBox-root': {
                                marginTop: '0 !important',
                              },
                            }}
                          >
                            <CheckBoxLabel
                              value={amentityCheckbox?.title}
                              checked={amentityCheckbox?.check_status}
                              onChange={(e) =>
                                handleCheckbox(amentityCheckbox.id, j, i, e)
                              }
                            />
                            <div
                              style={{ alignItems: 'center', display: 'flex' }}
                            >
                              <img
                                class='amenity-icons'
                                src={amentityCheckbox?.icon}
                                style={{
                                  width: '30px',
                                  height: '30px',
                                }}
                              />
                              <span
                                style={{
                                  color: 'white',
                                  fontSize: '15px',
                                  marginLeft: '10px',
                                }}
                              >
                                {amentityCheckbox?.title}
                              </span>
                            </div>
                          </Grid>
                        );
                      })}
                    </Grid>
                    <Divider style={{ paddingBottom: 30 }} />
                  </>
                );
              })}
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};
export default AmenitiesEditProfile;
