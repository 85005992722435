import 'firebase/compat/messaging';
import { getAuth } from 'firebase/auth';
import firebase from 'firebase/compat/app';
const firebaseConfig = {
  apiKey: 'AIzaSyCJnEgMXGy3O8_xBM1tVyDIgKpmKnVyCJA',
  authDomain: 'staywo-353711.firebaseapp.com',
  projectId: 'staywo-353711',
  storageBucket: 'staywo-353711.appspot.com',
  messagingSenderId: '345233581403',
  appId: '1:345233581403:web:a7946b601461b82d22667e',
  measurementId: 'G-3175BLH18G',
};

const app = firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging();

const { REACT_APP_VAPID_KEY } = process.env;
const publicKey = REACT_APP_VAPID_KEY;

export const getToken = async (setTokenFound) => {
  let currentToken = '';
  try {
    currentToken = await messaging.getToken({ vapidKey: publicKey });
    if (currentToken) {
      setTokenFound(true);
    } else {
      setTokenFound(false);
    }
  } catch (error) {
    console.log('An error occurred while retrieving token. ', error);
  }

  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });

export const auth = getAuth(app);
