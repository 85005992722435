import { API_URL } from '../../config';
import axios from 'axios';

export const getUserWalletApi = async (userId) => {
  try {
    const response = await axios.get(
      API_URL + `wallet/get-user-wallet/${userId}`
    );
    return response.data.data;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of get user wallet : ', response);
      return response;
    }
  }
};
