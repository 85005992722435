import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Typographyy from "../../../components/typography";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { BiUserPlus } from "react-icons/bi";

import Radio from "@mui/material/Radio";
import { Box } from "@mui/system";
import FormControlLabel from "@mui/material/FormControlLabel";

import Button from "@mui/material/Button";

import CardContent from "@mui/material/CardContent";

import Header from "../../../components/header";
import { IoIosArrowBack } from "react-icons/io";

export default function PhotoId() {
  const [radioo, setRadioo] = React.useState(false);
  const [tp, settp] = React.useState(false);
  const [airnab, setAirnab] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("mal");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  return (
    <>
      <Header></Header>
      <Grid
        container
        className="gridpadding1"
        style={{ background: "#282c34" }}
      >
        <Grid item xs={12} sm={12} md={6}>
          <CardContent
            sx={{
              textAlign: "left",
              padding: 0,
            }}
          >
            <Typography
              style={{ fontSize: "22px", fontWight: "bold", color: "#ffffff" }}
            >
              How would you like to add your a photo of your ID
            </Typography>
          </CardContent>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "15px",
            }}
          >
            <div style={{ textAlign: "left" }}>
              <Typography
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "#ffffff",
                }}
              >
                Upload Existing photo{" "}
              </Typography>

              <Typography
                variant="h4"
                sx={{ marginTop: 0.1, textAlign: "left" }}
                className="text-white"
              >
                {" "}
                Recommended
              </Typography>
            </div>
            <FormControlLabel value="" control={<Radio />} />
          </div>
          <hr />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              marginTop: 3,
            }}
          >
            <div>
              <Typography
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "#ffffff",
                }}
              >
                Take photo from your webcam
              </Typography>
            </div>

            <FormControlLabel
              control={
                <Radio
                  checked={selectedValue === "mal"}
                  onChange={handleChange}
                  value="mal"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
              }
            />
          </div>
          <hr />

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              marginTop: 3,
            }}
          >
            <div>
              <Typography
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "#ffffff",
                }}
              >
                Take photo with your Staywo mobile app
              </Typography>
            </div>
            <FormControlLabel
              control={
                <Radio
                  checked={selectedValue === "fem"}
                  onChange={handleChange}
                  value="fem"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
              }
            />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between",marginTop:"70px" }}>
          <div style={{ display: "flex",alignItems:"center" }}>
          <IoIosArrowBack style={{fontSize:"22px",color:"white"}}/>
          <button
            style={{
              fontSize: "20px",
              background: "transparent",
              border: "none",
              color: "#ffffff",
            }}
          >
           Back{" "}
          </button>

          </div>

          <div style={{ display: "flex",alignItems:"center" }}>
        

          <Button
            style={{ padding: "10px", fontSize: "15px" }}
            variant="contained"
          >

              <BiUserPlus style={{fontSize:"22px",color:"white",marginRight:"3px"}} />Continue
          </Button>
          </div>
        </div>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          sx={{
            paddingLeft: "60px",
            paddingRight: "60px",

            textAlign: "center",
          }}
          className="manpad"
        >
          <div
            style={{
              border: "1px solid white",
              borderRadius: "10px",
              padding: "10px",
            }}
          >
            <div sx={{ padding: 3, textAlign: "center" }}>
              <Link to="">
                <Typographyy
                  variant="h5"
                  sx={{
                    color: "white",
                    marginTop: 2,
                    textDecoration: "underline",
                  }}
                  bold="bold"
                  text="Your Privacy"
                />
              </Link>

              <Typography
                style={{
                  color: "white",
                  padding: "2px",
                  fontSize: "18px",
                  textAlign: "center",
                }}
              >
                We aim to keep the data you share during this process private,
                safe, and secure. Learn more in our
              </Typography>

              <Link to="">
                <Typographyy
                  variant="h5"
                  sx={{
                    color: "white",
                    padding: "2px",

                    textDecoration: "underline",
                  }}
                  bold="bold"
                  text="Privacy Policy"
                />
              </Link>
              <Link to="">
                <Typographyy
                  variant="h5"
                  sx={{
                    color: "white",
                    padding: "2px",

                    textDecoration: "underline",
                  }}
                  bold="bold"
                  text="How this works"
                />
              </Link>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
