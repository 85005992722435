import React, { useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { Grid, Typography, Link, Divider } from '@mui/material';
import { ActivationStatus } from '../../../services/Activation/ActivationStatus';
import { Toast } from 'primereact/toast';
import ConfirmationDialog from '../../../components/ConfirmationDialog';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  color: 'text.primary',
  backgroundColor: '#1b182b',
  boxShadow: 24,
  p: 4,
  textAlign: 'start',
  borderRadius: 5,
};
export default function DeactivePopUp() {
  const toast = useRef();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [active, setActive] = useState('Active');

  const handleDeactive = async () => {
    const statusAccount = await ActivationStatus();
    handleClose();
    if (statusAccount.status === 200) {
      toast.current.show({
        severity: 'success',
        summary: 'Account Deactive!',
        detail: 'Account Deactive successfully!',
        life: 10000,
      });
      setActive('Deactive');
      setTimeout(() => {
        localStorage.clear();
        window.location = '/login';
      }, 1500);
    } else {
      toast.current.show({
        severity: 'error',
        summary: 'Error Account Deactivation!',
        detail: 'Error In Account Deactivation !',
        life: 10000,
      });
    }
  };
  return (
    <>
      <Toast ref={toast} position='bottom-right' />

      <Link variant='contained' onClick={handleOpen}>
        Deactive
      </Link>

      <ConfirmationDialog
        message='Are you sure you want to deactivate your account?'
        header='Confirmation'
        icon='pi pi-exclamation-triangle'
        open={open}
        onHide={() => setOpen(false)}
        onConfirm={handleDeactive}
        confirmButtonLoading={false}
        confirmButtonColor='success'
      />
    </>
  );
}
