import React from "react";
import Slider from "react-slick";
import styled from "styled-components";

const SlickSlider = (props) => {
  var settings = {
    arrows: true,
    infinite: props.children?.length > 4 ? true : false,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: props.children?.length > 4 ? true : false,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    swipeToSlide: true,
    rtl: props.rightToLeft,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplaySpeed: 1500,
          pauseOnHover: true,
          swipeToSlide: true,
          infinite: props.children?.length > 4 ? true : false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          autoplaySpeed: 1500,
          pauseOnHover: true,
          swipeToSlide: true,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };
  return <SliderStyled {...settings}>{props.children}</SliderStyled>;
};

export default SlickSlider;

const SliderStyled = styled(Slider)`
  .slick-prev {
    &:before {
      @media (max-width: 768px) {
        display: none;
      }
    }
  }
  .slick-next {
    &:before {
      @media (max-width: 768px) {
        display: none;
      }
    }
  }
`;
