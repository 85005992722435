import React, { useState } from 'react';
import Header from '../components/header';
import Footer from './footer';
import getUserApi from '../services/User/getUser';
import { Markup } from 'interweave';
import DUMMYAVATAR from '../assets/images/avatar/5.jpg';
import { getUserProperties } from '../services/properties/getProperty';
import { Grid, Rating, Typography } from '@mui/material';
import SkeletonLoader from './skeletonLoader';
import ErrorMessage from './errorMessage';
import Property from './mainComponents/property';
import Pagination from './Pagination/Pagination';
import SingleReview from '../screens/Admin-Panel/Review/SingleReview';
import { getHostPropertiesReviewsApi } from '../services/review/getHostPropertyReviews';
import { useParams } from 'react-router-dom';
import getUserByIdApi from '../services/User/getUserById';
const userData = JSON.parse(localStorage.getItem('userData')) || null;

const UserProfileDetail = () => {
  const { id } = useParams();
  const [user, setUser] = useState({});
  const [properties, setProperties] = useState([]);
  const [isLoading, setIsLoading] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalReviewRecords, setTotalReviewRecords] = useState(0);
  const [customFirst, setCustomFirst2] = useState(0);
  const [customFirstReview, setCustomFirstReview2] = useState(0);
  const [customRows, setCustomRows2] = useState(6);
  const [customRowsReview, setCustomRowsReview2] = useState(12);
  const [userEmail, setUserEmail] = useState('');

  const getUser = async () => {
    const data = await getUserApi(id);
    setUser(data.data);
  };

  React.useEffect(() => {
    getUser();
  }, [id]);

  const getPropertiesByUserId = async (id, page, limit) => {
    const res = await getUserProperties(id, page, limit);
    if (res.status === 200) {
      setIsLoading(false);
      setProperties(res?.data.data);
      setTotalRecords(res?.data?.total);
    } else {
      setIsLoading(false);
    }
  };
  React.useEffect(() => {
    setIsLoading(true);
    getPropertiesByUserId(id, 0, 10);
  }, [id]);

  React.useEffect(() => {
    const getUserById = async () => {
      const res = await getUserByIdApi(id);
      setUserEmail(res?.email);
    };
    getUserById();
  }, [id]);

  const handlePageClick = (event) => {
    setCustomFirst2(event.first);
    setCustomRows2(event.rows);
    getPropertiesByUserId(id, event.page, event.rows);
  };

  const dropdownOptions = [
    { label: 6, value: 6 },
    { label: 10, value: 10 },
    { label: 20, value: 20 },
    { label: 50, value: 50 },
  ];

  const [guestReview, setGuestReview] = useState([]);

  let total = 0;
  let totalLength = guestReview?.items?.length;
  for (let i = 0; i < guestReview?.items?.length; i++) {
    total += guestReview?.items[i].over_all;
  }
  let totalRating = total / totalLength;

  const getPropertiesReviewByUserId = async (id, page) => {
    const res = await getHostPropertiesReviewsApi(id, page);
    if (res.status === 200) {
      setIsLoading(false);
      setGuestReview(res?.data.data);
      setTotalReviewRecords(res?.data?.data?.meta?.totalItems);
    } else {
      setIsLoading(false);
    }
  };
  React.useEffect(() => {
    setIsLoading(true);
    getPropertiesReviewByUserId(userData?.id, 0);
  }, [userData?.id]);
  const handlePageClickReview = (event) => {
    setCustomFirstReview2(event.first);
    setCustomRowsReview2(event.rows);
    getPropertiesReviewByUserId(userData?.id, event.page + 1);
  };
  const dropdownOptionsReview = [
    { label: 12, value: 12 },
    { label: 24, value: 24 },
    { label: 36, value: 36 },
    { label: 48, value: 48 },
  ];

  return (
    <>
      <Header />
      <div id='wrapper'>
        {/* content */}
        <div className='content'>
          {/* col-list-wrap */}
          <section className='gray-bg small-padding '>
            <div className='container'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='card-info smpar fl-wrap'>
                    <div className='bg-wrap bg-parallax-wrap-gradien'>
                      <div className='bg' data-bg='images/bg/8.jpg' />
                    </div>
                    <div className='card-info-media'>
                      <img
                        // className='bg'
                        src={
                          user?.profilePicture
                            ? user?.profilePicture
                            : DUMMYAVATAR
                        }
                        alt=''
                        style={{ width: '100%', height: '100%' }}
                      />
                    </div>
                    <div className='card-info-content'>
                      <div className='agent_card-title fl-wrap'>
                        <h4>
                          {' '}
                          {user?.firstName} {user?.lastName}{' '}
                        </h4>
                        <div className='geodir-category-location fl-wrap'>
                          <h5>
                            <a>
                              {' '}
                              {user?.userAddress?.city},{' '}
                              {user?.userAddress?.country}
                            </a>
                          </h5>
                        </div>
                      </div>
                      <div className='list-single-stats'>
                        <ul className='no-list-style'>
                          <li>
                            <span className='bookmark-counter'>
                              <i className='fas fa-sitemap' /> Listings -{' '}
                              {properties?.length}{' '}
                            </span>
                          </li>
                          <li style={{ background: 'none', marginLeft: 700 }}>
                            <span>
                              {user?.verified === true && (
                                <div
                                  className='tolt'
                                  data-microtip-position='top'
                                  data-tooltip='User Verified'
                                >
                                  <i
                                    className='fal fa-user-check'
                                    style={{ color: 'white', fontSize: 15 }}
                                  />
                                </div>
                              )}
                            </span>
                          </li>
                          <li style={{ background: 'none' }}>
                            <span>
                              {user?.isActive === true && (
                                <div
                                  className='tolt'
                                  data-microtip-position='top'
                                  data-tooltip='Email Verified'
                                >
                                  <i
                                    className='fa fa-envelope'
                                    style={{ color: 'white', fontSize: 15 }}
                                  />
                                </div>
                              )}
                            </span>
                          </li>
                          <li style={{ background: 'none' }}>
                            <span>
                              {user?.isFacebookVerified === true && (
                                <div
                                  className='tolt'
                                  data-microtip-position='top'
                                  data-tooltip='Facebook Verified'
                                >
                                  <i
                                    className='fa fa-facebook-square'
                                    style={{ color: 'white', fontSize: 15 }}
                                  />
                                </div>
                              )}
                            </span>
                          </li>
                          <li style={{ background: 'none' }}>
                            <span>
                              {user?.isGoogleVerified === true && (
                                <div
                                  className='tolt'
                                  data-microtip-position='top'
                                  data-tooltip='Google Verified'
                                >
                                  <i
                                    className='fa fa-google'
                                    style={{ color: 'white', fontSize: 15 }}
                                  />
                                </div>
                              )}
                            </span>
                          </li>
                          <li style={{ background: 'none' }}>
                            <span>
                              {user?.isPhoneNumberConfirmed === true && (
                                <div
                                  className='tolt'
                                  data-microtip-position='top'
                                  data-tooltip='Phone Verified'
                                >
                                  <i
                                    className='fa fa-phone'
                                    style={{ color: 'white', fontSize: 15 }}
                                  />
                                </div>
                              )}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className='list-single-main-container fl-wrap'>
                    {/* list-single-main-item */}
                    <div
                      className='list-single-main-item fl-wrap'
                      style={{ textAlign: 'start' }}
                    >
                      <div className='list-single-main-item-title'>
                        <h3>About Host</h3>
                      </div>
                      <div className='list-single-main-item_content fl-wrap'>
                        {user?.description ? (
                          <Markup
                            content={user?.description}
                            className='content-markup'
                          />
                        ) : (
                          <div style={{ color: '#fff' }}>
                            Host shared no details
                          </div>
                        )}
                      </div>
                    </div>
                    {/* list-single-main-item end */}
                  </div>
                  {/* content-tabs-wrap */}
                  <div className='content-tabs-wrap tabs-act fl-wrap'>
                    <div className='content-tabs fl-wrap'>
                      <ul className='tabs-menu fl-wrap no-list-style'>
                        <li className='current'>
                          <a href='#tab-listing'>Listing</a>
                        </li>
                        <li>
                          <a href='#tab-reviews'>Reviews</a>
                        </li>
                      </ul>
                    </div>
                    {/*tabs */}
                    <div className='tabs-container'>
                      {/*tab */}
                      <div className='tab'>
                        <div id='tab-listing' className='tab-content first-tab'>
                          {/* listing-item-wrap*/}
                          <div className='listing-item-container one-column-grid-wrap  box-list_ic fl-wrap'>
                            {isLoading === true ? (
                              <Loader />
                            ) : (
                              <>
                                {properties.length === 0 ? (
                                  <Grid container>
                                    <Grid item xs={12}>
                                      <ErrorMessage />
                                    </Grid>
                                  </Grid>
                                ) : (
                                  <Grid
                                    container
                                    rowSpacing={{ xs: 1, sm: 1, md: 1 }}
                                    columnSpacing={{ xs: 1, sm: 1, md: 2 }}
                                  >
                                    {properties?.map((property) => {
                                      return (
                                        <Grid item xs={12} sm={6} md={12}>
                                          <Property property={property} />
                                        </Grid>
                                      );
                                    })}
                                  </Grid>
                                )}
                              </>
                            )}
                            {!isLoading ? (
                              <Pagination
                                totalRecords={totalRecords}
                                onPageChange={handlePageClick}
                                dropdownOptions={dropdownOptions}
                                customFirst={customFirst}
                                customRows={customRows}
                              />
                            ) : null}
                          </div>
                          {/* listing-item-wrap end*/}
                          {/* pagination here  */}
                        </div>
                      </div>
                      {/*tab  end*/}
                      {/*tab */}
                      <div className='tab'>
                        <div id='tab-reviews' className='tab-content'>
                          <div
                            className='list-single-main-container fl-wrap'
                            style={{ marginTop: 30 }}
                          >
                            <div
                              className='list-single-main-item fl-wrap'
                              id='sec7'
                              style={{ backgroundColor: '#1b182b' }}
                            >
                              {guestReview?.items?.length > 0 ? (
                                <>
                                  <div className='list-single-main-item-title'>
                                    <h3>
                                      Reviews{' '}
                                      <span>
                                        {guestReview?.meta?.totalItems}
                                      </span>
                                    </h3>
                                  </div>
                                  <div className='list-single-main-item_content fl-wrap'>
                                    <div className='reviews-comments-wrap fl-wrap'>
                                      <div className='review-total'>
                                        <span className='review-number blue-bg'>
                                          {totalRating
                                            ? totalRating.toFixed(1)
                                            : 0}
                                        </span>
                                        <div
                                          className='listing-rating'
                                          data-starrating2='{totalRating}'
                                        >
                                          <Rating
                                            name='half-rating-read'
                                            value={
                                              totalRating ? totalRating : 0
                                            }
                                            precision={0.5}
                                            readOnly
                                          />
                                        </div>
                                      </div>
                                      {guestReview?.items?.map(
                                        (item, index) => (
                                          <SingleReview
                                            key={index}
                                            item={item}
                                          />
                                        )
                                      )}
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <Typography sx={{ textAlign: 'center' }}>
                                  No Review are Available
                                </Typography>
                              )}
                              {!isLoading ? (
                                <Pagination
                                  totalRecords={totalReviewRecords}
                                  onPageChange={handlePageClickReview}
                                  dropdownOptions={dropdownOptionsReview}
                                  customFirst={customFirstReview}
                                  customRows={customRowsReview}
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*tab end*/}
                    </div>
                    {/*tabs end*/}
                  </div>
                  {/* content-tabs-wrap end */}
                </div>
                {/* col-md 8 end */}
                {/*  sidebar*/}

                {/*   sidebar end*/}
              </div>
            </div>
            <div className='limit-box fl-wrap' />
          </section>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default UserProfileDetail;

const Loader = () => {
  return (
    <Grid
      container
      rowSpacing={{ xs: 1, sm: 1, md: 2 }}
      columnSpacing={{ xs: 1, sm: 1, md: 2 }}
    >
      <Grid item xs={12} sm={6} md={6}>
        <SkeletonLoader height={200} />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <SkeletonLoader height={200} />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <SkeletonLoader height={200} />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <SkeletonLoader height={200} />
      </Grid>
    </Grid>
  );
};
