import React, { useState, useEffect } from 'react';
import Header from '../../../components/header';
import getPage from '../../../services/TermsPages/getPage';
import './shared.css';
import { Markup } from "interweave";

const PrivacyPolicy = () => {
  const [privacyData, setprivacyData] = useState();
  const getPrivacyData = async () => {
    const data = await getPage('privacy-policy');
    if (data) {
      setprivacyData(data);
    }
  };
  useEffect(() => {
    getPrivacyData();
  }, []);

  return (
    <div className="main_p_and_t">
      <Header />
      <div className="container_p_and_t">
        <h1 className="main_tag_for_p_and_t">{privacyData?.name}</h1>
        <div
          style={{padding: '0% 10%'}}
        >
          <Markup
            content={privacyData?.content}
            className='content-markup'
          />
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
