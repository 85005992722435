import { API_URL } from '../../config';
import axios from 'axios';

export const getBookingUpdateRequestApi = async (id) => {
  try {
    const response = await axios.get(
      API_URL +
        `booking-update-request?page=0&limit=50&requestStatus=PENDING` +
        id
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of get booking update request  : ', response);
      return response;
    }
  }
};
