import { useEffect, useState } from 'react';
import { Divider, Grid, Typography, Tooltip, Button } from '@mui/material';
import React from 'react';
import Input from '../../../../components/form/input';
import { Image } from 'primereact/image';
import Buttons from '../../../../components/form/button';
import Upload from '../../../../components/form/upload';
import { AiFillDelete } from 'react-icons/ai';
import { deleteGalleryImageApi } from '../../../../services/properties/editProperty';
import { Toast } from 'primereact/toast';
import ConfirmationDialog from '../../../../components/ConfirmationDialog';

const Photos = ({
  photos,
  formik,
  images,
  setImages,
  imagesURLs,
  setImagesURLs,
  thumbnailURLs,
  setThumbnailURLs,
  setThumbnail,
  thumbnail,
  errorImages,
  isButtonLoading,
  errorThumbnail,
}) => {
  const [openThumbnail, setOpenThumbnail] = useState(false);
  const [openImage, setOpenImage] = useState(false);
  useEffect(() => {
    if (thumbnail.length > 0) {
      const newThumbnailUrls = [];
      thumbnail.forEach((image) =>
        newThumbnailUrls.push(URL.createObjectURL(image))
      );
      setThumbnailURLs(newThumbnailUrls);
    }
    if (images.length > 0) {
      const newImageUrls = [];
      images.forEach((image) => newImageUrls.push(URL.createObjectURL(image)));
      setImagesURLs(newImageUrls);
    }
  }, [images, thumbnail]);

  const onImageChange = (e) => {
    setImages((data) => [...data, ...e.target.files]);
  };
  const onThumbnailChange = (e) => {
    setThumbnail((data) => [...data, ...e.target.files]);
  };

  const clearImage = (imageSrc) => {
    const filterImage = imagesURLs.filter((name) => name != imageSrc);
    setImagesURLs(filterImage);
    //re-again url object
    const newImageUrls = [];
    filterImage.forEach((image) =>
      newImageUrls.push(URL.createObjectURL(image))
    );
    setImages(newImageUrls);
  };

  const clearThumbnailImage = (imageSrc) => {
    const filterImage = thumbnailURLs.filter((name) => name != imageSrc);
    setThumbnailURLs(filterImage);
    //re-again url object
    const newImageUrls = [];
    filterImage.forEach((image) =>
      newImageUrls.push(URL.createObjectURL(image))
    );
    setThumbnail(newImageUrls);
  };

  const toast = React.useRef(null);
  function youtube_parser(url) {
    if (url) {
      var r,
        rx =
          /(https?:\/\/)?(((m|www)\.)?(youtube(-nocookie)?|youtube.googleapis)\.com.*(v\/|v=|vi=|vi\/|e\/|embed\/|user\/.*\/u\/\d+\/)|youtu\.be\/)([_0-9a-z-]+)/i;
      r = url.match(rx);
      return r[8];
    }
  }

  const [deleteImageId, setDeleteImgId] = useState(0);
  const [
    showImagesDeleteConfirmationDialog,
    setShowImagesDeleteConfirmationDialog,
  ] = useState(false);

  const deleteImageFromGallery = async () => {
    const del = await deleteGalleryImageApi(deleteImageId);
    if (del.status === 200 || del.status === 201) {
      toast.current.show({
        severity: 'success',
        summary: 'Gallery Image Deleted!',
        detail: 'Gallery image deleted successfully!',
        life: 10000,
      });
      setShowImagesDeleteConfirmationDialog(false);
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } else {
      setShowImagesDeleteConfirmationDialog(false);
      toast.current.show({
        severity: 'error',
        summary: 'Error deleting gallery image!',
        detail: 'Gallery image cannot be deleted!',
        life: 10000,
      });
    }
  };

  return (
    <>
      <ConfirmationDialog
        message='Are you sure you want to delete the gallery image?'
        header='Confirmation'
        icon='pi pi-exclamation-triangle'
        open={showImagesDeleteConfirmationDialog}
        onHide={() => setShowImagesDeleteConfirmationDialog(false)}
        onConfirm={deleteImageFromGallery}
        confirmButtonColor='success'
        confirmButtonLoading={false}
      />
      <div className='dasboard-widget-title fl-wrap'>
        <h5>
          <i className></i>
          Property Photos
        </h5>
      </div>
      <Toast ref={toast} position='bottom-right' />
      <div className='dasboard-widget-box fl-wrap'>
        <div>
          <Typography
            variant={'h6'}
            sx={{ paddingBottom: 1, textAlign: 'initial' }}
          >
            Main Picture
          </Typography>
          <p
            style={{
              paddingBottom: 5,
              textAlign: 'left',
              fontSize: '1rem',
              fontWeight: 400,
              letterSpacing: '0.00938em',
              color: '#d6e3e5',
            }}
          >
            Your main picture is a guest’s first impression of your listing.
          </p>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 1, md: 2 }}
            columnSpacing={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={12} sm={12} md={12}>
              <Image
                src={photos?.values?.thumbnail}
                alt='Image Text'
                imageStyle={{
                  marginTop: 25,
                  width: '100%',
                  height: 800,
                  borderRadius: 10,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <Buttons
                title={'Change Main Picture'}
                isLoading={false}
                onSubmit={() => setOpenThumbnail(true)}
              />
            </Grid>

            {openThumbnail ? (
              <Grid
                item
                xs={12}
                className={
                  errorThumbnail && thumbnail?.length == 0
                    ? 'upload-image-error'
                    : null
                }
              >
                <Upload
                  label={'Upload Main Picture'}
                  handleChange={(e) => onThumbnailChange(e)}
                  disabled={thumbnail?.length >= 1 ? true : false}
                  multiple={false}
                />
                {errorThumbnail && thumbnail?.length == 0 ? (
                  <p className='upload-error'>{errorThumbnail}</p>
                ) : null}
                <div style={{ display: 'inline' }}>
                  {thumbnailURLs?.map((thumbnailSrc) => (
                    <div className='image-div '>
                      <img
                        src={thumbnailSrc}
                        style={{
                          maxWidth: '100%',
                          height: '100%',
                        }}
                      />
                      <div
                        className='clear-button '
                        onClick={() => clearThumbnailImage(thumbnailSrc)}
                      >
                        <i
                          className='fa fa-times'
                          style={{
                            cursor: 'pointer',
                            color: '#2e94f7',
                          }}
                        ></i>
                      </div>
                    </div>
                  ))}
                </div>
              </Grid>
            ) : null}
          </Grid>
          <Divider sx={{ paddingTop: 3 }} />
          <Typography
            variant={'h6'}
            sx={{ paddingBottom: 3, paddingTop: 3, textAlign: 'initial' }}
          >
            Gallery Photos
          </Typography>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 1, md: 1 }}
            columnSpacing={{ xs: 1, sm: 1, md: 2 }}
          >
            {photos?.values?.images?.map((image) => {
              return (
                <Grid item xs={12} sm={12} md={3} sx={{ position: 'relative' }}>
                  <Image
                    src={image?.locationUrl}
                    alt='Image Text'
                    imageStyle={{
                      marginTop: 15,
                      width: 'auto',
                      height: 200,
                      maxWidth: '330px',
                      borderRadius: 10,
                    }}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      top: 30,
                      right: 8,
                      cursor: 'pointer',
                      borderRadius: '100%',
                      backgroundColor: 'rgb(203 213 225)',
                      padding: '3px',
                    }}
                  >
                    <Tooltip title='Delete' placement='left' arrow>
                      <Button
                        onClick={() => {
                          setShowImagesDeleteConfirmationDialog(true);
                          setDeleteImgId(image?.id);
                        }}
                        style={{ padding: 0, minWidth: 'auto' }}
                      >
                        <AiFillDelete
                          style={{ fontSize: '25px', color: '#0089eb' }}
                        />
                      </Button>
                    </Tooltip>
                  </div>
                </Grid>
              );
            })}
          </Grid>
          <Grid container sx={{ marginTop: 3 }}>
            <Grid item xs={12} sm={12} md={3}>
              <Buttons
                title={'Upload More Image'}
                isLoading={false}
                onSubmit={() => setOpenImage(true)}
              />
            </Grid>
          </Grid>
          {openImage ? (
            <Grid
              item
              xs={12}
              className={
                errorImages && images?.length == 0 ? 'upload-image-error' : null
              }
              style={{ marginTop: '20px' }}
            >
              <Upload
                label={'Upload Property Gallery'}
                handleChange={onImageChange}
                disabled={false}
                multiple={true}
              />
              {errorImages && images?.length == 0 ? (
                <p className='upload-error'>{errorImages}</p>
              ) : null}
              <div style={{ display: 'inline' }}>
                {imagesURLs?.map((imageSrc) => (
                  <div className='image-div '>
                    <img
                      src={imageSrc}
                      style={{
                        maxWidth: '100%',
                        height: '100%',
                      }}
                    />
                    <div
                      className='clear-button '
                      onClick={() => clearImage(imageSrc)}
                    >
                      <i
                        className='fa fa-times'
                        style={{
                          cursor: 'pointer',
                          color: '#2e94f7',
                        }}
                      ></i>
                    </div>
                  </div>
                ))}
              </div>
            </Grid>
          ) : null}

          <Grid
            container
            rowSpacing={{ xs: 1, sm: 1, md: 2 }}
            columnSpacing={{ xs: 1, sm: 1, md: 2 }}
            style={{ marginTop: 5 }}
          >
            <Grid item xs={12}>
              <iframe
                width='560'
                height='315'
                src={`https://www.youtube.com/embed/${youtube_parser(
                  formik.values.youtubeVideoLink
                )}`}
                title='YouTube video player'
                frameborder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowfullscreen
              ></iframe>
            </Grid>
            <Grid item xs={12}>
              <Input
                id={'youtubeVideoLink'}
                name={'youtubeVideoLink'}
                label={'Youtube Video Link'}
                onChange={formik.handleChange}
                value={formik.values.youtubeVideoLink}
                error={
                  formik.touched.youtubeVideoLink &&
                  Boolean(formik.errors.youtubeVideoLink)
                }
                helperText={
                  formik.touched.youtubeVideoLink &&
                  formik.errors.youtubeVideoLink
                }
                defaultValue={formik.values.youtubeVideoLink}
              />
            </Grid>
          </Grid>

          <Grid
            container
            rowSpacing={{ xs: 1, sm: 1, md: 2 }}
            columnSpacing={{ xs: 1, sm: 1, md: 2 }}
            style={{ marginTop: '10px' }}
          >
            <Grid item xs={12} sm={12} md={2}>
              <Buttons
                title='Save'
                isLoading={isButtonLoading}
                onSubmit={formik.handleSubmit}
                loadingTitle={'Saving'}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default Photos;
