import { API_URL } from '../../config';
import axios from 'axios';

export const getBookingTransactionHistoryApi = async (userId) => {
  try {
    const response = await axios.get(
      API_URL +
        `Booking-Transactions/get-booking-transactions?page=0&limit=50&payment_by=${userId}`
    );
    return response.data.data;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log(
        'Bad Response of get user user booking transaction history : ',
        response
      );
      return response;
    }
  }
};
