import { ConfirmDialog } from "primereact/confirmdialog";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import InfoBar from "../../../components/dasboard/infoBar";
import Sidebar from "../../../components/dasboard/sidebar";
import Header from "../../../components/header";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../../../components/Checkout/CheckoutForm";
import { Box, Button, Card, Grid, Typography } from "@mui/material";
import NoEncryptionGmailerrorredIcon from "@mui/icons-material/NoEncryptionGmailerrorred";
import getBookingGuestAPI from "../../../services/booking/getBookingGuest";

const stripePromise = loadStripe(
  "pk_test_51JyJq6AQ044GxvRG2UF0G2FTwW1ZNr6N7FUqnXl6gRO73rEJ1J25Bb8uaHgBtEw4deV3HFNSFAC0eztcwhVcY8I400xMgTxky5"
);
const CheckoutPage = () => {
  const { bookingId } = useParams();
  const { getBookingDetail } = getBookingGuestAPI();
  const [bookingDetail, setBookingDetail] = useState([]);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const getBookingData = async (id) => {
    try {
      const response = await getBookingDetail(id);
      if (response.status === 200) {
        setBookingDetail(response.data?.data);
      }
    } catch (err) {
    }
  };
  useEffect(() => {
    getBookingData(bookingId);
  }, []);
  return (
    <>
      <Header />
      <div id="wrapper">
        <div className="dashboard-menu-overlay" />
        <Sidebar page={"Trip - Awaiting Payments"} />
        <div className="dashboard-content">
          <div className="dashboard-menu-btn color-bg">
            <span>
              <i className="fas fa-bars" />
            </span>
            Dashboard Menu
          </div>
          {/* {isOpen && <Dropdown/>} */}
          <div className="container dasboard-container">
            <InfoBar label={"Trip - Awaiting Payments"} />
            <div className="dasboard-wrapper fl-wrap no-pag">
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Grid item xs={12} sm={8} md={8}>
                  <Card sx={{ p: 3, bgcolor: "#1b182b" }}>
                    <Elements stripe={stripePromise}>
                      <CheckoutForm
                        users={userData}
                        stripeAmount={bookingDetail?.grossAmount}
                        bookingId={bookingId}
                      />
                    </Elements>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Card sx={{ p: 3, mb: 3, bgcolor: "#1b182b" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <NoEncryptionGmailerrorredIcon
                        sx={{ fontSize: "50px", mb: 2 }}
                      />
                      <Typography variant="h6">Payment Secure</Typography>
                      <Typography variant="p" sx={{ fontSize: "15px", my: 2 }}>
                        Your payment is 100% secure using 128 bit enctryption
                      </Typography>
                      <Typography variant="h6">2CHECKOUT</Typography>
                    </Box>
                  </Card>
                  <Card sx={{ p: 3, bgcolor: "#1b182b" }}>
                    <Box sx={{ borderBottom: "1px solid #545861", my: 2 }}>
                      <Typography
                        variant="h6"
                        sx={{ textAlign: "left", mb: 2 }}
                      >
                        Order Summary
                      </Typography>
                    </Box>
                    <Box sx={{ borderBottom: "1px solid #545861", my: 2 }}>
                      <ul className="listSummry">
                        <li className="list">
                          {process.env.REACT_APP_CURRENCY}{" "}
                          {bookingDetail?.perNightPrice?.toLocaleString(
                            "en-US"
                          )}{" "}
                          x {bookingDetail?.numberOfNights}{" "}
                          <span>
                            {process.env.REACT_APP_CURRENCY}{" "}
                            {bookingDetail?.totalAmount?.toLocaleString(
                              "en-US"
                            )}
                          </span>
                        </li>
                        <li className="list">
                          GST 13%{" "}
                          <span>
                            {process.env.REACT_APP_CURRENCY}{" "}
                            {bookingDetail?.totalGST?.toLocaleString("en-US")}
                          </span>
                        </li>
                      </ul>
                    </Box>
                    <Box>
                      <ul className="listSummry">
                        <li className="list">
                          Total ({process.env.REACT_APP_CURRENCY}){" "}
                          <span>
                            {process.env.REACT_APP_CURRENCY}{" "}
                            {bookingDetail?.grossAmount?.toLocaleString(
                              "en-US"
                            )}
                          </span>
                        </li>
                      </ul>
                    </Box>
                    <Button variant="contained">Next Step</Button>
                  </Card>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        <div className="dashbard-bg gray-bg" />
      </div>
    </>
  );
};

export default CheckoutPage;
