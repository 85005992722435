import { API_URL } from '../../config';
import axios from 'axios';

export const postLoginApi = async (data) => {
  try {
    const response = await axios.post(API_URL + 'auth/email/login', data);
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;

      console.log('Login Error Response status code', response.data.statusCode);
      console.log('Login Error Response : ', response.data.message[1]);
      throw response;
    }
  }
};

export const loginWithPhoneNumberApi = async (data) => {
  try {
    const response = await axios.post(
      API_URL + 'sms/initiate-verification',
      data
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Login Error Response : ', response);
      throw response;
    }
  }
};
