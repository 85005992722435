import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Input from "../../../../components/form/input";
import Card from "../../../../components/dasboard/card";
import Upload from "../../../../components/form/upload";
import Typography from "@mui/material/Typography";
const Media = ({ formik, inputValues, handleChangeInput, validationType }) => {
  function youtube_parser(url) {
    if (url) {
      var r,
        rx =
          /(https?:\/\/)?(((m|www)\.)?(youtube(-nocookie)?|youtube.googleapis)\.com.*(v\/|v=|vi=|vi\/|e\/|embed\/|user\/.*\/u\/\d+\/)|youtu\.be\/)([_0-9a-z-]+)/i;
      r = url.match(rx);
      if (r) {
        return r[8];
      } else {
        return url;
      }
    }
  }
  return (
    <Card withIcon title={"Media"} icon={"fa fa-camera"}>
      <Grid
        container
        rowSpacing={{ xs: 1, sm: 1, md: 2 }}
        columnSpacing={{ xs: 1, sm: 1, md: 2 }}
      >
        <Grid item xs={12}>
          <iframe
            width="560"
            height="315"
            src={`https://www.youtube.com/embed/${youtube_parser(
              inputValues.youtubeVideoLink
            )}`}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </Grid>
        <Grid item xs={12}>
          <Typography>Youtube Video Link</Typography>
          <Typography style={{fontSize:12}}>A memorable and unique link can make it easy to share your listing on business cards, websites, or social media</Typography>
          <Input
            id={"youtubeVideoLink"}
            name={"youtubeVideoLink"}
            label={"Youtube Video Link"}
            onChange={handleChangeInput}
            value={inputValues.youtubeVideoLink}
            // error={
            //   formik.touched.youtubeVideoLink &&
            //   Boolean(formik.errors.youtubeVideoLink)
            // }
            // helperText={
            //   formik.touched.youtubeVideoLink && formik.errors.youtubeVideoLink
            // }
            defaultValue={inputValues.youtubeVideoLink}
          />
          {validationType.open && validationType.type == "youtube" && (
            <p style={{ color: "red" }}>{validationType.message}</p>
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export default Media;
