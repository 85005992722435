import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { Typography, Button, Divider, TextField, Box, Link,Paper } from '@mui/material'
import { useNavigate } from "react-router-dom"
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Modal from '@mui/material/Modal';
import getUserCardApi from '../../../services/User/getUserCard';
import { FaCcMastercard, FaCcVisa } from 'react-icons/fa';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  color: 'text.primary',
  backgroundColor: "#1b182b",
  boxShadow: 24,
  p: 4,
  borderRadius: 5
};
export default function Payment() {
  const userData = JSON.parse(localStorage.getItem('userData'));
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [userCards, setUserCards] = useState([]);

  const brandCard = userCards.brand;
  const getUserCards = async () => {
    const res = await getUserCardApi(userData?.id);
    setUserCards(res?.data);
  };

  useEffect(() => {
    getUserCards();
  }, []);
  const navigate = useNavigate();

  return (
    <>


      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h4" style={{ color: "white", textAlign: "start" }}>Your payments
          </Typography>
          <p>Keep track of all your payments and refunds.</p>

        </Grid>
      </Grid>


      <Grid container sx={{ marginTop: 2 }} >

        <Button variant="contained" ><Link onClick={() => navigate("/admin/wallet-transaction-history")} style={{ color: "white", textDecoration: "none" }} >Transcation Histroy</Link></Button>

      </Grid>




      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} style={{ marginTop: 30 }}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h4" style={{ color: "white", textAlign: "start" }}>Payment methods</Typography>
          <p>Add and manage your payment methods using our secure payment system.</p>
        </Grid>
        <Grid item xs={8}><Divider /></Grid>
        <Grid item xs={6}>
          <Paper style={{ maxHeight: 100, overflow: 'auto',backgroundColor:"transparent" }} >
            {userCards &&
              userCards?.map((card, i) => {
                return (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'start',
                    }}
                    key={i}
                  >
                    <Typography
                      sx={{
                        color: '#fff',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {card?.brand === 'Visa' && (
                        <FaCcVisa
                          style={{
                            marginRight: '8px',
                            color: '#fff',
                            fontSize: '25px',
                          }}
                        />
                      )}
                      {card?.brand === 'MasterCard' && (
                        <FaCcMastercard
                          style={{
                            marginRight: '8px',
                            color: '#fff',
                            fontSize: '25px',
                          }}
                        />
                      )}
                      {card?.brand}
                    </Typography>
                    <Typography
                      sx={{ marginLeft: '10px' }}
                    >{`...${card?.last4digit}`}</Typography>
                  </Box>
                );
              })}
          </Paper>
        </Grid>
        <Grid item xs={2}>
          <Link onClick={() => navigate("/admin/your-cards")}><MoreHorizIcon sx={{ marginTop: 2 }} style={{ color: "white", cursor: "pointer" }} /></Link>

        </Grid>
        <Grid item xs={8}><Divider /></Grid>

      </Grid>





      <Grid container sx={{ marginTop: 2 }} >

        <Button variant="contained" ><Link onClick={() => navigate("/admin/add-card")} style={{ color: "white", textDecoration: "none" }} >Add Payment Method</Link></Button>

      </Grid>



      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} style={{ marginTop: 30 }}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h4" style={{ color: "white", textAlign: "start" }}>Airbnb gift credit</Typography>
          <p>Redeem a gift card and look up your credit balance. <Link style={{ color: "white" }}>Terms apply</Link></p>
        </Grid>
        <Grid item xs={8}><Divider /></Grid>
        <Grid item xs={6}>
          <p style={{ marginTop: 20 }}>{`Balance`}</p>

        </Grid>
        <Grid item xs={2}>
          <p style={{ marginTop: 20 }}>{`0`}</p>

        </Grid>
        <Grid item xs={8}><Divider /></Grid>




      </Grid>



      <Grid container sx={{ marginTop: 2 }} >

        <Button variant="contained">Add Gift Card</Button>

      </Grid>




      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} style={{ marginTop: 30 }}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h4" style={{ color: "white", textAlign: "start" }}>Coupons</Typography>
          <p>Add a coupon and save on your next trip.</p>
        </Grid>
        <Grid item xs={8}><Divider /></Grid>
        <Grid item xs={6}>
          <p style={{ marginTop: 20 }}>{`Coupons`}</p>

        </Grid>
        <Grid item xs={2}>
          <p style={{ marginTop: 20 }}>{`0`}</p>

        </Grid>
        <Grid item xs={8}><Divider /></Grid>




      </Grid>



      <Grid container sx={{ marginTop: 2 }} >

        <Button variant="contained" onClick={handleOpen}>Add coupon</Button>

      </Grid>



      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Enter A Coupon
          </Typography>
          <TextField placeholder='Coupon No' />
          <Grid container sx={{ marginTop: 2, justifyContent: "center" }} >

            <Button variant="contained" onClick={handleClose}>save</Button>

          </Grid>

        </Box>
      </Modal>
    </>
  )
}
