import { API_URL } from '../../config';
import axios from 'axios';

export const getPropertyRatesAndValsApi = async (id) => {
  try {
    const response = await axios.get(API_URL + `property-stats/stats/${id}`);
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of property price policy: ', response);
      return response;
    }
  }
};
