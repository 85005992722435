import React, { useEffect } from "react";
import { useState } from "react";
import InfoBar from "../../../components/dasboard/infoBar";
import Sidebar from "../../../components/dasboard/sidebar";
import Header from "../../../components/header";
import { getNotificationById } from "../../../services/Notification/getNotification";
import moment from "moment";

export default function NotificationPage() {
  const [notification, setNotification] = useState([]);
  let userData = JSON.parse(localStorage.getItem("userData"));
  useEffect(() => {
    getNotificationData();
  }, []);

  const getNotificationData = async () => {
    try {
      let result = await getNotificationById(1, userData.id);
      setNotification(result?.data?.data?.items);
    } catch (error) {}
  };

  return (
    <>
      <Header />
      <div id="wrapper">
        <div className="dashboard-menu-overlay" />

        <Sidebar page={"Add New"} />
        <div className="dashboard-content">
          <div className="dashboard-menu-btn color-bg">
            <span>
              <i className="fas fa-bars" />
            </span>
            Dashboard Menu
          </div>
          <div>
            <InfoBar label={"Notifications"} />
          </div>

          <div className="dasboard-wrapper fl-wrap no-pag">
            {/* notificationns */}
            <div className="dashboard-widget-title fl-wrap">Last Activites</div>
            <div className="dashboard-list-box  fl-wrap">
              {
                notification.length > 1 ? 
                notification?.map((item) => {
                  return (
                    <div className="dashboard-list fl-wrap">
                      <div className="dashboard-message">
                        <span className="close-dashboard-item color-bg">
                          <i className="fal fa-times"></i>
                        </span>
                        <div className="main-dashboard-message-icon color-bg">
                          <i className="far fa-check"></i>
                        </div>
                        <div className="main-dashboard-message-text">
                          <p>{item.text}</p>
                        </div>
                        <div className="main-dashboard-message-time">
                          <i className="fal fa-calendar-week"></i>{" "}
                          {moment(item.createdOn).format("DD MMM YYYY")}
                        </div>
                      </div>
                    </div>
                  );
                }) : 
                    <div className="dashboard-list fl-wrap">
                      <div className="dashboard-message">
                        <span className="close-dashboard-item color-bg">
                          <i className="fal fa-times"></i>
                        </span>
                        <div className="main-dashboard-message-icon color-bg">
                          <i className="far fa-check"></i>
                        </div>
                        <div className="main-dashboard-message-text">
                          <p>No new notifications</p>
                        </div>
                      </div>
                    </div>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
