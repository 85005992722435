import axios from 'axios';
import { API_URL } from '../../config';

export const postNewsletterApi = async (data) => {
  try {
    const response = await axios.post(API_URL + 'news-letter', data);
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;

      console.log('Response status code', response.data.statusCode);
      console.log('Response : ', response.data.message[1]);
      throw response;
    }
  }
};
