import { API_URL } from "../../config";
import axios from "axios";

export const getSpaceApi = async () => {
  try {
    const response = await axios.get(API_URL + "space/all");
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log("Bad Response of space : ", response);
      return response;
    }
  }
};

export const getSpaceTypeById = async (id) => {
  try {
    const response = await axios.get(API_URL + `space-type/${id}`);
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log("Bad Response of space type: ", response);
      return response;
    }
  }
};

export const getSpaceTypeApi = async () => {
  try {
    const response = await axios.get(API_URL + "space-type/all");
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log("Bad Response of space type: ", response);
      return response;
    }
  }
};

export const getAreaApi = async () => {
  try {
    const response = await axios.get(API_URL + "area/all");
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log("Bad Response of area: ", response);
      return response;
    }
  }
};

export const getTypeApi = async () => {
  try {
    const response = await axios.get(API_URL + "space/all");
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log("Bad Response of type: ", response);
      return response;
    }
  }
};
