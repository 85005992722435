import React, { useState } from 'react';
import InfoBar from '../../../components/dasboard/infoBar';
import Sidebar from '../../../components/dasboard/sidebar';
import Header from '../../../components/header';
import PaymentsIcon from '@mui/icons-material/Payments';
import {
  Box,
  InputLabel,
  Typography,
  TextField,
  FormHelperText,
  InputAdornment,
} from '@mui/material';
import getUserCardApi from '../../../services/User/getUserCard';
import Buttons from '../../../components/form/button';
import { useFormik } from 'formik';
import { Grid } from '@mui/material';
import { Dropdown } from 'primereact/dropdown';
import { walletTopUpApi } from '../../../services/wallet/walletTopUp';
import { Toast } from 'primereact/toast';
import { getUserWalletApi } from '../../../services/wallet/getUserWallet';
import { FaCcMastercard, FaCcVisa } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Select from 'react-select';
import { options } from './../../../constants/countries';
import * as yup from 'yup';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const TopUpPage = () => {
  const userData = JSON.parse(localStorage.getItem('userData'));
  const [isLoading, setIsLoading] = React.useState(false);
  const [useCreditCard, setUseCreditCard] = React.useState(false);
  const [amountError, setAmountError] = React.useState('');
  const [userCards, setUserCards] = useState([]);
  const [card, setCard] = React.useState({});
  const [noCardError, setNoCardError] = React.useState('');
  const toast = React.useRef(null);
  const [wallet, setWallet] = useState();
  const getWallet = async () => {
    const res = await getUserWalletApi(userData?.id);
    setWallet(res);
  };
  let noActiveCard = Object.keys(card).length === 0;
  React.useEffect(() => {
    getWallet();
  }, []);
  const getUserCards = async () => {
    const res = await getUserCardApi(userData?.id);
    let temp = [];
    res?.data.map((item) => {
      temp.push({ value: item, label: item.last4digit, brand: item.brand });
    });
    setUserCards(temp);
  };

  React.useEffect(() => {
    getUserCards();
  }, []);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      amount: 0,
      number: '',
      name: '',
      exp_month: '',
      exp_year: '',
      cvc: '',
      user_id: userData?.id,
      use_save_card: false,
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipcode: '',
      country: '',
    },
    ...(noActiveCard &&
      useCreditCard && {
        validationSchema: () => {
          return yup.object({
            name: yup.string().required('Please enter your name!'),
            number: yup
              .string()
              .min(16, 'Card number must be only 16 characters')
              .max(16, 'Card number must be only 16 characters')
              .required('Please enter your card number!'),
            exp_month: yup
              .string()
              .required('Please enter your card expiry month!'),
            exp_year: yup
              .string()
              .required('Please enter your card expiry year!'),
            cvc: yup
              .string()
              .min(3, 'CVC must be at least 3 characters!')
              .max(4, 'CVC can be only 4 characters!')
              .required('Please enter your card CVC!'),
            address1: yup.string().required('Street Address is required!'),
            address2: yup.string().required('Apt or suite number is required!'),
            city: yup.string().required('City is required!'),
            zipcode: yup.string().required('Zip Code is required!'),
            state: yup.string().required('State is required!'),
          });
        },
      }),
    onSubmit: async (values) => {
      if (values.amount <= 0) {
        setAmountError('Amount cannot be less than or equal to 0!');
        setIsLoading(false);
      } else if (values.amount > 1000000) {
        setAmountError('Amount cannot be greater 1 million');
        setIsLoading(false);
      } else {
        if (noActiveCard && !useCreditCard) {
          setIsLoading(false);
          setNoCardError('Please select a card!');
        } else {
          // setIsLoading(true);
          if (noActiveCard && useCreditCard) {
            const response = await walletTopUpApi({
              number: values.number,
              name: values.name,
              exp_month: values.exp_month,
              exp_year: values.exp_year,
              cvc: values.cvc,
              user_id: values.user_id,
              amount: values.amount,
              address1: values.address1,
              address2: values.address2,
              city: values.city,
              state: values.state,
              zipcode: values.zipcode,
              country: values.country?.name,
            });
            if (response.status === 201) {
              setIsLoading(false);
              toast.current.show({
                severity: 'success',
                summary: 'Wallet Topped Up!',
                detail: 'Your wallet top up is successfull!',
                life: 10000,
              });
              setTimeout(() => {
                navigate('/wallet');
              }, 2000);
            } else {
              setIsLoading(false);
              toast.current.show({
                severity: 'error',
                summary: "Something's wrong!",
                detail: `${response.data.error}`,
                life: 10000,
              });
            }
          } else if (card && !useCreditCard) {
            const response = await walletTopUpApi({
              user_id: values.user_id,
              amount: values.amount,
              card_id: card?.value?.id,
            });
            if (response.status === 201) {
              setIsLoading(false);
              toast.current.show({
                severity: 'success',
                summary: 'Wallet Topped Up!',
                detail: 'Your wallet top up is successfull!',
                life: 10000,
              });
              setTimeout(() => {
                navigate('/wallet');
              }, 2000);
            } else {
              setIsLoading(false);
              toast.current.show({
                severity: 'error',
                summary: "Something's wrong!",
                detail: `${response.data.error}`,
                life: 10000,
              });
            }
          }
        }
      }
    },
  });

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: 'black',
      fontSize: '16px',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      const color = '#fff';
      return { ...provided, opacity, transition, color };
    },
  };
  //Country options dropdown templates
  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className='country-item country-item-value'>
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className='country-item'>
        <div>{option.name}</div>
      </div>
    );
  };
  return (
    <>
      <Toast ref={toast} position='bottom-right' />

      <Header />
      <div id='wrapper'>
        <div className='dashboard-menu-overlay' />
        <Sidebar page={'Top Up'} />
        <div className='dashboard-content'>
          <div className='dashboard-menu-btn color-bg'>
            <span>
              <i className='fas fa-bars' />
            </span>
            Top Up Wallet
          </div>
          <InfoBar label={'Top Up Wallet'} />
          <div className='dasboard-wrapper fl-wrap no-pag'>
            <div>
              <div className='clearfix' />
              <div className='row'>
                <div className='col-md-8'>
                  <div
                    className='dasboard-content fl-wrap'
                    style={{ margin: '10px 0px' }}
                  >
                    <Box sx={{ p: 3 }}>
                      <Typography
                        sx={{
                          color: '#fff',
                          fontSize: '22px',
                          fontFamily: 'Jost',
                          display: 'flex',
                          alignItems: 'center',
                          paddingTop: '12px',
                        }}
                      >
                        <ArrowBackIosIcon
                          onClick={() => navigate(-1)}
                          style={{
                            display: 'flex',
                            color: 'white',
                            cursor: 'pointer',
                          }}
                        />
                        Your Wallet Balance
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '25px',
                          color: '#fff',
                        }}
                      >
                        <PaymentsIcon
                          style={{
                            marginRight: '16px',
                            fontSize: '18px',
                            color: '#fff',
                          }}
                        />
                        <span
                          style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            color: 'rgba(255, 255, 255, 0.7)',
                          }}
                        >
                          {process.env.REACT_APP_CURRENCY}{wallet?.amount
                            ? wallet?.amount?.toLocaleString('en-US')
                            : 0}{' '}
                          
                          <AddCircleIcon
                            style={{
                              marginRight: '16px',
                              fontSize: '25px',
                              color: 'rgba(255, 255, 255, 0.7)',
                              cursor: 'pointer',
                              marginLeft: '10px',
                            }}
                            onClick={() => navigate('/wallet/top-up')}
                          />
                        </span>
                      </Typography>

                      <Box>
                        <InputLabel
                          htmlFor='amount'
                          sx={{ margin: '10px 0px' }}
                        >
                          Enter amount to Top Up.
                        </InputLabel>
                        <TextField
                          id='outlined-basic'
                          label={`Amount ( ${process.env.REACT_APP_CURRENCY} )`}
                          variant='outlined'
                          fullWidth
                          name='amount'
                          type='number'
                          value={formik.values.amount}
                          onChange={formik.handleChange}
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 7);
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment
                                position='start'
                                sx={{
                                  '& .MuiTypography-root': {
                                    paddingBottom: '0px',
                                  },
                                }}
                              >
                                {process.env.REACT_APP_CURRENCY}
                              </InputAdornment>
                            ),
                          }}
                        />
                        {amountError && (
                          <small
                            id='username2-help'
                            className='p-error'
                            style={{
                              display: 'block',
                              color: 'red',
                              textAlign: 'left',
                            }}
                          >
                            {amountError}
                          </small>
                        )}
                      </Box>
                      {!userCards && (
                        <>
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography sx={{ textAlign: 'left' }}>
                              Card Number
                            </Typography>
                            <TextField
                              sx={{ width: '100%' }}
                              id='number'
                              name='number'
                              number
                              required
                              type='number'
                              placeholder='4242424242424242'
                              value={formik.values.number}
                              onChange={formik.handleChange}
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseInt(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 16);
                              }}
                            />
                            {formik.touched.number &&
                              Boolean(formik.errors.number) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: 'block',
                                    color: 'red',
                                    textAlign: 'left',
                                  }}
                                >
                                  {formik.errors.number}
                                </small>
                              )}
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography sx={{ textAlign: 'left' }}>
                              Card Holder Name
                            </Typography>
                            <TextField
                              sx={{ width: '100%' }}
                              id='name'
                              name='name'
                              required
                              placeholder='Card Holder Name'
                              value={formik.values.name}
                              onChange={formik.handleChange}
                            />
                            {formik.touched.name &&
                              Boolean(formik.errors.name) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: 'block',
                                    color: 'red',
                                    textAlign: 'left',
                                  }}
                                >
                                  {formik.errors.name}
                                </small>
                              )}
                          </Grid>
                          <Grid item xs={12} sm={4} md={4}>
                            <Typography sx={{ textAlign: 'left' }}>
                              Expiration Month
                            </Typography>
                            <TextField
                              sx={{ width: '100%' }}
                              id='exp_month'
                              name='exp_month'
                              required
                              number
                              placeholder='03'
                              value={formik.values.exp_month}
                              onChange={formik.handleChange}
                            />
                            {formik.touched.exp_month &&
                              Boolean(formik.errors.exp_month) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: 'block',
                                    color: 'red',
                                    textAlign: 'left',
                                  }}
                                >
                                  {formik.errors.exp_month}
                                </small>
                              )}
                          </Grid>
                          <Grid item xs={12} sm={4} md={4}>
                            <Typography sx={{ textAlign: 'left' }}>
                              Expiration Years
                            </Typography>
                            <TextField
                              sx={{ width: '100%' }}
                              id='exp_year'
                              name='exp_year'
                              required
                              number
                              placeholder='10'
                              value={formik.values.exp_year}
                              onChange={formik.handleChange}
                            />
                            {formik.touched.exp_year &&
                              Boolean(formik.errors.exp_year) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: 'block',
                                    color: 'red',
                                    textAlign: 'left',
                                  }}
                                >
                                  {formik.errors.exp_year}
                                </small>
                              )}
                          </Grid>
                          <Grid item xs={12} sm={4} md={4}>
                            <Typography sx={{ textAlign: 'left' }}>
                              CVC
                            </Typography>
                            <TextField
                              sx={{ width: '100%' }}
                              id='cvc'
                              name='cvc'
                              required
                              number
                              placeholder='10'
                              value={formik.values.cvc}
                              onChange={formik.handleChange}
                            />
                            {formik.touched.cvc &&
                              Boolean(formik.errors.cvc) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: 'block',
                                    color: 'red',
                                    textAlign: 'left',
                                  }}
                                >
                                  {formik.errors.cvc}
                                </small>
                              )}
                          </Grid>
                          <Grid item xs={12}>
                            <Typography
                              sx={{
                                color: '#fff',
                                fontSize: '22px',
                                fontFamily: 'Jost',
                                display: 'flex',
                                alignItems: 'center',
                                paddingTop: '12px',
                                borderTop: '1px solid rgba(255, 255, 255, 0.1)',
                                marginTop: '12px',
                              }}
                            >
                              Billing Address
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography sx={{ textAlign: 'left' }}>
                              Street Address
                            </Typography>
                            <TextField
                              sx={{ width: '100%' }}
                              id='address1'
                              name='address1'
                              required
                              placeholder='Street Address'
                              value={formik.values.address1}
                              onChange={formik.handleChange}
                            />
                            {formik.touched.address1 &&
                              Boolean(formik.errors.address1) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: 'block',
                                    color: 'red',
                                    textAlign: 'left',
                                  }}
                                >
                                  {formik.errors.address1}
                                </small>
                              )}
                          </Grid>
                          <Grid item xs={12}>
                            <Typography sx={{ textAlign: 'left' }}>
                              Apt or suite number
                            </Typography>
                            <TextField
                              sx={{ width: '100%' }}
                              id='address2'
                              name='address2'
                              required
                              type='text'
                              placeholder='Enter Suite Number'
                              value={formik.values.address2}
                              onChange={formik.handleChange}
                            />
                            {formik.touched.address2 &&
                              Boolean(formik.errors.address2) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: 'block',
                                    color: 'red',
                                    textAlign: 'left',
                                  }}
                                >
                                  {formik.errors.address2}
                                </small>
                              )}
                          </Grid>
                          <Grid item xs={12}>
                            <Typography sx={{ textAlign: 'left' }}>
                              City
                            </Typography>
                            <TextField
                              sx={{ width: '100%' }}
                              id='city'
                              name='city'
                              required
                              placeholder='City'
                              value={formik.values.city}
                              onChange={formik.handleChange}
                            />
                            {formik.touched.city &&
                              Boolean(formik.errors.city) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: 'block',
                                    color: 'red',
                                    textAlign: 'left',
                                  }}
                                >
                                  {formik.errors.city}
                                </small>
                              )}
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography sx={{ textAlign: 'left' }}>
                              State
                            </Typography>
                            <TextField
                              sx={{ width: '100%' }}
                              id='state'
                              name='state'
                              required
                              placeholder='State'
                              value={formik.values.state}
                              onChange={formik.handleChange}
                            />
                            {formik.touched.state &&
                              Boolean(formik.errors.state) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: 'block',
                                    color: 'red',
                                    textAlign: 'left',
                                  }}
                                >
                                  {formik.errors.state}
                                </small>
                              )}
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography sx={{ textAlign: 'left' }}>
                              Zip Code
                            </Typography>
                            <TextField
                              sx={{ width: '100%' }}
                              id='zipcode'
                              name='zipcode'
                              required
                              type='number'
                              placeholder='Zip Code'
                              value={formik.values.zipcode}
                              onChange={formik.handleChange}
                            />
                            {formik.touched.zipcode &&
                              Boolean(formik.errors.zipcode) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: 'block',
                                    color: 'red',
                                    textAlign: 'left',
                                  }}
                                >
                                  {formik.errors.zipcode}
                                </small>
                              )}
                          </Grid>
                          <Grid item xs={12}>
                            <Dropdown
                              value={formik.values.country}
                              options={options}
                              onChange={formik.handleChange}
                              optionLabel='name'
                              filter
                              showClear
                              filterBy='name'
                              name='country'
                              placeholder='Country/region'
                              valueTemplate={selectedCountryTemplate}
                              itemTemplate={countryOptionTemplate}
                              style={{
                                width: '100%',
                                background: 'transparent',
                                padding: '6px 5px',
                              }}
                            />
                            {formik.touched.country &&
                              Boolean(formik.errors.country) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: 'block',
                                    color: 'red',
                                    textAlign: 'left',
                                  }}
                                >
                                  {formik.errors.country}
                                </small>
                              )}
                          </Grid>
                        </>
                      )}
                      {userCards && !useCreditCard && (
                        <>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            sx={{ margin: '15px 0px' }}
                          >
                            <Typography
                              sx={{
                                color: '#fff',
                                fontSize: '22px',
                                fontFamily: 'Jost',
                                display: 'flex',
                                alignItems: 'center',
                                paddingTop: '12px',
                                marginTop: '12px',
                              }}
                            >
                              Your Card
                            </Typography>
                            <InputLabel
                              sx={{ margin: '10px 0px' }}
                              id='demo-simple-select-helper-label'
                            >
                              Select your Card.
                            </InputLabel>
                            <Select
                              className='override-select-edit'
                              placeholder='Select Card'
                              options={userCards}
                              value={
                                card.value == undefined
                                  ? { label: 'Select Card', value: '' }
                                  : card
                              }
                              onChange={(option) => setCard(option)}
                              styles={customStyles}
                              formatOptionLabel={(item) => (
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  {item?.brand === 'Visa' && (
                                    <FaCcVisa
                                      style={{
                                        marginRight: '8px',
                                        fontSize: '25px',
                                      }}
                                    />
                                  )}{' '}
                                  {item?.brand === 'MasterCard' && (
                                    <FaCcMastercard
                                      style={{
                                        marginRight: '8px',
                                        fontSize: '25px',
                                      }}
                                    />
                                  )}
                                  {item?.brand}
                                  <span
                                    style={{ marginLeft: '10px' }}
                                  >{`${item?.label}`}</span>
                                </div>
                              )}
                            />
                            <FormHelperText>
                              {noCardError && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: 'block',
                                    color: 'red',
                                    textAlign: 'left',
                                  }}
                                >
                                  {noCardError}
                                </small>
                              )}
                            </FormHelperText>
                          </Grid>
                        </>
                      )}

                      {userCards && (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          sx={{ margin: '15px 0px' }}
                        >
                          {useCreditCard ? (
                            <Typography
                              onClick={() => {
                                setUseCreditCard(false);
                                formik.values.amount = 0;
                                setCard({});
                              }}
                              sx={{
                                cursor: 'pointer',
                                color: '#fff',
                                textDecoration: 'underline',
                              }}
                            >
                              Use Saved Credit Card
                            </Typography>
                          ) : (
                            <Typography
                              onClick={() => {
                                setUseCreditCard(true);
                                formik.values.amount = 0;
                                setCard({});
                              }}
                              sx={{
                                cursor: 'pointer',
                                color: '#fff',
                                textDecoration: 'underline',
                              }}
                            >
                              Add Credit Card Instead
                            </Typography>
                          )}
                        </Grid>
                      )}
                      {useCreditCard && (
                        <>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            sx={{ margin: '15px 0px' }}
                          >
                            <Typography sx={{ textAlign: 'left' }}>
                              Card Number
                            </Typography>
                            <TextField
                              sx={{ width: '100%' }}
                              id='number'
                              name='number'
                              type='number'
                              required
                              placeholder='4242424242424242'
                              value={formik.values.number}
                              onChange={formik.handleChange}
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseInt(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 16);
                              }}
                            />
                            {formik.touched.number &&
                              Boolean(formik.errors.number) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: 'block',
                                    color: 'red',
                                    textAlign: 'left',
                                  }}
                                >
                                  {formik.errors.number}
                                </small>
                              )}
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography sx={{ textAlign: 'left' }}>
                              Card Holder Name
                            </Typography>
                            <TextField
                              sx={{ width: '100%' }}
                              id='name'
                              name='name'
                              required
                              placeholder='Card Holder Name'
                              value={formik.values.name}
                              onChange={formik.handleChange}
                            />
                            {formik.touched.name &&
                              Boolean(formik.errors.name) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: 'block',
                                    color: 'red',
                                    textAlign: 'left',
                                  }}
                                >
                                  {formik.errors.name}
                                </small>
                              )}
                          </Grid>
                          <Grid item xs={12} sm={4} md={4}>
                            <Typography sx={{ textAlign: 'left' }}>
                              Expiration Month
                            </Typography>
                            <TextField
                              sx={{ width: '100%' }}
                              id='exp_month'
                              name='exp_month'
                              required
                              type='number'
                              placeholder='03'
                              value={formik.values.exp_month}
                              onChange={formik.handleChange}
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseInt(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 2);
                              }}
                            />
                            {formik.touched.exp_month &&
                              Boolean(formik.errors.exp_month) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: 'block',
                                    color: 'red',
                                    textAlign: 'left',
                                  }}
                                >
                                  {formik.errors.exp_month}
                                </small>
                              )}
                          </Grid>
                          <Grid item xs={12} sm={4} md={4}>
                            <Typography sx={{ textAlign: 'left' }}>
                              Expiration Years
                            </Typography>
                            <TextField
                              sx={{ width: '100%' }}
                              id='exp_year'
                              name='exp_year'
                              required
                              type='number'
                              placeholder='eg: (2025)'
                              value={formik.values.exp_year}
                              onChange={formik.handleChange}
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseInt(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 4);
                              }}
                            />
                            {formik.touched.exp_year &&
                              Boolean(formik.errors.exp_year) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: 'block',
                                    color: 'red',
                                    textAlign: 'left',
                                  }}
                                >
                                  {formik.errors.exp_year}
                                </small>
                              )}
                          </Grid>
                          <Grid item xs={12} sm={4} md={4}>
                            <Typography sx={{ textAlign: 'left' }}>
                              CVC
                            </Typography>
                            <TextField
                              sx={{ width: '100%' }}
                              id='cvc'
                              name='cvc'
                              required
                              type='number'
                              placeholder='eg: (123)'
                              value={formik.values.cvc}
                              onChange={formik.handleChange}
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseInt(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 4);
                              }}
                            />
                            {formik.touched.cvc &&
                              Boolean(formik.errors.cvc) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: 'block',
                                    color: 'red',
                                    textAlign: 'left',
                                  }}
                                >
                                  {formik.errors.cvc}
                                </small>
                              )}
                          </Grid>
                          <Grid item xs={12}>
                            <Typography
                              sx={{
                                color: '#fff',
                                fontSize: '22px',
                                fontFamily: 'Jost',
                                display: 'flex',
                                alignItems: 'center',
                                paddingTop: '12px',
                                borderTop: '1px solid rgba(255, 255, 255, 0.1)',
                                marginTop: '12px',
                              }}
                            >
                              Billing Address
                            </Typography>
                          </Grid>

                          <Grid item xs={12}>
                            <Typography sx={{ textAlign: 'left' }}>
                              Street Address
                            </Typography>
                            <TextField
                              sx={{ width: '100%' }}
                              id='address1'
                              name='address1'
                              required
                              placeholder='Street Address'
                              value={formik.values.address1}
                              onChange={formik.handleChange}
                            />
                            {formik.touched.address1 &&
                              Boolean(formik.errors.address1) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: 'block',
                                    color: 'red',
                                    textAlign: 'left',
                                  }}
                                >
                                  {formik.errors.address1}
                                </small>
                              )}
                          </Grid>
                          <Grid item xs={12}>
                            <Typography sx={{ textAlign: 'left' }}>
                              Apt or suite number
                            </Typography>
                            <TextField
                              sx={{ width: '100%' }}
                              id='address2'
                              name='address2'
                              required
                              type='text'
                              placeholder='Enter Suite Number'
                              value={formik.values.address2}
                              onChange={formik.handleChange}
                            />
                            {formik.touched.address2 &&
                              Boolean(formik.errors.address2) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: 'block',
                                    color: 'red',
                                    textAlign: 'left',
                                  }}
                                >
                                  {formik.errors.address2}
                                </small>
                              )}
                          </Grid>
                          <Grid item xs={12}>
                            <Typography sx={{ textAlign: 'left' }}>
                              City
                            </Typography>
                            <TextField
                              sx={{ width: '100%' }}
                              id='city'
                              name='city'
                              required
                              placeholder='City'
                              value={formik.values.city}
                              onChange={formik.handleChange}
                            />
                            {formik.touched.city &&
                              Boolean(formik.errors.city) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: 'block',
                                    color: 'red',
                                    textAlign: 'left',
                                  }}
                                >
                                  {formik.errors.city}
                                </small>
                              )}
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography sx={{ textAlign: 'left' }}>
                              State
                            </Typography>
                            <TextField
                              sx={{ width: '100%' }}
                              id='state'
                              name='state'
                              required
                              placeholder='State'
                              value={formik.values.state}
                              onChange={formik.handleChange}
                            />
                            {formik.touched.state &&
                              Boolean(formik.errors.state) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: 'block',
                                    color: 'red',
                                    textAlign: 'left',
                                  }}
                                >
                                  {formik.errors.state}
                                </small>
                              )}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{ marginBottom: '10px' }}
                          >
                            <Typography sx={{ textAlign: 'left' }}>
                              Zip Code
                            </Typography>
                            <TextField
                              sx={{ width: '100%' }}
                              id='zipcode'
                              name='zipcode'
                              required
                              type='number'
                              placeholder='Zip Code'
                              value={formik.values.zipcode}
                              onChange={formik.handleChange}
                            />
                            {formik.touched.zipcode &&
                              Boolean(formik.errors.zipcode) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: 'block',
                                    color: 'red',
                                    textAlign: 'left',
                                  }}
                                >
                                  {formik.errors.zipcode}
                                </small>
                              )}
                          </Grid>
                          <Grid item xs={12}>
                            <Dropdown
                              value={formik.values.country}
                              options={options}
                              onChange={formik.handleChange}
                              optionLabel='name'
                              filter
                              showClear
                              filterBy='name'
                              name='country'
                              placeholder='Country/region'
                              valueTemplate={selectedCountryTemplate}
                              itemTemplate={countryOptionTemplate}
                              style={{
                                width: '100%',
                                background: 'transparent',
                                padding: '6px 5px',
                              }}
                            />
                            {formik.touched.country &&
                              Boolean(formik.errors.country) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: 'block',
                                    color: 'red',
                                    textAlign: 'left',
                                  }}
                                >
                                  {formik.errors.country}
                                </small>
                              )}
                          </Grid>
                        </>
                      )}
                      <Box
                        sx={{ marginTop: '20px', padding: '10px' }}
                        className='dasboard-content fl-wrap'
                      >
                        <Typography sx={{ fontSize: '17px' }}>
                          Make all payments through Staywo
                        </Typography>
                        <Typography sx={{ fontSize: '13px' }}>
                          Always pay and communicate through Staywo to ensure
                          you're protected under our{' '}
                          <a
                            href='/'
                            style={{
                              fontWeight: '500',
                              fontSize: '14px',
                              textDecoration: 'underline',
                              color: 'white',
                            }}
                          >
                            Terms of Service
                          </a>
                          , cancellation, and other safeguards.{' '}
                          <a
                            href='/'
                            style={{
                              fontWeight: '500',
                              fontSize: '14px',
                              textDecoration: 'underline',
                              color: 'white',
                            }}
                          >
                            Learn more
                          </a>
                        </Typography>
                      </Box>
                    </Box>

                    <Box>
                      <Buttons
                        isLoading={isLoading}
                        title={'Top Up'}
                        style={{ width: '50%', margin: '15px 0px' }}
                        onSubmit={formik.handleSubmit}
                      />
                    </Box>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopUpPage;
