import { API_URL } from '../../config';
import { axiosPrivate } from '../../api/axios';

const getVendorStatsApi = async (vendor_id, year_month) => {
  try {
    const response = await axiosPrivate.get(
      API_URL + `vendor/stats/${vendor_id}/${year_month}`
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('bad response of get vendor-stats: ', response);
      return response;
    }
  }
};
export default getVendorStatsApi;
