import { API_URL } from "../../config";
import axios from "axios";

export const whoIsComingApi = async (payload) => {
  try {
    const response = await axios.post(API_URL + "booking/whoIsComing", payload);
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log("Bad Response of who is coming  : ", response);
      return response;
    }
  }
};
