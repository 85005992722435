import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Box, Grid, Rating, Typography } from '@mui/material';
import moment from 'moment';
import { Toast } from 'primereact/toast';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import NightlightRoundIcon from '@mui/icons-material/NightlightRound';
import GroupIcon from '@mui/icons-material/Group';
import PaymentsIcon from '@mui/icons-material/Payments';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

// Icons
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PetsIcon from '@mui/icons-material/Pets';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import { getBookingByIdApi } from '../../../services/booking/getBooking';

const BookingDetail = () => {
  const [booking, setBooking] = useState({});
  const userData = JSON.parse(localStorage.getItem('userData'));
  const { id } = useParams();
  const toast = React.useRef();

  let startdate = moment(new Date(booking?.startDate), 'DD-MM-YYYY');
  let startdatedate = startdate.format('DD MMM YYYY');
  let enddate = moment(new Date(booking?.endDate), 'DD-MM-YYYY');
  let enddatedate = enddate.format('DD MMM YYYY');

  const GetDayDifference = () => {
    var given = moment(new Date(booking?.startDate));
    var current = moment(new Date(booking?.endDate));
    const diff = current.diff(given);
    const diffDuration = moment.duration(diff);
    return Math.ceil(diffDuration._milliseconds / (1000 * 60 * 60 * 24));
  };
  const totalNights = GetDayDifference();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    const getBookingById = async () => {
      const res = await getBookingByIdApi(id);
      setBooking(res);
    };
    getBookingById();
  }, [id]);

  return (
    <div className='main'>
      <Toast ref={toast} position='bottom-right' />

      <div className='wrapper'>
        <Grid container alignContent={'center'}>
          <Grid
            md={7}
            item
            sx={{
              padding: '50px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                color: '#fff',
                flexDirection: 'column',
                marginLeft: '15px',
                border: '1px solid rgba(255, 255, 255, 0.1)',
                padding: '12px',
                borderRadius: '8px',
              }}
            >
              <Typography
                sx={{
                  color: '#fff',
                  fontSize: '22px',
                  fontFamily: 'Jost',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <LocationCityIcon
                  sx={{ fontSize: '22px', marginRight: '5px' }}
                />
                Your Booking Details
              </Typography>
              <Box>
                <Typography
                  sx={{
                    color: '#fff',
                    fontSize: '18px',
                    fontFamily: 'Jost',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  Booking ID: {booking?.code}
                </Typography>
                <Typography
                  sx={{
                    color: '#fff',
                    fontSize: '18px',
                    fontFamily: 'Jost',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  Booking Status: {booking?.bookingStatus}
                </Typography>
                <Typography
                  sx={{
                    color: '#fff',
                    fontSize: '18px',
                    fontFamily: 'Jost',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <CalendarMonthIcon
                    sx={{ fontSize: '18px', marginRight: '5px' }}
                  />
                  Dates
                </Typography>
                <Typography>
                  From{' '}
                  <span style={{ color: '#fff', margin: '0px 8px' }}>
                    {startdatedate}{' '}
                  </span>
                  to
                  <span style={{ color: '#fff', margin: '0px 8px' }}>
                    {enddatedate}
                  </span>{' '}
                  (Total {totalNights}{' '}
                  <span
                    style={{ display: 'inline-flex', alignItems: 'center' }}
                  >
                    nights
                    <NightlightRoundIcon
                      sx={{ fontSize: '18px', marginRight: '5px' }}
                    />
                  </span>
                  )
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    color: '#fff',
                    fontSize: '18px',
                    fontFamily: 'Jost',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <AccessTimeFilledIcon
                    sx={{ fontSize: '18px', marginRight: '5px' }}
                  />
                  Check In
                </Typography>
                <Typography>
                  <span style={{ color: '#fff', margin: '0px 8px' }}>
                    {booking?.property?.propertyPolicy?.checkInWindow}
                  </span>
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    color: '#fff',
                    fontSize: '18px',
                    fontFamily: 'Jost',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <AccessTimeFilledIcon
                    sx={{ fontSize: '18px', marginRight: '5px' }}
                  />
                  Check Out
                </Typography>
                <Typography>
                  <span style={{ color: '#fff' }}>
                    {booking?.property?.propertyPolicy?.checkoutWindow}
                  </span>{' '}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    color: '#fff',
                    fontSize: '18px',
                    fontFamily: 'Jost',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <GroupIcon sx={{ fontSize: '22px', marginRight: '5px' }} />
                  Guests
                </Typography>
                <Typography>
                  {booking?.totalGuests > 0 ? (
                    <>
                      <span style={{ color: '#fff' }}>
                        {booking?.totalGuests}
                      </span>{' '}
                      guests ({booking?.totalAdults} adults,{' '}
                      {booking?.totalPets} pets, {booking?.totalChildren}{' '}
                      children, {booking?.totalInfants} infants)
                    </>
                  ) : (
                    <>
                      <span style={{ color: '#fff' }}>
                        {booking?.totalGuests}
                      </span>{' '}
                      guest
                    </>
                  )}
                </Typography>
                <Typography>
                  {booking?.cancellation && (
                    <>
                      {booking?.cancellation.user?.id === userData?.id ? (
                        <div
                          style={{
                            color: 'red',
                            fontWeight: '400',
                            fontSize: '22px',
                          }}
                        >
                          Booking cancellation request sent on this booking!
                        </div>
                      ) : (
                        <div
                          style={{
                            color: 'red',
                            fontWeight: '400',
                            fontSize: '22px',
                          }}
                        >
                          Booking cancellation received on this booking!
                        </div>
                      )}
                    </>
                  )}
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid
            md={5}
            item
            sx={{
              padding: '100px 25px',
            }}
          >
            <Box
              sx={{
                borderRadius: '8px',
                border: '1px solid rgba(255, 255, 255, 0.1)',
                padding: '20px',
              }}
            >
              <Box sx={{ display: 'flex' }}>
                <Box>
                  <img
                    src={booking?.property?.thumbnailUrl}
                    alt='booking?.property_image'
                    width={150}
                    height={150}
                  />
                </Box>
                <Box sx={{ marginLeft: '10px' }}>
                  <Typography
                    sx={{ color: '#fff', fontFamily: 'Jost', fontSize: '22px' }}
                  >
                    {booking?.property?.title &&
                    booking?.property?.title?.length > 30
                      ? booking?.property?.title?.slice(0, 30) + '...'
                      : booking?.property?.title?.slice(0, 30)}
                  </Typography>
                  <Typography sx={{ fontFamily: 'Jost', fontSize: '14px' }}>
                    {booking?.property?.city}, {booking?.property?.country}
                  </Typography>
                  <Typography sx={{ fontFamily: 'Jost', fontSize: '14px' }}>
                    ID: {booking?.property?.code}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: 'Jost',
                      fontSize: '16px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Rating
                      name='half-rating-read'
                      value={
                        booking?.property?.average_rating?.averageRating
                          ? booking?.property?.average_rating?.averageRating
                          : 0
                      }
                      precision={0.5}
                      readOnly
                      sx={{ marginRight: '4px' }}
                    />
                    (
                    {booking?.property?.average_rating
                      ? booking?.property?.average_rating?.averageRating?.toFixed(
                          1
                        )
                      : 0}
                    ) (
                    {booking?.property?.average_rating
                      ? ` ${booking?.property?.average_rating?.reviewCount} Reviews`
                      : ' (0 Reviews)'}
                    )
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ marginTop: '10px' }}>
                <Typography
                  sx={{
                    color: '#fff',
                    fontFamily: 'Jost',
                    fontSize: '25px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <PaymentsIcon sx={{ fontSize: '25px', marginRight: '5px' }} />{' '}
                  Price Details
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography
                    sx={{
                      color: '#fff',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <NightlightRoundIcon
                      sx={{ fontSize: '18px', marginRight: '5px' }}
                    />{' '}
                    Nightly Price x {booking?.numberOfNights} (nights)
                  </Typography>
                  <Typography>
                    {process.env.REACT_APP_CURRENCY}{' '}
                    {booking?.totalNightlyPrice?.toLocaleString('en-US')}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography
                    sx={{
                      color: '#fff',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {' '}
                    <CleaningServicesIcon
                      sx={{ fontSize: '18px', marginRight: '5px' }}
                    />{' '}
                    Cleaning Fee
                  </Typography>
                  <Typography>
                    {process.env.REACT_APP_CURRENCY}{' '}
                    {booking?.cleaningFee?.toLocaleString('en-US')}{' '}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                  }}
                >
                  <Typography
                    sx={{
                      color: '#fff',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <PersonAddIcon
                      sx={{ fontSize: '18px', marginRight: '5px' }}
                    />{' '}
                    Additional Guests Fee
                  </Typography>
                  <Typography>
                    <span style={{ color: '#fff' }}>
                      {booking?.totalExtraGuest}
                    </span>{' '}
                    x{' '}
                    <span style={{ color: '#fff' }}>
                      {process.env.REACT_APP_CURRENCY}{' '}
                      {booking?.extraGuestFee?.toLocaleString('en-US')}
                    </span>{' '}
                    ={' '}
                    <span style={{ color: '#fff' }}>
                      {' '}
                      {process.env.REACT_APP_CURRENCY}{' '}
                      {booking?.totalExtraGuestFee?.toLocaleString('en-US')}
                    </span>
                  </Typography>
                </Box>
                {booking?.property?.booking?.propertyCapacity?.noOfInfants >
                0 ? (
                  <Box
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Typography
                      sx={{
                        color: '#fff',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <ChildCareIcon
                        sx={{ fontSize: '18px', marginRight: '5px' }}
                      />{' '}
                      Infant Fee
                    </Typography>
                    <Typography>No Fee</Typography>
                  </Box>
                ) : (
                  <Box
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Typography
                      sx={{
                        color: '#fff',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <ChildCareIcon
                        sx={{ fontSize: '18px', marginRight: '5px' }}
                      />{' '}
                      Infant Fee
                    </Typography>
                    <Typography>Not Allowed</Typography>
                  </Box>
                )}
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography
                    sx={{
                      color: '#fff',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <PetsIcon sx={{ fontSize: '18px', marginRight: '5px' }} />{' '}
                    Pet Fee
                  </Typography>
                  {booking?.property?.booking?.propertyCapacity?.noOfPets >
                  0 ? (
                    <Typography>
                      {booking?.totalPets} x {process.env.REACT_APP_CURRENCY}{' '}
                      {booking?.petFee?.toLocaleString('en-US')} ={' '}
                      {booking?.totalPets * booking?.petFee}
                    </Typography>
                  ) : (
                    <Typography>Not Allowed</Typography>
                  )}
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography
                    sx={{
                      color: '#fff',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <AccountBalanceIcon
                      sx={{ fontSize: '18px', marginRight: '5px' }}
                    />{' '}
                    Service Fee ({booking?.serviceFeePercentage} %)
                  </Typography>
                  <Typography>
                    {process.env.REACT_APP_CURRENCY}{' '}
                    {booking?.totalServiceFee?.toLocaleString('en-US')}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography
                    sx={{
                      color: '#fff',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <AccountBalanceIcon
                      sx={{ fontSize: '18px', marginRight: '5px' }}
                    />{' '}
                    GST ({booking?.GSTFeePercentage} %)
                  </Typography>
                  <Typography>
                    {process.env.REACT_APP_CURRENCY}{' '}
                    {booking?.totalGST?.toLocaleString('en-US')}
                  </Typography>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography
                    sx={{
                      color: '#fff',
                      fontSize: '19px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <DoneOutlineIcon
                      sx={{ fontSize: '19px', marginRight: '5px' }}
                    />{' '}
                    Total ( {process.env.REACT_APP_CURRENCY} )
                  </Typography>
                  <Typography sx={{ fontSize: '18px', color: '#fff' }}>
                    {process.env.REACT_APP_CURRENCY}{' '}
                    {booking?.grossAmount?.toLocaleString('en-US')}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default BookingDetail;
