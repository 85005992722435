import axios from 'axios';
import { API_URL } from '../../config';

export const idVerification = async (data) => {
  try {
    const response = await axios.post(API_URL + 'user-verify', data);
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('user verify error res code', response.data.statusCode);
      console.log('user verify error response : ', response.data.message[1]);
      throw response;
    }
  }
};
