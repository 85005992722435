import axios from 'axios';
import { axiosPrivate } from '../../api/axios';
import { API_URL } from '../../config';
 
const updateProfileApi = async (data) => {
  try {
    const response = await axiosPrivate.patch(
      API_URL + `users/${data.id}`,
      data
    );
    return response.data;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('bad response of user update: ', response);
      return response;
    }
  }
};
export default updateProfileApi;
