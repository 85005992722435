import {
  Divider,
  Grid,
  Typography,
  Box,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Buttons from '../../../../components/form/button';
import CheckBoxLabel from '../../../../components/form/checkbox';
import RadioButton from '../../../../components/form/RadioButton';
import PoliciesContent from '../../../../components/Policies/PoliciesContent';
import { getCancellationPolicyByPropertyId } from '../../../../services/properties/getCancellationPolicy';
import Select from 'react-select';

const TIMINGS = [
  { value: '1 AM', label: '1 AM' },
  { value: '1:30 AM', label: '1:30 AM' },
  { value: '2 AM', label: '2 AM' },
  { value: '2:30 AM', label: '2:30 AM' },
  { value: '3 AM', label: '3 AM' },
  { value: '3:30 AM', label: '3:30 AM' },
  { value: '4 AM', label: '4 AM' },
  { value: '4:30 AM', label: '4:30 AM' },
  { value: '5 AM', label: '5 AM' },
  { value: '5:30 AM', label: '5:30 AM' },
  { value: '6 AM', label: '6 AM' },
  { value: '6:30 AM', label: '6:30 AM' },
  { value: '7 AM', label: '7 AM' },
  { value: '7:30 AM', label: '7:30 AM' },
  { value: '8 AM', label: '8 AM' },
  { value: '8:30 AM', label: '8:30 AM' },
  { value: '9 AM', label: '9 AM' },
  { value: '9:30 AM', label: '9:30 AM' },
  { value: '10 AM', label: '10 AM' },
  { value: '10:30 AM', label: '10:30 AM' },
  { value: '11 AM', label: '11 AM' },
  { value: '11:30 AM', label: '11:30 AM' },
  { value: '12 AM', label: '12 AM' },
  { value: '12:30 AM', label: '12:30 AM' },
  { value: '1 PM', label: '1 PM' },
  { value: '1:30 PM', label: '1:30 PM' },
  { value: '2 PM', label: '2 PM' },
  { value: '2:30 PM', label: '2:30 PM' },
  { value: '3 PM', label: '3 PM' },
  { value: '3:30 PM', label: '3:30 PM' },
  { value: '4 PM', label: '4 PM' },
  { value: '4:30 PM', label: '4:30 PM' },
  { value: '5 PM', label: '5 PM' },
  { value: '5:30 PM', label: '5:30 PM' },
  { value: '6 PM', label: '6 PM' },
  { value: '6:30 PM', label: '6:30 PM' },
  { value: '7 PM', label: '7 PM' },
  { value: '7:30 PM', label: '7:30 PM' },
  { value: '8 PM', label: '8 PM' },
  { value: '8:30 PM', label: '8:30 PM' },
  { value: '9 PM', label: '9 PM' },
  { value: '9:30 PM', label: '9:30 PM' },
  { value: '10 PM', label: '10 PM' },
  { value: '10:30 PM', label: '10:30 PM' },
  { value: '11 PM', label: '11 PM' },
  { value: '11:30 PM', label: '11:30 PM' },
  { value: '12 PM', label: '12 PM' },
  { value: '12:30 PM', label: '12:30 PM' },
  { value: 'Flexible', label: 'Flexible' },
];

const Policies = ({
  formik,
  isButtonLoading,
  canCelPolices,
  setCancelValue,
  id,
  validationType,
  checkIn,
  checkOut,
  setCheckIn,
  setCheckOut,
}) => {
  const [cancelId, setCanceId] = useState();
  const [cancellationPolicy, setCancellationPolicy] = useState({});
  setCancelValue(cancelId);

  useEffect(() => {
    const getCancellationPolicy = async () => {
      const response = await getCancellationPolicyByPropertyId(id);
      if (response.status === 200 || response.status === 201) {
        setCancellationPolicy(response?.data);
      } else {
        setCancellationPolicy({});
      }
    };
    getCancellationPolicy();
  }, [id]);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: 'black',
      fontSize: '16px',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      const color = '#fff';
      return { ...provided, opacity, transition, color };
    },
  };

  return (
    <>
      <div className='dasboard-widget-title fl-wrap'>
        <h5>
          <i className></i>
          Policies
        </h5>
      </div>

      <div className='dasboard-widget-box fl-wrap'>
        <div>
          <Typography
            variant={'h6'}
            sx={{ paddingTop: 3, textAlign: 'initial' }}
          >
            Basic Policies Configration
          </Typography>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 1, md: 1 }}
            columnSpacing={{ xs: 1, sm: 1, md: 1 }}
            style={{ textAlign: 'left' }}
          >
            <Grid item xs={4}>
              <CheckBoxLabel
                id={'instantBooking'}
                name={'instantBooking'}
                label={'Instant Booking?'}
                value={formik?.values?.instantBooking}
                checked={formik?.values?.instantBooking}
                onChange={formik?.handleChange}
              />
            </Grid>
            <Grid item xs={4}>
              <CheckBoxLabel
                id={'governmentIdRequired'}
                name={'governmentIdRequired'}
                label={'Government ID Required?'}
                value={formik?.values?.governmentIdRequired}
                checked={formik?.values?.governmentIdRequired}
                onChange={formik?.handleChange}
              />
            </Grid>
            <Grid item xs={4}>
              <CheckBoxLabel
                id={'guestPhotoRequirement'}
                name={'guestPhotoRequirement'}
                label={'Guest Photo Required?'}
                value={formik?.values?.guestPhotoRequirement}
                checked={formik?.values?.guestPhotoRequirement}
                onChange={formik?.handleChange}
              />
            </Grid>
          </Grid>
          <Divider sx={{ paddingTop: 3 }} />
          <Typography
            variant={'h6'}
            sx={{ paddingTop: 3, textAlign: 'initial' }}
          >
            Cancellation Policies
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box>
              {cancellationPolicy?.cancellation_policy_id && (
                <RadioGroup
                  aria-labelledby='demo-radio-buttons-group-label'
                  name='radio-buttons-group'
                  defaultValue={
                    cancellationPolicy?.cancellation_policy_id &&
                    cancellationPolicy?.cancellation_policy_id
                  }
                >
                  {canCelPolices?.map((cancel) => (
                    <>
                      <FormControlLabel
                        key={cancel.id}
                        onClick={(e) => setCanceId(cancel.id)}
                        value={cancel.id}
                        control={<Radio />}
                        label={<PoliciesContent data={cancel} />}
                      />
                    </>
                  ))}
                </RadioGroup>
              )}
            </Box>
          </Box>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 1, md: 2 }}
            columnSpacing={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={12} sm={12} md={12}>
              <RadioButton
                title={'Flexible - Full refund 1 day prior to arrival.'}
                value={'Flexible - Full refund 1 day prior to arrival.'}
              />
            </Grid>
          </Grid>
          <Divider sx={{ paddingTop: 3 }} />
          <Typography
            variant={'h6'}
            sx={{ paddingBottom: 3, paddingTop: 3, textAlign: 'initial' }}
          >
            Check-In/Check-Out
          </Typography>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 1, md: 2 }}
            columnSpacing={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={12} sm={12} md={6}>
              <Typography>Check-In Window</Typography>
              <Select
                className='override-select-edit'
                placeholder='Select Time'
                options={TIMINGS}
                value={checkIn}
                onChange={(e) => setCheckIn(e)}
                styles={customStyles}
              />
              {validationType.open && validationType.type == 'checkIn' && (
                <p style={{ color: 'red' }}>{validationType.message}</p>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Typography>Check-Out Window</Typography>
              <Select
                className='override-select-edit'
                placeholder='Select Time'
                options={TIMINGS}
                value={checkOut}
                onChange={(e) => setCheckOut(e)}
                styles={customStyles}
              />
              {validationType.open && validationType.type == 'checkOut' && (
                <p style={{ color: 'red' }}>{validationType.message}</p>
              )}
            </Grid>
          </Grid>
          <Divider sx={{ paddingTop: 3, marginBottom: 3 }} />
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 1, md: 2 }}
            columnSpacing={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={12} sm={12} md={2}>
              <Buttons
                title='Save'
                loadingTitle={'Saving'}
                isLoading={isButtonLoading}
                onSubmit={formik.handleSubmit}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default Policies;
