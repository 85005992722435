import { API_URL } from '../../config';
import { axiosPrivate } from '../../api/axios';

const getUserByIdApi = async (id) => {
  try {
    const response = await axiosPrivate.get(API_URL + `users/id/${id}`);
    return response.data?.data;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('bad response of get user: ', response);
      return response;
    }
  }
};
export default getUserByIdApi;
