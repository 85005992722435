import axios from "axios";
import { API_URL } from "../../config";
  
  export const getHouseRulesGroupApi = async () => {
    try {
      const response = await axios.get(API_URL + "house-rule-group/all");
      return response;
    } catch (error) {
      if (error) {
        const { response } = error;
        console.log("Bad Response of house-rule- : ", response);
        return response;
      }
    }
  };
  
  export const getHouseRulesByPropertyId = async (id) => {
    try {
      const response = await axios.get(API_URL + `property/houserule/${id}`);
      return response;
    } catch (error) {
      if (error) {
        const { response } = error;
        console.log("Bad Response of house rules : ", response);
        return response;
      }
    }
  };