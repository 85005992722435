import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { TabView, TabPanel } from 'primereact/tabview';
import Card from '../../../components/dasboard/card';
import InfoBar from '../../../components/dasboard/infoBar';
import Sidebar from '../../../components/dasboard/sidebar';
import { useParams } from 'react-router-dom';
import Header from '../../../components/header';
import AmenitiesEditProfile from './EditPropertiesSection/Amenities';
import HouseRules from './EditPropertiesSection/HouseRules';
import ListingBasic from './EditPropertiesSection/ListingBasic';
import SafetyGuest from './EditPropertiesSection/SafetyGuest';
import Pricing from './EditPropertiesSection/Pricing';
import Policies from './EditPropertiesSection/Policies';
import GuestInfo from './EditPropertiesSection/GuestInfo';
import Photos from './EditPropertiesSection/Photos';
import { BasicListingValidationSchema } from './EditPropertiesSection/ValidationSchema/ListingBasicValidationSchema';
import { PricingValidationSchema } from './EditPropertiesSection/ValidationSchema/PricingValidationSchema';
import { PoliciesValidationSchema } from './EditPropertiesSection/ValidationSchema/PoliciesValidationSchema';
import { GuestInfoValidationSchema } from './EditPropertiesSection/ValidationSchema/GuestInfoValidationSchema';
import { PhotosValidationSchema } from './EditPropertiesSection/ValidationSchema/PhotosValidationSchema';
import { EditorState, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML, convertFromHTML } from 'draft-convert';

import {
  getSpaceApi,
  getSpaceTypeById,
  getAreaApi,
} from '../../../services/properties/getAddPropertyDropdownValues';
import { getAmenitiesByPropertyId } from '../../../services/properties/getAmmenities';
import {
  getFacilityAPI,
  getAccessibilityAPI,
  getNearbyActivityAPI,
  getSecurityFeatureAPI,
  getSafetyFeatureAPI,
} from '../../../services/properties/getMiscellaneous';
import {
  getPropertyByIdApi,
  getPoliciesApi,
} from '../../../services/properties/getProperty';
import { Grid } from '@mui/material';
import SkeletonLoader from '../../../components/skeletonLoader';
import {
  editPropertyBasicInfo,
  editPropertyGuestInfo,
  editPropertyPolicies,
  editPropertyCapacityBasicInfo,
  editPhotoAndUrl,
  editPropertyPricing,
  editAmenity,
  editHouseRule,
  editSafetyConsideration,
  updateCancellationPolicies,
  editAccessibilities,
  editFacilities,
  editSecurityFeatures,
  editNearbyActivity,
  editSafetyFeature,
  editProperty,
  editPropertyInstantBooking,
} from '../../../services/properties/editProperty';
import { useSnackbar } from 'notistack';
import { getSafetyConsiderationByPropertyId } from '../../../services/properties/getSafetyConsiderations';
import { getHouseRulesByPropertyId } from '../../../services/properties/getHouseRules';
import { MediaValidationSchema } from '../AddPropertyPage/addPropertyValidationSchema';
import Miscellaneous from './EditPropertiesSection/Miscellaneous';

const EditPropertyPageRevamp = () => {
  //dropdown values getting from api.
  const { enqueueSnackbar } = useSnackbar();
  const [cancelValue, setCancelValue] = useState();
  const [spaceValues, setSpaceValues] = useState([]);
  const [spaceTypeValues, setSpaceTypeValues] = useState([]);
  const [areaValues, setAreaValues] = useState([]);
  const [noOfBathrooms, setNoOfBathrooms] = useState();
  const [amenitiesValue, setAmenitiesValue] = useState([]);
  const [safetyConsiderationValue, setSafetyConsiderationValue] = useState([]);
  const [houseRulesValue, setHouseRulesValue] = useState([]);
  const [propertyDetail, setPropertyDetail] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [canCelPolices, setCancelPolices] = useState([]);
  const [images, setImages] = useState([]);
  const [imagesURLs, setImagesURLs] = useState([]);
  const [thumbnail, setThumbnail] = useState([]);
  const [thumbnailURLs, setThumbnailURLs] = useState([]);
  const [errorImages, setErrorImages] = useState('');
  const [errorThumbnail, setErrorIThumbnail] = useState('');
  const [getFacility, setGetFacility] = useState([]);
  const [getAccessibility, setGetAccessibility] = useState([]);
  const [getNearbyActivity, setGetNearbyActivity] = useState([]);
  const [getSecurityFeature, setGetSecurityFeature] = useState([]);
  const [getSafetyFeature, setGetSafetyFeature] = useState([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [convertedContent, setConvertedContent] = useState('');
  const [spaceVal, setSpaceVal] = useState('');
  const [spaceTypeVal, setSpaceTypeVal] = useState('');
  const [areaVal, setAreaVal] = useState('');
  const [propertyType, setPropertyType] = useState('');
  const [privateBath, setPrivateBath] = useState({ label: '', value: '' });
  const [propertyDescLength, setPropertyDescLength] = useState(0);
  const [temporaryOptions, setTemporaryOptions] = useState([]);
  const [floodRelief, setFloodRelief] = useState('');
  const [mapCenter, setMapCenter] = useState({
    lat: 24.8840604,
    lng: 67.034556,
  });
  const [checkIn, setCheckIn] = useState('');
  const [checkOut, setCheckOut] = useState('');
  const userData = JSON.parse(localStorage.getItem('userData'));
  //Editor States Vars for Guest Info
  const [convertedContentOne, setConvertedContentOne] = useState('');
  const [convertedContentTwo, setConvertedContentTwo] = useState('');
  const [convertedContentThree, setConvertedContentThree] = useState('');
  const [validationType, setValidationType] = useState({
    message: '',
    open: false,
    type: '',
  });

  const { id } = useParams();

  const cancelPoliceData = {
    cancellationPolicyId: cancelValue
      ? cancelValue
      : parseInt(propertyDetail?.propertyPolicy?.cancellationPolicy),
    propertyId: parseInt(id),
  };
  const getDropdownValues = () => {
    getSpace();
    getArea();
    getAmenitiesGroup();
    getSafetyConsideration();
    getHouseRules();
    getMiscellaneousItems();
  };

  //upload formik schema
  const photosandurl = useFormik({
    initialValues: {
      youtubeVideoLink: propertyDetail?.youtubeVideoLink,
    },
    enableReinitialize: true,
    validationSchema: () => {
      return MediaValidationSchema;
    },
    onSubmit: async () => {
      try {
        setIsButtonLoading(true);
        if (
          (images?.length > 0 || photos?.values?.images?.length > 0) &&
          (thumbnail?.length > 0 || photos?.values?.thumbnail)
        ) {
          var uploadData = new FormData();

          for (let i = 0; i < images.length; i++) {
            uploadData.append('galleryFiles', images[i]);
          }
          for (let i = 0; i < photos?.values?.images?.length; i++) {
            uploadData.append('galleryFiles', photos?.values?.images[i]);
          }

          if (thumbnail.length != 0) {
            for (let i = 0; i < thumbnail?.length; i++) {
              uploadData.append('thumbnailFile', thumbnail[i]);
            }
          } else {
            uploadData.append('thumbnailFile', photos?.values?.thumbnail);
          }
          uploadData.append(
            'youtubeVideoLink',
            photosandurl?.values?.youtubeVideoLink
          );
          const response = await editPhotoAndUrl(id, uploadData).then(() => {
            enqueueSnackbar('Property successfully edited', {
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
            });
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          });
          setIsButtonLoading(false);
        } else {
          if (images?.length == 0) setErrorImages('Images feild is required');
          if (thumbnail?.length == 0)
            setErrorIThumbnail('Main picture field is required');
        }
        setIsButtonLoading(false);
      } catch (error) {
        setIsButtonLoading(false);
      }
    },
  });

  useEffect(() => {
    getPropertyDetail();
    getDropdownValues();
    getCancelPolices();
  }, []);

  // get getPropertyDetail
  const getPropertyDetail = async () => {
    try {
      const response = await getPropertyByIdApi(id);
      if (response.status === 200) {
        setPropertyDetail(response.data.data);
        let result = response.data.data;
        setCheckIn({
          label: result.propertyPolicy.checkInWindow,
          value: result.propertyPolicy.checkInWindow,
        });
        setCheckOut({
          label: result.propertyPolicy.checkoutWindow,
          value: result.propertyPolicy.checkoutWindow,
        });
        setSpaceVal({ label: result.space.title, value: result.space.id });
        setSpaceTypeVal({
          label: result.spaceType.title,
          value: result.spaceType.id,
        });
        setFloodRelief(result?.floodRelief);
        setAreaVal({ label: result.area.title, value: result.area.id });
        setPropertyType({
          label: result.propertyType,
          value: result.propertyType,
        });
        setMapCenter({
          lat: result?.lat,
          lng: result?.lng,
        });
        // if (
        //   result.propertyCapacity !== null &&
        //   result.propertyCapacity.isPrivateBathroom
        // ) {
        //   setPrivateBath({
        //     label: "Yes",
        //     value: result.propertyCapacity.isPrivateBathroom,
        //   });
        // } else if (
        //   result.propertyCapacity !== null &&
        //   !result.propertyCapacity.isPrivateBathroom
        // ) {
        //   setPrivateBath({
        //     label: "No",
        //     value: result.propertyCapacity.isPrivateBathroom,
        //   });
        // }
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  const getCancelPolices = async () => {
    try {
      const response = await getPoliciesApi();
      if (response.status === 200) {
        setCancelPolices(response.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  //getting space dropdown value from api
  const getSpace = async () => {
    const response = await getSpaceApi();
    if (response.status === 200) {
      let tempArr = [];
      response.data.data.map((item) => {
        tempArr.push({
          label: item.title,
          value: item.id,
        });
      });
      setSpaceValues(tempArr);
    }
  };

  //getting space type dropdown value from api
  const getSpaceType = async (selectedOption) => {
    const response = await getSpaceTypeById(selectedOption.value);
    if (response.status === 200) {
      let tempArr = [...temporaryOptions];
      let newArr = [];
      response.data.data.map((item) => {
        newArr.push({
          label: item.title,
          value: item.id,
        });
      });
      tempArr.push({
        type: selectedOption,
        data: newArr,
      });

      if (temporaryOptions.length > 0) {
        setTemporaryOptions(tempArr);
        setSpaceTypeValues(newArr);
      } else {
        setTemporaryOptions(tempArr);
        setSpaceTypeValues(tempArr[0].data);
      }
    }
  };

  //getting area dropdown value from api
  const getArea = async () => {
    const response = await getAreaApi();
    if (response.status === 200) {
      let tempArr = [];
      response.data.data.map((item) => {
        tempArr.push({
          label: item.title,
          value: item.id,
        });
      });
      setAreaValues(tempArr);
    }
  };

  //getting amentites groups from api
  const getAmenitiesGroup = async () => {
    const response = await getAmenitiesByPropertyId(id);
    if (response.status === 200) {
      setAmenitiesValue(response?.data?.data);
    }
  };
  // getting Miscellaneous
  const getMiscellaneousItems = async () => {
    const getFacility = await getFacilityAPI(id);
    if (getFacility.status === 200) {
      setGetFacility(getFacility?.data?.data);
    }
    const getAccessibilities = await getAccessibilityAPI(id);
    if (getAccessibilities.status === 200) {
      setGetAccessibility(getAccessibilities?.data?.data);
    }
    const getNearbyActivity = await getNearbyActivityAPI(id);
    if (getNearbyActivity.status === 200) {
      setGetNearbyActivity(getNearbyActivity?.data?.data);
    }
    const getSecurityFeature = await getSecurityFeatureAPI(id);
    if (getSecurityFeature.status === 200) {
      setGetSecurityFeature(getSecurityFeature?.data?.data);
    }
    const getSafetyFeature = await getSafetyFeatureAPI(id);
    if (getSafetyFeature.status === 200) {
      setGetSafetyFeature(getSafetyFeature?.data?.data);
    }
  };

  //getting safety consideration groups from api
  const getSafetyConsideration = async () => {
    const response = await getSafetyConsiderationByPropertyId(id);
    if (response.status === 200) {
      setSafetyConsiderationValue(response?.data?.data);
    }
  };

  //getting house rules groups from api
  const getHouseRules = async () => {
    const response = await getHouseRulesByPropertyId(id);
    if (response.status === 200) {
      setHouseRulesValue(response?.data?.data);
    }
  };
  //list basic formik schema
  const listingBasicFormik = useFormik({
    initialValues: {
      //Property Description
      title: propertyDetail?.title,
      description: propertyDetail?.description,
      //Property Type
      // spaceId: propertyDetail?.spaceId,
      // spaceTypeId: propertyDetail?.spaceTypeId,
      // areaId: propertyDetail?.areaId,
      // propertyType: propertyDetail?.propertyType,
      //Custom Link
      customUrl: propertyDetail.customUrl,
      //Guest Info
      noOfAdults: propertyDetail?.propertyCapacity?.noOfAdults,
      noOfChildren: propertyDetail?.propertyCapacity?.noOfChildren,
      noOfInfants: propertyDetail?.propertyCapacity?.noOfInfants,
      noOfPets: propertyDetail?.propertyCapacity?.noOfPets,
      noOfBedrooms: propertyDetail?.propertyCapacity?.noOfBedrooms,
      noOfBathrooms: propertyDetail?.propertyCapacity?.noOfBathrooms,
      noOfBeds: propertyDetail?.propertyCapacity?.noOfBeds,
      // isPrivateBathroom: propertyDetail?.propertyCapacity?.isPrivateBathroom,
      //Location
      zipCode: propertyDetail?.zipCode,
      address: propertyDetail?.address,
      country: propertyDetail?.country,
      state: propertyDetail?.state,
      city: propertyDetail?.city,
      lat: propertyDetail?.lat,
      lng: propertyDetail?.lng,
      status: propertyDetail?.status,
    },
    enableReinitialize: true,
    // validationSchema: () => {
    //   return BasicListingValidationSchema;
    // },
    onSubmit: async (values) => {
      if (spaceVal == '') {
        setValidationType({
          message: 'Space is required',
          open: true,
          type: 'space',
        });
      } else if (spaceTypeVal == '') {
        setValidationType({
          message: 'Space Type is required',
          open: true,
          type: 'spaceType',
        });
      } else if (areaVal == '') {
        setValidationType({
          message: 'Area is required',
          open: true,
          type: 'area',
        });
      } else if (propertyType == '') {
        setValidationType({
          message: 'Property Type is required',
          open: true,
          type: 'propertyType',
        });
      } else if (privateBath == '') {
        setValidationType({
          message: 'Private bathroom field is required',
          open: true,
          type: 'privateBath',
        });
      } else if (noOfBathrooms < 1) {
        setValidationType({
          message: 'Number of bathrooms must be at least one!',
          open: true,
          type: 'noOfBathrooms',
        });
      } else {
        setValidationType({
          message: '',
          open: false,
          type: '',
        });

        const data = {
          title: values.title,
          description: convertedContent
            ? convertedContent
            : propertyDetail?.description,
          spaceId: spaceVal !== '' ? spaceVal.value : '',
          spaceTypeId: spaceTypeVal !== '' ? spaceTypeVal.value : '',
          areaId: areaVal !== '' ? areaVal.value : '',
          customUrl: values.customUrl,
          address: values.address,
          propertyType: propertyType !== '' ? propertyType.value : '',
          city: values.city,
          country: values.country,
          state: values.state,
          zipCode: values.zipCode,
          lat: values.lat,
          lng: values.lng,
          status: values.status,
          floodRelief: JSON.parse(floodRelief),
          userId: userData?.id,
        };
        const PropertyCapacity = {
          noOfAdults: values.noOfAdults,
          noOfChildren: values.noOfChildren,
          noOfInfants: values.noOfInfants,
          noOfPets: values.noOfPets,
          noOfBeds: values.noOfBeds,
          noOfBedrooms: values.noOfBedrooms,
          noOfBathrooms: parseFloat(noOfBathrooms),
          isPrivateBathroom: privateBath !== '' ? privateBath.value : '',
        };
        //editPropertyBasicInfoApi
        try {
          setIsButtonLoading(true);
          const response = await editPropertyBasicInfo(id, data);
          const responseOfPropertyCapacity =
            await editPropertyCapacityBasicInfo(id, PropertyCapacity);
          if (
            response.status === 200 &&
            responseOfPropertyCapacity?.status === 200
          ) {
            setIsButtonLoading(false);
            enqueueSnackbar('Property successfully edited', {
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
            });
            setIsButtonLoading(false);
          }
        } catch (err) {
          setIsButtonLoading(false);
          enqueueSnackbar('Error. Cannot edit property right now.', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        }
      }
    },
  });

  //pricing formik schema
  const pricingFormik = useFormik({
    initialValues: {
      //Pricing Configration
      price: propertyDetail?.price,
      id: propertyDetail?.id,
      perNightPrice: propertyDetail?.perNightPrice,
      weeklyNightPrice:
        propertyDetail?.propertyPricePolicy &&
        propertyDetail?.propertyPricePolicy?.weeklyNightPrice,
      monthlyNightPrice:
        propertyDetail?.propertyPricePolicy &&
        propertyDetail?.propertyPricePolicy?.monthlyNightPrice,
      customLengthOfStayDiscount:
        propertyDetail?.propertyPricePolicy &&
        propertyDetail?.propertyPricePolicy?.customLengthOfStayDiscount,
      //Additional Charges
      cleaningFee:
        propertyDetail?.propertyPricePolicy &&
        propertyDetail?.propertyPricePolicy?.cleaningFee,
      petFee:
        propertyDetail?.propertyPricePolicy &&
        propertyDetail?.propertyPricePolicy?.petFee,
      extraGuestFee:
        propertyDetail?.propertyPricePolicy &&
        propertyDetail?.propertyPricePolicy?.extraGuestFee,
      extraGuestFeeAfter:
        propertyDetail?.propertyPricePolicy &&
        propertyDetail?.propertyPricePolicy?.extraGuestFeeAfter,
      weekendNightPrice:
        propertyDetail?.propertyPricePolicy &&
        propertyDetail?.propertyPricePolicy?.weekendNightPrice,
      //Trip Length
      minimumStay:
        propertyDetail?.propertyPricePolicy &&
        propertyDetail?.propertyPricePolicy?.minimumStay,
      maximumStay:
        propertyDetail?.propertyPricePolicy &&
        propertyDetail?.propertyPricePolicy?.maximumStay,
      propertyId: propertyDetail?.id,
      advanceNotice: {
        value: propertyDetail?.propertyPolicy?.advanceNotice,
        label: propertyDetail?.propertyPolicy?.advanceNotice,
      },
      sameDayCheckoutTime: {
        value: propertyDetail?.propertyPolicy?.sameDayCheckoutTime,
        label: propertyDetail?.propertyPolicy?.sameDayCheckoutTime,
      },
      preparationTime: {
        value: propertyDetail?.propertyPolicy?.preparationTime,
        label: propertyDetail?.propertyPolicy?.preparationTime,
      },
      availabilityWindow: {
        value: propertyDetail?.propertyPolicy?.availabilityWindow,
        label: propertyDetail?.propertyPolicy?.availabilityWindow,
      },
    },
    enableReinitialize: true,
    validationSchema: () => {
      return PricingValidationSchema;
    },
    onSubmit: async (values) => {
      //editPropertyPricingApi
      try {
        const data = {
          price: values.price,
          perNightPrice: values.perNightPrice,
          weeklyNightPrice: values.weeklyNightPrice,
          monthlyNightPrice: values.monthlyNightPrice,
          customLengthOfStayDiscount: values.customLengthOfStayDiscount,
          //Additional Charges
          cleaningFee: values.cleaningFee,
          petFee: values.petFee,
          extraGuestFee: values.extraGuestFee,
          extraGuestFeeAfter: values.extraGuestFeeAfter,
          weekendNightPrice: values.weekendNightPrice,
          //Trip Length
          minimumStay: values.minimumStay,
          maximumStay: values.maximumStay,
          propertyId: values.propertyId,
        };
        const dataOfPropertyPolicy = {
          advanceNotice: values.advanceNotice.value,
          propertyId: values.propertyId,
          ...(values.advanceNotice.value ===
            'Same day (customizable cutoff hour)'
            ? {
              sameDayCheckoutTime: values.sameDayCheckoutTime.value,
            }
            : ''),
          preparationTime: values.preparationTime.value,
          availabilityWindow: values.availabilityWindow.value,
        };

        setIsButtonLoading(true);
        const response = await editPropertyPricing(id, data);
        const resOfPropertyPolicyUpdate = await editPropertyPolicies(
          id,
          dataOfPropertyPolicy
        );
        if (
          response.status === 200 ||
          resOfPropertyPolicyUpdate.status === 200
        ) {
          setIsButtonLoading(false);
          enqueueSnackbar('Property successfully edited', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        }
      } catch (err) {
        setIsButtonLoading(false);
        enqueueSnackbar('Error. Cannot edit property right now.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      }
    },
  });

  //pollicies formik schema
  const policiesFormik = useFormik({
    initialValues: {
      governmentIdRequired: propertyDetail?.propertyPolicy?.governmentIdRequired
        ? propertyDetail?.propertyPolicy?.governmentIdRequired
        : false,
      guestPhotoRequirement: propertyDetail?.propertyPolicy?.guestPhotoRequirement
        ? propertyDetail?.propertyPolicy?.guestPhotoRequirement
        : false,
      //Check-In/Check-Out
      checkInWindow: checkIn.value ? checkIn.value : '',
      checkoutWindow: checkOut.value ? checkOut.value : '',
      //Pre Booking Message
      preBookingMessageRequired:
        propertyDetail?.propertyPolicy?.preBookingMessageRequired,
      instantBooking: propertyDetail?.instantBooking
        ? propertyDetail?.instantBooking
        : false,
      userId: userData?.id,
    },
    enableReinitialize: true,
    validationSchema: () => {
      return PoliciesValidationSchema;
    },
    onSubmit: async (values) => {
      if (checkIn === '') {
        setValidationType({
          message: 'Check-in window is required',
          open: true,
          type: 'checkIn',
        });
      } else if (checkOut === '') {
        setValidationType({
          message: 'Check-out window is required',
          open: true,
          type: 'checkOut',
        });
      } else {
        try {
          setIsButtonLoading(true);
          const response = await editPropertyPolicies(id, values);
          await editPropertyInstantBooking(id, {
            instantBooking: values.instantBooking,
            userId: userData?.id,
          });
          updateCancellationPolicies(cancelPoliceData);
          if (response.status === 200) {
            setIsButtonLoading(false);
            enqueueSnackbar('Property successfully edited', {
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
            });
          }
        } catch (err) {
          setIsButtonLoading(false);
          enqueueSnackbar('Error. Cannot edit property right now.', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        }
      }
    },
  });

  //Guest info formik schema
  const guestInfoFormik = useFormik({
    initialValues: {
      //Pre-Booking Configration
      // interactionWithGuest: propertyDetail?.interactionWithGuest,
      //Arrival Details - Instructions
      checkInInstruction: propertyDetail?.checkInInstruction,
      //Arrival Details - Wifi Details
      wifiUsername: propertyDetail?.wifiUsername,
      wifiPassword: propertyDetail?.wifiPassword,
    },
    enableReinitialize: true,
    validationSchema: () => {
      return GuestInfoValidationSchema;
    },
    onSubmit: async (values) => {
      try {
        setIsButtonLoading(true);
        const response = await editPropertyGuestInfo(id, values);
        if (response.status === 200) {
          setIsButtonLoading(false);
          enqueueSnackbar('Property successfully edited', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        }
      } catch (err) {
        setIsButtonLoading(false);
        enqueueSnackbar('Error. Cannot edit property right now.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      }
    },
  });

  //photos info formik schema
  const photos = useFormik({
    initialValues: {
      thumbnail: propertyDetail?.thumbnailUrl,
      images: propertyDetail.propertyImages,
    },
    enableReinitialize: true,
    validationSchema: () => {
      return PhotosValidationSchema;
    },
    onSubmit: () => {
      //Api for Guest Info
    },
  });

  const selectAmenities = async (
    amenityId,
    amenityIndex,
    amenityGroupIndex,
    event
  ) => {
    try {
      let copyAmenitiesValue = [...amenitiesValue];
      if (event.target.checked === true) {
        let data = {
          propertyId: parseInt(id),
          amenityId: amenityId,
          status: true,
        };
        const response = await editAmenity(data);
        if (response.status === 200) {
          copyAmenitiesValue[amenityGroupIndex].amenities[
            amenityIndex
          ].check_status =
            !copyAmenitiesValue[amenityGroupIndex]?.amenities[amenityIndex]
              ?.check_status;
          setAmenitiesValue([...copyAmenitiesValue]);
          enqueueSnackbar('Property amenity successfully updated', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        }
      } else if (event.target.checked === false) {
        let data = {
          propertyId: parseInt(id),
          amenityId: amenityId,
          status: false,
        };
        const response = await editAmenity(data);
        if (response.status === 200) {
          copyAmenitiesValue[amenityGroupIndex].amenities[
            amenityIndex
          ].check_status =
            !copyAmenitiesValue[amenityGroupIndex]?.amenities[amenityIndex]
              ?.check_status;
          setAmenitiesValue([...copyAmenitiesValue]);
          enqueueSnackbar('Property amenity successfully updated', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        }
      }
    } catch (err) {
      enqueueSnackbar('Failed to update property amenity', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
  };
  const selectAccessibilties = async (
    accessibiltiyId,
    accessibiltiyIndex,
    accessibiltiyGroupIndex,
    event
  ) => {
    try {
      let copyAccessibilityValue = [...getAccessibility];
      if (event.target.checked === true) {
        let data = {
          propertyId: parseInt(id),
          accessibilityId: accessibiltiyId,
          status: true,
        };
        const response = await editAccessibilities(data);
        if (response.status === 200) {
          copyAccessibilityValue[accessibiltiyGroupIndex].accessibility[
            accessibiltiyIndex
          ].check_status =
            !copyAccessibilityValue[accessibiltiyGroupIndex]?.accessibility[
              accessibiltiyIndex
            ]?.check_status;
          setAmenitiesValue([...copyAccessibilityValue]);
          enqueueSnackbar('Property accessibility successfully updated', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        }
      } else if (event.target.checked === false) {
        let data = {
          propertyId: parseInt(id),
          accessibilityId: accessibiltiyId,
          status: false,
        };
        const response = await editAccessibilities(data);
        if (response.status === 200) {
          copyAccessibilityValue[accessibiltiyGroupIndex].accessibility[
            accessibiltiyIndex
          ].check_status =
            !copyAccessibilityValue[accessibiltiyGroupIndex]?.accessibility[
              accessibiltiyIndex
            ]?.check_status;
          setAmenitiesValue([...copyAccessibilityValue]);
          enqueueSnackbar('Property accessibility successfully updated', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        }
      }
    } catch (err) {
      enqueueSnackbar('Failed to update property accessibility', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
  };
  const selectFacilities = async (
    facilityId,
    facilityIndex,
    facilityGroupIndex,
    event
  ) => {
    try {
      let copyFacilityValue = [...getFacility];
      if (event.target.checked === true) {
        let data = {
          propertyId: parseInt(id),
          facilityId: facilityId,
          status: true,
        };
        const response = await editFacilities(data);
        if (response.status === 200) {
          copyFacilityValue[facilityGroupIndex].facilities[
            facilityIndex
          ].check_status =
            !copyFacilityValue[facilityGroupIndex]?.facilities[facilityIndex]
              ?.check_status;
          setAmenitiesValue([...copyFacilityValue]);
          enqueueSnackbar('Property facility successfully updated', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        }
      } else if (event.target.checked === false) {
        let data = {
          propertyId: parseInt(id),
          facilityId: facilityId,
          status: false,
        };
        const response = await editFacilities(data);
        if (response.status === 200) {
          copyFacilityValue[facilityGroupIndex].facilities[
            facilityIndex
          ].check_status =
            !copyFacilityValue[facilityGroupIndex]?.facilities[facilityIndex]
              ?.check_status;
          setAmenitiesValue([...copyFacilityValue]);
          enqueueSnackbar('Property facility successfully updated', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        }
      }
    } catch (err) {
      enqueueSnackbar('Failed to update property facility', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
  };
  const selectSecurityFeature = async (sfId, sfIndex, sfGroupIndex, event) => {
    try {
      let copySfValue = [...getSecurityFeature];
      if (event.target.checked === true) {
        let data = {
          propertyId: parseInt(id),
          securityFeatureId: sfId,
          status: true,
        };
        const response = await editSecurityFeatures(data);
        if (response.status === 200) {
          copySfValue[sfGroupIndex].securityFeature[sfIndex].check_status =
            !copySfValue[sfGroupIndex]?.securityFeature[sfIndex]?.check_status;
          setAmenitiesValue([...copySfValue]);
          enqueueSnackbar('Property security feature successfully updated', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        }
      } else if (event.target.checked === false) {
        let data = {
          propertyId: parseInt(id),
          securityFeatureId: sfId,
          status: false,
        };
        const response = await editSecurityFeatures(data);
        if (response.status === 200) {
          copySfValue[sfGroupIndex].securityFeature[sfIndex].check_status =
            !copySfValue[sfGroupIndex]?.securityFeature[sfIndex]?.check_status;
          setAmenitiesValue([...copySfValue]);
          enqueueSnackbar('Property security feature successfully updated', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        }
      }
    } catch (err) {
      enqueueSnackbar('Failed to update property security feature', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
  };
  const selectSafetyFeature = async (sfId, sfIndex, sfGroupIndex, event) => {
    try {
      let copySfValue = [...getSafetyFeature];
      if (event.target.checked === true) {
        let data = {
          propertyId: parseInt(id),
          safetyFeatureId: sfId,
          status: true,
        };
        const response = await editSafetyFeature(data);
        if (response.status === 200) {
          copySfValue[sfGroupIndex].safetyFeature[sfIndex].check_status =
            !copySfValue[sfGroupIndex]?.safetyFeature[sfIndex]?.check_status;
          setAmenitiesValue([...copySfValue]);
          enqueueSnackbar('Property safety feature successfully updated', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        }
      } else if (event.target.checked === false) {
        let data = {
          propertyId: parseInt(id),
          safetyFeatureId: sfId,
          status: false,
        };
        const response = await editSafetyFeature(data);
        if (response.status === 200) {
          copySfValue[sfGroupIndex].safetyFeature[sfIndex].check_status =
            !copySfValue[sfGroupIndex]?.safetyFeature[sfIndex]?.check_status;
          setAmenitiesValue([...copySfValue]);
          enqueueSnackbar('Property safety feature successfully updated', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        }
      }
    } catch (err) {
      enqueueSnackbar('Failed to update property safety feature', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
  };
  const selectNearbyActivity = async (
    nearbyActivityId,
    nearbyActivityIndex,
    nearbyActivityGroupIndex,
    event
  ) => {
    try {
      let copyNearbyActivityValue = [...getNearbyActivity];
      if (event.target.checked === true) {
        let data = {
          propertyId: parseInt(id),
          nearbyActivityId: nearbyActivityId,
          status: true,
        };
        const response = await editNearbyActivity(data);
        if (response.status === 200) {
          copyNearbyActivityValue[nearbyActivityGroupIndex].nearbyActivity[
            nearbyActivityIndex
          ].check_status =
            !copyNearbyActivityValue[nearbyActivityGroupIndex]?.nearbyActivity[
              nearbyActivityIndex
            ]?.check_status;
          setAmenitiesValue([...copyNearbyActivityValue]);
          enqueueSnackbar('Property nearby activity successfully updated', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        }
      } else if (event.target.checked === false) {
        let data = {
          propertyId: parseInt(id),
          nearbyActivityId: nearbyActivityId,
          status: false,
        };
        const response = await editNearbyActivity(data);
        if (response.status === 200) {
          copyNearbyActivityValue[nearbyActivityGroupIndex].nearbyActivity[
            nearbyActivityIndex
          ].check_status =
            !copyNearbyActivityValue[nearbyActivityGroupIndex]?.nearbyActivity[
              nearbyActivityIndex
            ]?.check_status;
          setAmenitiesValue([...copyNearbyActivityValue]);
          enqueueSnackbar('Property nearby activity successfully updated', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        }
      }
    } catch (err) {
      enqueueSnackbar('Failed to update property nearby activity', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
  };

  const selectHouseRule = async (
    houseRuleId,
    houseRuleGroupIndex,
    houseRuleIndex,
    event
  ) => {
    try {
      let copyHouseRuleValue = [...houseRulesValue];
      if (event.target.checked === true) {
        let data = {
          propertyId: parseInt(id),
          houseRuleId: houseRuleId,
          status: true,
        };
        const response = await editHouseRule(data);
        if (response.status === 200) {
          copyHouseRuleValue[houseRuleGroupIndex].houseRules[
            houseRuleIndex
          ].check_status =
            !copyHouseRuleValue[houseRuleGroupIndex]?.houseRules[houseRuleIndex]
              ?.check_status;
          setAmenitiesValue([...copyHouseRuleValue]);
          enqueueSnackbar('Property house rule successfully updated', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        }
      } else if (event.target.checked === false) {
        let data = {
          propertyId: parseInt(id),
          houseRuleId: houseRuleId,
          status: false,
        };
        const response = await editHouseRule(data);
        if (response.status === 200) {
          copyHouseRuleValue[houseRuleGroupIndex].houseRules[
            houseRuleIndex
          ].check_status =
            !copyHouseRuleValue[houseRuleGroupIndex]?.houseRules[houseRuleIndex]
              ?.check_status;
          setAmenitiesValue([...copyHouseRuleValue]);
          enqueueSnackbar('Property house rule successfully updated', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        }
      }
    } catch (err) {
      enqueueSnackbar('Failed to update property amenity', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
  };

  const selectSafetyConsideration = async (
    safetyConsiderationId,
    safetyConsiderationGroupIndex,
    safetyConsiderationIndex,
    event
  ) => {
    try {
      let copySafetyConsiderationValue = [...safetyConsiderationValue];
      if (event.target.checked === true) {
        let data = {
          propertyId: parseInt(id),
          safetyConsiderationId: safetyConsiderationId,
          status: true,
        };
        const response = await editSafetyConsideration(data);
        if (response.status === 200) {
          copySafetyConsiderationValue[
            safetyConsiderationGroupIndex
          ].safetyConsideration[safetyConsiderationIndex].check_status =
            !copySafetyConsiderationValue[safetyConsiderationGroupIndex]
              ?.safetyConsideration[safetyConsiderationIndex]?.check_status;
          setSafetyConsiderationValue([...copySafetyConsiderationValue]);
          enqueueSnackbar(
            'Property safety consideration successfully updated',
            {
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
            }
          );
        }
      } else if (event.target.checked === false) {
        let data = {
          propertyId: parseInt(id),
          safetyConsiderationId: safetyConsiderationId,
          status: false,
        };
        const response = await editSafetyConsideration(data);
        if (response.status === 200) {
          copySafetyConsiderationValue[
            safetyConsiderationGroupIndex
          ].safetyConsideration[safetyConsiderationIndex].check_status =
            !copySafetyConsiderationValue[safetyConsiderationGroupIndex]
              ?.safetyConsideration[safetyConsiderationIndex]?.check_status;
          setSafetyConsiderationValue([...copySafetyConsiderationValue]);
          enqueueSnackbar(
            'Property safety consideration successfully updated',
            {
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
            }
          );
        }
      }
    } catch (err) {
      enqueueSnackbar('Failed to update property amenity', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
  };

  useEffect(() => {
    setPrivateBath({
      label: propertyDetail?.propertyCapacity?.isPrivateBathroom ? 'Yes' : 'No',
      value: propertyDetail?.propertyCapacity?.isPrivateBathroom,
    });
    setNoOfBathrooms(propertyDetail?.propertyCapacity?.noOfBathrooms);
  }, [propertyDetail]);

  //Guest Info Editor States
  const [editorStateOne, setEditorStateOne] = useState(
    EditorState.createEmpty()
  );
  const [editorStateTwo, setEditorStateTwo] = useState(
    EditorState.createEmpty()
  );
  const [editorStateThree, setEditorStateThree] = useState(
    EditorState.createEmpty()
  );

  useEffect(() => {
    if (guestInfoFormik.values.interactionWithGuest) {
      setEditorStateOne(
        EditorState.createWithContent(
          convertFromHTML(guestInfoFormik.values.interactionWithGuest)
        )
      );
    }
    if (guestInfoFormik.values.guestManual) {
      setEditorStateTwo(
        EditorState.createWithContent(
          convertFromHTML(guestInfoFormik.values.guestManual)
        )
      );
    }
    if (guestInfoFormik.values.checkInInstruction) {
      setEditorStateThree(
        EditorState.createWithContent(
          convertFromHTML(guestInfoFormik.values.checkInInstruction)
        )
      );
    }
    if (listingBasicFormik.values.description) {
      setEditorState(
        EditorState.createWithContent(
          convertFromHTML(listingBasicFormik.values.description)
        )
      );
    }
  }, [
    listingBasicFormik.values.description,
    guestInfoFormik.values.checkInInstruction,
    guestInfoFormik.values.guestManual,
    guestInfoFormik.values.interactionWithGuest,
  ]);

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  };

  const handleEditorChange1 = (state) => {
    setEditorStateOne(state);
    convertContentToHTML1();
  };

  const handleEditorChange2 = (state) => {
    setEditorStateTwo(state);
    convertContentToHTML2();
  };
  const handleEditorChange3 = (state) => {
    setEditorStateThree(state);
    convertContentToHTML3();
  };
  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  };
  const convertContentToHTML1 = () => {
    let currentContentAsHTML = convertToHTML(
      editorStateOne.getCurrentContent()
    );
    setConvertedContentOne(currentContentAsHTML);
  };
  const convertContentToHTML2 = () => {
    let currentContentAsHTML = convertToHTML(
      editorStateTwo.getCurrentContent()
    );
    setConvertedContentTwo(currentContentAsHTML);
  };
  const convertContentToHTML3 = () => {
    let currentContentAsHTML = convertToHTML(
      editorStateThree.getCurrentContent()
    );
    setConvertedContentThree(currentContentAsHTML);
  };

  useEffect(() => {
    let newDesc = listingBasicFormik.values.description
      ?.replace('<p>', '')
      .replace('</p>', '')
      .replace('&amp;', '');
    setPropertyDescLength(newDesc?.length);
    if (convertedContent) {
      let cDesc = convertedContent
        ?.replace('<p>', '')
        .replace('</p>', '')
        .replace('&amp;', '');
      setPropertyDescLength(cDesc?.length);
    }
  }, [listingBasicFormik.values.description, convertedContent]);

  const handleChangeFlood = (e) => {
    setFloodRelief(e.target.value);
  };

  return (
    <>
      <Header />
      <div id='wrapper'>
        <div className='dashboard-menu-overlay' />
        <Sidebar page={'My Listing'} />
        <div className='dashboard-content'>
          <div className='dashboard-menu-btn color-bg'>Dasboard Menu</div>
          <InfoBar label={'Edit Property'} />
          <div className='dasboard-wrapper fl-wrap no-pag'>
            <Card
              withIcon
              title={'Edit Property -'}
              subtitle={propertyDetail.title}
              code={propertyDetail.code}
              status={propertyDetail.status}
              instantBooking={propertyDetail?.instantBooking}
              propertyId={id}
              icon={'fa fa-pencil'}
            >
              {isLoading === true ? (
                <Loader />
              ) : (
                <TabView>
                  <TabPanel header='Photos'>
                    <Photos
                      photos={photos}
                      formik={photosandurl}
                      setImagesURLs={setImagesURLs}
                      imagesURLs={imagesURLs}
                      images={images}
                      setImages={setImages}
                      setThumbnailURLs={setThumbnailURLs}
                      thumbnailURLs={thumbnailURLs}
                      thumbnail={thumbnail}
                      setThumbnail={setThumbnail}
                      errorImages={errorImages}
                      errorThumbnail={errorThumbnail}
                      isButtonLoading={isButtonLoading}
                    />
                  </TabPanel>
                  <TabPanel header='Amenities'>
                    <AmenitiesEditProfile
                      amenitiesValues={amenitiesValue}
                      handleCheckbox={selectAmenities}
                    />
                  </TabPanel>
                  <TabPanel header='House Rule'>
                    <HouseRules
                      houseRulesValues={houseRulesValue}
                      handleCheckbox={selectHouseRule}
                      propertyPolicy={propertyDetail?.propertyPolicy}
                    />
                  </TabPanel>
                  <TabPanel header='Listing Basic'>
                    <ListingBasic
                      isButtonLoading={isButtonLoading}
                      propertyId={id}
                      spaceValues={spaceValues}
                      spaceTypeValues={spaceTypeValues}
                      areaValues={areaValues}
                      formik={listingBasicFormik}
                      noOfBathrooms={noOfBathrooms}
                      setNoOfBathrooms={setNoOfBathrooms}
                      spaceVal={spaceVal}
                      setSpaceVal={setSpaceVal}
                      getSpaceType={getSpaceType}
                      setSpaceTypeVal={setSpaceTypeVal}
                      spaceTypeVal={spaceTypeVal}
                      setAreaVal={setAreaVal}
                      areaVal={areaVal}
                      propertyType={propertyType}
                      setPropertyType={setPropertyType}
                      validationType={validationType}
                      privateBath={privateBath}
                      setPrivateBath={setPrivateBath}
                      editorState={editorState}
                      handleEditorChange={handleEditorChange}
                      propertyDescLength={propertyDescLength}
                      temporaryOptions={temporaryOptions}
                      setSpaceTypeValues={setSpaceTypeValues}
                      floodRelief={floodRelief}
                      handleChangeFlood={handleChangeFlood}
                      setMapCenter={setMapCenter}
                      mapCenter={mapCenter}
                      propertyDetail={propertyDetail}
                    />
                  </TabPanel>
                  <TabPanel header='Guest Safety'>
                    <SafetyGuest
                      safetyConsiderationValues={safetyConsiderationValue}
                      handleCheckbox={selectSafetyConsideration}
                    />
                  </TabPanel>
                  <TabPanel header='Pricing'>
                    <Pricing
                      isButtonLoading={isButtonLoading}
                      formik={pricingFormik}
                      propertyDetail={propertyDetail}
                    />
                  </TabPanel>
                  <TabPanel header='Policies'>
                    <Policies
                      isButtonLoading={isButtonLoading}
                      formik={policiesFormik}
                      canCelPolices={canCelPolices}
                      setCancelValue={setCancelValue}
                      id={propertyDetail?.id}
                      validationType={validationType}
                      checkIn={checkIn}
                      setCheckIn={setCheckIn}
                      checkOut={checkOut}
                      setCheckOut={setCheckOut}
                    />
                  </TabPanel>
                  <TabPanel header='Guest Info'>
                    <GuestInfo
                      isButtonLoading={isButtonLoading}
                      formik={guestInfoFormik}
                      editorStateOne={editorStateOne}
                      handleEditorChange1={handleEditorChange1}
                      editorStateTwo={editorStateTwo}
                      handleEditorChange2={handleEditorChange2}
                      editorStateThree={editorStateThree}
                      handleEditorChange3={handleEditorChange3}
                    />
                  </TabPanel>
                  <TabPanel header='Miscellaneous'>
                    <Miscellaneous
                      getFacility={getFacility}
                      getAccessibility={getAccessibility}
                      getNearbyActivity={getNearbyActivity}
                      getSecurityFeature={getSecurityFeature}
                      getSafetyFeature={getSafetyFeature}
                      handleCheckbox={selectAmenities}
                      selectAccessibilties={selectAccessibilties}
                      selectFacilities={selectFacilities}
                      selectSecurityFeature={selectSecurityFeature}
                      selectNearbyActivity={selectNearbyActivity}
                      selectSafetyFeature={selectSafetyFeature}
                    />
                  </TabPanel>
                </TabView>
              )}
            </Card>
          </div>
        </div>
        <div className='dashbard-bg gray-bg' />
      </div>
    </>
  );
};
const Loader = () => {
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12}>
        <SkeletonLoader height={400} />
      </Grid>
    </Grid>
  );
};

export default EditPropertyPageRevamp;
