import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/system";
import { TextareaAutosize } from "@mui/material";
import { Typography } from "@mui/material";
import Buttons from "../../../components/form/button";
import { useFormik } from "formik";
import { createOfferApi } from "../../../services/booking/createOffer";
import { useSnackbar } from "notistack";
import FormControl from "@mui/material/FormControl";
import FilledInput from "@mui/material/FilledInput";
import InputLabel from "@mui/material/InputLabel";

function CreateOfferDialog({
  open,
  handleClose,
  scroll,
  descriptionElementRef,
  id,
}) {
  const [amountError, setAmountError] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const formik = useFormik({
    initialValues: {
      bookingExtraPaymentid: id,
      amount: 0,
      description: "",
      userId: userData?.id,
    },
    onSubmit: async (values) => {
      if (values.amount <= 0) {
        setAmountError("Amount cannot be less than or equal to zero.");
      } else {
        const res = await createOfferApi({
          bookingExtraPaymentid: values.bookingExtraPaymentid,
          amount: parseInt(values.amount),
          description: values.description,
          userId: values.userId,
        });
        if (res.status === 201) {
          enqueueSnackbar("Your offer has been sent!", {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
          handleClose();
          window.location.reload();
        } else {
          enqueueSnackbar(`${res.data.response?.error}`, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
          handleClose();
        }
      }
    },
  });
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
      >
        <DialogTitle
          id='scroll-dialog-title'
          sx={{
            fontFamily: "Jost",
            fontSize: "25px",
            borderBottom: "0.5px solid #0095ff",
            background: "#17212f",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontFamily: "Jost", fontSize: "25px" }}>
              Create Offer
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent
          sx={{ height: "90vh", width: "35vw", background: "#1c172b" }}
        >
          <DialogContentText
            id='scroll-dialog-description'
            ref={descriptionElementRef}
            tabIndex={-1}
            sx={{ width: "100%" }}
          >
            <Typography
              sx={{
                color: "#fff",
                fontFamily: "Jost",
                fontSize: "18px",
                marginTop: "20px",
              }}
            >
              Create a counter offer for this request!
            </Typography>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "start",
                flexDirection: "column",
                alignItems: "start",
                textAlign: "start",
              }}
            >
              <FormControl fullWidth sx={{ m: 1 }} variant='filled'>
                <InputLabel htmlFor='amount'>Enter offer Amount.</InputLabel>
                <FilledInput
                  id='amount'
                  name='amount'
                  value={formik.values.amount}
                  onChange={formik.handleChange}
                  type='amount'
                />
              </FormControl>
              {amountError && (
                <div style={{ fontSize: "14px", color: "red" }}>
                  {amountError}
                </div>
              )}
            </Box>
            <Box
              sx={{
                width: "100%",
                padding: "1% 0px",
              }}
            >
              <Typography
                sx={{
                  width: "100%",
                  fontSize: "20px",
                  fontFamily: "Jost",
                  color: "#fff",
                  padding: "15px 0px",
                }}
              >
                Write a message or description for this offer.
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "start",
                  flexDirection: "column",
                  alignItems: "start",
                  textAlign: "start",
                }}
              >
                <TextareaAutosize
                  aria-label='description'
                  id='description'
                  name='description'
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  minRows={8}
                  placeholder='Type here.'
                  style={{
                    width: "100%",
                    background: "none",
                    borderRadius: "8px",
                    padding: "8px",
                    fontFamily: "Jost",
                    fontSize: "14px",
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Buttons
                isLoading={false}
                variant={"outlined"}
                title='Cancel'
                style={{ width: "30%", margin: "10px 0px", marginRight: "4px" }}
                onSubmit={handleClose}
              />
              <Buttons
                isLoading={false}
                variant={"contained"}
                title='Send Offer'
                style={{ width: "30%", margin: "10px 0px" }}
                onSubmit={formik.handleSubmit}
              />
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
export default CreateOfferDialog;
