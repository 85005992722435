import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useRef, useState } from "react";
import Header from "../../../components/header";
import * as yup from "yup";
import addDonationApi from "../../../services/Donations/addDonation";
import { options } from "../../../constants/countries";
import { Dropdown } from "primereact/dropdown";
import "./Donation.css";
import Buttons from "../../../components/form/button";
import { Toast } from "primereact/toast";

const Donation = () => {
  const toast = useRef(null);
  const [loading, setLoading] = useState(false);
  //Country options dropdown templates
  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className='country-item country-item-value'>
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className='country-item'>
        <div>{option.name}</div>
      </div>
    );
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      number: "",
      exp_month: "",
      exp_year: "",
      cvc: "",
      address1: "",
      address2: "",
      zipcode: "",
      city: "",
      state: "",
      fullName: "",
      phone: "",
      whatsapp: "",
      email: "",
      zipCode: "",
      country: "",
      message: "",
      amount: 1000,
      recurring: true,
    },
    validationSchema: () => {
      return yup.object({
        name: yup.string().required("Please enter your name!"),
        number: yup
          .string()
          .min(16)
          .max(16)
          .required("Please enter your card number!"),
        exp_month: yup
          .string()
          .required("Please enter your card expiry month!"),
        exp_year: yup.string().required("Please enter your card expiry year!"),
        cvc: yup.string().min(3).max(4).required("Please enter your card CVC!"),
        address1: yup.string().required("Street Address is required!"),
        address2: yup.string().required("Apt or suite number is required!"),
        city: yup.string().required("City is required!"),
        zipcode: yup.string().required("Zip Code is required!"),
        state: yup.string().required("State is required!"),
        country: yup.object().required("Country is required!"),
        fullName: yup.string().required("Full Name is required!"),
        phone: yup.string().required("Phone is required!"),
        whatsapp: yup.string().required("Whatsapp is required!"),
        email: yup.string().required("Email is required!"),
      });
    },
    onSubmit: async (values) => {
      setLoading(true);
      const data = { ...values, country: values?.country?.name };
      const res = await addDonationApi(data);
      if (res.status === 200 || res.status === 201) {
        setLoading(false);
        toast.current.show({
          severity: "success",
          summary: "Donated!",
          detail: "Your donation is done sucessfully!",
          life: 10000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else {
        setLoading(false);
        toast.current.show({
          severity: "error",
          summary: "An error while making your donation!",
          detail: `${res?.data?.message}`,
          life: 10000,
        });
      }
    },
  });

  return (
    <div className='main'>
      <Toast ref={toast} position='bottom-right' />
      <Header />
      <div className='wrapper main_donate_wrapper'>
        <div className='content sub_donate_wrapper'>
          <div className='form_wrapper'>
            <Typography
              style={{ fontSize: "28px", color: "#fff", fontFamily: "Jost", textAlign: "center" }}
            >
              Earthquake in <span
          style={{
            fontWeight: '600'
          }}
        >TURKEY</span> And <span
        style={{
          fontWeight: '600'
        }}>SYRIA</span> <br/>Families Need Your Help!
            </Typography>
            <Typography
              style={{ fontSize: "32px", color: "#fff", fontFamily: "Jost" }}
            >
              Your Donation
            </Typography>
            <Typography
              style={{ fontSize: "18px", color: "#fff", fontFamily: "Jost" }}
            >
              Staywo.com is a Digital Disaster Management and short-term Vacations Rental Properties platform that now provides Emergency relief for thousands of families and children impacted by the devastating Earthquakes in Turkey and Syria while helping families most in need worldwide. 
Staywo believes that instead of tents, we can reach even more families wherever they need help and provide temporary stays at Hotels, Homes, and Apartments. Staywo will also provide meals and necessities for daily use.  Below We provide a QR code for your Quick, Generous Donations so we can expedite our Operations to add More Properties in the nearest Earthquake Safe locations.
            </Typography>
            <div style={{ margin: "25px 0px" }}>
              {/* price select  */}
              <Grid
                container
                className='radio_opt_container'
                spacing={{ sm: 6, md: 2 }}
              >
                <Grid item md={4} sm={6} xs={6}>
                  <label
                    htmlFor='1000'
                    className={`amount-label ${
                      formik.values.amount == "1000" && "amount-label-active"
                    }`}
                  >
                    $1000
                  </label>
                  <input
                    type='radio'
                    name='amount'
                    id='1000'
                    value='1000'
                    style={{ display: "none" }}
                    onChange={(e) =>
                      formik.setFieldValue("amount", e.target.value)
                    }
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={6}>
                  <label
                    htmlFor='500'
                    className={`amount-label ${
                      formik.values.amount == "500" && "amount-label-active"
                    }`}
                  >
                    $500
                  </label>
                  <input
                    type='radio'
                    name='amount'
                    id='500'
                    value='500'
                    style={{ display: "none" }}
                    onChange={(e) =>
                      formik.setFieldValue("amount", e.target.value)
                    }
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={6}>
                  <label
                    htmlFor='250'
                    className={`amount-label ${
                      formik.values.amount == "250" && "amount-label-active"
                    }`}
                  >
                    $250
                  </label>
                  <input
                    type='radio'
                    name='amount'
                    id='250'
                    value='250'
                    style={{ display: "none" }}
                    onChange={(e) =>
                      formik.setFieldValue("amount", e.target.value)
                    }
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={6}>
                  <label
                    htmlFor='100'
                    className={`amount-label ${
                      formik.values.amount == "100" && "amount-label-active"
                    }`}
                  >
                    $100
                  </label>
                  <input
                    type='radio'
                    name='amount'
                    id='100'
                    value='100'
                    style={{ display: "none" }}
                    onChange={(e) =>
                      formik.setFieldValue("amount", e.target.value)
                    }
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={6}>
                  <label
                    htmlFor='50'
                    className={`amount-label ${
                      formik.values.amount == "50" && "amount-label-active"
                    }`}
                  >
                    $50
                  </label>
                  <input
                    type='radio'
                    name='amount'
                    id='50'
                    value='50'
                    style={{ display: "none" }}
                    onChange={(e) =>
                      formik.setFieldValue("amount", e.target.value)
                    }
                  />
                </Grid>
                <Grid item md={4} padding={0} sm={6} xs={6}>
                  <TextField
                    sx={{ marginTop: "-20px", width: "120px" }}
                    placeholder='  OTHER'
                    InputProps={{
                      startAdornment: "$",
                    }}
                    onChange={(e) =>
                      formik.setFieldValue("amount", e.target.value)
                    }
                  />
                </Grid>
              </Grid>
            </div>
            <FormGroup>
              <FormControlLabel
                sx={{ color: "#fff" }}
                control={
                  <Checkbox
                    checked={formik.values.recurring}
                    id='recurring'
                    onChange={formik.handleChange}
                    name='recurring'
                  />
                }
                label='Recurring'
              />
            </FormGroup>
            <div>
              <Box>
                <InputLabel htmlFor='amount' sx={{ margin: "10px 0px" }}>
                  Enter your name here. (As appear on your card)
                </InputLabel>
                <TextField
                  id='outlined-basic'
                  variant='outlined'
                  fullWidth
                  name='name'
                  placeholder='Name'
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
                {formik.touched.name && Boolean(formik.errors.name) && (
                  <small
                    id='username2-help'
                    className='p-error'
                    style={{
                      display: "block",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.touched.name && formik.errors.name}
                  </small>
                )}
              </Box>
              <Box>
                <InputLabel htmlFor='amount' sx={{ margin: "10px 0px" }}>
                  Enter your card number here.
                </InputLabel>
                <TextField
                  id='outlined-basic'
                  placeholder='Card Number (16 Characters)'
                  variant='outlined'
                  fullWidth
                  name='number'
                  type='number'
                  value={formik.values.number}
                  onChange={formik.handleChange}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 16);
                  }}
                />
                {formik.touched.number && Boolean(formik.errors.number) && (
                  <small
                    id='username2-help'
                    className='p-error'
                    style={{
                      display: "block",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.touched.number && formik.errors.number}
                  </small>
                )}
              </Box>
              <Box>
                <InputLabel htmlFor='amount' sx={{ margin: "10px 0px" }}>
                  Enter your card's expiry month here.
                </InputLabel>
                <TextField
                  id='outlined-basic'
                  placeholder='Expiry Month (eg: 20)'
                  variant='outlined'
                  fullWidth
                  type='number'
                  name='exp_month'
                  value={formik.values.exp_month}
                  onChange={formik.handleChange}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 2);
                  }}
                />
                {formik.touched.exp_month &&
                  Boolean(formik.errors.exp_month) && (
                    <small
                      id='username2-help'
                      className='p-error'
                      style={{
                        display: "block",
                        color: "red",
                        textAlign: "left",
                      }}
                    >
                      {formik.touched.exp_month && formik.errors.exp_month}
                    </small>
                  )}
              </Box>
              <Box>
                <InputLabel htmlFor='amount' sx={{ margin: "10px 0px" }}>
                  Enter your Card's expiry year here.
                </InputLabel>
                <TextField
                  id='outlined-basic'
                  placeholder='Expiry Year (eg: 2025)'
                  variant='outlined'
                  fullWidth
                  type='number'
                  name='exp_year'
                  value={formik.values.exp_year}
                  onChange={formik.handleChange}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 4);
                  }}
                />
                {formik.touched.exp_year && Boolean(formik.errors.exp_year) && (
                  <small
                    id='username2-help'
                    className='p-error'
                    style={{
                      display: "block",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.touched.exp_year && formik.errors.exp_year}
                  </small>
                )}
              </Box>
              <Box>
                <InputLabel htmlFor='amount' sx={{ margin: "10px 0px" }}>
                  Enter your card's CVC here.
                </InputLabel>
                <TextField
                  id='outlined-basic'
                  placeholder='CVC (eg: 1234)'
                  variant='outlined'
                  fullWidth
                  type='number'
                  name='cvc'
                  value={formik.values.cvc}
                  onChange={formik.handleChange}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 4);
                  }}
                />
                {formik.touched.cvc && Boolean(formik.errors.cvc) && (
                  <small
                    id='username2-help'
                    className='p-error'
                    style={{
                      display: "block",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.touched.cvc && formik.errors.cvc}
                  </small>
                )}
              </Box>
              <Typography
                sx={{
                  fontSize: "22px",
                  color: "#fff",
                  margin: "15px 0px",
                }}
              >
                Your Information
              </Typography>
              <Box>
                <InputLabel htmlFor='fullName' sx={{ margin: "10px 0px" }}>
                  Full Name
                </InputLabel>
                <TextField
                  id='outlined-basic'
                  placeholder='Full Name'
                  variant='outlined'
                  fullWidth
                  name='fullName'
                  value={formik.values.fullName}
                  onChange={formik.handleChange}
                />
                {formik.touched.fullName && Boolean(formik.errors.fullName) && (
                  <small
                    id='username2-help'
                    className='p-error'
                    style={{
                      display: "block",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.touched.fullName && formik.errors.fullName}
                  </small>
                )}
              </Box>
              <Box>
                <InputLabel htmlFor='email' sx={{ margin: "10px 0px" }}>
                  Email
                </InputLabel>
                <TextField
                  id='outlined-basic'
                  placeholder='Email'
                  variant='outlined'
                  fullWidth
                  type={"email"}
                  name='email'
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
                {formik.touched.email && Boolean(formik.errors.email) && (
                  <small
                    id='username2-help'
                    className='p-error'
                    style={{
                      display: "block",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.touched.email && formik.errors.email}
                  </small>
                )}
              </Box>
              <Box>
                <InputLabel htmlFor='phone' sx={{ margin: "10px 0px" }}>
                  Phone
                </InputLabel>
                <TextField
                  id='outlined-basic'
                  placeholder='Phone'
                  variant='outlined'
                  fullWidth
                  type={"tel"}
                  name='phone'
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                />
                {formik.touched.phone && Boolean(formik.errors.phone) && (
                  <small
                    id='username2-help'
                    className='p-error'
                    style={{
                      display: "block",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.touched.phone && formik.errors.phone}
                  </small>
                )}
              </Box>
              <Box>
                <InputLabel htmlFor='whatsapp' sx={{ margin: "10px 0px" }}>
                  Whatsapp
                </InputLabel>
                <TextField
                  id='outlined-basic'
                  placeholder='Whatsapp'
                  variant='outlined'
                  fullWidth
                  type={"tel"}
                  name='whatsapp'
                  value={formik.values.whatsapp}
                  onChange={formik.handleChange}
                />
                {formik.touched.whatsapp && Boolean(formik.errors.whatsapp) && (
                  <small
                    id='username2-help'
                    className='p-error'
                    style={{
                      display: "block",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.touched.whatsapp && formik.errors.whatsapp}
                  </small>
                )}
              </Box>
              <Box>
                <InputLabel htmlFor='amount' sx={{ margin: "10px 0px" }}>
                  Enter your Street Address here.
                </InputLabel>
                <TextField
                  id='outlined-basic'
                  placeholder='Street Address'
                  variant='outlined'
                  fullWidth
                  name='address1'
                  value={formik.values.address1}
                  onChange={formik.handleChange}
                />
                {formik.touched.address1 && Boolean(formik.errors.address1) && (
                  <small
                    id='username2-help'
                    className='p-error'
                    style={{
                      display: "block",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.touched.address1 && formik.errors.address1}
                  </small>
                )}
              </Box>
              <Box>
                <InputLabel htmlFor='amount' sx={{ margin: "10px 0px" }}>
                  Enter your Apt or Suite Number here.
                </InputLabel>
                <TextField
                  id='outlined-basic'
                  placeholder='Apt Or Suite Number'
                  variant='outlined'
                  fullWidth
                  name='address2'
                  value={formik.values.address2}
                  onChange={formik.handleChange}
                />
                {formik.touched.address2 && Boolean(formik.errors.address2) && (
                  <small
                    id='username2-help'
                    className='p-error'
                    style={{
                      display: "block",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.touched.address2 && formik.errors.address2}
                  </small>
                )}
              </Box>
              <Box>
                <InputLabel htmlFor='amount' sx={{ margin: "10px 0px" }}>
                  Enter your city here.
                </InputLabel>
                <TextField
                  id='outlined-basic'
                  placeholder='City'
                  variant='outlined'
                  fullWidth
                  name='city'
                  value={formik.values.city}
                  onChange={formik.handleChange}
                />
                {formik.touched.city && Boolean(formik.errors.city) && (
                  <small
                    id='username2-help'
                    className='p-error'
                    style={{
                      display: "block",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.touched.city && formik.errors.city}
                  </small>
                )}
              </Box>
              <Box>
                <InputLabel htmlFor='amount' sx={{ margin: "10px 0px" }}>
                  Enter your state here.
                </InputLabel>
                <TextField
                  id='outlined-basic'
                  placeholder='State'
                  variant='outlined'
                  fullWidth
                  name='state'
                  value={formik.values.state}
                  onChange={formik.handleChange}
                />
                {formik.touched.state && Boolean(formik.errors.state) && (
                  <small
                    id='username2-help'
                    className='p-error'
                    style={{
                      display: "block",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.touched.state && formik.errors.state}
                  </small>
                )}
              </Box>
              <Box>
                <InputLabel htmlFor='amount' sx={{ margin: "10px 0px" }}>
                  Enter your zip code here.
                </InputLabel>
                <TextField
                  id='outlined-basic'
                  placeholder='Zip Code (eg: 12345)'
                  variant='outlined'
                  fullWidth
                  name='zipcode'
                  type='number'
                  value={formik.values.zipcode}
                  onChange={formik.handleChange}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 5);
                  }}
                />
                {formik.touched.zipcode && Boolean(formik.errors.zipcode) && (
                  <small
                    id='username2-help'
                    className='p-error'
                    style={{
                      display: "block",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.touched.zipcode && formik.errors.zipcode}
                  </small>
                )}
              </Box>
              <Box>
                <InputLabel htmlFor='amount' sx={{ margin: "10px 0px" }}>
                  Select your country
                </InputLabel>
                <Dropdown
                  value={formik.values.country}
                  options={options}
                  onChange={formik.handleChange}
                  optionLabel='name'
                  filter
                  showClear
                  filterBy='name'
                  name='country'
                  id='country'
                  placeholder='Country/region'
                  valueTemplate={selectedCountryTemplate}
                  itemTemplate={countryOptionTemplate}
                  style={{
                    width: "100%",
                    background: "#1b182b",
                    borderColor: "#504d5c",
                    padding: "4.5px 0",
                  }}
                />
                {formik.touched.country && Boolean(formik.errors.country) && (
                  <small
                    id='username2-help'
                    className='p-error'
                    style={{
                      display: "block",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.country}
                  </small>
                )}
              </Box>
            </div>
            <Buttons
              isLoading={loading}
              style={{ margin: "10px 0px", width: "40%" }}
              title={`DONATE $${formik.values.amount}`}
              onSubmit={formik.handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Donation;
