import { API_URL } from '../../config';
import axios from 'axios';

export const getAdditionalPaymentApi = async () => {
  const userData = JSON.parse(localStorage.getItem('userData')) || null;
  let data = {
    order: 'ASC',
    page: 1,
    take: 50,
  };
  try {
    const response = await axios.post(
      API_URL + `booking-extra-payment/${userData?.id}`,
      data
    );
    return response.data.data;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('bad Response of additional payment get : ', response);
      return response;
    }
  }
};
