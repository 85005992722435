
import * as yup from "yup";

export const GuestInfoValidationSchema = yup.object({
    // interactionWithGuest: yup.string(),
    // address: yup.string().required("Address is required"),
    // direction: yup.string().required("Direction is required"),
    // guestManual: yup.string().required("Guest manual message is required"),
    checkInInstruction: yup.string().required("Check-in instruction is required"),
    // wifiUsername: yup.string(),
    // wifiPassword: yup.string(),
});