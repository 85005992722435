import { API_URL } from '../../config';
import axios from 'axios';

export const createPreviewApi = async (data) => {
  try {
    const response = await axios.post(
      API_URL + `property-price/create-preview`,data
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;

      console.log(
        'create preview response is status code',
        response.data.statusCode
      );
      console.log(
        'create preview   Error Response : ',
        response.data.message[1]
      );
      throw response;
    }
  }
};
