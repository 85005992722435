import React, { useEffect } from 'react';
import { GoogleLogin } from 'react-google-login';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Divider, TextField, Button } from '@mui/material';
import { Link } from '@mui/material';
import Modal from '@mui/material/Modal';
import { gapi } from 'gapi-script';
import { googleAuthMobileApi } from '../../../services/Authentication/GoogleAuth';
import Logo from '../../../assets/images/logo.png';
import InputPass from '../../../components/form/inputpass';
// import changeUserPassApi from '../../../services/User/changePassword';
import { useFormik } from 'formik';
import { socialLoginApi } from '../../../services/Authentication/SocialLogin';
import changeUserPassApi from '../../../services/User/changePassword';
import createPasswordApi from '../../../services/User/createPassword';
import * as yup from 'yup';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  p: 4,
  textAlign: 'start',
};
const Google = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [data, setData] = React.useState({
    firstName: '',
    lastName: '',
    email: '',
    accessToken: '',
    imageUrl: '',
  });

  const { enqueueSnackbar } = useSnackbar();
  const history = useNavigate();

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId:
          '345233581403-1nhti6o026hbs9d8r0bh06gpk3rjuaif.apps.googleusercontent.com',
        scope: 'email',
      });
    }

    gapi.load('client:auth2', start);
  }, []);
  const responseGoogleSuccess = async (response) => {
    const userData = response?.profileObj;
    const googleAuthRes = await socialLoginApi({
      email: userData?.email,
      firstName: userData?.givenName,
      lastName: userData?.familyName,
      accessToken: response?.accessToken,
      platform: 'google',
    });

    if (googleAuthRes?.data?.payload?.existingUser) {
      localStorage.setItem(
        'userToken',
        googleAuthRes?.data?.payload?.token?.token
      );
      localStorage.setItem('user', true);

      localStorage.setItem(
        'userData',
        JSON.stringify({
          email: googleAuthRes?.data?.payload?.token?.user?.email,
          firstName: googleAuthRes?.data?.payload?.token?.user?.firstName,
          lastName: googleAuthRes?.data?.payload?.token?.user?.lastName,
          id: googleAuthRes?.data?.payload?.token?.user?.id,
          provider: 'google',
          username: googleAuthRes?.data?.payload?.token?.user?.username,
          isHost: googleAuthRes?.data?.payload?.token?.user?.isHost,
          verified: googleAuthRes?.data?.payload?.token?.user?.verified,
          isFacebookVerified:
            googleAuthRes?.data?.payload?.token?.user?.isFacebookVerified,
          isGoogleVerified:
            googleAuthRes?.data?.payload?.token?.user?.isGoogleVerified,
          isDiamondHost:
            googleAuthRes?.data?.payload?.token?.user?.isDiamondHost,
        })
      );
      enqueueSnackbar('Successfully logged in.', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
      history('/');
    } else {
      handleOpen();
      setData({
        email: userData?.email,
        firstName: userData?.givenName,
        lastName: userData?.familyName,
        accessToken: response?.accessToken,
        imageUrl: userData?.imageUrl,
      });
    }
  };
  const formik = useFormik({
    initialValues: {
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      accessToken: data?.accessToken,
      password: '',
      imageUrl: data?.imageUrl,
    },
    enableReinitialize: true,
    validationSchema: () => {
      return yup.object({
        firstName: yup.string().required('Please add your First Name.'),
        lastName: yup.string().required('Please add your Last Name.'),
        password: yup.string().required('Please create a password.'),
      });
    },
    onSubmit: async (values) => {
      const googleAuthRes = await socialLoginApi({
        email: values?.email,
        firstName: values?.firstName,
        lastName: values?.lastName,
        accessToken: values?.accessToken,
        platform: 'google',
      });

      localStorage.setItem(
        'userToken',
        googleAuthRes?.data?.payload?.token?.token
      );
      localStorage.setItem('user', true);

      localStorage.setItem(
        'userData',
        JSON.stringify({
          email: googleAuthRes?.data?.payload?.token?.user?.email,
          firstName: googleAuthRes?.data?.payload?.token?.user?.firstName,
          lastName: googleAuthRes?.data?.payload?.token?.user?.lastName,
          id: googleAuthRes?.data?.payload?.token?.user?.id,
          provider: 'google',
          username: googleAuthRes?.data?.payload?.token?.user?.username,
          isHost: googleAuthRes?.data?.payload?.token?.user?.isHost,
          verified: googleAuthRes?.data?.payload?.token?.user?.verified,
          isFacebookVerified:
            googleAuthRes?.data?.payload?.token?.user?.isFacebookVerified,
          isGoogleVerified:
            googleAuthRes?.data?.payload?.token?.user?.isGoogleVerified,
          isDiamondHost:
            googleAuthRes?.data?.payload?.token?.user?.isDiamondHost,
        })
      );
      const uid = googleAuthRes?.data?.payload?.token?.user?.id;
      await createPasswordApi(
        {
          password: values.password,
          confirmPassword: values.password,
        },
        uid
      );
      enqueueSnackbar('Successfully logged in.', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
      history('/');
    },
  });
  // const handleGoogleLogin = async () => {
  //   localStorage.setItem(
  //     'userToken',
  //     googleAuthRes?.data?.payload?.token?.token
  //   );
  //   localStorage.setItem('user', true);

  //   localStorage.setItem(
  //     'userData',
  //     JSON.stringify({
  //       email: userGoogleData?.email,
  //       firstName: data?.firstName,
  //       lastName: data?.lastName,
  //       profilePicture: image,
  //       id: googleAuthRes?.data?.payload?.userResponse?.id,
  //       provider: 'google',
  //       username: googleAuthRes?.data?.payload?.user?.username,
  //       isHost: googleAuthRes?.data?.payload?.user?.isHost,
  //       verified: userGoogleData?.verified,
  //     })
  //   );
  //   const uid=googleAuthRes?.data?.payload?.userResponse?.id;
  //   const passwordApi = await changeUserPassApi(passwordObj,uid );
  //   console.log(passwordApi);
  //   enqueueSnackbar('Successfully logged in.', {
  //     variant: 'success',
  //     anchorOrigin: {
  //       vertical: 'bottom',
  //       horizontal: 'right',
  //     },
  //   });
  //   history('/');
  // };
  const responseGoogleError = (response) => {
    enqueueSnackbar('Error logging in.', {
      variant: 'error',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
    });
  };

  return (
    <>
      <GoogleLogin
        clientId='345233581403-1nhti6o026hbs9d8r0bh06gpk3rjuaif.apps.googleusercontent.com'
        buttonText='Login'
        onSuccess={responseGoogleSuccess}
        onFailure={responseGoogleError}
        cookiePolicy={'single_host_origin'}
        // isSignedIn={true}
        prompt='select_account'
        render={(renderProps) => (
          <>
            <div className='social-media-login'>
              <button
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
              >
                <i
                  style={{ fontSize: 22 }}
                  className='fa fa-brand fa-google'
                ></i>
              </button>
            </div>
          </>
        )}
      />
      <div>
        <Button onClick={handleOpen}></Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={style}>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                '& > :not(style)': {
                  m: 1,
                  width: 600,
                  height: 600,
                },
              }}
            >
              <Paper
                elevation={3}
                style={{
                  maxHeight: 900,
                  overflow: 'auto',
                  padding: 20,
                  background: '#1B182B',
                }}
              >
                <img
                  src={Logo}
                  alt='Logo'
                  srcset=''
                  width={100}
                  height={50}
                  style={{
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginBottom: '5px',
                  }}
                />
                <h5 style={{ textAlign: 'center' }}>Finish signing up</h5>
                <Divider sx={{ marginBottom: 3 }} />
                <Box
                  sx={{
                    width: 600,
                    maxWidth: '100%',
                  }}
                >
                  <TextField
                    fullWidth
                    placeholder='Name'
                    id='fullWidth'
                    name='firstName'
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                  />
                </Box>
                <Box
                  sx={{
                    width: 600,
                    maxWidth: '100%',
                  }}
                >
                  <TextField
                    fullWidth
                    placeholder='lastname'
                    name='lastName'
                    id='fullWidth'
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                  />
                  <span>
                    Make sure it matches the name on your government ID.
                  </span>
                </Box>
                <Box
                  sx={{
                    width: 600,
                    maxWidth: '100%',
                    marginTop: 3,
                  }}
                >
                  <TextField
                    fullWidth
                    placeholder='Email'
                    id='fullWidth'
                    name='email'
                    value={formik.values.email}
                    disabled
                  />
                  <span>We'll email you trip confirmations and receipts.</span>
                </Box>
                <p style={{ textAlign: 'center', marginTop: 10 }}>
                  This info came from Google.
                </p>
                <Box
                  sx={{
                    width: 600,
                    maxWidth: '100%',
                    marginTop: 3,
                  }}
                >
                  <InputPass
                    sx={{ mb: 3, fontSize: '14px', position: 'relative' }}
                    id={'password'}
                    name={'password'}
                    label={'Password'}
                    onChange={formik.handleChange}
                  />
                  <span>Add Your Password</span>
                </Box>
                <p style={{ marginTop: 20, color: '#fff' }}>
                  By selecting Agree and continue, I agree to Staywo{' '}
                  <Link>Terms of Service</Link>,{' '}
                  <Link>Payments Terms of Service,</Link> and Nondiscrimination
                  Policy and acknowledge the Privacy Policy.
                </p>
                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    variant='contained'
                    onClick={formik.handleSubmit}
                    fullWidth
                  >
                    Agree And Countinue
                  </Button>
                </Box>
              </Paper>
            </Box>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default Google;
