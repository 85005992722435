import { API_URL } from "../../config";
import axios from "axios";

export const rejectOfferApi = async (offerId, userId) => {
  try {
    const response = await axios.post(
      API_URL + "booking-extra-payment/rejectoffer",
      { offerid: offerId, userid: userId }
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log("Bad Response of reject  : ", response);
      return response;
    }
  }
};
