import { API_URL } from '../../config';
import axios from 'axios';

export const InviteGuestApi = async (data) => {
  try {
    const response = await axios.post(
      API_URL + 'booking-invitation/create',
      data
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('bad Response of invite guest API: ', response);
      return response;
    }
  }
};
