import { API_URL } from '../../config';
import axios from 'axios';

export const getBookingInvitationsApi = async (id, userType) => {
  try {
    const response = await axios.get(
      API_URL +
        `booking-invitation/get-invitations?userId=${id}&UserType=${userType}`
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('bad Response of get booking invitations: ', response);
      return response;
    }
  }
};
