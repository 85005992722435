import { API_URL } from '../../config';
import { axiosPrivate } from '../../api/axios';

const getUserAddressApi = async (user_id) => {
  try {
    const response = await axiosPrivate.get(
      API_URL + `user-address/${user_id}`
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('bad response of get user address: ', response);
      return response;
    }
  }
};
export default getUserAddressApi;
