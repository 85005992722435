import * as React from 'react';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, Divider } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import { GuestCountInviteGuest } from '../shared/GuestCountInviteGuest';
import { Link } from '@mui/material';
import DisclimerModal from '../DisclimerModal/DisclimerModal';
import './pricingModal.css';
import { useState } from 'react';
import { Formik, useFormik } from 'formik';
import { createPreviewApi } from '../../services/CreatePreview/CreatePreview';
import DatePickerHome from '../form/datePickerHome';
import DatePickerPricingModal from '../form/datePickerPricingPreviewModal';
import moment from 'moment';
import { GuestCount } from '../shared/GuestCount';
import { Toast } from 'primereact/toast';
import NightlightRoundIcon from '@mui/icons-material/NightlightRound';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  color: 'text.primary',
  backgroundColor: '#1b182b',
  boxShadow: 24,
  p: 4,
  textAlign: 'start',
};

export default function PricingModal({ propertyId, propertyDetail }) {
  const [open, setOpen] = React.useState(false);
  const [night, setNight] = React.useState(0);
  const [price, setTotalPrice] = useState({});

  const basicInfo = useFormik({
    initialValues: {
      noOfGuest: 0,
      noOfAdults: 0,
      noOfChildren: 0,
      noOfPets: 0,
      nights: 0,
      unit: 'NIGHT',
      startDate: '',
      endDate: '',
    },
    validationSchema: () => {
      return yup.object({
        noOfGuest: yup
          .number()
          .min(1)
          .required('Please select number of guests!'),
      });
    },
    onSubmit: async (values) => {
      var noOfNights = moment(values.endDate).diff(values.startDate, 'days');
      let data = {
        propertyId: parseInt(propertyId),
        ...(values?.startDate && values?.endDate
          ? { nights: noOfNights }
          : { nights: values.nights }),
        pets: values.noOfPets,
        guests: values.noOfGuest,
        unit: values.unit,
        ...(values?.startDate && {
          startDate: moment(values.startDate).format('YYYY-MM-DD'),
        }),
        ...(values?.endDate && {
          endDate: moment(values.endDate).format('YYYY-MM-DD'),
        }),
      };
      const res = await createPreviewApi(data);
      setTotalPrice(res.data);
    },
  });

  const handleGuestCountChange = async (value, count) => {
    basicInfo.setFieldValue('noOfGuest', value);
    basicInfo.setFieldValue('noOfAdults', count.adults);
    basicInfo.setFieldValue('noOfChildren', count.children);
    basicInfo.setFieldValue('noOfPets', count.pets);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const smartPricing = {
    title: 'Price tips',
    detail:
      'Price tips are automated nightly price recommendations that you can choose to save. They are based on the type and location of your listing, the season, demand, and other factors. Even if you use price tips, you always control your price and can override the tips at any time.',
  };

  const toast = React.useRef(null);
  return (
    <div>
      <Toast ref={toast} position='bottom-right' />
      <Link onClick={handleOpen} style={{ cursor: 'pointer' }}>
        Show
      </Link>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        style={{ zIndex: 200 }}
      >
        <Box sx={style}>
          <Button onClick={handleClose} sx={{ display: 'block' }}>
            {' '}
            <CloseIcon />{' '}
          </Button>

          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Preview what guests pay
          </Typography>
          <p>
            Select any combination of nights, guests, and pets, and we’ll show
            you the final price.
          </p>

          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ m: 2 }}
          >
            {/* No Of nights */}

            <Grid item xs={6}>
              <Box sx={{ minWidth: 320 }}>
                <FormControl fullWidth>
                  <InputLabel id='demo-simple-select-label'>Night</InputLabel>

                  <Select
                    style={{ zIndex: 20 }}
                    labelId='demo-simple-select-label'
                    label='Night'
                    name='nights'
                    id='nights'
                    onChange={basicInfo.handleChange}
                    value={basicInfo.values.nights}
                  >
                    <MenuItem className='MenuItem' value={1}>
                      1 night
                    </MenuItem>
                    <MenuItem className='MenuItem' value={2}>
                      2 night
                    </MenuItem>
                    <MenuItem className='MenuItem' value={3}>
                      3 night
                    </MenuItem>
                    <MenuItem className='MenuItem' value={7}>
                      1 Week
                    </MenuItem>
                    <MenuItem className='MenuItem' value={30}>
                      1 Month
                    </MenuItem>
                    <MenuItem className='MenuItem'>
                      <DatePickerPricingModal
                        handlers
                        handleDateStart={(value) => {
                          basicInfo.setFieldValue('startDate', value);
                          basicInfo.setFieldValue('nights', 0);
                        }}
                        handleDateEnd={(value) =>
                          basicInfo.setFieldValue('endDate', value)
                        }
                        basePrice={propertyDetail.perNightPrice}
                        toast={toast}
                        propertyPolicy={propertyDetail?.propertyPolicy}
                        propertyPricePolicy={
                          propertyDetail?.propertyPricePolicy
                        }
                        id={propertyDetail?.id}
                      />
                    </MenuItem>
                  </Select>
                  {basicInfo.values.startDate && basicInfo.values.endDate && (
                    <div style={{ fontSize: '18px', margin: '10px 0px' }}>
                      {moment(basicInfo.values.startDate).format(
                        'DD MMM, YYYY'
                      )}{' '}
                      -{' '}
                      {moment(basicInfo.values.endDate).format('DD MMM, YYYY')}(
                      <NightlightRoundIcon
                        sx={{ fontSize: '15px', marginLeft: '5px' }}
                      />
                      {moment(basicInfo.values.endDate).diff(
                        basicInfo.values.startDate,
                        'days'
                      )}{' '}
                      Nights)
                    </div>
                  )}
                </FormControl>
              </Box>
            </Grid>

            {/* Guest Component  */}

            <Grid item xs={6}>
              <GuestCount
                handleChange={handleGuestCountChange}
                handlers
                propertyDetail={propertyDetail}
              />
            </Grid>
          </Grid>
          {Object.keys(price).length > 0 && (
            <>
              {/* price Of nights Total */}

              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{ m: 2 }}
              >
                <Grid item xs={10}>
                  <span style={{ fontSize: '20px', margin: '8px 0px' }}>
                    Discount Type
                  </span>
                  <br />
                </Grid>
                <Grid item xs={2}>
                  <span>{price.discountType}</span>
                </Grid>
              </Grid>

              {/* Pets Section */}
              <Grid item xs={12}>
                <span
                  style={{
                    fontSize: '22px',
                    margin: '8px 0px',
                    textDecoration: 'underline',
                  }}
                >
                  Your Best Price
                </span>
                <br />
              </Grid>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{ m: 2 }}
              >
                <Grid item xs={10} sx={{ fontSize: '20px', margin: '8px 0px' }}>
                  Pet Fee
                </Grid>
                <Grid item xs={2} sx={{ fontSize: '18px' }}>
                  {process.env.REACT_APP_CURRENCY}
                  {price.petfee}
                </Grid>
              </Grid>

              {/* Service Fees */}

              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{ m: 2 }}
              >
                <Grid item xs={10}>
                  <span style={{ fontSize: '20px', margin: '8px 0px' }}>
                    Guest service fee{' '}
                  </span>
                </Grid>
                <Grid item xs={2}>
                  <span style={{ fontSize: '18px' }}>
                    {process.env.REACT_APP_CURRENCY}
                    {price.serviceFee}
                  </span>
                </Grid>
              </Grid>
              <hr />

              {/* Total Earning */}

              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{ m: 2 }}
              >
                <Grid item xs={10}>
                  <span>
                    <b style={{ fontSize: '20px', margin: '8px 0px' }}>Total</b>
                  </span>
                </Grid>
                <Grid item xs={2}>
                  <span style={{ fontSize: '18px' }}>
                    {process.env.REACT_APP_CURRENCY}
                    {price.total}
                  </span>
                </Grid>
              </Grid>

              {/* Your Earning */}
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{ m: 2 }}
              >
                <Grid item xs={10}>
                  <span style={{ fontSize: '20px', margin: '8px 0px' }}>
                    Your earnings
                  </span>
                </Grid>
                <Grid item xs={2}>
                  <span style={{ fontSize: '18px' }}>
                    {' '}
                    {process.env.REACT_APP_CURRENCY}
                    {price.hostEarning}
                  </span>
                </Grid>
              </Grid>
              <Divider />
            </>
          )}
          <Link>
            <DisclimerModal style={{ marginTop: 30 }} data={smartPricing} />
          </Link>
          <div
            style={{
              display: 'flex',

              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <Typography
              onClick={() => setOpen(false)}
              style={{
                textAlign: 'end',
                cursor: 'pointer',
                marginRight: 30,
              }}
            >
              Close
            </Typography>
            <Button onClick={basicInfo.handleSubmit} variant='contained'>
              Calclate
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
