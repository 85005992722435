import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import { Toast } from 'primereact/toast';
import ReservationDetail from '../../screens/Website/ReservationDetails/ReservationDetail';
import { Rating, Typography } from '@mui/material';
import moment from 'moment';

const SingleWithoutTimerForCurrentTrips = ({ booking, commissionFee }) => {
  const toast = useRef(null);
  const { title, thumbnailUrl, address, status, id } = booking?.property;
  const [hovered, setHovered] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const [guestReview, setGuestReview] = React.useState([]);
  let total = 0;
  let totalLength = guestReview?.items?.length;
  for (let i = 0; i < guestReview?.items?.length; i++) {
    total += guestReview?.items[i].over_all;
  }
  let totalRating = total / totalLength;

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  return (
    <div className='dashboard-listings-item fl-wrap'>
      <Toast ref={toast} position='bottom-right' />
      <div className='dashboard-listings-item_img'>
        <div className='bg-wrap1'>
          <img className='bg' src={thumbnailUrl} />
        </div>
      </div>
      <ReservationDetail
        open={open}
        handleClose={handleClose}
        booking={booking}
        scroll={scroll}
        descriptionElementRef={descriptionElementRef}
        type='traveler'
        commissionFee={commissionFee}
      />
      <div className='dashboard-listings-item_content'>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <h4>
              <Tooltip title={title}>
                <Link to={`/property-detail/${booking?.propertyId}`}>
                  {title.length > 40 ? (
                    <a
                      onMouseOver={(e) =>
                        (e.target.style.textOverflow = 'ellipsis')
                      }
                    >
                      {title.substring(0, 40)}...
                    </a>
                  ) : (
                    <a
                      onMouseOver={(e) =>
                        (e.target.style.textOverflow = 'ellipsis')
                      }
                    >
                      {title}
                    </a>
                  )}
                </Link>
              </Tooltip>
            </h4>
            <div
              className='geodir-category-location'
              style={{ marginBottom: '2%' }}
            >
              <a style={{ textDecoration: 'none' }}>
                <i className='fas fa-map-marker-alt'></i>{' '}
                <span
                  style={{
                    fontSize: '12px',
                    color: '#bbbbbb',
                  }}
                >
                  {booking?.property.address}
                  <div></div>
                </span>
              </a>
            </div>
            <div style={{ textAlign: 'start' }}>
              <div>
                <a
                  style={{
                    textAlign: 'left',
                    color: 'green',
                    fontSize: '16px',
                  }}
                >
                  {booking?.bookingStatus == 'confirmed'
                    ? 'Booking confirmed'
                    : ''}
                </a>
              </div>
              <div>
                <a style={{ textAlign: 'start', color: 'white' }}>
                  Property ID # {booking?.property.code}
                </a>
              </div>

              <div>
                <a style={{ textAlign: 'start', color: 'white' }}>
                  Booking ID # {booking?.code}
                </a>
              </div>
              <div>
                <Typography
                  sx={{
                    fontFamily: 'Jost',
                    fontSize: '16px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Rating
                    name='half-rating-read'
                    value={
                      booking?.property?.average_rating
                        ? booking?.property?.average_rating?.averageRating
                        : 0
                    }
                    precision={0.5}
                    readOnly
                    sx={{ marginRight: '4px' }}
                  />
                  (
                  {booking?.property?.average_rating
                    ? parseInt(
                        booking?.property?.average_rating?.averageRating
                      ).toFixed(1)
                    : 0}
                  )
                  {booking?.property?.average_rating
                    ? ` (${booking?.property?.average_rating?.reviewCount} Reviews)`
                    : ' (0 Reviews)'}
                </Typography>
              </div>

              <div>
                <a style={{ textAlign: 'start', color: 'white' }}>
                  {booking?.startDate && booking?.endDate && (
                    <>
                      From {moment(booking?.startDate).format('DD MMM YYYY')} to{' '}
                      {moment(booking?.endDate).format('DD MMM YYYY')}
                    </>
                  )}
                </a>
              </div>
              <div>
                <a
                  style={{
                    textAlign: 'start',
                    color: 'white',
                    fontSize: '18px',
                  }}
                >
                  {process.env.REACT_APP_CURRENCY}{' '}
                  {booking?.grossAmount
                    ? booking?.grossAmount.toLocaleString('en-US')
                    : 0}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className='dashboard-listings-item_opt'
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <span className='viewed-counter' style={{ fontSize: 13 }}>
            {/* <i className='fas fa-eye' style={{ paddingRight: 5 }}></i> Viewed -{' '} */}
          </span>
          <ul>
            <li>
              <Link
                state={{ booking: booking }}
                style={{ padding: '0px 10px', width: '100px' }}
                to={`/modify-booking/${booking?.id}`}
              >
                Modify Booking
              </Link>
            </li>
            <li>
              <div
                state={{ booking: booking }}
                style={
                  !hovered
                    ? {
                        padding: '16px 10px',
                        color: '#0095ff',
                        cursor: 'pointer',
                      }
                    : {
                        padding: '16px 10px',
                        color: '#0095ff',
                        cursor: 'pointer',
                        background: '#292e3a',
                      }
                }
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                onClick={handleClickOpen('body')}
              >
                View
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default SingleWithoutTimerForCurrentTrips;
