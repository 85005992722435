import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
// import { validationSchema } from "./LoginPageValidationSchema";
// import { postLoginApi } from "../../../services/properties/Authentication/Login";
import LOGO from "../../../assets/images/logo.png";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import Buttons from "../../../components/form/button";
import BACKGROUND from "../../../assets/images/otp.png";
import { otpVerificationApi } from "../../../services/Authentication/OtpVerification";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © All Rights Reserved "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const OtpPage = () => {
  const history = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("")


  
  // ** Reference for each input field
  const inputRef1 = useRef()
  const inputRef2 = useRef()
  const inputRef3 = useRef()
  const inputRef4 = useRef()
  const inputRef5 = useRef()
  const inputRef6 = useRef()

  // ** State for store input values
  const [otpCode, setOtpCode] = useState({
    one: undefined,
    two: undefined,
    three: undefined,
    four: undefined,
    five: undefined,
    six: undefined
  })

  // ** Set Last Character For An Input and focus on next input
  const handleInput = (e) => {
    const value = e.target.value
    setOtpCode({...otpCode, [e.target.name]:value.slice(-1)})
    if(e.target.name === "one"){
      inputRef2.current.focus()
    }else if(e.target.name === "two"){
      inputRef3.current.focus()
    }else if(e.target.name === "three"){
      inputRef4.current.focus()
    }else if(e.target.name === "four"){
      inputRef5.current.focus()
    }else if(e.target.name === "five"){
      inputRef6.current.focus()
    }
  }
    const handleSubmit = async () => {
        setErrorMessage("")
        try {
          setIsLoading(true);
          const response = await otpVerificationApi({
            code: `${otpCode.one}${otpCode.two}${otpCode.three}${otpCode.four}${otpCode.five}${otpCode.six}`
          });
          if (response?.status === 201) {
            localStorage.setItem("userToken", response?.data?.token);
            localStorage.setItem("user", true);
            setIsLoading(false);
            history("/admin/property-listing");
          }else{
            setErrorMessage("Please enter the valid otp code")
          }
        } catch (err) {
          setIsLoading(false);
          setErrorMessage("Somthing went wrong")
       }
    }

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={8}
        sx={{
          backgroundImage: `url(${BACKGROUND})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid style={{background: '#1b182b'}} item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4
          }}
        >
          <Box sx={{
            my: 2,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left'
          }}>
            <Grid item xs={12} justifyContent='center' display='flex' flexDirection={"column"} alignItems={"center"}>
              <a href='/' className='logo-holder'>
                <img
                  src={`${LOGO}`}
                  width='242px'
                  height='100px'
                  marginBottom='20px'
                  alt=''
                />
              </a>
            </Grid>
            <Typography textAlign='center' component='h1' variant='h5'>
              <h3>OTP Verification</h3>
            </Typography>
          </Box>
          <Box component="form" noValidate sx={{ mt: 5 }}>
          <div className='inputRow'>
            <input ref={inputRef1} onChange={(e) => handleInput(e)} name="one" value={otpCode?.one} type={"number"} placeholder="0"/>
            <input ref={inputRef2} onChange={(e) => handleInput(e)} name="two" value={otpCode?.two} type={"number"} placeholder="0"/>
            <input ref={inputRef3} onChange={(e) => handleInput(e)} name="three" value={otpCode?.three} type={"number"} placeholder="0"/>
            <input ref={inputRef4} onChange={(e) => handleInput(e)} name="four" value={otpCode?.four} type={"number"} placeholder="0"/>
            <input ref={inputRef5} onChange={(e) => handleInput(e)} name="five" value={otpCode?.five} type={"number"} placeholder="0"/>
            <input ref={inputRef6} onChange={(e) => handleInput(e)} name="six" value={otpCode?.six} type={"number"} placeholder="0"/>
          </div>
            <Grid container justifyContent={"left"} alignItems={"center"}>
              <Link underline="none">
                {"Didn't Recieve Code?"} Resend Code.
              </Link>
            </Grid>
            <Buttons
              title="Verify Otp"
              loadingTitle={"Verifying"}
              isLoading={isLoading}
              style={{ height: 55, mt: 3 }}
              onSubmit={handleSubmit}
            />
            {
              errorMessage && <Typography sx={{padding: "0px", marginTop: "15px", fontSize: "16px", color: "red", fontWeight: "300"}}>
              {errorMessage}
            </Typography>
            }
            <Grid container justifyContent={"center"} alignItems={"center"}>
              {/* <Grid item>
                <Link href="/signup" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid> */}
            </Grid>
            <Copyright sx={{ mt: 5 }} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default OtpPage;