import React from 'react'
import { styled } from '@mui/material/styles'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import MuiAccordion from '@mui/material/Accordion'
import Card from '../../../../components/dasboard/card'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import CheckBoxLabel from '../../../../components/form/checkbox'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import { Link } from '@mui/material'

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid #323041`,
  backgroundColor: '#1b182b',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: '#1b182b',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
    backgroundColor: '#1b182b',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  '& .css-v84d5j-MuiSvgIcon-root': {
    fontSize: '1rem',
    color: '#2e94f7',
  },
  '& .css-ahj2mt-MuiTypography-root': {
    marginTop: 13,
    color: 'white',
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}))

const Amenities = ({ amenitiesValue, handleCheckbox }) => {
  const [expanded, setExpanded] = React.useState(amenitiesValue[0]?.id)

  const handleChange = (panel) => (newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <Card title={'Amenities'} icon={'fa fa-home'}>
      <Grid
        container
        rowSpacing={{ xs: 1, sm: 1, md: 3 }}
        columnSpacing={{ xs: 1, sm: 1, md: 2 }}
      >
        <Grid item xs={12}>
          {amenitiesValue?.map((amenity, i) => {
            return (
              <Accordion
                expanded={expanded === amenity.id}
                onChange={handleChange(amenity.id)}
              >
                <AccordionSummary
                  aria-controls='panel1d-content'
                  id='panel1d-header'
                >
                  <Typography>
                    {amenity.title}
                  </Typography>
                </AccordionSummary>

                <Typography style={{marginLeft:40,fontSize:10}}>


                  {amenity.description ? amenity.description : "These are amenities that guests typically look for in a stay."}

                </Typography>


                <AccordionDetails>

                  <Grid
                    container
                    rowSpacing={{ xs: 1, sm: 1, md: 3 }}
                    columnSpacing={{ xs: 1, sm: 1, md: 2 }}
                    style={{ textAlign: 'left' }}
                  >
                    {amenity?.amenities?.map((amentityCheckbox, j) => {
                      return (
                        <Grid item xs={4}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            "& .MuiBox-root": {
                              marginTop: '0 !important',
                            }
                          }}
                        >
                          <CheckBoxLabel
                            value={amentityCheckbox.title}
                            checked={amentityCheckbox.isChecked}
                            onChange={(e) => handleCheckbox(i, j, e)}
                          />
                          <div style={{ alignItems: "center", display: "flex" }}>
                            <img
                              class='amenity-icons'
                              src={amentityCheckbox?.icon}
                              style={{
                                width: '30px',
                                height: '30px',
                              }}
                            />
                            <span
                              style={{
                                color: 'white',
                                fontSize: '15px',
                                marginLeft: '10px',
                              }}
                            >
                              {amentityCheckbox?.title}
                            </span>
                          </div>
                        </Grid>
                      )
                    })}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            )
          })}
        </Grid>
      </Grid>
    </Card>
  )
}

export default Amenities
