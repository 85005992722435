import { axiosPrivate } from '../../api/axios';
import { API_URL } from '../../config';

const updateUserEmergencyContactApi = async (id, data) => {
  try {
    let res = await axiosPrivate.put(
      API_URL + `user-emergency-contact/update/${id}`,
      data
    );
    return res;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('bad response of user-emergency-contact: ', response);
      return response;
    }
  }
};
export default updateUserEmergencyContactApi;
