import { API_URL } from '../../config';
import { axiosPrivate } from '../../api/axios';

const getUserCardApi = async (user_id) => {
  try {
    const response = await axiosPrivate.get(
      API_URL + `payment/card-all/stripe/${user_id}`
    );
    return response.data;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('bad response of get user card: ', response);
      return response;
    }
  }
};
export default getUserCardApi;
