import { API_URL } from '../../config';
import axios from 'axios';

export const socialLoginApi = async (data) => {
  try {
    const response = await axios.post(API_URL + `auth/social-login`, data);
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      throw response;
    }
  }
};
