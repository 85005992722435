import React, { useState } from "react";

const ContactForm = () => {

    const [inputValue, setInputValue] = useState({
        fName: "",
        lName: "",
        email: "",
        phone: "",
        subject: "",
        message: ""
    })

    const handleChange = (e) => {
        setInputValue({...inputValue, [e.target.name]:e.target.value})
    }

    const handleSubmit = (e) => {
        e.preventDefault()
    }

    return(
        <div className="form-container">
            <h2>Contact Us</h2>
            <p>Let's get conversation started. Tell us a bit about yourself, and we will get touch as soon as we can.</p>
            <form className="form" onSubmit={(e) => handleSubmit(e)}>
                <div className="name-input">
                    <input onChange={(e) => handleChange(e)} name="fName" value={inputValue?.fName} type={"text"} placeholder="First Name"/>
                    <input onChange={(e) => handleChange(e)} name="lName" value={inputValue?.lName} type={"text"} placeholder="Last Name"/>
                </div>
                <input onChange={(e) => handleChange(e)} name="email" value={inputValue?.email} type={"email"} placeholder="Work Email"/>
                <input onChange={(e) => handleChange(e)} name="phone" value={inputValue?.phone} type={"tel"} placeholder="Phone"/>
                <input onChange={(e) => handleChange(e)} name="subject" value={inputValue?.subject} type={"text"} placeholder="Subject"/>
                <textarea onChange={(e) => handleChange(e)} name="message" value={inputValue?.message} placeholder="Message"/>
                <button>Sent Message</button>
            </form>
        </div>
    )
}

export default ContactForm