import { Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';

import Card from '../../../components/dasboard/card';
import InfoBar from '../../../components/dasboard/infoBar';
import Sidebar from '../../../components/dasboard/sidebar';
import Header from '../../../components/header';
import PropertyCard from '../../../components/mainComponents/propertyCard';
import PropertyCard2 from '../../../components/mainComponents/propertyCard2';
import { addToWishlistApi } from '../../../services/properties/add&RemoveWishlist';
import { getWishlistPropertyApi } from '../../../services/properties/getWishlistProperty';

const WishlistProperty = () => {
  const [properties, setProperties] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const getWishlistProperties = async () => {
    const response = await getWishlistPropertyApi();
    if (response.status === 200) {
      setProperties(response?.data?.data);
    }
  };

  useEffect(() => {
    getWishlistProperties();
  }, []);

  const addToWishlist = async (id) => {
    const response = await addToWishlistApi(id);
    if (response.status === 200) {
      enqueueSnackbar('Property added to wishlist', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
  };

  console.log({ properties });
  return (
    <>
      <Header />
      <div id='wrapper'>
        <div className='dashboard-menu-overlay' />
        <Sidebar page={'Calendar'} />
        <div className='dashboard-content'>
          <div className='dashboard-menu-btn color-bg'>Dasboard Menu</div>
          <InfoBar label={`Wishlist Properties`} />
          <div className='dasboard-wrapper fl-wrap no-pag'>
            <Card withIcon={true} icon={'fa fa-home'} title={'My Wishlist'}>
              <Grid
                container
                rowSpacing={{ xs: 1, sm: 1, md: 1 }}
                columnSpacing={{ xs: 1, sm: 1, md: 2 }}
              >
                {properties?.map((property) => (
                  <Grid item xs={4}>
                    <PropertyCard2
                      property={property?.property}
                      addToWishlist={addToWishlist}
                      setSelectedPlace={setSelectedPlace}
                    />
                  </Grid>
                ))}
              </Grid>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default WishlistProperty;
