import React, { useState, useEffect } from 'react';
import InfoBar from '../../../components/dasboard/infoBar';
import Sidebar from '../../../components/dasboard/sidebar';
import Header from '../../../components/header';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Typography,
  TextField,
  Grid,
  Rating,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import Buttons from '../../../components/form/button';
import { GuestCountInviteGuest } from '../../../components/shared/GuestCountInviteGuest';
import { getPropertyByIdApi } from '../../../services/properties/getProperty';
import { useFormik } from 'formik';
import DatePickerInviteGuest from '../../../components/form/datePickerInviteGuest';
import moment from 'moment';
import { InviteGuestApi } from '../../../services/InviteGuest/InviteGuest';
import { Toast } from 'primereact/toast';
import { getPropertyPricePolicyApi } from '../../../services/properties/getPropertyPricePolicy';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PetsIcon from '@mui/icons-material/Pets';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import PaymentsIcon from '@mui/icons-material/Payments';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import NightlightRoundIcon from '@mui/icons-material/NightlightRound';
import { confirmDialog } from 'primereact/confirmdialog';
import { getPropertyTotalAmountByDateRange } from '../../../services/properties/propertyCalenderPrice';
import { calculateBookingPriceApi } from '../../../services/booking/calculateBookingPrice';
import Select from 'react-select';
import { InviteGuestValidationSchema } from './InviteGuestValidationSchema';
import { getCommissionFee } from '../../../services/commissionFee/CommissionFee';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import PaymentIcon from '@mui/icons-material/Payment';
import { options } from '../../../constants/countries';
import { Dropdown } from 'primereact/dropdown';

const InviteGuestPage = ({ gst, serviceFee }) => {
  const userData = JSON.parse(localStorage.getItem('userData')) || null;
  const [guestReview, setGuestReview] = useState([]);
  const [status, setStatus] = useState({
    label: 'Select Status',
    value: '',
  });
  const [additionalGuests, setAdditionalGuests] = useState(0);
  const [totalAmountByApi, setTotalAmountByApi] = useState(0);
  const [propertyDetail, setPropertyDetail] = useState({});
  const [commissionFee, setCommissionFee] = useState(0);
  const { id } = useParams();
  const [additionalGuestFee, setAdditionalGuestFee] = useState(0);
  const [propertyPricePolicy, setPropertyPricePolicy] = useState(false);
  React.useEffect(() => {
    const getPropertyPricePolicy = async () => {
      const prp = await getPropertyPricePolicyApi(id);
      setPropertyPricePolicy(prp);
    };
    getPropertyPricePolicy();
  }, [id]);

  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState({});
  const now = moment().format('h:mm:ss');
  const [availableToday, setAvailableToday] = useState(true);
  React.useEffect(() => {
    if (now < '11:0:0') {
      setAvailableToday(true);
    } else {
      setAvailableToday(false);
    }
  }, [now]);
  const navigate = useNavigate();
  const toast = React.useRef(null);

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    getCommissionFee()
      .then((res) => {
        setCommissionFee(res.data.data[0].commission_percentage);
      })
      .catch((err) => {});
  }, []);
  const basicInfo = useFormik({
    initialValues: {
      startDate: '',
      endDate: '',
      ownerId: userData?.id,
      noOfGuest: 0,
      noOfAdults: 0,
      noOfChildren: 0,
      noOfInfants: 0,
      noOfPets: 0,
      guestId: userData?.id,
      propertyId: id,
      bookingType: 'TENTATIVE',
      firstName: '',
      lastName: '',
      email: '',
      status: status,
      address1: '',
      address2: '',
      city: '',
      country: '',
      note: '',
    },

    validationSchema: () => {
      return InviteGuestValidationSchema;
    },
    onSubmit: async (values) => {
      const data = {
        startDate: moment(values.startDate).format('YYYY-MM-DD'),
        endDate: moment(values.endDate).format('YYYY-MM-DD'),
        ownerId: values.ownerId,
        noOfGuest: values.noOfGuest,
        noOfAdults: values.noOfAdults,
        noOfChildren: values.noOfChildren,
        noOfInfants: values.noOfInfants,
        noOfPets: values.noOfPets,
        guestId: values.guestId,
        propertyId: values.propertyId,
        bookingType: values.bookingType,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        status: values.status,
        address1: values.address1,
        address2: values.address2,
        city: values.city,
        country: values.country?.name,
        note: values.note,
      };
      setIsLoading(true);
      const inviteGuest = await InviteGuestApi(data);
      if (inviteGuest.status === 201) {
        setIsLoading(false);
        toast.current.show({
          severity: 'success',
          summary: 'Invitation sent to guest successfully!',
          detail: `${inviteGuest?.data?.message}`,
          life: 10000,
        });
        setTimeout(() => {
          navigate(`/admin/calendar/${propertyDetail?.id}`);
        }, 1500);
      } else {
        setIsLoading(false);
        toast.current.show({
          severity: 'error',
          summary: 'Couldnt Invitation sent to guest!',
          detail: `${inviteGuest?.data?.error}`,
          life: 10000,
        });
      }
    },
  });

  const GetDayDifference = () => {
    var given = moment(new Date(basicInfo.values.startDate));
    var current = moment(new Date(basicInfo.values.endDate));
    const diff = current.diff(given);
    const diffDuration = moment.duration(diff);
    return Math.ceil(diffDuration._milliseconds / (1000 * 60 * 60 * 24));
  };

  let propertyExtraGuestFee = propertyPricePolicy?.extraGuestFee
    ? parseInt(propertyPricePolicy?.extraGuestFee)
    : 0;
  let propertyCleaningFee = propertyPricePolicy?.cleaningFee
    ? parseInt(propertyPricePolicy?.cleaningFee)
    : 0;
  let propertyPetFee = propertyPricePolicy?.petFee
    ? parseInt(propertyPricePolicy?.petFee)
    : 0;
  let petFeeTotal =
    parseInt(basicInfo.values.noOfPets) * propertyPetFee
      ? parseInt(basicInfo.values.noOfPets) * propertyPetFee
      : 0;
  //Total Nights Var
  const totalNights = GetDayDifference();

  //Total Nights Cost
  const totalNightsCost = totalAmountByApi;
  //Total Service Fee
  const amountWithoutServiceFee =
    totalNightsCost +
    parseInt(
      propertyPricePolicy?.cleaningFee ? propertyPricePolicy?.cleaningFee : 0
    ) +
    parseInt(additionalGuestFee) +
    petFeeTotal;
  const totalServiceFee = amountWithoutServiceFee * serviceFee;
  const amountWithServiceFee =
    totalNightsCost +
    parseInt(
      propertyPricePolicy?.cleaningFee ? propertyPricePolicy?.cleaningFee : 0
    ) +
    parseInt(additionalGuestFee) +
    totalServiceFee +
    petFeeTotal;

  //Percentage of total Gst including service fee
  const totalGst = amountWithServiceFee * gst;
  //Now Total Amount
  const totalAmount = amountWithServiceFee + totalGst;

  //Calculation for additional guests and total additional guests fee
  React.useEffect(() => {
    if (basicInfo.values.noOfGuest > 0) {
      setAdditionalGuests(basicInfo.values.noOfGuest - 1);
      setAdditionalGuestFee(
        (basicInfo.values.noOfGuest - 1) * parseInt(propertyExtraGuestFee)
          ? (basicInfo.values.noOfGuest - 1) * parseInt(propertyExtraGuestFee)
          : 0
      );
    } else {
      setAdditionalGuests(basicInfo.values.noOfGuest);
    }
  }, [propertyExtraGuestFee, basicInfo]);

  const handleGuestCountChange = async (value, count) => {
    basicInfo.setFieldValue('noOfGuest', value);
    basicInfo.setFieldValue('noOfAdults', count.adults);
    basicInfo.setFieldValue('noOfChildren', count.children);
    basicInfo.setFieldValue('noOfInfants', count.infants);
    basicInfo.setFieldValue('noOfPets', count.pets);
  };

  useEffect(() => {
    basicInfo.setFieldValue('status', status?.value);
  }, [status]);

  React.useEffect(() => {
    const getProperty = async () => {
      const prp = await getPropertyByIdApi(id);
      setPropertyDetail(prp.data.data);
    };
    getProperty();
  }, [id]);

  const accept = () => {
    navigate(`/admin/calendar/${propertyDetail?.id}`);
  };
  const reject = () => {};
  const confirm1 = () => {
    confirmDialog({
      message: (
        <div
          style={{
            width: '30vw',
            textAlign: 'left',
            paddingTop: '20px',
          }}
        >
          Are you sure you want to cancel? All changes will be lost!
        </div>
      ),
      header: 'Cancel Sending Invite?',
      acceptLabel: 'Yes',
      rejectLabel: 'No',
      accept,
      reject,
    });
  };

  React.useEffect(() => {
    const getTotalAmountByDateRange = async () => {
      const response = await getPropertyTotalAmountByDateRange(
        id,
        moment(basicInfo.values.startDate).toDate(),
        moment(basicInfo.values.endDate).toDate()
      );
      setTotalAmountByApi(response);
    };
    getTotalAmountByDateRange();
  }, [basicInfo.values.startDate, basicInfo.values.endDate, id]);

  React.useEffect(() => {
    const getBookingCalculatedPrcie = async () => {
      const response = await calculateBookingPriceApi({
        propertyId: id,
        startDate: moment(basicInfo.values.startDate).format('YYYY-MM-DD'),
        endDate: moment(basicInfo.values.endDate).format('YYYY-MM-DD'),
        noOfAdults: parseInt(basicInfo.values.noOfAdults),
        noOfChildren: parseInt(basicInfo.values.noOfChildren),
        noOfInfants: parseInt(basicInfo.values.noOfInfants),
        noOfPets: parseInt(basicInfo.values.noOfPets),
        validate: true,
      });
      setValues(response.data.data);
    };
    getBookingCalculatedPrcie();
  }, [
    id,
    basicInfo.values.noOfAdults,
    basicInfo.values.noOfPets,
    basicInfo.values.noOfChildren,
    basicInfo.values.noOfInfants,
    basicInfo.values.startDate,
    basicInfo.values.endDate,
  ]);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: 'black',
      fontSize: '16px',
    }),
    singleValue: (provided, state) => {
      return {
        ...provided,
        opacity: state.isDisabled ? 0.5 : 1,
        transition: 'opacity 300ms',
        color: '#fff',
        fontSize: '16px',
      };
    },
    input: (provided, state) => ({
      ...provided,
      fontSize: '16px',
      color: 'white',
    }),
    menu: (provided, state) => ({
      ...provided,
      fontSize: '16px',
    }),
    control: (provided, state) => ({
      ...provided,
      minHeight: '55px',
      backgroundColor: 'transparent',
      border: '1px solid rgba(255, 255, 255, 0.3)',
      borderRadius: '3px',
      '&:hover': {
        borderColor: '#0095ff',
      },
    }),
  };

  const statusData = [
    {
      label: 'Tentative',
      value: 'ISTENTATIVE',
    },
    {
      label: 'Confirmed',
      value: 'CONFIRMED',
    },
  ];

  let staywoFee = (commissionFee * values?.grossAmount) / 100;
  //Country options dropdown templates
  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className='country-item country-item-value'>
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className='country-item'>
        <div>{option.name}</div>
      </div>
    );
  };

  return (
    <>
      <Toast ref={toast} position='bottom-right' />
      <Header />
      <div id='wrapper'>
        <div className='dashboard-menu-overlay' />
        <Sidebar page={'Invite Guest'} />
        <div className='dashboard-content'>
          <div className='dashboard-menu-btn color-bg'>
            <span>
              <i className='fas fa-bars' />
            </span>
            Invite Guest
          </div>
          <InfoBar
            label={`Invite Guest for (${propertyDetail?.title} - ${propertyDetail?.code})`}
          />
          <div className='dasboard-wrapper fl-wrap no-pag'>
            <Grid container spacing={2}>
              <Grid md={6} item>
                <div style={{ margin: '0px 0px' }}>
                  <div className='clearfix' />
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='row'>
                        <div
                          className='col-md-12'
                          style={{
                            textAlign: 'left',
                          }}
                        >
                          <label
                            className='label-field'
                            style={{ fontSize: '18px', color: '#fff' }}
                          >
                            Check In/ Check Out{' '}
                          </label>
                          <Box
                            component='div'
                            sx={{
                              '& .p-inputtext': {
                                minHeight: '55px !important',
                                '&:hover': {
                                  borderColor: '#0095ff !important',
                                },
                              },
                            }}
                          >
                            <DatePickerInviteGuest
                              basePrice={propertyDetail.perNightPrice}
                              availableToday={availableToday}
                              id={propertyDetail?.id}
                              handlers
                              handleDateStart={(value) =>
                                basicInfo.setFieldValue('startDate', value)
                              }
                              handleDateEnd={(value) =>
                                basicInfo.setFieldValue('endDate', value)
                              }
                              toast={toast}
                              propertyPolicy={propertyDetail?.propertyPolicy}
                              propertyPricePolicy={
                                propertyDetail?.propertyPricePolicy
                              }
                            />
                          </Box>

                          <small className='invite-guest-validation-error'>
                            {basicInfo.touched.startDate &&
                              basicInfo.errors.startDate}
                            <br />
                            {basicInfo.touched.endDate &&
                              basicInfo.errors.endDate}
                          </small>
                        </div>
                      </div>
                      <div
                        className='row'
                        style={{
                          textAlign: 'left',
                          marginTop: '20px',
                        }}
                      >
                        <div className='col-md-12'>
                          <label
                            className='label-field'
                            style={{ fontSize: '18px', color: '#fff' }}
                          >
                            Persons
                          </label>
                          <GuestCountInviteGuest
                            propertyDetail={propertyDetail}
                            handleChange={handleGuestCountChange}
                          />
                          <small className='invite-guest-validation-error'>
                            {basicInfo.touched.noOfGuest &&
                              basicInfo.errors.noOfGuest}
                          </small>
                        </div>
                      </div>
                      <div
                        className='row'
                        style={{
                          textAlign: 'left',
                          marginTop: '20px',
                        }}
                      >
                        <Box component='div' className='col-md-12'>
                          <InputLabel id='demo-simple-select-label'>
                            Status
                          </InputLabel>
                          <Select
                            className='override-select-edit'
                            placeholder='Select Status'
                            options={statusData}
                            value={status}
                            onChange={(e) => setStatus(e)}
                            styles={customStyles}
                          />
                          <small className='invite-guest-validation-error'>
                            {basicInfo.touched.status &&
                              basicInfo.errors.status}
                          </small>
                        </Box>
                      </div>
                      <div
                        className='row'
                        style={{
                          textAlign: 'left',
                          marginTop: '20px',
                        }}
                      >
                        <Box
                          sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <Typography sx={{ color: '#fff', fontSize: '22px' }}>
                            Additional Infromation
                          </Typography>
                        </Box>
                        <div className='col-md-12'>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                              <Box sx={{ margin: '10px 0px' }}>
                                <Typography className='signupFormLabel'>
                                  First Name
                                </Typography>
                                <TextField
                                  id='outlined-basic'
                                  placeholder='First Name'
                                  variant='outlined'
                                  fullWidth
                                  name='firstName'
                                  value={basicInfo.values.firstName}
                                  onChange={basicInfo.handleChange}
                                />
                                <small className='invite-guest-validation-error'>
                                  {basicInfo.touched.firstName &&
                                    basicInfo.errors.firstName}
                                </small>
                              </Box>
                            </Grid>

                            <Grid item xs={12} md={6}>
                              <Box sx={{ margin: '10px 0px' }}>
                                <Typography className='signupFormLabel'>
                                  Last Name
                                </Typography>
                                <TextField
                                  id='outlined-basic'
                                  placeholder='Last Name'
                                  variant='outlined'
                                  fullWidth
                                  name='lastName'
                                  value={basicInfo.values.lastName}
                                  onChange={basicInfo.handleChange}
                                />
                                <small className='invite-guest-validation-error'>
                                  {basicInfo.touched.lastName &&
                                    basicInfo.errors.lastName}
                                </small>
                              </Box>
                            </Grid>
                          </Grid>

                          <Box sx={{ margin: '10px 0px' }}>
                            <Typography className='signupFormLabel'>
                              Email
                            </Typography>
                            <TextField
                              id='outlined-basic'
                              placeholder='Email'
                              variant='outlined'
                              fullWidth
                              name='email'
                              value={basicInfo.values.email}
                              onChange={basicInfo.handleChange}
                            />
                            <small className='invite-guest-validation-error'>
                              {basicInfo.touched.email &&
                                basicInfo.errors.email}
                            </small>
                          </Box>
                          <Box sx={{ margin: '10px 0px' }}>
                            <Typography className='signupFormLabel'>
                              Address 1
                            </Typography>
                            <TextField
                              id='outlined-basic'
                              placeholder='Address 1'
                              variant='outlined'
                              fullWidth
                              name='address1'
                              value={basicInfo.values.address1}
                              onChange={basicInfo.handleChange}
                            />
                            <small className='invite-guest-validation-error'>
                              {basicInfo.touched.address1 &&
                                basicInfo.errors.address1}
                            </small>
                          </Box>
                          <Box sx={{ margin: '10px 0px' }}>
                            <Typography className='signupFormLabel'>
                              Address 2
                            </Typography>
                            <TextField
                              id='outlined-basic'
                              placeholder='Address 2'
                              variant='outlined'
                              fullWidth
                              name='address2'
                              value={basicInfo.values.address2}
                              onChange={basicInfo.handleChange}
                            />
                            <small className='invite-guest-validation-error'>
                              {basicInfo.touched.address2 &&
                                basicInfo.errors.address2}
                            </small>
                          </Box>
                          <Box sx={{ margin: '10px 0px' }}>
                            <Typography className='signupFormLabel'>
                              City
                            </Typography>
                            <TextField
                              id='outlined-basic'
                              placeholder='City'
                              variant='outlined'
                              fullWidth
                              name='city'
                              value={basicInfo.values.city}
                              onChange={basicInfo.handleChange}
                            />
                            <small className='invite-guest-validation-error'>
                              {basicInfo.touched.city && basicInfo.errors.city}
                            </small>
                          </Box>

                          <Box sx={{ margin: '10px 0px' }}>
                            <Typography className='signupFormLabel'>
                              Country
                            </Typography>
                            <Dropdown
                              value={basicInfo.values.country}
                              options={options}
                              onChange={(e) =>
                                basicInfo.setFieldValue('country', e.value)
                              }
                              optionLabel='name'
                              filter
                              showClear
                              filterBy='name'
                              name='country'
                              placeholder='Country/region'
                              valueTemplate={selectedCountryTemplate}
                              itemTemplate={countryOptionTemplate}
                              style={{
                                width: '100%',
                                background: '#1b182b',
                                borderColor: '#504d5c',
                                padding: '4.5px 0',
                                height: '55px',
                              }}
                            />
                          </Box>
                          <Box sx={{ margin: '10px 0px' }}>
                            <Typography className='signupFormLabel'>
                              Add Note
                            </Typography>
                            <TextField
                              id='outlined-basic'
                              placeholder='Add Note'
                              variant='outlined'
                              fullWidth
                              name='note'
                              value={basicInfo.values.note}
                              onChange={basicInfo.handleChange}
                            />
                          </Box>
                          <Box
                            sx={{
                              margin: '10px 0px',
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <Buttons
                              isLoading={isLoading}
                              title='Send Invite'
                              style={{ width: '70%' }}
                              onSubmit={basicInfo.handleSubmit}
                            />
                          </Box>
                          <Box
                            sx={{
                              margin: '10px 0px',
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <Buttons
                              isLoading={false}
                              title='Cancel'
                              style={{ width: '70%' }}
                              variant='outlined'
                              onSubmit={confirm1}
                            />
                          </Box>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid md={6} item>
                <Box
                  sx={{
                    borderRadius: '8px',
                    border: '1px solid rgba(255, 255, 255, 0.1)',
                    padding: '20px',
                  }}
                >
                  <Box sx={{ display: 'flex' }}>
                    <Box>
                      <img
                        src={propertyDetail?.thumbnailUrl}
                        alt='property_image'
                        width={150}
                        height={150}
                      />
                    </Box>
                    <Box sx={{ marginLeft: '10px' }}>
                      <Typography
                        sx={{
                          color: '#fff',
                          fontFamily: 'Jost',
                          fontSize: '22px',
                        }}
                      >
                        {propertyDetail?.title &&
                        propertyDetail?.title?.length > 30
                          ? propertyDetail?.title?.slice(0, 30) + '...'
                          : propertyDetail?.title?.slice(0, 30)}
                      </Typography>
                      <Typography sx={{ fontFamily: 'Jost', fontSize: '14px' }}>
                        {propertyDetail?.city}, {propertyDetail?.country}
                      </Typography>
                      <Typography sx={{ fontFamily: 'Jost', fontSize: '14px' }}>
                        ID: {propertyDetail?.code}
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: 'Jost',
                          fontSize: '16px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Rating
                          name='half-rating-read'
                          value={
                            propertyDetail?.average_rating
                              ? propertyDetail?.average_rating?.averageRating
                              : 0
                          }
                          precision={0.5}
                          readOnly
                          sx={{ marginRight: '4px' }}
                        />
                        (
                        {propertyDetail?.average_rating
                          ? parseInt(
                              propertyDetail?.average_rating?.averageRating
                            ).toFixed(1)
                          : 0}
                        )
                        {propertyDetail?.average_rating
                          ? ` (${propertyDetail?.average_rating?.reviewCount} Reviews)`
                          : ' (0 Reviews)'}
                      </Typography>
                    </Box>
                  </Box>
                  {basicInfo.values.startDate && basicInfo.values.endDate ? (
                    <Box sx={{ marginTop: '10px' }}>
                      <Typography
                        sx={{
                          color: '#fff',
                          fontFamily: 'Jost',
                          fontSize: '25px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <PaymentsIcon
                          sx={{ fontSize: '25px', marginRight: '5px' }}
                        />{' '}
                        Price Details
                      </Typography>

                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography
                          sx={{
                            color: '#fff',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <NightlightRoundIcon
                            sx={{ fontSize: '18px', marginRight: '5px' }}
                          />{' '}
                          Nightly Price x {values?.noOfNights} (nights)
                        </Typography>
                        <Typography>
                          {process.env.REACT_APP_CURRENCY}{' '}
                          {values?.totalNightsCharges?.toLocaleString('en-US')}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography
                          sx={{
                            color: '#fff',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {' '}
                          <CleaningServicesIcon
                            sx={{ fontSize: '18px', marginRight: '5px' }}
                          />{' '}
                          Cleaning Fee
                        </Typography>
                        <Typography>
                          {process.env.REACT_APP_CURRENCY}{' '}
                          {values?.cleaningFee?.toLocaleString('en-US')}{' '}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexDirection: 'row',
                        }}
                      >
                        <Typography
                          sx={{
                            color: '#fff',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <PersonAddIcon
                            sx={{ fontSize: '18px', marginRight: '5px' }}
                          />{' '}
                          Additional Guests Fee
                        </Typography>
                        <Typography>
                          <span style={{ color: '#fff' }}>
                            {values?.totalExtraGuests?.toLocaleString('en-US')}
                          </span>{' '}
                          x{' '}
                          <span style={{ color: '#fff' }}>
                            {process.env.REACT_APP_CURRENCY}{' '}
                            {values?.extraGuestFee?.toLocaleString('en-US')}
                          </span>{' '}
                          ={' '}
                          <span style={{ color: '#fff' }}>
                            {' '}
                            {process.env.REACT_APP_CURRENCY}{' '}
                            {values?.totalExtraGuestFee?.toLocaleString(
                              'en-US'
                            )}
                          </span>
                        </Typography>
                      </Box>
                      {propertyDetail?.propertyCapacity?.noOfInfants > 0 ? (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Typography
                            sx={{
                              color: '#fff',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <ChildCareIcon
                              sx={{ fontSize: '18px', marginRight: '5px' }}
                            />{' '}
                            Infant Fee
                          </Typography>
                          <Typography>No Fee</Typography>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Typography
                            sx={{
                              color: '#fff',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <ChildCareIcon
                              sx={{ fontSize: '18px', marginRight: '5px' }}
                            />{' '}
                            Infant Fee
                          </Typography>
                          <Typography>Not Allowed</Typography>
                        </Box>
                      )}
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography
                          sx={{
                            color: '#fff',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <PetsIcon
                            sx={{ fontSize: '18px', marginRight: '5px' }}
                          />{' '}
                          Pet Fee
                        </Typography>
                        {propertyDetail?.propertyCapacity?.noOfPets > 0 ? (
                          <Typography>
                            {values?.noOfPets} x{' '}
                            {process.env.REACT_APP_CURRENCY}{' '}
                            {values?.petFee?.toLocaleString('en-US')} ={' '}
                            {values?.totalPetFee?.toLocaleString('en-US')}
                          </Typography>
                        ) : (
                          <Typography>Not Allowed</Typography>
                        )}
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography
                          sx={{
                            color: '#fff',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <AccountBalanceIcon
                            sx={{ fontSize: '18px', marginRight: '5px' }}
                          />{' '}
                          Service Fee ({values?.serviceFee} %)
                        </Typography>
                        <Typography>
                          {process.env.REACT_APP_CURRENCY}{' '}
                          {values?.totalServiceCharges?.toLocaleString('en-US')}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography
                          sx={{
                            color: '#fff',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <AccountBalanceIcon
                            sx={{ fontSize: '18px', marginRight: '5px' }}
                          />{' '}
                          GST ({values?.gst} %)
                        </Typography>
                        <Typography>
                          {process.env.REACT_APP_CURRENCY}{' '}
                          {values?.totalGSTFee?.toLocaleString('en-US')}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography
                          sx={{
                            color: '#fff',
                            fontSize: '19px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <DoneOutlineIcon
                            sx={{ fontSize: '19px', marginRight: '5px' }}
                          />{' '}
                          Total ( {process.env.REACT_APP_CURRENCY} )
                        </Typography>
                        <Typography sx={{ fontSize: '18px', color: '#fff' }}>
                          {process.env.REACT_APP_CURRENCY}{' '}
                          {values?.grossAmount?.toLocaleString('en-US')}
                        </Typography>
                      </Box>
                      <Typography
                        sx={{
                          color: '#fff',
                          fontFamily: 'Jost',
                          fontSize: '25px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <PaymentsIcon
                          sx={{ fontSize: '25px', marginRight: '5px' }}
                        />{' '}
                        Potential Earnings
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          sx={{
                            color: 'white',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <NightsStayIcon
                            sx={{ fontSize: '18px', marginRight: '5px' }}
                          />
                          Total Stay Price
                        </Typography>

                        <Typography style={{ fontSize: '14px' }}>
                          {process.env.REACT_APP_CURRENCY}{' '}
                          {values?.grossAmount?.toLocaleString('en-US')}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          sx={{
                            color: 'white',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <PaymentIcon
                            sx={{ fontSize: '18px', marginRight: '5px' }}
                          />
                          Staywo Fee
                        </Typography>
                        <Typography style={{ fontSize: '14px' }}>
                          {process.env.REACT_APP_CURRENCY}{' '}
                          {staywoFee?.toLocaleString('en-US')}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          sx={{
                            color: 'white',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <PaymentIcon
                            sx={{ fontSize: '18px', marginRight: '5px' }}
                          />
                          GST
                        </Typography>
                        <Typography style={{ fontSize: '14px' }}>
                          {process.env.REACT_APP_CURRENCY}{' '}
                          {(values?.grossAmount * (values?.gst)/100).toLocaleString('en-US')}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          sx={{
                            color: '#fff',
                            fontSize: '19px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <DoneOutlineIcon
                            sx={{ fontSize: '19px', marginRight: '5px' }}
                          />{' '}
                          Total ( {process.env.REACT_APP_CURRENCY} )
                        </Typography>
                        <Typography sx={{ fontSize: '18px', color: '#fff' }}>
                          {process.env.REACT_APP_CURRENCY}{' '}
                          {(values?.grossAmount - staywoFee - ((values?.grossAmount * (values?.gst)/100)))?.toLocaleString(
                            'en-US'
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    <Typography
                      sx={{
                        margin: '10px',
                        color: '#fff',
                        fontSize: '18px',
                        textAlign: 'center',
                      }}
                    >
                      Please select a date range to calculate the price for the
                      booking invitation.
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};

export default InviteGuestPage;
