import { Box, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Sidebar from "../../../components/dasboard/sidebar";
import Header from "../../../components/header";
import { Grid, Tab } from "@mui/material";
import { getAdditionalPaymentApi } from "../../../services/booking/getAdditionalPayments";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Buttons from "../../../components/form/button";
import { getOffersApi } from "../../../services/booking/getOffers";
import { acceptOfferApi } from "../../../services/booking/acceptOffer";
import { withDrawOfferApi } from "../../../services/booking/withdrawOffer";
import { rejectOfferApi } from "../../../services/booking/rejectOffer";
import moment from "moment";
import { getBookingUpdateRequestApi } from "../../../services/booking/getBookingUpdateRequests";
import { actionBookingUpdateRequest } from "../../../services/booking/actionBookingUpdateRequest";
import { getBookingInvitationsApi } from "../../../services/InviteGuest/getBookingInvitations";
import { Toast } from "primereact/toast";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { getUserWalletApi } from "../../../services/wallet/getUserWallet";
import getUserCardApi from "../../../services/User/getUserCard";
import * as yup from "yup";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import ReservationDetailForModificationRequest from "./ReservationDetailForModificationRequests";
import { useSnackbar } from "notistack";
import { sendExtraPaymentMessageApi } from "../../../services/booking/sendMessageForExtraPayment";
import { getExtraPaymentMessagesApi } from "../../../services/booking/fetchExtraPaymentMessages";
import { acceptPaymentRequestDirect } from "../../../services/booking/acceptPaymentRequetsDirect";
import PaymentModalForExtraPayments from "./PaymentModalForExtraPayments";
import ReactPlayer from "react-player";
import PaymentModalForCreateOffer from "./PaymentModalForCreateOffer";
import { createOfferApi } from "../../../services/booking/createOffer";
import CreateOfferDialog from "../../../components/BookingComponents/CreateOfferDialog/CreateOfferDialog";

const PaymentRequestsPage = () => {
  const [additionalPayments, setAdditionalPayments] = useState([]);
  const [bookingUpdateRequests, setBookingUpdateRequests] = useState([]);
  const [bookingInvitations, setBookingInvitations] = useState([]);
  const [value, setValue] = React.useState("1");
  const userType = localStorage.getItem("userHost");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const toast = React.useRef();
  const navigate = useNavigate();

  const userHost = JSON.parse(localStorage.getItem("userHost"))
    ? "HOST"
    : "GUEST";

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const getAdditionalPayments = async () => {
    const res = await getAdditionalPaymentApi();
    setAdditionalPayments(res);
  };
  const getBookingInvitations = async () => {
    const res = await getBookingInvitationsApi(userData?.id, userHost);
    if (res.status === 200 || res.status === 201) {
      setBookingInvitations(res?.data?.data);
    } else {
      setBookingInvitations([]);
    }
  };

  const userHostLocal = JSON.parse(localStorage.getItem("userHost"));
  const getModifyBookingRequests = async () => {
    let id = userHostLocal
      ? `&ownerId=${userData?.id}`
      : `&guestId=${userData?.id}`;
    const res = await getBookingUpdateRequestApi(id);
    if (res.status === 200 || res.status === 201) {
      setBookingUpdateRequests(res?.data.data);
    } else {
      setBookingUpdateRequests([]);
    }
  };

  useEffect(() => {
    getAdditionalPayments();
    getModifyBookingRequests();
    getBookingInvitations();
  }, []);

  //----------------MUI-------------//
  function createData(
    paymentReason,
    amount,
    paymentType,
    bookingId,
    status,
    msg,
    attachments,
    id,
    date,
    IdForBooking,
    claimer
  ) {
    return {
      paymentReason,
      amount,
      paymentType,
      bookingId,
      status,
      msg,
      attachments,
      id,
      date,
      IdForBooking,
      claimer,
    };
  }
  function Row(props) {
    const { row } = props;
    const [off, setOff] = useState();
    const [offers, setOffers] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [dialog, setDialog] = useState(false);
    const [openCreateOffer, setOpenCreateOffer] = useState(false);
    const [showAcceptOffer, setShowAcceptOffer] = useState(false);
    const [card, setCard] = React.useState({});
    const [card2, setCard2] = React.useState({});
    const [card3, setCard3] = React.useState({});
    const [userCards, setUserCards] = useState([]);
    const [useWallet, setUseWallet] = useState(false);
    const [useWallet2, setUseWallet2] = useState(false);
    const [useWallet3, setUseWallet3] = useState(false);
    const [useCreditCard, setUseCreditCard] = useState(false);
    const [useCreditCard2, setUseCreditCard2] = useState(false);
    const [useCreditCard3, setUseCreditCard3] = useState(false);
    const [walletLessAmountError, setWalletLessAmountError] = useState(false);
    const [walletLessAmountError2, setWalletLessAmountError2] = useState(false);
    const [walletLessAmountError3, setWalletLessAmountError3] = useState(false);
    const [wallet, setWallet] = useState({});
    const noActiveCard = Object.keys(card).length === 0;
    const noActiveCard2 = Object.keys(card2).length === 0;
    const noActiveCard3 = Object.keys(card3).length === 0;
    const [openDetailDialog, setOpenDetailDialog] = useState(false);
    const [openDetailDialog2, setOpenDetailDialog2] = useState(false);

    const handleCloseDetailDialog = () => {
      setOpenDetailDialog(false);
    };
    const handleCloseDetailDialog2 = () => {
      setOpenDetailDialog2(false);
    };

    //===================pay dialog============//

    // //Get User Wallet Api
    useEffect(() => {
      const getWallet = async () => {
        const res = await getUserWalletApi(userData?.id);
        if (res.status === 404 || res.status === 401 || res.status === 403) {
          setWallet([]);
        } else {
          setWallet(res);
        }
      };
      getWallet();
    }, []);

    //Get User Cards
    useEffect(() => {
      const getUserCards = async () => {
        const res = await getUserCardApi(userData?.id);
        let temp = [];
        res?.data.forEach((item) => {
          return temp.push({
            value: item,
            label: item.last4digit,
            brand: item.brand,
          });
        });
        setUserCards(temp);
      };
      getUserCards();
    }, []);

    //Check if active card is empty

    useEffect(() => {
      const getOff = () => {
        offers?.forEach((offer) => {
          if (offer?.bookingextrapayment.id === row.id) {
            setOff(offer);
          }
        });
      };
      getOff();
    }, [row?.id, offers]);
    const handleClose = () => {
      setDialog(false);
    };
    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
      if (dialog) {
        const { current: descriptionElement } = descriptionElementRef;
        if (descriptionElement !== null) {
          descriptionElement.focus();
        }
      }
    }, [dialog]);
    const descriptionElementRefForCreateOffer = React.useRef(null);
    React.useEffect(() => {
      if (openCreateOffer) {
        const { current: descriptionElement } =
          descriptionElementRefForCreateOffer;
        if (descriptionElement !== null) {
          descriptionElement.focus();
        }
      }
    }, [openCreateOffer]);

    const rejectOffer = async () => {
      const res = await rejectOfferApi(off?.offerid, userData?.id);
      if (res.status === 201) {
        toast.current.show({
          severity: "info",
          summary: "Rejected",
          detail: "Offer is rejected successfully!",
          life: 10000,
        });
        window.location.reload();
      } else {
        toast.current.show({
          severity: "info",
          summary: "Error",
          detail: "Couldnt reject the offer.",
          life: 10000,
        });
      }
    };
    const withdrawOffer = async () => {
      const res = await withDrawOfferApi(row?.id, userData?.id);
      if (res.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Withdrawn",
          detail: "Additional Payment Request is withdrawn successfully!",
          life: 10000,
        });
        setShowWithdrawExtraPayReq(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        setShowWithdrawExtraPayReq(false);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Couldnt withdraw the request.",
          life: 10000,
        });
      }
    };
    const getOffers = async () => {
      const offers = await getOffersApi(userData?.id);
      setOffers(offers);
    };

    useEffect(() => {
      getOffers();
    }, []);

    const [showRejectOffer, setShowRejectOffer] = useState(false);
    const [showWithdrawExtraPayReq, setShowWithdrawExtraPayReq] =
      useState(false);
    const [msgVal, setMsgVal] = useState("");
    const [messages, setMessages] = useState([]);
    const [msgErr, setMsgValError] = useState("");

    const handleSendMessage = async () => {
      if (msgVal !== "") {
        const response = await sendExtraPaymentMessageApi(
          msgVal,
          userData?.id,
          row?.id
        );
        if (response.status === 200 || 201) {
          setMsgVal("");
          messages?.push({
            senderId: userData?.id,
            message: msgVal,
            bookingExtraPaymentId: row?.id,
          });
          toast.current.show({
            severity: "success",
            summary: "Message Sent!",
            detail: "Your message is sent successfully!",
            life: 10000,
          });
        } else {
          toast.current.show({
            severity: "error",
            summary: "Could not send Message",
            detail: "Your message could not be sent!",
            life: 10000,
          });
        }
      } else {
        setMsgValError("Please type some message and then send.");
      }
    };

    React.useEffect(() => {
      const extraPaymentMessages = async () => {
        const response = await getExtraPaymentMessagesApi(row?.id);
        setMessages(response.data);
      };
      if (row?.id) {
        extraPaymentMessages();
      }
    }, [row?.id]);
    const receivedHaveToAddPaymentDetailsForOfferOrDirectly =
      value === "1" && row?.paymentType === "Claim";
    const sentHaveToAddPaymentDetailsForOffer =
      value === "2" && row?.paymentType === "Extra Payment";

    const formik = useFormik({
      initialValues: {
        number: "",
        name: "",
        exp_month: "",
        exp_year: "",
        cvc: "",
        user_id: userData?.id,
        use_save_card: false,
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipcode: "",
        country: "",
      },
      enableReinitialize: true,
      ...(noActiveCard &&
        useCreditCard && {
          validationSchema: () => {
            return yup.object({
              name: yup.string().required("Please enter your name!"),
              number: yup
                .string()
                .min(16, "Card number must be only 16 characters")
                .max(16, "Card number must be only 16 characters")
                .required("Please enter your card number!"),
              exp_month: yup
                .string()
                .required("Please enter your card expiry month!"),
              exp_year: yup
                .string()
                .required("Please enter your card expiry year!"),
              cvc: yup
                .string()
                .min(3, "CVC must be at least 3 characters!")
                .max(4, "CVC can be only 4 characters!")
                .required("Please enter your card CVC!"),
              address1: yup.string().required("Street Address is required!"),
              address2: yup
                .string()
                .required("Apt or suite number is required!"),
              city: yup.string().required("City is required!"),
              zipcode: yup.string().required("Zip Code is required!"),
              state: yup.string().required("State is required!"),
            });
          },
        }),
      onSubmit: async () => {
        if (!useWallet && !useCreditCard && noActiveCard) {
          toast.current.show({
            severity: "info",
            summary: "No payment method selected",
            detail: "Please select a payment method to proceed your booking!",
            life: 10000,
          });
        } else {
          //If user only wants to be charged through wallet
          if (useWallet && noActiveCard && !useCreditCard) {
            const response = await acceptPaymentRequestDirect(
              {
                paymentMethod: "WALLET",
                extraPaymentId: row?.id,
                booking_id: row?.IdForBooking,
                user_id: formik.values.user_id,
                userid: formik.values.user_id,
              },
              row?.ID
            );
            if (response.status === 201) {
              toast.current.show({
                severity: "success",
                summary: "Booking Update Request Accepted!",
                detail: "Booking update request accepted!",
                life: 10000,
              });
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            } else {
              toast.current.show({
                severity: "error",
                summary: "Cannot Accept Request",
                detail: `There is an error accepting the request`,
                life: 10000,
              });
            }
          } else if (noActiveCard && !useWallet && !useCreditCard) {
            toast.current.show({
              severity: "error",
              summary: "No Payment method selected!",
              detail: "Please select a payment method to continue.",
              life: 10000,
            });
          }
          //If user only wants to be charged through exisiting card
          else if (card && !useWallet && !useCreditCard) {
            const response = await acceptPaymentRequestDirect(
              {
                card_id: card?.value?.id,
                paymentMethod: "CARD",
                extraPaymentId: row?.id,
                booking_id: row?.IdForBooking,
                user_id: formik.values.user_id,
                userid: formik.values.user_id,
              },
              row?.ID
            );
            if (response.status === 201) {
              toast.current.show({
                severity: "success",
                summary: "Booking Update Request Accepted!",
                detail: "Booking update request accepted!",
                life: 10000,
              });
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            } else {
              toast.current.show({
                severity: "error",
                summary: "Cannot Accept Request",
                detail: `There is an error accepting the request`,
                life: 10000,
              });
            }
          }
          //If user only wants to be charged by adding new card
          else if (useCreditCard && !useWallet && noActiveCard) {
            const response = await acceptPaymentRequestDirect(
              {
                name: formik.values.name,
                number: formik.values.number,
                exp_month: formik.values.exp_month,
                exp_year: formik.values.exp_year,
                cvc: formik.values.cvc,
                address1: formik.values.address1,
                address2: formik.values.address2,
                city: formik.values.city,
                state: formik.values.state,
                zipcode: formik.values.zipcode,
                country: formik.values.country?.name,
                paymentMethod: "CARD",
                extraPaymentId: row?.id,
                booking_id: row?.IdForBooking,
                user_id: formik.values.user_id,
                userid: formik.values.user_id,
              },
              row?.ID
            );
            if (response.status === 201) {
              toast.current.show({
                severity: "success",
                summary: "Booking Update Request Accepted!",
                detail: "Booking update request accepted!",
                life: 10000,
              });
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            } else {
              toast.current.show({
                severity: "error",
                summary: "Cannot Accept Request",
                detail: `There is an error accepting the request`,
                life: 10000,
              });
            }
          }
          //If user wants to be charged by through wallet + exisiting card
          else if (
            useWallet &&
            Object.keys(card).length > 0 &&
            !useCreditCard
          ) {
            const response = await acceptPaymentRequestDirect(
              {
                card_id: card?.value?.id,
                paymentMethod: "CARD_PLUS_WALLET",
                extraPaymentId: row?.id,
                booking_id: row?.IdForBooking,
                user_id: formik.values.user_id,
                userid: formik.values.user_id,
              },
              row?.ID
            );
            if (response.status === 201) {
              toast.current.show({
                severity: "success",
                summary: "Booking Update Request Accepted!",
                detail: "Booking update request accepted!",
                life: 10000,
              });
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            } else {
              toast.current.show({
                severity: "error",
                summary: "Cannot Accept Request",
                detail: `There is an error accepting the request`,
                life: 10000,
              });
            }
          }
          //If user wants to be charged through wallet + by adding new card
          else if (useWallet && useCreditCard && noActiveCard) {
            const response = await acceptPaymentRequestDirect(
              {
                name: formik.values.name,
                number: formik.values.number,
                exp_month: formik.values.exp_month,
                exp_year: formik.values.exp_year,
                cvc: formik.values.cvc,
                paymentMethod: "CARD_PLUS_WALLET",
                address1: formik.values.address1,
                address2: formik.values.address2,
                city: formik.values.city,
                state: formik.values.state,
                zipcode: formik.values.zipcode,
                country: formik.values.country?.name,
                extraPaymentId: row?.id,
                booking_id: row?.IdForBooking,
                user_id: formik.values.user_id,
                userid: formik.values.user_id,
              },
              row?.ID
            );
            if (response.status === 201) {
              toast.current.show({
                severity: "success",
                summary: "Booking Update Request Accepted!",
                detail: "Booking update request accepted!",
                life: 10000,
              });
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            } else {
              toast.current.show({
                severity: "error",
                summary: "Cannot Accept Request",
                detail: `There is an error accepting the request`,
                life: 10000,
              });
            }
          }
        }
      },
    });

    const acceptOfferWithoutPaying = async () => {
      const response = await acceptOfferApi({
        booking_id: row?.IdForBooking,
        user_id: formikForAcceptOffer.values.user_id,
        userid: formikForAcceptOffer.values.user_id,
        offerid: off?.offerid,
      });
      if (response.status === 201) {
        toast.current.show({
          severity: "success",
          summary: "Booking Update Request Accepted!",
          detail: "Booking update request accepted!",
          life: 10000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Cannot Accept Request",
          detail: `There is an error accepting the request`,
          life: 10000,
        });
      }
    };
    const formikForAcceptOffer = useFormik({
      initialValues: {
        number: "",
        name: "",
        exp_month: "",
        exp_year: "",
        cvc: "",
        user_id: userData?.id,
        use_save_card: false,
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipcode: "",
        country: "",
      },
      enableReinitialize: true,
      ...(noActiveCard2 &&
        useCreditCard2 && {
          validationSchema: () => {
            return yup.object({
              name: yup.string().required("Please enter your name!"),
              number: yup
                .string()
                .min(16, "Card number must be only 16 characters")
                .max(16, "Card number must be only 16 characters")
                .required("Please enter your card number!"),
              exp_month: yup
                .string()
                .required("Please enter your card expiry month!"),
              exp_year: yup
                .string()
                .required("Please enter your card expiry year!"),
              cvc: yup
                .string()
                .min(3, "CVC must be at least 3 characters!")
                .max(4, "CVC can be only 4 characters!")
                .required("Please enter your card CVC!"),
              address1: yup.string().required("Street Address is required!"),
              address2: yup
                .string()
                .required("Apt or suite number is required!"),
              city: yup.string().required("City is required!"),
              zipcode: yup.string().required("Zip Code is required!"),
              state: yup.string().required("State is required!"),
            });
          },
        }),
      onSubmit: async () => {
        if (!useWallet2 && !useCreditCard2 && noActiveCard2) {
          toast.current.show({
            severity: "info",
            summary: "No payment method selected",
            detail: "Please select a payment method to proceed your booking!",
            life: 10000,
          });
        } else {
          //If user only wants to be charged through wallet
          if (useWallet2 && noActiveCard2 && !useCreditCard2) {
            const response = await acceptOfferApi({
              paymentMethod: "WALLET",
              booking_id: row?.IdForBooking,
              user_id: formikForAcceptOffer.values.user_id,
              userid: formikForAcceptOffer.values.user_id,
              offerid: off?.offerid,
            });
            if (response.status === 201) {
              toast.current.show({
                severity: "success",
                summary: "Booking Update Request Accepted!",
                detail: "Booking update request accepted!",
                life: 10000,
              });
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            } else {
              toast.current.show({
                severity: "error",
                summary: "Cannot Accept Request",
                detail: `There is an error accepting the request`,
                life: 10000,
              });
            }
          } else if (noActiveCard && !useWallet && !useCreditCard) {
            toast.current.show({
              severity: "error",
              summary: "No Payment method selected!",
              detail: "Please select a payment method to continue.",
              life: 10000,
            });
          }
          //If user only wants to be charged through exisiting card
          else if (card2 && !useWallet2 && !useCreditCard2) {
            const response = await acceptOfferApi({
              card_id: card2?.value?.id,
              paymentMethod: "CARD",
              booking_id: row?.IdForBooking,
              user_id: formikForAcceptOffer.values.user_id,
              userid: formikForAcceptOffer.values.user_id,
              offerid: off?.offerid,
            });
            if (response.status === 201) {
              toast.current.show({
                severity: "success",
                summary: "Booking Update Request Accepted!",
                detail: "Booking update request accepted!",
                life: 10000,
              });
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            } else {
              toast.current.show({
                severity: "error",
                summary: "Cannot Accept Request",
                detail: `There is an error accepting the request`,
                life: 10000,
              });
            }
          }
          //If user only wants to be charged by adding new card
          else if (useCreditCard2 && !useWallet2 && noActiveCard2) {
            const response = await acceptOfferApi({
              name: formikForAcceptOffer.values.name,
              number: formikForAcceptOffer.values.number,
              exp_month: formikForAcceptOffer.values.exp_month,
              exp_year: formikForAcceptOffer.values.exp_year,
              cvc: formikForAcceptOffer.values.cvc,
              address1: formikForAcceptOffer.values.address1,
              address2: formikForAcceptOffer.values.address2,
              city: formikForAcceptOffer.values.city,
              state: formikForAcceptOffer.values.state,
              zipcode: formikForAcceptOffer.values.zipcode,
              country: formikForAcceptOffer.values.country?.name,
              paymentMethod: "CARD",
              booking_id: row?.IdForBooking,
              user_id: formikForAcceptOffer.values.user_id,
              userid: formikForAcceptOffer.values.user_id,
              offerid: off?.offerid,
            });
            if (response.status === 201) {
              toast.current.show({
                severity: "success",
                summary: "Booking Update Request Accepted!",
                detail: "Booking update request accepted!",
                life: 10000,
              });
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            } else {
              toast.current.show({
                severity: "error",
                summary: "Cannot Accept Request",
                detail: `There is an error accepting the request`,
                life: 10000,
              });
            }
          }
          //If user wants to be charged by through wallet + exisiting card
          else if (
            useWallet2 &&
            Object.keys(card2).length > 0 &&
            !useCreditCard2
          ) {
            const response = await acceptOfferApi({
              card_id: card2?.value?.id,
              paymentMethod: "CARD_PLUS_WALLET",
              booking_id: row?.IdForBooking,
              user_id: formikForAcceptOffer.values.user_id,
              userid: formikForAcceptOffer.values.user_id,
              offerid: off?.offerid,
            });
            if (response.status === 201) {
              toast.current.show({
                severity: "success",
                summary: "Booking Update Request Accepted!",
                detail: "Booking update request accepted!",
                life: 10000,
              });
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            } else {
              toast.current.show({
                severity: "error",
                summary: "Cannot Accept Request",
                detail: `There is an error accepting the request`,
                life: 10000,
              });
            }
          }
          //If user wants to be charged through wallet + by adding new card
          else if (useWallet2 && useCreditCard2 && noActiveCard2) {
            const response = await acceptOfferApi({
              name: formikForAcceptOffer.values.name,
              number: formikForAcceptOffer.values.number,
              exp_month: formikForAcceptOffer.values.exp_month,
              exp_year: formikForAcceptOffer.values.exp_year,
              cvc: formikForAcceptOffer.values.cvc,
              paymentMethod: "CARD_PLUS_WALLET",
              address1: formikForAcceptOffer.values.address1,
              address2: formikForAcceptOffer.values.address2,
              city: formikForAcceptOffer.values.city,
              state: formikForAcceptOffer.values.state,
              zipcode: formikForAcceptOffer.values.zipcode,
              country: formikForAcceptOffer.values.country?.name,
              booking_id: row?.IdForBooking,
              user_id: formikForAcceptOffer.values.user_id,
              userid: formikForAcceptOffer.values.user_id,
              offerid: off?.offerid,
            });
            if (response.status === 201) {
              toast.current.show({
                severity: "success",
                summary: "Booking Update Request Accepted!",
                detail: "Booking update request accepted!",
                life: 10000,
              });
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            } else {
              toast.current.show({
                severity: "error",
                summary: "Cannot Accept Request",
                detail: `There is an error accepting the request`,
                life: 10000,
              });
            }
          }
        }
      },
    });

    const [amountError, setAmountError] = useState("");
    const formikForCreateOffer = useFormik({
      initialValues: {
        number: "",
        name: "",
        exp_month: "",
        exp_year: "",
        cvc: "",
        user_id: userData?.id,
        use_save_card: false,
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipcode: "",
        country: "",
        amount: 0,
        description: "",
      },
      enableReinitialize: true,
      ...(noActiveCard3 &&
        useCreditCard3 && {
          validationSchema: () => {
            return yup.object({
              name: yup.string().required("Please enter your name!"),
              number: yup
                .string()
                .min(16, "Card number must be only 16 characters")
                .max(16, "Card number must be only 16 characters")
                .required("Please enter your card number!"),
              exp_month: yup
                .string()
                .required("Please enter your card expiry month!"),
              exp_year: yup
                .string()
                .required("Please enter your card expiry year!"),
              cvc: yup
                .string()
                .min(3, "CVC must be at least 3 characters!")
                .max(4, "CVC can be only 4 characters!")
                .required("Please enter your card CVC!"),
              address1: yup.string().required("Street Address is required!"),
              address2: yup
                .string()
                .required("Apt or suite number is required!"),
              city: yup.string().required("City is required!"),
              zipcode: yup.string().required("Zip Code is required!"),
              state: yup.string().required("State is required!"),
            });
          },
        }),
      onSubmit: async () => {
        if (parseInt(formikForCreateOffer.values.amount) <= 0) {
          setAmountError("Amount cannot be less than or equal to zero.");
        } else {
          if (!useWallet3 && !useCreditCard3 && noActiveCard3) {
            toast.current.show({
              severity: "info",
              summary: "No payment method selected",
              detail: "Please select a payment method to proceed your booking!",
              life: 10000,
            });
          } else {
            //If user only wants to be charged through wallet
            if (useWallet3 && noActiveCard3 && !useCreditCard3) {
              const response = await createOfferApi({
                paymentMethod: "WALLET",
                booking_id: row?.IdForBooking,
                user_id: formikForCreateOffer.values.user_id,
                userid: formikForCreateOffer.values.user_id,
                //others
                bookingExtraPaymentid: row?.id,
                amount: parseInt(formikForCreateOffer.values.amount),
                description: formikForCreateOffer.values.description,
                userId: userData?.id,
              });
              if (response.status === 201) {
                toast.current.show({
                  severity: "success",
                  summary: "Booking Update Request Accepted!",
                  detail: "Booking update request accepted!",
                  life: 10000,
                });
                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              } else {
                toast.current.show({
                  severity: "error",
                  summary: "Cannot Accept Request",
                  detail: `There is an error accepting the request`,
                  life: 10000,
                });
              }
            } else if (noActiveCard3 && !useWallet3 && !useCreditCard3) {
              toast.current.show({
                severity: "error",
                summary: "No Payment method selected!",
                detail: "Please select a payment method to continue.",
                life: 10000,
              });
            }
            //If user only wants to be charged through exisiting card
            else if (card3 && !useWallet3 && !useCreditCard3) {
              const response = await createOfferApi({
                card_id: card3?.value?.id,
                paymentMethod: "CARD",
                booking_id: row?.IdForBooking,
                user_id: formikForCreateOffer.values.user_id,
                userid: formikForCreateOffer.values.user_id,
                //others
                bookingExtraPaymentid: row?.id,
                amount: parseInt(formikForCreateOffer.values.amount),
                description: formikForCreateOffer.values.description,
                userId: userData?.id,
              });
              if (response.status === 201) {
                toast.current.show({
                  severity: "success",
                  summary: "Booking Update Request Accepted!",
                  detail: "Booking update request accepted!",
                  life: 10000,
                });
                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              } else {
                toast.current.show({
                  severity: "error",
                  summary: "Cannot Accept Request",
                  detail: `There is an error accepting the request`,
                  life: 10000,
                });
              }
            }
            //If user only wants to be charged by adding new card
            else if (useCreditCard3 && !useWallet3 && noActiveCard3) {
              const response = await createOfferApi({
                name: formikForCreateOffer.values.name,
                number: formikForCreateOffer.values.number,
                exp_month: formikForCreateOffer.values.exp_month,
                exp_year: formikForCreateOffer.values.exp_year,
                cvc: formikForCreateOffer.values.cvc,
                address1: formikForCreateOffer.values.address1,
                address2: formikForCreateOffer.values.address2,
                city: formikForCreateOffer.values.city,
                state: formikForCreateOffer.values.state,
                zipcode: formikForCreateOffer.values.zipcode,
                country: formikForCreateOffer.values.country?.name,
                paymentMethod: "CARD",
                booking_id: row?.IdForBooking,
                user_id: formikForCreateOffer.values.user_id,
                userid: formikForCreateOffer.values.user_id,
                //others
                bookingExtraPaymentid: row?.id,
                amount: parseInt(formikForCreateOffer.values.amount),
                description: formikForCreateOffer.values.description,
                userId: userData?.id,
              });
              if (response.status === 201) {
                toast.current.show({
                  severity: "success",
                  summary: "Booking Update Request Accepted!",
                  detail: "Booking update request accepted!",
                  life: 10000,
                });
                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              } else {
                toast.current.show({
                  severity: "error",
                  summary: "Cannot Accept Request",
                  detail: `There is an error accepting the request`,
                  life: 10000,
                });
              }
            }
            //If user wants to be charged by through wallet + exisiting card
            else if (
              useWallet3 &&
              Object.keys(card3).length > 0 &&
              !useCreditCard3
            ) {
              const response = await createOfferApi({
                card_id: card3?.value?.id,
                paymentMethod: "CARD_PLUS_WALLET",
                booking_id: row?.IdForBooking,
                user_id: formikForCreateOffer.values.user_id,
                userid: formikForCreateOffer.values.user_id,
                //others
                bookingExtraPaymentid: row?.id,
                amount: parseInt(formikForCreateOffer.values.amount),
                description: formikForCreateOffer.values.description,
                userId: userData?.id,
              });
              if (response.status === 201) {
                toast.current.show({
                  severity: "success",
                  summary: "Booking Update Request Accepted!",
                  detail: "Booking update request accepted!",
                  life: 10000,
                });
                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              } else {
                toast.current.show({
                  severity: "error",
                  summary: "Cannot Accept Request",
                  detail: `There is an error accepting the request`,
                  life: 10000,
                });
              }
            }
            //If user wants to be charged through wallet + by adding new card
            else if (useWallet3 && useCreditCard3 && noActiveCard3) {
              const response = await createOfferApi({
                name: formikForCreateOffer.values.name,
                number: formikForCreateOffer.values.number,
                exp_month: formikForCreateOffer.values.exp_month,
                exp_year: formikForCreateOffer.values.exp_year,
                cvc: formikForCreateOffer.values.cvc,
                paymentMethod: "CARD_PLUS_WALLET",
                address1: formikForCreateOffer.values.address1,
                address2: formikForCreateOffer.values.address2,
                city: formikForCreateOffer.values.city,
                state: formikForCreateOffer.values.state,
                zipcode: formikForCreateOffer.values.zipcode,
                country: formikForCreateOffer.values.country?.name,
                booking_id: row?.IdForBooking,
                user_id: formikForCreateOffer.values.user_id,
                userid: formikForCreateOffer.values.user_id,
                //others
                bookingExtraPaymentid: row?.id,
                amount: parseInt(formikForCreateOffer.values.amount),
                description: formikForCreateOffer.values.description,
                userId: userData?.id,
              });
              if (response.status === 201) {
                toast.current.show({
                  severity: "success",
                  summary: "Booking Update Request Accepted!",
                  detail: "Booking update request accepted!",
                  life: 10000,
                });
                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              } else {
                toast.current.show({
                  severity: "error",
                  summary: "Cannot Accept Request",
                  detail: `There is an error accepting the request`,
                  life: 10000,
                });
              }
            }
          }
        }
      },
    });
    //Set wallet usage func
    const setUseWalletFunc = () => {
      if (wallet && parseInt(wallet?.amount) > parseInt(row?.amount)) {
        setUseWallet(!useWallet);
      } else {
        setWalletLessAmountError(
          "You dont have enough balance in wallet! Please consider topping up the wallet!"
        );
      }
    };
    const setUseWalletFunc2 = () => {
      if (wallet && parseInt(wallet?.amount) > parseInt(off?.amount)) {
        setUseWallet2(!useWallet2);
      } else {
        setWalletLessAmountError(
          "You dont have enough balance in wallet! Please consider topping up the wallet!"
        );
      }
    };
    const setUseWalletFunc3 = () => {
      if (
        wallet &&
        parseInt(wallet?.amount) > parseInt(formikForCreateOffer.values?.amount)
      ) {
        setUseWallet3(!useWallet3);
      } else {
        setWalletLessAmountError(
          "You dont have enough balance in wallet! Please consider topping up the wallet!"
        );
      }
    };

    const descriptionElementRef2 = React.useRef(null);
    React.useEffect(() => {
      if (openDetailDialog) {
        const { current: descriptionElement } = descriptionElementRef2;
        if (descriptionElement !== null) {
          descriptionElement.focus();
        }
      }
    }, [openDetailDialog]);
    const descriptionElementRef3 = React.useRef(null);
    React.useEffect(() => {
      if (openDetailDialog2) {
        const { current: descriptionElement } = descriptionElementRef3;
        if (descriptionElement !== null) {
          descriptionElement.focus();
        }
      }
    }, [openDetailDialog2]);

    //set credit card add usage
    const setUseCreditCardFunc = () => {
      setUseCreditCard(!useCreditCard);
      setCard({});
    };
    const setUseCreditCardFunc2 = () => {
      setUseCreditCard2(!useCreditCard2);
      setCard2({});
    };
    const setUseCreditCardFunc3 = () => {
      setUseCreditCard3(!useCreditCard3);
      setCard3({});
    };

    React.useEffect(() => {
      if (wallet && parseInt(wallet?.amount) < parseInt(row?.amount)) {
        setUseWallet(false);
        setWalletLessAmountError(
          "You dont have enough balance in wallet! Please consider topping up the wallet!"
        );
      }
    }, [wallet?.amount, row?.amount, wallet]);
    React.useEffect(() => {
      if (wallet && parseInt(wallet?.amount) < parseInt(off?.amount)) {
        setUseWallet2(false);
        setWalletLessAmountError2(
          "You dont have enough balance in wallet! Please consider topping up the wallet!"
        );
      }
    }, [wallet?.amount, off?.amount, wallet]);
    React.useEffect(() => {
      if (
        wallet &&
        parseInt(wallet?.amount) < parseInt(formikForCreateOffer.values?.amount)
      ) {
        setUseWallet3(false);
        setWalletLessAmountError3(
          "You dont have enough balance in wallet! Please consider topping up the wallet!"
        );
      }
    }, [wallet?.amount, formikForCreateOffer.values?.amount, wallet]);
    return (
      <React.Fragment>
        {/* confirmation dialogs */}
        <ConfirmationDialog
          message='Are you sure you want to proceed?'
          header='Confirmation'
          icon='pi pi-exclamation-triangle'
          open={showRejectOffer}
          onHide={() => setShowRejectOffer(false)}
          onConfirm={rejectOffer}
          confirmButtonColor='success'
          confirmButtonLoading={false}
        />
        <ConfirmationDialog
          message='Are you sure you want to proceed?'
          header='Confirmation'
          icon='pi pi-exclamation-triangle'
          open={showAcceptOffer}
          onHide={() => setShowAcceptOffer(false)}
          onConfirm={acceptOfferWithoutPaying}
          confirmButtonColor='success'
          confirmButtonLoading={false}
        />
        <ConfirmationDialog
          message='Are you sure you want to proceed?'
          header='Confirmation'
          icon='pi pi-exclamation-triangle'
          open={showWithdrawExtraPayReq}
          onHide={() => setShowWithdrawExtraPayReq(false)}
          onConfirm={withdrawOffer}
          confirmButtonColor='success'
          confirmButtonLoading={false}
        />
        <PaymentModalForExtraPayments
          open={openDetailDialog}
          handleClose={handleCloseDetailDialog}
          booking={row?.data?.booking}
          scroll={"body"}
          descriptionElementRef={descriptionElementRef2}
          header='Confirmation'
          guestAccept={formik.handleSubmit}
          detailType='REQUESTS'
          formik={formik}
          data={row?.data}
          toast={toast}
          wallet={wallet}
          useWallet={useWallet}
          setUseWalletFunc={setUseWalletFunc}
          setUseCreditCardFunc={setUseCreditCardFunc}
          walletLessAmountError={walletLessAmountError}
          userCards={userCards}
          card={card}
          setCard={setCard}
          useCreditCard={useCreditCard}
        />
        <PaymentModalForExtraPayments
          open={openDetailDialog2}
          handleClose={handleCloseDetailDialog2}
          descriptionElementRef={descriptionElementRef3}
          header='Confirmation'
          formik={formikForAcceptOffer}
          data={row?.data}
          toast={toast}
          wallet={wallet}
          useWallet={useWallet2}
          setUseWalletFunc={setUseWalletFunc2}
          setUseCreditCardFunc={setUseCreditCardFunc2}
          walletLessAmountError={walletLessAmountError2}
          userCards={userCards}
          card={card2}
          setCard={setCard2}
          useCreditCard={useCreditCard2}
          offerId={off?.offerid}
          reject={rejectOffer}
        />
        <PaymentModalForCreateOffer
          open={dialog}
          handleClose={handleClose}
          descriptionElementRef={descriptionElementRef}
          header='Confirmation'
          formik={formikForCreateOffer}
          data={row?.data}
          toast={toast}
          wallet={wallet}
          useWallet={useWallet3}
          setUseWalletFunc={setUseWalletFunc3}
          setUseCreditCardFunc={setUseCreditCardFunc3}
          walletLessAmountError={walletLessAmountError3}
          userCards={userCards}
          card={card3}
          setCard={setCard3}
          useCreditCard={useCreditCard3}
          offerId={off?.offerid}
          reject={rejectOffer}
          amountError={amountError}
        />
        <CreateOfferDialog
          open={openCreateOffer}
          handleClose={() => setOpenCreateOffer(false)}
          descriptionElementRef={descriptionElementRefForCreateOffer}
          id={row.id}
        />
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>
            <IconButton
              aria-label='expand row'
              size='small'
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component='th' scope='row'>
            {row.paymentReason}
          </TableCell>
          <TableCell align='right'>
            {process.env.REACT_APP_CURRENCY} {row.amount}
          </TableCell>
          <TableCell align='right'>{row.paymentType}</TableCell>
          <TableCell align='right'>{row.bookingId}</TableCell>
          <TableCell align='right'>{row.status}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout='auto' unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography
                  variant='h6'
                  gutterBottom
                  component='div'
                  sx={{ fontFamily: "Jost", fontSize: "24px" }}
                >
                  More Details
                </Typography>
                <Box
                  sx={{
                    borderTop: "1px solid rgba(81, 81, 81, 1)",
                    padding: "20px 0px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      fontFamily: "Jost",
                      fontSize: "24px",
                    }}
                  >
                    {value === "2" ? "Sent Date" : "Received Date"}
                  </Typography>
                  <Typography
                    component='p'
                    sx={{ fontSize: "14px", color: "#fff" }}
                  >
                    {moment(row.date).format("DD MMM, YYYY")}
                  </Typography>
                </Box>
                {/* attachment */}
                <Box
                  sx={{
                    borderTop: "1px solid rgba(81, 81, 81, 1)",
                    padding: "10px 0px",
                  }}
                >
                  <Typography
                    sx={{ color: "#fff", fontFamily: "Jost", fontSize: "24px" }}
                  >
                    Image Attachments
                  </Typography>
                  {row.attachments.length <= 0 ? (
                    <Typography component='p' sx={{ fontSize: "14px" }}>
                      No attachments added.
                    </Typography>
                  ) : (
                    <Box>
                      {row.attachments?.map((att, i) => {
                        return (
                          <img
                            src={att.attachmentURL}
                            key={att.attachmentKey}
                            alt={att.attachmentKey}
                            width='200'
                            height='200'
                            style={{ margin: "0px 10px", borderRadius: "10px" }}
                          />
                        );
                      })}
                    </Box>
                  )}
                </Box>
                <Box
                  sx={{
                    padding: "20px 0px",
                    borderTop: "1px solid rgba(81, 81, 81, 1)",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      fontFamily: "Jost",
                      fontSize: "24px",
                    }}
                  >
                    Messages
                  </Typography>
                  <Box
                    component='div'
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      border: "2px solid gray",
                      borderRadius: "12px",
                      padding: "4px",
                      background: "#1b182b",
                    }}
                  >
                    {messages?.length === 0 && (
                      <Typography sx={{ padding: "8px" }}>
                        There are so messages...
                      </Typography>
                    )}
                    {messages?.map((message, i) => {
                      return (
                        <React.Fragment key={i}>
                          {/* counter message */}
                          {!(message?.senderId === userData?.id) && (
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                padding: "12px",
                                justifyContent: "start",
                                alignItems: "center",
                              }}
                              key={i}
                            >
                              <Box
                                sx={{
                                  display: "inline-flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <img
                                  src={
                                    row?.claimer?.profilePicture ||
                                    require("../../../assets/images/avatar/5.jpg")
                                  }
                                  style={{
                                    borderRadius: "50%",
                                    width: "50px",
                                    height: "50px",
                                    marginRight: "12px",
                                  }}
                                  alt=''
                                />
                                <Box
                                  sx={{
                                    background: "gray",
                                    display: "inline",
                                    padding: "8px",
                                    borderRadius: "4px",
                                  }}
                                >
                                  <span>{message?.message}</span>
                                </Box>
                              </Box>
                            </Box>
                          )}
                          {/* your message */}
                          {message?.senderId === userData?.id && (
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                padding: "12px",
                                justifyContent: "end",
                                alignItems: "center",
                              }}
                              key={i}
                            >
                              <Box
                                sx={{
                                  display: "inline-flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  flexDirection: "row-reverse",
                                }}
                              >
                                <img
                                  src={
                                    userData?.profilePicture ||
                                    require("../../../assets/images/avatar/5.jpg")
                                  }
                                  style={{
                                    borderRadius: "50%",
                                    width: "50px",
                                    height: "50px",
                                    marginLeft: "12px",
                                  }}
                                  alt=''
                                />
                                <Box
                                  sx={{
                                    background: "gray",
                                    display: "inline",
                                    padding: "8px",
                                    borderRadius: "4px",
                                  }}
                                >
                                  <span>{message?.message}</span>
                                </Box>
                              </Box>
                            </Box>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </Box>
                  {/* message field */}
                  <Box>
                    <textarea
                      placeholder='Type Message'
                      style={{
                        color: "#ffffffb3",
                        width: "100%",
                        marginTop: "8px",
                        borderRadius: "10px",
                        background: "#292e3a",
                      }}
                      onChange={(e) => setMsgVal(e.target.value)}
                      onKeyPress={(e) =>
                        e.key == "Enter" ? handleSendMessage() : null
                      }
                      value={msgVal}
                    >
                      {msgVal}
                    </textarea>
                    <small
                      id='username2-help'
                      className='p-error'
                      style={{
                        display: "block",
                        color: "red",
                        textAlign: "left",
                      }}
                    >
                      {msgErr}
                    </small>
                  </Box>
                  {/* send button */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "end",
                    }}
                  >
                    <Buttons
                      title={"Send"}
                      variant='contained'
                      style={{ width: "15%" }}
                      isLoading={false}
                      onSubmit={handleSendMessage}
                    />
                  </Box>
                </Box>

                {value === "2" && off && (
                  <Box
                    sx={{
                      borderTop: "1px solid rgba(81, 81, 81, 1)",
                      padding: "10px 0px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#fff",
                        fontFamily: "Jost",
                        fontSize: "24px",
                        marginTop: "20px",
                      }}
                    >
                      Counter Offer
                    </Typography>
                    <Box>
                      <Box sx={{ display: "flex" }}>
                        <Typography
                          sx={{
                            marginRight: "6px",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          Amount:
                        </Typography>
                        <Typography sx={{ fontSize: "16px" }}>
                          {process.env.REACT_APP_CURRENCY} {off?.amount}
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex" }}>
                        <Typography
                          sx={{
                            marginRight: "6px",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          Message:
                        </Typography>
                        <Typography sx={{ fontSize: "16px" }}>
                          {off?.description}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "end",
                        }}
                      >
                        <Buttons
                          isLoading={false}
                          variant={"outlined"}
                          title={"Accept"}
                          style={{
                            width: "8%",
                            marginRight: "6px",
                            background: "green",
                            padding: "4px 6px",
                          }}
                          onSubmit={
                            sentHaveToAddPaymentDetailsForOffer
                              ? () => setOpenDetailDialog2(true)
                              : () => setShowAcceptOffer(true)
                          }
                        />
                        <Buttons
                          isLoading={false}
                          variant={"outlined"}
                          title={"Reject"}
                          style={{
                            width: "8%",
                            background: "red",
                            padding: "4px 6px",
                          }}
                          onSubmit={() => setShowRejectOffer(true)}
                        />
                      </Box>
                    </Box>
                  </Box>
                )}
                {value === "1" && (
                  <>
                    <Box sx={{ padding: "30px 0px" }}>
                      <Typography
                        sx={{
                          color: "#fff",
                          fontFamily: "Jost",
                          fontSize: "18px",
                        }}
                      >
                        Actions
                      </Typography>
                      <Box sx={{ display: "flex" }}>
                        <Buttons
                          isLoading={false}
                          variant={"contained"}
                          title={"Accept Request"}
                          style={{ width: "20%", background: "green" }}
                          onSubmit={() => setOpenDetailDialog(true)}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        padding: "15px 0px",
                        borderTop: "1px solid rgba(81, 81, 81, 1)",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontFamily: "Jost",
                          fontSize: "18px",
                          marginTop: "20px",
                        }}
                      >
                        Offers
                      </Typography>
                      <Box sx={{ display: "flex" }}>
                        <Buttons
                          isLoading={false}
                          variant={"contained"}
                          title={"Create Offer"}
                          style={{ width: "30%" }}
                          onSubmit={
                            receivedHaveToAddPaymentDetailsForOfferOrDirectly
                              ? () => setDialog(true)
                              : () => setOpenCreateOffer(true)
                          }
                        />
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
              {value === "2" && (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                    borderTop: "1px solid rgba(81, 81, 81, 1)",
                    padding: "10px 0px",
                  }}
                >
                  <Buttons
                    isLoading={false}
                    variant={"outlined"}
                    title={"Withdraw Request"}
                    style={{
                      width: "20%",
                      margin: "10px 0px",
                    }}
                    onSubmit={() => setShowWithdrawExtraPayReq(true)}
                  />
                </Box>
              )}
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
  const [openDialog, setOpenDialog] = React.useState(false);

  //booking-update-request
  function createDataBookingUpdateRequests(
    startDate,
    endDate,
    bookingId,
    requestStatus,
    ID,
    propertyTitle,
    data
  ) {
    return {
      startDate,
      endDate,
      bookingId,
      requestStatus,
      ID,
      propertyTitle,
      data,
    };
  }
  function BookingUpdateRequestRow(props) {
    const { row } = props;
    const [card, setCard] = React.useState({});
    const [userCards, setUserCards] = useState([]);
    const [useWallet, setUseWallet] = useState(true);
    const [useCreditCard, setUseCreditCard] = useState(false);
    const [walletLessAmountError, setWalletLessAmountError] = useState(false);
    const [wallet, setWallet] = useState({});
    const noActiveCard = Object.keys(card).length === 0;

    //===================pay dialog============//

    // //Get User Wallet Api
    useEffect(() => {
      const getWallet = async () => {
        const res = await getUserWalletApi(userData?.id);
        if (res.status === 404 || res.status === 401 || res.status === 403) {
          setWallet([]);
        } else {
          setWallet(res);
        }
      };
      getWallet();
    }, [userData?.id]);

    //Set use wallet to false if wallet doesnt exist or wallet amount is 0
    useEffect(() => {
      if (parseInt(wallet?.amount ? wallet?.amount : 0) <= 0) {
        setUseWallet(false);
      } else if (parseInt(wallet?.amount ? wallet?.amount : 0) > 0) {
        setUseWallet(true);
      }
    }, [wallet?.amount]);

    //Get User Cards
    useEffect(() => {
      const getUserCards = async () => {
        const res = await getUserCardApi(userData?.id);
        let temp = [];
        res?.data.forEach((item) => {
          return temp.push({
            value: item,
            label: item.last4digit,
            brand: item.brand,
          });
        });
        setUserCards(temp);
      };
      getUserCards();
    }, [userData?.id]);

    //===================pay dialog============//
    const userHost = JSON.parse(localStorage.getItem("userHost"));

    const approve = async () => {
      if (!userHost) {
        setOpenDetailDialog(true);
      } else {
        setOpenDetailDialog(true);
      }
    };

    const hostApprove = async () => {
      const response = await actionBookingUpdateRequest(
        {
          requestStatus: "APPROVED",
          userId: userData?.id,
          user_id: userData?.id,
        },
        row?.ID
      );
      if (response.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Booking Update Request Accepted!",
          detail: "Booking update request accepted!",
          life: 10000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Cannot Accept Request",
          detail: `${response?.data?.message}`,
          life: 10000,
        });
      }
    };
    const reject = async () => {
      const res = await actionBookingUpdateRequest(
        { requestStatus: "REJECTED", userId: userData?.id },
        row?.ID
      );
      if (!(res.status === 400)) {
        toast.current.show({
          severity: "info",
          summary: "Rejected",
          detail: "Booking Update Request rejected!",
          life: 10000,
        });
        setTimeout(() => {
          window.location = window.location.search;
        }, 1500);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Failure!",
          detail: `${res?.data?.message}`,
          life: 10000,
        });
      }
    };
    //set credit card add usage
    const setUseCreditCardFunc = () => {
      setUseCreditCard(!useCreditCard);
      setCard({});
    };

    const formik = useFormik({
      initialValues: {
        number: "",
        name: "",
        exp_month: "",
        exp_year: "",
        cvc: "",
        user_id: userData?.id,
        use_save_card: false,
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipcode: "",
        country: "",
      },
      enableReinitialize: true,
      ...(noActiveCard &&
        useCreditCard && {
          validationSchema: () => {
            return yup.object({
              name: yup.string().required("Please enter your name!"),
              number: yup
                .string()
                .min(16, "Card number must be only 16 characters")
                .max(16, "Card number must be only 16 characters")
                .required("Please enter your card number!"),
              exp_month: yup
                .string()
                .required("Please enter your card expiry month!"),
              exp_year: yup
                .string()
                .required("Please enter your card expiry year!"),
              cvc: yup
                .string()
                .min(3, "CVC must be at least 3 characters!")
                .max(4, "CVC can be only 4 characters!")
                .required("Please enter your card CVC!"),
              address1: yup.string().required("Street Address is required!"),
              address2: yup
                .string()
                .required("Apt or suite number is required!"),
              city: yup.string().required("City is required!"),
              zipcode: yup.string().required("Zip Code is required!"),
              state: yup.string().required("State is required!"),
            });
          },
        }),
      onSubmit: async () => {
        if (!useWallet && !useCreditCard && noActiveCard) {
          toast.current.show({
            severity: "info",
            summary: "No payment method selected",
            detail: "Please select a payment method to proceed your booking!",
            life: 10000,
          });
        } else {
          //If user only wants to be charged through wallet
          if (useWallet && noActiveCard && !useCreditCard) {
            const response = await actionBookingUpdateRequest(
              {
                userId: formik.values.user_id,
                paymentMethod: "WALLET",
                requestStatus: "APPROVED",
              },
              row?.ID
            );
            if (response.status === 200) {
              toast.current.show({
                severity: "success",
                summary: "Booking Update Request Accepted!",
                detail: "Booking update request accepted!",
                life: 10000,
              });
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            } else {
              toast.current.show({
                severity: "error",
                summary: "Cannot Accept Request",
                detail: `${response?.data?.message}`,
                life: 10000,
              });
            }
          } else if (noActiveCard && !useWallet && !useCreditCard) {
            toast.current.show({
              severity: "error",
              summary: "No Payment method selected!",
              detail: "Please select a payment method to continue.",
              life: 10000,
            });
          }
          //If user only wants to be charged through exisiting card
          else if (card && !useWallet && !useCreditCard) {
            const response = await actionBookingUpdateRequest(
              {
                userId: formik.values.user_id,
                card_id: card?.value?.id,
                paymentMethod: "CARD",
                requestStatus: "APPROVED",
              },
              row?.ID
            );
            if (response.status === 200) {
              toast.current.show({
                severity: "success",
                summary: "Booking Update Request Accepted!",
                detail: "Booking update request accepted!",
                life: 10000,
              });
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            } else {
              toast.current.show({
                severity: "error",
                summary: "Cannot Accept Request",
                detail: `${response?.data?.message}`,
                life: 10000,
              });
            }
          }
          //If user only wants to be charged by adding new card
          else if (useCreditCard && !useWallet && noActiveCard) {
            const response = await actionBookingUpdateRequest(
              {
                name: formik.values.name,
                number: formik.values.number,
                exp_month: formik.values.exp_month,
                exp_year: formik.values.exp_year,
                cvc: formik.values.cvc,
                userId: formik.values.user_id,
                address1: formik.values.address1,
                address2: formik.values.address2,
                city: formik.values.city,
                state: formik.values.state,
                zipcode: formik.values.zipcode,
                country: formik.values.country?.name,
                paymentMethod: "CARD",
                requestStatus: "APPROVED",
              },
              row?.ID
            );
            if (response.status === 200) {
              toast.current.show({
                severity: "success",
                summary: "Booking Update Request Accepted!",
                detail: "Booking update request accepted!",
                life: 10000,
              });
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            } else {
              toast.current.show({
                severity: "error",
                summary: "Cannot Accept Request",
                detail: `${response?.data?.message}`,
                life: 10000,
              });
            }
          }
          //If user wants to be charged by through wallet + exisiting card
          else if (
            useWallet &&
            Object.keys(card).length > 0 &&
            !useCreditCard
          ) {
            const response = await actionBookingUpdateRequest(
              {
                userId: formik.values.user_id,
                card_id: card?.value?.id,
                paymentMethod: "CARD_PLUS_WALLET",
                requestStatus: "APPROVED",
              },
              row?.ID
            );
            if (response.status === 200) {
              toast.current.show({
                severity: "success",
                summary: "Booking Update Request Accepted!",
                detail: "Booking update request accepted!",
                life: 10000,
              });
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            } else {
              toast.current.show({
                severity: "error",
                summary: "Cannot Accept Request",
                detail: `${response?.data?.message}`,
                life: 10000,
              });
            }
          }
          //If user wants to be charged through wallet + by adding new card
          else if (useWallet && useCreditCard && noActiveCard) {
            const response = await actionBookingUpdateRequest(
              {
                name: formik.values.name,
                number: formik.values.number,
                exp_month: formik.values.exp_month,
                exp_year: formik.values.exp_year,
                cvc: formik.values.cvc,
                userId: formik.values.user_id,
                paymentMethod: "CARD_PLUS_WALLET",
                address1: formik.values.address1,
                address2: formik.values.address2,
                city: formik.values.city,
                state: formik.values.state,
                zipcode: formik.values.zipcode,
                country: formik.values.country?.name,
                requestStatus: "APPROVED",
              },
              row?.ID
            );
            if (response.status === 200) {
              toast.current.show({
                severity: "success",
                summary: "Booking Update Request Accepted!",
                detail: "Booking update request accepted!",
                life: 10000,
              });
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            } else {
              toast.current.show({
                severity: "error",
                summary: "Cannot Accept Request",
                detail: `${response?.data?.message}`,
                life: 10000,
              });
            }
          }
        }
      },
    });
    //Set wallet usage func
    const setUseWalletFunc = () => {
      if (wallet && parseInt(wallet?.amount) > 0) {
        setUseWallet(!useWallet);
      } else {
        setWalletLessAmountError(
          "You dont have enough balance in wallet! Please consider topping up the wallet!"
        );
      }
    };

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
      if (openDialog) {
        const { current: descriptionElement } = descriptionElementRef;
        if (descriptionElement !== null) {
          descriptionElement.focus();
        }
      }
    }, [openDialog]);

    const [openDetailDialog, setOpenDetailDialog] = useState(false);

    const handleCloseDetailDialog = () => {
      setOpenDetailDialog(false);
    };

    const type =
      userData?.id === row?.data?.booking?.owner?.id && userHost
        ? "host"
        : "traveler";
    return (
      <React.Fragment>
        {/* Confirmation Dialogs  */}

        <ReservationDetailForModificationRequest
          open={openDetailDialog}
          handleClose={handleCloseDetailDialog}
          booking={row?.data?.booking}
          scroll={"body"}
          descriptionElementRef={descriptionElementRef}
          header='Confirmation'
          type={type}
          guestAccept={formik.handleSubmit}
          reject={reject}
          hostApprove={hostApprove}
          detailType='REQUESTS'
          formik={formik}
          data={row?.data}
          toast={toast}
          wallet={wallet}
          useWallet={useWallet}
          setUseWalletFunc={setUseWalletFunc}
          setUseCreditCardFunc={setUseCreditCardFunc}
          walletLessAmountError={walletLessAmountError}
          userCards={userCards}
          card={card}
          setCard={setCard}
          useCreditCard={useCreditCard}
        />
        {/* Dialog */}
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell></TableCell>

          <TableCell align='left'>
            {moment(row.startDate).format("DD MMM YYYY")} -{" "}
            {moment(row.endDate).format("DD MMM YYYY")}
          </TableCell>
          <TableCell align='left'>{row.propertyTitle?.slice(0, 35)}</TableCell>
          <TableCell align='right'>{row.bookingId}</TableCell>
          <TableCell align='right'>{row.requestStatus}</TableCell>
          <TableCell align='right'>
            {" "}
            <Buttons
              isLoading={false}
              variant={"contained"}
              title={"View Details"}
              style={{ width: "100%" }}
              onSubmit={approve}
            />
          </TableCell>
        </TableRow>
        <TableRow></TableRow>
      </React.Fragment>
    );
  }
  function createDataBookingInvitations(
    startDate,
    endDate,
    propertyTitle,
    totalAmount,
    hostName,
    actions
  ) {
    return {
      startDate,
      endDate,
      propertyTitle,
      totalAmount,
      hostName,
      actions,
    };
  }
  function BookingInvitationsRow(props) {
    const { row } = props;

    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell></TableCell>
          <TableCell align='left'>
            {moment(row.startDate).format("DD MMM YYYY")}
          </TableCell>
          <TableCell align='right'>
            {moment(row.endDate).format("DD MMM YYYY")}
          </TableCell>
          <TableCell align='right'>{row.propertyTitle}</TableCell>
          <TableCell align='right'>{row.totalAmount}</TableCell>
          <TableCell align='right'>{row.hostName}</TableCell>
          <TableCell align='right'>{row.actions}</TableCell>
        </TableRow>
        <TableRow></TableRow>
      </React.Fragment>
    );
  }

  const [rows, setRows] = useState([]);
  const [rows2, setRows2] = useState([]);
  const [rows3, setRows3] = useState([]);
  const [rows4, setRows4] = useState([]);
  const [notify, setNotifiy] = useState({ isActive: false, message: "" });
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    let rowsI = [];
    let rowsII = [];
    let rowsIII = [];
    let rowsIV = [];

    const getRows = () => {
      if (userType === "true") {
        additionalPayments?.forEach((item) => {
          if (
            item.RequestedFrom === "GUEST" &&
            item.booking.ownerId === userData?.id
          ) {
            rowsI.push(
              createData(
                item.PaymentReason === "DAMAGE"
                  ? "Damage, Missing Items, or Unexpected Cleaning"
                  : "Extra Services",
                item.Amount,
                item.PaymentType === "CLAIM" ? "Claim" : "Extra Payment",
                item.booking.code,
                item.isResolved ? "Accepted" : "Pending",
                item.Description,
                item.attachments,
                item.id,
                item.createdOn,
                item.booking.id,
                item?.claimer
              )
            );
            setRows(rowsI);
          }
        });
      } else if (userType === "false") {
        additionalPayments?.forEach((item) => {
          if (
            item.RequestedFrom === "HOST" &&
            item.booking.guestId === userData?.id
          ) {
            rowsI.push(
              createData(
                item.PaymentReason === "DAMAGE"
                  ? "Damage, Missing Items, or Unexpected Cleaning"
                  : "Extra Services",
                item.Amount,
                item.PaymentType === "CLAIM" ? "Claim" : "Extra Payment",
                item.booking.code,
                item.isResolved ? "Accepted" : "Pending",
                item.Description,
                item.attachments,
                item.id,
                item.createdOn,
                item.booking.id,
                item?.claimer
              )
            );
            setRows(rowsI);
          }
        });
      }
    };

    const getRowsTab2 = () => {
      if (userType === "true") {
        additionalPayments?.forEach((item) => {
          if (
            item.RequestedFrom === "HOST" &&
            item.booking.ownerId === userData?.id
          ) {
            rowsII.push(
              createData(
                item.PaymentReason === "DAMAGE"
                  ? "Damage, Missing Items, or Unexpected Cleaning"
                  : "Extra Services",
                item.Amount,
                item.PaymentType === "CLAIM" ? "Claim" : "Extra Payment",
                item.booking.code,
                item.isResolved ? "Accepted" : "Pending",
                item.Description,
                item.attachments,
                item.id,
                item.createdOn,
                item.booking.id,
                item?.claimer
              )
            );
            setRows2(rowsII);
          } else {
            setNotifiy({
              isActive: true,
              message: "You have pending request on the travel side",
            });
          }
        });
      } else if (userType === "false") {
        additionalPayments?.forEach((item) => {
          if (
            item.RequestedFrom === "GUEST" &&
            item.booking.guestId === userData?.id
          ) {
            rowsII.push(
              createData(
                item.PaymentReason === "DAMAGE"
                  ? "Damage, Missing Items, or Unexpected Cleaning"
                  : "Extra Services",
                item.Amount,
                item.PaymentType === "CLAIM" ? "Claim" : "Extra Service",
                item.booking.code,
                item.isResolved ? "Accepted" : "Pending",
                item.Description,
                item.attachments,
                item.id,
                item.createdOn,
                item.booking.id,
                item?.claimer
              )
            );
            setRows2(rowsII);
          } else {
            setNotifiy({
              isActive: true,
              message: "You have pending request on the host  side",
            });
          }
        });
      }
    };

    const getRowsTab3 = () => {
      bookingUpdateRequests?.forEach((item) => {
        if (userHostLocal) {
          if (item.booking.ownerId === userData?.id) {
            rowsIII.push(
              createDataBookingUpdateRequests(
                item.startDate,
                item.endDate,
                item.booking.code,
                item.requestStatus,
                item.id,
                item.booking?.property.title,
                item
              )
            );
            setRows3(rowsIII);
          }
        } else {
          if (item.booking.guestId === userData?.id) {
            rowsIII.push(
              createDataBookingUpdateRequests(
                item.startDate,
                item.endDate,
                item.booking.code,
                item.requestStatus,
                item.id,
                item.booking?.property.title,
                item
              )
            );
            setRows3(rowsIII);
          }
        }
      });
    };
    const getRowsTab4 = () => {
      bookingInvitations?.forEach((item) => {
        rowsIV.push(
          createDataBookingInvitations(
            item.startDate,
            item.endDate,
            item.property?.title,
            `${
              process.env.REACT_APP_CURRENCY
            } ${item.grossAmount?.toLocaleString("en-US")}`,
            item.property?.user?.firstName +
              ` ${item.property?.user?.lastName}`,
            <>
              <Buttons
                isLoading={false}
                title={"View Details"}
                onSubmit={() =>
                  navigate(
                    `/book/${item.property?.id}?startDate=${
                      item.startDate
                    }&endDate=${item.endDate}&noOfGuests=${
                      item.totalGuests
                    }&noOfInfants=${item?.totalInfants}&noOfPets=${
                      item.totalPets
                    }&noOfAdults=${item.totalAdults}&noOfChildren=${
                      item.totalPets
                    }&ownerId=${item.property?.userId}&propertyAddress=${
                      item.property?.address
                    }&code=${item.property?.code}&bookingId=${
                      item.id
                    }&checkInTime=${
                      item.property?.propertyPolicy
                        ? item?.property?.propertyPolicy?.checkInWindow
                        : moment().format("mm:hh a")
                    }&checkOutTime=${
                      item.property?.propertyPolicy
                        ? item?.property?.propertyPolicy?.checkoutWindow
                        : "11:00 a.m"
                    }`
                  )
                }
              />
            </>
          )
        );
        setRows4(rowsIV);
      });
    };
    getRows();
    getRowsTab2();
    getRowsTab3();
    getRowsTab4();

    if (notify.isActive) {
      enqueueSnackbar(notify.message, {
        variant: "info",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
  }, [
    additionalPayments,
    userType,
    bookingUpdateRequests,
    userData?.id,
    bookingInvitations,
    notify.isActive,
  ]);

  return (
    <section>
      <Header />
      <div id='wrapper'>
        <div className='dashboard-menu-overlay' />
        <Sidebar page={"Manage Requests"} />
        <Toast ref={toast} position='bottom-right' />

        <div className='dashboard-content' style={{ marginTop: "-80px" }}>
          <Box>
            <Box
              sx={{
                width: "100%",
                padding: "1% 0px",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  // width: "100%",
                  fontSize: "25px",
                  fontFamily: "Jost",
                  color: "#fff",
                  padding: "10px 0px",
                }}
              >
                Manage Requests
                <div style={{ fontSize: "16px", color: "#878c9f" }}>
                  You can manage your all requests here!
                </div>
              </Typography>
              {userData && !userData?.isHost ? (
                ""
              ) : userType === "true" ? (
                <div
                  className='show-reg-form'
                  style={{
                    border: "1px solid #eee",
                    lineHeight: "55px",
                    height: "55px",
                    marginLeft: "15px",
                    borderColor: "rgba(255, 255, 255, 0.1)",
                    borderRadius: "5px",
                  }}
                  onClick={() => {
                    userType === "true"
                      ? localStorage.setItem("userHost", false)
                      : localStorage.setItem("userHost", true);
                    setTimeout(() => {
                      navigate("/");
                    }, 1500);
                  }}
                >
                  <i
                  // className="fas fa-random"
                  >
                    <svg
                      width='20'
                      height='13'
                      viewBox='0 0 26 19'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g clipPath='url(#clip0_110_2)'>
                        <path
                          d='M19.0097 0.219315L22.3151 3.58929L18.9451 6.89472L17.952 5.88222L19.5936 4.27215L7.69127 4.15706C6.51833 4.14572 5.55483 5.09076 5.54349 6.26371C5.53215 7.43665 6.47719 8.40015 7.65013 8.41149L18.7116 8.51845C20.6665 8.53735 22.2416 10.1431 22.2227 12.0981C22.2038 14.053 20.598 15.6281 18.6431 15.6092L6.74075 15.4941L8.35086 17.1356L7.3384 18.1287L4.033 14.7587L7.40295 11.4532L8.39602 12.4657L6.75447 14.0759L18.6568 14.191C19.8297 14.2023 20.7932 13.2573 20.8046 12.0843C20.8159 10.9114 19.8709 9.94789 18.6979 9.93655L7.63642 9.82959C5.68151 9.81069 4.10644 8.2049 4.12535 6.24996C4.14425 4.29501 5.75007 2.71998 7.70498 2.73888L19.6073 2.85397L17.9972 1.21246L19.0097 0.219315Z'
                          fill='#fff'
                        />
                      </g>
                      <circle
                        r='2'
                        transform='matrix(-1 0 0 1 24 3.99988)'
                        fill='#FA1414'
                      />
                      <circle
                        r='2'
                        transform='matrix(-1 0 0 1 2 14.9999)'
                        fill='#FA1414'
                      />
                      <defs>
                        <clipPath id='clip0_110_2'>
                          <rect
                            width='18.1732'
                            height='18.1732'
                            fill='white'
                            transform='matrix(0.00966894 -0.999953 -0.999953 -0.00966894 22.1723 18.348)'
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </i>
                  <Link
                    to='/'
                    className=''
                    style={{
                      textDecoration: "none",
                      cursor: "pointer",
                      fontSize: 15,
                      color: "inherit",
                    }}
                  >
                    <span style={{ fontSize: 15 }}>Switch to Traveler</span>
                  </Link>
                </div>
              ) : (
                <div
                  className='show-reg-form'
                  style={{
                    border: "1px solid #eee",
                    lineHeight: "55px",
                    height: "55px",
                    marginLeft: "15px",
                    borderColor: "rgba(255, 255, 255, 0.1)",
                    borderRadius: "5px",
                  }}
                  onClick={() => {
                    userType === "true"
                      ? localStorage.setItem("userHost", false)
                      : localStorage.setItem("userHost", true);

                    window.location.reload();
                  }}
                >
                  <i
                  // className="fas fa-random"
                  >
                    <svg
                      width='20'
                      height='13'
                      viewBox='0 0 26 19'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g clipPath='url(#clip0_110_2)'>
                        <path
                          d='M19.0097 0.219315L22.3151 3.58929L18.9451 6.89472L17.952 5.88222L19.5936 4.27215L7.69127 4.15706C6.51833 4.14572 5.55483 5.09076 5.54349 6.26371C5.53215 7.43665 6.47719 8.40015 7.65013 8.41149L18.7116 8.51845C20.6665 8.53735 22.2416 10.1431 22.2227 12.0981C22.2038 14.053 20.598 15.6281 18.6431 15.6092L6.74075 15.4941L8.35086 17.1356L7.3384 18.1287L4.033 14.7587L7.40295 11.4532L8.39602 12.4657L6.75447 14.0759L18.6568 14.191C19.8297 14.2023 20.7932 13.2573 20.8046 12.0843C20.8159 10.9114 19.8709 9.94789 18.6979 9.93655L7.63642 9.82959C5.68151 9.81069 4.10644 8.2049 4.12535 6.24996C4.14425 4.29501 5.75007 2.71998 7.70498 2.73888L19.6073 2.85397L17.9972 1.21246L19.0097 0.219315Z'
                          fill='#fff'
                        />
                      </g>
                      <circle
                        r='2'
                        transform='matrix(-1 0 0 1 24 3.99988)'
                        fill='#FA1414'
                      />
                      <circle
                        r='2'
                        transform='matrix(-1 0 0 1 2 14.9999)'
                        fill='#FA1414'
                      />
                      <defs>
                        <clipPath id='clip0_110_2'>
                          <rect
                            width='18.1732'
                            height='18.1732'
                            fill='white'
                            transform='matrix(0.00966894 -0.999953 -0.999953 -0.00966894 22.1723 18.348)'
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </i>
                  <Link
                    to='/admin/add-property'
                    style={{
                      textDecoration: "none",
                      cursor: "pointer",
                      fontSize: 15,
                      color: "inherit",
                    }}
                  >
                    <span style={{ fontSize: 15 }}>Switch to Host</span>
                  </Link>
                </div>
              )}
            </Box>

            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label='lab API tabs example'
                >
                  <Tab
                    sx={{ fontSize: "14px" }}
                    label='Received Payment Requests'
                    value='1'
                  />
                  <Tab
                    sx={{ fontSize: "14px" }}
                    label='Sent Payment Requests'
                    value='2'
                  />
                  <Tab
                    sx={{ fontSize: "14px" }}
                    label='Booking Update Requests'
                    value='3'
                  />
                  <Tab
                    sx={{ fontSize: "14px" }}
                    label='Booking Invitations'
                    value='4'
                  />
                </TabList>
              </Box>
              <TabPanel value='1'>
                <>
                  <Grid
                    container
                    rowSpacing={{ xs: 1, sm: 1, md: 1 }}
                    columnSpacing={{ xs: 1, sm: 1, md: 2 }}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <Box>
                        <TableContainer
                          component={Paper}
                          sx={{ background: "#1f2d40" }}
                        >
                          <Table aria-label='collapsible table'>
                            <TableHead>
                              <TableRow>
                                <TableCell />
                                <TableCell>Payment Reason</TableCell>
                                <TableCell align='right'>Amount</TableCell>
                                <TableCell align='right'>
                                  Payment Type
                                </TableCell>
                                <TableCell align='right'>Booking ID</TableCell>
                                <TableCell align='right'>Status</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {rows.map((row) => (
                                <Row key={row.name} row={row} />
                              ))}
                              {rows.length < 1 && (
                                <Box
                                  sx={{
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    margin: "20px 0px",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "22px", color: "#fff" }}
                                  >
                                    No Requests!
                                  </Typography>
                                </Box>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </Grid>
                  </Grid>
                </>
              </TabPanel>
              <TabPanel value='2'>
                <>
                  <Grid
                    container
                    rowSpacing={{ xs: 1, sm: 1, md: 1 }}
                    columnSpacing={{ xs: 1, sm: 1, md: 2 }}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <Box>
                        <TableContainer
                          component={Paper}
                          sx={{ background: "#1f2d40" }}
                        >
                          <Table aria-label='collapsible table'>
                            <TableHead>
                              <TableRow>
                                <TableCell />
                                <TableCell>Payment Reason</TableCell>
                                <TableCell align='right'>Amount</TableCell>
                                <TableCell align='right'>
                                  Payment Type
                                </TableCell>
                                <TableCell align='right'>Booking ID</TableCell>
                                <TableCell align='right'>Status</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {rows2.map((row) => (
                                <Row key={row.name} row={row} />
                              ))}
                              {rows2.length < 1 && (
                                <Box
                                  sx={{
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    margin: "20px 0px",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "22px", color: "#fff" }}
                                  >
                                    No Requests!
                                  </Typography>
                                </Box>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </Grid>
                  </Grid>
                </>
              </TabPanel>
              <TabPanel value='3'>
                <>
                  <Grid
                    container
                    rowSpacing={{ xs: 1, sm: 1, md: 1 }}
                    columnSpacing={{ xs: 1, sm: 1, md: 2 }}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <Box>
                        <TableContainer
                          component={Paper}
                          sx={{ background: "#1f2d40" }}
                        >
                          <Table aria-label='collapsible table'>
                            <TableHead>
                              <TableRow>
                                <TableCell />
                                <TableCell align='left'>
                                  Booking Dates
                                </TableCell>
                                <TableCell align='left'>
                                  Property Title
                                </TableCell>
                                <TableCell align='right'>
                                  Booking Code
                                </TableCell>
                                <TableCell align='right'>Status</TableCell>
                                <TableCell align='right'>Actions</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {rows3.map((row) => (
                                <BookingUpdateRequestRow
                                  key={row.name}
                                  row={row}
                                />
                              ))}
                              {rows3.length === 0 && (
                                <Box
                                  sx={{
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    margin: "20px 0px",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "22px", color: "#fff" }}
                                  >
                                    No Requests!
                                  </Typography>
                                </Box>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </Grid>
                  </Grid>
                </>
              </TabPanel>
              <TabPanel value='4'>
                <>
                  <Grid
                    container
                    rowSpacing={{ xs: 1, sm: 1, md: 1 }}
                    columnSpacing={{ xs: 1, sm: 1, md: 2 }}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <Box>
                        <TableContainer
                          component={Paper}
                          sx={{ background: "#1f2d40" }}
                        >
                          <Table aria-label='collapsible table'>
                            <TableHead>
                              <TableRow>
                                <TableCell />
                                <TableCell align='left'>Start Date</TableCell>
                                <TableCell align='center'>End Date</TableCell>
                                <TableCell align='right'>
                                  Property Title
                                </TableCell>
                                <TableCell align='right'>
                                  Total Amount
                                </TableCell>
                                <TableCell align='right'>Host Name</TableCell>
                                <TableCell align='right'>Actions</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {rows4.map((row) => (
                                <BookingInvitationsRow
                                  key={row.name}
                                  row={row}
                                />
                              ))}
                              {rows4.length < 1 && (
                                <Box
                                  sx={{
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    margin: "20px 0px",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "22px", color: "#fff" }}
                                  >
                                    No Requests!
                                  </Typography>
                                </Box>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </Grid>
                  </Grid>
                </>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
    </section>
  );
};

export default PaymentRequestsPage;
