import { API_URL } from '../../config';
import axios from 'axios';

export const getCommissionFee = async () => {
  let options;
  options = {
    headers: {
      'Content-Type': 'application/json',
    },
    method: "GET",
  };
  return axios(
    API_URL + 'system-configuration/get-column?column=commission_percentage',
    options
  )
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      return error.response;
    });
};
