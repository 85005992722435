import { API_URL } from '../../config';
import axios from 'axios';

export const getTransactionHistoryApi = async (userId) => {
  try {
    const response = await axios.get(
      API_URL + `wallet/get-transactions/${userId}`
    );
    return response.data.data[0];
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log(
        'Bad Response of get user user transaction history : ',
        response
      );
      return response;
    }
  }
};
