import * as yup from 'yup';

//Basic Info Validation
export const BasicListingValidationSchema = yup.object({
  title: yup.string().required('Listing title is required'),
  customUrl: yup.string().nullable(),
  noOfAdults: yup
    .number()
    .required('Number of adults is required')
    .min(1, 'Number should be greater then 1'),
  noOfChildren: yup
    .number()
    .required('Number of children is required')
    .min(0, 'Number should be greater then 1'),
  noOfBedrooms: yup
    .number()
    .required('Number of bedrooms is required')
    .min(1, 'Number should be greater then 1'),
  noOfBathrooms: yup
    .number()
    .required('Number of bathrooms is required')
    .min(1, 'Number should be greater then 1'),
  noOfBeds: yup
    .string()
    .min(1, 'Number should be greater then 1')
    .required('Number of beds is required'),
  noOfInfants: yup.number().min(0, 'Number should be greater then 1'),
  noOfPets: yup.number().min(0, 'Number should be greater then 1'),
  // isPrivateBathroom: yup
  // .string()
  // .required("Private bathroom field is required."),
  zipCode: yup.string().required('Zipcode is required'),
  address: yup.string().required('Address is required'),
  country: yup.string().required('Country is required'),
  state: yup.string().required('State is required'),
  city: yup.string().required('City is required'),
});
