import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { experimentalStyled as styled } from '@mui/material/styles';
import * as React from 'react';
import { Link } from 'react-router-dom';
import CardContent from '@mui/material/CardContent';
import { RiGlobalLine } from 'react-icons/ri';
import { FaRegAddressCard } from 'react-icons/fa';
import { MdOutlineSecurity } from 'react-icons/md';
import { MdPayments } from 'react-icons/md';
import { AiOutlineNotification } from 'react-icons/ai';
import { AiOutlineEye } from 'react-icons/ai';
import Header from '../../../components/header';
import { SiYourtraveldottv } from 'react-icons/si';
import { CgCreditCard } from 'react-icons/cg';
import Tile from '../../../components/account/tile';

export default function Account() {
  const information = [
    {
      title: 'Personal Info',
      desc: 'Provide personal details and how we can reach you.',
      icon: <FaRegAddressCard style={{ color: '#0095ff', fontSize: '50px' }} />,
      link: '/admin/edit-profile',
    },
    {
      title: 'Login and security',
      desc: 'Update your password and secure account.',
      icon: (
        <MdOutlineSecurity style={{ color: '#0095ff', fontSize: '50px' }} />
      ),
      link: '/admin/login-and-security',
    },
    {
      title: 'Payments and Payouts',
      desc: 'Review payments, payouts, coupons, gift, card and taxes.',
      icon: <MdPayments style={{ color: '#0095ff', fontSize: '50px' }} />,
      link: '/admin/payment-payouts',
    },
    // {
    //   title: 'Notifications',
    //   desc: 'Choose notification and preferences and how you want to be.',
    //   icon: (
    //     <AiOutlineNotification style={{ color: '#0095ff', fontSize: '50px' }} />
    //   ),
    //   link: '/admin/edit-profile',
    // },
    // {
    //   title: 'Privacy and Sharing',
    //   desc: 'Control connected apps what you share, and who you see it.',
    //   icon: <AiOutlineEye style={{ color: '#0095ff', fontSize: '50px' }} />,
    //   link: '/admin/edit-profile',
    // },
    // {
    //   title: 'Global Preferences',
    //   desc: 'Set your default language, timezone and currency.',
    //   icon: <RiGlobalLine style={{ color: '#0095ff', fontSize: '50px' }} />,
    //   link: '/admin/edit-profile',
    // },

    // {
    //   title: 'Travel for Work',
    //   desc: 'Set your default language, timezone and currency.',
    //   icon: (
    //     <SiYourtraveldottv style={{ color: '#0095ff', fontSize: '50px' }} />
    //   ),
    //   link: '/admin/edit-profile',
    // },
    // {
    //   title: 'Professional Hosting Tools',
    //   desc: 'Get professioanl tools if you manage several properties on Staywo.com.',
    //   icon: <RiGlobalLine style={{ color: '#0095ff', fontSize: '50px' }} />,
    //   link: '/admin/edit-profile',
    // },
    // {
    //   title: 'Referral Credit & Coupon',
    //   desc: 'You have 0$ referral credits & coupons Learn more.',
    //   icon: <CgCreditCard style={{ color: '#0095ff', fontSize: '50px' }} />,
    //   link: '/admin/edit-profile',
    // },
  ];

  const user = JSON.parse(localStorage.getItem('userData')) || null;

  return (
    <div>
      <Header></Header>
      <Grid container sx={{ background: '#282c34', padding: '0px 50px' }}>
        <Grid item xs={12} sm={12} md={12}>
          <CardContent sx={{ textAlign: 'left', marginTop: 10 }}>
            <Typography
              sx={{ color: '#fff', fontSize: '40px', fontFamily: 'Jost' }}
            >
              <Link to="/admin/dashboard" style={{marginRight:10}}>

              <i class="fa-solid fa-less-than" style={{color:"white",fontSize:33}}></i>
              </Link>

              Account
            </Typography>
            <Typography
              className='text-white'
              sx={{ marginTop: 1, color: '#ffffff' }}
            >
              <span style={{ fontSize: '25px' }}>
                {user?.firstName} {user?.lastName},{' '}
              </span>
              <span style={{ fontSize: '18px' }}>{user?.email}. </span>
              {/* <Link
                to='/'
                className='text-white'
                style={{ textDecoration: 'underline', color: '#fff' }}
              >
                Go to profile
              </Link> */}
            </Typography>
          </CardContent>
        </Grid>
      </Grid>

      <Grid
        sx={{
          background: '#282c34',
          padding: '80px',
        }}
        container
        spacing={{ xs: 2, md: 2 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        {information.map((v, i) => (
          <Tile value={v} key={i} />
        ))}
      </Grid>
    </div>
  );
}
