import React from "react";
import ReactPlayer from "react-player";

const YoutubeVideo = ({ link }) => {
  return (
    <div className="video-responsive">
      <ReactPlayer
        url={link}
        width="100%"
        height="100%"
      />
    </div>
  );
};

export default YoutubeVideo;
