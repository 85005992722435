import Footer from '../../../components/footer';
import Header from '../../../components/header';
import NewsLetter from '../../../components/newsLetter';
import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { Paginator } from 'primereact/paginator';
import Property from '../../../components/mainComponents/property';
import { getPropertyApi } from '../../../services/properties/getProperty';
import ErrorMessage from '../../../components/errorMessage';
import SkeletonLoader from '../../../components/skeletonLoader';
import { useParams } from 'react-router-dom';
import getUserApi from '../../../services/User/getUser';
import { SettingsSuggestRounded } from '@mui/icons-material';

const HostDetailPage = () => {
  const [properties, setProperties] = useState([]);
  const [filteredProperty, setFilteredProperty] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [basicFirst, setBasicFirst] = useState(0);
  const [basicRows, setBasicRows] = useState(10);
  const [filterToggle, setFilterToggle] = useState(false);
  const [user, setUser] = useState({});

  const onBasicPageChange = (event) => {
    setBasicFirst(event.first);
    setBasicRows(event.rows);
  };

  function handleSearch(value) {
    if (value === '') {
      setFilteredProperty(properties);
      setFilterToggle(false);
    } else {
      const filtered = properties.filter((property) => {
        return property.title.toLowerCase().includes(value.toLowerCase());
      });
      setFilterToggle(true);
      setFilteredProperty(filtered);
    }
  }

  const getPropertyListings = async () => {
    try {
      const response = await getPropertyApi();
      if (response.status === 200) {
        setProperties(response.data.data);
        setFilteredProperty(response.data.data);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPropertyListings();
  }, []);

  const userData = JSON.parse(localStorage.getItem('userData')) || null;

  const getUserData = async () => {
    const data = await getUserApi(userData.email);
    setUser(data);
  };
  useEffect(() => {
    getUserData();
  }, []);

  return (
    <div>
      <Header />
      <div id="wrapper">
        <div className="content">
          {/* col-list-wrap */}
          <section className="gray-bg small-padding ">
            <div className="container">
              <div className="card-info smpar fl-wrap">
                <div className="box-widget-menu-btn smact">
                  <i className="far fa-ellipsis-h" />
                </div>
                <div className="show-more-snopt-tooltip bxwt">
                  <a href="#">
                    <i className="fas fa-comment-alt" /> Write a review
                  </a>
                  <a href="#">
                    {' '}
                    <i className="fas fa-exclamation-triangle" /> Report{' '}
                  </a>
                </div>
                <div className="bg-wrap bg-parallax-wrap-gradien">
                  <div className="bg" data-bg="images/bg/14.jpg" />
                </div>
                <div className="card-info-media">
                  <div className="bg" data-bg="images/agency/1.png" />
                </div>
                <div className="card-info-content">
                  <div className="agent_card-title fl-wrap">
                    <h4>Mavers RealEstate Agency</h4>
                    <div className="geodir-category-location fl-wrap">
                      <a href="#">
                        <i className="fas fa-map-marker-alt" />
                      </a>
                      <div
                        className="listing-rating card-popup-rainingvis"
                        data-starrating2={4}
                      >
                        <span className="re_stars-title">Good</span>
                      </div>
                    </div>
                  </div>
                  <div className="list-single-stats">
                    <ul className="no-list-style">
                      <li>
                        <span className="viewed-counter">
                          <i className="fas fa-eye" /> Viewed - 156{' '}
                        </span>
                      </li>
                      <li>
                        <span className="bookmark-counter">
                          <i className="fas fa-comment-alt" /> Reviews - 4{' '}
                        </span>
                      </li>
                      <li>
                        <span className="bookmark-counter">
                          <i className="fas fa-user-tag" /> Agents - 6{' '}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="card-verified tolt"
                    data-microtip-position="left"
                    data-tooltip="Verified"
                  >
                    <i className="fal fa-user-check" />
                  </div>
                </div>
              </div>
              <div className="row" style={{ width: '124vw' }}>
                <div className="col-md-8">
                  <div className="list-single-main-container fl-wrap">
                    {/* list-single-main-item */}
                    <div className="list-single-main-item fl-wrap">
                      <div className="list-single-main-item-title">
                        <h3 style={{ fontSize: '24px' }}>About This Host</h3>
                      </div>
                      <div className="list-single-main-item_content fl-wrap">
                        <p style={{ fontSize: '16px' }}>
                          Vivamus vel lacus lacinia, condimentum nunc non,
                          iaculis diam. Proin in mollis augue, eget fermentum
                          quam. Donec semper purus ut ante tempus gravida.
                          Quisque et ante orci. Sed venenatis turpis mi, non
                          varius justo scelerisque id. Maecenas fermentum nunc
                          sed maximus efficitur. Pellentesque nec turpis
                          viverra, cursus ex porta, porttitor odio. In gravida
                          ante nec eleifend commodo. Cras gravida interdum nisl,
                          vel venenatis nisl consectetur ut. Duis in erat quis
                          dui imperdiet pretium et eu purus. Fusce purus ex,
                          placerat et eros quis, lacinia tempor ligula.
                        </p>
                      </div>
                    </div>
                    {/* list-single-main-item end */}
                  </div>
                  {/* content-tabs-wrap */}
                  <div className="content-tabs-wrap tabs-act fl-wrap">
                    <div className="content-tabs fl-wrap">
                      <ul className="tabs-menu fl-wrap no-list-style">
                        <li className="current">
                          <a href="#tab-listing"> Listing</a>
                        </li>

                        <li>
                          <a href="#tab-reviews">Reviews</a>
                        </li>
                      </ul>
                    </div>
                    {/*tabs */}
                    <div className="tabs-container">
                      {/*tab */}
                      <div className="tab">
                        <div id="tab-listing" className="tab-content first-tab">
                          <div className="dasboard-wrapper fl-wrap no-pag">
                            {isLoading === true ? (
                              <Loader />
                            ) : (
                              <>
                                {properties.length === 0 ? (
                                  <Grid container>
                                    <Grid item xs={12}>
                                      <ErrorMessage />
                                    </Grid>
                                  </Grid>
                                ) : (
                                  <Grid
                                    container
                                    rowSpacing={{ xs: 1, sm: 1, md: 1 }}
                                    columnSpacing={{ xs: 1, sm: 1, md: 2 }}
                                  >
                                    {filterToggle
                                      ? filteredProperty.map((property) => {
                                          return (
                                            <Grid item xs={12} sm={6} md={12}>
                                              <Property property={property} />
                                            </Grid>
                                          );
                                        })
                                      : filteredProperty
                                          ?.slice(
                                            basicFirst,
                                            basicRows + basicFirst
                                          )
                                          .map((property) => {
                                            return (
                                              <Grid item xs={12} sm={6} md={12}>
                                                <Property property={property} />
                                              </Grid>
                                            );
                                          })}
                                  </Grid>
                                )}
                              </>
                            )}

                            <Paginator
                              first={basicFirst}
                              rows={basicRows}
                              totalRecords={properties?.length}
                              rowsPerPageOptions={[5, 10, 20, 30]}
                              onPageChange={onBasicPageChange}
                              style={{ backgroundColor: '#1b182b' }}
                            ></Paginator>
                          </div>
                        </div>
                      </div>
                      {/*tab  end*/}

                      <div className="tab">
                        <div id="tab-reviews" className="tab-content">
                          <div
                            className="list-single-main-container fl-wrap"
                            style={{ marginTop: 30 }}
                          >
                            {/* list-single-main-item */}
                            <div
                              className="list-single-main-item fl-wrap"
                              id="sec6"
                            >
                              <div className="list-single-main-item-title">
                                <h3>
                                  Reviews <span>2</span>
                                </h3>
                              </div>
                              <div className="list-single-main-item_content fl-wrap">
                                <div className="reviews-comments-wrap fl-wrap">
                                  <div className="review-total">
                                    <span className="review-number blue-bg">
                                      4.0
                                    </span>
                                    <div
                                      className="listing-rating card-popup-rainingvis"
                                      data-starrating2={4}
                                    >
                                      <span className="re_stars-title">
                                        Good
                                      </span>
                                    </div>
                                  </div>
                                  {/* reviews-comments-item */}
                                  <div className="reviews-comments-item">
                                    <div className="review-comments-avatar">
                                      <img src="images/avatar/2.jpg" alt="" />
                                    </div>
                                    <div className="reviews-comments-item-text smpar">
                                      <div className="box-widget-menu-btn smact">
                                        <i className="far fa-ellipsis-h" />
                                      </div>
                                      <div className="show-more-snopt-tooltip bxwt">
                                        <a href="#">
                                          {' '}
                                          <i className="fas fa-reply" /> Reply
                                        </a>
                                        <a href="#">
                                          {' '}
                                          <i className="fas fa-exclamation-triangle" />{' '}
                                          Report{' '}
                                        </a>
                                      </div>
                                      <h4>
                                        <a href="#">Liza Rose</a>
                                      </h4>
                                      <div
                                        className="listing-rating card-popup-rainingvis"
                                        data-starrating2={3}
                                      >
                                        <span className="re_stars-title">
                                          Average
                                        </span>
                                      </div>
                                      <div className="clearfix" />
                                      <p>
                                        " Donec quam felis, ultricies nec,
                                        pellentesque eu, pretium quis, sem.
                                        Nulla consequat massa quis enim. Donec
                                        pede justo, fringilla vel, aliquet nec,
                                        vulputate eget, arcu. In enim justo,
                                        rhoncus ut, imperdiet a, venenatis
                                        vitae, justo. Nullam dictum felis eu
                                        pede mollis pretium. "
                                      </p>
                                      <div className="reviews-comments-item-date">
                                        <span className="reviews-comments-item-date-item">
                                          <i className="far fa-calendar-check" />
                                          12 April 2018
                                        </span>
                                        <a href="#" className="rate-review">
                                          <i className="fal fa-thumbs-up" />{' '}
                                          Helpful Review <span>6</span>{' '}
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  {/*reviews-comments-item end*/}
                                  {/* reviews-comments-item */}
                                  <div className="reviews-comments-item">
                                    <div className="review-comments-avatar">
                                      <img src="images/avatar/3.jpg" alt="" />
                                    </div>
                                    <div className="reviews-comments-item-text smpar">
                                      <div className="box-widget-menu-btn smact">
                                        <i className="far fa-ellipsis-h" />
                                      </div>
                                      <div className="show-more-snopt-tooltip bxwt">
                                        <a href="#">
                                          {' '}
                                          <i className="fas fa-reply" /> Reply
                                        </a>
                                        <a href="#">
                                          {' '}
                                          <i className="fas fa-exclamation-triangle" />{' '}
                                          Report{' '}
                                        </a>
                                      </div>
                                      <h4>
                                        <a href="#">Adam Koncy</a>
                                      </h4>
                                      <div
                                        className="listing-rating card-popup-rainingvis"
                                        data-starrating2={5}
                                      >
                                        <span className="re_stars-title">
                                          Excellent
                                        </span>
                                      </div>
                                      <div className="clearfix" />
                                      <p>
                                        " Lorem ipsum dolor sit amet,
                                        consectetur adipiscing elit. Nunc
                                        posuere convallis purus non cursus. Cras
                                        metus neque, gravida sodales massa ut. "
                                      </p>
                                      <div className="reviews-comments-item-date">
                                        <span className="reviews-comments-item-date-item">
                                          <i className="far fa-calendar-check" />
                                          03 December 2017
                                        </span>
                                        <a href="#" className="rate-review">
                                          <i className="fal fa-thumbs-up" />{' '}
                                          Helpful Review <span>2</span>{' '}
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  {/*reviews-comments-item end*/}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*tab end*/}
                    </div>
                    {/*tabs end*/}
                  </div>
                  {/* content-tabs-wrap end */}
                </div>

                {/*   sidebar end*/}
              </div>
            </div>
            <div className="limit-box fl-wrap" />
          </section>
        </div>
        <NewsLetter />
        <Footer />
      </div>
    </div>
  );
};

const Loader = () => {
  return (
    <Grid
      container
      rowSpacing={{ xs: 1, sm: 1, md: 2 }}
      columnSpacing={{ xs: 1, sm: 1, md: 2 }}
    >
      <Grid item xs={12} sm={6} md={6}>
        <SkeletonLoader height={200} />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <SkeletonLoader height={200} />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <SkeletonLoader height={200} />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <SkeletonLoader height={200} />
      </Grid>
    </Grid>
  );
};

export default HostDetailPage;
