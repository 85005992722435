import React, {  useState } from "react";
import { Grid, Box, Tab } from "@mui/material";
import Card from "../../../components/dasboard/card";
import InfoBar from "../../../components/dasboard/infoBar";
import Sidebar from "../../../components/dasboard/sidebar";
import Header from "../../../components/header";
import SkeletonLoader from "../../../components/skeletonLoader";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Payment from "./Payment";
import Payouts from "./Payouts";
import Taxes from "./Taxes";
import Guest from "./Guest";




const PaymentAndPayout = () => {
  const [value, setValue] = React.useState("PaymentTab");
  const [isLoading, setIsLoading] = useState(true);
  const [filterToggle, setFilterToggle] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);

  };

  return (
    <>
      <Header />
      <div id='wrapper'>
        <div className='dashboard-menu-overlay' />
        <Sidebar page={"Payment And Payout"} />
        <div className='dashboard-content'>
          <div className='dashboard-menu-btn color-bg'>
            <span>
              <i className='fas fa-bars' />
            </span>
            Dasboard Menu
          </div>
          <InfoBar label={"Payment And Payout"} />
          <div className='dasboard-wrapper fl-wrap no-pag'>


            <Card>
              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      onChange={handleChange}
                      aria-label='lab API tabs example'
                    >
                      <Tab
                        sx={{ fontSize: "14px" }}
                        label="Payment"
                        value="PaymentTab"

                      />
                      <Tab
                        sx={{ fontSize: "14px" }}
                        label="Payouts"
                        value="PayoutsTab"

                      />
                      {/* <Tab
                        sx={{ fontSize: "14px" }}
                        label="Taxes"
                        value="TaxesTab"

                      /> */}
                      <Tab
                        sx={{ fontSize: "14px" }}
                        label="Guest"
                        value="GuestTab"

                      />

                    </TabList>
                  </Box>
                  <TabPanel value={"PaymentTab"} index={0}>
                    <Payment />
                  </TabPanel>
                  <TabPanel value={"PayoutsTab"} index={1}>
                    <Payouts />
                  </TabPanel>
                  {/* <TabPanel value={"TaxesTab"} index={2}>
                    <Taxes />
                  </TabPanel> */}
                  <TabPanel value={"GuestTab"} index={3}>
                    <Guest />
                  </TabPanel>
                </TabContext>
              </Box>
            </Card>

          </div>
        </div>
        <div className='dashbard-bg gray-bg' />
      </div>
    </>
  );
};

const Loader = () => {
  return (
    <Grid
      container
      rowSpacing={{ xs: 1, sm: 1, md: 2 }}
      columnSpacing={{ xs: 1, sm: 1, md: 2 }}
    >
      <Grid item xs={12} sm={6} md={6}>
        <SkeletonLoader height={200} />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <SkeletonLoader height={200} />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <SkeletonLoader height={200} />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <SkeletonLoader height={200} />
      </Grid>
    </Grid>
  );
};

export default PaymentAndPayout;
