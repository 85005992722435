import { API_URL } from '../../config';
import axios from 'axios';

export const getCancellationFeePreviewApi = async (bookingId) => {
  try {
    const response = await axios.get(
      API_URL + `booking-cancellation/cancellation-fee-preview/${bookingId}`
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of get offer  : ', response);
      return response;
    }
  }
};
