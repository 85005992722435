import { API_URL } from '../../config';
import axios from 'axios';
//https://api.staywo.com/rating/by-host?page=1&property_id=8
export const getHostReviewApi = async (id) => {
  try {
    const response = await axios.get(
      API_URL + `rating/by-host?page=1&property_id=${id}`
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of get host review : ', response);
      return response;
    }
  }
};
