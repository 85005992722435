import axios from 'axios';
import { API_URL } from '../../config';

export const verifyEmail = async (token, email) => {
  try {
    const response = await axios.get(
      API_URL + `auth/email/verify-email/${token}/${email}`
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('bad response of token verify email: ', response);
      return response;
    }
  }
};
