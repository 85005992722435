import { API_URL } from "../../config";
import axios from "axios";

export const getNotificationById = async (page, id) => {
  try {
    const response = await axios.get(
      API_URL + "notification/all-notification?page=" + page + "&user_id=" + id
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      return response;
    }
  }
};
