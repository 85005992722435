import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Formik, useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { initiateChat } from "../../../services/Chat/chat";
import { getPropertyByIdApi } from "../../../services/properties/getProperty";

import { confirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";

import DatePickers from "../../../components/form/datePicker";
import Buttons from "../../../components/form/button";
import { addBookingApi } from "../../../services/booking/addBooking";
import { GuestCount } from "../../../components/shared/GuestCount";
import { Typography } from "@mui/material";
import { RadioButton } from "primereact/radiobutton";
import NightlightRoundIcon from "@mui/icons-material/NightlightRound";

import io from "socket.io-client";
import { SOCKET_URL } from "../../../config";
// const socket = io.connect("ws://api.staywo.com:8001");

let socket = null;
export const ReserveCard = ({ id, isSelect, propertyDetail }) => {
  const now = moment().format("h:mm:ss");
  const [availableToday, setAvailableToday] = useState(true);
  useEffect(() => {
    if (now < "11:0:0") {
      setAvailableToday(true);
    } else {
      setAvailableToday(false);
    }
  }, [now]);
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  const userData = JSON.parse(localStorage.getItem("userData")) || null;
  const [displayResponsive, setDisplayResponsive] = useState(false);
  const [position, setPosition] = useState("center");
  const [displayPosition, setDisplayPosition] = useState(false);
  const user = localStorage.getItem("user");
  const userToken = localStorage.getItem("userToken");
  const [userDetail, setUserDetail] = useState({});
  const dialogFuncMap = {
    displayPosition: setDisplayPosition,
    displayResponsive: setDisplayResponsive,
  };

  const getPropertyDetail = async () => {
    try {
      const response = await getPropertyByIdApi(id);
      if (response.status === 200) {
        setUserDetail(response.data.data.user);
      } else {
        window.location = "/";
      }
    } catch (err) {
      window.location = "/";
    }
  };

  useEffect(() => {
    socket = io(SOCKET_URL, {
      forceNew: true,
      cors: {
        origin: "*",
      },
      transports: ["websocket"],
    });
  }, []);

  useEffect(() => {
    getPropertyDetail();
  }, [propertyDetail]);

  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);

    if (position) {
      setPosition(position);
    }
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };

  const reject = () => {};

  const history = useNavigate();

  const basicInfo = useFormik({
    initialValues: {
      startDate: "",
      endDate: "",
      ownerId: 0,
      noOfGuest: 0,
      noOfAdults: 0,
      noOfChildren: 0,
      noOfInfants: 0,
      noOfPets: 0,
      guestId: userData?.id,
      propertyId: id,
    },
    validationSchema: () => {
      return yup.object({
        startDate: yup.string().required("start date is required."),
        endDate: yup.string().required("end date is required."),
        noOfGuest: yup
          .number()
          .min(1)
          .required("Please select number of guests!"),
      });
    },
    onSubmit: (values) => {
      userToken ? confirm1() : history("/login");
    },
  });
  const handleGuestCountChange = async (value, count) => {
    basicInfo.setFieldValue("noOfGuest", value);
    basicInfo.setFieldValue("noOfAdults", count.adults);
    basicInfo.setFieldValue("noOfChildren", count.children);
    basicInfo.setFieldValue("noOfInfants", count.infants);
    basicInfo.setFieldValue("noOfPets", count.pets);
  };

  const [report, setReport] = useState(null);
  const [screen, setScreen] = useState(0);

  const GetDayDifference = () => {
    var given = moment(new Date(basicInfo.values.startDate));
    var current = moment(new Date(basicInfo.values.endDate));
    const diff = current.diff(given);
    const diffDuration = moment.duration(diff);
    return Math.ceil(diffDuration._milliseconds / (1000 * 60 * 60 * 24));
  };
  let totalNights = GetDayDifference();

  const accept = () => {
    window.location = `/book/${propertyDetail?.id}?startDate=${moment(
      basicInfo.values.startDate
    ).format("YYYY-MM-DD")}&endDate=${moment(basicInfo.values.endDate).format(
      "YYYY-MM-DD"
    )}&noOfGuests=${basicInfo.values.noOfGuest}&noOfInfants=${
      basicInfo.values.noOfInfants
    }&noOfPets=${basicInfo.values.noOfPets}&noOfAdults=${
      basicInfo.values.noOfAdults
    }&noOfChildren=${basicInfo.values.noOfChildren}&checkInTime=${
      propertyDetail?.propertyPolicy?.checkInWindow
        ? propertyDetail?.propertyPolicy?.checkInWindow
        : moment().format("hh:mm a")
    }&ownerId=${propertyDetail?.user?.id}&propertyAddress=${
      propertyDetail?.address
    }&code=${propertyDetail?.code}&checkOutTime=${
      propertyDetail?.propertyPolicy?.checkoutWindow
        ? propertyDetail?.propertyPolicy?.checkoutWindow
        : "11:00 a.m"
    }`;
  };
  const confirm1 = () => {
    confirmDialog({
      message: (
        <div
          style={{
            width: "30vw",
            textAlign: "left",
            paddingTop: "20px",
          }}
        >
          <Typography>
            You are reserving this property from{" "}
            <span style={{ color: "#fff" }}>
              {moment(basicInfo.values.startDate).format("DD MMM YYYY")}
            </span>{" "}
            to{" "}
            <span style={{ color: "#fff" }}>
              {moment(basicInfo.values.endDate).format("DD MMM YYYY")}.
            </span>
          </Typography>
          <Typography>
            Total <span style={{ color: "#fff" }}>{totalNights}</span> Nights{" "}
            <NightlightRoundIcon
              sx={{ fontSize: "18px", marginRight: "5px" }}
            />{" "}
          </Typography>
          <Typography>
            <Typography>
              {basicInfo.values.noOfGuest > 0 ? (
                <>
                  <span style={{ color: "#fff" }}>
                    {basicInfo.values.noOfGuest}
                  </span>{" "}
                  guests ({basicInfo.values.noOfAdults} adults,{" "}
                  {basicInfo.values.noOfChildren} children,{" "}
                  {basicInfo.values.noOfInfants} infants,{" "}
                  {basicInfo.values.noOfPets} pets)
                </>
              ) : (
                <>
                  <span style={{ color: "#fff" }}>
                    {basicInfo.values.noOfGuest}
                  </span>{" "}
                  guest
                </>
              )}
            </Typography>
          </Typography>
        </div>
      ),
      header: "Booking Request",
      acceptLabel: "Ok and Proceed",
      rejectLabel: "Cancel",
      accept,
      reject,
    });
  };
  return (
    <ReserveCardStyled>
      <Toast ref={toast} position='bottom-right' />
      <div className='box-widget fl-wrap'>
        <div className='box-widget-fixed-init fl-wrap output' id='sec-contact'>
          <div
            className='box-widget-title fl-wrap box-widget-title-color color-bg'
            style={{
              backgroundColor: "#096bb1",
              textAlign: "center",
              fontSize: "20px",
            }}
          >
            Reserve
          </div>
          <div className='box-widget-content fl-wrap'>
            <div className='custom-form'>
              <div className='row'>
                <div
                  className='col-md-12'
                  style={{
                    textAlign: "left",
                  }}
                >
                  <label className='label-field' style={{ fontSize: "15px" }}>
                    Check In/ Check Out
                  </label>
                  <DatePickers
                    basePrice={propertyDetail.perNightPrice}
                    handlers
                    toast={toast}
                    propertyPolicy={propertyDetail?.propertyPolicy}
                    propertyPricePolicy={propertyDetail?.propertyPricePolicy}
                    availableToday={availableToday}
                    handleDateStart={(value) =>
                      basicInfo.setFieldValue("startDate", value)
                    }
                    handleDateEnd={(value) =>
                      basicInfo.setFieldValue("endDate", value)
                    }
                    id={id}
                  />
                </div>
              </div>
              <div
                className='row'
                style={{
                  textAlign: "left",
                  marginTop: "20px",
                }}
              >
                <div className='col-md-12'>
                  <label className='label-field' style={{ fontSize: "15px" }}>
                    Persons
                  </label>
                  <GuestCount
                    isSelect={isSelect}
                    propertyDetail={propertyDetail}
                    handleChange={handleGuestCountChange}
                  />
                </div>
              </div>

              <Buttons
                style={{ height: 50, marginTop: "20px" }}
                title={"Proceed Now"}
                onSubmit={basicInfo.handleSubmit}
                isLoading={loading}
              />

              <small
                id='username2-help'
                className='p-error'
                style={{
                  display: "block",
                  color: "red",
                  textAlign: "left",
                }}
              >
                {basicInfo.touched.startDate && basicInfo.errors.startDate}{" "}
                <br />
                {basicInfo.touched.endDate && basicInfo.errors.endDate}
                <br />
                {basicInfo.touched.noOfGuest && basicInfo.errors.noOfGuest}
              </small>

              {/* <form method="post" name="contact-property-form">
              <label>
                Your name*{" "}
                <span className="dec-icon">
                  <i className="fas fa-user"></i>
                </span>
              </label>
              <input
                name="phone"
                type="text"
                onClick="this.select()"
                value=""
              />
              <label>
                Your phone *{" "}
                <span className="dec-icon">
                  <i className="fas fa-phone"></i>
                </span>
              </label>
              <input
                name="phone"
                type="text"
                onClick="this.select()"
                value=""
              />
              <div className="row">
                <div className="col-sm-6">
                  <label>
                    Date{" "}
                    <span className="dec-icon">
                      <i className="fas fa-calendar-check"></i>
                    </span>
                  </label>
                  <div className="date-container fl-wrap">
                    <input
                      type="text"
                      placeholder=""
                      style={{ padding: "16px 5px 16px 60px" }}
                      name="datepicker-here"
                      value=""
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <label>Time </label>
                  <select
                    data-placeholder="9 AM"
                    className="chosen-select on-radius no-search-select"
                  >
                    <option>9 AM</option>
                    <option>10 AM</option>
                    <option>11 AM</option>
                    <option>12 AM</option>
                    <option>13 PM</option>
                    <option>14 PM</option>
                    <option>15 PM</option>
                    <option>16 PM</option>
                  </select>
                </div>
              </div>
              <button type="submit" className="btn float-btn color-bg fw-btn">
                {" "}
                Send
              </button>
            </form> */}
            </div>
          </div>
        </div>
      </div>
      {/* report text */}
      <div
        className='box-widget fl-wrap'
        style={{
          marginTop: "20px",
        }}
      >
        <i className='fal fa-flag' style={{ color: "white" }}></i>
        <div
          onClick={() => onClick("displayResponsive")}
          style={{
            fontSize: "16px",
            color: "#fff",
            marginBottom: "0px",
            textAlign: "center",
            textDecorationLine: "underline",
            background: "none",
            display: "inline",
            margin: "0px 8px",
            cursor: "pointer",
          }}
        >
          Report this listing
        </div>

        <Dialog
          header='Report this listing?'
          visible={displayResponsive}
          onHide={() => onHide("displayResponsive")}
          breakpoints={{ "960px": "75vw" }}
          style={{ width: "50vw", fontSize: "15px" }}
        >
          {screen === 0 && (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "start",
                justifyContent: "start",
                height: "100%",
                flexDirection: "column",
              }}
            >
              <h1 style={{ fontSize: "22px", fontWeight: "bold" }}>
                Why are you reporting this listing?
              </h1>
              <p>This wont be shared with the Host.</p>
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "start",
                  height: "100%",
                  flexDirection: "column",
                }}
              >
                <div
                  className='field-radiobutton'
                  style={{ margin: "8px 0px" }}
                >
                  <RadioButton
                    inputId='report1'
                    name='report'
                    value='Chicago'
                    onChange={(e) => setReport(e.value)}
                    checked={report === "Chicago"}
                  />
                  <label
                    htmlFor='report1'
                    style={{ margin: "0px 8px", fontSize: "18px" }}
                  >
                    It’s inaccurate or incorrect
                  </label>
                </div>
                <div
                  className='field-radiobutton'
                  style={{ margin: "8px 0px" }}
                >
                  <RadioButton
                    inputId='report2'
                    name='report'
                    value='It’s not a real place to stay'
                    onChange={(e) => setReport(e.value)}
                    checked={report === " It’s not a real place to stay"}
                  />
                  <label
                    htmlFor='report2'
                    style={{ margin: "0px 8px", fontSize: "18px" }}
                  >
                    It’s not a real place to stay
                  </label>
                </div>
                <div
                  className='field-radiobutton'
                  style={{ margin: "8px 0px", fontSize: "18px" }}
                >
                  <RadioButton
                    inputId='report3'
                    name='report'
                    value='It’s a scam'
                    onChange={(e) => setReport(e.value)}
                    checked={report === "It’s a scam"}
                  />
                  <label
                    htmlFor='report3'
                    style={{ margin: "0px 8px", fontSize: "18px" }}
                  >
                    It’s a scam
                  </label>
                </div>
                <div
                  className='field-radiobutton'
                  style={{ margin: "8px 0px", fontSize: "18px" }}
                >
                  <RadioButton
                    inputId='report4'
                    name='report'
                    value=' It’s offensive'
                    onChange={(e) => setReport(e.value)}
                    checked={report === "It’s offensive"}
                  />
                  <label
                    htmlFor='report4'
                    style={{ margin: "0px 8px", fontSize: "18px" }}
                  >
                    It’s offensive
                  </label>
                </div>
                <div
                  className='field-radiobutton'
                  style={{ margin: "8px 0px", fontSize: "18px" }}
                >
                  <RadioButton
                    inputId='report5'
                    name='report'
                    value='It’s something else'
                    onChange={(e) => setReport(e.value)}
                    checked={report === "It’s something else"}
                  />
                  <label
                    htmlFor='report5'
                    style={{ margin: "0px 8px", fontSize: "18px" }}
                  >
                    It’s something else
                  </label>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  label='Next'
                  onClick={() => setScreen(screen + 1)}
                  style={{ width: "10rem", height: "3rem", fontSize: "15px" }}
                />
              </div>
            </div>
          )}
          {screen === 1 && (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "start",
                justifyContent: "start",
                height: "100%",
                flexDirection: "column",
              }}
            >
              <h1 style={{ fontSize: "22px", fontWeight: "bold" }}>
                We got your report.
              </h1>
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "start",
                  height: "100%",
                  flexDirection: "column",
                }}
              >
                <p style={{ fontSize: "16px" }}>
                  Thanks for taking the time to let us know what’s going on.
                  Reports like yours help keep the staywo community safe and
                  secure.
                </p>
              </div>
            </div>
          )}
        </Dialog>
      </div>
    </ReserveCardStyled>
  );
};

const ReserveCardStyled = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: -160px;
  width: 100%;

  .label-field {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 10px;
    display: block;
  }
`;
