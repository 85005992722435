import React, { useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  color: 'text.primary',
  backgroundColor: '#1b182b',
  boxShadow: 24,
  p: 3,
  borderRadius: 5,
  textAlign: 'start',
};

export default function Dialouge({ data }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div>
      <div>
        <Link
          component='button'
          variant='body2'
          style={{ textAlign: 'start' }}
          onClick={handleOpen}
        >
          Learn More
        </Link>
        <Modal
          aria-labelledby='transition-modal-title'
          aria-describedby='transition-modal-description'
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <Button onClick={handleClose} sx={{ display: 'block' }}>
                {' '}
                <CloseIcon />{' '}
              </Button>
              <Typography
                id='transition-modal-title'
                variant='h5'
                component='h2'
              >
                Cancellation policy
              </Typography>

              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{ m: 2 }}
              >
                <Grid item xs={4}>
                  <Typography
                    id='transition-modal-title'
                    variant='h6'
                    component='h2'
                  >
                    Before one Month
                  </Typography>
                  <Typography id='transition-modal-description' sx={{ mt: 2 }}>
                    {data}
                    <br />
                    3:00 PM
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    id='transition-modal-title'
                    variant='h6'
                    component='h2'
                  >
                    Full refund
                  </Typography>
                  <Typography id='transition-modal-description' sx={{ mt: 2 }}>
                    Get back 100% of what you paid.
                  </Typography>
                </Grid>
              </Grid>
              <hr />
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{ m: 2 }}
              >
                <Grid item xs={4}>
                  <Typography
                    id='transition-modal-title'
                    variant='h6'
                    component='h2'
                  >
                    Before Ten days
                  </Typography>
                  <Typography id='transition-modal-description' sx={{ mt: 2 }}>
                    {data}
                    <br />
                    3:00 PM
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    id='transition-modal-title'
                    variant='h6'
                    component='h2'
                  >
                    Partial refund
                  </Typography>
                  <Typography id='transition-modal-description' sx={{ mt: 2 }}>
                    Get back 50% of every night. No refund of the service fee
                  </Typography>
                </Grid>
              </Grid>
              <hr />
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{ m: 2 }}
              >
                <Grid item xs={4}>
                  <Typography
                    id='transition-modal-title'
                    variant='h6'
                    component='h2'
                  >
                    After
                  </Typography>
                  <Typography id='transition-modal-description' sx={{ mt: 2 }}>
                    {data}
                    <br />
                    3:00 PM
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    id='transition-modal-title'
                    variant='h6'
                    component='h2'
                  >
                    No refund
                  </Typography>
                  <Typography id='transition-modal-description' sx={{ mt: 2 }}>
                    This reservation is non-refundable.
                  </Typography>
                </Grid>
              </Grid>
              <hr />
              <Typography sx={{ mt: 2 }}>
                Cleaning fees are refunded if you cancel before check-in.
              </Typography>
              <Link component='button'>
                Learn more about cancellation policies
              </Link>
            </Box>
          </Fade>
        </Modal>
      </div>
    </div>
  );
}
