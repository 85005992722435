import { Divider, Grid, Typography } from '@mui/material';
import CheckBoxLabel from '../../../../components/form/checkbox';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordion from '@mui/material/Accordion';
import { styled } from '@mui/material/styles';
const Accessibility = ({ getAccessibility, handleCheckbox }) => {
  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid #323041`,
    backgroundColor: '#1b182b',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor: '#1b182b',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
      backgroundColor: '#1b182b',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
    '& .css-v84d5j-MuiSvgIcon-root': {
      fontSize: '1rem',
      color: '#2e94f7',
    },
    '& .css-ahj2mt-MuiTypography-root': {
      marginTop: 13,
      color: 'white',
    },
  }));
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));
  return (
    <Grid item xs={12}>
      <Accordion>
        <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
          <Typography sx={{ py: 1, textAlign: 'initial' }} variant={'h6'}>
            Accessibility
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ bacgroudColor: '#1f2d40' }}>
          {getAccessibility?.map((item, i) => {
            return (
              <>
                <Typography
                  variant={'h6'}
                  style={{
                    paddingTop: 25,
                    paddingBottom: 5,
                    textAlign: 'initial',
                  }}
                >
                  {item?.title}
                </Typography>
                <Typography
                  style={{
                    textAlign: 'initial',
                    fontSize:13
                  }}
                >
                  {item?.description}
                </Typography>
                <Grid
                  container
                  rowSpacing={{ xs: 1, sm: 1, md: 1 }}
                  columnSpacing={{ xs: 1, sm: 1, md: 1 }}
                  style={{ textAlign: 'left' }}
                >
                  {item?.accessibility?.map((accessibilityCheckbox, j) => {
                    return (
                      <Grid item xs={4}>
                        <CheckBoxLabel
                          label={accessibilityCheckbox?.title}
                          value={accessibilityCheckbox?.title}
                          checked={accessibilityCheckbox?.check_status}
                          onChange={(e) =>
                            handleCheckbox(accessibilityCheckbox.id, j, i, e)
                          }
                        />
                      </Grid>
                    );
                  })}
                </Grid>
                <Divider style={{ paddingBottom: 30 }} />
              </>
            );
          })}
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default Accessibility;
