import axios from "axios";
import { API_URL } from "../../config";
  
  export const getSafetyConsiderationGroupApi = async () => {
    try {
      const response = await axios.get(API_URL + "safety-consideration-group/all");
      return response;
    } catch (error) {
      if (error) {
        const { response } = error;
        console.log("Bad Response of safety-consideration : ", response);
        return response;
      }
    }
  };
  

  export const getSafetyConsiderationByPropertyId = async (id) => {
    try {
      const response = await axios.get(API_URL + `property/safety-consideration/${id}`);
      return response;
    } catch (error) {
      if (error) {
        const { response } = error;
        console.log("Bad Response of guest safety rules : ", response);
        return response;
      }
    }
  };