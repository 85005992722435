import React, { useEffect, useState } from 'react';

import { useFormik } from 'formik';
import { Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import { Toast } from 'primereact/toast';
import {
  BasicInfoValidationSchema,
  DetailValidationSchema,
  LocationValidationSchema,
  MediaValidationSchema,
  PriceValidationScheme,
} from './addPropertyValidationSchema';
import {
  getSpaceApi,
  getSpaceTypeById,
  getAreaApi,
} from '../../../services/properties/getAddPropertyDropdownValues';
import { convertToHTML } from 'draft-convert';
import { addPropertyApi } from '../../../services/properties/addProperty';
import { getAmenitiesGroupApi } from '../../../services/properties/getAmmenities';

import Header from '../../../components/header';
import Media from './addPropertiesSections/media';
import Price from './addPropertiesSections/price';
import DialogBox from '../../../components/dialog';
import Card from '../../../components/dasboard/card';
import Buttons from '../../../components/form/button';
import Details from './addPropertiesSections/details';
import Location from './addPropertiesSections/location';
import BasicInfo from './addPropertiesSections/basicInfo';
import Amenities from './addPropertiesSections/amenities';
import Progressbar from '../../../components/progressBar';
import Sidebar from '../../../components/dasboard/sidebar';
import InfoBar from '../../../components/dasboard/infoBar';
import UploadPic from './addPropertiesSections/upload';
import ProgressBar from 'react-animated-progress-bar';
import { useNavigate } from 'react-router-dom';
import { EditorState } from 'draft-js';
import { useCallback, useRef } from 'react';
import ReactCanvasConfetti from 'react-canvas-confetti';
import ConfirmationDialog from '../../../components/ConfirmationDialog';

const AddPropertyPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [step, setStep] = useState(0);
  const [openDialog, setOpenDialog] = useState(0);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showSaveAndExit, setShowSaveAndExit] = useState(false);
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const [saveAndExitButtonLoading, setSaveAndExitButtonLoading] =
    useState(false);
  const userType = localStorage.getItem('userHost') || true;
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [convertedContent, setConvertedContent] = useState('');
  const [progressBar, setprogressBar] = useState(false);
  const toast = useRef(null);
  const userData = JSON.parse(localStorage.getItem('userData')) || null;
  const userHost = JSON.parse(localStorage.getItem('userHost')) || null;
  const userHostLocation =
    JSON.parse(localStorage.getItem('userLocation')) || null;

  const [hostLocation, setHostLocation] = useState({
    latitude: '',
    longitude: '',
  });

  const [validationType, setValidationType] = useState({
    message: '',
    open: false,
    type: '',
  });

  const canvasStyles = {
    position: 'fixed',
    pointerEvents: 'none',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 9999,
  };
  const refAnimationInstance = useRef(null);
  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback((particleRatio, opts) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio),
      });
  }, []);

  const fire = () => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55,
    });

    makeShot(0.2, {
      spread: 60,
    });

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (!userData || !userData.isHost || userType === 'false') {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    if (userHostLocation !== null) {
      setHostLocation({
        latitude: userHostLocation.latitude,
        longitude: userHostLocation.longitude,
      });
    }
  }, []);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setHostLocation({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
      localStorage.setItem(
        'userLocation',
        JSON.stringify({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        })
      );
    });
  }, []);

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  };
  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  };

  //dropdown values getting from api.
  const [spaceValues, setSpaceValues] = useState([]);
  const [spaceTypeValues, setSpaceTypeValues] = useState([]);
  const [areaValues, setAreaValues] = useState([]);
  const [amenitiesValue, setAmenitiesValue] = useState([]);
  const [spaceVal, setSpaceVal] = useState('');
  const [spaceTypeVal, setSpaceTypeVal] = useState('');
  const [areaVal, setAreaVal] = useState('');
  const [propertyType, setPropertyType] = useState('');

  //property gallery and property thumbnail images and selected amenities values passing seperatly.
  const [images, setImages] = useState([]);
  const [imagesURLs, setImagesURLs] = useState([]);
  const [thumbnail, setThumbnail] = useState([]);
  const [thumbnailURLs, setThumbnailURLs] = useState([]);
  const [amenitiesIds, setAmenitiesIds] = useState([]);
  //Custome Error
  const [errorImages, setErrorImages] = useState('');
  const [errorThumbnail, setErrorIThumbnail] = useState('');
  const [temporaryOptions, setTemporaryOptions] = useState([]);

  const [inputValues, setInputValues] = useState({
    floodRelief: '',
    title: '',
    description: '',
    noOfAdults: 1,
    noOfPets: 0,
    noOfInfants: 0,
    noOfChildren: 0,
    noOfBedrooms: 1,
    noOfBathrooms: 1,
    noOfBeds: 1,
    isPrivateBathroom: {
      label: 'Private Bathroom',
      value: '',
    },
    zipCode: '',
    address: '',
    country: '',
    state: '',
    city: '',
    lat: '',
    lng: '',
    price: '',
    perNightPrice: '',
    youtubeVideoLink: '',
    thumbnail: [],
    images: [],
  });

  const handleChange = (e) => {
    setInputValues({ ...inputValues, [e.target.name]: e.target.value });
  };

  //basicinfo formik schema

  const handleSubmitBasicInfo = () => {
    if (inputValues.title == '') {
      setValidationType({
        message: 'Title is required',
        open: true,
        type: 'title',
      });
    } else if (inputValues.floodRelief == '') {
      setValidationType({
        message: 'Disaster management is required',
        open: true,
        type: 'floodRelief',
      });
    } else if (spaceVal == '') {
      setValidationType({
        message: 'Space is required',
        open: true,
        type: 'space',
      });
    } else if (spaceTypeVal == '') {
      setValidationType({
        message: 'Space Type is required',
        open: true,
        type: 'spaceType',
      });
    } else if (areaVal == '') {
      setValidationType({
        message: 'Area is required',
        open: true,
        type: 'area',
      });
    } else if (propertyType == '') {
      setValidationType({
        message: 'Property Type is required',
        open: true,
        type: 'propertyType',
      });
    } else {
      setValidationType({
        message: '',
        open: false,
        type: '',
      });
      nextStep();
    }
  };
  const basicInfo = useFormik({
    initialValues: {
      floodRelief: false,
      title: '',
      description: '',
    },
    // enableReinitialize: true,
    validationSchema: () => {
      return BasicInfoValidationSchema;
    },
    onSubmit: () => {
      if (spaceVal == '') {
        setValidationType({
          message: 'Space is required',
          open: true,
          type: 'space',
        });
      } else if (spaceTypeVal == '') {
        setValidationType({
          message: 'Space Type is required',
          open: true,
          type: 'spaceType',
        });
      } else if (areaVal == '') {
        setValidationType({
          message: 'Area is required',
          open: true,
          type: 'area',
        });
      } else if (propertyType == '') {
        setValidationType({
          message: 'Property Type is required',
          open: true,
          type: 'propertyType',
        });
      } else {
        setValidationType({
          message: '',
          open: false,
          type: '',
        });
        nextStep();
      }
    },
  });

  //detail formik schema

  const handleSubmitDetail = () => {
    if (inputValues.noOfAdults < 1) {
      setValidationType({
        message: 'Number should be at least 1',
        open: true,
        type: 'adults',
      });
    } else if (inputValues.noOfChildren < 0) {
      setValidationType({
        message: 'Number of children is required',
        open: true,
        type: 'children',
      });
    } else if (inputValues.noOfBedrooms < 1) {
      setValidationType({
        message: 'Number should be at least 1',
        open: true,
        type: 'bedrooms',
      });
    } else if (inputValues.noOfBathrooms < 1) {
      setValidationType({
        message: 'Number should be at least 1',
        open: true,
        type: 'bathrooms',
      });
    } else if (inputValues.noOfBeds < 1) {
      setValidationType({
        message: 'Number should be at least 1',
        open: true,
        type: 'beds',
      });
    } else if (inputValues.isPrivateBathroom.value === '') {
      setValidationType({
        message: 'Private bathroom field is required.',
        open: true,
        type: 'isBathroom',
      });
    } else {
      nextStep();
    }
  };

  const detail = useFormik({
    initialValues: {
      noOfAdults: 1,
      noOfPets: 0,
      noOfInfants: 0,
      noOfChildren: 0,
      noOfBedrooms: 1,
      noOfBathrooms: 1,
      noOfBeds: 1,
      isPrivateBathroom: false,
    },
    validationSchema: () => {
      // setOpenDialog(false)
      return DetailValidationSchema;
    },
    onSubmit: () => {
      nextStep();
    },
  });

  //information formik schema

  const handleSubmitLocation = () => {
    if (inputValues.address == '') {
      setValidationType({
        message: 'Address is required',
        open: true,
        type: 'address',
      });
    } else if (inputValues.country == '') {
      setValidationType({
        message: 'Country is required',
        open: true,
        type: 'country',
      });
    } else if (inputValues.city == '') {
      setValidationType({
        message: 'City is required',
        open: true,
        type: 'city',
      });
    } else if (inputValues.state == '') {
      setValidationType({
        message: 'State is required',
        open: true,
        type: 'state',
      });
    } else if (inputValues.zipCode == '') {
      setValidationType({
        message: 'Zipcode is required',
        open: true,
        type: 'zipCode',
      });
    } else {
      nextStep();
    }
  };

  const location = useFormik({
    initialValues: {
      zipCode: '',
      address: '',
      country: '',
      state: '',
      city: '',
      lat: '',
      lng: '',
    },
    validationSchema: () => {
      //setOpenDialog(false)
      return LocationValidationSchema;
    },
    onSubmit: (data) => {
      nextStep();
    },
  });

  //media formik schema

  const handleSubmitMedia = () => {
    nextStep();
  };

  const media = useFormik({
    initialValues: {
      youtubeVideoLink: '',
      thumbnail: [],
      images: [],
    },
    validationSchema: () => {
      // setOpenDialog(false)
      return MediaValidationSchema;
    },
    onSubmit: () => {
      nextStep();
    },
  });

  //upload formik schema
  const UploadhandleSubmit = () => {
    try {
      if (images?.length > 0 && thumbnail?.length > 0) {
        nextStep();
      } else {
        if (images?.length === 0) setErrorImages('Images feild is required');
        if (thumbnail?.length === 0)
          setErrorIThumbnail('Main picture field is required');
      }
    } catch (error) {}
  };

  //price formik schema

  const handleSubmitPrice = () => {
    if (inputValues.perNightPrice <= 5) {
      setValidationType({
        message: `Price should be at least ${process.env.REACT_APP_CURRENCY} 5`,
        open: true,
        type: 'perNightPrice',
      });
      setShowConfirmationModal(false);
    } else if (inputValues.perNightPrice == '') {
      setValidationType({
        message: 'Per night price is required.',
        open: true,
        type: 'perNightPrice',
      });
      setShowConfirmationModal(false);
    } else {
      submitProperty();
    }
  };

  const handleSaveAndExit = () => {
    setShowSaveAndExit(false);
    submitProperty();
  };

  const price = useFormik({
    initialValues: {
      perNightPrice: '',
    },
    validationSchema: () => {
      return PriceValidationScheme;
    },
    onSubmit: async () => {
      submitProperty();
    },
  });

  //add property function destructing all schema values into one combineFormData object
  const submitProperty = async () => {
    setSubmitButtonLoading(true);
    setprogressBar(true);
    setSaveAndExitButtonLoading(true);

    const propertyCapacity = {
      noOfAdults: inputValues.noOfAdults,
      noOfPets: inputValues.noOfPets,
      noOfInfants: inputValues.noOfInfants,
      noOfChildren: inputValues.noOfChildren,
      noOfBedrooms: inputValues.noOfBedrooms,
      noOfBathrooms: inputValues.noOfBathrooms,
      noOfBeds: inputValues.noOfBeds,
      isPrivateBathroom: inputValues.isPrivateBathroom,
    };

    var formData = new FormData();
    formData.append('userId', JSON.parse(localStorage.getItem('userData')).id);
    formData.append(
      'floodRelief',
      inputValues.floodRelief === '' ? false : inputValues.floodRelief
    );
    formData.append('spaceId', spaceVal !== '' ? spaceVal.value : '');
    formData.append(
      'spaceTypeId',
      spaceTypeVal !== '' ? spaceTypeVal.value : ''
    );
    formData.append('areaId', areaVal !== '' ? areaVal.value : '');
    formData.append(
      'propertyType',
      propertyType !== '' ? propertyType.value : ''
    );
    formData.append('title', inputValues.title);
    formData.append('description', convertedContent);

    formData.append('propertyCapacity[noOfAdults]', inputValues.noOfAdults);
    formData.append('propertyCapacity[noOfPets]', inputValues.noOfPets);
    formData.append('propertyCapacity[noOfInfants]', inputValues.noOfInfants);
    formData.append('propertyCapacity[noOfChildren]', inputValues.noOfChildren);
    formData.append('propertyCapacity[noOfBedrooms]', inputValues.noOfBedrooms);
    formData.append(
      'propertyCapacity[noOfBathrooms]',
      inputValues.noOfBathrooms
    );
    formData.append('propertyCapacity[noOfBeds]', inputValues.noOfBeds);
    formData.append(
      'propertyCapacity[isPrivateBathroom]',
      inputValues.isPrivateBathroom.value
    );
    formData.append('zipCode', inputValues.zipCode);
    formData.append('address', inputValues.address);
    formData.append('country', inputValues.country);
    formData.append('state', inputValues.state);
    formData.append('city', inputValues.city);
    formData.append('lat', inputValues.lat);
    formData.append('lng', inputValues.lng);

    for (let i = 0; i < amenitiesIds.length; i++) {
      formData.append('propertyAmenityIds[]', amenitiesIds[i]);
    }

    formData.append('youtubeVideoLink', inputValues.youtubeVideoLink);
    for (let i = 0; i < images.length; i++) {
      formData.append('galleryFiles', images[i]);
    }
    for (let i = 0; i < thumbnail.length; i++) {
      formData.append('thumbnailFile', thumbnail[i]);
    }

    formData.append('price', 0);
    formData.append('perNightPrice', inputValues.perNightPrice);
    try {
      const response = await addPropertyApi(formData);
      if (response.status === 200) {
        setprogressBar(false);
        fire();
        fire();
        fire();
        setShowConfirmationModal(false);
        enqueueSnackbar('Your property is successfully added.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
        setSubmitButtonLoading(false);
        clearInputs();
        navigate(`/admin/edit-property/${response?.data?.data.id}`);
      } else {
        setSubmitButtonLoading(false);
        setprogressBar(false);
        setShowConfirmationModal(false);
        enqueueSnackbar(response.data.error.message, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      }
    } catch (err) {
      setprogressBar(false);
      setSubmitButtonLoading(false);
      setShowConfirmationModal(false);
      if (err) {
        enqueueSnackbar(err.response.data.message, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      }
    }
  };

  const clearInputs = () => {
    basicInfo?.resetForm();
    detail?.resetForm();
    location?.resetForm();
    media?.resetForm();
    price?.resetForm();
    setAmenitiesIds([]);
    setImages([]);
    setImagesURLs([]);
    setThumbnail([]);
    setThumbnailURLs([]);
    setInputValues({});
    setEditorState(EditorState.createEmpty());
    setSpaceVal('');
    setSpaceTypeVal('');
    setAreaVal('');
    setPropertyType('');
  };

  //stepform next button function
  const nextStep = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setStep(step + 1);
  };

  //stepform previous button function
  const previousStep = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setStep(step - 1);
  };

  const getSpace = async () => {
    const response = await getSpaceApi();
    if (response.status === 200) {
      let tempArr = [...spaceValues];
      response.data.data.map((item) => {
        tempArr.push({
          label: item.title,
          value: item.id,
        });
      });
      setSpaceValues(tempArr);
    }
  };

  //getting space type dropdown value from api
  const getSpaceType = async (selectedOption) => {
    const response = await getSpaceTypeById(selectedOption.value);
    if (response.status === 200) {
      let tempArr = [...temporaryOptions];
      let newArr = [];
      response.data.data.map((item) => {
        newArr.push({
          label: item.title,
          value: item.id,
        });
      });
      tempArr.push({
        type: selectedOption,
        data: newArr,
      });

      if (temporaryOptions.length > 0) {
        setTemporaryOptions(tempArr);
        setSpaceTypeValues(newArr);
      } else {
        setTemporaryOptions(tempArr);
        setSpaceTypeValues(tempArr[0].data);
      }
    }
  };

  //getting area dropdown value from api
  const getArea = async () => {
    const response = await getAreaApi();
    if (response.status === 200) {
      let tempArr = [];
      response.data.data.map((item) => {
        tempArr.push({
          label: item.title,
          value: item.id,
        });
      });
      setAreaValues(tempArr);
    }
  };

  //getting amentites groups from api
  const getAmenitiesGroup = async () => {
    const response = await getAmenitiesGroupApi();
    if (response.status === 200) {
      setAmenitiesValue(response?.data?.data);
    }
  };

  //amentities selection funcction
  const handleCheckbox = (amenityGroupIndex, amenityIndex, e) => {
    let copyAmenitiesValue = [...amenitiesValue];
    let copyAmenitiesIds = [...amenitiesIds];

    //yes checked store id in array else filter that value from array
    if (e.target.checked === true) {
      const { id } =
        copyAmenitiesValue[amenityGroupIndex].amenities[amenityIndex];
      copyAmenitiesIds.push(id);
    } else if (e.target.checked === false) {
      const { id } =
        copyAmenitiesValue[amenityGroupIndex].amenities[amenityIndex];
      copyAmenitiesIds = copyAmenitiesIds.filter(
        (copyAmenitiesId) => copyAmenitiesId !== id
      );
    }

    copyAmenitiesValue[amenityGroupIndex].amenities[amenityIndex].isChecked =
      !copyAmenitiesValue[amenityGroupIndex]?.amenities[amenityIndex]
        ?.isChecked;
    setAmenitiesValue([...copyAmenitiesValue]);
    setAmenitiesIds([...copyAmenitiesIds]);
  };

  const getDropdownValues = () => {
    getSpace();
    // getSpaceType();
    getArea();
    getAmenitiesGroup();
  };

  useEffect(() => {
    getDropdownValues();
  }, []);

  const steps = [
    'Basic Info',
    'Details',
    'Location',
    'Amenities',
    'Media',
    'Upload',
    'Price',
  ];

  return (
    <>
      <Header />
      <Toast ref={toast} position='bottom-right' />
      <ConfirmationDialog
        message='Are you sure you want to proceed?'
        header='Confirmation'
        icon='pi pi-exclamation-triangle'
        open={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
        onConfirm={handleSubmitPrice}
        confirmButtonLoading={submitButtonLoading}
        confirmButtonColor='success'
      />
      <ConfirmationDialog
        message='Are you sure you want save and exit?'
        header='Confirmation'
        icon='pi pi-exclamation-triangle'
        open={showSaveAndExit}
        onHide={() => setShowSaveAndExit(false)}
        onConfirm={handleSaveAndExit}
        confirmButtonLoading={saveAndExitButtonLoading}
        confirmButtonColor='success'
      />
      <div id='wrapper'>
        <div className='dashboard-menu-overlay' />
        <Sidebar page={'Add New'} />
        <div className='dashboard-content'>
          <div className='dashboard-menu-btn color-bg'>
            <span>
              <i className='fas fa-bars' />
            </span>
            Dashboard Menu
          </div>
          <InfoBar label={'ADD PROPERTY'} />
          <div
            className='dasboard-widget-box fl-wrap'
            style={{ marginTop: 40 }}
          >
            <Progressbar step={step} steps={steps} />
          </div>
          <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />

          <div className='dasboard-wrapper fl-wrap no-pag'>
            <Card
              withIcon
              title={'Add Property'}
              icon={'fa fa-pencil'}
              formik={basicInfo}
            >
              {(() => {
                switch (step) {
                  case 0:
                    return (
                      <>
                        <BasicInfo
                          spaceValues={spaceValues}
                          spaceTypeValues={spaceTypeValues}
                          areaValues={areaValues}
                          formik={basicInfo}
                          editorState={editorState}
                          handleEditorChange={handleEditorChange}
                          spaceVal={spaceVal}
                          setSpaceVal={setSpaceVal}
                          getSpaceType={getSpaceType}
                          setSpaceTypeVal={setSpaceTypeVal}
                          spaceTypeVal={spaceTypeVal}
                          validationType={validationType}
                          setAreaVal={setAreaVal}
                          areaVal={areaVal}
                          propertyType={propertyType}
                          setPropertyType={setPropertyType}
                          convertedContent={convertedContent}
                          temporaryOptions={temporaryOptions}
                          setSpaceTypeValues={setSpaceTypeValues}
                          inputValues={inputValues}
                          handleChangeInput={handleChange}
                        />
                        <Grid container spacing={3}>
                          <Grid item xs={2} md={2}>
                            <Buttons
                              title={'Next'}
                              isLoading={false}
                              onSubmit={handleSubmitBasicInfo}
                            />
                          </Grid>
                        </Grid>
                      </>
                    );
                  case 1:
                    return (
                      <>
                        <Details
                          formik={detail}
                          inputValues={inputValues}
                          handleChangeInput={handleChange}
                          validationType={validationType}
                          setInputValues={setInputValues}
                        />
                        <Grid container columnSpacing={{ xs: 2, sm: 2 }}>
                          <Grid item xs={3} sm={3} md={2}>
                            <Buttons
                              title={'Save and Exit'}
                              color={'success'}
                              loadingTitle={'Submitting'}
                              onSubmit={() => setShowSaveAndExit(true)}
                              isLoading={false}
                            />
                          </Grid>
                          <Grid item xs={3} sm={3} md={2}>
                            <Buttons
                              title={'Back'}
                              isLoading={false}
                              onSubmit={previousStep}
                            />
                          </Grid>
                          <Grid item xs={3} sm={3} md={2}>
                            <Buttons
                              title={'Next'}
                              isLoading={false}
                              onSubmit={handleSubmitDetail}
                            />
                          </Grid>
                        </Grid>
                      </>
                    );
                  case 2:
                    return (
                      <>
                        <Location
                          formik={location}
                          hostLocation={hostLocation}
                          setHostLocation={setHostLocation}
                          inputValues={inputValues}
                          setInputValues={setInputValues}
                          handleChangeInput={handleChange}
                          validationType={validationType}
                        />
                        <Grid
                          container
                          columnSpacing={{ xs: 2, sm: 2 }}
                          spacing={3}
                        >
                          <Grid item xs={3} sm={3} md={2}>
                            <Buttons
                              title={'Save and Exit'}
                              color={'success'}
                              loadingTitle={'Submitting'}
                              onSubmit={() => setShowSaveAndExit(true)}
                              isLoading={false}
                            />
                          </Grid>
                          <Grid item xs={3} sm={3} md={2}>
                            <Buttons
                              title={'Back'}
                              isLoading={false}
                              onSubmit={previousStep}
                            />
                          </Grid>
                          <Grid item xs={3} sm={3} md={2}>
                            <Buttons
                              title={'Next'}
                              isLoading={false}
                              onSubmit={handleSubmitLocation}
                            />
                          </Grid>
                        </Grid>
                      </>
                    );
                  case 3:
                    return (
                      <>
                        <Amenities
                          amenitiesValue={amenitiesValue}
                          handleCheckbox={handleCheckbox}
                        />
                        <Grid
                          container
                          columnSpacing={{ xs: 2, sm: 2 }}
                          spacing={3}
                        >
                          <Grid item xs={3} sm={3} md={2}>
                            <Buttons
                              title={'Save and Exit'}
                              color={'success'}
                              loadingTitle={'Submitting'}
                              onSubmit={() => setShowSaveAndExit(true)}
                              isLoading={false}
                            />
                          </Grid>
                          <Grid item xs={3} sm={3} md={2}>
                            <Buttons
                              title={'Back'}
                              isLoading={false}
                              onSubmit={previousStep}
                            />
                          </Grid>
                          <Grid item xs={3} sm={3} md={2}>
                            <Buttons
                              title={'Next'}
                              isLoading={false}
                              onSubmit={nextStep}
                            />
                          </Grid>
                        </Grid>
                      </>
                    );
                  case 4:
                    return (
                      <>
                        <Media
                          formik={media}
                          inputValues={inputValues}
                          handleChangeInput={handleChange}
                          validationType={validationType}
                        />
                        <Grid
                          container
                          columnSpacing={{ xs: 2, sm: 2 }}
                          spacing={3}
                        >
                          <Grid item xs={3} sm={3} md={2}>
                            <Buttons
                              title={'Save and Exit'}
                              color={'success'}
                              loadingTitle={'Submitting'}
                              onSubmit={() => setShowSaveAndExit(true)}
                              isLoading={false}
                            />
                          </Grid>
                          <Grid item xs={3} sm={3} md={2}>
                            <Buttons
                              title={'Back'}
                              isLoading={false}
                              onSubmit={previousStep}
                            />
                          </Grid>
                          <Grid item xs={3} sm={3} md={2}>
                            <Buttons
                              title={'Next'}
                              isLoading={false}
                              onSubmit={handleSubmitMedia}
                            />
                          </Grid>
                        </Grid>
                      </>
                    );
                  case 5:
                    return (
                      <>
                        <UploadPic
                          setImagesURLs={setImagesURLs}
                          imagesURLs={imagesURLs}
                          images={images}
                          setImages={setImages}
                          setThumbnailURLs={setThumbnailURLs}
                          thumbnailURLs={thumbnailURLs}
                          thumbnail={thumbnail}
                          setThumbnail={setThumbnail}
                          errorImages={errorImages}
                          errorThumbnail={errorThumbnail}
                        />
                        <Grid
                          container
                          columnSpacing={{ xs: 2, sm: 2 }}
                          spacing={3}
                        >
                          <Grid item xs={3} sm={3} md={2}>
                            <Buttons
                              title={'Save and Exit'}
                              color={'success'}
                              loadingTitle={'Submitting'}
                              onSubmit={() => setShowSaveAndExit(true)}
                              isLoading={false}
                            />
                          </Grid>
                          <Grid item xs={3} sm={3} md={2}>
                            <Buttons
                              title={'Back'}
                              isLoading={false}
                              onSubmit={previousStep}
                            />
                          </Grid>
                          <Grid item xs={3} sm={3} md={2}>
                            <Buttons
                              title={'Next'}
                              isLoading={false}
                              onSubmit={() => UploadhandleSubmit()}
                            />
                          </Grid>
                        </Grid>
                      </>
                    );
                  case 6:
                    return (
                      <>
                        <Price
                          formik={price}
                          inputValues={inputValues}
                          handleChangeInput={handleChange}
                          validationType={validationType}
                        />
                        <Grid
                          container
                          columnSpacing={{ xs: 2, sm: 2 }}
                          spacing={3}
                        >
                          <Grid item xs={3} sm={3} md={2}>
                            <Buttons
                              title={'Back'}
                              isLoading={false}
                              onSubmit={previousStep}
                            />
                          </Grid>
                          <Grid item xs={3} sm={3} md={3}>
                            <Buttons
                              title={'Submit'}
                              color={'success'}
                              loadingTitle={'Submitting'}
                              onSubmit={() => setShowConfirmationModal(true)}
                              isLoading={false}
                            />
                          </Grid>
                        </Grid>
                        {progressBar && (
                          <ProgressBar
                            width='1000px'
                            height='10px'
                            rect
                            fontColor='gray'
                            percentage={progressBar ? '99' : '70'}
                            rectPadding='1px'
                            rectBorderRadius='20px'
                            trackPathColor='transparent'
                            bgColor='#333333'
                            trackBorderColor='grey'
                          />
                        )}
                      </>
                    );
                  default:
                    return null;
                }
              })()}
            </Card>
          </div>
        </div>
        <div className='dashbard-bg gray-bg' />
      </div>
    </>
  );
};

export default AddPropertyPage;
