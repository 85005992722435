import { API_URL } from '../../config';
import axios from 'axios';

export const updateBookingApi = async (bookingID, data) => {
  const config = {
    method: 'put',
    url: API_URL + 'booking/update/' + bookingID,
    headers: {
      accept: '*/*',
      'Content-Type': 'application/json',
    },
    data: data,
  };
  try {
    const response = await axios(config);
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of update booking : ', response);
      return response;
    }
  }
};
