import React from 'react';

export const HeroHeader = ({ bg, title }) => {
  return (
    <section
      className="hidden-section single-par2"
      data-scrollax-parent="true"
      style={{
        height: '70vh',
        padding: '200px 0 90px',
        marginTop: '50px',
      }}
    >
      <div className="bg-wrap bg-parallax-wrap-gradien">
        <img
          className="bg par-elem"
          src={require(`../../assets/images/bg/${bg}.jpg`)}
          data-scrollax="properties: { translateY: '30%' }"
          alt="contactBackground"
        />
      </div>
      <div className="container">
        <div className="section-title center-align big-title">
          <h2>
            <span>{title}</span>
          </h2>
          <h4>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut nec
            tincidunt arcu, sit amet fermentum sem.
          </h4>
        </div>
        <div className="scroll-down-wrap">
          <div className="mousey">
            <div className="scroller"></div>
          </div>
          <span>Scroll Down To Discover</span>
        </div>
      </div>
    </section>
  );
};
