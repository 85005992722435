import React, { useState } from 'react'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from '@mui/material';
import stywo from "../../assets/images/Staywo_logo.png";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1100,
    color: 'text.primary',
    backgroundColor: "#1b182b",
    boxShadow: 24,
    p: 4,
    borderRadius: 5

};

export default function Dialouge({ data, summary }) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <div>
            <div>
                <Link
                    component="button"
                    variant="body2"
                    onClick={handleOpen}>
                    Learn More
                </Link>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <Box sx={style} >
                            <Button onClick={handleClose} sx={{ display: 'block' }}> <CloseIcon /> </Button>
                            <img src={stywo} width="80" height="50" />
                            <Typography sx={{ mb: 3 }}>Staywo is comprehensive protection included for free with every booking.
                            </Typography>
                            <hr />
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ m: 2 }}>
                                <Grid item xs={6}>
                                    <Typography style={{ textAlign: "start" }} id="transition-modal-title" variant="h6" component="h2">
                                        {data.titleOne}
                                    </Typography>
                                    <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                                        {summary.DetailOne}
                                    </Typography>


                                </Grid >
                                <Grid item xs={6}>
                                    <Typography style={{ textAlign: "start" }} id="transition-modal-title" variant="h6" component="h2">
                                        {data.titleTwo}
                                    </Typography>
                                    <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                                        {summary.DetailTwo}
                                    </Typography>


                                </Grid >

                            </Grid>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ m: 2 }}>
                                <Grid item xs={6}>
                                    <Typography style={{ textAlign: "start" }} id="transition-modal-title" variant="h6" component="h2">
                                        {data.titleThree}
                                    </Typography>
                                    <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                                        {summary.DetailThree}
                                    </Typography>


                                </Grid >
                                <Grid item xs={6}>
                                    <Typography style={{ textAlign: "start" }} id="transition-modal-title" variant="h6" component="h2">
                                        {data.titleFour}
                                    </Typography>
                                    <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                                        {summary.DetailFour}
                                    </Typography>


                                </Grid >

                            </Grid>
                            <Typography>Find complete details on how AirCover protects your booking in our <Link component="button" >Help Center</Link></Typography>
                        </Box>
                    </Fade>
                </Modal>
            </div>
        </div>
    )
}
