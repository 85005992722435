import { API_URL } from '../../config';
import axios from 'axios';
import { axiosPrivate } from '../../api/axios';

export const bookingUpdateRequestApi = async (data) => {
  try {
    const response = await axiosPrivate.post(
      API_URL + 'booking-update-request',
      data
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of booking update request  : ', response);
      return response;
    }
  }
};

const token = localStorage.getItem('userToken');
axios.interceptors.request.use(
  function (config) {
    Object.assign(config.headers, { Authorization: `Bearer ${token}` });
    return config;
  },
  function (error) {
    return console.log('Interception error:', error);
  }
);
