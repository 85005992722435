import axios from 'axios';
const BASE_URL = 'https://api.staywo.com';

export default axios.create({
  baseURL: BASE_URL,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

axiosPrivate.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem('userToken');
    Object.assign(config.headers, { Authorization: `Bearer ${token}` });
    return config;
  },
  function (error) {
    return console.log('Interception error:', error);
  }
);

axiosPrivate.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return console.log('Interception error:', error);
  }
);
