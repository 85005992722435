import axios from 'axios';
import { API_URL } from '../../config';

const getPage = async (slug) => {
  try {
    const response = await axios.get(API_URL + `page/by-slug/${slug}`);
    return response.data;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('bad response of get page : ', response);
      return response;
    }
  }
};
export default getPage;
