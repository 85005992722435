import React, { useState } from "react";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Box } from "@mui/system";
import { Rating, Typography } from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import { AiOutlineRight } from "react-icons/ai";
import { MdVerifiedUser } from "react-icons/md";
import { BiLogInCircle } from "react-icons/bi";
import { FcBusinessman } from "react-icons/fc";
import moment from "moment";
import { BsCash, BsLockFill } from "react-icons/bs";
import Buttons from "../../components/form/button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import NightlightRoundIcon from "@mui/icons-material/NightlightRound";
import { useFormik } from "formik";
import * as yup from "yup";
import { editPropertyCalenderDateByRange } from "../../services/properties/propertyCalenderPrice";
import { useSnackbar } from "notistack";
import { AiFillStar } from "react-icons/ai";

const ReadMore = ({ children, title, index, commonQuestions }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <Box
      sx={{
        // paddingBottom: "12px",
        borderBottomWidth: 1,
        borderBottomColor: "red",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        marginBottom: 3,
        borderBottom:
          index !== commonQuestions.length - 1 && "0.5px solid #717171",
      }}
    >
      <Typography>
        <Typography
          sx={{
            fontSize: "16px",
            color: "white",
            fontWeight: "bolder",
          }}
        >
          {title}
        </Typography>
        <Typography sx={{ fontSize: "12px" }}>
          {isReadMore ? `${text.slice(0, 90)}...` : text}
        </Typography>
      </Typography>
      <span
        onClick={toggleReadMore}
        style={{
          color: "white",
          textDecoration: "underline",
          fontWeight: "bolder",
          cursor: "pointer",
          // fontSize: "14px",
          marginBottom: 11,
        }}
      >
        {isReadMore ? "Read more" : " Show less"}
      </span>
    </Box>
  );
};

const ReservationDetailCard = ({
  open,
  dialogContentStyle,
  isHost,
  data,
  openSpecialOfferModal,
}) => {
  const [scroll, setScroll] = React.useState("paper");
  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    initialValues: {
      propertyId: data?.property?.id,
      privateNote: "",
      startDate: data?.startDate,
      endDate: data?.endDate,
    },
    validationSchema: yup.object({
      privateNote: yup.string().required("Private Note Is Required."),
    }),
    enableReinitialize: true,
    onSubmit: async (values) => {
      const res = await editPropertyCalenderDateByRange(values);
      if (res.status === 200 || res.status === 201) {
        enqueueSnackbar("Calendar note added!", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    },
  });
  const commonQuestions = [
    {
      id: 1,
      title: "Fill your place faster",
      description:
        "The best type of listing is one that’s filled. Here’s how to make it easier for guests to book your place The best type of listing is one that’s filled. Here’s how to make it easier for guests to book your place",
    },
    {
      id: 2,
      title: "Understanding your reservation status",
      description:
        "Your reservation status lets you know how things are going. Your reservation status lets you know how things are going",
    },
    {
      id: 3,
      title: "When a reservation is “not possible“",
      description:
        "As a Host, you may get a trip request that’s marked “not possible.”  Why this happens  “Not possible”.As a Host, you may get a trip request that’s marked “not possible.”  Why this happens  “Not possible”",
    },
  ];

  const booking = data;

  return (
    <>
      <Box
        open={true}
        scroll={scroll}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          <NavLink to={"/admin/property-listing"}>
            <Box
              sx={{ margin: "0px 10px", display: "flex", alignItems: "center" }}
            >
              <ArrowBackIcon sx={{ color: "#fff" }} />
              <Buttons
                title='Back To My Listings'
                isLoading={false}
                variant={"text"}
              />
            </Box>
          </NavLink>
        </Box>
        {data?.booking && (
          <DialogContent
            sx={{
              height: "90vh",
              width: "35vw",
              background: "#1c172b",
              ...dialogContentStyle,
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{ color: "#fff", fontFamily: "Jost", fontSize: "25px" }}
              >
                Reservation Details
              </Typography>
            </Box>

            <DialogContentText
              id='scroll-dialog-description'
              tabIndex={-1}
              sx={{ width: "100%" }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "25px 0px",
                  borderBottom: "0.5px solid #0095ff",
                }}
              >
                <Box>
                  <Typography sx={{ fontSize: "12px" }}>
                    {isHost && (
                      <>
                        {" "}
                        {data?.booking?.bookingStatus === "completed" && (
                          <>Past Guest</>
                        )}
                        {data?.booking?.bookingStatus === "confirmed" && (
                          <>Upcoming Guest</>
                        )}
                      </>
                    )}
                    {!isHost && (
                      <>
                        {" "}
                        {data?.booking?.bookingStatus === "completed" && (
                          <>Previous Trip</>
                        )}
                        {data?.booking?.bookingStatus === "confirmed" && (
                          <>Upcoming Trip</>
                        )}
                      </>
                    )}
                  </Typography>
                  {/* PROPERTY DETAILS TEMPLATE */}
                  <Box component='div' sx={{ marginTop: "10px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        padding: "10px 0px",
                        // borderBottom: '0.5px solid #0095ff',
                      }}
                    >
                      <Box>
                        <img
                          src={booking?.property?.thumbnailUrl}
                          alt='Property Image'
                          width={130}
                          height={130}
                        />
                      </Box>
                      <Box sx={{ marginLeft: "10px" }}>
                        <Typography
                          sx={{
                            color: "#fff",
                            fontFamily: "Jost",
                            fontSize: "22px",
                          }}
                        >
                          {booking?.property?.title &&
                          booking?.property?.title?.length > 30
                            ? booking?.property?.title?.slice(0, 30) + "..."
                            : booking?.property?.title?.slice(0, 30)}
                        </Typography>
                        <Typography
                          sx={{ fontFamily: "Jost", fontSize: "14px" }}
                        >
                          {booking?.property?.city},{" "}
                          {booking?.property?.country}
                        </Typography>
                        <Typography
                          sx={{ fontFamily: "Jost", fontSize: "14px" }}
                        >
                          Property ID: {booking?.property?.code}
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "Jost",
                            fontSize: "16px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Rating
                            name='half-rating-read'
                            value={
                              booking?.property?.average_rating
                                ? booking?.property?.average_rating
                                    ?.averageRating
                                : 0
                            }
                            precision={0.5}
                            readOnly
                            sx={{ marginRight: "4px" }}
                          />
                          (
                          {booking?.property?.average_rating
                            ? `${booking?.property?.average_rating?.reviewCount}`
                            : `0`}
                          )
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Typography sx={{ fontSize: "14px" }}>
                    {moment(data?.booking?.startDate).format("MMM")}
                    {"  "}
                    {moment(data?.booking?.startDate).format("DD")} -{" "}
                    {moment(data?.booking?.endDate).format("DD")} (
                    <NightlightRoundIcon
                      sx={{ fontSize: "15px", marginLeft: "5px" }}
                    />{" "}
                    {data?.booking?.numberOfNights} night
                    {data?.booking?.numberOfNights > 1 ? "s" : ""})
                  </Typography>
                  <Typography sx={{ fontSize: "14px" }}>
                    <span style={{ color: "#fff" }}>
                      {data?.booking?.totalGuests}
                    </span>{" "}
                    guests ({data?.booking?.totalAdults} adults,{" "}
                    {data?.booking?.totalChildren} children,{" "}
                    {data?.booking?.totalInfants} infants,{" "}
                    {data?.booking?.totalPets} pets) -{" "}
                    <span style={{ color: "#fff", fontSize: "16px" }}>
                      {process.env.REACT_APP_CURRENCY}
                      {data?.booking?.grossAmount?.toLocaleString("en-US")}
                    </span>
                  </Typography>
                  {/* SPECIAL OFFER HANDLER */}
                  {data?.guest &&
                    isHost &&
                    data?.booking?.bookingStatus === "pending" && (
                      <Button
                        variant='outlined'
                        sx={{ width: "100%", marginTop: 2 }}
                        onClick={() => {
                          openSpecialOfferModal(data?.guest);
                        }}
                      >
                        Special Offer
                      </Button>
                    )}
                </Box>
              </Box>

              {/* <Box
                sx={{
                  padding: '25px 0px',
                  borderBottom: '0.5px solid #0095ff',
                }}
              >
                <Typography sx={{ fontSize: '16px', color: 'white' }}>
                  Sunny's review:
                </Typography>
                <Typography sx={{ fontSize: '14px' }}>
                  Nice place to stay, hosts are cooperative
                </Typography>
                <Link
                  to='/'
                  style={{
                    color: 'white',
                    textDecoration: 'underline',
                    fontSize: '14px',
                  }}
                >
                  Show review
                </Link>
              </Box> */}
              <Box
                sx={{
                  padding: "25px 0px",
                  borderBottom: "0.5px solid #0095ff",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Jost",
                    fontSize: "22px",
                    color: "white",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {isHost && <>About {data?.guest?.firstName}</>}
                  {!isHost && <>About {data?.propertyOwner?.firstName}</>}
                  <a
                    href={
                      isHost
                        ? `/about-host/${data?.propertyOwner?.id}`
                        : `/about-host/${data?.guest?.id}`
                    }
                    target='_blank'
                    rel='noreferrer'
                  >
                    <img
                      src={
                        isHost
                          ? data?.guest?.profilePicture !== null
                            ? data?.guest?.profilePicture
                            : require("../../assets/images/avatar/5.jpg")
                          : data?.propertyOwner?.profilePicture !== null
                          ? data?.propertyOwner?.profilePicture
                          : require("../../assets/images/avatar/5.jpg")
                      }
                      alt=''
                      style={{
                        borderRadius: "50%",
                        width: "50px",
                        height: "50px",
                      }}
                    />
                  </a>
                </Typography>

                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography sx={{ display: "flex", alignItems: "center" }}>
                    {isHost ? (
                      <>
                        <Rating
                          name='half-rating-read'
                          value={
                            data?.guest?.guestAverageRating
                              ? data?.guest?.guestAverageRating?.averageRating
                              : 0
                          }
                          precision={0.5}
                          readOnly
                        />
                        <span
                          style={{
                            textDecoration: "underline",
                            fontSize: "14px",
                            color: "rgba(255, 255, 255, 0.7)",
                            marginLeft: "10px",
                          }}
                        >
                          (
                          {data?.guest?.guestAverageRating
                            ? data?.guest?.guestAverageRating?.reviewCount
                            : 0}{" "}
                          - Reviews)
                        </span>
                      </>
                    ) : (
                      <>
                        <>
                          <Rating
                            name='half-rating-read'
                            value={
                              data?.propertyOwner?.average_rating
                                ? data?.propertyOwner?.average_rating
                                    ?.averageRating
                                : 0
                            }
                            precision={0.5}
                            readOnly
                          />
                          <span
                            style={{
                              textDecoration: "underline",
                              fontSize: "14px",
                              color: "rgba(255, 255, 255, 0.7)",
                              marginLeft: "10px",
                            }}
                          >
                            (
                            {data?.propertyOwner?.average_rating
                              ? data?.propertyOwner?.average_rating?.reviewCount
                              : 0}{" "}
                            - Reviews)
                          </span>
                        </>
                      </>
                    )}
                  </Typography>
                  {data?.guest?.verified && (
                    <Typography sx={{ display: "flex", alignItems: "center" }}>
                      <MdVerifiedUser
                        style={{
                          fontSize: "14px",
                          color: "white",

                          marginRight: "4px",
                        }}
                      />
                      <Link
                        to='#'
                        style={{
                          textDecoration: "underline",
                          fontSize: "14px",
                          color: "rgba(255, 255, 255, 0.7)",
                        }}
                      >
                        Indenitiy Verified
                      </Link>
                    </Typography>
                  )}
                  <Typography sx={{ display: "flex", alignItems: "center" }}>
                    <BiLogInCircle
                      style={{
                        fontSize: "14px",
                        color: "#fff",

                        marginRight: "4px",
                      }}
                    />
                    <Link
                      to='#'
                      style={{
                        fontSize: "14px",
                        color: "rgba(255, 255, 255, 0.7)",
                      }}
                    >
                      {isHost && (
                        <>
                          Joined on{" "}
                          {moment(data?.propertyOwner?.createdOn).format(
                            "DD MMM, YYYY"
                          )}
                        </>
                      )}
                      {!isHost && (
                        <>
                          Joined on{" "}
                          {moment(data?.guest?.createdOn).format(
                            "DD MMM, YYYY"
                          )}
                        </>
                      )}
                    </Link>
                  </Typography>
                  {/* <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                    <AiFillHome
                      style={{
                        fontSize: '14px',
                        color: 'white',

                        marginRight: '4px',
                      }}
                    />
                    <Link
                      to='/'
                      style={{
                        fontSize: '14px',
                        color: 'rgba(255, 255, 255, 0.7)',
                      }}
                    >
                      Lives in San Francisco, CA
                    </Link>
                  </Typography> */}
                  {isHost && (
                    <Typography sx={{ display: "flex", alignItems: "center" }}>
                      <FcBusinessman
                        style={{
                          fontSize: "16px",
                          color: "white",

                          marginRight: "4px",
                        }}
                      />
                      <Link
                        to={`/about-host/${data?.guest?.id}`}
                        style={{
                          fontSize: "14px",
                          color: "rgba(255, 255, 255, 0.7)",
                        }}
                        target='_blank'
                      >
                        Also a host
                      </Link>
                    </Typography>
                  )}
                </Box>
                {!isHost && (
                  <Link
                    to={
                      isHost
                        ? `/about-host/${data?.propertyOwner?.id}`
                        : `/about-host/${data?.guest?.id}`
                    }
                    style={{
                      color: "white",
                      textDecoration: "underline",
                      fontSize: "14px",
                    }}
                    target='_blank'
                  >
                    Show profile
                  </Link>
                )}
                {/* <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: '2',
                    gridGap: '4px',
                    marginTop: '6px',
                  }}
                >
                  <Button
                    variant='outlined'
                    sx={{ fontFamily: 'Jost', fontSize: '14px' }}
                  >
                    Call
                  </Button>
                </Box> */}
              </Box>

              <Box
                sx={{
                  padding: "25px 0px",
                  borderBottom: "0.5px solid #0095ff",
                }}
              >
                <Typography
                  sx={{ fontFamily: "Jost", fontSize: "22px", color: "white" }}
                >
                  Booking Details
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "12px 0px",
                    }}
                  >
                    <Typography>
                      <Typography sx={{ fontSize: "16px", color: "white" }}>
                        Guests
                      </Typography>
                      <Typography sx={{ fontSize: "12px" }}>
                        <p>
                          <i className='fas fa-user'></i>{" "}
                          {data?.booking?.totalGuests} Guests
                        </p>

                        <p>
                          (
                          {data?.booking?.totalAdults > 0
                            ? data?.booking?.totalAdults + " Adults"
                            : ""}
                          ,
                          {data?.booking?.totalChildren > 0
                            ? data?.booking?.totalChildren + " Children"
                            : "0 Children"}
                        </p>

                        <p>
                          {data?.booking?.totalInfants > 0
                            ? data?.booking?.totalInfants + " Infants"
                            : "0 Infants"}
                          ,
                          {data?.booking?.totalPets > 0
                            ? data?.booking?.totalPets + " Pets"
                            : "0 Pets"}
                          )
                        </p>
                      </Typography>
                    </Typography>
                    {/* <Link
                      to='/'
                      style={{
                        textDecoration: 'underline',
                        fontSize: '14px',
                        color: '#fff',
                      }}
                    >
                      View
                    </Link> */}
                  </Box>
                  <Box
                    sx={{
                      paddingBottom: "12px",
                    }}
                  >
                    <Typography>
                      <Typography sx={{ fontSize: "16px", color: "white" }}>
                        Check In Time
                      </Typography>
                      <Typography sx={{ fontSize: "14px" }}>
                        {data?.property?.propertyPolicy?.checkInWindow}
                      </Typography>
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      paddingBottom: "12px",
                    }}
                  >
                    <Typography>
                      <Typography sx={{ fontSize: "16px", color: "white" }}>
                        Check Out
                      </Typography>
                      <Typography sx={{ fontSize: "14px" }}>
                        {data?.property?.propertyPolicy?.checkoutWindow}
                        {/* Thu, 14th February, 2022 */}
                      </Typography>
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      paddingBottom: "12px",
                    }}
                  >
                    <Typography>
                      <Typography sx={{ fontSize: "16px", color: "white" }}>
                        Booking Date
                      </Typography>
                      <Typography sx={{ fontSize: "12px" }}>
                        {/* Thu, 13th February, 2022 */}
                        {moment(data?.booking?.startDate).format("DD MMM YYYY")}
                      </Typography>
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      paddingBottom: "12px",
                    }}
                  >
                    <Typography>
                      <Typography sx={{ fontSize: "16px", color: "white" }}>
                        Confirmation Code
                      </Typography>
                      <Typography sx={{ fontSize: "12px" }}>
                        {data?.booking?.code}
                      </Typography>
                    </Typography>
                  </Box>
                </Box>

                {isHost && (
                  <Link
                    to={`/admin/calendar/${data?.property?.id}`}
                    style={{
                      color: "white",
                      textDecoration: "underline",
                      fontSize: "14px",
                    }}
                  >
                    Show calendar
                  </Link>
                )}
              </Box>

              {/* CHECKING INSTRUCTIONS */}
              {booking?.property?.checkInInstruction && (
                <Box
                  sx={{
                    padding: "25px 0px",
                    borderBottom: "0.5px solid #0095ff",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Jost",
                      fontSize: "22px",
                      color: "white",
                    }}
                  >
                    Check-in Instructions
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: "white" }}>
                    {booking?.property?.checkInInstruction
                      ? booking?.property?.checkInInstruction
                      : "No instructions"}
                  </Typography>
                </Box>
              )}

              {/* WIFI DETAILS */}
              {booking?.property?.wifiUsername &&
                booking?.property?.wifiPassword && (
                  <Box
                    sx={{
                      padding: "25px 0px",
                      borderBottom: "0.5px solid #0095ff",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Jost",
                        fontSize: "22px",
                        color: "white",
                      }}
                    >
                      Wifi Details
                    </Typography>
                    <Typography sx={{ fontSize: "14px", color: "white" }}>
                      Wifi Username:{" "}
                      {booking?.property?.wifiUsername
                        ? booking?.property?.wifiUsername
                        : "Not provided"}
                    </Typography>
                    <Typography sx={{ fontSize: "14px", color: "white" }}>
                      Wifi Password:{" "}
                      {booking?.property?.wifiPassword
                        ? booking?.property?.wifiPassword
                        : "Not provided"}
                    </Typography>
                  </Box>
                )}
              {/* WHO IS COMING */}
              {booking?.whoIsComing && (
                <Box
                  sx={{
                    padding: "25px 0px",
                    borderBottom: "0.5px solid #0095ff",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Jost",
                      fontSize: "22px",
                      color: "white",
                    }}
                  >
                    Who is coming
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: "white" }}>
                    {booking?.whoIsComing
                      ? booking?.whoIsComing
                      : "No Description Provided"}
                  </Typography>
                </Box>
              )}

              {/* <Box
              sx={{
                padding: '25px 0px',
                borderBottom: '0.5px solid #0095ff',
              }}
            >
              <Typography
                sx={{ fontFamily: 'Jost', fontSize: '22px', color: 'white' }}
              >
                Guest Paid
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography>
                    <Typography sx={{ fontSize: '14px', color: 'white' }}>
                      Accomodation
                    </Typography>
                  </Typography>
                  <Typography style={{ fontSize: '14px' }}>$10.00</Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography>
                    <Typography sx={{ fontSize: '14px', color: 'white' }}>
                      Guest Service Fee
                    </Typography>
                  </Typography>
                  <Typography style={{ fontSize: '14px' }}>$0.30</Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography>
                    <Typography sx={{ fontSize: '14px', color: 'white' }}>
                      Total(USD)
                    </Typography>
                  </Typography>
                  <Typography style={{ fontSize: '14px' }}>$9.70</Typography>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                padding: '25px 0px',
                borderBottom: '0.5px solid #0095ff',
              }}
            >
              <Typography
                sx={{ fontFamily: 'Jost', fontSize: '22px', color: 'white' }}
              >
                Host Payout
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography>
                    <Typography sx={{ fontSize: '14px', color: 'white' }}>
                      Total Stay Price
                    </Typography>
                    <Link
                      to="/"
                      style={{
                        color: 'white',
                        textDecoration: 'underline',
                        fontSize: '12px',
                      }}
                    >
                      Show breakpoints
                    </Link>
                  </Typography>
                  <Typography style={{ fontSize: '14px' }}>${data?.booking?.totalAmount}.00</Typography>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography>
                    <Typography sx={{ fontSize: '14px', color: 'white' }}>
                      Host Service Fee(3.0%)
                    </Typography>
                  </Typography>
                  <Typography style={{ fontSize: '14px' }}>${data?.booking?.totalGST}.00</Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography>
                    <Typography sx={{ fontSize: '14px', color: 'white' }}>
                      Total(USD)
                    </Typography>
                  </Typography>
                  <Typography style={{ fontSize: '14px' }}>${data?.booking?.grossAmount}.00</Typography>
                </Box>
              </Box>
            </Box> */}

              <Box
                sx={{
                  padding: "25px 0px",
                  borderBottom: "0.5px solid #0095ff",
                }}
              >
                {/* <Link
                  to='/'
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <BsCash
                      style={{
                        marginRight: '16px',
                        fontSize: '16px',
                        color: '#fff',
                      }}
                    />
                    <div style={{ fontSize: '14px', color: 'white' }}>
                      Transactions
                    </div>
                  </Box>
                  <AiOutlineRight style={{ fontSize: '14px', color: '#fff' }} />
                </Link> */}
              </Box>
              {/* <Box
              sx={{
                padding: '25px 0px',
                borderBottom: '0.5px solid #0095ff',
              }}
            >
              <Typography
                sx={{ fontFamily: 'Jost', fontSize: '22px', color: 'white' }}
              >
                Guest Paid
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography>
                    <Typography sx={{ fontSize: '14px', color: 'white' }}>
                      Accomodation
                    </Typography>
                  </Typography>
                  <Typography style={{ fontSize: '14px' }}>$10.00</Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography>
                    <Typography sx={{ fontSize: '14px', color: 'white' }}>
                      Guest Service Fee
                    </Typography>
                  </Typography>
                  <Typography style={{ fontSize: '14px' }}>$0.30</Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography>
                    <Typography sx={{ fontSize: '14px', color: 'white' }}>
                      Total(USD)
                    </Typography>
                  </Typography>
                  <Typography style={{ fontSize: '14px' }}>$9.70</Typography>
                </Box>
              </Box>
            </Box> */}

              {/* CALENDER NOTE */}
              {isHost && (
                <Box
                  sx={{
                    padding: "25px 0px",
                    borderBottom: "0.5px solid #0095ff",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Jost",
                      fontSize: "22px",
                      color: "white",
                    }}
                  >
                    Calendar Note
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <BsLockFill
                        style={{ fontSize: "14px", marginRight: "6px" }}
                      />
                      <div
                        style={{
                          fontSize: "14px",
                          color: "rgba(255, 255, 255, 0.7)",
                        }}
                      >
                        Add a private member for these dates that only can view
                      </div>
                    </Box>
                  </Typography>
                  <textarea
                    aria-label='maximum height'
                    style={{
                      width: "100%",
                      background: "transparent",
                      fontSize: "14px",
                      borderRadius: "4px",
                      padding: "2px",
                      color: "white",
                    }}
                    value={formik.values.privateNote}
                    name='privateNote'
                    onChange={formik.handleChange}
                    rows={5}
                  />
                  {formik.touched.privateNote &&
                    Boolean(formik.errors.privateNote) && (
                      <div>{formik.errors.privateNote}</div>
                    )}
                  <Button
                    variant='outlined'
                    sx={{
                      fontFamily: "Jost",
                      fontSize: "14px",
                      marginTop: "6px",
                    }}
                    onClick={formik.handleSubmit}
                  >
                    Save
                  </Button>
                </Box>
              )}
              <Box
                sx={{
                  padding: "25px 0px",
                }}
              >
                <Typography
                  sx={{ fontFamily: "Jost", fontSize: "22px", color: "white" }}
                >
                  Support
                </Typography>

                <Box
                  sx={{
                    padding: "25px 0px",
                    borderBottom: "0.5px solid #0095ff",
                    borderTop: "0.5px solid #0095ff",
                  }}
                >
                  <Link
                    to={`/additional-payments/${data?.booking?.id}`}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <BsCash
                        style={{
                          marginRight: "16px",
                          fontSize: "16px",
                          color: "#fff",
                        }}
                      />
                      <div style={{ fontSize: "14px", color: "white" }}>
                        Send or request money
                      </div>
                    </Box>
                    <AiOutlineRight
                      style={{ fontSize: "14px", color: "#fff" }}
                    />
                  </Link>
                </Box>

                {/* REPORT USER */}
                {/* <Box
                  sx={{
                    padding: '25px 0px',
                    borderBottom: '0.5px solid #0095ff',
                    borderTop: '0.5px solid #0095ff',
                    fontSize: '16px',
                  }}
                >
                  <Link
                    to='/#'
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <AiOutlineFileExclamation
                        style={{
                          marginRight: '16px',
                          fontSize: '18px',
                          color: '#fff',
                        }}
                      />
                      <div style={{ fontSize: '14px', color: 'white' }}>
                        Report User
                      </div>
                    </Box>
                    <AiOutlineRight
                      style={{ fontSize: '14px', color: '#fff' }}
                    />
                  </Link>
                </Box> */}
                {/* <Box
                  sx={{
                    padding: '25px 0px',
                    borderBottom: '0.5px solid #0095ff',
                    borderTop: '0.5px solid #0095ff',
                    fontSize: '16px',
                  }}
                >
                  User Reported
                </Box>
                <Box
                  sx={{
                    padding: '25px 0px',
                    borderBottom: '0.5px solid #0095ff',
                    borderTop: '0.5px solid #0095ff',
                  }}
                >
                  <Link
                    to='/'
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <IoIosHelpCircle
                        style={{
                          marginRight: '16px',
                          fontSize: '18px',
                          color: '#fff',
                        }}
                      />
                      <div style={{ fontSize: '14px', color: 'white' }}>
                        Get Help
                      </div>
                    </Box>
                    <AiOutlineRight
                      style={{ fontSize: '14px', color: '#fff' }}
                    />
                  </Link>
                </Box> */}
              </Box>
              {!isHost && data?.booking?.bookingStatus === "completed" && (
                <Box
                  sx={{
                    padding: "25px 0px",
                    borderBottom: "0.5px solid #0095ff",
                    borderTop: "0.5px solid #0095ff",
                  }}
                >
                  <Link
                    state={{ booking: data?.booking }}
                    to={`/rate-host/${booking?.propertyOwner?.id}/${booking?.property?.id}/${data?.booking?.id}?host=${booking?.propertyOwner?.firstName}`}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <AiFillStar
                        style={{
                          color: "#ff2400",
                          fontSize: "14px",
                          marginRight: "4px",
                        }}
                      />
                      <div style={{ fontSize: "14px", color: "white" }}>
                        Rate the host
                      </div>
                    </Box>
                    <AiOutlineRight
                      style={{ fontSize: "14px", color: "#fff" }}
                    />
                  </Link>
                </Box>
              )}
              {isHost && data?.booking?.bookingStatus === "completed" && (
                <Box
                  sx={{
                    padding: "25px 0px",
                    borderBottom: "0.5px solid #0095ff",
                    borderTop: "0.5px solid #0095ff",
                  }}
                >
                  <Link
                    state={{ booking: booking }}
                    to={`/admin/rate-guest/${booking?.guest?.id}/${booking?.property?.id}/${data?.booking?.id}?guest=${booking?.guest?.firstName}`}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <AiFillStar
                        style={{
                          color: "#ff2400",
                          fontSize: "14px",
                          marginRight: "4px",
                        }}
                      />
                      <div style={{ fontSize: "14px", color: "white" }}>
                        Rate the guest
                      </div>
                    </Box>
                    <AiOutlineRight
                      style={{ fontSize: "14px", color: "#fff" }}
                    />
                  </Link>
                </Box>
              )}
              {/* COMMON QUESTIONS */}
              <Box
                sx={{
                  padding: "25px 0px",
                  borderBottom: "0.5px solid #0095ff",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Jost",
                    fontSize: "22px",
                    color: "white",
                    marginBottom: 2,
                  }}
                >
                  Common Questions
                </Typography>
                <Box>
                  {commonQuestions.map((item, index) => {
                    return (
                      <ReadMore
                        title={item.title}
                        key={index}
                        commonQuestions={commonQuestions}
                        index={index}
                      >
                        {item.description}
                      </ReadMore>
                    );
                  })}
                </Box>
                <Link
                  to='/'
                  style={{
                    color: "white",
                    textDecoration: "underline",
                    fontSize: "18px",
                    letterSpacing: 1,
                    fontWeight: "bolder",
                  }}
                >
                  Show more topics
                </Link>
              </Box>
              {/* =========END COMMON QUESTIONS======= */}
            </DialogContentText>
          </DialogContent>
        )}
      </Box>
    </>
  );
};

export default ReservationDetailCard;
