import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/system";
import {
  Checkbox,
  DialogActions,
  FormControlLabel,
  FormGroup,
  Grid,
  Rating,
  TextField,
  Typography,
} from "@mui/material";
import { ProgressSpinner } from "primereact/progressspinner";
import PaymentIcon from "@mui/icons-material/Payment";
import DiscountIcon from "@mui/icons-material/Discount";
import PaymentsIcon from "@mui/icons-material/Payments";
import { Link, useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  AiFillStar,
  AiFillHome,
  AiOutlineRight,
  AiOutlineFileExclamation,
} from "react-icons/ai";
import { MdVerifiedUser } from "react-icons/md";
import { BiLogInCircle } from "react-icons/bi";
import { FcBusinessman } from "react-icons/fc";
import { IoIosHelpCircle } from "react-icons/io";
import { BsCash, BsLockFill } from "react-icons/bs";
import { Clear } from "@mui/icons-material";
import Buttons from "../../../components/form/button";
import moment from "moment";
import getBookingGuestAPI from "../../../services/booking/getBookingGuest";
import { InputTextarea } from "primereact/inputtextarea";
import { whoIsComingApi } from "../../../services/booking/whoIsComing";
import { useSnackbar } from "notistack";
import axios from "axios";
import { API_URL } from "../../../config";
import NightlightRoundIcon from "@mui/icons-material/NightlightRound";
import { calculateBookingPriceApi } from "./../../../services/booking/calculateBookingPrice";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PetsIcon from "@mui/icons-material/Pets";
import ChildCareIcon from "@mui/icons-material/ChildCare";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import { useCountdown } from "../../../components/BookingComponents/CountDownHook";
import { useFormik } from "formik";
import * as yup from "yup";
import { editPropertyCalenderDateByRange } from "../../../services/properties/propertyCalenderPrice";
import { Dropdown } from "primereact/dropdown";
import { options } from "../../../constants/countries";
import { FaCcMastercard, FaCcVisa } from "react-icons/fa";
import { Toast } from "primereact/toast";
import Select from "react-select";

function ReservationDetailForModificationRequest({
  open,
  handleClose,
  descriptionElementRef,
  booking,
  type,
  header,
  showInstruction,
  detailType,
  reject,
  hostApprove,
  guestAccept,
  data,
  toast,
  wallet,
  useWallet,
  setUseWalletFunc,
  setUseCreditCardFunc,
  walletLessAmountError,
  userCards,
  card,
  setCard,
  useCreditCard,
}) {
  const { getBookingDetail } = getBookingGuestAPI();
  const [bookingDetail, setBookingDetail] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [whoIsComing, setWhoIsComing] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [values, setValues] = useState({});
  const navigate = useNavigate();
  const getBookingData = async (id) => {
    try {
      // setIsLoading(false);
      const response = await getBookingDetail(id);
      if (response.status === 200) {
        setBookingDetail(response.data?.data);
        let dataaaa = response.data?.data;
        setWhoIsComing(dataaaa?.whoIsComing);
        try {
          const resulttt = await calculateBookingPriceApi({
            propertyId: dataaaa.property.id,
            startDate: moment(dataaaa.startDate).format("YYYY-MM-DD"),
            endDate: moment(dataaaa.endDate).format("YYYY-MM-DD"),
            noOfAdults: dataaaa.totalAdults,
            noOfChildren: dataaaa.totalChildren,
            noOfInfants: dataaaa.totalInfants,
            noOfPets: dataaaa.totalPets,
            validate: false,
          });
          setValues(resulttt.data.data);
        } catch (error) {}
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getBookingData(booking?.id);
  }, [booking]);
  //Country options dropdown templates
  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className='country-item country-item-value'>
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className='country-item'>
        <div>{option.name}</div>
      </div>
    );
  };
  const handleWhoIsComing = async () => {
    let payload = {
      bookingId: bookingDetail?.id,
      userId: bookingDetail?.guestId,
      whoIsComing:
        bookingDetail?.whoIsComing == null
          ? whoIsComing
          : bookingDetail?.whoIsComing,
    };
    setIsLoading(true);
    try {
      let res = await whoIsComingApi(payload);
      if (res.status === 200) {
        setIsLoading(false);
        handleClose();
        enqueueSnackbar(res?.data?.message, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      } else {
        setIsLoading(false);
        enqueueSnackbar(res?.data?.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    } catch (error) {}
  };

  let staywoFee = (booking?.commissionFee * bookingDetail?.grossAmount) / 100;

  const RenderActions = () => {
    return (
      <DialogActions
        sx={{
          fontFamily: "Jost",
          fontSize: "25px",
          background: "#17212f",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          sx={{
            color: "rgba(255, 255, 255, 0.7)",
          }}
          autoFocus
          onClick={handleClose}
        >
          Close
        </Button>
        <div>
          <Button
            sx={{
              color: "rgba(255, 255, 255, 0.7)",
            }}
            autoFocus
            onClick={reject}
          >
            Decline
          </Button>

          {type === "host" ? (
            <Button
              sx={{
                color: "rgba(255, 255, 255, 0.7)",
              }}
              onClick={hostApprove}
            >
              Accept
            </Button>
          ) : (
            <Button
              sx={{
                color: "rgba(255, 255, 255, 0.7)",
              }}
              onClick={guestAccept}
            >
              Accept
            </Button>
          )}
        </div>
      </DialogActions>
    );
  };

  const formik = useFormik({
    initialValues: {
      propertyId: bookingDetail?.property?.id,
      privateNote: "",
      startDate: bookingDetail?.startDate,
      endDate: bookingDetail?.endDate,
    },
    validationSchema: yup.object({
      privateNote: yup.string().required("Private Note Is Required."),
    }),
    enableReinitialize: true,
    onSubmit: async (values) => {
      const res = await editPropertyCalenderDateByRange(values);
      if (res.status === 200 || res.status === 201) {
        enqueueSnackbar("Calendar note added!", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    },
  });

  const pathName = window.location.pathname;

  let endDate = moment(booking?.endDate);
  let today = moment();

  let differenceOfTodayWithEndDate = today.diff(endDate, "days");
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: "black",
      fontSize: "16px",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      const color = "#fff";
      return { ...provided, opacity, transition, color };
    },
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
        sx={{
          "& .MuiPaper-root": {
            overflowX: "hidden",
          },
        }}
      >
        <DialogTitle
          id='scroll-dialog-title'
          sx={{
            fontFamily: "Jost",
            fontSize: "25px",
            borderBottom: "0.5px solid #0095ff",
            background: "#17212f",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontFamily: "Jost", fontSize: "25px" }}>
              {header}
            </Typography>
            {/* <Button
              variant="outlined"
              sx={{ fontFamily: 'Jost', fontSize: '14px' }}
            >
              Print
            </Button> */}
          </Box>
        </DialogTitle>
        <DialogContent
          sx={{ height: "90vh", width: "32vw", background: "#1c172b" }}
        >
          <DialogContentText
            id='scroll-dialog-description'
            ref={descriptionElementRef}
            tabIndex={-1}
            sx={{ width: "100%" }}
          >
            <Box component='div' sx={{ marginTop: "10px" }}>
              <Typography
                sx={{ fontFamily: "Jost", fontSize: "22px", color: "white" }}
              >
                Property Details
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "10px 0px",
                  borderBottom: "0.5px solid #0095ff",
                }}
              >
                <Box>
                  <img
                    src={booking?.property?.thumbnailUrl}
                    alt='Property'
                    width={130}
                    height={130}
                  />
                </Box>
                <Box sx={{ marginLeft: "10px" }}>
                  <Typography
                    sx={{ color: "#fff", fontFamily: "Jost", fontSize: "22px" }}
                  >
                    {booking?.property?.title &&
                    booking?.property?.title?.length > 30
                      ? booking?.property?.title?.slice(0, 30) + "..."
                      : booking?.property?.title?.slice(0, 30)}
                  </Typography>
                  <Typography sx={{ fontFamily: "Jost", fontSize: "14px" }}>
                    {booking?.property?.city}, {booking?.property?.country}
                  </Typography>
                  <Typography sx={{ fontFamily: "Jost", fontSize: "14px" }}>
                    Property ID: {booking?.property?.code}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Jost",
                      fontSize: "16px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name='half-rating-read'
                      value={
                        bookingDetail?.property?.average_rating
                          ? bookingDetail?.property?.average_rating
                              ?.averageRating
                          : 0
                      }
                      precision={0.5}
                      readOnly
                      sx={{ marginRight: "4px" }}
                    />
                    (
                    {bookingDetail?.property?.average_rating
                      ? bookingDetail?.property?.average_rating?.averageRating?.toFixed(
                          1
                        )
                      : 0}
                    ) (
                    {bookingDetail?.property?.average_rating
                      ? `${bookingDetail?.property?.average_rating?.reviewCount} Reviews`
                      : `0 Reviews`}
                    )
                  </Typography>
                </Box>
              </Box>
            </Box>

            {/* {bookingDetail?.property?.rating == null && type == 'traveler' && (
              <Box
                sx={{
                  padding: '25px 0px',
                  borderBottom: '0.5px solid #0095ff',
                }}
              >
                <Typography sx={{ fontSize: '16px', color: 'white' }}>
                  {booking?.owner.firstName} review:
                </Typography>
                <Typography sx={{ fontSize: '14px' }}>
                  Nice place to stay, hosts are cooperative
                </Typography>
                <Link
                  to='/'
                  style={{
                    color: 'white',
                    textDecoration: 'underline',
                    fontSize: '14px',
                  }}
                >
                  Show review
                </Link>
              </Box>
            )} */}
            <Box
              sx={{
                padding: "25px 0px",
                borderBottom: "0.5px solid #0095ff",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  sx={{ fontFamily: "Jost", fontSize: "22px", color: "white" }}
                >
                  About{" "}
                  {type === "host" ? (
                    <a
                      href={`/about-host/${booking?.guest?.id}`}
                      style={{ color: "#fff" }}
                    >
                      {booking?.guest?.firstName}
                    </a>
                  ) : (
                    <a
                      href={`/about-host/${booking?.owner?.id}`}
                      style={{ color: "#fff" }}
                    >
                      {" "}
                      {booking?.owner?.firstName}{" "}
                    </a>
                  )}
                </Typography>
                <a
                  href={
                    type == "traveler"
                      ? `/about-host/${booking?.owner?.id}`
                      : `/about-host/${booking?.guest?.id}`
                  }
                  target='_blank'
                  rel='noreferrer'
                >
                  <img
                    src={
                      type === "host"
                        ? booking?.guest?.profilePicture !== null
                          ? booking?.guest?.profilePicture
                          : require("../../../assets/images/avatar/5.jpg")
                        : booking?.owner?.profilePicture !== null
                        ? booking?.owner?.profilePicture
                        : require("../../../assets/images/avatar/5.jpg")
                    }
                    style={{
                      borderRadius: "50%",
                      width: "65px",
                      height: "65px",
                    }}
                    alt=''
                  />
                </a>
              </div>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ display: "flex", alignItems: "center" }}>
                  <Rating
                    name='half-rating-read'
                    value={
                      type == "host"
                        ? bookingDetail?.guest?.guestAverageRating
                          ? bookingDetail?.guest?.guestAverageRating
                              ?.averageRating
                          : 0
                        : bookingDetail?.owner?.average_rating
                        ? bookingDetail?.owner?.average_rating?.averageRating
                        : 0
                    }
                    precision={0.5}
                    readOnly
                    size='small'
                  />
                  <span style={{ marginLeft: "10px" }}>
                    (
                    {type == "host"
                      ? bookingDetail?.guest?.guestAverageRating
                        ? bookingDetail?.guest?.guestAverageRating?.averageRating?.toFixed(
                            1
                          )
                        : 0
                      : bookingDetail?.owner?.average_rating
                      ? bookingDetail?.owner?.average_rating?.averageRating?.toFixed(
                          1
                        )
                      : 0}
                    )
                  </span>
                  <span
                    style={{
                      fontSize: "14px",
                      color: "rgba(255, 255, 255, 0.7)",
                    }}
                  >
                    (
                    {type == "host"
                      ? bookingDetail?.guest?.guestAverageRating
                        ? bookingDetail?.guest?.guestAverageRating?.reviewCount
                        : 0
                      : bookingDetail?.owner?.average_rating
                      ? bookingDetail?.owner?.average_rating?.averageRating?.toFixed(
                          1
                        )
                      : 0}{" "}
                    Reviews)
                  </span>
                </Typography>
                {booking?.owner?.verified && (
                  <Typography sx={{ display: "flex", alignItems: "center" }}>
                    <MdVerifiedUser
                      style={{
                        fontSize: "14px",
                        color: "white",
                        marginRight: "4px",
                      }}
                    />
                    <Link
                      to='/'
                      style={{
                        textDecoration: "underline",
                        fontSize: "14px",
                        color: "rgba(255, 255, 255, 0.7)",
                      }}
                    >
                      Indenitiy Verified
                    </Link>
                  </Typography>
                )}

                <Typography sx={{ display: "flex", alignItems: "center" }}>
                  <BiLogInCircle
                    style={{
                      fontSize: "14px",
                      color: "white",

                      marginRight: "4px",
                    }}
                  />
                  <Link
                    to='/'
                    style={{
                      fontSize: "14px",
                      color: "rgba(255, 255, 255, 0.7)",
                    }}
                  >
                    Joined on{" "}
                    {type === "host"
                      ? moment(booking?.guest?.createdOn).format("DD MMM YYYY")
                      : moment(booking?.owner?.createdOn).format("DD MMM YYYY")}
                  </Link>
                </Typography>
                {!(type == "host") && (
                  <>
                    {bookingDetail?.owner?.country != "" ? (
                      <Typography
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <AiFillHome
                          style={{
                            fontSize: "14px",
                            color: "white",

                            marginRight: "4px",
                          }}
                        />
                        {/* <Link
                      to='/'
                      style={{
                        fontSize: '14px',
                        color: 'rgba(255, 255, 255, 0.7)',
                      }}
                    > */}
                        Lives in {bookingDetail?.owner?.country}
                        {/* </Link> */}
                      </Typography>
                    ) : (
                      ""
                    )}
                  </>
                )}
                {type == "host" && (
                  <Typography sx={{ display: "flex", alignItems: "center" }}>
                    <FcBusinessman
                      style={{
                        fontSize: "16px",
                        color: "white",
                        marginRight: "4px",
                      }}
                    />
                    <Link
                      to={`/about-host/${booking?.guest?.id}`}
                      target='_blank'
                      style={{
                        fontSize: "14px",
                        color: "rgba(255, 255, 255, 0.7)",
                      }}
                    >
                      Also a host
                    </Link>
                  </Typography>
                )}
              </Box>
              {!pathName.includes("admin/messages") && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    variant='outlined'
                    sx={{ width: "48%", marginTop: 2 }}
                    onClick={() => {
                      window.location.href = `/admin/messages/${bookingDetail?.guest?.id}`;
                    }}
                  >
                    Message
                  </Button>
                  <Button
                    variant='outlined'
                    sx={{ width: "48%", marginTop: 2 }}
                    onClick={() => {}}
                    disabled={
                      detailType === "PAST" && differenceOfTodayWithEndDate > 15
                    }
                  >
                    Call
                  </Button>
                </Box>
              )}

              {!(type == "host") && (
                <Link
                  to={`/about-host/${bookingDetail?.owner?.id}`}
                  target='_blank'
                  style={{
                    color: "white",
                    textDecoration: "underline",
                    fontSize: "14px",
                  }}
                >
                  Show profile
                </Link>
              )}
              {/* <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '2',
                  gridGap: '4px',
                  marginTop: '6px',
                }}
              >
                <Button
                  variant="outlined"
                  sx={{ fontFamily: 'Jost', fontSize: '14px' }}
                >
                  Message
                </Button>
                <Button
                  variant="outlined"
                  sx={{ fontFamily: 'Jost', fontSize: '14px' }}
                >
                  Call
                </Button>
              </Box> */}
            </Box>

            <Box
              sx={{
                padding: "25px 0px",
                borderBottom: "0.5px solid #0095ff",
              }}
            >
              <Typography
                sx={{ fontFamily: "Jost", fontSize: "22px", color: "white" }}
              >
                Booking Details
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ fontSize: "16px", color: "white" }}>
                  Booking nights
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {moment(bookingDetail?.startDate).format("DD MMM YYYY")} -{" "}
                  {moment(bookingDetail?.endDate).format("DD MMM YYYY")} ({" "}
                  <NightlightRoundIcon sx={{ fontSize: "15px" }} />{" "}
                  {bookingDetail?.numberOfNights} night
                  {bookingDetail?.numberOfNights > 1 ? "s" : ""})
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "14px 0px",
                  }}
                >
                  <Typography>
                    <Typography sx={{ fontSize: "16px", color: "white" }}>
                      Guests
                    </Typography>
                    <Typography sx={{ fontSize: "14px" }}>
                      <p>
                        <i className='fas fa-user'></i>{" "}
                        {bookingDetail?.totalGuests} guests
                      </p>
                      <p>
                        {bookingDetail?.totalAdults > 0
                          ? bookingDetail?.totalAdults + " Adults"
                          : "0 Adults"}
                        {" - "}
                        {bookingDetail?.totalChildren > 0
                          ? bookingDetail?.totalChildren + " Children"
                          : "0 Children"}
                      </p>

                      <p>
                        {bookingDetail?.totalInfants > 0
                          ? bookingDetail?.totalInfants + " Infants"
                          : "0 Infants"}
                        {" - "}
                        {bookingDetail?.totalPets > 0
                          ? bookingDetail?.totalPets + " Pets"
                          : "0 Pets"}
                      </p>
                    </Typography>
                  </Typography>
                  {/* <Link
                    to="/"
                    style={{
                      textDecoration: 'underline',
                      fontSize: '14px',
                      color: '#fff',
                    }}
                  >
                    View
                  </Link> */}
                </Box>
                <Box
                  sx={{
                    paddingBottom: "14px",
                  }}
                >
                  <Typography>
                    <Typography sx={{ fontSize: "16px", color: "white" }}>
                      Check In Time
                    </Typography>
                    <Typography sx={{ fontSize: "14px" }}>
                      {bookingDetail?.property?.propertyPolicy?.checkInWindow}
                    </Typography>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    paddingBottom: "14px",
                  }}
                >
                  <Typography>
                    <Typography sx={{ fontSize: "16px", color: "white" }}>
                      Check Out
                    </Typography>
                    <Typography sx={{ fontSize: "14px" }}>
                      {bookingDetail?.property?.propertyPolicy?.checkoutWindow}
                    </Typography>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    paddingBottom: "14px",
                  }}
                >
                  <Typography>
                    <Typography sx={{ fontSize: "16px", color: "white" }}>
                      Booking Date
                    </Typography>
                    <Typography sx={{ fontSize: "14px" }}>
                      {moment(bookingDetail?.createdOn).format("DD MMM YYYY")}
                    </Typography>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    paddingBottom: "14px",
                  }}
                >
                  <Typography>
                    <Typography sx={{ fontSize: "16px", color: "white" }}>
                      Confirmation Code
                    </Typography>
                    <Typography sx={{ fontSize: "14px" }}>
                      {booking?.code}
                    </Typography>
                  </Typography>
                </Box>
              </Box>

              {type == "host" && (
                <Link
                  to={`/admin/calendar/${bookingDetail?.property?.id}`}
                  style={{
                    color: "white",
                    textDecoration: "underline",
                    fontSize: "14px",
                  }}
                >
                  Show calendar
                </Link>
              )}
            </Box>

            {showInstruction && (
              <Box
                sx={{
                  padding: "25px 0px",
                  borderBottom: "0.5px solid #0095ff",
                }}
              >
                <Typography
                  sx={{ fontFamily: "Jost", fontSize: "22px", color: "white" }}
                >
                  Check-in Instructions
                </Typography>
                <Typography sx={{ fontSize: "14px", color: "white" }}>
                  {bookingDetail?.property?.checkInInstruction
                    ? bookingDetail?.property?.checkInInstruction
                    : "No instructions"}
                </Typography>
              </Box>
            )}
            {showInstruction && (
              <Box
                sx={{
                  padding: "25px 0px",
                  borderBottom: "0.5px solid #0095ff",
                }}
              >
                <Typography
                  sx={{ fontFamily: "Jost", fontSize: "22px", color: "white" }}
                >
                  Wifi Details
                </Typography>
                <Typography sx={{ fontSize: "14px", color: "white" }}>
                  Wifi Username:{" "}
                  {bookingDetail?.property?.wifiUsername
                    ? bookingDetail?.property?.wifiUsername
                    : "Not provided"}
                </Typography>
                <Typography sx={{ fontSize: "14px", color: "white" }}>
                  Wifi Password:{" "}
                  {bookingDetail?.property?.wifiPassword
                    ? bookingDetail?.property?.wifiPassword
                    : "Not provided"}
                </Typography>
              </Box>
            )}
            {whoIsComing && (
              <Box
                sx={{
                  padding: "25px 0px",
                  borderBottom: "0.5px solid #0095ff",
                }}
              >
                <Typography
                  sx={{ fontFamily: "Jost", fontSize: "22px", color: "white" }}
                >
                  Who is coming
                </Typography>
                {type === "traveler" ? (
                  <>
                    <InputTextarea
                      rows={5}
                      cols={30}
                      value={whoIsComing}
                      onChange={(e) => setWhoIsComing(e.target.value)}
                      // disabled={
                      //   statusType === 'pending'
                      //     ? days + hours + minutes + seconds <= 0 &&
                      //       bookingDetail.bookingStatus == 'pending'
                      //     : days + hours + minutes + seconds <= 0
                      // }
                    />
                    <Buttons
                      title={"Save"}
                      onSubmit={handleWhoIsComing}
                      isLoading={isLoading}
                    />
                  </>
                ) : (
                  <Typography sx={{ fontSize: "14px", color: "white" }}>
                    {bookingDetail?.whoIsComing
                      ? bookingDetail?.whoIsComing
                      : "No Description Provided"}
                  </Typography>
                )}
              </Box>
            )}

            <Box
              sx={{
                padding: "25px 0px",
                borderBottom: "0.5px solid #0095ff",
              }}
            >
              <Typography
                sx={{ fontFamily: "Jost", fontSize: "22px", color: "white" }}
              >
                {type === "host" ? " Guest" : "You"} Paid
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    sx={{
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    <CleaningServicesIcon
                      sx={{ fontSize: "18px", marginRight: "5px" }}
                    />{" "}
                    Accomodation
                  </Typography>
                  <Typography>
                    {process.env.REACT_APP_CURRENCY}{" "}
                    {bookingDetail?.totalPrice?.toLocaleString("en-US")}
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    sx={{
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <NightlightRoundIcon
                      sx={{ fontSize: "18px", marginRight: "5px" }}
                    />{" "}
                    Nightly Price x {values?.noOfNights} (nights)
                  </Typography>
                  <Typography>
                    {process.env.REACT_APP_CURRENCY}{" "}
                    {values?.totalNightsCharges?.toLocaleString("en-US")}
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    sx={{
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    <CleaningServicesIcon
                      sx={{ fontSize: "18px", marginRight: "5px" }}
                    />{" "}
                    Cleaning Fee
                  </Typography>
                  <Typography>
                    {process.env.REACT_APP_CURRENCY}{" "}
                    {values?.cleaningFee?.toLocaleString("en-US")}{" "}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <PersonAddIcon
                      sx={{ fontSize: "18px", marginRight: "5px" }}
                    />{" "}
                    Additional Guests Fee
                  </Typography>
                  <Typography>
                    <span style={{ color: "#fff" }}>
                      {values?.totalExtraGuests}
                    </span>{" "}
                    x{" "}
                    <span style={{ color: "#fff" }}>
                      {process.env.REACT_APP_CURRENCY}{" "}
                      {values?.extraGuestFee?.toLocaleString("en-US")}
                    </span>{" "}
                    ={" "}
                    <span style={{ color: "#fff" }}>
                      {" "}
                      {process.env.REACT_APP_CURRENCY}{" "}
                      {values?.totalExtraGuestFee?.toLocaleString("en-US")}
                    </span>
                  </Typography>
                </Box>

                {values?.noOfInfants > 0 ? (
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      sx={{
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <ChildCareIcon
                        sx={{ fontSize: "18px", marginRight: "5px" }}
                      />{" "}
                      Infant Fee
                    </Typography>
                    <Typography>No Fee</Typography>
                  </Box>
                ) : (
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      sx={{
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <ChildCareIcon
                        sx={{ fontSize: "18px", marginRight: "5px" }}
                      />{" "}
                      Infant Fee
                    </Typography>
                    <Typography>Not Allowed</Typography>
                  </Box>
                )}

                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    sx={{
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <PetsIcon sx={{ fontSize: "18px", marginRight: "5px" }} />{" "}
                    Pet Fee
                  </Typography>
                  {values?.noOfPets > 0 ? (
                    <Typography>
                      {values?.noOfPets} x {process.env.REACT_APP_CURRENCY}{" "}
                      {values?.petFee?.toLocaleString("en-US")} ={" "}
                      {values?.totalPetFee?.toLocaleString("en-US")}
                    </Typography>
                  ) : (
                    <Typography>Not Allowed</Typography>
                  )}
                </Box>

                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    sx={{
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <AccountBalanceIcon
                      sx={{ fontSize: "18px", marginRight: "5px" }}
                    />{" "}
                    Service Fee ({booking?.serviceFeePercentage} %)
                  </Typography>
                  <Typography>
                    {process.env.REACT_APP_CURRENCY}{" "}
                    {values?.totalServiceCharges?.toLocaleString("en-US")}
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    sx={{
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <AccountBalanceIcon
                      sx={{ fontSize: "18px", marginRight: "5px" }}
                    />{" "}
                    GST ({values?.gst} %)
                  </Typography>
                  <Typography>
                    {process.env.REACT_APP_CURRENCY}{" "}
                    {values?.totalGSTFee?.toLocaleString("en-US")}
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: "19px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <DoneOutlineIcon
                      sx={{ fontSize: "19px", marginRight: "5px" }}
                    />{" "}
                    Total ( {process.env.REACT_APP_CURRENCY} )
                  </Typography>
                  <Typography sx={{ fontSize: "18px", color: "#fff" }}>
                    {process.env.REACT_APP_CURRENCY}{" "}
                    {bookingDetail?.grossAmount
                      ? bookingDetail?.grossAmount?.toLocaleString("en-US")
                      : 0}
                  </Typography>
                </Box>
              </Box>
            </Box>

            {type === "host" && (
              <Box
                sx={{
                  padding: "25px 0px",
                  borderBottom: "0.5px solid #0095ff",
                }}
              >
                <Typography
                  sx={{ fontFamily: "Jost", fontSize: "22px", color: "white" }}
                >
                  Host payout
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: "14px", color: "white" }}>
                      Total Stay Price
                    </Typography>

                    <Typography style={{ fontSize: "14px" }}>
                      {process.env.REACT_APP_CURRENCY}{" "}
                      {bookingDetail?.grossAmount?.toLocaleString("en-US")}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: "14px", color: "white" }}>
                      Staywo Fee
                    </Typography>
                    <Typography style={{ fontSize: "14px" }}>
                      {process.env.REACT_APP_CURRENCY}{" "}
                      {staywoFee.toLocaleString("en-US")}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography>
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "19px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <DoneOutlineIcon
                          sx={{ fontSize: "19px", marginRight: "5px" }}
                        />{" "}
                        Total ( {process.env.REACT_APP_CURRENCY} )
                      </Typography>
                    </Typography>
                    <Typography sx={{ fontSize: "18px", color: "#fff" }}>
                      {process.env.REACT_APP_CURRENCY}{" "}
                      {(bookingDetail?.grossAmount - staywoFee).toLocaleString(
                        "en-US"
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}

            <Box
              sx={{
                padding: "25px 0px",
                borderBottom: "0.5px solid #0095ff",
              }}
            >
              <Typography
                sx={{ fontFamily: "Jost", fontSize: "22px", color: "white" }}
              >
                Extended Payment Breakpoints
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    sx={{
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <NightlightRoundIcon
                      sx={{ fontSize: "18px", marginRight: "5px" }}
                    />{" "}
                    Nightly Price x {data?.extraNights} (extended nights)
                  </Typography>
                  <Typography>
                    {process.env.REACT_APP_CURRENCY}{" "}
                    {data?.extraNightFee?.toLocaleString("en-US")}
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    sx={{
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <AccountBalanceIcon
                      sx={{ fontSize: "18px", marginRight: "5px" }}
                    />{" "}
                    Service Fee ({data?.serviceFee} %)
                  </Typography>
                  <Typography>
                    {process.env.REACT_APP_CURRENCY}{" "}
                    {data?.serviceCharges?.toLocaleString("en-US")}
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    sx={{
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <AccountBalanceIcon
                      sx={{ fontSize: "18px", marginRight: "5px" }}
                    />{" "}
                    GST ({data?.totalGST} %)
                  </Typography>
                  <Typography>
                    {process.env.REACT_APP_CURRENCY}{" "}
                    {data?.totalGSTCharges?.toLocaleString("en-US")}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: "19px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <DoneOutlineIcon
                        sx={{ fontSize: "19px", marginRight: "5px" }}
                      />{" "}
                      Total Extended Stay Price ({" "}
                      {process.env.REACT_APP_CURRENCY} )
                    </Typography>
                  </Box>

                  <Typography sx={{ fontSize: "18px", color: "#fff" }}>
                    {process.env.REACT_APP_CURRENCY}{" "}
                    {data?.grossAmount?.toLocaleString("en-US")}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: "19px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <DoneOutlineIcon
                        sx={{ fontSize: "19px", marginRight: "5px" }}
                      />{" "}
                      Total Booking Price ( {process.env.REACT_APP_CURRENCY} )
                    </Typography>
                  </Box>

                  <Typography sx={{ fontSize: "18px", color: "#fff" }}>
                    {process.env.REACT_APP_CURRENCY}{" "}
                    {(
                      bookingDetail?.grossAmount + data?.grossAmount
                    )?.toLocaleString("en-US")}
                  </Typography>
                </Box>
              </Box>
            </Box>
            {type == "traveler" && !(data?.initiatedBy === "GUEST") && (
              <Box
                sx={{
                  padding: "25px 0px",
                  borderBottom: "0.5px solid #0095ff",
                }}
              >
                <Typography
                  sx={{ fontFamily: "Jost", fontSize: "22px", color: "white" }}
                >
                  Enter Payment Details
                </Typography>
                <>
                  <form>
                    <Toast ref={toast} position='bottom-right' />
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: "22px",
                        fontFamily: "Jost",
                        display: "flex",
                        alignItems: "center",
                        paddingTop: "15px",
                        paddingBottom: "15px",
                      }}
                    >
                      <PaymentIcon
                        sx={{ fontSize: "22px", marginRight: "5px" }}
                      />{" "}
                      Pay With
                    </Typography>
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: "22px",
                        fontFamily: "Jost",
                        display: "flex",
                        alignItems: "center",
                        // margin: '12px 0px',
                        paddingTop: "12px",
                        borderTop: "1px solid rgba(255, 255, 255, 0.1)",
                      }}
                    >
                      Your Wallet
                    </Typography>
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "25px",
                            color: "#fff",
                          }}
                        >
                          <PaymentsIcon
                            style={{
                              marginRight: "16px",
                              fontSize: "18px",
                              color: "#fff",
                            }}
                          />
                          <span
                            style={{
                              display: "inline-flex",
                              alignItems: "center",
                              color: "rgba(255, 255, 255, 0.7)",
                            }}
                          >
                            {wallet?.amount
                              ? wallet?.amount?.toLocaleString("en-US")
                              : 0}{" "}
                            {process.env.REACT_APP_CURRENCY}
                            <AddCircleIcon
                              style={{
                                marginRight: "16px",
                                fontSize: "25px",
                                color: "rgba(255, 255, 255, 0.7)",
                                cursor: "pointer",
                                marginLeft: "10px",
                              }}
                              onClick={() => navigate("/wallet/top-up")}
                            />
                          </span>
                        </Typography>
                      </Box>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={useWallet ? true : false}
                              value={useWallet}
                              onChange={setUseWalletFunc}
                            />
                          }
                          label='Use your wallet.'
                        />
                      </FormGroup>
                      {walletLessAmountError && (
                        <Typography
                          sx={{
                            color: "yellow",
                            fontSize: "16px",
                            fontFamily: "Jost",
                            display: "flex",
                            alignItems: "center",
                            marginTop: "12px",
                            fontWeight: "400",
                          }}
                        >
                          <span
                            style={{
                              color: "#fff",
                              fontWeight: "bold",
                              marginRight: "4px",
                            }}
                          >
                            Info:
                          </span>{" "}
                          {walletLessAmountError}
                        </Typography>
                      )}
                    </Box>

                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: "22px",
                        fontFamily: "Jost",
                        display: "flex",
                        alignItems: "center",
                        paddingTop: "12px",
                        borderTop: "1px solid rgba(255, 255, 255, 0.1)",
                        marginTop: "12px",
                      }}
                    >
                      Your Card
                    </Typography>
                    {userCards?.length > 0 ? (
                      <div style={{ width: "50%" }}>
                        <Select
                          className='override-select-edit'
                          placeholder='Select Card'
                          options={userCards}
                          value={
                            card.value == undefined
                              ? { label: "Select Card", value: "" }
                              : card
                          }
                          onChange={(option) => setCard(option)}
                          styles={customStyles}
                          formatOptionLabel={(item) => (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {item?.brand === "Visa" && (
                                <FaCcVisa
                                  style={{
                                    marginRight: "8px",
                                    fontSize: "25px",
                                  }}
                                />
                              )}
                              {item?.brand === "MasterCard" && (
                                <FaCcMastercard
                                  style={{
                                    marginRight: "8px",
                                    fontSize: "25px",
                                  }}
                                />
                              )}

                              {item?.brand}
                              <span
                                style={{ marginLeft: "10px" }}
                              >{`${item?.label}`}</span>
                            </div>
                          )}
                        />
                      </div>
                    ) : (
                      <Typography
                        sx={{
                          color: "white",
                          fontSize: "16px",
                          fontFamily: "Jost",
                          display: "flex",
                          alignItems: "center",
                          marginTop: "12px",
                          fontWeight: "400",
                        }}
                      >
                        You dont have any cards! Add one by clicking below.
                      </Typography>
                    )}
                    <Grid
                      container
                      spacing={{ xs: 2, md: 3 }}
                      columns={{ xs: 4, sm: 8, md: 12 }}
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          onClick={setUseCreditCardFunc}
                          sx={{
                            cursor: "pointer",
                            color: "#fff",
                            textDecoration: "underline",
                          }}
                        >
                          {useCreditCard
                            ? "Use Saved Credit Card"
                            : "Add Credit Card"}
                        </Typography>
                      </Grid>

                      {useCreditCard && (
                        <>
                          <Grid item xs={12}>
                            <Typography
                              sx={{
                                color: "#fff",
                                fontSize: "22px",
                                fontFamily: "Jost",
                                display: "flex",
                                alignItems: "center",
                                paddingTop: "12px",
                                borderTop: "1px solid rgba(255, 255, 255, 0.1)",
                                marginTop: "12px",
                              }}
                            >
                              Card Details
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography sx={{ textAlign: "left" }}>
                              Card Number
                            </Typography>
                            <TextField
                              sx={{ width: "100%" }}
                              id='number'
                              name='number'
                              number
                              required
                              placeholder='4242424242424242'
                              value={formik.values.number}
                              onChange={formik.handleChange}
                            />
                            {formik.touched.number &&
                              Boolean(formik.errors.number) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: "block",
                                    color: "red",
                                    textAlign: "left",
                                  }}
                                >
                                  {formik.errors.number}
                                </small>
                              )}
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography sx={{ textAlign: "left" }}>
                              Card Holder Name
                            </Typography>
                            <TextField
                              sx={{ width: "100%" }}
                              id='name'
                              name='name'
                              required
                              placeholder='Card Holder Name'
                              value={formik.values.name}
                              onChange={formik.handleChange}
                            />
                            {formik.touched.name &&
                              Boolean(formik.errors.name) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: "block",
                                    color: "red",
                                    textAlign: "left",
                                  }}
                                >
                                  {formik.errors.name}
                                </small>
                              )}
                          </Grid>
                          <Grid item xs={12} sm={4} md={4}>
                            <Typography sx={{ textAlign: "left" }}>
                              Expiration Month
                            </Typography>
                            <TextField
                              sx={{ width: "100%" }}
                              id='exp_month'
                              name='exp_month'
                              required
                              number
                              placeholder='03'
                              value={formik.values.exp_month}
                              onChange={formik.handleChange}
                            />
                            {formik.touched.exp_month &&
                              Boolean(formik.errors.exp_month) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: "block",
                                    color: "red",
                                    textAlign: "left",
                                  }}
                                >
                                  {formik.errors.exp_month}
                                </small>
                              )}
                          </Grid>
                          <Grid item xs={12} sm={4} md={4}>
                            <Typography sx={{ textAlign: "left" }}>
                              Expiration Years
                            </Typography>
                            <TextField
                              sx={{ width: "100%" }}
                              id='exp_year'
                              name='exp_year'
                              required
                              number
                              placeholder='10'
                              value={formik.values.exp_year}
                              onChange={formik.handleChange}
                            />
                            {formik.touched.exp_year &&
                              Boolean(formik.errors.exp_year) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: "block",
                                    color: "red",
                                    textAlign: "left",
                                  }}
                                >
                                  {formik.errors.exp_year}
                                </small>
                              )}
                          </Grid>
                          <Grid item xs={12} sm={4} md={4}>
                            <Typography sx={{ textAlign: "left" }}>
                              CVC
                            </Typography>
                            <TextField
                              sx={{ width: "100%" }}
                              id='cvc'
                              name='cvc'
                              required
                              number
                              placeholder='10'
                              value={formik.values.cvc}
                              onChange={formik.handleChange}
                            />
                            {formik.touched.cvc &&
                              Boolean(formik.errors.cvc) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: "block",
                                    color: "red",
                                    textAlign: "left",
                                  }}
                                >
                                  {formik.errors.cvc}
                                </small>
                              )}
                          </Grid>

                          <Grid item xs={12}>
                            <Typography
                              sx={{
                                color: "#fff",
                                fontSize: "22px",
                                fontFamily: "Jost",
                                display: "flex",
                                alignItems: "center",
                                paddingTop: "12px",
                                borderTop: "1px solid rgba(255, 255, 255, 0.1)",
                                marginTop: "12px",
                              }}
                            >
                              Billing Address
                            </Typography>
                          </Grid>

                          <Grid item xs={12}>
                            <Typography sx={{ textAlign: "left" }}>
                              Street Address
                            </Typography>
                            <TextField
                              sx={{ width: "100%" }}
                              id='address1'
                              name='address1'
                              required
                              placeholder='Street Address'
                              value={formik.values.address1}
                              onChange={formik.handleChange}
                            />
                            {formik.touched.address1 &&
                              Boolean(formik.errors.address1) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: "block",
                                    color: "red",
                                    textAlign: "left",
                                  }}
                                >
                                  {formik.errors.address1}
                                </small>
                              )}
                          </Grid>
                          <Grid item xs={12}>
                            <Typography sx={{ textAlign: "left" }}>
                              Apt or suite number
                            </Typography>
                            <TextField
                              sx={{ width: "100%" }}
                              id='address2'
                              name='address2'
                              required
                              type='text'
                              placeholder='Enter Suite Number'
                              value={formik.values.address2}
                              onChange={formik.handleChange}
                            />
                            {formik.touched.address2 &&
                              Boolean(formik.errors.address2) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: "block",
                                    color: "red",
                                    textAlign: "left",
                                  }}
                                >
                                  {formik.errors.address2}
                                </small>
                              )}
                          </Grid>
                          <Grid item xs={12}>
                            <Typography sx={{ textAlign: "left" }}>
                              City
                            </Typography>
                            <TextField
                              sx={{ width: "100%" }}
                              id='city'
                              name='city'
                              required
                              placeholder='City'
                              value={formik.values.city}
                              onChange={formik.handleChange}
                            />
                            {formik.touched.city &&
                              Boolean(formik.errors.city) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: "block",
                                    color: "red",
                                    textAlign: "left",
                                  }}
                                >
                                  {formik.errors.city}
                                </small>
                              )}
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography sx={{ textAlign: "left" }}>
                              State
                            </Typography>
                            <TextField
                              sx={{ width: "100%" }}
                              id='state'
                              name='state'
                              required
                              placeholder='State'
                              value={formik.values.state}
                              onChange={formik.handleChange}
                            />
                            {formik.touched.state &&
                              Boolean(formik.errors.state) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: "block",
                                    color: "red",
                                    textAlign: "left",
                                  }}
                                >
                                  {formik.errors.state}
                                </small>
                              )}
                          </Grid>
                          <Grid item xs={12}>
                            <Dropdown
                              value={formik.values.country}
                              options={options}
                              onChange={formik.handleChange}
                              optionLabel='name'
                              filter
                              showClear
                              filterBy='name'
                              id='country-dropdown'
                              name='country'
                              placeholder='Country/region'
                              valueTemplate={selectedCountryTemplate}
                              itemTemplate={countryOptionTemplate}
                              style={{
                                width: "100%",
                                background: "transparent",
                                padding: "6px 5px",
                                zIndex: 9999,
                              }}
                            />
                            {formik.touched.country &&
                              Boolean(formik.errors.country) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: "block",
                                    color: "red",
                                    textAlign: "left",
                                  }}
                                >
                                  {formik.errors.country}
                                </small>
                              )}
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography sx={{ textAlign: "left" }}>
                              Zip Code
                            </Typography>
                            <TextField
                              sx={{ width: "100%" }}
                              id='zipcode'
                              name='zipcode'
                              required
                              type='number'
                              placeholder='Zip Code'
                              value={formik.values.zipcode}
                              onChange={formik.handleChange}
                            />
                            {formik.touched.zipcode &&
                              Boolean(formik.errors.zipcode) && (
                                <small
                                  id='username2-help'
                                  className='p-error'
                                  style={{
                                    display: "block",
                                    color: "red",
                                    textAlign: "left",
                                  }}
                                >
                                  {formik.errors.zipcode}
                                </small>
                              )}
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </form>
                </>
              </Box>
            )}
          </DialogContentText>
        </DialogContent>
        {type == "traveler" && (
          <>
            {!(data?.initiatedBy === "GUEST") ? (
              <RenderActions />
            ) : (
              <DialogActions
                sx={{
                  fontFamily: "Jost",
                  fontSize: "25px",
                  background: "#17212f",
                }}
              >
                <Button
                  sx={{
                    color: "rgba(255, 255, 255, 0.7)",
                  }}
                  autoFocus
                  onClick={handleClose}
                >
                  Close
                </Button>
              </DialogActions>
            )}
          </>
        )}
        {type == "host" && (
          <>
            {!(data?.initiatedBy === "HOST") ? (
              <RenderActions />
            ) : (
              <DialogActions
                sx={{
                  fontFamily: "Jost",
                  fontSize: "25px",
                  background: "#17212f",
                }}
              >
                <Button
                  sx={{
                    color: "rgba(255, 255, 255, 0.7)",
                  }}
                  autoFocus
                  onClick={handleClose}
                >
                  Close
                </Button>
              </DialogActions>
            )}
          </>
        )}
      </Dialog>
    </div>
  );
}
export default ReservationDetailForModificationRequest;
