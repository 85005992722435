import { Divider, Grid, Typography, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Buttons from '../../../../components/form/button';
import Input from '../../../../components/form/input';

const GuestInfo = ({
  formik,
  isButtonLoading,
  editorStateOne,
  handleEditorChange1,
  editorStateTwo,
  handleEditorChange2,
  editorStateThree,
  handleEditorChange3,
}) => {

  let maxLength = 1000;
  return (
    <>
      <div className="dasboard-widget-title fl-wrap">
        <h5>
          <i className></i>
          Guest Information
        </h5>
      </div>

      <div className="dasboard-widget-box fl-wrap">
        
          <Typography
            variant={'h6'}
            sx={{ paddingBottom: 3, paddingTop: 3, textAlign: 'initial' }}
          >
            Arrival Details - Instructions {" "}
            {formik.values.checkInInstruction?.length
            ? maxLength - formik.values.checkInInstruction.length
            : maxLength}
            {" "}characters
            remaining out of {maxLength}){' '}
          </Typography>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 1, md: 2 }}
            columnSpacing={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={12} sm={12} md={12}>
            <TextField
                id={'checkInInstruction'}
                name={'checkInInstruction'}
                placeholder={'Check In Instruction'}
                onChange={formik.handleChange}
                value={formik.values.checkInInstruction}
                fullWidth
                multiline
                minRows={6}
                maxRows={12}
                onInput={(e) => {
                  e.target.value =e.target.value.slice(0, 1000);
                }}
                error={
                  formik.touched.checkInInstruction &&
                  Boolean(formik.errors.checkInInstruction)
                }
                helperText={
                  formik.touched.checkInInstruction && formik.errors.checkInInstruction
                }
              />
            </Grid>
          </Grid>
          <Divider sx={{ paddingTop: 3 }} />
          <Typography
            variant={'h6'}
            sx={{ paddingBottom: 3, paddingTop: 3, textAlign: 'initial' }}
          >
            Arrival Details - Wifi Details
          </Typography>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 1, md: 2 }}
            columnSpacing={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={12} sm={12} md={6}>
            <Typography
            variant={'h6'}
            sx={{ paddingBottom: 1, paddingTop: 3, textAlign: 'initial',fontSize:"13px" }}
          >
            Wifi Name (Max 30 characters)
          </Typography>
              <Input
                id={'wifiUsername'}
                name={'wifiUsername'}
                label={'Wifi Name'}
                onChange={formik.handleChange}
                value={formik.values.wifiUsername}
                defaultValue={formik.values.wifiUsername}
                maxLength={30}
                error={
                  formik.touched.wifiUsername &&
                  Boolean(formik.errors.wifiUsername)
                }
                helperText={
                  formik.touched.wifiUsername && formik.errors.wifiUsername
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
            <Typography
            variant={'h6'}
            sx={{ paddingBottom:1,paddingTop: 3, textAlign: 'initial',fontSize:"13px" }}
          >
            Wifi Password (Max 30 characters)
          </Typography>
              <Input
                id={'wifiPassword'}
                name={'wifiPassword'}
                label={'Wifi Password'}
                onChange={formik.handleChange}
                value={formik.values.wifiPassword}
                defaultValue={formik.values.wifiPassword}
                maxLength={30}
                error={
                  formik.touched.wifiPassword &&
                  Boolean(formik.errors.wifiPassword)
                }
                helperText={
                  formik.touched.wifiPassword && formik.errors.wifiPassword
                }
              />
            </Grid>
          </Grid>
          <Divider sx={{ paddingTop: 3, marginBottom: 3 }} />
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 1, md: 2 }}
            columnSpacing={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={12} sm={12} md={2}>
              <Buttons
                title="Save"
                isLoading={isButtonLoading}
                onSubmit={formik.handleSubmit}
                loadingTitle={'Saving'}
              />
            </Grid>
          </Grid>
      </div>
    </>
  );
};

export default GuestInfo;
