import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    color: 'text.primary',
    backgroundColor: "#1b182b",
    boxShadow: 24,
    p: 4,
    textAlign: "start",
    borderRadius:5
};
export default function  DisclimerModal({ data }) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div>
            <Button onClick={handleOpen}>Learn More</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {data.title}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {data.detail}
                    </Typography>
                    <Typography   onClick={handleClose} style={{ textAlign: "end", marginTop: 20, cursor: 'pointer' }}>Close</Typography>
                </Box>

            </Modal>
        </div>
    )
}
