import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';

// prime react
import { Calendar } from 'primereact/calendar';
import { getPropertyCalenderPriceApi } from '../services/properties/propertyCalenderPrice';
import { Button } from '@mui/material';
import { Toast } from 'primereact/toast';

const ModifyBookingDatePicker = ({
  handleDateStart,
  handleDateEnd,
  handlers,
  basePrice,
  startDate,
  endDate,
  id,
  propertyPricePolicy,
}) => {
  const [value, setValue] = useState([new Date(startDate), new Date(endDate)]);
  const [disabledDates, setDisabledDates] = useState([]);
  const weekendPrice = propertyPricePolicy?.weekendNightPrice;
  const [disabledDatesCalendarData, setDisabledDatesCalendarData] = useState(
    []
  );
  const [formattedDisabledDates, setFormattedDisabledDates] = useState([]);
  const [calendarData, setCalendarData] = useState([]);
  const cal = useRef(null);
  useEffect(() => {
    var now = moment(startDate)?.clone(),
      dates = [];
    while (now?.isSameOrBefore(moment(endDate))) {
      dates.push(new Date(now.format('M/D/YYYY')));
      now.add(1, 'days');
    }
    setDisabledDates(dates);
  }, [startDate, endDate]);

  const dateTemplate = (date) => {
    for (let i = 0; i < disabledDates?.length; i++) {
      if (
        parseInt(moment(disabledDates[i]).format('DD')) === date.day &&
        date.month === parseInt(moment(disabledDates[i]).format('MM')) - 1
      ) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '10rem',
              backgroundColor: '#0095FE',
              textDecoration: 'line-through',
              fontSize: '14px',
            }}
          >
            {date.day}
            <span
              style={{
                fontSize: '0.60em',
                display: 'flex',
              }}
            >
              {(moment(disabledDates[i]).format('ddd') === 'Sat' ||
                moment(disabledDates[i]).format('ddd') === 'Sun') &&
              weekendPrice !== 0 ? (
                <>
                  {process.env.REACT_APP_CURRENCY}
                  {weekendPrice}
                </>
              ) : (
                <>
                  {' '}
                  {process.env.REACT_APP_CURRENCY}
                  {basePrice}
                </>
              )}
            </span>
          </div>
        );
      }
    }
    for (let i = 0; i < calendarData.length; i++) {
      if (
        date.day === parseInt(moment(calendarData[i]['forDate']).format('D')) &&
        date.month ===
          parseInt(moment(calendarData[i]['forDate']).format('M')) - 1
      ) {
        if (
          calendarData[i]['isBooked'] === true ||
          calendarData[i]['isAvailable'] === false
        ) {
          let now = moment(calendarData[i]['forDate']);
          disabledDatesCalendarData.push(new Date(now.format('M/D/YYYY')));
          formattedDisabledDates.push(
            moment(calendarData[i]['forDate']).format('YYYY-MM-DD')
          );
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '11rem',
                textDecoration: 'line-through',
                fontSize: '14px',
              }}
            >
              {date.day}
              {calendarData[i]['price'] && (
                <>
                  <br />
                  <span
                    style={{
                      fontSize: '0.60em',
                      display: 'flex',
                    }}
                  >
                    {(moment(calendarData[i]['forDate']).format('ddd') ===
                      'Sat' ||
                      moment(calendarData[i]['forDate']).format('ddd') ===
                        'Sun') &&
                    weekendPrice !== 0 ? (
                      <>
                        {process.env.REACT_APP_CURRENCY}
                        {weekendPrice}
                      </>
                    ) : (
                      <>
                        {' '}
                        {process.env.REACT_APP_CURRENCY}
                        {calendarData[i]['price']}
                      </>
                    )}
                  </span>
                </>
              )}
            </div>
          );
        } else {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '11rem',
                fontSize: '14px',
              }}
            >
              {date.day}
              {calendarData[i]['price'] && (
                <>
                  <br />
                  <span
                    style={{
                      fontSize: '17px',
                      display: 'flex',
                    }}
                  >
                    {(moment(calendarData[i]['forDate']).format('ddd') ===
                      'Sat' ||
                      moment(calendarData[i]['forDate']).format('ddd') ===
                        'Sun') &&
                    weekendPrice !== 0 ? (
                      <>
                        {process.env.REACT_APP_CURRENCY}
                        {weekendPrice}
                      </>
                    ) : (
                      <>
                        {' '}
                        {process.env.REACT_APP_CURRENCY}
                        {calendarData[i]['price']}
                      </>
                    )}
                  </span>
                </>
              )}
            </div>
          );
        }
      }
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '10rem',
          fontSize: '14px',
        }}
      >
        {date.day}
        <span
          style={{
            fontSize: '0.60em',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {process.env.REACT_APP_CURRENCY} {basePrice}
        </span>
      </div>
    );
  };

  useEffect(() => {
    const getCalendarData = async () => {
      const response = await getPropertyCalenderPriceApi(id);
      if (response.status === 200) {
        setCalendarData(response.data.data);
      }
    };
    getCalendarData();
  }, [id]);

  const handleClose = () => {
    cal.current.hide();
  };

  const toast = useRef(null);
  return (
    <>
      <Toast ref={toast} position='bottom-right' />
      <DatePickerContainer>
        <Calendar
          ref={cal}
          id='range'
          value={value}
          minDate={new Date(startDate)}
          showButtonBar
          onClearButtonClick={() => {
            setValue([]);
          }}
          onChange={(e) => {
            if (e.value) {
              e.value.splice(0, 0, new Date(startDate));
              var now = moment(e.value[0])?.clone(),
                dates = [];
              while (now?.isSameOrBefore(moment(e.value[1]))) {
                dates.push(now.format('YYYY-MM-DD'));
                now.add(1, 'days');
              }
              function intersect(a, b) {
                var setA = new Set(a);
                var setB = new Set(b);
                var intersection = new Set(
                  [...setA].filter((x) => setB.has(x))
                );
                return Array.from(intersection);
              }

              let result = intersect(dates, formattedDisabledDates);
              if (result.length > 0) {
                toast.current.show({
                  severity: 'info',
                  summary: 'Invalid Date Range',
                  detail:
                    'You cannot select your stay between unavailable dates, please select a different date range!',
                  life: 3000,
                });
                setValue([]);
                return false;
              } else {
                setValue(e.value);
                handlers && handleDateEnd(e.value[1]);
              }
            }
          }}
          selectionMode='range'
          disabledDates={
            disabledDates &&
            disabledDatesCalendarData && [
              ...disabledDates,
              ...disabledDatesCalendarData,
            ]
          }
          readOnlyInput
          placeholder='Select new date range.'
          dateTemplate={dateTemplate}
          numberOfMonths={2}
          dateFormat='dd M yy'
          footerTemplate={() => (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: '12px',
                width: '100%',
              }}
            >
              <Button
                variant='contained'
                disabled={value?.length > 0 ? false : true}
                onClick={handleClose}
              >
                Apply
              </Button>
            </div>
          )}
        />
      </DatePickerContainer>
    </>
  );
};

const DatePickerContainer = styled.div`
  height: 100%;

  .p-calendar input {
    text-align: center;
  }

  .p-calendar .p-inputtext {
    background: none;
    color: #fff;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    text-align: 'center';
    font-size: 14px;
    line-height: 1.4375em;
    min-height: 50px;

    &:hover {
      border-color: #fff;
    }
    &:focus {
      box-shadow: none;
      /* border-color: #326fd1; */
      border: 2px solid;
    }
  }

  .p-calendar {
    width: 100%;
    height: 100%;
    font-size: 25px;
  }
  .p-highlight {
    color: #0095f8 !important;
  }
`;

export default ModifyBookingDatePicker;
