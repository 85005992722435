import { API_URL } from '../../config';
import axios from 'axios';

export const getPropertyViewsApi = async (id) => {
  try {
    const response = await axios.get(API_URL + `property_view/count/${id}`);
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('bad Response of get property view : ', response);
      return response;
    }
  }
};
