import { API_URL } from '../../config';
import axios from 'axios';

export const updatePropertyRestrictedDays = async (id, data) => {
  try {
    const response = await axios.put(
      API_URL + `property-restricted-days/update/${id}`,
      data
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of property : ', response);
      return response;
    }
  }
};
