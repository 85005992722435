import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Typographyy from "../../../components/typography";
import { Typography } from "@mui/material";
import Radio from "@mui/material/Radio";
import {BiUserPlus} from "react-icons/bi"

import { Box } from "@mui/system";
import Button from "@mui/material/Button";

import FormControlLabel from "@mui/material/FormControlLabel";

import { Link } from "react-router-dom";

import CardContent from "@mui/material/CardContent";

import Header from "../../../components/header";

export default function ProvideId() {
  const [radioo, setRadioo] = React.useState(false);
  const [tp, settp] = React.useState(false);

  const [selectedValue, setSelectedValue] = React.useState("mal");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  return (
    <>
      <Header></Header>
      <Grid
        container
        className="gridpadding1"
        style={{ background: "#282c34" }}
      >
        <Grid item xs={12} sm={12} md={6}>
          <CardContent
            sx={{
              textAlign: "left",
              padding: 0,
            }}
          >
            <Typographyy
              variant="h4"
              bold="bold"
              text="Let’s add your government ID"
            />
            <Typography
              className="text-white"
              variant="h5"
              sx={{ marginTop: "15px" }}
            >
              {" "}
              We’ll need you to add an official government ID. This step helps
              make sure you’re really you.
            </Typography>
          </CardContent>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <div sx={{ textAlign: "left" }}>
              <Typographyy
                variant="h4"
                bold="bold"
                text="Upload Existing Photo"
              />

              <Typography
                variant="h4"
                sx={{ marginTop: 0.5, textAlign: "left" }}
                className="text-white"
              >
                {" "}
                Recommended
              </Typography>
            </div>
            <FormControlLabel
              control={
                <Radio
                  checked={selectedValue === "mal"}
                  onChange={handleChange}
                  value="mal"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
              }
            />
          </div>
          <hr />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              marginTop: 3,
            }}
          >
            <div>
              <Typography
                variant="h4"
                sx={{ fontWeight: "bold", textAlign: "left" }}
                className="text-white"
              >
                {" "}
                Take photo with your webcam
              </Typography>
            </div>
            <FormControlLabel
              control={
                <Radio
                  checked={selectedValue === "fem"}
                  onChange={handleChange}
                  value="fem"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
              }
            />
          </div>
          <div className="btncont" style={{ paddingTop: "100px" }}>
            <hr class="MuiButton-root" />

            <div style={{ display: "flex", alignItems: "center" , justifyContent:"flex-end"}}>
              <Button
                style={{ padding: "10px", fontSize: "15px" }}
                variant="contained"
              >
                <BiUserPlus
                  style={{
                    fontSize: "22px",
                    color: "white",
                    marginRight: "3px",
                  }}
                />
                Continue
              </Button>
            </div>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          sx={{
            paddingLeft: "60px",
            paddingRight: "60px",

            textAlign: "center",
          }}
          className="manpad"
        >
          <div
            style={{
              border: "1px solid white",
              borderRadius: "10px",
              padding: "10px",
            }}
          >
            <div sx={{ padding: 3, textAlign: "center" }}>
              <Link to="">
                <Typographyy
                  variant="h5"
                  sx={{
                    color: "white",
                    marginTop: 2,
                    textDecoration: "underline",
                  }}
                  bold="bold"
                  text="Your Privacy"
                />
              </Link>

              <Typography
                style={{
                  color: "white",
                  padding: "2px",
                  fontSize: "18px",
                  textAlign: "center",
                }}
              >
                We aim to keep the data you share during this process private,
                safe, and secure. Learn more in our
              </Typography>

              <Link to="">
                <Typographyy
                  variant="h5"
                  sx={{
                    color: "white",
                    padding: "2px",

                    textDecoration: "underline",
                  }}
                  bold="bold"
                  text="Privacy Policy"
                />
              </Link>
              <Link to="">
                <Typographyy
                  variant="h5"
                  sx={{
                    color: "white",
                    padding: "2px",

                    textDecoration: "underline",
                  }}
                  bold="bold"
                  text="How this works"
                />
              </Link>
            </div>
          </div>
        </Grid>
      </Grid>
    </>

    // <>
    //   <Header></Header>

    //     <Grid
    //       item
    //       xs={12}
    //       sm={12}
    //       md={6}
    //       sx={{ textAlign: "left", background: "#282c34" }}
    //     >

    //       <Typographyy
    //         variant="h3"
    //         bold="bold"
    //         text="Let’s add your government ID"
    //       />

    //       <Typography
    //         className="text-white"
    //         variant="h4"
    //         sx={{ marginTop: "15px" }}
    //       >
    //         {" "}
    //         We’ll need you to add an official government ID. This step helps
    //         make sure you’re really you.
    //       </Typography>
    //       <div
    //         sx={{
    //           display: "flex",
    //           justifyContent: "space-between",
    //           alignItems: "flex-start",
    //           marginTop: 5,
    //           marginBottom: 1,
    //         }}
    //       >
    //         <div sx={{}}>
    //           <Typography
    //             variant="h4"
    //             sx={{ fontWeight: "bold" }}
    //             className="text-white"
    //           >
    //             {" "}
    //             Upload Existing Photo
    //           </Typography>
    //           <Typography
    //             variant="h4"
    //             sx={{ marginTop: 0.5 }}
    //             className="text-white"
    //           >
    //             {" "}
    //             Recommended
    //           </Typography>
    //         </div>
    //         <Radio
    //           checked={selectedValue === "d"}
    //           onChange={handleChange}
    //           value="d"
    //           color="default"
    //           name="radio-button-demo"
    //           inputProps={{ "aria-label": "D" }}
    //         />
    //       </div>
    //       <hr />

    //       <div
    //         sx={{
    //           display: "flex",
    //           justifyContent: "space-between",
    //           alignItems: "flex-start",
    //           marginTop: 3,
    //         }}
    //       >
    //         <div>
    //           <Typography
    //             variant="h4"
    //             sx={{ fontWeight: "bold" }}
    //             className="text-white"
    //           >
    //             {" "}
    //             Take photo with your webcam
    //           </Typography>
    //         </div>
    //         <Radio
    //           checked={selectedValue === "d"}
    //           onChange={handleChange}
    //           value="d"
    //           color="default"
    //           name="radio-button-demo"
    //           inputProps={{ "aria-label": "D" }}
    //         />
    //       </div>
    //       <br />
    //       <br />
    //       <br />
    //       <hr />
    //       <div
    //         sx={{
    //           display: "flex",
    //           justifyContent: "flex-end",
    //         }}
    //       >
    //         <Button
    //           sx={{
    //             marginTop: 0,
    //             fontSize: "18px",
    //             padding: 2,
    //             borderRadius: "10px",
    //             background: "white",
    //             color: "black",
    //           }}
    //           variant="contained"
    //         >
    //           Continue
    //         </Button>
    //       </div>
    //     </Grid>

    //   </Grid>
    // </>
  );
}
