import { API_URL } from '../../config';
import { axiosPrivate } from '../../api/axios';
const addUserEmergnecyContactApi = async (data) => {
  try {
    const response = await axiosPrivate.post(
      API_URL + `user-emergency-contact`,
      data
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('bad response of add user emergency contact: ', response);
      return response;
    }
  }
};
export default addUserEmergnecyContactApi;
