import axios from "axios";
import { API_URL } from "../../config";

export const getHomeSliderImgApi = async () => {
  try {
    const response = await axios.get(
      API_URL + "cms-slider/get-slides-and-count/1"
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log("Bad Response of properties : ", response);
      return response;
    }
  }
};
