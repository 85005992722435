//
import { API_URL } from '../../config';
import axios from 'axios';

export const resetPasswordApi = async (data) => {
  try {
    const response = await axios.patch(
      API_URL + `auth/email/reset-password`,
      data
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('response: ', response);
      throw response;
    }
  }
};
