import React, { useEffect, useState } from "react";
//Yup and Formik are used for validations
import { useFormik } from "formik";
import { Box, Grid } from "@mui/material";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import Jumbotron from "../../../components/jumbotron";
import NewsLetter from "../../../components/newsLetter";
import BreadCrumbs from "../../../components/breadCrumb";
import SlickSlider from "../../../components/slickSlider";
import PropertyCard from "../../../components/mainComponents/propertyCard";
import { FakeCities } from "../../../FakeData/cities";
import SkeletonLoader from "../../../components/skeletonLoader";
import ErrorMessage from "../../../components/errorMessage";
import ImageCarousel from "../../../components/imageCarousel";

import {
  place1,
  place2,
  place3,
  place4,
  place5,
} from "../../../assets/images/plans";
import {
  addToWishlistApi,
  removeFromWishlistApi,
} from "../../../services/properties/add&RemoveWishlist";
import { useSnackbar } from "notistack";
import { getHomePagePropertiesApi } from "../../../services/properties/getHomePageProperties";
import moment from "moment";
import * as yup from "yup";

const HomePage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [properties, setProperties] = useState([]);
  const [reommendedProperties, setRecommendedProperties] = useState([]);
  const [inspiredProperties, setInspiredProperties] = useState([]);
  const [savedProperties, setSavedProperties] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [cities, setCities] = useState([]);

  // const userData = JSON.parse(localStorage.getItem('userData'));
  // const navigate = useNavigate();
  const isDefined = (key) => {
    if (key === undefined || key === "") return false;
    else return true;
  };
  const formik = useFormik({
    initialValues: {
      startDate: "",
      endDate: "",
      noOfGuest: "",
      noOfAdults: "",
      noOfChildren: "",
      noOfInfants: "",
      noOfPets: "",
      lat: +parseFloat(0.0),
      lng: +parseFloat(0.0),
      state: "",
      city: "",
      country: "",
      address: "",
    },
    validationSchema: yup.object({
      address: yup.string().required("Please select an address to explore!"),
    }),
    onSubmit: (values) => {
      let url = ``;
      url +=
        url.length > 0
          ? isDefined(values.startDate)
            ? `&startDate=${moment(values.startDate).format("YYYY-MM-DD")}`
            : ``
          : isDefined(values.startDate)
          ? `startDate=${moment(values.startDate).format("YYYY-MM-DD")}`
          : ``;
      url +=
        url.length > 0
          ? isDefined(values.endDate)
            ? `&endDate=${moment(values.endDate).format("YYYY-MM-DD")}`
            : ``
          : isDefined(values.endDate)
          ? `endDate=${moment(values.endDate).format("YYYY-MM-DD")}`
          : ``;
      url +=
        url.length > 0
          ? parseInt(values.noOfGuest) > 0
            ? `&noOfGuest=${values.noOfGuest}`
            : ``
          : parseInt(values.noOfGuest) > 0
          ? `noOfGuest=${values.noOfGuest}`
          : ``;
      url +=
        url.length > 0
          ? parseInt(values.noOfAdults) > 0
            ? `&noOfAdults=${values.noOfAdults}`
            : ``
          : parseInt(values.noOfAdults) > 0
          ? `noOfAdults=${values.noOfAdults}`
          : ``;
      url +=
        url.length > 0
          ? parseInt(values.noOfInfants) > 0
            ? `&noOfInfants=${values.noOfInfants}`
            : ``
          : parseInt(values.noOfInfants) > 0
          ? `noOfInfants=${values.noOfInfants}`
          : ``;
      url +=
        url.length > 0
          ? parseInt(values.noOfPets) > 0
            ? `&noOfPets=${values.noOfPets}`
            : ``
          : parseInt(values.noOfPets) > 0
          ? `noOfPets=${values.noOfPets}`
          : ``;
      url +=
        url.length > 0
          ? parseInt(values.noOfChildren) > 0
            ? `&noOfChildren=${values.noOfChildren}`
            : ``
          : parseInt(values.noOfChildren) > 0
          ? `noOfChildren=${values.noOfChildren}`
          : ``;
      url +=
        url.length > 0
          ? values.lat > 0
            ? `&lat=${values.lat}`
            : ``
          : values.lat > 0
          ? `lat=${values.lat}`
          : ``;
      url +=
        url.length > 0
          ? values.lng > 0
            ? `&lng=${values.lng}`
            : ``
          : values.lng > 0
          ? `lng=${values.lng}`
          : ``;
      url +=
        url.length > 0
          ? isDefined(values.state)
            ? `&state=${values.state}`
            : ``
          : isDefined(values.state)
          ? `state=${values.state}`
          : ``;
      url +=
        url.length > 0
          ? isDefined(values.city)
            ? `&city=${values.city}`
            : ``
          : isDefined(values.city)
          ? `city=${values.city}`
          : ``;
      url +=
        url.length > 0
          ? isDefined(values.country)
            ? `&country=${values.country}`
            : ``
          : isDefined(values.country)
          ? `country=${values.country}`
          : ``;
      url +=
        url.length > 0
          ? isDefined(values.address)
            ? `&address=${values.address}`
            : ``
          : isDefined(values.address)
          ? `address=${values.address}`
          : ``;
      window.location = `/property-listing?` + url;
    },
  });

  const getCities = () => {
    setCities(FakeCities);
  };

  const addToWishlist = async (id) => {
    const response = await addToWishlistApi(userData?.id, id);
    if (response.status === 200) {
      enqueueSnackbar("Wishlist updated!", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };
  const removeFromWishlist = async (id) => {
    const response = await removeFromWishlistApi(userData?.id, id);
    if (response.status === 200) {
      enqueueSnackbar("Wishlist updated!", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };
  const userData = JSON.parse(localStorage.getItem("userData"));

  const getHomePageProperties = async () => {
    setIsLoading(true);
    await getHomePagePropertiesApi(userData?.id)
      .then((res) => {
        setIsLoading(false);
        setProperties(res?.data?.topProperties);
        setInspiredProperties(res?.data?.inspiredDestination);
        setRecommendedProperties(res?.data?.recommendedProperties);
      })
      .catch((err) => {
        setIsLoading(false);
        setProperties([]);
        setInspiredProperties([]);
        setRecommendedProperties([]);
        console.log("There is an error in getting home page properties:", err);
      });
  };

  useEffect(() => {
    getCities();
    getHomePageProperties();
  }, []);

  return (
    <div className='main'>
      <Header />
      <div className='wrapper'>
        <div className='content'>
          <Jumbotron formik={formik} />
          <BreadCrumbs />
          <Categories cities={cities} />
          <TopProperties
            addToWishlist={addToWishlist}
            rtl
            removeFromWishlist={removeFromWishlist}
            properties={properties}
            mainTitle={"Top Properties"}
            subTitle={""}
            isLoading={isLoading}
            rightToLeft={true}
          />
          {savedProperties.length > 0 ? (
            <SavedProperties
              addToWishlist={addToWishlist}
              properties={savedProperties.length > 0 ? savedProperties : []}
              mainTitle={"Saved Properties"}
              removeFromWishlist={removeFromWishlist}
              subTitle={""}
              isLoading={isLoading}
              rightToLeft={false}
            />
          ) : (
            ""
          )}
          <TopProperties
            rtl
            addToWishlist={addToWishlist}
            removeFromWishlist={removeFromWishlist}
            properties={reommendedProperties}
            mainTitle={"Recommended Properties"}
            subTitle={""}
            isLoading={isLoading}
            rightToLeft={true}
          />
          <TopProperties
            addToWishlist={addToWishlist}
            properties={inspiredProperties}
            mainTitle={"Inspired Properties"}
            removeFromWishlist={removeFromWishlist}
            subTitle={""}
            isLoading={isLoading}
            rightToLeft={false}
          />
          <Slider />
          <NewsLetter />
          <Footer />
        </div>
      </div>
    </div>
  );
};

const Categories = ({ cities }) => {
  return (
    <>
      <section
        className='gray-bg small-padding'
        style={{ paddingBottom: 0, paddingTop: 10 }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-md-4'>
              <div className='section-title fl-wrap property-heading'>
                <h4></h4>
                <h2>Top Cities</h2>
              </div>
            </div>
          </div>

          <div style={{ marginTop: 0 }}>
            <SlickSlider>
              {cities?.map((city) => {
                return (
                  <div className='city'>
                    <div className='city-img'>
                      <div className='geodir-category-img fl-wrap '>
                        <a
                          href={`/property-listing?city=${city?.title}`}
                          className='geodir-category-img_item'
                          style={{ textDecoration: "none" }}
                        >
                          <img
                            src={city?.images}
                            alt=''
                            style={{ borderRadius: "0px !important" }}
                          ></img>
                          <h3
                            className='city-title'
                            style={{ marginTop: "0px" }}
                          >
                            {city?.title}
                          </h3>
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
            </SlickSlider>
          </div>
        </div>
      </section>
    </>
  );
};

const Slider = () => {
  const data = [
    {
      id: 1,
      locationUrl: place1,
    },
    {
      id: 2,
      locationUrl: place2,
    },
    {
      id: 3,
      locationUrl: place3,
    },
    {
      id: 4,
      locationUrl: place4,
    },
    {
      id: 5,
      locationUrl: place5,
    },
  ];

  return (
    <section
      className='gray-bg small-padding'
      style={{ paddingBottom: 0, paddingTop: 10 }}
    >
      <div className='container'>
        <div className='row'>
          <div className='col-md-4'>
            <div className='section-title fl-wrap property-heading'>
              <h2>Gallery</h2>
            </div>
          </div>
        </div>
        <div style={{ marginTop: 0 }}>
          <ImageCarousel propertyDetail={data} />
        </div>
      </div>
    </section>
  );
};

const TopProperties = ({
  addToWishlist,
  properties,
  mainTitle,
  subTitle,
  isLoading,
  rightToLeft,
  removeFromWishlist,
}) => {
  return (
    <section
      className='gray-bg small-padding'
      style={{
        paddingBottom: 0,
        paddingTop: 10,
      }}
    >
      <div className='container'>
        <div className='row'>
          <div className='col-md-4'>
            <div className='section-title fl-wrap property-heading'>
              <h4>{subTitle}</h4>
              <h2>{mainTitle}</h2>
            </div>
          </div>
        </div>

        <div style={{ marginTop: 0 }}>
          {isLoading === true ? (
            <Loader />
          ) : (
            <>
              {properties?.length === 0 ? (
                <ErrorMessage />
              ) : (
                <Box
                  className='sliderContainer'
                  component='div'
                  sx={{
                    "& .slick-slider": {
                      "& .slick-list": {
                        "& .gallery-item": {
                          "& .listing-item": {
                            marginBottom: "0px",
                            "& .geodir-category-listing": {
                              marginBottom: "0px",
                            },
                          },
                        },
                      },
                    },
                  }}
                >
                  <SlickSlider rightToLeft={rightToLeft}>
                    {properties?.map((property) => {
                      return (
                        <div className='gallery-item'>
                          <PropertyCard
                            property={property}
                            addToWishlist={addToWishlist}
                            removeFromWishlist={removeFromWishlist}
                          />
                        </div>
                      );
                    })}
                  </SlickSlider>
                </Box>
              )}
            </>
          )}
        </div>
      </div>
    </section>
  );
};

const SavedProperties = ({
  addToWishlist,
  properties,
  mainTitle,
  subTitle,
  isLoading,
  rightToLeft,
  removeFromWishlist,
}) => {
  return (
    <section
      className='gray-bg small-padding'
      style={{ paddingBottom: 0, paddingTop: 10 }}
    >
      <div className='container'>
        <div className='row'>
          <div className='col-md-4'>
            <div className='section-title fl-wrap property-heading'>
              <h4>{subTitle}</h4>
              <h2>{mainTitle}</h2>
            </div>
          </div>
        </div>

        <div style={{ marginTop: 0 }}>
          {isLoading === true ? (
            <Loader />
          ) : (
            <>
              {properties?.length === 0 ? (
                <ErrorMessage />
              ) : (
                <SlickSlider rightToLeft={rightToLeft}>
                  {properties?.map((property) => {
                    return (
                      <div className='gallery-item'>
                        <PropertyCard
                          removeFromWishlist={removeFromWishlist}
                          property={property.property}
                          addToWishlist={addToWishlist}
                        />
                      </div>
                    );
                  })}
                </SlickSlider>
              )}
            </>
          )}
        </div>
      </div>
    </section>
  );
};

const Loader = () => {
  return (
    <Grid container columnSpacing={{ md: 2 }}>
      <Grid item xs={12} sm={12} md={3}>
        <SkeletonLoader height={380} />
      </Grid>
      <Grid item xs={12} sm={12} md={3}>
        <SkeletonLoader height={380} />
      </Grid>
      <Grid item xs={12} sm={12} md={3}>
        <SkeletonLoader height={380} />
      </Grid>
      <Grid item xs={12} sm={12} md={3}>
        <SkeletonLoader height={380} />
      </Grid>
    </Grid>
  );
};

export default HomePage;
