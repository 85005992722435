import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import HouseIcon from '@mui/icons-material/House';
import { useNavigate } from 'react-router-dom';
import { getGSTValueApi } from '../services/system/getGSTValue';
import Ratings from './Ratings';

const Map = ({
  mapValues,
  userLat,
  userLong,
  mapCenter,
  activeProperty,
  zoom,
  getPropertiesWithCoords,
}) => {

  const [showAddress, setShowAddress] = useState(null);
  const [gst, setGST] = React.useState(0);
  //Get and set GST & serviceFee Value from server
  React.useEffect(() => {
    const getGSTValue = async () => {
      let gstVal = await getGSTValueApi();
      setGST(gstVal);
    };

    getGSTValue();
  }, []);

  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <GoogleMapReact
        center={mapCenter}
        defaultZoom={10}
        zoom={zoom}
        gestureHandling='none'
        onDragEnd={(e) => getPropertiesWithCoords(e)}
      >
        {mapValues?.map((item, index) => (
          <AnyReactComponent
            index={index}
            lat={item?.lat && +parseFloat(item?.lat)}
            lng={item?.lng && +parseFloat(item?.lng)}
            key={index}
            address={item?.address}
            city={item?.city}
            country={item?.country}
            desc={item?.desc}
            setShowAddress={setShowAddress}
            showAddress={showAddress}
            gestureHandling='none'
            propertyImage={item?.propertyImage}
            propertyTitle={item?.propertyTitle}
            propertyPrice={item?.propertyPrice}
            propertyCapacity={item?.propertyCapacity}
            perNightPrice={item?.perNightPrice}
            pricePolicy={item?.propertyPricePolicy}
            id={item?.propertyId}
            gst={gst}
            activeProperty={activeProperty}
            floodRelief={item?.floodRelief}
            rating={item?.property_Rating}
          />
        ))}
      </GoogleMapReact>
    </div>
  );
};


const AnyReactComponent = ({
  address,
  setShowAddress,
  index,
  showAddress,
  propertyTitle,
  city,
  propertyId,
  country,
  propertyPrice,
  propertyCapacity,
  propertyImage,
  selectedPlace,
  data,
  perNightPrice,
  pricePolicy,
  id,
  gst,
  key,
  activeProperty,
  floodRelief,
  rating
}) => {
  const handleClick = (index) => {
    if (showAddress === index) {
      setShowAddress(null);
    } else {
      setShowAddress(index);
    }
  };

  const cleaningFee = pricePolicy ? parseInt(pricePolicy?.cleaningFee) : 0;
  const totalCost = perNightPrice + cleaningFee;
  const navigate = useNavigate();

  const markerClassFunction = () => {
    if (floodRelief) {
      return "floodReliefClass map-iconselected-flood";
    } else if (activeProperty?.id === id) {
      return "activePropertyClass map-iconselected-active";
    } else {
      return "map-iconselected";
    }
  };


  return (
    <React.Fragment key={key}>
      <div
        // className={`${
        //   selectedPlace === propertyId ? 'map-iconselected ' : 'map-icon'
        // }`}
        className={markerClassFunction()}
        onClick={() => handleClick(index)}
      >
        <HouseIcon sx={{ borderColor: "red" }} />
      </div>

      {!(showAddress === index) ? null : (
        <div
          className='map-address-section'
          onClick={() => navigate(`/property-detail/${id}`)}
        >
          <div className='subcatageories'>
            <div
              style={{
                background: "black",
                width: "100%",
                position: "relative",
              }}
            >
              <img
                className='imgproperty'
                src={propertyImage}
                alt='img'
                style={{
                  width: "100%",
                  height: "200px",
                  maxWidth: "fit-content",
                }}
              />
            </div>
            <p className='subp'>{propertyTitle}</p>

            <p className='subp'>
              {city}, {country}
            </p>
            <p className='subp'>
              {propertyCapacity?.noOfBedrooms} BR -
              {propertyCapacity?.noOfBathrooms} BA
            </p>
            {/* rating and reviews  */}
            <div
              style={{
                textAlign: 'initial',
                paddingTop: 0,
                paddingBottom: 5,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Ratings
                rating={
                  rating?.averageRating
                }
              />
              <span style={{ marginLeft: 2, color: '#c2c1c5', fontSize: 12 }}>
                (
                {rating ? parseInt(
                  rating?.averageRating
                )?.toFixed(1)
                  : 0}

                )
                (
                {rating
                  ? rating?.reviewCount
                  : 0}{' '}
                Reviews)

              </span>
            </div>

            <p className='subp'>
              Total {process.env.REACT_APP_CURRENCY}
              {totalCost
                ? parseInt(totalCost)?.toLocaleString("en-US")
                : 0}{" "}
              &nbsp; avg/night
            </p>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Map;
