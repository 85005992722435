import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Header from '../../../components/header';
import Sidebar from '../../../components/dasboard/sidebar';
import { AiFillCreditCard } from 'react-icons/ai';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import Button from '@mui/material/Button';
import { getTransactionHistoryApi } from '../../../services/TransactionHistory/getTransactionHistory';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
const TransactionHistory = () => {
  const [transactionHistory, setTransactionHistory] = React.useState();
  const userData = JSON.parse(localStorage.getItem('userData')) || null;
  const getTransaction = async () => {
    const res = await getTransactionHistoryApi(userData?.id);
    filterTransactionHistoryData(res);
  };

  React.useEffect(() => {
    getTransaction();
  }, []);
  const filterTransactionHistoryData = (data) => {
    let values = data;
    let array = [];
    values?.reverse().forEach((value) => {
      let data = {
        id: value.code,
        amount:
          value.difference < 0
            ? `- ${process.env.REACT_APP_CURRENCY} ${Math.abs(value.difference)} (Payment Done)`
            : `+ ${process.env.REACT_APP_CURRENCY} ${Math.abs(value.difference)} (Top Up)`,
        createdOn: moment(value.createdOn).format('DD MMM YYYY'),
        type: value.transactionType,
      };
      array.push(data);
    });
    setTransactionHistory(array);
  };

  const navigate = useNavigate();
  return (
    <div>
      <Header />
      <div id="wrapper">
        <div className="dashboard-menu-overlay" />
        <Sidebar page={"Wallet Transaction History"} />
        <div className="dashboard-content">
          <div className="dasboard-wrapper fl-wrap no-pag">
            <div>
              <div className="clearfix" />
              <div className="row">
                <div className="col-md-12">

                  <div
                    style=
                    {{
                      margin: "10px 0px", display: "flex",
                      justifyContent: "space-between", alignItems: "center"
                    }}
                  >
                    <Typography
                      sx={{
                        color: 'white',
                        fontFamily: 'Jost',
                        fontSize: '20px',
                        fontWeight: '700',
                        paddingBottom: '0px',
                      }}
                    >
                      <span>

                        <ArrowBackIosIcon onClick={() => navigate(-1)} style={{cursor:"pointer"}}/>
                        YOUR WALLET TRANSACTIONS

                      </span>


                    </Typography>
                    <Button
                      variant="contained"
                      sx={{
                        background: '#0095ff',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      onClick={() => navigate('/admin/your-cards')}
                    >
                      <AiFillCreditCard
                        style={{ fontSize: '20px', marginRight: '6px' }}
                      />
                      Manage Payment Methods
                    </Button>
                  </div>
                  <div
                    style={{ margin: "30px 0px" }}
                  >
                    <DataTable
                      responsiveLayout="scroll"
                      value={transactionHistory}
                      style={{ width: '100%', fontSize: '16px' }}
                    >
                      <Column field="id" header="Transaction ID"></Column>
                      <Column field="createdOn" header="Payment Date"></Column>
                      <Column field="amount" header="Amount"></Column>
                      <Column field="type" header="Transaction Type"></Column>
                    </DataTable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionHistory;
