import { Grid } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import InfoBar from "../../../components/dasboard/infoBar";
import Sidebar from "../../../components/dasboard/sidebar";
import Header from "../../../components/header";
import Property from "../../../components/mainComponents/property";
import { API_URL } from "../../../config";
import ActionCard from "./ActionCard";

const ActionPage = () => {
  return (
    <>
      <Header />

      <div id="wrapper">
        <div className="dashboard-menu-overlay" />
        <Sidebar page={"Add New"} />
        <div className="dashboard-content">
          <div className="dashboard-menu-btn color-bg">
            <span>
              <i className="fas fa-bars" />
            </span>
            Dashboard Menu
          </div>
          <InfoBar label={"Action"} />

          <div className="dasboard-wrapper fl-wrap no-pag">
            <div className="action_page">
              <h3 className="ac_page_h_title">Account review</h3>
              <div className="mt-4">
                <h5 className="ac_page_sub_title text-muted">
                  Action required
                </h5>
                <p className="text-muted action_text">
                  To continue processing payments through Stripe, please provide
                  the requested infromation.
                </p>
              </div>

              {JSON.parse(localStorage.getItem("userData"))?.verified ===
                false && (
                <ActionCard
                  title={"Verify your identity"}
                  description={
                    "Please provide a goverment ID, to verify your identity. Please make sure that the information on the ID picture is clearly visible"
                  }
                  btnText={"Get Verified"}
                  url={"https://dev-web.staywo.com/admin/id-verification"}
                  affect={["Payouts", "Bookings", "Property Listing"]}
                />
              )}

              {JSON.parse(localStorage.getItem("userBankInfo")) === false && (
                <ActionCard
                  title={"Provide your bank details"}
                  description={
                    "Please provide a goverment ID, to verify your identity. Please make sure that the information on the ID picture is clearly visible"
                  }
                  btnText={"Add Bank"}
                  url={"https://dev-web.staywo.com/admin/bank-account"}
                  affect={["Payouts"]}
                />
              )}
              {JSON.parse(localStorage.getItem("property_inprogress"))?.length >
                0 && (
                <div className="action_area">
                  <div
                    className=""
                    style={{ marginBottom: "15px", maxWidth: "600px" }}
                  >
                    <h5 className="ac_page_sub_title text-muted">
                      Property is inprogress
                    </h5>
                    <p className="text-muted action_text">
                      Please follow these steps to add your property details: <br/> 1. Fill out the form with accurate information.<br/> 2. Upload property images. <br/> 3. Submit. <br/> Our team will review and approve within 24hrs. Happy listing! 🏠
                    </p>
                  </div>

                  <Grid
                    container
                    rowSpacing={{ xs: 1, sm: 1, md: 1 }}
                    columnSpacing={{ xs: 1, sm: 1, md: 2 }}
                  >
                    {JSON.parse(
                      localStorage.getItem("property_inprogress")
                    )?.map((property) => {
                      return (
                        <Grid item xs={12} sm={6} md={12}>
                          <Property property={property} fromAction={true} />
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="dashbard-bg gray-bg" />
      </div>
    </>
  );
};

export default ActionPage;
