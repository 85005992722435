import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { Toast } from 'primereact/toast';
import { Paginator } from 'primereact/paginator';
import Card from '../../../components/dasboard/card';
import InfoBar from '../../../components/dasboard/infoBar';
import Sidebar from '../../../components/dasboard/sidebar';
import Input from '../../../components/form/input';
import Header from '../../../components/header';
import getBookingOwnerAPI from '../../../services/booking/getBookingOwner';
import ErrorMessage from '../../../components/errorMessage';
import SkeletonLoader from '../../../components/skeletonLoader';
import { useRef } from 'react';
import { confirmDialog } from 'primereact/confirmdialog';
import moment from 'moment';
import SingleWithoutTimer from '../../../components/BookingComponents/SingleWithoutTimer';
import { updateBookingApi } from '../../../services/booking/updateBookingStatus';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Calendar } from 'primereact/calendar';
import ReservationDetail from '../../Website/ReservationDetails/ReservationDetail';
import { getCommissionFee } from './../../../services/commissionFee/CommissionFee';

const CurrentReservations = () => {
  const { getCurrentdData } = getBookingOwnerAPI();
  const [properties, setProperties] = useState([]);
  const [filteredProperty, setFilteredProperty] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [bookingDetails, setBookingDetails] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [basicFirst, setBasicFirst] = useState(0);
  const [basicRows, setBasicRows] = useState(10);
  const [filterToggle, setFilterToggle] = useState(false);
  const [commissionFee, setCommissionFee] = useState(0);

  const onBasicPageChange = (event) => {
    setBasicFirst(event.first);
    setBasicRows(event.rows);
  };

  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  function handleSearch(value) {
    if (value === '') {
      setFilteredProperty(properties);
      setFilterToggle(false);
    } else {
      const filtered = properties.filter((property) => {
        return property?.booking_property.title
          .toLowerCase()
          .includes(value.toLowerCase());
      });
      setFilterToggle(true);
      setFilteredProperty(filtered);
    }
  }

  const getPropertyListings = async () => {
    try {
      const response = await getCurrentdData();
      if (response.status === 200) {
        setProperties(response.data?.data);
        setFilteredProperty(response.data?.data);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPropertyListings();
    getCommissionFee()
      .then((res) => {
        setCommissionFee(res.data.data[0].commission_percentage);
      })
      .catch((err) => {});
  }, []);
  const toast = useRef(null);

  const accept = async (id) => {
    try {
      const response = await updateBookingApi(id, 'accepted');
      if (response.status === 200) {
        // setLoading(false);
        toast.current.show({
          severity: 'info',
          summary: 'Booking Accepted',
          life: 3000,
        });
      }
    } catch (err) {
      // setLoading(false);
      toast.current.show({
        severity: 'warn',
        summary: 'Not Accepted',
        detail: 'there seems to be an error right now',
        life: 3000,
      });
    }
  };

  const reject = () => {
    toast.current.show({
      severity: 'warn',
      summary: 'Cancelled',
      detail: 'You have cancelled booking',
      life: 3000,
    });
  };

  const confirm1 = (booking) => {
    confirmDialog({
      message: (
        <div
          style={{
            width: '30vw',
            textAlign: 'left',
            paddingTop: '20px',
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{ overflowY: 'scroll', maxHeight: '250px' }}
          >
            <Grid item xs={6}>
              <div className=''>
                <h1>Name</h1>
                <p>
                  {booking?.owner.firstName + ' ' + booking?.owner.lastName}
                </p>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <h1>PROFILE</h1>
                <img
                  width={50}
                  height={50}
                  alt='alt'
                  src={
                    booking?.owner?.profilePicture !== null
                      ? booking?.owner?.profilePicture
                      : require('../../../assets/images/avatar/5.jpg')
                  }
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <h1>GUEST</h1>
                <p>
                  <i className='fas fa-user'></i>{' '}
                  {booking?.totalAdults +
                    booking?.totalChildren +
                    booking?.totalInfants +
                    booking?.totalPets}
                </p>
                <p>
                  {booking?.totalAdults > 0
                    ? booking?.totalAdults + ' Adults'
                    : ''}
                  {booking?.totalAdults > 0 && booking?.totalChildren > 0
                    ? ' - '
                    : ''}
                  {booking?.totalChildren > 0
                    ? booking?.totalChildren + ' Children'
                    : ''}
                </p>
                <p>
                  {booking?.totalInfants > 0
                    ? booking?.totalInfants + ' Infants'
                    : ''}
                  {booking?.totalInfants > 0 && booking?.totalPets > 0
                    ? ' - '
                    : ''}
                  {booking?.totalPets > 0 ? booking?.totalPets + ' Pets' : ''}
                </p>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <h1>BOOKING DATE</h1>
                <p>{moment(booking?.createdOn).format('DD MMM YYYY')}</p>
              </div>
            </Grid>
            <Grid item xs={12} sx={{ pr: 2 }}>
              <div>
                <h1>PAYMENT INVOICE</h1>
                <div
                  style={{
                    display: 'flex',
                    marginTop: '20px',
                    justifyContent: 'space-between',
                  }}
                >
                  <p>{booking?.numberOfNights} nights </p>
                  <p>
                    {process.env.REACT_APP_CURRENCY}{' '}
                    {booking?.totalAmount?.toLocaleString('en-US')}
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <p>GST 13%</p>
                  <p>
                    {process.env.REACT_APP_CURRENCY}{' '}
                    {booking?.totalGST?.toLocaleString('en-US')}
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <p>Total</p>
                  <p>
                    {process.env.REACT_APP_CURRENCY}{' '}
                    {(booking?.totalAmount + booking?.totalGST)?.toLocaleString(
                      'en-US'
                    )}
                  </p>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      ),
      header: 'Confirmation',
      acceptLabel: 'Accept',
      rejectLabel: 'Decline',
      header: 'Confirmation',
      accept: () => accept(booking.id),
      reject,
    });
  };

  const handleClickOpen = (item) => {
    setOpen(true);
    setBookingDetails(item);
  };

  return (
    <>
      <Header />
      <Toast ref={toast} position='bottom-right' />
      <div id='wrapper'>
        <div className='dashboard-menu-overlay' />
        <Sidebar page={'Current Reservations'} />
        <div className='dashboard-content'>
          <div className='dashboard-menu-btn color-bg'>
            <span>
              <i className='fas fa-bars' />
            </span>
            Dasboard Menu
          </div>

          <InfoBar label={'Current Reservations'} />
          <div className='dasboard-wrapper fl-wrap no-pag'>
            {isLoading === true ? (
              <Loader />
            ) : (
              <>
                {properties?.length === 0 ? (
                  <Grid container>
                    <Grid item xs={12}>
                      <ErrorMessage />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    container
                    rowSpacing={{ xs: 1, sm: 1, md: 1 }}
                    columnSpacing={{ xs: 1, sm: 1, md: 2 }}
                  >
                    {filterToggle
                      ? filteredProperty.map((booking) => {
                          return (
                            <Grid item xs={12} sm={6} md={12}>
                              <SingleWithoutTimer
                                booking={booking}
                                confirm1={confirm1}
                                accept={() => accept(booking.id)}
                                open={open}
                                setOpen={setOpen}
                                handleClickOpen={handleClickOpen}
                              />
                            </Grid>
                          );
                        })
                      : filteredProperty
                          ?.slice(basicFirst, basicRows + basicFirst)
                          .map((booking) => {
                            return (
                              <Grid item xs={12} sm={6} md={12}>
                                <SingleWithoutTimer
                                  booking={booking}
                                  confirm1={confirm1}
                                  open={open}
                                  setOpen={setOpen}
                                  handleClickOpen={handleClickOpen}
                                />
                              </Grid>
                            );
                          })}
                  </Grid>
                )}
              </>
            )}

            <Card>
              <Paginator
                first={basicFirst}
                rows={basicRows}
                totalRecords={properties?.length}
                rowsPerPageOptions={[5, 10, 20, 30]}
                onPageChange={onBasicPageChange}
              ></Paginator>
            </Card>
          </div>
        </div>
        <div className='dashbard-bg gray-bg' />
      </div>
      <ReservationDetail
        open={open}
        handleClose={handleClose}
        booking={bookingDetails}
        scroll={'body'}
        descriptionElementRef={descriptionElementRef}
        header='Confirmation'
        type='host'
        accept={accept}
        commissionFee={commissionFee}
        detailType='CURRENT'
      />
    </>
  );
};

const Loader = () => {
  return (
    <Grid
      container
      rowSpacing={{ xs: 1, sm: 1, md: 2 }}
      columnSpacing={{ xs: 1, sm: 1, md: 2 }}
    >
      <Grid item xs={12} sm={6} md={6}>
        <SkeletonLoader height={200} />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <SkeletonLoader height={200} />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <SkeletonLoader height={200} />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <SkeletonLoader height={200} />
      </Grid>
    </Grid>
  );
};

export default CurrentReservations;
