import * as yup from "yup";

export const PricingValidationSchema = yup.object({
    price: yup.string().required("Price is required"),
    perNightPrice: yup.string().required("Price per night is required"),
    weeklyNightPrice: yup.string(),
    monthlyNightPrice: yup.string(),
    customLengthOfStayDiscount: yup.string(),
    cleaningFee: yup.string(),
    petFee: yup.string(),
    extraGuestFee: yup.string(),
    extraGuestFeeAfter: yup.string(),
    weekendNightPrice: yup.string(),
    minimumStay: yup.string(),
    maximumStay: yup.string(),
});