import Rating from '@mui/material/Rating';

const Ratings = ({ rating }) => {
  return (
    <Rating
      size='medium'
      name='read-only'
      value={rating}
      readOnly
      precision={0.5}
    />
  );
};

export default Ratings;
