import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Buttons from './form/button';

//date-picker
import DatePicker from './form/datePicker';

import bedImg from '../assets/images/homesliderimgs/bed.jpg';
import islandImg from '../assets/images/homesliderimgs/island.jpg';
import terrisImg from '../assets/images/homesliderimgs/terris.jpg';
import villaImg from '../assets/images/homesliderimgs/villa.jpg';
import seaviewImg from '../assets/images/homesliderimgs/seaview.jpg';

import img from '../assets/images/1095424.png'
import syriaImg from '../assets/images/syria-flag.png'

//drop-down
import { GoogleApiWrapper } from 'google-maps-react';
import { GuestCount } from './shared/GuestCount';
import AutoCompletePlaces from './shared/AutoCompletePlaces';
import DatePickerHome from './form/datePickerHome';
import ImageCarousel from './imageCarousel';
import axios from 'axios';
import { API_URL } from '../config';
import { getHomeSliderImgApi } from '../services/Home/getSliderImg';
const Jumbotron = ({ formik }) => {
  const [isSelect, setIsSelect] = useState(false);
  const handleGuestCountChange = async (value, count) => {
    formik.setFieldValue('noOfGuest', value);
    formik.setFieldValue('noOfAdults', count.adults);
    formik.setFieldValue('noOfChildren', count.children);
    formik.setFieldValue('noOfInfants', count.infants);
    formik.setFieldValue('noOfPets', count.pets);
  };

  const getHomeSliderImg = async () => {
    const response = await getHomeSliderImgApi();
    if (response.status === 200) {
      localStorage.setItem('sliderImage', JSON.stringify(response.data.data));
    }
  };
  useEffect(() => {
    getHomeSliderImg();
  }, []);

  return (
    <section
      className='hero-section hero-section_dec'
      data-scrollax-parent='true'
      onClick={() => setIsSelect(!isSelect)}
    >
      <div className='bg-wrap'>
        {/* <img
          className="bg par-elem"
          src={require('../assets/images/cozy.jpg')}
          data-scrollax="properties: { translateY: '30%' }"
          alt="backgroundImage"
        /> */}
        <ImageCarousel
          propertyDetail={JSON.parse(localStorage.getItem('sliderImage'))}
        />
      </div>
      <div className='overlay'></div>
      <div className='container'>
        <div className='hero-title hero-title_small'>
          <h4>World's #1 Digital Disaster Management Platform</h4>
          <h2>
            Find The Properties in <img src={img} style={{paddingBottom:'15px'}} width={"35px"} /> Türkiye, <img src={syriaImg} style={{paddingBottom:'15px'}} width={"35px"} /> Syria and Donate for <br />
            Earthquake Victims Using Our Platform
          </h2>
        </div>
        <div className='main-search-input-wrap'>
          <div className='main-search-input fl-wrap'>
            <Grid
              container
              rowSpacing={{ xs: 2, sm: 2, md: 2 }}
              columnSpacing={{ xs: 1, sm: 1, md: 1 }}
            >
              <Grid item xs={12} sm={12} md={3.5}>
                <AutoCompletePlaces
                  formik={formik}
                  label='Search Destination...'
                />
                {formik.touched.address && Boolean(formik.errors.address) && (
                  <small
                    id='username2-help'
                    className='p-error'
                    style={{
                      display: 'block',
                      color: 'red',
                      textAlign: 'left',
                    }}
                  >
                    {formik.errors.address}
                  </small>
                )}
              </Grid>
              <Grid className='custom-form' item xs={12} sm={12} md={4}>
                <DatePickerHome
                  formik={formik}
                  handlers
                  handleDateStart={(value) =>
                    formik.setFieldValue('startDate', value)
                  }
                  handleDateEnd={(value) =>
                    formik.setFieldValue('endDate', value)
                  }
                />
              </Grid>

              <Grid item xs={12} sm={12} md={2.5}>
                <GuestCount
                  isSelect={isSelect}
                  handleChange={handleGuestCountChange}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={2}>
                <Buttons
                  style={{ height: 55, fontSize: 14 }}
                  title={'Search'}
                  onSubmit={formik.handleSubmit}
                  isLoading={false}
                />
              </Grid>
            </Grid>
          </div>
        </div>
        <div className='scroll-down-wrap'>
          <div className='mousey'>
            <div className='scroller'></div>
          </div>
          <span>Scroll Down To Discover</span>
        </div>
      </div>
    </section>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
})(Jumbotron);
