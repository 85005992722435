import React, { useRef, useState } from 'react';
import InfoBar from '../../../components/dasboard/infoBar';
import Sidebar from '../../../components/dasboard/sidebar';
import Header from '../../../components/header';
import { Box, Typography } from '@mui/material';
import getUserCardApi from '../../../services/User/getUserCard';
import { FaCcMastercard, FaCcVisa } from 'react-icons/fa';
import { AiOutlineRight } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { AiOutlineLeft } from 'react-icons/ai';
import PaymentsIcon from '@mui/icons-material/Payments';
import setCardAsDefaultApi from '../../../services/User/setUserCardAsDefault';
import { Toast } from 'primereact/toast';

const YourCardsPage = () => {
  const userData = JSON.parse(localStorage.getItem('userData'));
  const [userCards, setUserCards] = React.useState([]);

  React.useEffect(() => {
    const getUserCards = async () => {
      const res = await getUserCardApi(userData?.id);
      setUserCards(res?.data);
    };
    getUserCards();
  }, [userData?.id]);
  const toast = useRef(null);

  const setCardAsDefault = async (cardiD) => {
    const response = await setCardAsDefaultApi({
      id: cardiD,
      userId: userData?.id,
    });
    if (response.status === 201) {
      toast.current.show({
        severity: 'success',
        summary: 'Card set as default!',
        detail: 'Card is set to default successfully!',
        life: 10000,
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      toast.current.show({
        severity: 'error',
        summary: 'Could not set card to default',
        detail: 'An error occured when setting card as default.',
        life: 10000,
      });
    }
  };

  return (
    <>
      <Toast ref={toast} position='bottom-right' />
      <Header />
      <div id='wrapper'>
        <div className='dashboard-menu-overlay' />
        <Sidebar page={'Your Cards'} />
        <div className='dashboard-content'>
          <div className='dashboard-menu-btn color-bg'>
            <span>
              <i className='fas fa-bars' />
            </span>
            Your Cards
          </div>
          <InfoBar label={'Your Cards'} />
          <div className='dasboard-wrapper fl-wrap no-pag'>
            <div>
              <div className='clearfix' />
              <div className='row'>
                <div className='col-md-8'>
                  <div
                    className='dasboard-content fl-wrap'
                    style={{ margin: '10px 0px' }}
                  >
                    <Box sx={{ p: 3 }}>
                      <Box>
                        <Box
                          sx={{
                            padding: '25px 0px',
                          }}
                        >
                          <Link
                            to='/wallet'
                            style={{
                              display: 'flex',
                              justifyContent: 'start',
                              alignItems: 'center',
                              flexDirection: 'row-reverse',
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <PaymentsIcon
                                style={{
                                  marginRight: '8px',
                                  fontSize: '22px',
                                  color: '#fff',
                                }}
                              />
                              <div
                                style={{
                                  fontSize: '18px',
                                  color: 'white',
                                }}
                              >
                                Back To Wallet
                              </div>
                            </Box>
                            <AiOutlineLeft
                              style={{
                                fontSize: '18px',
                                color: '#fff',
                                marginRight: '6px',
                              }}
                            />
                          </Link>
                        </Box>
                      </Box>
                      <Typography
                        sx={{
                          textAlign: 'left',
                          color: '#fff',
                          fontSize: '18px',
                        }}
                      >
                        Your Cards
                      </Typography>
                      {userCards &&
                        userCards?.map((card, i) => {
                          return (
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                border: `1px solid ${
                                  card?.isDefault
                                    ? 'rgb(0, 150, 255)'
                                    : 'rgba(255, 255, 255, 0.1)'
                                }`,
                                borderRadius: '8px',
                                padding: '6px',
                                height: '100%',
                                margin: '10px 0px',
                              }}
                              key={i}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'start',
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: '#fff',
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  {card?.brand === 'Visa' && (
                                    <FaCcVisa
                                      style={{
                                        marginRight: '8px',
                                        color: '#fff',
                                        fontSize: '25px',
                                      }}
                                    />
                                  )}
                                  {card?.brand === 'MasterCard' && (
                                    <FaCcMastercard
                                      style={{
                                        marginRight: '8px',
                                        color: '#fff',
                                        fontSize: '25px',
                                      }}
                                    />
                                  )}
                                  {card?.brand}
                                </Typography>
                                <Typography
                                  sx={{ marginLeft: '10px' }}
                                >{`...${card?.last4digit}`}</Typography>
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                {!card?.isDefault && (
                                  <div
                                    onClick={() => setCardAsDefault(card?.id)}
                                    style={{
                                      textDecoration: 'underline',
                                      color: 'rgb(0, 150, 255)',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    Set As Default
                                  </div>
                                )}
                                <div
                                  style={{
                                    textDecoration: 'underline',
                                    marginLeft: '8px',
                                    color: 'red',
                                    cursor: 'pointer',
                                  }}
                                >
                                  Delete
                                </div>
                              </div>
                            </Box>
                          );
                        })}
                      <Box>
                        <Box
                          sx={{
                            padding: '25px 0px',
                          }}
                        >
                          <Link
                            to='/admin/add-card'
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <i
                                className='fal fa-credit-card'
                                style={{
                                  marginRight: '8px',
                                  fontSize: '25px',
                                  color: '#0096ff',
                                }}
                              ></i>
                              <div
                                style={{
                                  fontSize: '18px',
                                  color: 'white',
                                }}
                              >
                                Add Credit Or Debit Card
                              </div>
                            </Box>
                            <AiOutlineRight
                              style={{
                                fontSize: '14px',
                                color: '#fff',
                              }}
                            />
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default YourCardsPage;
