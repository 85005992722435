import React, { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Rating, Tooltip, Typography } from '@mui/material';
import { Toast } from 'primereact/toast';
import moment from 'moment';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const SingleWithoutTimer = ({ booking, accept, handleClickOpen, pageType }) => {
  const toast = useRef(null);
  const navigate = useNavigate();
  const [guestReview, setGuestReview] = React.useState([]);

  let total = 0;
  let totalLength = guestReview?.items?.length;
  for (let i = 0; i < guestReview?.items?.length; i++) {
    total += guestReview?.items[i].over_all;
  }
  let totalRating = total / totalLength;

  return (
    <div className='dashboard-listings-item fl-wrap'>
      <Toast ref={toast} position='bottom-right' />
      <div className='dashboard-listings-item_img'>
        <div className='bg-wrap1'>
          <img className='bg' src={booking?.property.thumbnailUrl} />
        </div>
      </div>
      <div className='dashboard-listings-item_content'>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <h4>
              <Tooltip title={booking?.property.title}>
                <Link to={`/property-detail/${booking?.id}`}>
                  {booking?.property.title.length > 40 ? (
                    <a
                      onMouseOver={(e) =>
                        (e.target.style.textOverflow = 'ellipsis')
                      }
                    >
                      {booking?.property.title.substring(0, 40)}...
                    </a>
                  ) : (
                    <a
                      onMouseOver={(e) =>
                        (e.target.style.textOverflow = 'ellipsis')
                      }
                    >
                      {booking?.property.title}
                    </a>
                  )}
                </Link>
              </Tooltip>
            </h4>
            <div
              className='geodir-category-location'
              style={{ marginBottom: '2%' }}
            >
              <a style={{ textDecoration: 'none' }}>
                <i className='fas fa-map-marker-alt'></i>{' '}
                <span
                  style={{
                    fontSize: '12px',
                    color: '#bbbbbb',
                  }}
                >
                  {booking?.property.address}
                  <div></div>
                </span>
              </a>
            </div>
            <div style={{ textAlign: 'start' }}>
              <div>
                <a
                  style={{
                    textAlign: 'left',
                    color: 'green',
                    fontWeight: 'bold',
                    fontSize: '16px',
                  }}
                >
                  {booking?.bookingStatus === 'pending' ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {' '}
                      <AccessTimeFilledIcon
                        sx={{
                          fontSize: '18px',
                          color: '#fff',
                          marginRight: '5px',
                        }}
                      />
                      <span>Awaiting your approval!</span>
                    </div>
                  ) : booking?.bookingStatus === 'accepted' ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {' '}
                      <AccessTimeFilledIcon
                        sx={{
                          fontSize: '18px',
                          color: '#fff',
                          marginRight: '5px',
                        }}
                      />
                      <span>Pending guest payment.</span>
                    </div>
                  ) : booking?.bookingStatus === 'confirmed' ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {' '}
                      <CheckCircleIcon
                        sx={{
                          fontSize: '18px',
                          color: '#fff',
                          marginRight: '5px',
                        }}
                      />
                      <span>Booking confirmed.</span>
                    </div>
                  ) : (
                    ''
                  )}
                </a>
              </div>
              <div>
                <a style={{ textAlign: 'start', color: 'white' }}>
                  Property ID # {booking?.property.code}
                </a>
              </div>

              <div>
                <a style={{ textAlign: 'start', color: 'white' }}>
                  Booking ID # {booking?.code}
                </a>
              </div>
              <div>
                <Typography
                  sx={{
                    fontFamily: 'Jost',
                    fontSize: '16px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Rating
                    name='half-rating-read'
                    value={
                      booking?.property?.average_rating
                        ? booking?.property?.average_rating?.averageRating
                        : 0
                    }
                    precision={0.5}
                    readOnly
                    sx={{ marginRight: '4px' }}
                  />
                  (
                  {booking?.property?.average_rating
                    ? parseInt(
                        booking?.property?.average_rating?.averageRating
                      )?.toFixed(1)
                    : 0}
                  )
                  {booking?.property?.average_rating
                    ? ` (${booking?.property?.average_rating?.reviewCount} Reviews)`
                    : ' (0) Reviews'}
                </Typography>
              </div>

              <div>
                <a style={{ textAlign: 'start', color: 'white' }}>
                  {booking?.startDate && booking?.endDate && (
                    <>
                      From {moment(booking?.startDate).format('DD MMM YYYY')} to{' '}
                      {moment(booking?.endDate).format('DD MMM YYYY')}
                    </>
                  )}
                </a>
              </div>
              <div>
                <a
                  style={{
                    textAlign: 'start',
                    color: 'white',
                    fontSize: '18px',
                  }}
                >
                  {process.env.REACT_APP_CURRENCY}{' '}
                  {booking?.grossAmount?.toLocaleString('en-US')}
                </a>
              </div>
            </div>
            <div className='clearfix' style={{ paddingBottom: 10 }}></div>

            <div
              style={{
                textAlign: 'initial',
                display: 'flex',
                alignItems: 'center',
              }}
            ></div>
          </div>
        </div>

        <div
          className='dashboard-listings-item_opt'
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <ul>
            {!(pageType === 'PAST') && (
              <li
                style={{
                  padding: '16px 10px',
                  color: '#0095ff',
                  cursor: 'pointer',
                }}
                isLoading={false}
                onClick={() => navigate(`/modify-booking/${booking?.id}`)}
              >
                Modify Booking
              </li>
            )}
            <li
              style={{
                padding: '16px 10px',
                color: '#0095ff',
                cursor: 'pointer',
              }}
              isLoading={false}
              onClick={() => handleClickOpen(booking)}
            >
              View
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SingleWithoutTimer;
