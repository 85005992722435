import React, { useState, useRef } from 'react';
import Header from '../../../components/header';
import Sidebar from '../../../components/dasboard/sidebar';
import { Box, Rating, TextField, Typography } from '@mui/material';
import { Toast } from 'primereact/toast';
import { useParams } from 'react-router-dom';
import { rateGuestApi } from '../../../services/review/rateGuest';
export default function GuestReview() {
  const toast = useRef(null);
  const { guestId } = useParams();
  const { propertyId } = useParams();
  const { bookingId } = useParams();
  const userData = JSON.parse(localStorage.getItem('userData')) || null;
  const [overAll, setOverAll] = useState(0);
  const [publicReview, setPublicReview] = useState('');
  const [privateReview, setPrivateReview] = useState('');

  const handleControl = async (e) => {
    e.preventDefault();
    if (publicReview === '' || privateReview === '') {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please provide your review!',
        life: 3000,
      });
      return;
    } else {
      const reviewData = {
        over_all: overAll,
        public_note: publicReview,
        private_note: privateReview,
        host_id: userData?.id,
        guest_id: guestId,
        property_id: propertyId,
        booking_id: bookingId,
      };
      try {
        const response = await rateGuestApi(reviewData);
        if (response.status === 200) {
          toast.current.show({
            severity: 'success',
            summary: 'Review Sent',
            detail: 'Review about guest sent!',
            life: 3000,
          });
          setTimeout(() => {
            window.location = '/admin/previous-reservation';
          }, 1500);
        } else {
          toast.current.show({
            severity: 'error',
            summary: 'Error sending Review',
            detail: 'There is a problem sending review',
            life: 3000,
          });
        }
      } catch (error) {
        if (error) {
          toast.current.show({
            severity: 'error',
            summary: 'Error sending Review',
            detail: error,
            life: 3000,
          });
        }
      }
    }
  };
  const search = window.location.search;
  const guestName = new URLSearchParams(search).get('guest');

  return (
    <>
      <Header />
      <Toast ref={toast} position='bottom-right' />
      <div id='wrapper'>
        <div className='dashboard-menu-overlay' />
        <Sidebar page={'Reviews'} />
        <div className='dashboard-content'>
          <div className='dashboard-menu-btn color-bg'>
            <span>
              <i className='fas fa-bars' />
            </span>
            Dasboard Menu
          </div>
          <div className='container dasboard-container'>
            <div className='dashboard-title fl-wrap'>
              <div className='dashboard-title-item'>
                <span style={{ fontSize: '15px' }}>Review {guestName}</span>
              </div>
            </div>
          </div>
          {/*  */}

          <div class='dasboard-wrapper fl-wrap no-pag'>
            <div class='row'>
              <div class='col-md-7'>
                <div class='dasboard-widget-title fl-wrap'>
                  <h5>
                    <i class='fas fa-comment'></i>Leave a review
                  </h5>
                </div>
                <div class='dasboard-widget-box fl-wrap'>
                  <div class='custom-form'>
                    <form onSubmit={handleControl}>
                      <Typography>Public Review</Typography>
                      <TextField
                        multiline
                        rows={4}
                        required
                        sx={{ width: '100%', mb: 3 }}
                        placeholder='Tell everyone how your experience was!'
                        variant='standard'
                        value={publicReview ?? ''}
                        onChange={(e) => setPublicReview(e.target.value)}
                      />
                      <Typography>Private Note</Typography>
                      <TextField
                        multiline
                        rows={4}
                        required
                        sx={{ width: '100%' }}
                        placeholder='Leave the private note for the guest about how he can improve his service.'
                        variant='standard'
                        value={privateReview ?? ''}
                        onChange={(e) => setPrivateReview(e.target.value)}
                      />
                      <button
                        type='submit'
                        class='btn color-bg float-btn'
                        style={{
                          cursor: 'pointer',
                          outline: 'none',
                          border: 'none',
                        }}
                      >
                        Submit Review
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              <div class='col-md-5'>
                <div class='dasboard-widget-title dbt-mm fl-wrap'>
                  <h5>
                    <i class='fas fa-star'></i>Review Your Stay
                  </h5>
                </div>
                <div class='dasboard-widget-box fl-wrap'>
                  <Box
                    id='guest-rating'
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      mb: 2,
                    }}
                  >
                    <Typography
                      variant='p'
                      sx={{
                        color: '#fff',
                        fontSize: '22px',
                        textAlign: 'left',
                      }}
                    >
                      Overall Rating
                    </Typography>
                    <Rating
                      name='simple-controlled'
                      sx={{ fontSize: '45px', mt: 1 }}
                      value={overAll}
                      onChange={(event, newValue) => {
                        setOverAll(newValue);
                      }}
                    />
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
