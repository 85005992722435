import axios from "axios";
import { API_URL } from "../../config";

const addDonationApi = async (data) => {
  try {
    const response = await axios.post(API_URL + "donations", data);
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log("bad response of get donation add : ", response);
      return response;
    }
  }
};
export default addDonationApi;
