import { Grid, Typography } from "@mui/material";
import HouseIcon from "@mui/icons-material/House";
import React from "react";

const ErrorMessage = () => {
  return (
    <Grid
      conatiner
      sx={{ border: "1px solid #5c5c5c", padding: 5, borderRadius: 3     }}
    >
      <Grid item xs={12}>
        <HouseIcon sx={{ fontSize: 100, color: "#2e94f7" }} />
        <Typography variant="subtitle1" sx={{ color: "white" }}>
          No Properties avalaible right now.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ErrorMessage;
