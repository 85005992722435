import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Input from '../../../components/form/input';
import CssBaseline from '@mui/material/CssBaseline';
import Buttons from '../../../components/form/button';
import BACKGROUND from '../../../assets/images/otp.png';
import { PhoneNumberValidationSchema } from './PhoneNumberLoginValidationSchema';
import { loginWithPhoneNumberApi } from '../../../services/Authentication/Login';
import LOGO from '../../../assets/images/logo.png';
import { auth } from '../../../firebaseInit';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import * as yup from 'yup';
import { Toast } from 'primereact/toast';
import { Button, Divider, Modal, TextField } from '@mui/material';
import InputPass from '../../../components/form/inputpass';
import { socialLoginApi } from '../../../services/Authentication/SocialLogin';
import createPasswordApi from '../../../services/User/createPassword';

// ** React Phone number
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

function Copyright(props) {
  return (
    <Typography
      variant='body2'
      color='text.secondary'
      align='center'
      {...props}
    >
      {'© Staywo Inc.USA 2022 . All rights reserved.'}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  p: 4,
  textAlign: 'start',
};
const PhoneNumberLoginPage = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const history = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [otpField, setOtpField] = useState(false);
  const [confirmation, setConfirmation] = useState({});
  const [fUser, setFUser] = useState({});

  const [phoneNumber, setPhoneNumber] = useState('');

  // phone number submit handler
  const phoneNumberSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const elem = document.getElementById('recaptcha-container');
    let verify = new RecaptchaVerifier(
      elem,
      {
        size: 'invisible',
      },
      auth
    );
    signInWithPhoneNumber(auth, phoneNumber, verify)
      .then((confirmationResult) => {
        setIsLoading(false);
        toast.current.show({
          severity: 'success',
          summary: 'Successfully sent OTP',
          detail: 'OTP have been successfully sent to your mobile number.!',
          life: 10000,
        });
        setOtpField(true);
        setConfirmation(confirmationResult);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        toast.current.show({
          severity: 'error',
          summary: 'Couldnt send OTP',
          detail: `${error.message}`,
          life: 10000,
        });
      });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const otpFormik = useFormik({
    initialValues: {
      otp: '',
    },
    validationSchema: () => {
      return yup.object({
        otp: yup.string().required('OTP Is Required.'),
      });
    },
    onSubmit: (values) => {
      setIsLoading(true);
      confirmation
        .confirm(values.otp)
        .then(async (res) => {
          setIsLoading(false);
          const phoneAuthRes = await socialLoginApi({
            accessToken: res?.user?.accessToken,
            platform: 'phone_number',
            phoneNumber: res?.user?.phoneNumber,
          });

          if (phoneAuthRes?.data?.payload?.existingUser) {
            localStorage.setItem(
              'userToken',
              phoneAuthRes?.data?.payload?.token?.token
            );
            localStorage.setItem('user', true);

            localStorage.setItem(
              'userData',
              JSON.stringify({
                firstName: phoneAuthRes?.data?.payload?.token?.user?.firstName,
                lastName: phoneAuthRes?.data?.payload?.token?.user?.lastName,
                id: phoneAuthRes?.data?.payload?.token?.user?.id,
                username: phoneAuthRes?.data?.payload?.token?.user?.username,
                isHost: phoneAuthRes?.data?.payload?.token?.user?.isHost,
                verified: phoneAuthRes?.data?.payload?.token?.user?.verified,
                isFacebookVerified:
                  phoneAuthRes?.data?.payload?.token?.user?.isFacebookVerified,
                isGoogleVerified:
                  phoneAuthRes?.data?.payload?.token?.user?.isGoogleVerified,
                isDiamondHost:
                  phoneAuthRes?.data?.payload?.token?.user?.isDiamondHost,
              })
            );
            enqueueSnackbar('Successfully logged in.', {
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
            });
            history('/');
          } else {
            setFUser(res?.user);
            handleOpen();
          }
        })
        .catch((err) => {
          setIsLoading(false);
          toast.current.show({
            severity: 'error',
            summary: 'Invalid OTP',
            detail: `${err.message}`,
            life: 10000,
          });
        });
    },
  });
  const toast = React.useRef(null);
  const loginFormik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      password: '',
    },
    enableReinitialize: true,
    validationSchema: () => {
      return yup.object({
        firstName: yup.string().required('Please add your First Name.'),
        lastName: yup.string().required('Please add your Last Name.'),
        password: yup.string().required('Please create a password.'),
      });
    },
    onSubmit: async (values) => {
      try {
        const phoneAuthRes = await socialLoginApi({
          accessToken: fUser?.accessToken,
          platform: 'phone_number',
          phoneNumber: fUser?.phoneNumber,
          firstName: values.firstName,
          lastName: values.lastName,
        });

        if (phoneAuthRes?.data?.payload?.existingUser) {
          localStorage.setItem(
            'userToken',
            phoneAuthRes?.data?.payload?.token?.token
          );
          localStorage.setItem('user', true);

          localStorage.setItem(
            'userData',
            JSON.stringify({
              firstName: phoneAuthRes?.data?.payload?.token?.user?.firstName,
              lastName: phoneAuthRes?.data?.payload?.token?.user?.lastName,
              id: phoneAuthRes?.data?.payload?.token?.user?.id,
              username: phoneAuthRes?.data?.payload?.token?.user?.username,
              isHost: phoneAuthRes?.data?.payload?.token?.user?.isHost,
              verified: phoneAuthRes?.data?.payload?.token?.user?.verified,
              isFacebookVerified:
                phoneAuthRes?.data?.payload?.token?.user?.isFacebookVerified,
              isGoogleVerified:
                phoneAuthRes?.data?.payload?.token?.user?.isGoogleVerified,
              isDiamondHost:
                phoneAuthRes?.data?.payload?.token?.user?.isDiamondHost,
            })
          );
          const uid = phoneAuthRes?.data?.payload?.token?.user?.id;
          await createPasswordApi(
            {
              password: values.password,
              confirmPassword: values.password,
            },
            uid
          );
          enqueueSnackbar('Successfully logged in.', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
          history('/');
        }
      } catch (error) {
        enqueueSnackbar('Error logging in.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      }
    },
  });
  return (
    <>
      <Toast ref={toast} position='bottom-right' />
      <Grid container component='main' sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={8}
          sx={{
            backgroundImage: `url(${BACKGROUND})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light'
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid
          style={{ background: '#1b182b' }}
          item
          xs={12}
          sm={8}
          md={4}
          component={Paper}
          elevation={6}
          square
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'left',
            }}
          >
            <Grid item xs={12} justifyContent='center' display='flex'>
              <a href='/' className='logo-holder'>
                <img
                  src={`${LOGO}`}
                  width='242px'
                  height='100px'
                  marginBottom='20px'
                  alt=''
                />
              </a>
            </Grid>
            <Typography textAlign='center' component='h1' variant='h5'>
              <h4>Phone Login</h4>
            </Typography>
            <Box component='form' noValidate sx={{ mt: 5 }}>
              {!otpField ? (
                <>
                  {/* <Input
                    sx={{ mb: 3, fontSize: '14px', marginBottom: 8 }}
                    id={'phoneNumber'}
                    name={'phoneNumber'}
                    label={'Phone Number'}
                    type={'text'}
                    onChange={formik.handleChange}
                    value={formik.values.phoneNumber}
                    defaultValue={formik.values.phoneNumber}
                    error={
                      formik.touched.phoneNumber &&
                      Boolean(formik.errors.phoneNumber)
                    }
                    helperText={
                      formik.touched.phoneNumber && formik.errors.phoneNumber
                    }
                  /> */}
                  <PhoneInput
                    containerClass='phone-number'
                    dropdownClass={'phone-number-dropdown'}
                    inputStyle={{
                      background: 'none',
                      color: 'white',
                      padding: '15px',
                    }}
                    country={'us'}
                    value={phoneNumber}
                    onChange={(value) => setPhoneNumber(`+${value}`)}
                    id={'phoneNumber'}
                    name={'phoneNumber'}
                    label={'Phone Number'}
                    type={'text'}
                    defaultValue={phoneNumber}
                    enableSearch={true}
                    disableSearchIcon={true}
                  />
                  <div id='recaptcha-container'></div>
                  <Buttons
                    title='Send OTP'
                    loadingTitle={'Sending OTP'}
                    isLoading={isLoading}
                    style={{ height: 55, mb: 5, mt: 0, fontSize: 14 }}
                    onSubmit={(e) => phoneNumberSubmit(e)}
                  />
                </>
              ) : (
                <>
                  <Input
                    sx={{ mb: 3, fontSize: '14px', marginBottom: 8 }}
                    id={'otp'}
                    name={'otp'}
                    label={'OTP'}
                    type={'text'}
                    onChange={otpFormik.handleChange}
                    value={otpFormik.values.otp}
                    defaultValue={otpFormik.values.otp}
                    error={
                      otpFormik.touched.otp && Boolean(otpFormik.errors.otp)
                    }
                    helperText={otpFormik.touched.otp && otpFormik.errors.otp}
                  />
                  <Buttons
                    title='Verify'
                    loadingTitle={'Verifying OTP'}
                    isLoading={isLoading}
                    style={{ height: 55, mb: 5, mt: 0, fontSize: 14 }}
                    onSubmit={otpFormik.handleSubmit}
                  />
                </>
              )}
              <Grid container justifyContent={'center'} alignItems={'center'}>
                <Grid item>
                  <Link
                    onClick={() => history('/login')}
                    variant='body2'
                    style={{ fontSize: '14px', cursor: 'pointer' }}
                  >
                    {'Need to use other login options?'}
                  </Link>
                </Grid>
              </Grid>
              <Copyright
                sx={{
                  mt: 2,
                  fontSize: '14px',
                  position: 'absolute',
                  bottom: '8px',
                  right: '6%',
                }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>

      <div>
        <Button onClick={handleOpen}></Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={style}>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                '& > :not(style)': {
                  m: 1,
                  width: 600,
                  height: 600,
                },
              }}
            >
              <Paper
                elevation={3}
                style={{
                  maxHeight: 900,
                  overflow: 'auto',
                  padding: 20,
                  background: '#1B182B',
                }}
              >
                <img
                  src={LOGO}
                  alt='Logo'
                  srcset=''
                  width={100}
                  height={50}
                  style={{
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginBottom: '5px',
                  }}
                />
                <h5 style={{ textAlign: 'center' }}>Finish signing up</h5>
                <Divider sx={{ marginBottom: 3 }} />
                <Box
                  sx={{
                    width: 600,
                    maxWidth: '100%',
                  }}
                >
                  <TextField
                    fullWidth
                    placeholder='Name'
                    id='fullWidth'
                    name='firstName'
                    value={loginFormik.values.firstName}
                    onChange={loginFormik.handleChange}
                  />
                </Box>
                <Box
                  sx={{
                    width: 600,
                    maxWidth: '100%',
                  }}
                >
                  <TextField
                    fullWidth
                    placeholder='Last Name'
                    name='lastName'
                    id='fullWidth'
                    value={loginFormik.values.lastName}
                    onChange={loginFormik.handleChange}
                  />
                  <span>
                    Make sure it matches the name on your government ID.
                  </span>
                </Box>
                <Box
                  sx={{
                    width: 600,
                    maxWidth: '100%',
                    marginTop: 3,
                  }}
                >
                  <TextField
                    fullWidth
                    placeholder='Phone Number'
                    id='fullWidth'
                    name='phoneNumber'
                    value={fUser?.phoneNumber}
                    disabled
                  />
                </Box>
                <Box
                  sx={{
                    width: 600,
                    maxWidth: '100%',
                    marginTop: 3,
                  }}
                >
                  <InputPass
                    sx={{ mb: 3, fontSize: '14px', position: 'relative' }}
                    id={'password'}
                    name={'password'}
                    label={'Password'}
                    onChange={loginFormik.handleChange}
                  />
                  <span>Add Your Password</span>
                </Box>
                <p style={{ marginTop: 20, color: '#fff' }}>
                  By selecting Agree and continue, I agree to Staywo{' '}
                  <Link>Terms of Service</Link>,{' '}
                  <Link>Payments Terms of Service,</Link> and Nondiscrimination
                  Policy and acknowledge the Privacy Policy.
                </p>
                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    variant='contained'
                    onClick={loginFormik.handleSubmit}
                    fullWidth
                  >
                    Agree And Countinue
                  </Button>
                </Box>
              </Paper>
            </Box>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default PhoneNumberLoginPage;
