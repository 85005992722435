import { Rating } from '@mui/material';
import React from 'react';
import moment from 'moment';
const SingleReview = ({ item }) => {
  const {
    accuracy,
    check_in,
    cleanliness,
    communication,
    location,
    over_all,
    value,
  } = item;

  return (
    <div className='reviews-comments-item'>
      <div className='review-comments-avatar'>
        <img
          src={
            item?.guest_user?.profilePicture
              ? item?.guest_user?.profilePicture
              : require('../../../assets/images/avatar/5.jpg')
          }
          alt=''
        />
      </div>
      <div className='reviews-comments-item-text smpar'>
        <div className='show-more-snopt-tooltip bxwt'></div>
        <h4>
          <a style={{ textDecoration: 'none' }} href='#'>
            {item.guest_user.firstName} {item?.guest_user?.middleName}{' '}
            {item.guest_user.lastName}
          </a>
        </h4>
        <div className='listing-rating' style={{ top: 3, marginLeft: 10 }}>
          <Rating name='readOnly' value={item?.over_all} readOnly />
          {/* <Ratings avaRating={avaRating} /> */}
        </div>
        <div className='clearfix'></div>
        <p>{item.public_note}</p>
        <div className='reviews-comments-item-date'>
          <span className='reviews-comments-item-date-item'>
            <i className='far fa-calendar-check'></i>
            {moment(item.createdOn).format('DD MMM YYYY')}
          </span>
        </div>
      </div>
    </div>
  );
};

export default SingleReview;
