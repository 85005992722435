import React, { useState } from "react";
import { Chart } from "primereact/chart";
import InfoBar from "../../../components/dasboard/infoBar";
import Sidebar from "../../../components/dasboard/sidebar";
import Header from "../../../components/header";
import { Box, Typography } from "@mui/material";
import { AiOutlineRight } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import PaymentsIcon from "@mui/icons-material/Payments";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { getUserWalletApi } from "../../../services/wallet/getUserWallet";
import { useEffect } from "react";
const WalletPage = () => {
  const userData = JSON.parse(localStorage.getItem("userData")) || null;
  const [wallet, setWallet] = useState();
  const getWallet = async () => {
    const res = await getUserWalletApi(userData?.id);
    setWallet(res);
  };
  useEffect(() => {
    getWallet();
  }, []);
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <div id="wrapper">
        <div className="dashboard-menu-overlay" />
        <Sidebar page={"Wallet"} />
        <div className="dashboard-content">
          <div className="dashboard-menu-btn color-bg">
            <span>
              <i className="fas fa-bars" />
            </span>
            My Wallet
          </div>
            <InfoBar label={"My Wallet"} />
          <div className="dasboard-wrapper fl-wrap no-pag">
            <div>
              <div className="clearfix" />
              <div className="row">
                <div className="col-md-8">
                  <div
                    className="dasboard-content fl-wrap"
                    style={{ margin: "10px 0px" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "25px",
                          color: "#fff",
                          padding: "20px",
                        }}
                      >
                        <PaymentsIcon
                          style={{
                            marginRight: "16px",
                            fontSize: "18px",
                            color: "#fff",
                          }}
                        />
                        <span
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                          }}
                        >
                          {wallet?.amount
                            ? wallet?.amount?.toLocaleString("en-US")
                            : 0}{" "}
                          {process.env.REACT_APP_CURRENCY}
                          <AddCircleIcon
                            style={{
                              marginRight: "16px",
                              fontSize: "25px",
                              color: "#fff",
                              cursor: "pointer",
                              marginLeft: "10px",
                            }}
                            onClick={() => navigate("/wallet/top-up")}
                          />
                        </span>
                      </Typography>
                    </Box>
                  </div>
                  <div
                    className="dasboard-content fl-wrap"
                    style={{ margin: "10px 0px" }}
                  >
                    <Box sx={{ p: 3 }}>
                      <Box sx={{ display: "flex" }}>
                        <Typography
                          sx={{
                            marginRight: "5px",
                            fontSize: "22px",
                            color: "",
                          }}
                        ></Typography>
                        <Typography sx={{ fontSize: "22px", color: "#fff" }}>
                          Add Payment Method
                        </Typography>
                      </Box>
                      <Box>
                        <Box
                          sx={{
                            padding: "25px 0px",
                          }}
                        >
                          <Link
                            to="/admin/add-card"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <i
                                className="fal fa-credit-card"
                                style={{
                                  marginRight: "8px",
                                  fontSize: "25px",
                                  color: "#0096ff",
                                }}
                              ></i>
                              <div style={{ fontSize: "18px", color: "white" }}>
                                Credit Or Debit Card
                              </div>
                            </Box>
                            <AiOutlineRight
                              style={{ fontSize: "14px", color: "#fff" }}
                            />
                          </Link>
                        </Box>
                        <Box
                          sx={{
                            padding: "25px 0px",
                          }}
                        >
                          <Link
                            to="/admin/wallet-transaction-history"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <i
                                className="fal fa-money-check-edit"
                                style={{
                                  marginRight: "8px",
                                  fontSize: "25px",
                                  color: "#0096ff",
                                }}
                              ></i>
                              <div style={{ fontSize: "18px", color: "white" }}>
                                Wallet Transaction History
                              </div>
                            </Box>
                            <AiOutlineRight
                              style={{ fontSize: "14px", color: "#fff" }}
                            />
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WalletPage;
