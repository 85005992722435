import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/system';
import { DialogActions, Rating, Typography } from '@mui/material';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Link } from 'react-router-dom';
import { AiFillStar, AiFillHome, AiOutlineRight } from 'react-icons/ai';
import { MdVerifiedUser } from 'react-icons/md';
import { BiLogInCircle } from 'react-icons/bi';
import { FcBusinessman } from 'react-icons/fc';
import { BsCash, BsLockFill } from 'react-icons/bs';
import Buttons from '../../../components/form/button';
import moment from 'moment';
import getBookingGuestAPI from '../../../services/booking/getBookingGuest';
import { InputTextarea } from 'primereact/inputtextarea';
import { whoIsComingApi } from '../../../services/booking/whoIsComing';
import { useSnackbar } from 'notistack';
import NightlightRoundIcon from '@mui/icons-material/NightlightRound';
import { calculateBookingPriceApi } from './../../../services/booking/calculateBookingPrice';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PetsIcon from '@mui/icons-material/Pets';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { useCountdown } from '../../../components/BookingComponents/CountDownHook';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { editPropertyCalenderDateByRange } from '../../../services/properties/propertyCalenderPrice';

function ReservationDetail({
  open,
  handleClose,
  scroll,
  descriptionElementRef,
  booking,
  type,
  header,
  accept,
  isLoadingAccept,
  isLoadingDecline,
  statusType,
  commissionFee,
  showInstruction,
  detailType,
}) {
  const { getBookingDetail } = getBookingGuestAPI();
  const [bookingDetail, setBookingDetail] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [whoIsComing, setWhoIsComing] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [values, setValues] = useState({});

  const getBookingData = async (id) => {
    try {
      // setIsLoading(false);
      const response = await getBookingDetail(id);
      if (response.status === 200) {
        setBookingDetail(response.data?.data);
        console.log(response.data.data)
        let dataaaa = response.data?.data;
        setWhoIsComing(dataaaa?.whoIsComing);

        try {
          const resulttt = await calculateBookingPriceApi({
            propertyId: dataaaa.property.id,
            startDate: moment(dataaaa.startDate).format('YYYY-MM-DD'),
            endDate: moment(dataaaa.endDate).format('YYYY-MM-DD'),
            noOfAdults: dataaaa.totalAdults,
            noOfChildren: dataaaa.totalChildren,
            noOfInfants: dataaaa.totalInfants,
            noOfPets: dataaaa.totalPets,
            validate: false,
          });
          setValues(resulttt.data.data);
        } catch (error) {}
      }
      // setIsLoading(false);
    } catch (err) {
      // setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    getBookingData(booking?.id);
  }, [booking]);

  const handleWhoIsComing = async () => {
    let payload = {
      bookingId: bookingDetail?.id,
      userId: bookingDetail?.guestId,
      whoIsComing:
        bookingDetail?.whoIsComing == null
          ? whoIsComing
          : bookingDetail?.whoIsComing,
    };
    setIsLoading(true);
    try {
      let res = await whoIsComingApi(payload);
      if (res.status === 200) {
        setIsLoading(false);
        handleClose();
        enqueueSnackbar(res?.data?.message, {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      } else {
        setIsLoading(false);
        enqueueSnackbar(res?.data?.message, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      }
    } catch (error) {}
  };

  let staywoFee = (commissionFee * bookingDetail?.grossAmount) / 100;

  const THREE_DAYS_IN_MS = 3 * 24 * 60 * 60 * 1000;
  const NOW_IN_MS = new Date(booking?.createdOn).getTime();
  const dateTimeAfterThreeDays = NOW_IN_MS + THREE_DAYS_IN_MS;
  const [days, hours, minutes, seconds] = useCountdown(dateTimeAfterThreeDays);

  const RenderActions = () => {
    if (days + hours + minutes + seconds <= 0) {
      return (
        <DialogActions
          sx={{
            fontFamily: 'Jost',
            fontSize: '25px',
            background: '#17212f',
          }}
        >
          <Button
            sx={{
              color: 'rgba(255, 255, 255, 0.7)',
            }}
            autoFocus
            onClick={handleClose}
          >
            Close
          </Button>
        </DialogActions>
      );
    } else
      return (
        <DialogActions
          sx={{
            fontFamily: 'Jost',
            fontSize: '25px',
            background: '#17212f',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button
            sx={{
              color: 'rgba(255, 255, 255, 0.7)',
            }}
            autoFocus
            onClick={handleClose}
          >
            Close
          </Button>
          <div>
            {isLoadingDecline ? (
              <ProgressSpinner
                style={{ width: '28px', height: '28px' }}
                strokeWidth='8'
                fill='var(--surface-ground)'
                animationDuration='.5s'
              />
            ) : (
              <Button
                sx={{
                  color: 'rgba(255, 255, 255, 0.7)',
                }}
                autoFocus
                onClick={() => accept(booking.id, 'rejected')}
              >
                Decline
              </Button>
            )}
            {isLoadingAccept ? (
              <ProgressSpinner
                style={{ width: '28px', height: '28px' }}
                strokeWidth='8'
                fill='var(--surface-ground)'
                animationDuration='.5s'
              />
            ) : (
              <Button
                sx={{
                  color: 'rgba(255, 255, 255, 0.7)',
                }}
                onClick={() => accept(booking.id, 'accepted')}
              >
                Accept
              </Button>
            )}
          </div>
        </DialogActions>
      );
  };

  const formik = useFormik({
    initialValues: {
      propertyId: bookingDetail?.property?.id,
      privateNote: '',
      startDate: bookingDetail?.startDate,
      endDate: bookingDetail?.endDate,
    },
    validationSchema: yup.object({
      privateNote: yup.string().required('Private Note Is Required.'),
    }),
    enableReinitialize: true,
    onSubmit: async (values) => {
      const res = await editPropertyCalenderDateByRange(values);
      if (res.status === 200 || res.status === 201) {
        enqueueSnackbar('Calendar note added!', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      }
    },
  });

  const pathName = window.location.pathname;

  let endDate = moment(booking?.endDate);
  let today = moment();

  let differenceOfTodayWithEndDate = today.diff(endDate, 'days');

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
        sx={{
          '& .MuiPaper-root': {
            overflowX: 'hidden',
          },
        }}
      >
        <DialogTitle
          id='scroll-dialog-title'
          sx={{
            fontFamily: 'Jost',
            fontSize: '25px',
            borderBottom: '0.5px solid #0095ff',
            background: '#17212f',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography sx={{ fontFamily: 'Jost', fontSize: '25px' }}>
              {header}
            </Typography>
            {/* <Button
              variant="outlined"
              sx={{ fontFamily: 'Jost', fontSize: '14px' }}
            >
              Print
            </Button> */}
          </Box>
        </DialogTitle>
        <DialogContent
          sx={{ height: '90vh', width: '32vw', background: '#1c172b' }}
        >
          <DialogContentText
            id='scroll-dialog-description'
            ref={descriptionElementRef}
            tabIndex={-1}
            sx={{ width: '100%' }}
          >
            <Box component='div' sx={{ marginTop: '10px' }}>
              <Typography
                sx={{ fontFamily: 'Jost', fontSize: '22px', color: 'white' }}
              >
                Property Details
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '10px 0px',
                  borderBottom: '0.5px solid #0095ff',
                }}
              >
                <Box>
                  <img
                    src={booking?.property?.thumbnailUrl}
                    alt='Property'
                    width={130}
                    height={130}
                  />
                </Box>
                <Box sx={{ marginLeft: '10px' }}>
                  <Typography
                    sx={{ color: '#fff', fontFamily: 'Jost', fontSize: '22px' }}
                  >
                    {booking?.property?.title &&
                    booking?.property?.title?.length > 30
                      ? booking?.property?.title?.slice(0, 30) + '...'
                      : booking?.property?.title?.slice(0, 30)}
                  </Typography>
                  <Typography sx={{ fontFamily: 'Jost', fontSize: '14px' }}>
                    {booking?.property?.city}, {booking?.property?.country}
                  </Typography>
                  <Typography sx={{ fontFamily: 'Jost', fontSize: '14px' }}>
                    Property ID: {booking?.property?.code}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: 'Jost',
                      fontSize: '16px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Rating
                      name='half-rating-read'
                      value={
                        booking?.property?.average_rating
                          ? booking?.property?.average_rating?.averageRating
                          : 0
                      }
                      precision={0.5}
                      readOnly
                      sx={{ marginRight: '4px' }}
                    />
                    (
                    {booking?.property?.average_rating
                      ? booking?.property?.average_rating?.averageRating?.toFixed(
                          1
                        )
                      : 0}
                    ) (
                    {booking?.property?.average_rating
                      ? `${booking?.property?.average_rating?.reviewCount} Reviews`
                      : 0}
                    )
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                padding: '25px 0px',
                borderBottom: '0.5px solid #0095ff',
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography
                  sx={{ fontFamily: 'Jost', fontSize: '22px', color: 'white' }}
                >
                  About{' '}
                  {type === 'host' ? (
                    <a
                      href={`/about-host/${booking?.guest?.id}`}
                      style={{ color: '#fff' }}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {booking?.guest?.firstName}
                    </a>
                  ) : (
                    <a
                      href={`/about-host/${booking?.owner?.id}`}
                      style={{ color: '#fff' }}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {' '}
                      {booking?.owner?.firstName}{' '}
                    </a>
                  )}
                </Typography>
                <img
                  src={
                    type === 'host'
                      ? booking?.guest?.profilePicture !== null
                        ? booking?.guest?.profilePicture
                        : require('../../../assets/images/avatar/5.jpg')
                      : booking?.owner?.profilePicture !== null
                      ? booking?.owner?.profilePicture
                      : require('../../../assets/images/avatar/5.jpg')
                  }
                  style={{
                    borderRadius: '50%',
                    width: '65px',
                    height: '65px',
                  }}
                  alt=''
                />
              </div>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                  {type === 'host' ? (
                    <>
                      <Rating
                        name='half-rating-read'
                        value={
                          booking?.guest?.guestAverageRating
                            ? booking?.guest?.guestAverageRating?.averageRating
                            : 0
                        }
                        precision={0.5}
                        readOnly
                      />
                      <span
                        style={{
                          textDecoration: 'underline',
                          fontSize: '14px',
                          color: 'rgba(255, 255, 255, 0.7)',
                          marginLeft: '10px',
                        }}
                      >
                        (
                        {booking?.guest?.guestAverageRating
                          ? booking?.guest?.guestAverageRating?.reviewCount
                          : 0}{' '}
                        - Reviews)
                      </span>
                    </>
                  ) : (
                    <>
                      <Rating
                        name='half-rating-read'
                        value={
                          booking?.owner?.average_rating
                            ? booking?.owner?.average_rating?.averageRating
                            : 0
                        }
                        precision={0.5}
                        readOnly
                      />
                      <span
                        style={{
                          textDecoration: 'underline',
                          fontSize: '14px',
                          color: 'rgba(255, 255, 255, 0.7)',
                          marginLeft: '10px',
                        }}
                      >
                        (
                        {booking?.owner?.average_rating
                          ? booking?.owner?.average_rating?.reviewCount
                          : 0}{' '}
                        - Reviews)
                      </span>
                    </>
                  )}
                </Typography>
                {booking?.owner.verified && (
                  <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                    <MdVerifiedUser
                      style={{
                        fontSize: '14px',
                        color: 'white',
                        marginRight: '4px',
                      }}
                    />
                    <Link
                      to='/'
                      style={{
                        textDecoration: 'underline',
                        fontSize: '14px',
                        color: 'rgba(255, 255, 255, 0.7)',
                      }}
                    >
                      Indenitiy Verified
                    </Link>
                  </Typography>
                )}

                <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                  <BiLogInCircle
                    style={{
                      fontSize: '14px',
                      color: 'white',

                      marginRight: '4px',
                    }}
                  />
                  <Link
                    to='/'
                    style={{
                      fontSize: '14px',
                      color: 'rgba(255, 255, 255, 0.7)',
                    }}
                  >
                    Joined on{' '}
                    {type === 'host'
                      ? moment(booking?.guest?.createdOn).format('DD MMM YYYY')
                      : moment(booking?.owner?.createdOn).format('DD MMM YYYY')}
                  </Link>
                </Typography>
                {!(type == 'host') && (
                  <>
                    {bookingDetail?.owner?.country != '' ? (
                      <Typography
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        <AiFillHome
                          style={{
                            fontSize: '14px',
                            color: 'white',

                            marginRight: '4px',
                          }}
                        />
                        {/* <Link
                      to='/'
                      style={{
                        fontSize: '14px',
                        color: 'rgba(255, 255, 255, 0.7)',
                      }}
                    > */}
                        Lives in {bookingDetail?.owner?.country}
                        {/* </Link> */}
                      </Typography>
                    ) : (
                      ''
                    )}
                  </>
                )}
                {type == 'host' && (
                  <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                    <FcBusinessman
                      style={{
                        fontSize: '16px',
                        color: 'white',
                        marginRight: '4px',
                      }}
                    />
                    <Link
                      to={`/about-host/${booking?.guest?.id}`}
                      style={{
                        fontSize: '14px',
                        color: 'rgba(255, 255, 255, 0.7)',
                      }}
                      target='_blank'
                      rel='noreferrer'
                    >
                      Also a host
                    </Link>
                  </Typography>
                )}
              </Box>
              {!pathName.includes('admin/messages') && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Button
                    variant='outlined'
                    sx={{ width: '48%', marginTop: 2 }}
                    onClick={() => {
                      window.location.href = `/admin/messages/${bookingDetail?.guest?.id}`;
                    }}
                  >
                    Message
                  </Button>
                  {(bookingDetail.bookingStatus === 'confirmed' || bookingDetail.bookingStatus === 'ongoing'|| bookingDetail.bookingStatus === 'completed') && (
                    <a
                      href={`tel:${bookingDetail?.owner?.phoneNumber}`}
                      style={{ display: 'contents' }}
                    >
                      <Button
                        variant='outlined'
                        sx={{ width: '48%', marginTop: 2 }}
                        onClick={() => {}}
                        // disabled={
                        //   detailType === 'PAST' &&
                        //   differenceOfTodayWithEndDate > 15
                        // }
                      >
                        Call
                      </Button>
                    </a>
                  )}
                </Box>
              )}

              {!(type == 'host') && (
                <Link
                  to={`/about-host/${bookingDetail?.owner?.id}`}
                  style={{
                    color: 'white',
                    textDecoration: 'underline',
                    fontSize: '14px',
                  }}
                  target='_blank'
                  rel='noreferrer'
                >
                  Show profile
                </Link>
              )}
            </Box>

            <Box
              sx={{
                padding: '25px 0px',
                borderBottom: '0.5px solid #0095ff',
              }}
            >
              <Typography
                sx={{ fontFamily: 'Jost', fontSize: '22px', color: 'white' }}
              >
                Booking Details
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontSize: '16px', color: 'white' }}>
                  Booking nights
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {moment(bookingDetail?.startDate).format('DD MMM YYYY')} -{' '}
                  {moment(bookingDetail?.endDate).format('DD MMM YYYY')}{' '}
                  <NightlightRoundIcon
                    sx={{ fontSize: '15px', marginLeft: '5px' }}
                  />{' '}
                  ({bookingDetail?.numberOfNights} night
                  {bookingDetail?.numberOfNights > 1 ? 's' : ''})
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '14px 0px',
                  }}
                >
                  <Typography>
                    <Typography sx={{ fontSize: '16px', color: 'white' }}>
                      Guests
                    </Typography>
                    <Typography sx={{ fontSize: '14px' }}>
                      <p>
                        <i className='fas fa-user'></i>{' '}
                        {bookingDetail?.totalGuests}
                      </p>
                      <p>
                        {bookingDetail?.totalAdults > 0
                          ? bookingDetail?.totalAdults + ' Adults'
                          : ''}
                        {bookingDetail?.totalAdults > 0 &&
                        bookingDetail?.totalChildren > 0
                          ? ' - '
                          : ''}
                        {bookingDetail?.totalChildren > 0
                          ? bookingDetail?.totalChildren + ' Children'
                          : ''}
                      </p>
                      {bookingDetail?.totalInfants > 0 ||
                      bookingDetail?.totalPets > 0 ? (
                        <p>
                          {bookingDetail?.totalInfants > 0
                            ? bookingDetail?.totalInfants + ' Infants'
                            : ''}
                          {bookingDetail?.totalInfants > 0 &&
                          bookingDetail?.totalPets > 0
                            ? ' - '
                            : ''}
                          {bookingDetail?.totalPets > 0
                            ? bookingDetail?.totalPets + ' Pets'
                            : ''}
                        </p>
                      ) : (
                        ''
                      )}
                    </Typography>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    paddingBottom: '14px',
                  }}
                >
                  <Typography>
                    <Typography sx={{ fontSize: '16px', color: 'white' }}>
                      Check In Time
                    </Typography>
                    <Typography sx={{ fontSize: '14px' }}>
                      {bookingDetail?.property?.propertyPolicy?.checkInWindow}
                    </Typography>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    paddingBottom: '14px',
                  }}
                >
                  <Typography>
                    <Typography sx={{ fontSize: '16px', color: 'white' }}>
                      Check Out
                    </Typography>
                    <Typography sx={{ fontSize: '14px' }}>
                      {bookingDetail?.property?.propertyPolicy?.checkoutWindow}
                    </Typography>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    paddingBottom: '14px',
                  }}
                >
                  <Typography>
                    <Typography sx={{ fontSize: '16px', color: 'white' }}>
                      Booking Date
                    </Typography>
                    <Typography sx={{ fontSize: '14px' }}>
                      {moment(bookingDetail?.createdOn).format('DD MMM YYYY')}
                    </Typography>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    paddingBottom: '14px',
                  }}
                >
                  <Typography>
                    <Typography sx={{ fontSize: '16px', color: 'white' }}>
                      Confirmation Code
                    </Typography>
                    <Typography sx={{ fontSize: '14px' }}>
                      {booking?.code}
                    </Typography>
                  </Typography>
                </Box>
              </Box>

              {type == 'host' && (
                <Link
                  to={`/admin/calendar/${bookingDetail?.property?.id}`}
                  style={{
                    color: 'white',
                    textDecoration: 'underline',
                    fontSize: '14px',
                  }}
                >
                  Show calendar
                </Link>
              )}
            </Box>

            {showInstruction && (
              <Box
                sx={{
                  padding: '25px 0px',
                  borderBottom: '0.5px solid #0095ff',
                }}
              >
                <Typography
                  sx={{ fontFamily: 'Jost', fontSize: '22px', color: 'white' }}
                >
                  Check-in Instructions
                </Typography>
                <Typography sx={{ fontSize: '14px', color: 'white' }}>
                  {bookingDetail?.property?.checkInInstruction
                    ? bookingDetail?.property?.checkInInstruction
                    : 'No instructions'}
                </Typography>
              </Box>
            )}
            {showInstruction && (
              <Box
                sx={{
                  padding: '25px 0px',
                  borderBottom: '0.5px solid #0095ff',
                }}
              >
                <Typography
                  sx={{ fontFamily: 'Jost', fontSize: '22px', color: 'white' }}
                >
                  Wifi Details
                </Typography>
                <Typography sx={{ fontSize: '14px', color: 'white' }}>
                  Wifi Username:{' '}
                  {bookingDetail?.property?.wifiUsername
                    ? bookingDetail?.property?.wifiUsername
                    : 'Not provided'}
                </Typography>
                <Typography sx={{ fontSize: '14px', color: 'white' }}>
                  Wifi Password:{' '}
                  {bookingDetail?.property?.wifiPassword
                    ? bookingDetail?.property?.wifiPassword
                    : 'Not provided'}
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                padding: '25px 0px',
                borderBottom: '0.5px solid #0095ff',
              }}
            >
              <Typography
                sx={{ fontFamily: 'Jost', fontSize: '22px', color: 'white' }}
              >
                Who is coming
              </Typography>
              {type === 'traveler' ? (
                <>
                  <InputTextarea
                    rows={5}
                    cols={30}
                    value={whoIsComing}
                    onChange={(e) => setWhoIsComing(e.target.value)}
                    // disabled={
                    //   statusType === "pending"
                    //     ? days + hours + minutes + seconds <= 0 &&
                    //       bookingDetail.bookingStatus == "pending"
                    //     : days + hours + minutes + seconds <= 0
                    // }
                  />
                  <Buttons
                    title={'Save'}
                    onSubmit={handleWhoIsComing}
                    isLoading={isLoading}
                  />
                </>
              ) : (
                <Typography sx={{ fontSize: '14px', color: 'white' }}>
                  {bookingDetail?.whoIsComing
                    ? bookingDetail?.whoIsComing
                    : 'No Description Provided'}
                </Typography>
              )}
            </Box>

            <Box
              sx={{
                padding: '25px 0px',
                borderBottom: '0.5px solid #0095ff',
              }}
            >
              <Typography
                sx={{ fontFamily: 'Jost', fontSize: '22px', color: 'white' }}
              >
                {type === 'host' ? ' Guest' : 'You'} Paid
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography
                    sx={{
                      color: '#fff',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {' '}
                    <CleaningServicesIcon
                      sx={{ fontSize: '18px', marginRight: '5px' }}
                    />{' '}
                    Accomodation
                  </Typography>
                  <Typography>
                    {process.env.REACT_APP_CURRENCY}{' '}
                    {bookingDetail?.totalPrice?.toLocaleString('en-US')}
                  </Typography>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography
                    sx={{
                      color: '#fff',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <NightlightRoundIcon
                      sx={{ fontSize: '18px', marginRight: '5px' }}
                    />{' '}
                    Nightly Price x {values?.noOfNights} (nights)
                  </Typography>
                  <Typography>
                    {process.env.REACT_APP_CURRENCY}{' '}
                    {values?.totalNightsCharges?.toLocaleString('en-US')}
                  </Typography>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography
                    sx={{
                      color: '#fff',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {' '}
                    <CleaningServicesIcon
                      sx={{ fontSize: '18px', marginRight: '5px' }}
                    />{' '}
                    Cleaning Fee
                  </Typography>
                  <Typography>
                    {process.env.REACT_APP_CURRENCY}{' '}
                    {values?.cleaningFee?.toLocaleString('en-US')}{' '}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                  }}
                >
                  <Typography
                    sx={{
                      color: '#fff',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <PersonAddIcon
                      sx={{ fontSize: '18px', marginRight: '5px' }}
                    />{' '}
                    Additional Guests Fee
                  </Typography>
                  <Typography>
                    <span style={{ color: '#fff' }}>
                      {values?.totalExtraGuests}
                    </span>{' '}
                    x{' '}
                    <span style={{ color: '#fff' }}>
                      {process.env.REACT_APP_CURRENCY}{' '}
                      {values?.extraGuestFee?.toLocaleString('en-US')}
                    </span>{' '}
                    ={' '}
                    <span style={{ color: '#fff' }}>
                      {' '}
                      {process.env.REACT_APP_CURRENCY}{' '}
                      {values?.totalExtraGuestFee?.toLocaleString('en-US')}
                    </span>
                  </Typography>
                </Box>

                {values?.noOfInfants > 0 ? (
                  <Box
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Typography
                      sx={{
                        color: '#fff',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <ChildCareIcon
                        sx={{ fontSize: '18px', marginRight: '5px' }}
                      />{' '}
                      Infant Fee
                    </Typography>
                    <Typography>No Fee</Typography>
                  </Box>
                ) : (
                  <Box
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Typography
                      sx={{
                        color: '#fff',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <ChildCareIcon
                        sx={{ fontSize: '18px', marginRight: '5px' }}
                      />{' '}
                      Infant Fee
                    </Typography>
                    <Typography>Not Allowed</Typography>
                  </Box>
                )}

                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography
                    sx={{
                      color: '#fff',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <PetsIcon sx={{ fontSize: '18px', marginRight: '5px' }} />{' '}
                    Pet Fee
                  </Typography>
                  {values?.noOfPets > 0 ? (
                    <Typography>
                      {values?.noOfPets} x {process.env.REACT_APP_CURRENCY}{' '}
                      {values?.petFee?.toLocaleString('en-US')} ={' '}
                      {values?.totalPetFee?.toLocaleString('en-US')}
                    </Typography>
                  ) : (
                    <Typography>Not Allowed</Typography>
                  )}
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography
                    sx={{
                      color: '#fff',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <AccountBalanceIcon
                      sx={{ fontSize: '18px', marginRight: '5px' }}
                    />{' '}
                    Service Fee ({commissionFee} %)
                  </Typography>
                  <Typography>
                    {process.env.REACT_APP_CURRENCY}{' '}
                    {values?.totalServiceCharges?.toLocaleString('en-US')}
                  </Typography>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography
                    sx={{
                      color: '#fff',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <AccountBalanceIcon
                      sx={{ fontSize: '18px', marginRight: '5px' }}
                    />{' '}
                    GST ({values?.gst} %)
                  </Typography>
                  <Typography>
                    {process.env.REACT_APP_CURRENCY}{' '}
                    {values?.totalGSTFee?.toLocaleString('en-US')}
                  </Typography>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography
                    sx={{
                      color: '#fff',
                      fontSize: '19px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <DoneOutlineIcon
                      sx={{ fontSize: '19px', marginRight: '5px' }}
                    />{' '}
                    Total ( {process.env.REACT_APP_CURRENCY} )
                  </Typography>
                  <Typography sx={{ fontSize: '18px', color: '#fff' }}>
                    {process.env.REACT_APP_CURRENCY}{' '}
                    {bookingDetail?.grossAmount
                      ? bookingDetail?.grossAmount?.toLocaleString('en-US')
                      : 0}
                  </Typography>
                </Box>
              </Box>
            </Box>

            {type === 'host' && (
              <Box
                sx={{
                  padding: '25px 0px',
                  borderBottom: '0.5px solid #0095ff',
                }}
              >
                <Typography
                  sx={{ fontFamily: 'Jost', fontSize: '22px', color: 'white' }}
                >
                  Host payout
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography sx={{ fontSize: '14px', color: 'white' }}>
                      Total Stay Price
                    </Typography>

                    <Typography style={{ fontSize: '14px' }}>
                      {process.env.REACT_APP_CURRENCY}{' '}
                      {bookingDetail?.grossAmount?.toLocaleString('en-US')}
                    </Typography>
                  </Box>
                  {/* <Link
                    to="/"
                    style={{
                      color: "white",
                      textDecoration: "underline",
                      fontSize: "14px",
                    }}
                  >
                    Show breakdowns
                  </Link> */}
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography sx={{ fontSize: '14px', color: 'white' }}>
                      Staywo Fee
                    </Typography>
                    <Typography style={{ fontSize: '14px' }}>
                      {process.env.REACT_APP_CURRENCY}{' '}
                      {staywoFee.toLocaleString('en-US')}
                      {/* {bookingDetail?.totalServiceFee?.toLocaleString("en-US")} */}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography>
                      <Typography
                        sx={{
                          color: '#fff',
                          fontSize: '19px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <DoneOutlineIcon
                          sx={{ fontSize: '19px', marginRight: '5px' }}
                        />{' '}
                        Total ( {process.env.REACT_APP_CURRENCY} )
                      </Typography>
                    </Typography>
                    <Typography sx={{ fontSize: '18px', color: '#fff' }}>
                      {process.env.REACT_APP_CURRENCY}{' '}
                      {(bookingDetail?.grossAmount - staywoFee).toLocaleString(
                        'en-US'
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}

            {type == 'host' && !(detailType === 'REQUESTS') && (
              <Box
                sx={{
                  padding: '25px 0px',
                  borderBottom: '0.5px solid #0095ff',
                }}
              >
                <Typography
                  sx={{ fontFamily: 'Jost', fontSize: '22px', color: 'white' }}
                >
                  Calendar Note
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <BsLockFill
                      style={{ fontSize: '14px', marginRight: '6px' }}
                    />
                    <div
                      style={{
                        fontSize: '14px',
                        color: 'rgba(255, 255, 255, 0.7)',
                      }}
                    >
                      Add a private member for these dates that only can view
                    </div>
                  </Box>
                </Typography>
                <textarea
                  aria-label='maximum height'
                  style={{
                    width: '100%',
                    background: 'transparent',
                    fontSize: '14px',
                    borderRadius: '4px',
                    padding: '2px',
                    color: 'white',
                  }}
                  value={formik.values.privateNote}
                  name='privateNote'
                  onChange={formik.handleChange}
                  rows={5}
                />
                {formik.touched.privateNote &&
                  Boolean(formik.errors.privateNote) && (
                    <div>{formik.errors.privateNote}</div>
                  )}
                <Button
                  variant='outlined'
                  sx={{
                    fontFamily: 'Jost',
                    fontSize: '14px',
                    marginTop: '6px',
                  }}
                  onClick={formik.handleSubmit}
                >
                  Save
                </Button>
              </Box>
            )}
            <Box
              sx={{
                padding: '25px 0px',
                borderBottom: '0.5px solid #0095ff',
                borderTop: '0.5px solid #0095ff',
              }}
            >
              {type === 'traveler' ? (
                <>
                  <a
                    href={`https://api-staging.staywo.com/booking/invoice/${booking.id}`}
                    target='_blank'
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <BsCash
                        style={{
                          marginRight: '16px',
                          fontSize: '16px',
                          color: '#fff',
                        }}
                      />

                      <div style={{ fontSize: '14px', color: 'white' }}>
                        Get Booking Invoice
                      </div>
                    </Box>
                    <AiOutlineRight
                      style={{ fontSize: '14px', color: '#fff' }}
                    />
                  </a>
                </>
              ) : (
                !(detailType === 'REQUESTS') && (
                  <Link
                    to={`/additional-payments/${booking?.id}`}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <BsCash
                        style={{
                          marginRight: '16px',
                          fontSize: '16px',
                          color: '#fff',
                        }}
                      />
                      <div style={{ fontSize: '14px', color: 'white' }}>
                        Send or request money
                      </div>
                    </Box>
                    <AiOutlineRight
                      style={{ fontSize: '14px', color: '#fff' }}
                    />
                  </Link>
                )
              )}
            </Box>

            {type === 'traveler' && (
              <Box
                sx={{
                  padding: '25px 0px',
                }}
              >
                <Typography
                  sx={{ fontFamily: 'Jost', fontSize: '22px', color: 'white' }}
                >
                  Support
                </Typography>

                {/* cancel reservation button  */}
                {!(detailType === 'PAST') && (
                  <Link
                    state={{ booking: booking }}
                    to={`/admin/cancel-reservation/${booking.id}`}
                  >
                    <Buttons
                      isLoading={false}
                      variant={'contained'}
                      title={'Cancel Reservation'}
                      // onSubmit={} On Click/Submit Function
                    ></Buttons>
                  </Link>
                )}
              </Box>
            )}
            {!(type == 'host') && detailType === 'PAST' && (
              <Box
                sx={{
                  padding: '25px 0px',
                  borderBottom: '0.5px solid #0095ff',
                  borderTop: '0.5px solid #0095ff',
                }}
              >
                <Link
                  state={{ booking: booking }}
                  to={`/rate-host/${booking?.owner?.id}/${booking?.property?.id}/${booking?.id}?host=${booking?.owner?.firstName}`}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <AiFillStar
                      style={{
                        color: '#ff2400',
                        fontSize: '14px',
                        marginRight: '4px',
                      }}
                    />
                    <div style={{ fontSize: '14px', color: 'white' }}>
                      Rate the host
                    </div>
                  </Box>
                  <AiOutlineRight style={{ fontSize: '14px', color: '#fff' }} />
                </Link>
              </Box>
            )}
            {type == 'host' && detailType === 'PAST' && (
              <Box
                sx={{
                  padding: '25px 0px',
                  borderBottom: '0.5px solid #0095ff',
                  borderTop: '0.5px solid #0095ff',
                }}
              >
                <Link
                  state={{ booking: booking }}
                  to={`/admin/rate-guest/${booking?.guest?.id}/${booking?.property?.id}/${booking?.id}?guest=${booking?.guest?.firstName}`}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <AiFillStar
                      style={{
                        color: '#ff2400',
                        fontSize: '14px',
                        marginRight: '4px',
                      }}
                    />
                    <div style={{ fontSize: '14px', color: 'white' }}>
                      Rate the guest
                    </div>
                  </Box>
                  <AiOutlineRight style={{ fontSize: '14px', color: '#fff' }} />
                </Link>
              </Box>
            )}

            {type === 'traveler' && (
              <>
                {' '}
                {detailType === 'PAST' && (
                  <Link
                    state={{ booking: booking }}
                    to={`/property-detail/${booking?.property?.id}`}
                  >
                    <Buttons
                      isLoading={false}
                      variant={'contained'}
                      title={'Book Again'}
                      // onSubmit={} On Click/Submit Function
                    ></Buttons>
                  </Link>
                )}
              </>
            )}
          </DialogContentText>
        </DialogContent>
        {type === 'host' &&
        !(
          detailType === 'PAST' ||
          detailType === 'UPCOMING' ||
          detailType === 'CURRENT' ||
          detailType === 'CONFIRMED'
        ) ? (
          <RenderActions />
        ) : (
          <DialogActions
            sx={{
              fontFamily: 'Jost',
              fontSize: '25px',
              background: '#17212f',
            }}
          >
            <Button
              sx={{
                color: 'rgba(255, 255, 255, 0.7)',
              }}
              autoFocus
              onClick={handleClose}
            >
              Close
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}
export default ReservationDetail;
