import { API_URL } from '../../config';
import axios from 'axios';

//-completed
export const editPropertyPolicies = async (id, data) => {
  try {
    const response = await axios.put(
      API_URL + `property-policy/update/policy/${id}`,
      data
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of edit property policies : ', response);
      return response;
    }
  }
};

export const updateCancellationPolicies = async (data) => {
  try {
    const response = await axios.post(
      API_URL + `cancellation-policy/property-cancellation-policy`,
      data
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of edit property policies : ', response);
      return response;
    }
  }
};

//-completed
export const editPropertyGuestInfo = async (id, data) => {
  try {
    const response = await axios.put(
      API_URL + `property/update/info-for-guest/${id}`,
      data
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of edit guest info : ', response);
      return response;
    }
  }
};
//-completed
export const editPropertyInstantBooking = async (id, data) => {
  try {
    const response = await axios.put(
      API_URL + `property/instantBooking/${id}/${data.instantBooking}`,
      data
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of edit property : ', response);
      return response;
    }
  }
};

//-completed
export const editPropertyBasicInfo = async (id, data) => {
  try {
    const response = await axios.put(
      API_URL + `property/update/listing-basic/${id}`,
      data
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of edit basic info : ', response);
      return response;
    }
  }
};

//-completed
export const editPropertyPricing = async (id, data) => {
  try {
    const response = await axios.put(
      API_URL + `property_price_policy/updateByPropertyId/${id}`,
      data
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of edit pricing : ', response);
      return response;
    }
  }
};

export const editPropertyCapacityBasicInfo = async (id, PropertyCapacity) => {
  try {
    const response = await axios.put(
      API_URL + `property-capacity/update/listing-basic/${id}`,
      PropertyCapacity
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of edit basic info : ', response);
      return response;
    }
  }
};

export const editPhotoAndUrl = async (id, uploadData) => {
  try {
    const response = await axios.put(
      API_URL + `property/update/photosAndVideoUrl/${id}`,
      uploadData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of property : ', response);
      return response;
    }
  }
};

export const editAmenity = async (data) => {
  try {
    const response = await axios.post(
      API_URL + `property-amenities/create`,
      data
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of edit amenity : ', response);
      return response;
    }
  }
};
export const editAccessibilities = async (data) => {
  try {
    const response = await axios.post(
      API_URL + `property-accessibility/create`,
      data
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of edit accessibility : ', response);
      return response;
    }
  }
};
export const editFacilities = async (data) => {
  try {
    const response = await axios.post(
      API_URL + `property-facility/create`,
      data
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of edit accessibility : ', response);
      return response;
    }
  }
};
export const editSecurityFeatures = async (data) => {
  try {
    const response = await axios.post(
      API_URL + `property-security-feature/create`,
      data
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of edit accessibility : ', response);
      return response;
    }
  }
};
export const editNearbyActivity = async (data) => {
  try {
    const response = await axios.post(
      API_URL + `property-nearby-activity/create`,
      data
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of edit accessibility : ', response);
      return response;
    }
  }
};
export const editSafetyFeature = async (data) => {
  try {
    const response = await axios.post(
      API_URL + `property-safety-feature/create`,
      data
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of edit accessibility : ', response);
      return response;
    }
  }
};

export const editSafetyConsideration = async (data, id) => {
  try {
    const response = await axios.post(
      API_URL + `property-safety-consideration/create`,
      data
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of edit safety consideration : ', response);
      return response;
    }
  }
};

export const editHouseRule = async (data) => {
  try {
    const response = await axios.post(
      API_URL + `property-house-rule/create`,
      data
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of edit houserule : ', response);
      return response;
    }
  }
};
export const deleteGalleryImageApi = async (id) => {
  try {
    const response = await axios.delete(
      API_URL + `property/delete/image/${id}`
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of delete image from gallery! : ', response);
      return response;
    }
  }
};
