import React from 'react';

const VUpload = ({
  handleChange,
  label,
  disabled,
  multiple,
  icon,
  displayImg,
  resetAction,
}) => {
  return (
    <>
      <div
        className="image-uploder-container"
        style={{ height: '250px', width: '350px', alignItems: 'center' }}
      >
        {displayImg ? (
          <>
            <img
              src={displayImg}
              alt="img"
              style={{ width: '100%', height: '100%' }}
            />
          </>
        ) : (
          <label className="image-uploader-label">
            <label className="image-uploader-sub-label">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                {icon ? (
                  icon
                ) : (
                  <i
                    className={'fa fa-camera'}
                    style={{ marginRight: '10px' }}
                  ></i>
                )}
                <div style={{ fontSize: '20px' }}>{label}</div>
              </div>
              <input
                type="file"
                accept="image/*"
                name="fileUpload"
                placeholder="Upload"
                multiple={multiple ? 'multiple' : false}
                onChange={handleChange}
                value=""
                style={{ display: 'none' }}
                disabled={disabled}
              />
            </label>
          </label>
        )}
      </div>
      {displayImg && (
        <div>
          <button
            style={{
              background: 'none',
              color: 'rgba(255, 255, 255, 0.7)',
              border: 'none',
              outline: 'none',
              cursor: 'pointer',
              fontSize: '16px',
              margin: '12px 0px',
              fontFamily: 'Jost',
            }}
            onClick={resetAction}
          >
            REUPLOAD
          </button>
        </div>
      )}
    </>
  );
};

export default VUpload;
