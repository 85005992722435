import { API_URL } from "../../config";
import axios from "axios";

export const updatePropertyStatus = async (propertyId, userId, status) => {
  const config = {
    method: "put",
    url: `${API_URL}property/property-status/${propertyId}/${userId}/${status}`,
    headers: {
      accept: "*/*",
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await axios(config);
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log("Bad Response of update booking : ", response);
      return response;
    }
  }
};
