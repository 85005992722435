import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/system";
import {
  Checkbox,
  DialogActions,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import PaymentIcon from "@mui/icons-material/Payment";
import PaymentsIcon from "@mui/icons-material/Payments";
import { useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Dropdown } from "primereact/dropdown";
import { options } from "../../../constants/countries";
import { FaCcMastercard, FaCcVisa } from "react-icons/fa";
import { Toast } from "primereact/toast";
import Select from "react-select";

function PaymentModalForExtraPayments({
  open,
  handleClose,
  descriptionElementRef,
  header,
  toast,
  wallet,
  useWallet,
  setUseWalletFunc,
  setUseCreditCardFunc,
  walletLessAmountError,
  userCards,
  card,
  setCard,
  useCreditCard,
  formik,
}) {
  const navigate = useNavigate();

  //Country options dropdown templates
  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className='country-item country-item-value'>
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className='country-item'>
        <div>{option.name}</div>
      </div>
    );
  };

  const RenderActions = () => {
    return (
      <DialogActions
        sx={{
          fontFamily: "Jost",
          fontSize: "25px",
          background: "#17212f",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          sx={{
            color: "rgba(255, 255, 255, 0.7)",
          }}
          autoFocus
          onClick={handleClose}
        >
          Close
        </Button>
        <div>
          <Button
            sx={{
              color: "rgba(255, 255, 255, 0.7)",
            }}
            onClick={formik.handleSubmit}
          >
            Accept
          </Button>
        </div>
      </DialogActions>
    );
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: "black",
      fontSize: "16px",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      const color = "#fff";
      return { ...provided, opacity, transition, color };
    },
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
        sx={{
          "& .MuiPaper-root": {
            overflowX: "hidden",
          },
        }}
      >
        <DialogTitle
          id='scroll-dialog-title'
          sx={{
            fontFamily: "Jost",
            fontSize: "25px",
            borderBottom: "0.5px solid #0095ff",
            background: "#17212f",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontFamily: "Jost", fontSize: "25px" }}>
              {header}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent
          sx={{ height: "90vh", width: "32vw", background: "#1c172b" }}
        >
          <DialogContentText
            id='scroll-dialog-description'
            ref={descriptionElementRef}
            tabIndex={-1}
            sx={{ width: "100%" }}
          >
            <Box
              sx={{
                padding: "25px 0px",
                borderBottom: "0.5px solid #0095ff",
              }}
            >
              <Typography
                sx={{ fontFamily: "Jost", fontSize: "22px", color: "white" }}
              >
                Enter Payment Details
              </Typography>
              <>
                <form>
                  <Toast ref={toast} position='bottom-right' />
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: "22px",
                      fontFamily: "Jost",
                      display: "flex",
                      alignItems: "center",
                      paddingTop: "15px",
                      paddingBottom: "15px",
                    }}
                  >
                    <PaymentIcon
                      sx={{ fontSize: "22px", marginRight: "5px" }}
                    />{" "}
                    Pay With
                  </Typography>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: "22px",
                      fontFamily: "Jost",
                      display: "flex",
                      alignItems: "center",
                      // margin: '12px 0px',
                      paddingTop: "12px",
                      borderTop: "1px solid rgba(255, 255, 255, 0.1)",
                    }}
                  >
                    Your Wallet
                  </Typography>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "25px",
                          color: "#fff",
                        }}
                      >
                        <PaymentsIcon
                          style={{
                            marginRight: "16px",
                            fontSize: "18px",
                            color: "#fff",
                          }}
                        />
                        <span
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                            color: "rgba(255, 255, 255, 0.7)",
                          }}
                        >
                          {process.env.REACT_APP_CURRENCY}{wallet?.amount
                            ? wallet?.amount?.toLocaleString("en-US")
                            : 0}{" "}
                          
                          <AddCircleIcon
                            style={{
                              marginRight: "16px",
                              fontSize: "25px",
                              color: "rgba(255, 255, 255, 0.7)",
                              cursor: "pointer",
                              marginLeft: "10px",
                            }}
                            onClick={() => navigate("/wallet/top-up")}
                          />
                        </span>
                      </Typography>
                    </Box>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={useWallet ? true : false}
                            value={useWallet}
                            onChange={setUseWalletFunc}
                          />
                        }
                        label='Use your wallet.'
                      />
                    </FormGroup>
                    {walletLessAmountError && (
                      <Typography
                        sx={{
                          color: "yellow",
                          fontSize: "16px",
                          fontFamily: "Jost",
                          display: "flex",
                          alignItems: "center",
                          marginTop: "12px",
                          fontWeight: "400",
                        }}
                      >
                        <span
                          style={{
                            color: "#fff",
                            fontWeight: "bold",
                            marginRight: "4px",
                          }}
                        >
                          Info:
                        </span>{" "}
                        {walletLessAmountError}
                      </Typography>
                    )}
                  </Box>

                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: "22px",
                      fontFamily: "Jost",
                      display: "flex",
                      alignItems: "center",
                      paddingTop: "12px",
                      borderTop: "1px solid rgba(255, 255, 255, 0.1)",
                      marginTop: "12px",
                    }}
                  >
                    Your Card
                  </Typography>
                  {userCards?.length > 0 ? (
                    <div style={{ width: "50%" }}>
                      <Select
                        className='override-select-edit'
                        placeholder='Select Card'
                        options={userCards}
                        value={
                          card.value == undefined
                            ? { label: "Select Card", value: "" }
                            : card
                        }
                        onChange={(option) => setCard(option)}
                        styles={customStyles}
                        formatOptionLabel={(item) => (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {item?.brand === "Visa" && (
                              <FaCcVisa
                                style={{
                                  marginRight: "8px",
                                  fontSize: "25px",
                                }}
                              />
                            )}
                            {item?.brand === "MasterCard" && (
                              <FaCcMastercard
                                style={{
                                  marginRight: "8px",
                                  fontSize: "25px",
                                }}
                              />
                            )}

                            {item?.brand}
                            <span
                              style={{ marginLeft: "10px" }}
                            >{`${item?.label}`}</span>
                          </div>
                        )}
                      />
                    </div>
                  ) : (
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: "16px",
                        fontFamily: "Jost",
                        display: "flex",
                        alignItems: "center",
                        marginTop: "12px",
                        fontWeight: "400",
                      }}
                    >
                      You dont have any cards! Add one by clicking below.
                    </Typography>
                  )}
                  <Grid
                    container
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography
                        onClick={setUseCreditCardFunc}
                        sx={{
                          cursor: "pointer",
                          color: "#fff",
                          textDecoration: "underline",
                        }}
                      >
                        {useCreditCard
                          ? "Use Saved Credit Card"
                          : "Add Credit Card"}
                      </Typography>
                    </Grid>

                    {useCreditCard && (
                      <>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              color: "#fff",
                              fontSize: "22px",
                              fontFamily: "Jost",
                              display: "flex",
                              alignItems: "center",
                              paddingTop: "12px",
                              borderTop: "1px solid rgba(255, 255, 255, 0.1)",
                              marginTop: "12px",
                            }}
                          >
                            Card Details
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography sx={{ textAlign: "left" }}>
                            Card Number
                          </Typography>
                          <TextField
                            sx={{ width: "100%" }}
                            id='number'
                            name='number'
                            number
                            required
                            placeholder='4242424242424242'
                            value={formik.values.number}
                            onChange={formik.handleChange}
                          />
                          {formik.touched.number &&
                            Boolean(formik.errors.number) && (
                              <small
                                id='username2-help'
                                className='p-error'
                                style={{
                                  display: "block",
                                  color: "red",
                                  textAlign: "left",
                                }}
                              >
                                {formik.errors.number}
                              </small>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography sx={{ textAlign: "left" }}>
                            Card Holder Name
                          </Typography>
                          <TextField
                            sx={{ width: "100%" }}
                            id='name'
                            name='name'
                            required
                            placeholder='Card Holder Name'
                            value={formik.values.name}
                            onChange={formik.handleChange}
                          />
                          {formik.touched.name &&
                            Boolean(formik.errors.name) && (
                              <small
                                id='username2-help'
                                className='p-error'
                                style={{
                                  display: "block",
                                  color: "red",
                                  textAlign: "left",
                                }}
                              >
                                {formik.errors.name}
                              </small>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                          <Typography sx={{ textAlign: "left" }}>
                            Expiration Month
                          </Typography>
                          <TextField
                            sx={{ width: "100%" }}
                            id='exp_month'
                            name='exp_month'
                            required
                            number
                            placeholder='03'
                            value={formik.values.exp_month}
                            onChange={formik.handleChange}
                          />
                          {formik.touched.exp_month &&
                            Boolean(formik.errors.exp_month) && (
                              <small
                                id='username2-help'
                                className='p-error'
                                style={{
                                  display: "block",
                                  color: "red",
                                  textAlign: "left",
                                }}
                              >
                                {formik.errors.exp_month}
                              </small>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                          <Typography sx={{ textAlign: "left" }}>
                            Expiration Years
                          </Typography>
                          <TextField
                            sx={{ width: "100%" }}
                            id='exp_year'
                            name='exp_year'
                            required
                            number
                            placeholder='10'
                            value={formik.values.exp_year}
                            onChange={formik.handleChange}
                          />
                          {formik.touched.exp_year &&
                            Boolean(formik.errors.exp_year) && (
                              <small
                                id='username2-help'
                                className='p-error'
                                style={{
                                  display: "block",
                                  color: "red",
                                  textAlign: "left",
                                }}
                              >
                                {formik.errors.exp_year}
                              </small>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                          <Typography sx={{ textAlign: "left" }}>
                            CVC
                          </Typography>
                          <TextField
                            sx={{ width: "100%" }}
                            id='cvc'
                            name='cvc'
                            required
                            number
                            placeholder='10'
                            value={formik.values.cvc}
                            onChange={formik.handleChange}
                          />
                          {formik.touched.cvc && Boolean(formik.errors.cvc) && (
                            <small
                              id='username2-help'
                              className='p-error'
                              style={{
                                display: "block",
                                color: "red",
                                textAlign: "left",
                              }}
                            >
                              {formik.errors.cvc}
                            </small>
                          )}
                        </Grid>

                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              color: "#fff",
                              fontSize: "22px",
                              fontFamily: "Jost",
                              display: "flex",
                              alignItems: "center",
                              paddingTop: "12px",
                              borderTop: "1px solid rgba(255, 255, 255, 0.1)",
                              marginTop: "12px",
                            }}
                          >
                            Billing Address
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography sx={{ textAlign: "left" }}>
                            Street Address
                          </Typography>
                          <TextField
                            sx={{ width: "100%" }}
                            id='address1'
                            name='address1'
                            required
                            placeholder='Street Address'
                            value={formik.values.address1}
                            onChange={formik.handleChange}
                          />
                          {formik.touched.address1 &&
                            Boolean(formik.errors.address1) && (
                              <small
                                id='username2-help'
                                className='p-error'
                                style={{
                                  display: "block",
                                  color: "red",
                                  textAlign: "left",
                                }}
                              >
                                {formik.errors.address1}
                              </small>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                          <Typography sx={{ textAlign: "left" }}>
                            Apt or suite number
                          </Typography>
                          <TextField
                            sx={{ width: "100%" }}
                            id='address2'
                            name='address2'
                            required
                            type='text'
                            placeholder='Enter Suite Number'
                            value={formik.values.address2}
                            onChange={formik.handleChange}
                          />
                          {formik.touched.address2 &&
                            Boolean(formik.errors.address2) && (
                              <small
                                id='username2-help'
                                className='p-error'
                                style={{
                                  display: "block",
                                  color: "red",
                                  textAlign: "left",
                                }}
                              >
                                {formik.errors.address2}
                              </small>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                          <Typography sx={{ textAlign: "left" }}>
                            City
                          </Typography>
                          <TextField
                            sx={{ width: "100%" }}
                            id='city'
                            name='city'
                            required
                            placeholder='City'
                            value={formik.values.city}
                            onChange={formik.handleChange}
                          />
                          {formik.touched.city &&
                            Boolean(formik.errors.city) && (
                              <small
                                id='username2-help'
                                className='p-error'
                                style={{
                                  display: "block",
                                  color: "red",
                                  textAlign: "left",
                                }}
                              >
                                {formik.errors.city}
                              </small>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ textAlign: "left" }}>
                            State
                          </Typography>
                          <TextField
                            sx={{ width: "100%" }}
                            id='state'
                            name='state'
                            required
                            placeholder='State'
                            value={formik.values.state}
                            onChange={formik.handleChange}
                          />
                          {formik.touched.state &&
                            Boolean(formik.errors.state) && (
                              <small
                                id='username2-help'
                                className='p-error'
                                style={{
                                  display: "block",
                                  color: "red",
                                  textAlign: "left",
                                }}
                              >
                                {formik.errors.state}
                              </small>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                          <Dropdown
                            value={formik.values.country}
                            options={options}
                            onChange={formik.handleChange}
                            optionLabel='name'
                            filter
                            showClear
                            filterBy='name'
                            id='country-dropdown'
                            name='country'
                            placeholder='Country/region'
                            valueTemplate={selectedCountryTemplate}
                            itemTemplate={countryOptionTemplate}
                            style={{
                              width: "100%",
                              background: "transparent",
                              padding: "6px 5px",
                              zIndex: 9999,
                            }}
                          />
                          {formik.touched.country &&
                            Boolean(formik.errors.country) && (
                              <small
                                id='username2-help'
                                className='p-error'
                                style={{
                                  display: "block",
                                  color: "red",
                                  textAlign: "left",
                                }}
                              >
                                {formik.errors.country}
                              </small>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ textAlign: "left" }}>
                            Zip Code
                          </Typography>
                          <TextField
                            sx={{ width: "100%" }}
                            id='zipcode'
                            name='zipcode'
                            required
                            type='number'
                            placeholder='Zip Code'
                            value={formik.values.zipcode}
                            onChange={formik.handleChange}
                          />
                          {formik.touched.zipcode &&
                            Boolean(formik.errors.zipcode) && (
                              <small
                                id='username2-help'
                                className='p-error'
                                style={{
                                  display: "block",
                                  color: "red",
                                  textAlign: "left",
                                }}
                              >
                                {formik.errors.zipcode}
                              </small>
                            )}
                        </Grid>
                      </>
                    )}
                  </Grid>
                </form>
              </>
            </Box>
          </DialogContentText>
        </DialogContent>
        <>
          <RenderActions />
        </>
      </Dialog>
    </div>
  );
}
export default PaymentModalForExtraPayments;
