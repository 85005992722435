import { API_URL } from '../../config';
import axios from 'axios';

export const getBookingApi = async (page) => {
  try {
    const response = await axios.get(API_URL + 'booking/all?page=' + page);
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of booking : ', response);
      return response;
    }
  }
};
export const getBookingByIdApi = async (id) => {
  try {
    const response = await axios.get(API_URL + `booking/${id}`);
    return response.data.data;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of get booking by id : ', response);
      return response;
    }
  }
};
