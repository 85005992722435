import { API_URL } from '../../config';
import axios from 'axios';

const getBookingGuestAPI = () => {
  const userData = JSON.parse(localStorage.getItem('userData')) || null;

  const pendingTripsAPI = async (page = 0, status = 'pending') => {
    try {
      const response = await axios.get(
        API_URL +
          `booking/all?guestId=${userData.id}&page=${page}&bookingStatus=${status}`
      );
      return response;
    } catch (error) {
      if (error) {
        const { response } = error;
        console.log('Bad Response of booking : ', response);
        return response;
      }
    }
  };

  const upcomingTripsAPI = async (page = 0, status = 'confirmed') => {
    try {
      const response = await axios.get(
        API_URL +
          `booking/all?guestId=${userData.id}&page=${page}&bookingStatus=${status}`
      );
      return response;
    } catch (error) {
      if (error) {
        const { response } = error;
        console.log('Bad Response of booking : ', response);
        return response;
      }
    }
  };

  const currentTripsAPI = async (page = 0, status = 'ongoing') => {
    try {
      const response = await axios.get(
        API_URL +
          `booking/all?guestId=${userData.id}&page=${page}&bookingStatus=${status}`
      );
      return response;
    } catch (error) {
      if (error) {
        const { response } = error;
        console.log('Bad Response of booking : ', response);
        return response;
      }
    }
  };

  const previousTripsAPI = async (page = 0, status = 'completed') => {
    try {
      const response = await axios.get(
        API_URL +
          `booking/all?guestId=${userData.id}&page=${page}&bookingStatus=${status}`
      );
      return response;
    } catch (error) {
      if (error) {
        const { response } = error;
        console.log('Bad Response of booking : ', response);
        return response;
      }
    }
  };

  const cancelReservation = async (dataReason) => {
    try {
      const response = await axios.post(
        API_URL + `booking-cancellation`,
        dataReason
      );
      return response;
    } catch (error) {
      if (error) {
        const { response } = error;
        console.log('Bad Response of booking : ', response);
        return response;
      }
    }
  };

  const getBookingDetail = async (id) => {
    try {
      const response = await axios.get(API_URL + `booking/${id}`);
      return response;
    } catch (error) {
      if (error) {
        const { response } = error;
        console.log('Bad Response of booking : ', response);
        return response;
      }
    }
  };

  return {
    upcomingTripsAPI,
    pendingTripsAPI,
    previousTripsAPI,
    currentTripsAPI,
    cancelReservation,
    getBookingDetail,
  };
};

export default getBookingGuestAPI;

// export const getBookingGuestApi = async (page = 1, status = 'reserved') => {
//   try {
//     const response = await axios.get(
//       API_URL +
//       `/booking/guest?guest_id=${userData.id}&page=${page}&status=${status}`
//     )
//     return response
//   } catch (error) {
//     if (error) {
//       const { response } = error
//       console.log('Bad Response of booking : ', response)
//       return response
//     }
//   }
// }
