import React from 'react'
import Header from '../../../components/header'
import UserProfileDetail from '../../../components/UserProfileDetail'
// import EditDetailPage from '../EditDetailPage/EditDetailPage'

const UserProfile = () => {
  return (
    <div>
      <div>
        <div className="loader-wrap">
          <div className="loader-inner">
            <svg>
              <defs>
                <filter id="goo">
                  <fegaussianblur in="SourceGraphic" stdDeviation="2" result="blur" />
                  <fecolormatrix in="blur" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2" result="gooey" />
                  <fecomposite in="SourceGraphic" in2="gooey" operator="atop" />
                </filter>
              </defs>
            </svg>
          </div>
        </div>
      </div>
      {/* <Header /> */}
      <div>
        <UserProfileDetail />
      </div>
    </div>
  )
}

export default UserProfile
