import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Input from "../../../../components/form/input";
import Card from "../../../../components/dasboard/card";
import Upload from "../../../../components/form/upload";
import { Toast } from "primereact/toast";
import { Typography } from "@mui/material";
const UploadPic = ({
  images,
  setImages,
  imagesURLs,
  setImagesURLs,
  thumbnailURLs,
  setThumbnailURLs,
  setThumbnail,
  thumbnail,
  errorImages,
  errorThumbnail,
}) => {
  useEffect(() => {
    if (thumbnail.length > 0) {
      const newThumbnailUrls = [];
      thumbnail.forEach((image) =>
        newThumbnailUrls.push(URL.createObjectURL(image))
      );
      setThumbnailURLs(newThumbnailUrls);
    }
    if (images.length > 0) {
      const newImageUrls = [];
      images.forEach((image) => newImageUrls.push(URL.createObjectURL(image)));
      setImagesURLs(newImageUrls);
    }
  }, [images, thumbnail]);
  const toast = React.useRef();
  const [imgDimention, setImgDimention] = useState(false);
  const onImageChange = (e) => {
    setImages((data) => [...data, ...e.target.files]);
  };
  const onThumbnailChange = (e) => {
    let imgFile = e.target.files[0];
    let img = new Image();
    img.src = window.URL.createObjectURL(imgFile);
    // img.onload = () => {
    //     if(img.width === img.height){
    //         setImgDimention(true);
    //     }else{
    //       setImgDimention(false);
    //     }
    // }
    const imgSize = e.target.files[0].size / 1024;
    // if(imgDimention === true){
    if (imgSize >= 3024) {
      toast.current.show({
        severity: "info",
        summary: "Error",
        detail: "Please photo upload below 3 mb",
        life: 10000,
      });
    } else {
      setThumbnail((data) => [...data, ...e.target.files]);
    }
    // }else{
    //   toast.current.show({
    //     severity: 'info',
    //     summary: 'Error',
    //     detail: 'Please photo dimention 1000 x 1000',
    //     life: 10000,
    //   });
    // }
  };

  const clearImage = (imageSrc) => {
    const filterImage = imagesURLs.filter((name) => name != imageSrc);
    setImagesURLs(filterImage);
    //re-again url object
    const newImageUrls = [];
    filterImage.forEach((image) =>
      newImageUrls.push(URL.createObjectURL(image))
    );
    setImages(newImageUrls);
  };

  const clearThumbnailImage = (imageSrc) => {
    const filterImage = thumbnailURLs.filter((name) => name != imageSrc);
    setThumbnailURLs(filterImage);
    //re-again url object
    const newImageUrls = [];
    filterImage.forEach((image) =>
      newImageUrls.push(URL.createObjectURL(image))
    );
    setThumbnail(newImageUrls);
  };
  return (
    <Card withIcon title={"Media"} icon={"fa fa-camera"}>
      <Toast ref={toast} position='bottom-right' />
      <Grid
        container
        rowSpacing={{ xs: 1, sm: 1, md: 2 }}
        columnSpacing={{ xs: 1, sm: 1, md: 2 }}
      >
        <Grid
          item
          xs={12}
          className={
            errorThumbnail && thumbnail?.length === 0
              ? "upload-image-error"
              : null
          }
        >
          <Typography>
            Your cover photo is a guest’s first impression of your listing.
          </Typography>
          <Upload
            label={"Upload Main Picture"}
            handleChange={(e) => onThumbnailChange(e)}
            disabled={thumbnail?.length >= 1 ? true : false}
            multiple={false}
          />
          {errorThumbnail && thumbnail?.length === 0 ? (
            <p className='upload-error'>{errorThumbnail}</p>
          ) : null}
          <div style={{ display: "inline" }}>
            {thumbnailURLs?.map((thumbnailSrc) => (
              <div className='image-div '>
                <img
                  src={thumbnailSrc}
                  style={{
                    maxWidth: "100%",
                    height: "100%",
                  }}
                />
                <div
                  className='clear-button '
                  onClick={() => clearThumbnailImage(thumbnailSrc)}
                >
                  <i
                    className='fa fa-times'
                    style={{
                      cursor: "pointer",
                      color: "#2e94f7",
                    }}
                  ></i>
                </div>
              </div>
            ))}
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          className={
            errorImages && images?.length === 0 ? "upload-image-error" : null
          }
        >
          <Upload
            label={"Upload Property Gallery"}
            handleChange={onImageChange}
            disabled={false}
            multiple={true}
          />
          {errorImages && images?.length === 0 ? (
            <p className='upload-error'>{errorImages}</p>
          ) : null}
          <div style={{ display: "inline" }}>
            {imagesURLs?.map((imageSrc) => (
              <div className='image-div '>
                <img
                  src={imageSrc}
                  style={{
                    maxWidth: "100%",
                    height: "100%",
                  }}
                  alt='img'
                />
                <div
                  className='clear-button '
                  onClick={() => clearImage(imageSrc)}
                >
                  <i
                    className='fa fa-times'
                    style={{
                      cursor: "pointer",
                      color: "#2e94f7",
                    }}
                  ></i>
                </div>
              </div>
            ))}
          </div>
        </Grid>
      </Grid>
    </Card>
  );
};

export default UploadPic;
