import React from 'react';
// import TextField from '@mui/material/TextField'
import { InputText } from 'primereact/inputtext';

//Simple input component
const Input = ({
  id,
  sx,
  name,
  type,
  label,
  value,
  error,
  margin,
  disabled,
  onChange,
  onClick,
  helperText,
  defaultValue,
  maxLength,
  minLength,
  min,
  step,
  showIcon
}) => {
  return (
    <>
      <div className="field">
        {/* <label htmlFor="username1">{label}</label> */}
        {
          showIcon ? <span style={{ display: "flex" }} className="p-input-icon-left">
            <i style={{ fontStyle: "normal", fontSize: "16px", top: "44%" }}>{process.env.REACT_APP_CURRENCY}</i>
            <InputText
              variant="outlined"
              fullWidth
              id={id}
              sx={sx}
              type={type}
              inputProps={{
                maxLength: 50,
              }}
              style={{
                minHeight: '55px',
                ...sx,
              }}
              name={name}
              label={label}
              placeholder={label}
              value={value}
              error={error}
              margin={margin}
              disabled={disabled}
              onChange={onChange}
              helperText={helperText}
              defaultValue={defaultValue}
              onClick={onClick}
              className={error && 'p-invalid'}
              maxLength={maxLength}
              minLength={minLength}
              min={min}
              step={step}
            />
          </span>
            : <InputText
              variant="outlined"
              fullWidth
              id={id}
              sx={sx}
              type={type}
              inputProps={{
                maxLength: 50,
              }}
              style={{
                minHeight: '55px',
                ...sx,
              }}
              name={name}
              label={label}
              placeholder={label}
              value={value}
              error={error}
              margin={margin}
              disabled={disabled}
              onChange={onChange}
              helperText={helperText}
              defaultValue={defaultValue}
              onClick={onClick}
              className={error && 'p-invalid'}
              maxLength={maxLength}
              minLength={minLength}
              min={min}
              step={step}
            />
        }

        {error && (
          <small
            id="username2-help"
            className="p-error"
            style={{
              display: 'block',
              color: 'red',
              textAlign: 'left',
            }}
          >
            {helperText}
          </small>
        )}
      </div>
    </>
  );
};

export default Input;
