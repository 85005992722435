import { API_URL } from '../../config';
import axios from 'axios';
import moment from 'moment';

export const getPropertyCalenderPriceApi = async (id) => {
  try {
    const response = await axios.get(
      API_URL +
        `property-price/all?propertyId=${id}&startDate=${moment()
          .subtract(2, 'months')
          .format('MM-DD-YYYY')}&endDate=${moment()
          .add(2, 'months')
          .format('MM-DD-YYYY')}`
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of properties price : ', response);
      return response;
    }
  }
};

export const getPropertyCalenderPriceByPropertyIdApi = async (id) => {
  try {
    const response = await axios.get(
      API_URL + `property-price/byPropertyId?propertyId=${id}`
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of properties price : ', response);
      return response;
    }
  }
};
export const getPropertyTotalAmountByDateRange = async (
  id,
  startDate,
  endDate
) => {
  try {
    const response = await axios.get(
      API_URL + `booking/getTotalPrice/${startDate}/${endDate}/${id}`
    );
    return response.data.data;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log(
        'Bad Response of properties total price by date range: ',
        response
      );
      return response;
    }
  }
};

export const editPropertyCalenderDate = async (data) => {
  try {
    const response = await axios.post(API_URL + `property-price`, data);
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      return response;
    }
  }
};
export const editPropertyCalenderDateByRange = async (data) => {
  try {
    const response = await axios.post(
      API_URL + `property-price/updateByRange`,
      data
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of price update by range: ', response);
      return response;
    }
  }
};
