//Yup and Formik are used for validations
import * as yup from 'yup';
/*Formik-Validation*/
export const validationSchema = yup.object({
  firstName: yup.string().required('First name is required'),
  middleName: yup.string(),
  lastName: yup.string().required('Last name is required'),
  // phoneNumber: yup.string().min(9).max(15).required("Phone number is required"),
  email: yup
    .string()
    .required('Email is required')
    .email('Please enter valid email'),
  password: yup.string().required('Password is required'),
  confirmEmail: yup
    .string()
    .required('Email is required!')
    .email('Please enter valid email.'),
});
