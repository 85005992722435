import React, { useState } from 'react';
import Input from '../form/input';
import ListItems from '../listItems';
import { GoogleApiWrapper } from 'google-maps-react';

import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
  getZipCode,
} from 'use-places-autocomplete';

const AutoCompletePlaces = ({ formik, label }) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });

  const [mapCenter, setMapCenter] = useState({
    lat: 10.26664,
    lng: -75.46663,
    address: 'DHA PHASE 2',
  });

  const handleSelect =
    ({ description }) =>
    () => {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setValue(description, false);
      formik.values.address = description;
      clearSuggestions();

      // Get latitude and longitude via utility functions
      getGeocode({ address: description })
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          formik.values.lat = lat;
          formik.values.lng = lng;
          setMapCenter({
            lat,
            lng,
            address: description,
          });
        })
        .catch((error) => {
          console.log('😱 Error: ', error);
        });

      getGeocode({ address: description })
        // By default we use the "long_name" value from API response, you can tell the utility to use "short_name"
        // by setting the second parameter to "true"
        .then((results) => {
          let city, state, country;
          for (let i = 0; i < results[0].address_components.length; i++) {
            for (
              let j = 0;
              j < results[0].address_components[i].types.length;
              j++
            ) {
              switch (results[0].address_components[i].types[j]) {
                case 'locality':
                  city = results[0].address_components[i].long_name;
                  break;

                case 'country':
                  country = results[0].address_components[i].long_name;
                  break;
                default:
                  break;
              }
            }
          }

          try {
            formik.values.city = city;
            formik.values.country = country;
          } catch (error) {
            console.log('Error: ', error);
          }
        });
    };

  const [isClick, setIsClick] = useState(false);
  const renderSuggestions = () =>
    data?.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <ListItems
          key={place_id}
          onClick={handleSelect(suggestion)}
          primary={main_text}
          secondary={secondary_text}
        />
      );
    });
  return (
    <>
      <Input
        sx={{ fontSize: '14px', textAlign: 'center' }}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          setIsClick(true);
        }}
        onClick={() => setIsClick(!isClick)}
        // disabled={!ready}
        label={label}
        // defaultValue={value}
      />
      {isClick && (
        <ul
          id='placesAutoComplete'
          style={{
            position: 'absolute',
            zIndex: 9999,
            color: '#fff',
            width: '29rem',
            fontSize: '14px',
          }}
        >
          {renderSuggestions()}
        </ul>
      )}
    </>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  libraries: ['cities'],
})(AutoCompletePlaces);
