import { API_URL } from '../../config';
import axios from 'axios';

export const addBookingApi = async (data, ownerId) => {
  const config = {
    method: 'post',
    url: API_URL + 'booking/add',
    headers: {
      accept: '*/*',
      'Content-Type': 'application/json',
    },
    data: { ...data, ownerId },
  };
  try {
    const response = await axios(config);
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Error Response of booking : ', response);
      return response;
    }
  }
};
