import React, { useState } from 'react';
import { Chart } from 'primereact/chart';
import InfoBar from '../../../components/dasboard/infoBar';
import Sidebar from '../../../components/dasboard/sidebar';
import Header from '../../../components/header';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PaymentsIcon from '@mui/icons-material/Payments';
import Button from '@mui/material/Button';
import { AiFillCreditCard } from 'react-icons/ai';
import Select from 'react-select';
import getVendorStatsApi from '../../../services/Vendor/getVendorStats';
import moment from 'moment/moment';
const MyEarningsPage = () => {
  const userData = JSON.parse(localStorage.getItem('userData')) || null;
  const [selectedMonth, setSelectedMonth] = useState({});
  const [graphData, setGraphData] = useState([]);
  const [stats, setStats] = useState({});
  const [year, setYear] = useState({
    value: moment().format('YYYY'),
    label: moment().format('YYYY'),
  });
  const basicData = {
    labels: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'June',
      'July',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    datasets: [
      {
        label: 'Earnings',
        data: [...graphData],
        fill: true,
        borderColor: '#3270fc',
        tension: 0.2,
        backgroundColor: '#0095ff',
      },
    ],
  };

  const getLightTheme = () => {
    let basicOptions = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {
          labels: {
            color: '#fff',
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: '#fff',
          },
          grid: {
            color: '#3c3949',
          },
        },
        y: {
          ticks: {
            color: '#fff',
            callback: function (value, index, ticks) {
              return `${process.env.REACT_APP_CURRENCY}` + value;
            },
          },
          grid: {
            color: '#0095ff',
          },
        },
      },
    };
    return {
      basicOptions,
    };
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: 'black',
      fontSize: '16px',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      const color = '#fff';
      return { ...provided, opacity, transition, color };
    },
  };

  const monthsData = [
    {
      value: `01`,
      label: 'January',
    },
    {
      value: `02`,
      label: 'February',
    },
    {
      value: `03`,
      label: 'March',
    },
    {
      value: `04`,
      label: 'April',
    },
    {
      value: `05`,
      label: 'May',
    },
    {
      value: `06`,
      label: 'June',
    },
    {
      value: `07`,
      label: 'July',
    },
    {
      value: `08`,
      label: 'August',
    },
    {
      value: `09`,
      label: 'September',
    },
    {
      value: `10`,
      label: 'October',
    },

    {
      value: `11`,
      label: 'November',
    },
    {
      value: `12`,
      label: 'December',
    },
  ];
  const { basicOptions } = getLightTheme();

  const navigate = useNavigate();

  React.useEffect(() => {
    const getStats = async () => {
      const res = await getVendorStatsApi(
        userData?.id,
        `${year.value}-${selectedMonth.value}`
      );
      setStats(res.data.data);
    };
    if (selectedMonth.value) {
      getStats();
    }
  }, [userData?.id, selectedMonth, year]);

  React.useEffect(() => {
    if (Object.keys(stats).length > 0) {
      if (selectedMonth?.value === `01`) {
        setGraphData([
          stats?.confirmedOverallOfMonth?.confirmed,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ]);
      } else if (selectedMonth?.value === `02`) {
        setGraphData([
          0,
          stats?.confirmedOverallOfMonth?.confirmed,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ]);
      } else if (selectedMonth?.value === `03`) {
        setGraphData([
          0,
          0,
          stats?.confirmedOverallOfMonth?.confirmed,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ]);
      } else if (selectedMonth?.value === `04`) {
        setGraphData([
          0,
          0,
          0,
          stats?.confirmedOverallOfMonth?.confirmed,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ]);
      } else if (selectedMonth?.value === `05`) {
        setGraphData([
          0,
          0,
          0,
          0,
          stats?.confirmedOverallOfMonth?.confirmed,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ]);
      } else if (selectedMonth?.value === `06`) {
        setGraphData([
          0,
          0,
          0,
          0,
          0,
          stats?.confirmedOverallOfMonth?.confirmed,
          0,
          0,
          0,
          0,
          0,
          0,
        ]);
      } else if (selectedMonth?.value === `07`) {
        setGraphData([
          0,
          0,
          0,
          0,
          0,
          0,
          stats?.confirmedOverallOfMonth?.confirmed,
          0,
          0,
          0,
          0,
          0,
        ]);
      } else if (selectedMonth?.value === `08`) {
        setGraphData([
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          stats?.confirmedOverallOfMonth?.confirmed,
          0,
          0,
          0,
          0,
        ]);
      } else if (selectedMonth?.value === `09`) {
        setGraphData([
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          stats?.confirmedOverallOfMonth?.confirmed,
          0,
          0,
          0,
        ]);
      } else if (selectedMonth?.value === `10`) {
        setGraphData([
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          stats?.confirmedOverallOfMonth?.confirmed,
          0,
          0,
        ]);
      } else if (selectedMonth?.value === `11`) {
        setGraphData([
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          stats?.confirmedOverallOfMonth?.confirmed,
          0,
        ]);
      } else if (selectedMonth?.value === `12`) {
        setGraphData([
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          stats?.confirmedOverallOfMonth?.confirmed,
        ]);
      }
    }
  }, [selectedMonth, stats, year]);

  React.useEffect(() => {
    setSelectedMonth({
      value: `${moment().format('MM')}`,
      label: `${moment().format('MMMM')}`,
    });
  }, []);

  const yearsData = [
    {
      value: moment().format('YYYY'),
      label: moment().format('YYYY'),
    },
    {
      value: moment().add(1, 'year').format('YYYY'),
      label: moment().add(1, 'year').format('YYYY'),
    },
    {
      value: moment().add(2, 'year').format('YYYY'),
      label: moment().add(2, 'year').format('YYYY'),
    },
  ];
  return (
    <>
      <Header />
      <div id='wrapper'>
        <div className='dashboard-menu-overlay' />
        <Sidebar page={'My Earnings'} />
        <div className='dashboard-content'>
          <div className='dashboard-menu-btn color-bg'>
            <span>
              <i className='fas fa-bars' />
            </span>
            My Earnings
          </div>
          <InfoBar label={'My Earnings'} />
          <div className='dasboard-wrapper fl-wrap no-pag'>
            <div>
              <div className='clearfix' />
              <div className='row'>
                <div className='col-md-8'>
                  <div style={{ width: '50%', margin: '15px auto' }}>
                    <Typography sx={{ fontSize: '18px', color: '#fff' }}>
                      Select Month
                    </Typography>
                    <Select
                      className='override-select-edit'
                      placeholder='Select Month'
                      options={monthsData}
                      value={selectedMonth}
                      onChange={(e) => setSelectedMonth(e)}
                      styles={customStyles}
                    />
                  </div>
                  <div style={{ width: '50%', margin: '15px auto' }}>
                    <Typography sx={{ fontSize: '18px', color: '#fff' }}>
                      Select Year
                    </Typography>
                    <Select
                      className='override-select-edit'
                      placeholder='Select Year'
                      options={yearsData}
                      value={year}
                      onChange={(e) => setYear(e)}
                      styles={customStyles}
                    />
                  </div>
                  <div className='dasboard-content fl-wrap'>
                    <Box sx={{ p: 3 }}>
                      <Box sx={{ display: 'flex' }}>
                        <Typography
                          sx={{
                            marginRight: '5px',
                            fontSize: '22px',
                            color: '',
                          }}
                        >
                          In {selectedMonth?.label}-{year?.value} so far,
                        </Typography>
                        <Typography sx={{ fontSize: '22px', color: '#fff' }}>
                          {stats?.confirmedOverall?.confirmed?.toLocaleString(
                            'en-US'
                          ) || 0}{' '}
                          {process.env.REACT_APP_CURRENCY}
                        </Typography>
                      </Box>
                      <Chart
                        type='bar'
                        data={basicData}
                        options={basicOptions}
                      />
                    </Box>
                  </div>

                  <div
                    className='dasboard-content fl-wrap'
                    style={{ margin: '10px 0px' }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '25px',
                          color: '#fff',
                          padding: '20px',
                        }}
                      >
                        <PaymentsIcon
                          style={{
                            marginRight: '16px',
                            fontSize: '18px',
                            color: '#fff',
                          }}
                        />
                        {process.env.REACT_APP_CURRENCY}{' '}
                        {stats?.unpaidOverall?.confirmed?.toLocaleString(
                          'en-US'
                        ) || 0}
                      </Typography>
                      <Button
                        variant='contained'
                        sx={{
                          background: '#0095ff',
                          display: 'flex',
                          alignItems: 'center',
                          marginRight: '10px',
                        }}
                        onClick={() =>
                          navigate(`/admin/vendor-withdrawal-history`)
                        }
                      >
                        <AiFillCreditCard
                          style={{ fontSize: '20px', marginRight: '6px' }}
                        />
                        Transaction History
                      </Button>
                    </Box>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyEarningsPage;
