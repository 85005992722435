import { API_URL } from "../../config";
import axios from "axios";

export const acceptPaymentRequestDirect = async (data) => {
  try {
    const response = await axios.post(
      API_URL + "booking-extra-payment/accept-extra-payment",
      data
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log("Error Response of accept  : ", response);
      return response;
    }
  }
};
