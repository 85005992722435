import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { Paginator } from 'primereact/paginator';
import Card from '../../../components/dasboard/card';
import InfoBar from '../../../components/dasboard/infoBar';
import Sidebar from '../../../components/dasboard/sidebar';
import Header from '../../../components/header';
import ErrorMessage from '../../../components/errorMessage';
import SkeletonLoader from '../../../components/skeletonLoader';
import AwaitingPaymentBookingCard from '../../../components/BookingComponents/AwaitingPaymentBookingCard';
import { getBookingGuestAwaitingPaymentApi } from '../../../services/booking/getAwaitingPayment';
import SingleWithTimer from '../../../components/Tripis/SingleWithTimer';
import { getCommissionFee } from './../../../services/commissionFee/CommissionFee';

const AwaitingPaymentPage = () => {
  const [properties, setProperties] = useState([]);
  const [filteredProperty, setFilteredProperty] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [basicFirst, setBasicFirst] = useState(0);
  const [basicRows, setBasicRows] = useState(10);
  const [filterToggle, setFilterToggle] = useState(false);
  const [commissionFee, setCommissionFee] = useState(0);

  const onBasicPageChange = (event) => {
    setBasicFirst(event.first);
    setBasicRows(event.rows);
  };

  const getPropertyListings = async () => {
    try {
      const response = await getBookingGuestAwaitingPaymentApi();
      if (response.status === 200) {
        setProperties(response.data?.data);
        setFilteredProperty(response.data?.data);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPropertyListings();
    getCommissionFee()
      .then((res) => {
        setCommissionFee(res.data.data[0].commission_percentage);
      })
      .catch((err) => {});
  }, []);

  return (
    <>
      <Header />
      <div id='wrapper'>
        <div className='dashboard-menu-overlay' />
        <Sidebar page={'Awaiting Payment'} />
        <div className='dashboard-content'>
          <div className='dashboard-menu-btn color-bg'>
            <span>
              <i className='fas fa-bars' />
            </span>
            Dasboard Menu
          </div>
          {/* {isOpen && <Dropdown/>} */}

          <InfoBar label={'Awaiting Payment'} />
          <div className='dasboard-wrapper fl-wrap no-pag'>
            {isLoading === true ? (
              <Loader />
            ) : (
              <>
                {properties?.length === 0 ? (
                  <Grid container>
                    <Grid item xs={12}>
                      <ErrorMessage />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    container
                    rowSpacing={{ xs: 1, sm: 1, md: 1 }}
                    columnSpacing={{ xs: 1, sm: 1, md: 2 }}
                  >
                    {filterToggle
                      ? filteredProperty.map((booking) => {
                          return (
                            <>
                              <Grid item xs={12} sm={6} md={12}>
                                <SingleWithTimer
                                  booking={booking}
                                  commissionFee={commissionFee}
                                />
                              </Grid>
                            </>
                          );
                        })
                      : filteredProperty
                          ?.slice(basicFirst, basicRows + basicFirst)
                          .map((booking) => {
                            return (
                              <>
                                <Grid item xs={12} sm={6} md={12}>
                                  <SingleWithTimer
                                    booking={booking}
                                    commissionFee={commissionFee}
                                  />
                                </Grid>
                              </>
                            );
                          })}
                  </Grid>
                )}
              </>
            )}

            <Card>
              <Paginator
                first={basicFirst}
                rows={basicRows}
                totalRecords={properties?.length}
                rowsPerPageOptions={[5, 10, 20, 30]}
                onPageChange={onBasicPageChange}
              ></Paginator>
            </Card>
          </div>
        </div>
        <div className='dashbard-bg gray-bg' />
      </div>
    </>
  );
};

const Loader = () => {
  return (
    <Grid
      container
      rowSpacing={{ xs: 1, sm: 1, md: 2 }}
      columnSpacing={{ xs: 1, sm: 1, md: 2 }}
    >
      <Grid item xs={12} sm={6} md={6}>
        <SkeletonLoader height={200} />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <SkeletonLoader height={200} />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <SkeletonLoader height={200} />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <SkeletonLoader height={200} />
      </Grid>
    </Grid>
  );
};

export default AwaitingPaymentPage;
