import {
  Grid,
  Typography,
  Button,
  List,
  ListItemButton,
  ListItemText,
  TextField,
} from '@mui/material';
import React, { useState, useRef } from 'react';
import InfoBar from '../dasboard/infoBar';
import Sidebar from '../dasboard/sidebar';
import Header from '../header';
import { useLocation, useNavigate } from 'react-router-dom';
import getBookingGuestAPI from '../../services/booking/getBookingGuest';
import { Toast } from 'primereact/toast';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { getCancellationFeePreviewApi } from '../../services/booking/getCancellationFeePreview';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
const CancelReservation = () => {
  const toast = useRef(null);
  const { cancelReservation } = getBookingGuestAPI();
  const [cancellationFee, setCancellationFee] = useState({});
  const userData = JSON.parse(localStorage.getItem('userData')) || null;
  const location = useLocation();
  const { booking } = location.state;
  const [reason, setReason] = useState('');
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      userId: userData?.id,
      bookingId: booking?.id,
      reason: reason,
      comment: '',
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      reason: yup.string().required('Please select a reason!'),
    }),
    onSubmit: async (values) => {
      const response = await cancelReservation(values);
      if (response.status === 201) {
        toast.current.show({
          severity: 'success',
          summary: 'Cancellation Request Sent!',
          detail: 'Booking Cancellation Request Sent successfully!',
          life: 3000,
        });
        setTimeout(() => {
          navigate(`/booking/${booking?.id}`);
        }, 2000);
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Cancellation Request cannot be Sent!',
          detail: 'Booking Cancellation Request cannot be sent!',
          life: 3000,
        });
      }
    },
  });

  React.useEffect(() => {
    const getCancellationFeePreview = async () => {
      const res = await getCancellationFeePreviewApi(booking?.id);
      setCancellationFee(res.data);
    };
    if (booking?.id) {
      getCancellationFeePreview();
    }
  }, [booking?.id]);

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (formik.touched.reason && Boolean(formik.errors.reason)) {
      toast.current.show({
        severity: 'info',
        summary: 'Please select a reason!',
        detail:
          'Please select a reason to send the booking cancellation request.',
        life: 3000,
      });
    }
  }, [formik.touched.reason, formik.errors.reason]);
  return (
    <>
      <Header />
      <Toast ref={toast} position='bottom-right' />
      <div id='wrapper'>
        <div className='dashboard-menu-overlay' />
        <Sidebar page={'Booking Details'} />
        <div className='dashboard-content'>
          <div className='dashboard-menu-btn color-bg'>
            <span>
              <i className='fas fa-bars' />
            </span>
            Dasboard Menu
          </div>
          {/* {isOpen && <Dropdown/>} */}
          <div className='container dasboard-container'>
            <InfoBar label={'Booking Details'} />
            <div className='dasboard-wrapper fl-wrap no-pag'>
              <Grid
                container
                sx={{ m: '0 auto' }}
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Grid item xs={12} sm={8} md={8}>
                  <Typography sx={{ fontSize: '18px' }}>
                    Please select a reason
                  </Typography>
                  <List
                    sx={{ p: 2, bgcolor: '#1b182b', color: '#fff' }}
                    component='nav'
                  >
                    <ListItemButton
                      className={
                        reason === 'I no longer meed accommodation'
                          ? 'cancelActive'
                          : ''
                      }
                      onClick={() =>
                        setReason('I no longer meed accommodation')
                      }
                    >
                      <ListItemText
                        sx={{ fontSize: '14px' }}
                        primary='I no longer meed accommodation'
                      />
                    </ListItemButton>
                    <ListItemButton
                      className={
                        reason === 'I made the reservation by accident'
                          ? 'cancelActive'
                          : ''
                      }
                      onClick={() =>
                        setReason('I made the reservation by accident')
                      }
                    >
                      <ListItemText
                        sx={{ fontSize: '14px' }}
                        primary='I made the reservation by accident'
                      />
                    </ListItemButton>
                    <ListItemButton
                      className={
                        reason === 'My host need to cancel'
                          ? 'cancelActive'
                          : ''
                      }
                      onClick={() => setReason('My host need to cancel')}
                    >
                      <ListItemText
                        sx={{ fontSize: '14px' }}
                        primary='My host need to cancel'
                      />
                    </ListItemButton>
                    <ListItemButton
                      className={
                        reason === 'I am uncomfortable with the host'
                          ? 'cancelActive'
                          : ''
                      }
                      onClick={() =>
                        setReason('I am uncomfortable with the host')
                      }
                    >
                      <ListItemText
                        sx={{ fontSize: '14px' }}
                        primary="I'm uncomfortable with the host"
                      />
                    </ListItemButton>
                    <ListItemButton
                      className={
                        reason === 'The place is not what i expecting'
                          ? 'cancelActive'
                          : ''
                      }
                      onClick={() =>
                        setReason('The place is not what i expecting')
                      }
                    >
                      <ListItemText
                        sx={{ fontSize: '14px' }}
                        primary="The place isn't what i expecting"
                      />
                    </ListItemButton>
                    <ListItemButton
                      className={
                        reason === 'My travel dates changed'
                          ? 'cancelActive'
                          : ''
                      }
                      onClick={() => setReason('My travel dates changed')}
                    >
                      <ListItemText
                        sx={{ fontSize: '14px' }}
                        primary='My travel dates changed'
                      />
                    </ListItemButton>
                    <ListItemButton
                      className={reason === 'Other' ? 'cancelActive' : ''}
                      sx={{ mb: 2 }}
                      onClick={() => setReason('Other')}
                    >
                      <ListItemText sx={{ fontSize: '14px' }} primary='Other' />
                    </ListItemButton>

                    <form onSubmit={formik.handleSubmit}>
                      {reason === 'Other' && (
                        <TextField
                          multiline
                          rows={4}
                          required
                          sx={{ width: '60%', m: '0 auto' }}
                          placeholder='Explain your reason...'
                          onChange={formik.handleChange}
                          id='reason'
                        />
                      )}

                      {!(reason === 'Other')
                        ? reason && (
                            <TextField
                              multiline
                              rows={4}
                              required
                              sx={{ width: '60%', m: '0 auto' }}
                              placeholder='Enter your comment'
                              onChange={formik.handleChange}
                              value={formik.values.comment}
                              name='comment'
                            />
                          )
                        : ''}
                      <br></br>
                      <Button
                        sx={{ my: 2 }}
                        onClick={handleClickOpen}
                        variant='contained'
                      >
                        Send Cancellation Request
                      </Button>

                      <Dialog
                        fullScreen={fullScreen}
                        open={open}
                        onClose={handleClose}
                        aria-labelledby='responsive-dialog-title'
                      >
                        <DialogTitle
                          id='responsive-dialog-title'
                          sx={{ background: '#1c172b' }}
                        >
                          {'Booking Cancellation Charges Preview'}
                        </DialogTitle>
                        <DialogContent sx={{ background: '#1c172b' }}>
                          <DialogContentText>
                            <Typography
                              sx={{ color: '#fff', fontSize: '18px' }}
                            >
                              Gross Booking Amount
                            </Typography>
                            <p>
                              {process.env.REACT_APP_CURRENCY}
                              {cancellationFee?.grossAmount?.toLocaleString(
                                'en-US'
                              ) || 0}
                            </p>
                            <Typography
                              sx={{ color: '#fff', fontSize: '18px' }}
                            >
                              Cleaning Fee
                            </Typography>
                            <p>
                              {process.env.REACT_APP_CURRENCY}
                              {cancellationFee?.cleaningFee?.toLocaleString(
                                'en-US'
                              ) || 0}
                            </p>
                            <Typography
                              sx={{ color: '#fff', fontSize: '18px' }}
                            >
                              Service Fee
                            </Typography>
                            <p>
                              {process.env.REACT_APP_CURRENCY}
                              {cancellationFee?.serviceFee?.toLocaleString(
                                'en-US'
                              ) || 0}
                            </p>
                            <Typography
                              sx={{ color: '#fff', fontSize: '18px' }}
                            >
                              Total Stay Refund
                            </Typography>
                            <p>
                              {process.env.REACT_APP_CURRENCY}
                              {cancellationFee?.stayRefund?.toLocaleString(
                                'en-US'
                              ) || 0}
                            </p>
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ background: '#1c172b' }}>
                          <Button autoFocus onClick={handleClose}>
                            Disagree
                          </Button>
                          <Button onClick={formik.handleSubmit} autoFocus>
                            Agree
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </form>
                  </List>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        <div className='dashbard-bg gray-bg' />
      </div>
    </>
  );
};
export default CancelReservation;
