import React, { useState, useEffect } from "react";
import InfoBar from "../../../components/dasboard/infoBar";
import Sidebar from "../../../components/dasboard/sidebar";
import Header from "../../../components/header";
import { Box, Tab, Grid, Typography, Icon } from "@mui/material";
import { TabList, TabPanel, TabContext } from "@mui/lab";
import dashboardStyles from "./style";
import TabCards from "./TabCards";
import NoDataFound from "./NoDataFound";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CloseIcon from "@mui/icons-material/Close";
import Buttons from "../../../components/form/button";
import axios from "axios";
import { API_URL } from "../../../config";
import SkeletonLoader from "../../../components/skeletonLoader";

const dashboardTabs = [
  { label: "Pending", key: "1", slug: "pending" },
  { label: "Accepted", key: "2", slug: "accepted" },
  { label: "Confirmed", key: "3", slug: "confirmed" },
  { label: "Current", key: "4", slug: "ongoing" },
  { label: "Pending Review", key: "5", slug: "completed" },
];

const Dashboard = () => {
  const classes = dashboardStyles();
  const userData = JSON.parse(localStorage.getItem("userData")) || null;
  const userHost = JSON.parse(localStorage.getItem("userHost")) || null;
  const [value, setValue] = React.useState("pending");
  const [bookingData, setBookingData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getData = async (status) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        API_URL + `booking/all?ownerId=${userData.id}&bookingStatus=${status}`
      );
      setIsLoading(false);
      setBookingData(response.data.data);
    } catch (error) {
      if (error) {
        const { response } = error;
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    getData(value);
  }, []);

  const handleChange = async (event, newValue) => {
    setValue(newValue);
    getData(newValue);
  };

  return (
    <>
      <Header />
      <div id="wrapper">
        <div className="dashboard-menu-overlay" />
        <Sidebar page={"Dashboard"} />
        <div className="dashboard-content">
          <div className="dashboard-menu-btn color-bg">
            <span>
              <i className="fas fa-bars" />
            </span>
            Dashboard Menu
          </div>
          <div>
            <InfoBar label={"Today"} />
          </div>
          <div
            className="dasboard-wrapper fl-wrap no-pag"
            style={{ marginTop: "17px" }}
          >
            <div className={classes.reservationsCountSection}>
              <Typography variant="h6">Your reservations</Typography>
              <Typography variant="body2">All reservations (0)</Typography>
            </div>
            <div style={{ margin: "10px 0" }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    {dashboardTabs?.map((tab, i) => (
                      <Tab
                        sx={{ fontSize: "14px" }}
                        label={tab.label}
                        key={i}
                        value={tab.slug}
                        icon={
                          <div
                            style={{
                              display: value == tab.slug ? "flex" : "none",
                              background: "#0095ff",
                              borderRadius: "50%",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: "0 5px",
                            }}
                          >
                            <p
                              style={{
                                paddingBottom: 0,
                                color: "#fff",
                              }}
                            >
                              {bookingData.length}
                            </p>
                          </div>
                        }
                        iconPosition="end"
                      />
                    ))}
                  </TabList>
                </Box>
                <TabPanel
                  value="pending"
                  sx={{
                    "&.MuiTabPanel-root": {
                      padding: 0,
                      marginTop: "10px",
                    },
                  }}
                >
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <>
                      {bookingData.length > 0 ? (
                        <TabCards bookingData={bookingData} />
                      ) : (
                        <NoDataFound />
                      )}
                    </>
                  )}
                </TabPanel>
                <TabPanel value="accepted">
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <>
                      {bookingData.length > 0 ? (
                        <TabCards bookingData={bookingData} />
                      ) : (
                        <NoDataFound />
                      )}
                    </>
                  )}
                </TabPanel>
                <TabPanel value="confirmed">
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <>
                      {bookingData.length > 0 ? (
                        <TabCards bookingData={bookingData} />
                      ) : (
                        <NoDataFound />
                      )}
                    </>
                  )}
                </TabPanel>
                <TabPanel value="ongoing">
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <>
                      {bookingData.length > 0 ? (
                        <TabCards bookingData={bookingData} />
                      ) : (
                        <NoDataFound />
                      )}
                    </>
                  )}
                </TabPanel>
                <TabPanel value="completed">
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <>
                      {bookingData.length > 0 ? (
                        <TabCards bookingData={bookingData} />
                      ) : (
                        <NoDataFound />
                      )}
                    </>
                  )}
                </TabPanel>
              </TabContext>
            </div>

            <div style={{ margin: "10px 0" }}>
              <Typography className={classes.nextStepHead}>
                Next steps for you
              </Typography>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <div className={classes.cardsStyle}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <CalendarMonthIcon style={{ color: "#fff" }} />
                      <CloseIcon style={{ color: "#fff" }} />
                    </div>

                    <Typography variant="body2" className={classes.titleOne}>
                      Fine-tune your price
                    </Typography>
                    <Typography variant="body2" className={classes.titleTwo}>
                      Explore new pet fees and short-stay cleaning fees.
                    </Typography>

                    <div
                      style={{ width: "60%", position: "absolute", bottom: 12 }}
                    >
                      <Buttons
                        title={"Go to pricing settings"}
                        isLoading={false}
                        // onSubmit={() => setOpenThumbnail(true)}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <div className={classes.cardsStyle}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <CalendarMonthIcon style={{ color: "#fff" }} />
                      <CloseIcon style={{ color: "#fff" }} />
                    </div>

                    <Typography variant="body2" className={classes.titleThree}>
                      Cheerful 1 Bedroom Townhouse
                    </Typography>
                    <Typography variant="body2" className={classes.titleOne}>
                      is your dedicated workspace in a shared space?
                    </Typography>

                    <div
                      style={{
                        position: "absolute",
                        bottom: 12,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ width: "60%" }}>
                        <Buttons
                          title={"Yes"}
                          isLoading={false}
                          // onSubmit={() => setOpenThumbnail(true)}
                        />
                      </div>
                      <div style={{ width: "60%", marginLeft: "10px" }}>
                        <Buttons
                          title={"No"}
                          isLoading={false}
                          // onSubmit={() => setOpenThumbnail(true)}
                        />
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <div className={classes.cardsStyle}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <CalendarMonthIcon style={{ color: "#fff" }} />
                      <CloseIcon style={{ color: "#fff" }} />
                    </div>

                    <Typography variant="body2" className={classes.titleThree}>
                      Cheerful 1 Bedroom Townhouse
                    </Typography>
                    <Typography variant="body2" className={classes.titleOne}>
                      is your hot tub shared?
                    </Typography>
                    <Typography variant="body2" className={classes.titleTwo}>
                      This info helps guests know that your place is the right
                      fit
                    </Typography>

                    <div
                      style={{
                        position: "absolute",
                        bottom: 12,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ width: "60%" }}>
                        <Buttons
                          title={"Yes"}
                          isLoading={false}
                          // onSubmit={() => setOpenThumbnail(true)}
                        />
                      </div>
                      <div style={{ width: "60%", marginLeft: "10px" }}>
                        <Buttons
                          title={"No"}
                          isLoading={false}
                          // onSubmit={() => setOpenThumbnail(true)}
                        />
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <div className={classes.cardsStyle}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <CalendarMonthIcon style={{ color: "#fff" }} />
                      <CloseIcon style={{ color: "#fff" }} />
                    </div>

                    <Typography variant="body2" className={classes.titleThree}>
                      Cheerful 1 Bedroom Townhouse
                    </Typography>
                    <Typography variant="body2" className={classes.titleOne}>
                      Tell us more about your TV.
                    </Typography>

                    <div
                      style={{ width: "40%", position: "absolute", bottom: 12 }}
                    >
                      <Buttons
                        title={"Add Details"}
                        isLoading={false}
                        // onSubmit={() => setOpenThumbnail(true)}
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Loader = () => {
  return (
    <Grid
      container
      rowSpacing={{ xs: 1, sm: 1, md: 2 }}
      columnSpacing={{ xs: 1, sm: 1, md: 2 }}
    >
      <Grid item xs={12}>
        <SkeletonLoader height={200} />
      </Grid>
    </Grid>
  );
};

export default Dashboard;
