import * as React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Box } from "@mui/system";
import { Rating, Typography } from "@mui/material";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { AiFillStar, AiFillHome, AiOutlineRight } from "react-icons/ai";
import { MdVerifiedUser } from "react-icons/md";
import { BiLogInCircle } from "react-icons/bi";
import { FcBusinessman } from "react-icons/fc";
import { IoIosHelpCircle } from "react-icons/io";
import moment from "moment";
import { BsCash, BsLockFill } from "react-icons/bs";
import Buttons from "../../components/form/button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import NightlightRoundIcon from "@mui/icons-material/NightlightRound";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PetsIcon from "@mui/icons-material/Pets";
import ChildCareIcon from "@mui/icons-material/ChildCare";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import { calculateBookingPriceApi } from "../../services/booking/calculateBookingPrice";
import getBookingGuestAPI from "../../services/booking/getBookingGuest";
import { getCommissionFee } from "../../services/commissionFee/CommissionFee";
import { useFormik } from "formik";
import { editPropertyCalenderDateByRange } from "../../services/properties/propertyCalenderPrice";
import * as yup from "yup";
import { useSnackbar } from "notistack";

const ReservationDetailCardForBookingCalendar = ({ open, data }) => {
  const { getBookingDetail } = getBookingGuestAPI();
  const { enqueueSnackbar } = useSnackbar();
  const [scroll, setScroll] = React.useState("paper");
  const [commissionFee, setCommissionFee] = useState(0);
  const [bookingDetail, setBookingDetail] = useState([]);
  const [values, setValues] = useState({});
  const navigate = useNavigate();
  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behaviour: "smooth" });
    getBookingData(data?.id);
    getCommissionFee()
      .then((res) => {
        setCommissionFee(res.data.data[0].commission_percentage);
      })
      .catch((err) => {});
  }, [data]);

  const getBookingData = async (id) => {
    try {
      // setIsLoading(false);
      const response = await getBookingDetail(id);
      if (response.status === 200) {
        setBookingDetail(response.data?.data);
        let dataaaa = response.data?.data;

        try {
          const resulttt = await calculateBookingPriceApi({
            propertyId: dataaaa.property.id,
            startDate: moment(dataaaa.startDate).toDate(),
            endDate: moment(dataaaa.endDate).toDate(),
            noOfAdults: dataaaa.totalAdults,
            noOfChildren: dataaaa.totalChildren,
            noOfInfants: dataaaa.totalInfants,
            noOfPets: dataaaa.totalPets,
            validate: true,
          });
          setValues(resulttt.data.data);
        } catch (error) {}
      }
    } catch (err) {
    }
  };

  let staywoFee = (commissionFee * bookingDetail?.grossAmount) / 100;

  const formik = useFormik({
    initialValues: {
      propertyId: bookingDetail?.property?.id,
      privateNote: "",
      startDate: bookingDetail?.startDate,
      endDate: bookingDetail?.endDate,
    },
    validationSchema: yup.object({
      privateNote: yup.string().required("Private Note Is Required."),
    }),
    enableReinitialize: true,
    onSubmit: async (values) => {
      const res = await editPropertyCalenderDateByRange(values);
      if (res.status === 200 || res.status === 201) {
        enqueueSnackbar("Calendar note added!", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    },
  });
  return (
    <>
      <Box
        open={true}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          <NavLink to={"/admin/property-listing"}>
            <Box
              sx={{ margin: "0px 10px", display: "flex", alignItems: "center" }}
            >
              <ArrowBackIcon sx={{ color: "#fff" }} />
              <Buttons
                title="Back To My Listings"
                isLoading={false}
                variant={"text"}
              />
            </Box>
          </NavLink>
        </Box>
        <DialogContent
          sx={{ height: "90vh", width: "20vw", background: "#1c172b" }}
          id="scroll-dialog-description"
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{ color: "#fff", fontFamily: "Jost", fontSize: "25px" }}
            >
              Reservation Details
            </Typography>
          </Box>
          <Box component="div" sx={{ marginTop: "10px" }}>
            <Typography
              sx={{ fontFamily: "Jost", fontSize: "22px", color: "white" }}
            >
              Property Details
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "10px 0px",
                borderBottom: "0.5px solid #0095ff",
              }}
            >
              <Box>
                <img
                  src={data?.property?.thumbnailUrl}
                  alt="Property Image"
                  width={130}
                  height={130}
                />
              </Box>
              <Box sx={{ marginLeft: "10px" }}>
                <Typography
                  sx={{ color: "#fff", fontFamily: "Jost", fontSize: "22px" }}
                >
                  {data?.property?.title && data?.property?.title?.length > 30
                    ? data?.property?.title?.slice(0, 30) + "..."
                    : data?.property?.title?.slice(0, 30)}
                </Typography>
                <Typography sx={{ fontFamily: "Jost", fontSize: "14px" }}>
                  {data?.property?.city}, {data?.property?.country}
                </Typography>
                <Typography sx={{ fontFamily: "Jost", fontSize: "14px" }}>
                  Property ID: {data?.property?.code}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Jost",
                    fontSize: "16px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Rating
                    name="half-rating-read"
                    value={
                      data?.property?.average_rating
                        ? data?.property?.average_rating?.averageRating
                        : 0
                    }
                    precision={0.5}
                    readOnly
                    sx={{ marginRight: "4px" }}
                  />
                  (
                  {data?.property?.average_rating
                    ? data?.property?.average_rating?.reviewCount
                    : 0}
                  )
                </Typography>
              </Box>
            </Box>
          </Box>
          <DialogContentText
            id="scroll-dialog-description"
            tabIndex={-1}
            sx={{ width: "100%" }}
          >
            <Box
              sx={{
                padding: "25px 0px",
                borderBottom: "0.5px solid #0095ff",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  sx={{ fontFamily: "Jost", fontSize: "22px", color: "white" }}
                >
                  About{" "}
                  {data?.guest?.firstName
                    ? data?.guest?.firstName + " " + data?.guest?.lastName
                    : ""}
                </Typography>
                <img
                  src={
                    data?.guest?.profilePicture !== null
                      ? data?.guest?.profilePicture
                      : require("../../assets/images/avatar/5.jpg")
                  }
                  style={{
                    borderRadius: "50%",
                    width: "65px",
                    height: "65px",
                  }}
                />
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <>
                  <Rating
                    name="half-rating-read"
                    value={
                      data?.guest?.guestAverageRating
                        ? data?.guest?.guestAverageRating?.averageRating
                        : 0
                    }
                    precision={0.5}
                    readOnly
                  />
                  <span
                    style={{
                      textDecoration: "underline",
                      fontSize: "14px",
                      color: "rgba(255, 255, 255, 0.7)",
                      marginLeft: "10px",
                    }}
                  >
                    (
                    {data?.guest?.guestAverageRating
                      ? data?.guest?.guestAverageRating?.reviewCount
                      : 0}{" "}
                    - Reviews)
                  </span>
                </>
              </div>
              {data?.owner?.verified && (
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <MdVerifiedUser
                    style={{
                      fontSize: "14px",
                      color: "white",
                      marginRight: "4px",
                    }}
                  />

                  <Link
                    to="/"
                    style={{
                      textDecoration: "underline",
                      fontSize: "14px",
                      color: "rgba(255, 255, 255, 0.7)",
                    }}
                  >
                    Indenitiy Verified
                  </Link>
                </Typography>
              )}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <BiLogInCircle
                  style={{
                    fontSize: "14px",
                    color: "white",

                    marginRight: "4px",
                  }}
                />
                <a
                  href="#"
                  style={{
                    fontSize: "14px",
                    color: "rgba(255, 255, 255, 0.7)",
                  }}
                >
                  Joined on{" "}
                  {moment(data?.guest?.createdOn).format("DD MMM YYYY")}
                </a>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <FcBusinessman
                  style={{
                    fontSize: "16px",
                    color: "white",
                    marginRight: "4px",
                  }}
                />
                <Link
                  to={`/about-host/${data?.guest?.id}`}
                  style={{
                    fontSize: "14px",
                    color: "rgba(255, 255, 255, 0.7)",
                  }}
                  target="_blank"
                  rel="noreferrer"
                >
                  Also a host
                </Link>
              </div>
              <Link
                to={`/about-host/${data?.guest?.id}`}
                style={{
                  color: "white",
                  textDecoration: "underline",
                  fontSize: "14px",
                }}
                target="_blank"
                rel="noreferrer"
              >
                Show profile
              </Link>
            </Box>
            <Box
              sx={{
                padding: "25px 0px",
                borderBottom: "0.5px solid #0095ff",
              }}
            >
              <Typography
                sx={{ fontFamily: "Jost", fontSize: "22px", color: "white" }}
              >
                Booking Details
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ fontSize: "16px", color: "white" }}>
                  Booking nights
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {moment(data?.startDate).format("DD MMM YYYY")} -{" "}
                  {moment(data?.endDate).format("DD MMM YYYY")}{" "}
                  <NightlightRoundIcon
                    sx={{ fontSize: "15px", marginLeft: "5px" }}
                  />{" "}
                  ({data?.numberOfNights} night
                  {data?.numberOfNights > 1 ? "s" : ""})
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "14px 0px",
                  }}
                >
                  <Typography>
                    <Typography sx={{ fontSize: "16px", color: "white" }}>
                      Guests
                    </Typography>
                    <Typography sx={{ fontSize: "14px" }}>
                      <p>
                        <i className="fas fa-user"></i> {data?.totalGuests}
                      </p>
                      <p>
                        {data?.totalAdults > 0
                          ? data?.totalAdults + " Adults"
                          : ""}
                        {data?.totalAdults > 0 && data?.totalChildren > 0
                          ? " - "
                          : ""}
                        {data?.totalChildren > 0
                          ? data?.totalChildren + " Children"
                          : ""}
                      </p>
                      {data?.totalInfants > 0 || data?.totalPets > 0 ? (
                        <p>
                          {data?.totalInfants > 0
                            ? data?.totalInfants + " Infants"
                            : ""}
                          {data?.totalInfants > 0 && data?.totalPets > 0
                            ? " - "
                            : ""}
                          {data?.totalPets > 0 ? data?.totalPets + " Pets" : ""}
                        </p>
                      ) : (
                        ""
                      )}
                    </Typography>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    paddingBottom: "14px",
                  }}
                >
                  <Typography>
                    <Typography sx={{ fontSize: "16px", color: "white" }}>
                      Check In Time
                    </Typography>
                    <Typography sx={{ fontSize: "14px" }}>
                      {data?.property?.propertyPolicy?.checkInWindow}
                    </Typography>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    paddingBottom: "14px",
                  }}
                >
                  <Typography>
                    <Typography sx={{ fontSize: "16px", color: "white" }}>
                      Check Out
                    </Typography>
                    <Typography sx={{ fontSize: "14px" }}>
                      {data?.property?.propertyPolicy?.checkoutWindow}
                    </Typography>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    paddingBottom: "14px",
                  }}
                >
                  <Typography>
                    <Typography sx={{ fontSize: "16px", color: "white" }}>
                      Booking Date
                    </Typography>
                    <Typography sx={{ fontSize: "14px" }}>
                      {moment(data?.createdOn).format("DD MMM YYYY")}
                    </Typography>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    paddingBottom: "14px",
                  }}
                >
                  <Typography>
                    <Typography sx={{ fontSize: "16px", color: "white" }}>
                      Confirmation Code
                    </Typography>
                    <Typography sx={{ fontSize: "14px" }}>
                      {data?.code}
                    </Typography>
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                padding: "25px 0px",
                borderBottom: "0.5px solid #0095ff",
              }}
            >
              <Typography
                sx={{ fontFamily: "Jost", fontSize: "22px", color: "white" }}
              >
                Check-in Instructions
              </Typography>
              <Typography sx={{ fontSize: "14px", color: "white" }}>
                {data?.property?.checkInInstruction
                  ? data?.property?.checkInInstruction
                  : "No instructions"}
              </Typography>
            </Box>
            <Box
              sx={{
                padding: "25px 0px",
                borderBottom: "0.5px solid #0095ff",
              }}
            >
              <Typography
                sx={{ fontFamily: "Jost", fontSize: "22px", color: "white" }}
              >
                Wifi Details
              </Typography>
              <Typography sx={{ fontSize: "14px", color: "white" }}>
                Wifi Username:{" "}
                {data?.property?.wifiUsername
                  ? data?.property?.wifiUsername
                  : "Not provided"}
              </Typography>
              <Typography sx={{ fontSize: "14px", color: "white" }}>
                Wifi Password:{" "}
                {data?.property?.wifiPassword
                  ? data?.property?.wifiPassword
                  : "Not provided"}
              </Typography>
            </Box>
            <Box
              sx={{
                padding: "25px 0px",
                borderBottom: "0.5px solid #0095ff",
              }}
            >
              <Typography
                sx={{ fontFamily: "Jost", fontSize: "22px", color: "white" }}
              >
                Who is coming
              </Typography>
              <Typography sx={{ fontSize: "14px", color: "white" }}>
                {data?.whoIsComing
                  ? data?.whoIsComing
                  : "No Description Provided"}
              </Typography>
            </Box>
            <Box
              sx={{
                padding: "25px 0px",
                borderBottom: "0.5px solid #0095ff",
              }}
            >
              <Typography
                sx={{ fontFamily: "Jost", fontSize: "22px", color: "white" }}
              >
                {"Guest"} Paid
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    sx={{
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    <CleaningServicesIcon
                      sx={{ fontSize: "18px", marginRight: "5px" }}
                    />{" "}
                    Accomodation
                  </Typography>
                  <Typography>
                    {process.env.REACT_APP_CURRENCY}{" "}
                    {data?.totalPrice?.toLocaleString("en-US")}
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    sx={{
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <NightlightRoundIcon
                      sx={{ fontSize: "18px", marginRight: "5px" }}
                    />{" "}
                    Nightly Price x {values?.noOfNights} (nights)
                  </Typography>
                  <Typography>
                    {process.env.REACT_APP_CURRENCY}{" "}
                    {values?.totalNightsCharges?.toLocaleString("en-US")}
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    sx={{
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    <CleaningServicesIcon
                      sx={{ fontSize: "18px", marginRight: "5px" }}
                    />{" "}
                    Cleaning Fee
                  </Typography>
                  <Typography>
                    {process.env.REACT_APP_CURRENCY}{" "}
                    {values?.cleaningFee?.toLocaleString("en-US")}{" "}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <PersonAddIcon
                      sx={{ fontSize: "18px", marginRight: "5px" }}
                    />{" "}
                    Additional Guests Fee
                  </Typography>
                  <Typography>
                    <span style={{ color: "#fff" }}>
                      {values?.totalExtraGuests}
                    </span>{" "}
                    x{" "}
                    <span style={{ color: "#fff" }}>
                      {process.env.REACT_APP_CURRENCY}{" "}
                      {values?.extraGuestFee?.toLocaleString("en-US")}
                    </span>{" "}
                    ={" "}
                    <span style={{ color: "#fff" }}>
                      {" "}
                      {process.env.REACT_APP_CURRENCY}{" "}
                      {values?.totalExtraGuestFee?.toLocaleString("en-US")}
                    </span>
                  </Typography>
                </Box>
                {values?.noOfInfants > 0 ? (
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      sx={{
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <ChildCareIcon
                        sx={{ fontSize: "18px", marginRight: "5px" }}
                      />{" "}
                      Infant Fee
                    </Typography>
                    <Typography>No Fee</Typography>
                  </Box>
                ) : (
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      sx={{
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <ChildCareIcon
                        sx={{ fontSize: "18px", marginRight: "5px" }}
                      />{" "}
                      Infant Fee
                    </Typography>
                    <Typography>Not Allowed</Typography>
                  </Box>
                )}

                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    sx={{
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <PetsIcon sx={{ fontSize: "18px", marginRight: "5px" }} />{" "}
                    Pet Fee
                  </Typography>
                  {values?.noOfPets > 0 ? (
                    <Typography>
                      {values?.noOfPets} x {process.env.REACT_APP_CURRENCY}{" "}
                      {values?.petFee?.toLocaleString("en-US")} ={" "}
                      {values?.totalPetFee?.toLocaleString("en-US")}
                    </Typography>
                  ) : (
                    <Typography>Not Allowed</Typography>
                  )}
                </Box>

                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    sx={{
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <AccountBalanceIcon
                      sx={{ fontSize: "18px", marginRight: "5px" }}
                    />{" "}
                    Service Fee ({commissionFee} %)
                  </Typography>
                  <Typography>
                    {process.env.REACT_APP_CURRENCY}{" "}
                    {values?.totalServiceCharges?.toLocaleString("en-US")}
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    sx={{
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <AccountBalanceIcon
                      sx={{ fontSize: "18px", marginRight: "5px" }}
                    />{" "}
                    GST ({values?.gst} %)
                  </Typography>
                  <Typography>
                    {process.env.REACT_APP_CURRENCY}{" "}
                    {values?.totalGSTFee?.toLocaleString("en-US")}
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: "19px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <DoneOutlineIcon
                      sx={{ fontSize: "19px", marginRight: "5px" }}
                    />{" "}
                    Total ( {process.env.REACT_APP_CURRENCY} )
                  </Typography>
                  <Typography sx={{ fontSize: "18px", color: "#fff" }}>
                    {process.env.REACT_APP_CURRENCY}{" "}
                    {bookingDetail?.grossAmount
                      ? bookingDetail?.grossAmount?.toLocaleString("en-US")
                      : 0}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                padding: "25px 0px",
                borderBottom: "0.5px solid #0095ff",
              }}
            >
              <Typography
                sx={{ fontFamily: "Jost", fontSize: "22px", color: "white" }}
              >
                Host payout
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ fontSize: "14px", color: "white" }}>
                    Total Stay Price
                  </Typography>

                  <Typography style={{ fontSize: "14px" }}>
                    {process.env.REACT_APP_CURRENCY}{" "}
                    {bookingDetail?.grossAmount?.toLocaleString("en-US")}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ fontSize: "14px", color: "white" }}>
                    Staywo Fee
                  </Typography>
                  <Typography style={{ fontSize: "14px" }}>
                    {process.env.REACT_APP_CURRENCY}{" "}
                    {staywoFee.toLocaleString("en-US")}
                    {/* {bookingDetail?.totalServiceFee?.toLocaleString("en-US")} */}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography>
                    <Typography sx={{ fontSize: "14px", color: "white" }}>
                      Total({process.env.REACT_APP_CURRENCY})
                    </Typography>
                  </Typography>
                  <Typography style={{ fontSize: "14px" }}>
                    {process.env.REACT_APP_CURRENCY}{" "}
                    {(bookingDetail?.grossAmount - staywoFee).toLocaleString(
                      "en-US"
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                padding: "25px 0px",
                borderBottom: "0.5px solid #0095ff",
              }}
            >
              <Typography
                sx={{ fontFamily: "Jost", fontSize: "22px", color: "white" }}
              >
                Calendar Note
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <BsLockFill
                    style={{ fontSize: "14px", marginRight: "6px" }}
                  />
                  <div
                    style={{
                      fontSize: "14px",
                      color: "rgba(255, 255, 255, 0.7)",
                    }}
                  >
                    Add a private member for these dates that only can view
                  </div>
                </Box>
              </Typography>
              <textarea
                aria-label="maximum height"
                style={{
                  width: "100%",
                  background: "transparent",
                  fontSize: "14px",
                  borderRadius: "4px",
                  padding: "2px",
                  color: "white",
                }}
                value={formik.values.privateNote}
                name="privateNote"
                onChange={formik.handleChange}
                rows={5}
              />
              {formik.touched.privateNote &&
                Boolean(formik.errors.privateNote) && (
                  <div>{formik.errors.privateNote}</div>
                )}
              <Button
                variant="outlined"
                sx={{
                  fontFamily: "Jost",
                  fontSize: "14px",
                  marginTop: "6px",
                }}
                onClick={formik.handleSubmit}
              >
                Save
              </Button>
            </Box>
            <Box
              sx={{
                padding: "25px 0px",
              }}
            >
              <Typography
                sx={{ fontFamily: "Jost", fontSize: "22px", color: "white" }}
              >
                Support
              </Typography>

              <Box
                sx={{
                  padding: "25px 0px",
                  borderBottom: "0.5px solid #0095ff",
                  borderTop: "0.5px solid #0095ff",
                }}
              >
                <Link
                  to={`/additional-payments/${data?.id}`}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <BsCash
                      style={{
                        marginRight: "16px",
                        fontSize: "16px",
                        color: "#fff",
                      }}
                    />
                    <div style={{ fontSize: "14px", color: "white" }}>
                      Send or request money hello
                    </div>
                  </Box>
                  <AiOutlineRight style={{ fontSize: "14px", color: "#fff" }} />
                </Link>
              </Box>
              {/* <Box
                sx={{
                  padding: '25px 0px',
                  borderBottom: '0.5px solid #0095ff',
                  borderTop: '0.5px solid #0095ff',
                  fontSize: '16px',
                }}
              >
                User Reported
              </Box> */}
              {/* <Box
                sx={{
                  padding: '25px 0px',
                  borderBottom: '0.5px solid #0095ff',
                  borderTop: '0.5px solid #0095ff',
                }}
              >
                <Link
                  to='/'
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <IoIosHelpCircle
                      style={{
                        marginRight: '16px',
                        fontSize: '18px',
                        color: '#fff',
                      }}
                    />
                    <div style={{ fontSize: '14px', color: 'white' }}>
                      Get Help
                    </div>
                  </Box>
                  <AiOutlineRight style={{ fontSize: '14px', color: '#fff' }} />
                </Link>
              </Box> */}
              <Box
                sx={{
                  padding: "25px 0px",
                  borderBottom: "0.5px solid #0095ff",
                  borderTop: "0.5px solid #0095ff",
                }}
              >
                <Link
                  state={{ booking: data }}
                  to={`/admin/rate-guest/${data?.guest?.id}/${data?.property?.id}/${data?.id}?guest=${data?.guest?.firstName}`}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <AiFillStar
                      style={{
                        color: "#ff2400",
                        fontSize: "14px",
                        marginRight: "4px",
                      }}
                    />
                    <div style={{ fontSize: "14px", color: "white" }}>
                      Rate the guest
                    </div>
                  </Box>
                  <AiOutlineRight style={{ fontSize: "14px", color: "#fff" }} />
                </Link>
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Box>
    </>
  );
};

export default ReservationDetailCardForBookingCalendar;
