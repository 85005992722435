import { API_URL } from "../../config";
import axios from "axios";

export const getAmenitiesGroupApi = async () => {
  try {
    const response = await axios.get(API_URL + "amenity-group/all");
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log("Bad Response of ammentiy : ", response);
      return response;
    }
  }
};


export const getAmenitiesByPropertyId = async (id) => {
  try {
    const response = await axios.get(API_URL + `property/amenities/${id}`);
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log("Bad Response of ammentiy : ", response);
      return response;
    }
  }
};
