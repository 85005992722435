import { API_URL } from '../../config';
import axios from 'axios';
const getBookingOwnerAPI = () => {
  // Get User Information from localStorage
  const userData = JSON.parse(localStorage.getItem('userData')) || null;
  const getAcceptedData = async (page = 0, status = 'accepted') => {
    try {
      const response = await axios.get(
        API_URL +
        `booking/all?ownerId=${userData.id}&page=${page}&bookingStatus=${status}`
      );
      return response;
    } catch (error) {
      if (error) {
        const { response } = error;
        console.log('Bad Response of booking : ', response);
        return response;
      }
    }
  };

  // Pending status data get
  const getPendingData = async (page = 0, status = 'pending') => {
    try {
      const response = await axios.get(
        API_URL +
        `booking/all?ownerId=${userData.id}&page=${page}&bookingStatus=${status}`
      );
      return response;
    } catch (error) {
      if (error) {
        const { response } = error;
        console.log('Good Response of booking : ', response);
        return response;
      }
    }
  };
  // Completed status data get
  const getCompletedData = async (page = 0, status = 'completed') => {
    try {
      const response = await axios.get(
        API_URL +
        `booking/all?ownerId=${userData.id}&page=${page}&bookingStatus=${status}`
      );
      return response;
    } catch (error) {
      if (error) {
        const { response } = error;
        console.log('Bad Response of booking : ', response);
        return response;
      }
    }
  };
  // Confirmed status data get
  const getConfirmedData = async (page = 0, status = 'confirmed') => {
    try {
      const response = await axios.get(
        API_URL +
        `booking/all?ownerId=${userData.id}&page=${page}&bookingStatus=${status}`
      );
      return response;
    } catch (error) {
      if (error) {
        const { response } = error;
        console.log('Bad Response of booking : ', response);
        return response;
      }
    }
  };
  // Ongoing status data get
  const getCurrentdData = async (page = 0, status = 'accepted') => {
    try {
      const response = await axios.get(
        API_URL +
        `booking/all?guestId=${userData.id}&page=${page}&bookingStatus=${status}`
      );
      return response;
    } catch (error) {
      if (error) {
        const { response } = error;
        console.log('Bad Response of booking : ', response);
        return response;
      }
    }
  };
  // Get cancellation Data
  const getCancellationData = async (id) => {
    try {
      const response = await axios.get(
        API_URL + `booking-cancellation/booking/${id}`
      );
      return response;
    } catch (error) {
      if (error) {
        const { response } = error;
        console.log('Bad Response of booking : ', response);
        return response;
      }
    }
  };

    // Get cancellation Data
    const getCancellationDataUpdate = async (cancelID, data) => {
      try {
        const response = await axios.patch(
          API_URL + `booking-cancellation/${cancelID}`, + data
        );
        return response;
      } catch (error) {
        if (error) {
          const { response } = error;
          console.log('Bad Response of booking : ', response);
          return response;
        }
      }
    };

  return {
    getAcceptedData,
    getPendingData,
    getCompletedData,
    getConfirmedData,
    getCurrentdData,
    getCancellationData,
    getCancellationDataUpdate
  };
};
export default getBookingOwnerAPI;

// export const getBookingOwnerApi = async (page = 1, status = 'pending') => {
//   const userData = JSON.parse(localStorage.getItem('userData')) || null

//   try {
//     const response = await axios.get(
//       API_URL +
//       `/booking/owner?owner_id=${userData.id}&page=${page}&status=${status}`
//     )
//     return response
//   } catch (error) {
//     if (error) {
//       const { response } = error
//       console.log('Bad Response of booking : ', response)
//       return response
//     }
//   }
// }
