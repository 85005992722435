import React from 'react';
import Grid from '@mui/material/Grid';
import Input from '../../../../components/form/input';
import Card from '../../../../components/dasboard/card';
import Typography from '@mui/material/Typography';
import Select from 'react-select';
const Details = ({
  formik,
  inputValues,
  handleChangeInput,
  validationType,
  setInputValues,
}) => {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: 'black',
      fontSize: '16px',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      const color = '#fff';
      return { ...provided, opacity, transition, color };
    },
  };
  return (
    <>
      <Card withIcon title={'Number of Guest'} icon={'fa fa-users'}>
        <Grid
          container
          rowSpacing={{ xs: 1, sm: 1, md: 2 }}
          columnSpacing={{ xs: 1, sm: 1, md: 2 }}
        >
          <Grid item xs={12} sm={12} md={4}>
            <Typography>Adults</Typography>
            <Input
              id={'noOfAdults'}
              name={'noOfAdults'}
              type={'number'}
              label={'Adults'}
              value={inputValues.noOfAdults}
              onChange={handleChangeInput}
              defaultValue={inputValues.noOfAdults}
              minLength={1}
              min={0}
              step={0}
            />
            {validationType.open && validationType.type == 'adults' && (
              <p style={{ color: 'red' }}>{validationType.message}</p>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Typography>Children</Typography>
            <Input
              id={'noOfChildren'}
              type={'number'}
              name={'noOfChildren'}
              label={'Children'}
              onChange={handleChangeInput}
              value={inputValues.noOfChildren}
              defaultValue={inputValues.noOfChildren}
              minLength={1}
              min={0}
              step={0}
            />
            {validationType.open && validationType.type == 'children' && (
              <p style={{ color: 'red' }}>{validationType.message}</p>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Typography>Infants</Typography>
            <Input
              id={'noOfInfants'}
              type={'number'}
              name={'noOfInfants'}
              label={'Infants'}
              onChange={handleChangeInput}
              value={inputValues.noOfInfants}
              minLength={1}
              min={0}
              step={0}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Typography>Pets</Typography>
            <Input
              id={'noOfPets'}
              type={'number'}
              name={'noOfPets'}
              label={'Pets'}
              onChange={handleChangeInput}
              value={inputValues.noOfPets}
              defaultValue={inputValues.noOfPets}
              minLength={1}
              min={0}
              step={0}
            />
          </Grid>
        </Grid>
      </Card>
      <Card withIcon title={'Property Detail'} icon={'fa fa-list-ul'}>
        <Grid
          container
          rowSpacing={{ xs: 1, sm: 1, md: 2 }}
          columnSpacing={{ xs: 1, sm: 1, md: 2 }}
        >
          <Grid item xs={12} sm={12} md={4}>
            <Typography>Bedrooms</Typography>
            <Input
              type={'number'}
              id={'noOfBedrooms'}
              name={'noOfBedrooms'}
              label={'Bedrooms'}
              onChange={handleChangeInput}
              value={inputValues.noOfBedrooms}
              defaultValue={formik.values.noOfBedrooms}
              minLength={1}
              min={0}
              step={0}
            />
            {validationType.open && validationType.type == 'bedrooms' && (
              <p style={{ color: 'red' }}>{validationType.message}</p>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Typography>Bathroom</Typography>
            <Input
              type={'number'}
              id={'noOfBathrooms'}
              name={'noOfBathrooms'}
              label={'Bathroom'}
              onChange={(e) => {
                if (e.target.value <= 50) {
                  setInputValues({
                    ...inputValues,
                    noOfBathrooms: e.target.value,
                  });
                }
              }}
              value={inputValues?.noOfBathrooms}
              defaultValue={inputValues.noOfBathrooms}
              min={1}
              step={0.5}
            />
            {validationType.open && validationType.type == 'bathrooms' && (
              <p style={{ color: 'red' }}>{validationType.message}</p>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Typography>Beds</Typography>
            <Input
              id={'noOfBeds'}
              name={'noOfBeds'}
              label={'Beds'}
              type={'number'}
              value={inputValues.noOfBeds}
              onChange={handleChangeInput}
              defaultValue={inputValues.noOfBeds}
              minLength={1}
              min={0}
              step={0}
            />
            {validationType.open && validationType.type == 'beds' && (
              <p style={{ color: 'red' }}>{validationType.message}</p>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Typography>Private Bathroom</Typography>
            <Select
              className='override-select-edit'
              placeholder='Yes/No'
              options={[
                { label: 'Yes', value: true },
                {
                  label: 'No',
                  value: false,
                },
              ]}
              value={inputValues.isPrivateBathroom}
              onChange={(e) =>
                setInputValues({ ...inputValues, isPrivateBathroom: e })
              }
              styles={customStyles}
            />
            {inputValues.isPrivateBathroom.value === '' && validationType.open && validationType.type == 'isBathroom' && (
              <p style={{ color: 'red' }}>{validationType.message}</p>
            )}
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default Details;
