import { API_URL } from '../../config';
import axios from 'axios';

export const actionBookingUpdateRequest = async (action, id) => {
  try {
    const response = await axios.patch(
      API_URL + `booking-update-request/${id}/status`,
      action
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log(
        'Error Response of action for booking update request  : ',
        response
      );
      return response;
    }
  }
};
