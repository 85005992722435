import { Grid, Box, Typography, Button } from '@mui/material';
import React, { useState } from 'react';
import { Chart } from 'primereact/chart';
import { getPropertyStatsApi } from '../../services/properties/getPropertyStats';
import moment from 'moment';
import Select from 'react-select';
import { AiFillCreditCard } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

const Earning = ({ propertyDetail }) => {
  const [selectedMonth, setSelectedMonth] = useState({});
  const [graphData, setGraphData] = useState([]);
  const [stats, setStats] = useState({});
  const [year, setYear] = useState({
    value: moment().format('YYYY'),
    label: moment().format('YYYY'),
  });
  const monthsData = [
    {
      month: '01',
      label: 'January',
      value: '01',
    },
    {
      month: '02',
      label: 'February',
      value: '02',
    },
    {
      month: '03',
      label: 'March',
      value: '03',
    },
    {
      month: '04',
      label: 'April',
      value: '04',
    },
    {
      month: '05',
      label: 'May',
      value: '05',
    },
    {
      month: '06',
      label: 'June',
      value: '06',
    },
    {
      month: '07',
      label: 'July',
      value: '07',
    },
    {
      month: '08',
      label: 'August',
      value: '08',
    },
    {
      month: '09',
      label: 'September',
      value: '09',
    },
    {
      month: '10',
      label: 'October',
      value: '10',
    },

    {
      month: '11',
      label: 'November',
      value: '11',
    },
    {
      month: '12',
      label: 'December',
      value: '12',
    },
  ];
  const yearsData = [
    {
      value: moment().format('YYYY'),
      label: moment().format('YYYY'),
    },
    {
      value: moment().add(1, 'year').format('YYYY'),
      label: moment().add(1, 'year').format('YYYY'),
    },
    {
      value: moment().add(2, 'year').format('YYYY'),
      label: moment().add(2, 'year').format('YYYY'),
    },
  ];
  React.useEffect(() => {
    const getStats = async () => {
      const res = await getPropertyStatsApi(
        propertyDetail?.id,
        year.value,
        selectedMonth?.month
      );
      setStats(res.data);
    };
    if (selectedMonth.month && year.value) {
      getStats();
    }
  }, [selectedMonth, propertyDetail?.id, year]);

  const basicData = {
    labels: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'June',
      'July',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    datasets: [
      {
        label: 'Earnings',
        data: [...graphData],
        fill: true,
        borderColor: '#3270fc',
        tension: 0.2,
        backgroundColor: '#0095ff',
      },
    ],
  };

  const getLightTheme = () => {
    let basicOptions = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {
          labels: {
            color: '#fff',
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: '#fff',
          },
          grid: {
            color: '#3c3949',
          },
        },
        y: {
          ticks: {
            color: '#fff',
            callback: function (value, index, ticks) {
              return `${process.env.REACT_APP_CURRENCY}` + value;
            },
          },
          grid: {
            color: '#0095ff',
          },
        },
      },
    };
    return {
      basicOptions,
    };
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: 'black',
      fontSize: '16px',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      const color = '#fff';
      return { ...provided, opacity, transition, color };
    },
  };
  const { basicOptions } = getLightTheme();

  React.useEffect(() => {
    if (Object.keys(stats).length > 0) {
      if (selectedMonth?.month === '01') {
        setGraphData([stats?.earning, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
      } else if (selectedMonth?.month === '02') {
        setGraphData([0, stats?.earning, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
      } else if (selectedMonth?.month === '03') {
        setGraphData([0, 0, stats?.earning, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
      } else if (selectedMonth?.month === '04') {
        setGraphData([0, 0, 0, stats?.earning, 0, 0, 0, 0, 0, 0, 0, 0]);
      } else if (selectedMonth?.month === '05') {
        setGraphData([0, 0, 0, 0, stats?.earning, 0, 0, 0, 0, 0, 0, 0]);
      } else if (selectedMonth?.month === '06') {
        setGraphData([0, 0, 0, 0, 0, stats?.earning, 0, 0, 0, 0, 0, 0]);
      } else if (selectedMonth?.month === '07') {
        setGraphData([0, 0, 0, 0, 0, 0, stats?.earning, 0, 0, 0, 0, 0]);
      } else if (selectedMonth?.month === '08') {
        setGraphData([0, 0, 0, 0, 0, 0, 0, stats?.earning, 0, 0, 0, 0]);
      } else if (selectedMonth?.month === '09') {
        setGraphData([0, 0, 0, 0, 0, 0, 0, 0, stats?.earning, 0, 0, 0]);
      } else if (selectedMonth?.month === '10') {
        setGraphData([0, 0, 0, 0, 0, 0, 0, 0, 0, stats?.earning, 0, 0]);
      } else if (selectedMonth?.month === '11') {
        setGraphData([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, stats?.earning, 0]);
      } else if (selectedMonth?.month === '12') {
        setGraphData([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, stats?.earning]);
      }
    }
  }, [selectedMonth, stats]);

  React.useEffect(() => {
    setSelectedMonth({
      value: `${moment().format('MM')}`,
      month: `${moment().format('MM')}`,
      label: `${moment().format('MMMM')}`,
    });
  }, []);

  const navigate = useNavigate();
  return (
    <Grid
      container
      sx={{ mb: 4 }}
      spacing={{ xs: 2, md: 3 }}
      columns={{ xs: 4, sm: 8, md: 12 }}
    >
      <Grid item xs={12} md={4} lg={4} sm={12}>
        <Typography sx={{ fontSize: '18px', color: '#fff' }}>
          Select Month
        </Typography>
        <Select
          className='override-select-edit'
          placeholder='Select Month'
          options={monthsData}
          value={selectedMonth}
          onChange={(e) => setSelectedMonth(e)}
          styles={customStyles}
        />
        <Typography sx={{ fontSize: '18px', color: '#fff' }}>
          Select Year
        </Typography>
        <Select
          className='override-select-edit'
          placeholder='Select Year'
          options={yearsData}
          value={year}
          onChange={(e) => setYear(e)}
          styles={customStyles}
        />

        <div className='clearfix'></div>
        <Box sx={{ pl: 1, my: 2 }}>
          <Typography sx={{ fontSize: '18px', color: '#fff' }}>
            {process.env.REACT_APP_CURRENCY}{' '}
            {stats?.earning?.toLocaleString('en-US') || 0}
          </Typography>
          <Typography sx={{ fontSize: '18px' }}>
            Booked earnings for {selectedMonth?.label}-{year?.value}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            pl: 1,
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Typography sx={{ fontSize: '18px', color: '#fff' }}>
              {process.env.REACT_APP_CURRENCY}{' '}
              {stats?.payout?.toLocaleString('en-US') || 0}
            </Typography>
            <Typography sx={{ fontSize: '18px' }}>Paid out</Typography>
          </Box>
          <Box>
            <Typography sx={{ fontSize: '18px', color: '#fff' }}>
              {process.env.REACT_APP_CURRENCY}{' '}
              {stats?.expectedPayout?.toLocaleString('en-US') || 0}
            </Typography>
            <Typography sx={{ fontSize: '18px' }}>Expected</Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={12} lg={12} sm={12}>
        <Chart type='bar' data={basicData} options={basicOptions} />
      </Grid>
      <Grid item xs={12} md={12} lg={12} sm={12}>
        <ul>
          <li
            className='lists'
            style={{
              display: 'flex',
              padding: '10px',
              alignItems: 'center',
              justifyContent: 'space-between',
              color: '#fff',
              borderBottom: '1px solid #292e3a',
            }}
          >
            <h6>Night Booked</h6>
            <h6>{stats?.nightsBooked || 0}</h6>
          </li>
          <li
            style={{
              display: 'flex',
              padding: '10px',
              alignItems: 'center',
              justifyContent: 'space-between',
              color: '#fff',
              borderBottom: '1px solid #292e3a',
            }}
          >
            <h6>Occupancy rate</h6>
            <h6>{parseInt(stats?.occupancyRate).toFixed(2) || 0}%</h6>
          </li>
          <li
            style={{
              display: 'flex',
              padding: '10px',
              alignItems: 'center',
              justifyContent: 'space-between',
              color: '#fff',
              borderBottom: '1px solid #292e3a',
            }}
          >
            <h6>Cleaning fees</h6>
            <h6>
              {' '}
              {process.env.REACT_APP_CURRENCY} {stats?.cleaningFee || 0}
            </h6>
          </li>
          <li
            style={{
              display: 'flex',
              padding: '10px',
              alignItems: 'center',
              justifyContent: 'space-between',
              color: '#fff',
              borderBottom: '1px solid #292e3a',
            }}
          >
            <h6>Cancellation fees</h6>
            <h6>
              {' '}
              {process.env.REACT_APP_CURRENCY} {stats?.cancellationFee || 0}
            </h6>
          </li>
          <Button
            variant='contained'
            sx={{
              background: '#0095ff',
              display: 'flex',
              alignItems: 'center',
              marginRight: '10px',
              margin: '10px 0px',
            }}
            onClick={() => navigate(`/admin/vendor-withdrawal-history`)}
          >
            <AiFillCreditCard
              style={{ fontSize: '20px', marginRight: '6px' }}
            />
            Transaction History
          </Button>
        </ul>
      </Grid>
    </Grid>
  );
};

export default Earning;
