import * as yup from 'yup';

export const PoliciesValidationSchema = yup.object({
  // cancellationPolicy: yup.string(),
  instantBooking: yup.boolean(),
  governmentIdRequired: yup.boolean(),
  guestPhotoRequirement: yup.boolean(),
  // checkInWindow: yup.string().required('Check-in window is required'),
  // checkoutWindow: yup.string().required('Check-out window is required'),
});
