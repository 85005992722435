import {
  Divider,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Button,
} from '@mui/material';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/system';
import React, { useRef, useState } from 'react';
import Buttons from '../../../../components/form/button';
import { MdClose } from 'react-icons/md';
import Select from 'react-select';
import personImage from '../../../../assets/images/calendar-availability-person.png';
import na from '../../../../assets/images/na.jpg';
import moment from 'moment';
import { useFormik } from 'formik';
import { getPropertyRestrictedDaysApi } from '../../../../services/RestrictedDays/getPropertyRestrictedDays';
import { useParams } from 'react-router-dom';
import { updatePropertyRestrictedDays } from '../../../../services/RestrictedDays/updatePropertyRestrictedDays';
import { useSnackbar } from 'notistack';
import PricingModal from '../../../../components/Pricingmodal/PricingModal';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import CloseIcon from '@mui/icons-material/Close';
import { Toast } from 'primereact/toast';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  color: 'text.primary',
  backgroundColor: '#1b182b',
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
};
const Pricing = ({ formik, isButtonLoading, propertyDetail }) => {
  const toast = useRef();

  const [editAdvanceNotice, setEditAdvanceNotice] = React.useState(false);
  const [editPreparationTime, setEditPreparationTime] = React.useState(false);
  const [restrictedDays, setRestrictedDayes] = React.useState({});
  const [editAvailabilityWindow, setEditAvailabilityWindow] =
    React.useState(false);
  const [editRestrictedDays, setEditRestrictedDays] = React.useState(false);

  const [checked, setChecked] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };
  const [userCards, setUserCards] = useState([]);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName='.Mui-focusVisible'
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor:
            theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? 'white' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: 'black',
      fontSize: '16px',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      const color = '#fff';
      return { ...provided, opacity, transition, color };
    },
  };

  const advanceNoticeOptions = [
    {
      value: 'Same day (customizable cutoff hour)',
      label: 'Same day (customizable cutoff hour)',
    },
    { value: "At least one day's notice", label: "At least one day's notice" },
    { value: "At least two days' notice", label: "At least two days' notice" },
    {
      value: "At least three days' notice",
      label: "At least three days' notice",
    },
    {
      value: "At least seven days' notice",
      label: "At least seven days' notice",
    },
  ];
  const preparationTimeOptions = [
    { value: 'None', label: 'None' },
    {
      value: 'Book one night before and after each reservation.',
      label: 'Book one night before and after each reservation.',
    },
    {
      value: 'Book two nights before and after each reservation.',
      label: 'Book two nights before and after each reservation.',
    },
  ];
  const availabilityWindowOptions = [
    { value: 'All Future Dates', label: 'All Future Dates' },
    { value: '12 months in advance', label: '12 months in advance' },
    { value: '9 months in advance', label: '9 months in advance' },
    { value: '6 months in advance', label: '6 months in advance' },
    { value: '3 months in advance', label: '3 months in advance' },
    { value: 'Dates Unavailable', label: 'Dates Unavailable' },
  ];

  const TIMINGS = [
    { value: '1 AM', label: '1 AM' },
    { value: '2 AM', label: '2 AM' },
    { value: '3 AM', label: '3 AM' },
    { value: '4 AM', label: '4 AM' },
    { value: '5 AM', label: '5 AM' },
    { value: '6 AM', label: '6 AM' },
    { value: '7 AM', label: '7 AM' },
    { value: '8 AM', label: '8 AM' },
    { value: '9 AM', label: '9 AM' },
    { value: '10 AM', label: '10 AM' },
    { value: '11 AM', label: '11 AM' },
    { value: '12 AM', label: '12 AM' },
    { value: '1 PM', label: '1 PM' },
    { value: '2 PM', label: '2 PM' },
    { value: '3 PM', label: '3 PM' },
    { value: '4 PM', label: '4 PM' },
    { value: '5 PM', label: '5 PM' },
    { value: '6 PM', label: '6 PM' },
    { value: '7 PM', label: '7 PM' },
    { value: '8 PM', label: '8 PM' },
    { value: '9 PM', label: '9 PM' },
    { value: '10 PM', label: '10 PM' },
    { value: '11 PM', label: '11 PM' },
    { value: '12 PM', label: '12 PM' },
  ];

  const { id } = useParams();
  React.useEffect(() => {
    const getPropertyRestrictedDays = async () => {
      const restrictedDayz = await getPropertyRestrictedDaysApi(id);
      setRestrictedDayes(restrictedDayz?.data?.data);
    };
    getPropertyRestrictedDays();
  }, [id]);
  const { enqueueSnackbar } = useSnackbar();

  const restrictedDaysFormik = useFormik({
    initialValues: {
      propertyId: parseInt(id),
      monday: restrictedDays?.monday,
      tuesday: restrictedDays?.tuesday,
      wednesday: restrictedDays?.wednesday,
      thursday: restrictedDays?.thursday,
      friday: restrictedDays?.friday,
      saturday: restrictedDays?.saturday,
      sunday: restrictedDays?.sunday,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      let res = await updatePropertyRestrictedDays(id, values);
      if (res.status === 200) {
        setEditRestrictedDays(false);
        enqueueSnackbar('Property restricted days updated successfully!', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      } else {
        enqueueSnackbar('Error: Cannot update property restricted days!', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      }
    },
  });
  if (formik.values.weeklyNightPrice > 75) {
    toast.current.show({
      severity: 'error',
      summary: 'weekly Discount is Less than 75!',
      life: 10000,
    });
    formik.values.weeklyNightPrice = 0;
  }
  if (formik.values.monthlyNightPrice > 75) {
    toast.current.show({
      severity: 'error',
      summary: 'Monthly Discount is Less than 75!',
      life: 10000,
    });
    formik.values.monthlyNightPrice = 0;
  }
  const handleCustomFee = () => {
    handleClose();
    setChecked(false);
  };
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Button onClick={handleCustomFee} sx={{ display: 'block' }}>
            {' '}
            <CloseIcon />{' '}
          </Button>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Enter A Custom Fee
          </Typography>
          <TextField placeholder='Custom Fee' />
          <Grid container sx={{ marginTop: 2, justifyContent: 'center' }}>
            <Button variant='contained' onClick={handleCustomFee}>
              Save
            </Button>
          </Grid>
        </Box>
      </Modal>
      <Toast ref={toast} position='bottom-right' />

      <div className='dasboard-widget-title fl-wrap'>
        <h5>
          <i className></i>
          Pricing
        </h5>
      </div>

      <div className='dasboard-widget-box fl-wrap'>
        <Typography
          variant={'h6'}
          sx={{ paddingBottom: 3, textAlign: 'initial' }}
        >
          Pricing
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={10}>
            <p>Preview what guests pay</p>
          </Grid>
          <Grid item xs={2}>
            <PricingModal propertyId={id} propertyDetail={propertyDetail} />
          </Grid>
        </Grid>
        <Divider sx={{ paddingTop: 3, marginBottom: 5 }} />
        <div>
          <Typography
            variant={'h6'}
            sx={{ paddingBottom: 3, textAlign: 'initial' }}
          >
            Pricing Configuration
          </Typography>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 1, md: 2 }}
            columnSpacing={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={12} sm={12} md={6}>
              <Typography>Per Night Price</Typography>
              <Typography style={{ fontSize: 13 }}>
                No discounts apply to reservations that are less than 7 days{' '}
              </Typography>

              <TextField
                variant='outlined'
                fullWidth
                type='number'
                name='perNightPrice'
                value={formik.values.perNightPrice}
                onChange={formik.handleChange}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 15);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Typography style={{ paddingBottom: 0 }}>
                        {process.env.REACT_APP_CURRENCY}
                      </Typography>
                    </InputAdornment>
                  ),
                }}
                defaultValue={formik.values.perNightPrice}
                error={
                  formik.touched.perNightPrice &&
                  Boolean(formik.errors.perNightPrice)
                }
                helperText={
                  formik.touched.perNightPrice && formik.errors.perNightPrice
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Typography>Weekly Night discount</Typography>
              <Typography style={{ fontSize: 13 }}>
                Weekly discounts apply to reservations that are 7 days or
                longer.{' '}
              </Typography>

              <TextField
                variant='outlined'
                fullWidth
                type='number'
                name='weeklyNightPrice'
                value={formik.values.weeklyNightPrice}
                onChange={formik.handleChange}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 15);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Typography style={{ paddingBottom: 0 }}>
                        {'%'}
                      </Typography>
                    </InputAdornment>
                  ),
                }}
                defaultValue={formik.values.weeklyNightPrice}
                error={
                  formik.touched.weeklyNightPrice &&
                  Boolean(formik.errors.weeklyNightPrice)
                }
                helperText={
                  formik.touched.weeklyNightPrice &&
                  formik.errors.weeklyNightPrice
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Typography>Monthly Night discount</Typography>
              <Typography style={{ fontSize: 13 }}>
                Monthly discounts apply to reservations that are 28 days or
                longer.
              </Typography>

              <TextField
                variant='outlined'
                fullWidth
                type='number'
                name='monthlyNightPrice'
                value={formik.values.monthlyNightPrice}
                onChange={formik.handleChange}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 15);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Typography style={{ paddingBottom: 0 }}>
                        {'%'}
                      </Typography>
                    </InputAdornment>
                  ),
                }}
                defaultValue={formik.values.monthlyNightPrice}
                error={
                  formik.touched.monthlyNightPrice &&
                  Boolean(formik.errors.monthlyNightPrice)
                }
                helperText={
                  formik.touched.monthlyNightPrice &&
                  formik.errors.monthlyNightPrice
                }
              />
            </Grid>
          </Grid>
          <Divider sx={{ paddingTop: 3 }} />
          <Typography
            variant={'h6'}
            sx={{ paddingBottom: 3, paddingTop: 3, textAlign: 'initial' }}
          >
            Additional Charges
          </Typography>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 1, md: 2 }}
            columnSpacing={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={12} sm={12} md={6}>
              <Typography>Pet Fee</Typography>
              {
                propertyDetail.propertyCapacity.noOfPets === 0 ?
                  <Typography style={{ fontSize: 13 }}>
                    No pets allowed Select Pets First
                  </Typography> :


                  <TextField
                    variant='outlined'
                    fullWidth
                    type='number'
                    name='petFee'
                    value={formik.values.petFee}
                    onChange={formik.handleChange}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 15);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <Typography style={{ paddingBottom: 0 }}>
                            {process.env.REACT_APP_CURRENCY}
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                    defaultValue={formik.values.petFee}
                    error={formik.touched.petFee && Boolean(formik.errors.petFee)}
                    helperText={formik.touched.petFee && formik.errors.petFee}
                  />
              }

            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Typography>Extra Guest Fee</Typography>
              <TextField
                variant='outlined'
                fullWidth
                type='number'
                name='extraGuestFee'
                value={formik.values.extraGuestFee}
                onChange={formik.handleChange}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 15);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Typography style={{ paddingBottom: 0 }}>
                        {process.env.REACT_APP_CURRENCY}
                      </Typography>
                    </InputAdornment>
                  ),
                }}
                defaultValue={formik.values.extraGuestFee}
                error={
                  formik.touched.extraGuestFee &&
                  Boolean(formik.errors.extraGuestFee)
                }
                helperText={
                  formik.touched.extraGuestFee && formik.errors.extraGuestFee
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Typography>Extra Guest Fee After (No Of Guests)</Typography>
              <TextField
                variant='outlined'
                fullWidth
                type='number'
                name='extraGuestFeeAfter'
                value={formik.values.extraGuestFeeAfter}
                onChange={formik.handleChange}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 15);
                }}
                defaultValue={formik.values.extraGuestFeeAfter}
                error={
                  formik.touched.extraGuestFeeAfter &&
                  Boolean(formik.errors.extraGuestFeeAfter)
                }
                helperText={
                  formik.touched.extraGuestFeeAfter &&
                  formik.errors.extraGuestFeeAfter
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Typography>Weekend Night Price</Typography>
              <TextField
                variant='outlined'
                fullWidth
                type='number'
                name='weekendNightPrice'
                value={formik.values.weekendNightPrice}
                onChange={formik.handleChange}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 15);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Typography style={{ paddingBottom: 0 }}>
                        {process.env.REACT_APP_CURRENCY}
                      </Typography>
                    </InputAdornment>
                  ),
                }}
                defaultValue={formik.values.weekendNightPrice}
                error={
                  formik.touched.weekendNightPrice &&
                  Boolean(formik.errors.weekendNightPrice)
                }
                helperText={
                  formik.touched.weekendNightPrice &&
                  formik.errors.weekendNightPrice
                }
              />
            </Grid>
          </Grid>
          <Divider sx={{ paddingTop: 3 }} />
          <Typography
            variant={'h6'}
            sx={{ paddingBottom: 3, paddingTop: 3, textAlign: 'initial' }}
          >
            Cleaning Fees
          </Typography>

          <Grid
            container
            rowSpacing={{ xs: 1, sm: 1, md: 2 }}
            columnSpacing={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={12} sm={12} md={12}>
              <Typography>Cleaning Fee</Typography>
              <Typography style={{ fontSize: 13 }}>
                Set a fee to cover cleaning after each stay
              </Typography>

              <TextField
                variant='outlined'
                fullWidth
                type='number'
                name='cleaningFee'
                value={formik.values.cleaningFee}
                onChange={formik.handleChange}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 15);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Typography style={{ paddingBottom: 0 }}>
                        {process.env.REACT_APP_CURRENCY}
                      </Typography>
                    </InputAdornment>
                  ),
                }}
                defaultValue={formik.values.cleaningFee}
                error={
                  formik.touched.cleaningFee &&
                  Boolean(formik.errors.cleaningFee)
                }
                helperText={
                  formik.touched.cleaningFee && formik.errors.cleaningFee
                }
              />
            </Grid>
            {/* <Grid item xs={12} sm={12} md={10} style={{ marginTop: 10 }}>
              <Typography>Custom cleaning Fees for Longer Stay</Typography>
            </Grid> */}
            {/* cleaning fee for longer stay */}
            {/* <FormControlLabel
              control={
                <IOSSwitch
                  sx={{ m: 1 }}
                  checked={checked}
                  onChange={handleChange}
                  onClick={handleOpen}
                />
              }
              style={{ marginTop: 13 }}
            /> */}
          </Grid>
          <Divider sx={{ paddingTop: 3 }} />
          <Typography
            variant={'h6'}
            sx={{ paddingTop: 3, textAlign: 'initial' }}
          >
            Trip Length
          </Typography>
          <Typography style={{ fontSize: 13 }}>
            Set trip length and check-in day requirements for specific dates.
          </Typography>

          <Grid
            container
            rowSpacing={{ xs: 1, sm: 1, md: 2 }}
            columnSpacing={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={12} sm={12} md={6}>
              <Typography>Minimum Stay</Typography>
              <TextField
                variant='outlined'
                fullWidth
                type='number'
                name='minimumStay'
                value={formik.values.minimumStay}
                onChange={formik.handleChange}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 15);
                }}
                defaultValue={formik.values.minimumStay}
                error={
                  formik.touched.minimumStay &&
                  Boolean(formik.errors.minimumStay)
                }
                helperText={
                  formik.touched.minimumStay && formik.errors.minimumStay
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Typography>Maximum Stay</Typography>
              <TextField
                variant='outlined'
                fullWidth
                type='number'
                name='maximumStay'
                value={formik.values.maximumStay}
                onChange={formik.handleChange}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 15);
                }}
                defaultValue={formik.values.maximumStay}
                error={
                  formik.touched.maximumStay &&
                  Boolean(formik.errors.maximumStay)
                }
                helperText={
                  formik.touched.maximumStay && formik.errors.maximumStay
                }
              />
            </Grid>
          </Grid>
          <Divider sx={{ paddingTop: 3, marginBottom: 3 }} />
          <Typography
            variant={'h6'}
            sx={{ paddingBottom: 3, paddingTop: 3, textAlign: 'initial' }}
          >
            Calendar Availabaility
          </Typography>
          {/* Advance notice  */}
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 1, md: 2 }}
            columnSpacing={{ xs: 1, sm: 1, md: 2 }}
            sx={{ margin: '20px 0px' }}
          >
            {!editAdvanceNotice ? (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <Box>
                  <Typography sx={{ color: '#fff', fontSize: '18px' }}>
                    Advance Notice
                  </Typography>
                  {formik.values.advanceNotice?.label ===
                    'Same day (customizable cutoff hour)' ? (
                    <p>
                      Same day, until {formik.values.sameDayCheckoutTime?.label}
                    </p>
                  ) : (
                    <p>{formik.values.advanceNotice?.label}</p>
                  )}
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: '18px',
                      textDecoration: 'underline',
                      color: '#fff',
                      cursor: 'pointer',
                    }}
                    onClick={() => setEditAdvanceNotice(!editAdvanceNotice)}
                  >
                    Edit
                  </Typography>
                </Box>
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                sx={{ border: '1px solid gray', borderRadius: '8px' }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'end',
                    justifyContent: 'end',
                  }}
                >
                  <MdClose
                    style={{
                      fontSize: '22px',
                      color: '#fff',
                      cursor: 'pointer',
                      fontWeight: 'bolder',
                      margin: 3,
                    }}
                    onClick={() => setEditAdvanceNotice(!editAdvanceNotice)}
                  />
                </Box>
                <h4 style={{ textAlign: 'start' }}>Advance Notice</h4>
                <Grid item xs={12} sm={12} md={8}>
                  <Select
                    className='override-select-edit'
                    options={advanceNoticeOptions}
                    value={formik.values.advanceNotice}
                    onChange={(e) => {
                      formik.setFieldValue('advanceNotice', e);
                    }}
                    id='advanceNotice'
                    name='advanceNotice'
                    defaultValue={formik.values.advanceNotice}
                    styles={customStyles}
                  />
                  {!(
                    formik.values.advanceNotice.value ===
                    'Same day (customizable cutoff hour)'
                  ) && (
                      <>
                        <FormGroup sx={{ margin: '10px 0px' }}>
                          <FormControlLabel
                            control={<Checkbox defaultChecked />}
                            label='For Instant Book Hosts: Allow guests to send reservation requests without advance notice.'
                          />
                        </FormGroup>
                      </>
                    )}
                  {formik.values.advanceNotice.value ===
                    'Same day (customizable cutoff hour)' && (
                      <>
                        <Typography sx={{ color: '#fff', margin: '10px 0px' }}>
                          Guests can book same day until:
                        </Typography>
                        <Select
                          className='override-select-edit'
                          placeholder='Select Time'
                          options={TIMINGS}
                          value={formik.values.advanceNoticeSameDayTime}
                          onChange={(e) => {
                            formik.setFieldValue('advanceNoticeSameDayTime', e);
                          }}
                          defaultValue={formik.values.advanceNoticeSameDayTime}
                          styles={customStyles}
                        />
                      </>
                    )}
                  {formik.values.advanceNotice.value ===
                    'Same day (customizable cutoff hour)' && (
                      <>
                        <div
                          style={{
                            border: '1px solid gray',
                            width: '100px',
                            margin: '10px',
                          }}
                        >
                          <div style={{ margin: '10px 0px' }}>Same day</div>
                          <img src={personImage} alt='' />
                          <span
                            style={{
                              display: 'flex',
                              width: '100%',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            {formik.values.advanceNoticeSameDayTime?.value}
                          </span>
                        </div>
                      </>
                    )}

                  {formik.values.advanceNotice.value ===
                    "At least one day's notice" && (
                      <div style={{ display: 'flex' }}>
                        <>
                          <div
                            style={{
                              border: '1px solid gray',
                              width: '100px',
                              margin: '10px',
                            }}
                          >
                            <div style={{ margin: '10px 0px' }}>
                              {moment().format('DD')}
                            </div>
                            <img src={na} alt='' width={100} />
                          </div>
                        </>
                        <>
                          <div
                            style={{
                              border: '1px solid gray',
                              width: '100px',
                              margin: '10px',
                            }}
                          >
                            <div style={{ margin: '10px 0px' }}>
                              {moment().add(1, 'days').format('DD')}
                            </div>
                            <img src={personImage} alt='' width={50} />
                          </div>
                        </>
                      </div>
                    )}
                  {/* 2 days  */}
                  {formik.values.advanceNotice.value ===
                    "At least two days' notice" && (
                      <div style={{ display: 'flex' }}>
                        <>
                          <div
                            style={{
                              border: '1px solid gray',
                              width: '100px',
                              margin: '10px',
                            }}
                          >
                            <div style={{ margin: '10px 0px' }}>
                              {moment().format('DD')}
                            </div>
                            <img src={na} alt='' width={100} />
                          </div>
                        </>
                        <>
                          <div
                            style={{
                              border: '1px solid gray',
                              width: '100px',
                              margin: '10px',
                            }}
                          >
                            <div style={{ margin: '10px 0px' }}>
                              {moment().add(1, 'days').format('DD')}
                            </div>
                            <img src={na} alt='' width={100} />
                          </div>
                        </>
                        <>
                          <div
                            style={{
                              border: '1px solid gray',
                              width: '100px',
                              margin: '10px',
                            }}
                          >
                            <div style={{ margin: '10px 0px' }}>
                              {moment().add(2, 'days').format('DD')}
                            </div>
                            <img src={personImage} alt='' width={50} />
                          </div>
                        </>
                      </div>
                    )}
                  {/* 3 days  */}
                  {formik.values.advanceNotice.value ===
                    "At least three days' notice" && (
                      <div style={{ display: 'flex' }}>
                        <>
                          <div
                            style={{
                              border: '1px solid gray',
                              width: '100px',
                              margin: '10px',
                            }}
                          >
                            <div style={{ margin: '10px 0px' }}>
                              {moment().format('DD')}
                            </div>
                            <img src={na} alt='' width={100} />
                          </div>
                        </>
                        <>
                          <div
                            style={{
                              border: '1px solid gray',
                              width: '100px',
                              margin: '10px',
                            }}
                          >
                            <div style={{ margin: '10px 0px' }}>
                              {moment().add(1, 'days').format('DD')}
                            </div>
                            <img src={na} alt='' width={100} />
                          </div>
                        </>
                        <>
                          <div
                            style={{
                              border: '1px solid gray',
                              width: '100px',
                              margin: '10px',
                            }}
                          >
                            <div style={{ margin: '10px 0px' }}>
                              {moment().add(2, 'days').format('DD')}
                            </div>
                            <img src={na} alt='' width={100} />
                          </div>
                        </>
                        <>
                          <div
                            style={{
                              border: '1px solid gray',
                              width: '100px',
                              margin: '10px',
                            }}
                          >
                            <div style={{ margin: '10px 0px' }}>
                              {moment().add(3, 'days').format('DD')}
                            </div>
                            <img src={personImage} alt='' width={50} />
                          </div>
                        </>
                      </div>
                    )}
                  {/* 7 days  */}
                  {formik.values.advanceNotice.value ===
                    "At least seven days' notice" && (
                      <>
                        <div style={{ display: 'flex' }}>
                          <>
                            <div
                              style={{
                                border: '1px solid gray',
                                width: '100px',
                                margin: '10px',
                              }}
                            >
                              <div style={{ margin: '10px 0px' }}>
                                {moment().format('DD')}
                              </div>
                              <img src={na} alt='' width={100} />
                            </div>
                          </>
                          <>
                            <div
                              style={{
                                border: '1px solid gray',
                                width: '100px',
                                margin: '10px',
                              }}
                            >
                              <div style={{ margin: '10px 0px' }}>
                                {moment().add(1, 'days').format('DD')}
                              </div>
                              <img src={na} alt='' width={100} />
                            </div>
                          </>
                          <>
                            <div
                              style={{
                                border: '1px solid gray',
                                width: '100px',
                                margin: '10px',
                              }}
                            >
                              <div style={{ margin: '10px 0px' }}>
                                {moment().add(2, 'days').format('DD')}
                              </div>
                              <img src={na} alt='' width={100} />
                            </div>
                          </>
                          <>
                            <div
                              style={{
                                border: '1px solid gray',
                                width: '100px',
                                margin: '10px',
                              }}
                            >
                              <div style={{ margin: '10px 0px' }}>
                                {moment().add(3, 'days').format('DD')}
                              </div>
                              <img src={na} alt='' width={100} />
                            </div>
                          </>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <>
                            <div
                              style={{
                                border: '1px solid gray',
                                width: '100px',
                                margin: '10px',
                              }}
                            >
                              <div style={{ margin: '10px 0px' }}>
                                {moment().add(4, 'days').format('DD')}
                              </div>
                              <img src={na} alt='' width={100} />
                            </div>
                          </>

                          <>
                            <div
                              style={{
                                border: '1px solid gray',
                                width: '100px',
                                margin: '10px',
                              }}
                            >
                              <div style={{ margin: '10px 0px' }}>
                                {moment().add(5, 'days').format('DD')}
                              </div>
                              <img src={na} alt='' width={100} />
                            </div>
                          </>
                          <>
                            <div
                              style={{
                                border: '1px solid gray',
                                width: '100px',
                                margin: '10px',
                              }}
                            >
                              <div style={{ margin: '10px 0px' }}>
                                {moment().add(6, 'days').format('DD')}
                              </div>
                              <img src={na} alt='' width={100} />
                            </div>
                          </>
                          <>
                            <div
                              style={{
                                border: '1px solid gray',
                                width: '100px',
                                margin: '10px',
                              }}
                            >
                              <div style={{ margin: '10px 0px' }}>
                                {moment().add(7, 'days').format('DD')}
                              </div>
                              <img src={personImage} alt='' width={65} />
                            </div>
                          </>
                        </div>
                      </>
                    )}
                </Grid>
              </Grid>
            )}
          </Grid>
          {/* Preparation time  */}
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 1, md: 2 }}
            columnSpacing={{ xs: 1, sm: 1, md: 2 }}
            sx={{ margin: '20px 0px' }}
          >
            {!editPreparationTime ? (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <Box>
                  <Typography sx={{ color: '#fff', fontSize: '18px' }}>
                    Preparation Time
                  </Typography>
                  <p>{formik.values.preparationTime?.label}</p>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: '18px',
                      textDecoration: 'underline',
                      color: '#fff',
                      cursor: 'pointer',
                    }}
                    onClick={() => setEditPreparationTime(!editPreparationTime)}
                  >
                    Edit
                  </Typography>
                </Box>
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                sx={{ border: '1px solid gray', borderRadius: '8px' }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'end',
                    justifyContent: 'end',
                  }}
                >
                  <MdClose
                    style={{
                      fontSize: '22px',
                      color: '#fff',
                      cursor: 'pointer',
                      fontWeight: 'bolder',
                      margin: '3px',
                    }}
                    onClick={() => setEditPreparationTime(!editPreparationTime)}
                  />
                </Box>
                <Grid item xs={12} sm={12} md={8}>
                  <h4 style={{ textAlign: 'start' }}>Preparation Time</h4>

                  <Select
                    className='override-select-edit'
                    options={preparationTimeOptions}
                    value={formik.values.preparationTime}
                    onChange={(e) => {
                      formik.setFieldValue('preparationTime', e);
                    }}
                    id='preparationTime'
                    name='preparationTime'
                    defaultValue={formik.values.preparationTime}
                    styles={customStyles}
                  />

                  {formik.values.preparationTime.value === 'None' && (
                    <div style={{ display: 'flex' }}>
                      <>
                        <div
                          style={{
                            border: '1px solid gray',
                            width: '100px',
                            margin: '10px',
                          }}
                        >
                          <img src={personImage} alt='' width={50} />
                        </div>
                      </>
                    </div>
                  )}

                  {/* 1 night  */}
                  {formik.values.preparationTime.value ===
                    'Book one night before and after each reservation.' && (
                      <div style={{ display: 'flex' }}>
                        <>
                          <div
                            style={{
                              border: '1px solid gray',
                              width: '100px',
                              margin: '10px',
                            }}
                          >
                            <div style={{ margin: '10px 0px' }}>
                              {moment().format('DD')}
                            </div>
                            <img src={na} alt='' width={100} />
                          </div>
                        </>
                        <>
                          <div
                            style={{
                              border: '1px solid gray',
                              width: '100px',
                              margin: '10px',
                            }}
                          >
                            <div style={{ margin: '10px 0px' }}>
                              {moment().add(1, 'days').format('DD')}
                            </div>
                            <img src={personImage} alt='' width={50} />
                          </div>
                        </>
                      </div>
                    )}
                  {/* 2 nights  */}
                  {formik.values.preparationTime.value ===
                    'Book two nights before and after each reservation.' && (
                      <div style={{ display: 'flex' }}>
                        <>
                          <div
                            style={{
                              border: '1px solid gray',
                              width: '100px',
                              margin: '10px',
                            }}
                          >
                            <div style={{ margin: '10px 0px' }}>
                              {moment().format('DD')}
                            </div>
                            <img src={na} alt='' width={100} />
                          </div>
                        </>
                        <>
                          <div
                            style={{
                              border: '1px solid gray',
                              width: '100px',
                              margin: '10px',
                            }}
                          >
                            <div style={{ margin: '10px 0px' }}>
                              {moment().add(1, 'days').format('DD')}
                            </div>
                            <img src={na} alt='' width={100} />
                          </div>
                        </>
                        <>
                          <div
                            style={{
                              border: '1px solid gray',
                              width: '100px',
                              margin: '10px',
                            }}
                          >
                            <div style={{ margin: '10px 0px' }}>
                              {moment().add(2, 'days').format('DD')}
                            </div>
                            <img src={personImage} alt='' width={50} />
                          </div>
                        </>
                      </div>
                    )}
                </Grid>
              </Grid>
            )}
          </Grid>
          {/* Availability window  */}
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 1, md: 2 }}
            columnSpacing={{ xs: 1, sm: 1, md: 2 }}
            sx={{ margin: '20px 0px' }}
          >
            {!editAvailabilityWindow ? (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <Box>
                  <Typography sx={{ color: '#fff', fontSize: '18px' }}>
                    Availability Window
                  </Typography>
                  <p>{formik.values.availabilityWindow?.label}</p>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: '18px',
                      textDecoration: 'underline',
                      color: '#fff',
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      setEditAvailabilityWindow(!editAvailabilityWindow)
                    }
                  >
                    Edit
                  </Typography>
                </Box>
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                sx={{ border: '1px solid gray', borderRadius: '8px' }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'end',
                    justifyContent: 'end',
                  }}
                >
                  <MdClose
                    style={{
                      fontSize: '22px',
                      color: '#fff',
                      cursor: 'pointer',
                      fontWeight: 'bolder',
                      margin: '8px',
                    }}
                    onClick={() =>
                      setEditAvailabilityWindow(!editAvailabilityWindow)
                    }
                  />
                </Box>
                <Grid item xs={12} sm={12} md={8}>
                  <Select
                    className='override-select-edit'
                    options={availabilityWindowOptions}
                    value={formik.values.availabilityWindow}
                    onChange={(e) => {
                      formik.setFieldValue('availabilityWindow', e);
                    }}
                    id='availabilityWindow'
                    name='availabilityWindow'
                    defaultValue={formik.values.availabilityWindow}
                    styles={customStyles}
                  />
                  {/* All future dates  */}
                  {formik.values.availabilityWindow.value ===
                    'All Future Dates' && (
                      <div style={{ display: 'flex' }}>
                        <>
                          <div
                            style={{
                              border: '1px solid gray',
                              width: '100px',
                              margin: '10px',
                              minHeight: '100px',
                            }}
                          >
                            <div style={{ margin: '10px 0px' }}>
                              {moment().add(1, 'year').format('YYYY')}
                            </div>
                          </div>
                        </>
                        <>
                          <div
                            style={{
                              border: '1px solid gray',
                              width: '100px',
                              margin: '10px',
                              minHeight: '100px',
                            }}
                          >
                            <div style={{ margin: '10px 0px' }}>
                              {moment().add(2, 'years').format('YYYY')}
                            </div>
                          </div>
                        </>
                        <>
                          <div
                            style={{
                              border: '1px solid gray',
                              width: '100px',
                              margin: '10px',
                              minHeight: '100px',
                            }}
                          >
                            <div style={{ margin: '10px 0px' }}>
                              {moment().add(3, 'years').format('YYYY')}
                            </div>
                            <img src={personImage} alt='' width={70} />
                          </div>
                        </>
                      </div>
                    )}
                  {/* 12 months*/}
                  {formik.values.availabilityWindow.value ===
                    '12 months in advance' && (
                      <div style={{ display: 'flex' }}>
                        <>
                          <div
                            style={{
                              border: '1px solid gray',
                              width: '100px',
                              margin: '10px',
                              minHeight: '100px',
                            }}
                          >
                            <div style={{ margin: '10px 0px' }}>
                              {moment().format('YYYY')}
                            </div>
                          </div>
                        </>
                        <>
                          <div
                            style={{
                              border: '1px solid gray',
                              width: '100px',
                              margin: '10px',
                              minHeight: '100px',
                            }}
                          >
                            <div style={{ margin: '10px 0px' }}>
                              {moment().add(1, 'years').format('YYYY')}
                            </div>
                            <img src={personImage} alt='' width={70} />
                          </div>
                        </>
                      </div>
                    )}
                  {/* 9 months
                   */}
                  {formik.values.availabilityWindow.value ===
                    '9 months in advance' && (
                      <>
                        <div style={{ display: 'flex' }}>
                          <>
                            <div
                              style={{
                                border: '1px solid gray',
                                width: '100px',
                                margin: '10px',
                                minHeight: '100px',
                              }}
                            >
                              <div style={{ margin: '10px 0px' }}>
                                {moment().format('MMM')}
                              </div>
                            </div>
                          </>
                          <>
                            <div
                              style={{
                                border: '1px solid gray',
                                width: '100px',
                                margin: '10px',
                                minHeight: '100px',
                              }}
                            >
                              <div style={{ margin: '10px 0px' }}>
                                {moment().add(1, 'month').format('MMM')}
                              </div>
                            </div>
                          </>
                          <>
                            <div
                              style={{
                                border: '1px solid gray',
                                width: '100px',
                                margin: '10px',
                                minHeight: '100px',
                              }}
                            >
                              <div style={{ margin: '10px 0px' }}>
                                {moment().add(2, 'month').format('MMM')}
                              </div>
                            </div>
                          </>
                          <>
                            <div
                              style={{
                                border: '1px solid gray',
                                width: '100px',
                                margin: '10px',
                                minHeight: '100px',
                              }}
                            >
                              <div style={{ margin: '10px 0px' }}>
                                {moment().add(3, 'month').format('MMM')}
                              </div>
                            </div>
                          </>
                          <>
                            <div
                              style={{
                                border: '1px solid gray',
                                width: '100px',
                                margin: '10px',
                                minHeight: '100px',
                              }}
                            >
                              <div style={{ margin: '10px 0px' }}>
                                {moment().add(4, 'month').format('MMM')}
                              </div>
                            </div>
                          </>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <>
                            <div
                              style={{
                                border: '1px solid gray',
                                width: '100px',
                                margin: '10px',
                                minHeight: '100px',
                              }}
                            >
                              <div style={{ margin: '10px 0px' }}>
                                {moment().add(5, 'month').format('MMM')}
                              </div>
                            </div>
                          </>
                          <>
                            <div
                              style={{
                                border: '1px solid gray',
                                width: '100px',
                                margin: '10px',
                                minHeight: '100px',
                              }}
                            >
                              <div style={{ margin: '10px 0px' }}>
                                {moment().add(6, 'month').format('MMM')}
                              </div>
                            </div>
                          </>
                          <>
                            <div
                              style={{
                                border: '1px solid gray',
                                width: '100px',
                                margin: '10px',
                                minHeight: '100px',
                              }}
                            >
                              <div style={{ margin: '10px 0px' }}>
                                {moment().add(7, 'month').format('MMM')}
                              </div>
                            </div>
                          </>
                          <>
                            <div
                              style={{
                                border: '1px solid gray',
                                width: '100px',
                                margin: '10px',
                                minHeight: '100px',
                              }}
                            >
                              <div style={{ margin: '10px 0px' }}>
                                {moment().add(8, 'month').format('MMM')}
                              </div>
                              <img src={personImage} alt='' width={65} />
                            </div>
                          </>
                        </div>
                      </>
                    )}
                  {/* 6 months
                   */}
                  {formik.values.availabilityWindow.value ===
                    '6 months in advance' && (
                      <>
                        <div style={{ display: 'flex' }}>
                          <>
                            <div
                              style={{
                                border: '1px solid gray',
                                width: '100px',
                                margin: '10px',
                                minHeight: '100px',
                              }}
                            >
                              <div style={{ margin: '10px 0px' }}>
                                {moment().format('MMM')}
                              </div>
                            </div>
                          </>
                          <>
                            <div
                              style={{
                                border: '1px solid gray',
                                width: '100px',
                                margin: '10px',
                                minHeight: '100px',
                              }}
                            >
                              <div style={{ margin: '10px 0px' }}>
                                {moment().add(1, 'month').format('MMM')}
                              </div>
                            </div>
                          </>
                          <>
                            <div
                              style={{
                                border: '1px solid gray',
                                width: '100px',
                                margin: '10px',
                                minHeight: '100px',
                              }}
                            >
                              <div style={{ margin: '10px 0px' }}>
                                {moment().add(2, 'month').format('MMM')}
                              </div>
                            </div>
                          </>
                          <>
                            <div
                              style={{
                                border: '1px solid gray',
                                width: '100px',
                                margin: '10px',
                                minHeight: '100px',
                              }}
                            >
                              <div style={{ margin: '10px 0px' }}>
                                {moment().add(3, 'month').format('MMM')}
                              </div>
                            </div>
                          </>
                          <>
                            <div
                              style={{
                                border: '1px solid gray',
                                width: '100px',
                                margin: '10px',
                                minHeight: '100px',
                              }}
                            >
                              <div style={{ margin: '10px 0px' }}>
                                {moment().add(4, 'month').format('MMM')}
                              </div>
                            </div>
                          </>
                        </div>
                        <div>
                          <>
                            <div
                              style={{
                                border: '1px solid gray',
                                width: '100px',
                                margin: '10px',
                                minHeight: '100px',
                              }}
                            >
                              <div style={{ margin: '10px 0px' }}>
                                {moment().add(5, 'month').format('MMM')}
                              </div>
                              <img src={personImage} alt='' width={65} />
                            </div>
                          </>
                        </div>
                      </>
                    )}
                  {/* 3 months
                   */}
                  {formik.values.availabilityWindow.value ===
                    '3 months in advance' && (
                      <>
                        <div style={{ display: 'flex' }}>
                          <>
                            <div
                              style={{
                                border: '1px solid gray',
                                width: '100px',
                                margin: '10px',
                                minHeight: '100px',
                              }}
                            >
                              <div style={{ margin: '10px 0px' }}>
                                {moment().format('MMM')}
                              </div>
                            </div>
                          </>
                          <>
                            <div
                              style={{
                                border: '1px solid gray',
                                width: '100px',
                                margin: '10px',
                                minHeight: '100px',
                              }}
                            >
                              <div style={{ margin: '10px 0px' }}>
                                {moment().add(1, 'month').format('MMM')}
                              </div>
                            </div>
                          </>
                          <>
                            <div
                              style={{
                                border: '1px solid gray',
                                width: '100px',
                                margin: '10px',
                                minHeight: '100px',
                              }}
                            >
                              <div style={{ margin: '10px 0px' }}>
                                {moment().add(2, 'month').format('MMM')}
                              </div>
                              <img src={personImage} alt='' width={65} />
                            </div>
                          </>
                        </div>
                      </>
                    )}
                  {/* unavailable
                   */}
                  {formik.values.availabilityWindow.value ===
                    'Dates Unavailable' && (
                      <>
                        <div style={{ display: 'flex' }}>
                          <>
                            <div
                              style={{
                                border: '1px solid gray',
                                width: '100px',
                                margin: '10px',
                                minHeight: '100px',
                              }}
                            >
                              <div style={{ margin: '10px 0px' }}>
                                {moment().format('MMM')}
                              </div>
                              <img src={na} alt='' width={100} />
                            </div>
                          </>
                          <>
                            <div
                              style={{
                                border: '1px solid gray',
                                width: '100px',
                                margin: '10px',
                                minHeight: '100px',
                              }}
                            >
                              <div style={{ margin: '10px 0px' }}>
                                {moment().add(1, 'month').format('MMM')}
                              </div>
                              <img src={na} alt='' width={100} />
                            </div>
                          </>
                          <>
                            <div
                              style={{
                                border: '1px solid gray',
                                width: '100px',
                                margin: '10px',
                                minHeight: '100px',
                              }}
                            >
                              <div style={{ margin: '10px 0px' }}>
                                {moment().add(2, 'month').format('MMM')}
                              </div>
                              <img src={na} alt='' width={100} />
                            </div>
                          </>
                        </div>
                      </>
                    )}
                </Grid>
              </Grid>
            )}
          </Grid>
          {/* restricted days  */}
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 1, md: 2 }}
            columnSpacing={{ xs: 1, sm: 1, md: 2 }}
            sx={{ margin: '20px 0px' }}
          >
            {!editRestrictedDays ? (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <Box>
                  <Typography sx={{ color: '#fff', fontSize: '18px' }}>
                    Restricted check-in days
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: '18px',
                      textDecoration: 'underline',
                      color: '#fff',
                      cursor: 'pointer',
                    }}
                    onClick={() => setEditRestrictedDays(!editRestrictedDays)}
                  >
                    Edit
                  </Typography>
                </Box>
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                sx={{ border: '1px solid gray', borderRadius: '8px' }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'end',
                    justifyContent: 'end',
                  }}
                >
                  <MdClose
                    style={{
                      fontSize: '22px',
                      color: '#fff',
                      cursor: 'pointer',
                      fontWeight: 'bolder',
                      margin: '8px',
                    }}
                    onClick={() => setEditRestrictedDays(!editRestrictedDays)}
                  />
                </Box>
                <Typography sx={{ color: '#fff', fontSize: '22px' }}>
                  Restricted check-in days
                </Typography>
                <Grid container md={12}>
                  <Grid item xs={12} sm={12} md={6}>
                    <FormGroup sx={{ margin: '0px 10px' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={restrictedDaysFormik.values.sunday}
                            name='sunday'
                            onChange={restrictedDaysFormik.handleChange}
                            checked={restrictedDaysFormik.values.sunday}
                          />
                        }
                        label='Sunday'
                        sx={{ display: 'inline' }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={restrictedDaysFormik.values.monday}
                            name='monday'
                            onChange={restrictedDaysFormik.handleChange}
                            checked={restrictedDaysFormik.values.monday}
                          />
                        }
                        label='Monday'
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={restrictedDaysFormik.values.tuesday}
                            name='tuesday'
                            onChange={restrictedDaysFormik.handleChange}
                            checked={restrictedDaysFormik.values.tuesday}
                          />
                        }
                        label='Tuesday'
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={restrictedDaysFormik.values.wednesday}
                            name='wednesday'
                            onChange={restrictedDaysFormik.handleChange}
                            checked={restrictedDaysFormik.values.wednesday}
                          />
                        }
                        label='Wednesday'
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <FormGroup sx={{ margin: '0px 10px' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={restrictedDaysFormik.values.thursday}
                            name='thursday'
                            checked={restrictedDaysFormik.values.thursday}
                            onChange={restrictedDaysFormik.handleChange}
                          />
                        }
                        label='Thursday'
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={restrictedDaysFormik.values.friday}
                            checked={restrictedDaysFormik.values.friday}
                            name='friday'
                            onChange={restrictedDaysFormik.handleChange}
                          />
                        }
                        label='Friday'
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={restrictedDaysFormik.values.saturday}
                            checked={restrictedDaysFormik.values.saturday}
                            name='saturday'
                            onChange={restrictedDaysFormik.handleChange}
                          />
                        }
                        label='Saturday'
                      />
                    </FormGroup>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    display='flex'
                    justifyContent='flex-end'
                    alignItems='flex-end'
                    width='100%'
                  >
                    <Buttons
                      title='Save'
                      isLoading={false}
                      onSubmit={restrictedDaysFormik.handleSubmit}
                      style={{ margin: '15px 10px', width: '20%' }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 1, md: 2 }}
            columnSpacing={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={12} sm={12} md={2}>
              <Buttons
                title='Save'
                isLoading={isButtonLoading}
                onSubmit={formik.handleSubmit}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default Pricing;
