import axios from 'axios';
import { API_URL } from '../../config';

export const postRegistrationApi = async (data) => {
  try {
    const response = await axios.post(API_URL + 'auth/email/register', data);
    return response;
  } catch (error) {
    if (error) {
      console.log('Error', error);
      return error;
    }
  }
};
