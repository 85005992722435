import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import HouseIcon from '@mui/icons-material/House';

const PropertyDetailGoogleMap = ({ mapValues }) => {
  let latN = mapValues.lat ? parseFloat(mapValues?.lat) : 30.3753;
  let lngN = mapValues.lng ? parseFloat(mapValues?.lng) : 69.3451;
  
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyB53v_rdmTRQm8y9Iqa-g9h-2_qO0G6Fuo' }}
        center={{
          lat: latN,
          lng: lngN,
        }}
        draggable={false}
        defaultZoom={12}
        options={{ 
          scrollwheel: false, 
          zoomControl: false, 
          fullscreenControl: false 
        }}
      >
        <AnyReactComponent
            lat={latN}
            lng={lngN}
            showAddress={""}
          />
      </GoogleMapReact>
    </div>
  );
};

const AnyReactComponent = ({ address, setShowAddress, index, showAddress }) => {
  const handleClick = (index) => {
    if (showAddress == index) {
      setShowAddress(null);
    } else {
      setShowAddress(index);
    }
  };
  return (
    <React.Fragment>
      <div className="map-icon" onClick={() => handleClick(index)}>
        <HouseIcon />
      </div>
      {!(showAddress == index) ? null : (
        <div className="map-address-section">{address}</div>
      )}
    </React.Fragment>
  );
};

export default PropertyDetailGoogleMap;
