import { API_URL } from '../../config';
import axios from 'axios';

export const createOfferApi = async (data) => {
  try {
    const response = await axios.post(
      API_URL + 'booking-extra-payment/createoffer',
      data
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of createoffer  : ', response);
      return response;
    }
  }
};
