import React, { useEffect, useRef, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Webcam from 'react-webcam';
import LockIcon from '@mui/icons-material/Lock';
import Header from '../../../components/header';
import Card from '../../../components/dasboard/card';
import Buttons from '../../../components/form/button';
import ProgressBar from '../../../components/progressBar';
import Sidebar from '../../../components/dasboard/sidebar';
import InfoBar from '../../../components/dasboard/infoBar';
import styled from 'styled-components';
import { options } from '../../../constants/countries';
import VUpload from '../../../components/form/verifyUpload';
import {
  BsFillCreditCard2FrontFill,
  BsFillCreditCard2BackFill,
} from 'react-icons/bs';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { idVerification } from '../../../services/IdVerification/idVerification';
import getUserApi from '../../../services/User/getUser';
import { useNavigate } from 'react-router-dom';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import { Toast } from 'primereact/toast';

const AddPropertyPage = () => {
  const [step, setStep] = useState(0);
  const [user, setUser] = useState({});
  const userData = JSON.parse(localStorage.getItem('userData')) || null;
  const [frontPic, setfrontPic] = useState([]);
  const [backPic, setbackPic] = useState([]);
  const [frontPicUrls, setfrontPicUrls] = useState([]);
  const [backPicUrls, setbackPicUrls] = useState([]);
  const [displayImage, setDisplayImage] = useState(false);
  const navigate = useNavigate();
  const [errorFrontPic, setErrorFrontPic] = useState('');
  const [errorBackPic, setErrorBackPic] = useState('');
  const [isResetFront, setisResetFront] = useState(false);
  const [isResetBack, setisResetBack] = useState(false);
  const [camImgIDFront, setcamImgIDFront] = useState('');
  const [camImgIDBack, setcamImgIDBack] = useState('');
  const [camImgSelf, setcamImgSelf] = useState('');
  const [selectedCountry, setSelectedCountry] = useState(null);
  const webcamIDFrontRef = React.useRef(null);
  const webcamIDBackRef = React.useRef(null);
  const webcamSelfRef = React.useRef(null);
  const [activeFrame, setActiveFrame] = useState(false);
  const [camImgFile, setCamImgFile] = useState([]);

  useEffect(() => {
    if (frontPic.length > 0) {
      const newFrontPics = [];
      frontPic.forEach((fPic) => newFrontPics.push(URL.createObjectURL(fPic)));
      setfrontPicUrls(newFrontPics);
    }
    if (backPic.length > 0) {
      const newBackPics = [];
      backPic.forEach((bPic) => newBackPics.push(URL.createObjectURL(bPic)));
      setbackPicUrls(newBackPics);
    }
  }, [frontPic, backPic]);

  const detail = useFormik({
    initialValues: {
      uploadType: 'ep',
    },
    onSubmit: () => {
      nextStep();
    },
  });

  const UploadhandleSubmit = () => {
    try {
      if (frontPic?.length > 0 && backPic?.length > 0) {
        nextStep();
      } else {
        if (frontPic?.length === 0) setErrorFrontPic('Front Pic is required');
        if (backPic?.length === 0) setErrorBackPic('Back Pic is required');
      }
    } catch (error) {}
  };

  const onFrontPicChange = (e) => {
    setfrontPic((data) => [...data, ...e.target.files]);
    setDisplayImage(true);
  };

  const onBackPicChange = (e) => {
    setbackPic((data) => [...data, ...e.target.files]);
    setDisplayImage(true);
  };

  const nextStep = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setStep(step + 1);
  };

  const previousStep = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setStep(step - 1);
  };

  const steps = [
    'Overview',
    'Select Upload Method',
    'Select ID Type',
    'Upload',
    'Take Your Picture',
    'Under Review',
  ];

  const captureCamIDFront = React.useCallback(() => {
    const imageSrc = webcamIDFrontRef.current.getScreenshot();
    setcamImgIDFront(imageSrc);
  }, [webcamIDFrontRef]);
  const captureCamIDBack = React.useCallback(() => {
    const imageSrc = webcamIDBackRef.current.getScreenshot();
    setcamImgIDBack(imageSrc);
  }, [webcamIDBackRef]);
  const captureCamSelf = React.useCallback(() => {
    const imageSrc = webcamSelfRef.current.getScreenshot();
    setcamImgSelf(imageSrc);
  }, [webcamSelfRef]);

  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className='country-item country-item-value'>
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className='country-item'>
        <div>{option.name}</div>
      </div>
    );
  };

  const getUser = async () => {
    const user = await getUserApi(userData?.email);
    setUser(user);
  };

  useEffect(() => {
    getUser();
  }, []);

  const onCountryChange = (e) => {
    setSelectedCountry(e.value);
  };
  const camOverlay = () => (
    <svg
      width='120%'
      height='100%'
      className='svg'
      viewBox='0 0 260 200'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      style={{ zIndex: 9999 }}
    >
      <defs>
        <mask id='overlay-mask' x='0' y='0' width='100%' height='100%'>
          <rect x='0' y='0' width='150%' height='100%' fill='#fff' />
          <circle cx='50%' cy='50%' r='70' />
        </mask>
      </defs>
      <rect
        x='0'
        y='0'
        width='100%'
        height='100%'
        mask='url(#overlay-mask)'
        fillOpacity='0.7'
      />
    </svg>
  );

  const clearFrontPic = (imageSrc) => {
    setisResetFront(true);
    const filterImage = frontPicUrls.filter((name) => name !== imageSrc);
    setfrontPicUrls(filterImage);
    const newImageUrls = [];
    filterImage.forEach((image) =>
      newImageUrls.push(URL.createObjectURL(image))
    );
    setfrontPic(newImageUrls);
  };
  const clearBackPic = (imageSrc) => {
    setisResetBack(true);
    const filterImage = backPicUrls.filter((name) => name !== imageSrc);
    setbackPicUrls(filterImage);
    const newImageUrls = [];
    filterImage.forEach((image) =>
      newImageUrls.push(URL.createObjectURL(image))
    );
    setbackPic(newImageUrls);
  };
  const toast = useRef(null);
  const general = useFormik({
    initialValues: {
      idType: '',
      country: selectedCountry ? selectedCountry.name : '',
      dob: '',
      cnic: '',
    },
    enableReinitialize: true,
    validationSchema: () => {
      return yup.object({
        country: yup.string().required('Country/Region is required.'),
        idType: yup.string().required('ID Upload Type is required.'),
        dob: yup.string().required('Date Of Birth is required.'),
        cnic: yup.string().required('Identity Number is required!'),
      });
    },
    onSubmit: (values) => {
      const ageDiff = moment().diff(moment(values.dob), 'years');
      if (ageDiff < 18) {
        toast.current.show({
          severity: 'info',
          summary: 'Age should be 18+',
          detail: 'Your age must be 18+ in order to verify your documents.',
          life: 3000,
        });
      } else {
        nextStep();
      }
    },
  });
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  React.useEffect(() => {
    if (camImgSelf) {
      setCamImgFile([dataURLtoFile(camImgSelf, 'livePic')]);
    }
  }, [camImgSelf]);
  const [dob, setDob] = useState('');
  const verifyFormData = useFormik({
    initialValues: {
      userId: userData?.id,
      idCardType: general.values.idType,
      country: general.values.country,
      dob: moment(general.values.dob).format('YYYY-MM-DD'),
      cnic: general.values.cnic,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      var formData = new FormData();
      formData.append('frontPic', frontPic[0]);
      formData.append('backPic', backPic[0]);
      formData.append('userId', values.userId);
      formData.append('idCardType', values.idCardType);
      formData.append('country', values.country);
      formData.append('livePic', camImgFile[0]);
      formData.append('dob', values.dob);
      formData.append('iDCardNumber', values.cnic);

      const res = await idVerification(formData);
      if (res.status === 201) {
        nextStep();
      }
    },
  });
  function DataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(',');
    const byteString =
      splitDataURI[0].indexOf('base64') >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  }
  const verifyForWebcam = useFormik({
    initialValues: {
      userId: userData?.id,
      idCardType: general.values.idType,
      country: general.values.country,
      dob: moment(general.values.dob).format('YYYY-MM-DD'),
      cnic: general.values.cnic,
    },

    enableReinitialize: true,
    onSubmit: async (values) => {
      var formData = new FormData();
      formData.append(
        'frontPic',
        DataURIToBlob(camImgIDFront),
        'frontPic.jpeg'
      );
      formData.append('backPic', DataURIToBlob(camImgIDBack), 'backPic.jpeg');
      formData.append('userId', values.userId);
      formData.append('idCardType', values.idCardType);
      formData.append('country', values.country);
      formData.append('livePic', camImgFile[0]);
      formData.append('dob', values.dob);
      formData.append('iDCardNumber', values.cnic);
      const res = await idVerification(formData);
      if (res.status === 201) {
        nextStep();
      }
    },
  });

  React.useEffect(() => {
    if (userData?.verified === 'pending') {
      setStep(5);
    }
  }, [userData?.verified]);

  return (
    <>
      <Toast ref={toast} position='bottom-right' />
      <Header />
      <div id='wrapper'>
        <div className='dashboard-menu-overlay' />
        <Sidebar page={'Trust & Verification'} />
        <div className='dashboard-content'>
          <div className='dashboard-menu-btn color-bg'>
            <span>
              <i className='fas fa-bars' />
            </span>
            Dasboard Menu
          </div>
          <InfoBar label={'ID Verification'} />
          <div
            className='dasboard-widget-box fl-wrap'
            style={{ marginTop: 40 }}
          >
            <ProgressBar step={step} steps={steps} />
          </div>
          <div className='dasboard-wrapper fl-wrap no-pag'>
            <Card withIcon title={'ID Verification'} icon={'fa fa-pencil'}>
              {userData.verified ? (
                <>
                  {' '}
                  <VerificationCard>
                    {/* <h2>ID Verification</h2> */}
                    <p style={{ fontSize: '21px' }}>
                      Your ID verification already completed
                    </p>
                  </VerificationCard>
                </>
              ) : (
                (() => {
                  switch (step) {
                    case 0:
                      return (
                        <>
                          <VerificationCard>
                            <h2>ID Verification</h2>
                            <p style={{ fontSize: '16px' }}>
                              Please provide a government ID, to verify your
                              identity. Please make sure that the information on
                              the ID picture is clearly visible.
                            </p>
                          </VerificationCard>

                          <Grid container spacing={3}>
                            <Grid item xs={2} md={2}>
                              <Buttons
                                title={'Proceed'}
                                isLoading={false}
                                onSubmit={() => nextStep()}
                              />
                            </Grid>
                          </Grid>
                        </>
                      );
                    case 1:
                      return (
                        <>
                          <Card
                            withIcon
                            title={'ID Upload Method'}
                            icon={'fal fa-credit-card'}
                          >
                            <Grid
                              container
                              rowSpacing={{ xs: 1, sm: 1, md: 2 }}
                              columnSpacing={{ xs: 1, sm: 1, md: 2 }}
                            >
                              <Grid item xs={12} sm={12} md={12}>
                                <Typography
                                  sx={{
                                    color: 'white',
                                    fontSize: '18px',
                                  }}
                                >
                                  How would you like to add a photo of your ID?
                                </Typography>
                                <FormControl>
                                  <RadioGroup
                                    aria-labelledby='demo-radio-buttons-group-label'
                                    name={'uploadType'}
                                    onChange={detail.handleChange}
                                    value={detail.values.uploadType}
                                    defaultValue={detail.values.uploadType}
                                  >
                                    <FormControlLabel
                                      value='ep'
                                      control={<Radio />}
                                      label='Upload an existing photo.'
                                      sx={{
                                        color: '#fff',
                                      }}
                                    />
                                    <Typography
                                      sx={{
                                        marginLeft: '15%',
                                        fontSize: '14px',
                                      }}
                                    >
                                      Recommended
                                    </Typography>
                                    <FormControlLabel
                                      value='wb'
                                      control={<Radio />}
                                      label='Take photo with your webcam.'
                                      sx={{
                                        color: '#fff',
                                      }}
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Grid>
                            </Grid>
                          </Card>
                          <Grid container columnSpacing={{ xs: 2, sm: 2 }}>
                            <Grid item xs={3} sm={3} md={2}>
                              <Buttons
                                title={'Back'}
                                isLoading={false}
                                onSubmit={previousStep}
                              />
                            </Grid>
                            <Grid item xs={3} sm={3} md={2}>
                              <Buttons
                                title={'Continue'}
                                isLoading={false}
                                onSubmit={detail?.handleSubmit}
                              />
                            </Grid>
                          </Grid>
                        </>
                      );
                    case 2:
                      return (
                        <>
                          <Card
                            withIcon
                            title={'Choose an ID type to add'}
                            icon={'fa fa-users'}
                          >
                            <Grid container>
                              <Grid item xs={12} sm={12} md={4}>
                                <FormControl>
                                  <FormLabelStyled id='demo-controlled-radio-buttons-group'>
                                    Choose an ID type to add.
                                  </FormLabelStyled>
                                  <Dropdown
                                    value={selectedCountry}
                                    options={options}
                                    onChange={onCountryChange}
                                    optionLabel='name'
                                    filter
                                    showClear
                                    filterBy='name'
                                    placeholder='Select region/country.'
                                    valueTemplate={selectedCountryTemplate}
                                    itemTemplate={countryOptionTemplate}
                                    style={{
                                      width: '250%',
                                      background: 'transparent',
                                      padding: '6px 5px',
                                    }}
                                  />
                                  {general.touched.country &&
                                    Boolean(general.errors.country) && (
                                      <small
                                        id='username2-help'
                                        className='p-error'
                                        style={{
                                          display: 'block',
                                          color: 'red',
                                          textAlign: 'left',
                                        }}
                                      >
                                        {general.touched.country &&
                                          general.errors.country}
                                      </small>
                                    )}
                                  <RadioGroup
                                    aria-labelledby='demo-radio-buttons-group-label'
                                    name={'idType'}
                                    onChange={general.handleChange}
                                    value={general.values.idType}
                                  >
                                    <FormControlLabel
                                      value='driving-license'
                                      control={<Radio />}
                                      label="Driver's License"
                                      sx={{
                                        color: 'rgba(255, 255, 255, 0.7)',
                                        padding: '15px 0px',
                                        borderBottom: '1px solid gray',
                                        width: '250%',
                                      }}
                                    />
                                    <FormControlLabel
                                      value='passport'
                                      control={<Radio />}
                                      label='Passport'
                                      sx={{
                                        color: 'rgba(255, 255, 255, 0.7)',
                                        padding: '15px 0px',
                                        borderBottom: '1px solid gray',
                                        width: '250%',
                                      }}
                                    />
                                    <FormControlLabel
                                      value='identity-card'
                                      control={<Radio />}
                                      label='Identity Card'
                                      sx={{
                                        color: 'rgba(255, 255, 255, 0.7)',
                                        padding: '15px 0px',
                                      }}
                                    />
                                  </RadioGroup>
                                  {general.touched.idType &&
                                    Boolean(general.errors.idType) && (
                                      <small
                                        id='username2-help'
                                        className='p-error'
                                        style={{
                                          display: 'block',
                                          color: 'red',
                                          textAlign: 'left',
                                        }}
                                      >
                                        {general.touched.idType &&
                                          general.errors.idType}
                                      </small>
                                    )}
                                </FormControl>
                                <Typography
                                  sx={{ color: '#fff', fontSize: '18px' }}
                                >
                                  Date Of Birth
                                </Typography>
                                <Calendar
                                  id='basic'
                                  value={general.values.dob}
                                  onChange={(e) =>
                                    general.setFieldValue('dob', e.value)
                                  }
                                  style={{
                                    width: '100%',
                                    marginBottom: '15px',
                                  }}
                                  placeholder='Date Of Birth'
                                />
                                {general.touched.dob &&
                                  Boolean(general.errors.dob) && (
                                    <small
                                      id='username2-help'
                                      className='p-error'
                                      style={{
                                        display: 'block',
                                        color: 'red',
                                        textAlign: 'left',
                                      }}
                                    >
                                      {general.touched.dob &&
                                        general.errors.dob}
                                    </small>
                                  )}
                                {general.values.idType !== '' && (
                                  <>
                                    <Typography
                                      sx={{ color: '#fff', fontSize: '18px' }}
                                    >
                                      Enter your{' '}
                                      {general.values.idType === 'passport' &&
                                        'passport number.'}{' '}
                                      {general.values.idType ===
                                        'driving-license' &&
                                        'driving license number.'}
                                      {general.values.idType ===
                                        'identity-card' &&
                                        'identity card number.'}
                                    </Typography>
                                    <TextField
                                      sx={{ width: '100%' }}
                                      id='cnic'
                                      name='cnic'
                                      required
                                      placeholder={
                                        'Enter your identity number here.'
                                      }
                                      value={general.values.cnic}
                                      onChange={general.handleChange}
                                      inputProps={{ maxLength: 50 }}
                                    />
                                    {general.touched.cnic &&
                                      Boolean(general.errors.cnic) && (
                                        <small
                                          id='username2-help'
                                          className='p-error'
                                          style={{
                                            display: 'block',
                                            color: 'red',
                                            textAlign: 'left',
                                          }}
                                        >
                                          {general.touched.cnic &&
                                            general.errors.cnic}
                                        </small>
                                      )}
                                  </>
                                )}
                                <div
                                  style={{
                                    fontSize: '14px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: 'rgba(255, 255, 255, 0.7)',
                                  }}
                                >
                                  <LockIcon
                                    sx={{
                                      fontSize: '16px',
                                      marginRight: '4px',
                                    }}
                                  />
                                  We aim to keep your data private, safe &
                                  secure.
                                </div>
                              </Grid>
                            </Grid>
                          </Card>
                          <Grid
                            container
                            columnSpacing={{ xs: 2, sm: 2 }}
                            spacing={3}
                          >
                            <Grid item xs={3} sm={3} md={2}>
                              <Buttons
                                title={'Back'}
                                isLoading={false}
                                onSubmit={previousStep}
                              />
                            </Grid>
                            <Grid item xs={3} sm={3} md={2}>
                              <Buttons
                                title={'Continue'}
                                isLoading={false}
                                onSubmit={general?.handleSubmit}
                              />
                            </Grid>
                          </Grid>
                        </>
                      );

                    case 3:
                      return (
                        <>
                          {detail.values.uploadType !== 'ep' ? (
                            <>
                              <FormLabelStyled id='demo-controlled-radio-buttons-group'>
                                {activeFrame
                                  ? 'Capture back of your ID'
                                  : 'Capture front of your ID.'}
                              </FormLabelStyled>
                              {!activeFrame ? (
                                <div
                                  style={{
                                    display: 'flex',
                                    height: '500px',
                                    marginBottom: '20px',
                                    position: 'relative',
                                  }}
                                >
                                  {camImgIDFront ? (
                                    <>
                                      <div
                                        style={{
                                          position: 'absolute',
                                          top: '0',
                                          left: '0',
                                          width: '100%',
                                          height: '100%',
                                          backgroundImage: `url(${camImgIDFront})`,
                                          backgroundSize: 'cover',
                                          backgroundPosition: 'center',
                                          backgroundRepeat: 'no-repeat',
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <Webcam
                                        audio={false}
                                        ref={webcamIDFrontRef}
                                        height={'100%'}
                                        screenshotFormat='image/jpeg'
                                        videoConstraints={{
                                          width: 1280,
                                          facingMode: 'user',
                                        }}
                                      ></Webcam>
                                    </>
                                  )}
                                </div>
                              ) : (
                                <div
                                  style={{
                                    display: 'flex',
                                    height: '500px',
                                    marginBottom: '20px',
                                    position: 'relative',
                                  }}
                                >
                                  {camImgIDBack ? (
                                    <>
                                      <div
                                        style={{
                                          position: 'absolute',
                                          top: '0',
                                          left: '0',
                                          width: '100%',
                                          height: '100%',
                                          backgroundImage: `url(${camImgIDBack})`,
                                          backgroundSize: 'cover',
                                          backgroundPosition: 'center',
                                          backgroundRepeat: 'no-repeat',
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <Webcam
                                        audio={false}
                                        ref={webcamIDBackRef}
                                        height={'100%'}
                                        screenshotFormat='image/jpeg'
                                        videoConstraints={{
                                          width: 1280,
                                          facingMode: 'user',
                                        }}
                                      ></Webcam>
                                    </>
                                  )}
                                </div>
                              )}
                              {!activeFrame && (
                                <div>
                                  {camImgIDFront ? (
                                    <>
                                      <Button
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setcamImgIDFront('');
                                        }}
                                      >
                                        Retake
                                      </Button>

                                      <Button
                                        variant='contained'
                                        onClick={(e) => {
                                          setActiveFrame(true);
                                        }}
                                      >
                                        Capture Back <ArrowForwardIcon />
                                      </Button>
                                    </>
                                  ) : (
                                    <Button
                                      onClick={(e) => {
                                        e.preventDefault();
                                        captureCamIDFront();
                                      }}
                                    >
                                      Capture
                                    </Button>
                                  )}
                                </div>
                              )}
                              {activeFrame && (
                                <div>
                                  {camImgIDBack ? (
                                    <>
                                      <Button
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setcamImgIDBack('');
                                        }}
                                      >
                                        Retake
                                      </Button>
                                    </>
                                  ) : (
                                    <Button
                                      onClick={(e) => {
                                        e.preventDefault();
                                        captureCamIDBack();
                                      }}
                                    >
                                      Capture
                                    </Button>
                                  )}
                                </div>
                              )}
                            </>
                          ) : (
                            <>
                              <FormLabelStyled id='demo-controlled-radio-buttons-group'>
                                Upload images of your driver's license.
                              </FormLabelStyled>
                              <div
                                style={{
                                  fontSize: '14px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  color: 'rgba(255, 255, 255, 0.7)',
                                  marginBottom: '16px',
                                }}
                              >
                                Make sure your photos aren't blurry and the
                                front of your ID clearly shows your face.
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alingItems: 'center',
                                }}
                              >
                                <div style={{ margin: '8px 12px' }}>
                                  <VUpload
                                    label={
                                      isResetFront
                                        ? 'Re-upload Front'
                                        : 'Upload Front'
                                    }
                                    handleChange={(e) => onFrontPicChange(e)}
                                    multiple={true}
                                    displayImg={
                                      displayImage ? frontPicUrls[0] : false
                                    }
                                    resetAction={() =>
                                      clearFrontPic(frontPicUrls[0])
                                    }
                                    icon={
                                      <BsFillCreditCard2FrontFill
                                        style={{
                                          marginRight: '6px',
                                          fontSize: '5rem',
                                        }}
                                      />
                                    }
                                  />
                                  {errorFrontPic && frontPic?.length === 0 ? (
                                    <p className='upload-error'>
                                      {errorFrontPic}
                                    </p>
                                  ) : null}
                                </div>
                                <div style={{ margin: '8px 12px' }}>
                                  <VUpload
                                    label={
                                      isResetBack
                                        ? 'Re-upload Back'
                                        : 'Upload Back'
                                    }
                                    handleChange={(e) => onBackPicChange(e)}
                                    multiple={true}
                                    displayImg={
                                      displayImage ? backPicUrls[0] : false
                                    }
                                    resetAction={() =>
                                      clearBackPic(backPicUrls[0])
                                    }
                                    icon={
                                      <BsFillCreditCard2BackFill
                                        style={{
                                          marginRight: '6px',
                                          fontSize: '5rem',
                                        }}
                                      />
                                    }
                                  />
                                  {errorBackPic && backPic?.length === 0 ? (
                                    <p className='upload-error'>
                                      {errorBackPic}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              <div
                                style={{
                                  fontSize: '14px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  color: 'rgba(255, 255, 255, 0.7)',
                                  margin: '22px 0px',
                                }}
                              >
                                <LockIcon
                                  sx={{
                                    fontSize: '16px',
                                    marginRight: '4px',
                                  }}
                                />
                                We aim to keep your data private, safe & secure.
                              </div>
                            </>
                          )}
                          <Grid
                            container
                            columnSpacing={{ xs: 2, sm: 2 }}
                            spacing={3}
                          >
                            <Grid item xs={3} sm={3} md={2}>
                              <Buttons
                                title={'Back'}
                                isLoading={false}
                                onSubmit={previousStep}
                              />
                            </Grid>
                            <Grid item xs={3} sm={3} md={2}>
                              {detail.values.uploadType !== 'ep' ? (
                                <Buttons
                                  title={'Continue'}
                                  isLoading={false}
                                  onSubmit={nextStep}
                                />
                              ) : (
                                <Buttons
                                  title={'Continue'}
                                  isLoading={false}
                                  onSubmit={UploadhandleSubmit}
                                />
                              )}
                            </Grid>
                          </Grid>
                        </>
                      );
                    case 4:
                      return (
                        <>
                          <FormLabelStyled id='demo-controlled-radio-buttons-group'>
                            {camImgSelf
                              ? 'Review your Image'
                              : 'Next, take a picture of yourself.'}
                          </FormLabelStyled>
                          <div
                            style={{
                              fontSize: '16px',
                              display: 'flex',
                              alignItems: 'center',
                              color: 'rgba(255, 255, 255, 0.7)',
                              marginBottom: '16px',
                            }}
                          >
                            {camImgSelf
                              ? 'Review your image and make sure its in the best quality and in acceptable quality.'
                              : "So that we can match with your picture on your ID, make sure it's clear."}
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              height: '500px',
                              marginBottom: '20px',
                              position: 'relative',
                            }}
                          >
                            {camImgSelf ? (
                              <>
                                <div
                                  style={{
                                    position: 'absolute',
                                    top: '0',
                                    left: '0',
                                    width: '100%',
                                    height: '100%',
                                    backgroundImage: `url(${camImgSelf})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                  }}
                                />
                                {camOverlay()}
                              </>
                            ) : (
                              <>
                                <Webcam
                                  audio={false}
                                  ref={webcamSelfRef}
                                  height={'100%'}
                                  screenshotFormat='image/jpeg'
                                  videoConstraints={{
                                    width: 1280,
                                    facingMode: 'user',
                                  }}
                                ></Webcam>
                                {camOverlay()}
                              </>
                            )}
                          </div>
                          {verifyFormData.touched.livePic &&
                            Boolean(verifyFormData.errors.livePic) && (
                              <small
                                id='username2-help'
                                className='p-error'
                                style={{
                                  display: 'block',
                                  color: 'red',
                                  textAlign: 'left',
                                }}
                              >
                                {verifyFormData.touched.livePic &&
                                  verifyFormData.errors.livePic}
                              </small>
                            )}
                          {camImgSelf ? (
                            <Button
                              onClick={(e) => {
                                e.preventDefault();
                                setcamImgSelf('');
                              }}
                            >
                              Retake
                            </Button>
                          ) : (
                            <Button
                              onClick={(e) => {
                                e.preventDefault();
                                captureCamSelf();
                              }}
                            >
                              Capture
                            </Button>
                          )}
                          <div
                            style={{
                              fontSize: '14px',
                              display: 'flex',
                              alignItems: 'center',
                              color: 'rgba(255, 255, 255, 0.7)',
                              margin: '22px 0px',
                            }}
                          >
                            <LockIcon
                              sx={{
                                fontSize: '16px',
                                marginRight: '4px',
                              }}
                            />
                            We aim to keep your data private, safe & secure.
                          </div>
                          <Grid
                            container
                            columnSpacing={{ xs: 2, sm: 2 }}
                            spacing={3}
                          >
                            <Grid item xs={3} sm={3} md={2}>
                              <Buttons
                                title={'Back'}
                                isLoading={false}
                                onSubmit={previousStep}
                              />
                            </Grid>
                            <Grid item xs={3} sm={3} md={2}>
                              {detail.values.uploadType !== 'ep' ? (
                                <Buttons
                                  title={'Submit'}
                                  isLoading={false}
                                  onSubmit={verifyForWebcam.handleSubmit}
                                />
                              ) : (
                                <Buttons
                                  title={'Submit'}
                                  isLoading={false}
                                  onSubmit={verifyFormData.handleSubmit}
                                />
                              )}
                            </Grid>
                          </Grid>
                        </>
                      );
                    case 5:
                      return (
                        <>
                          <Box
                            sx={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <AiOutlineClockCircle
                              style={{ fontSize: '3rem', color: '#fff' }}
                            />
                            <Typography
                              sx={{
                                color: '#fff',
                                marginTop: '15px',
                                fontSize: '1.5rem',
                                fontFamilt: 'Jost',
                              }}
                            >
                              {' '}
                              Your ID is under review!
                            </Typography>
                            <Typography
                              style={{
                                color: 'rgba(255, 255, 255, 0.7)',
                                fontSize: '1rem',
                                fontFamilt: 'Jost',
                              }}
                            >
                              {' '}
                              We'll let you know soon, whether it is approved or
                              we need more information about you.
                            </Typography>
                            <Grid item xs={3} sm={3} md={4}>
                              <Buttons
                                title={'Okay'}
                                isLoading={false}
                                onSubmit={() => navigate('/admin/edit-profile')}
                              />
                            </Grid>
                          </Box>
                        </>
                      );
                    default:
                      return null;
                  }
                })()
              )}
            </Card>
          </div>
        </div>
        <div className='dashbard-bg gray-bg' />
      </div>
    </>
  );
};

export default AddPropertyPage;

const VerificationCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;

  h2 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
    color: #fff;
    align-self: flex-start;
  }
`;

const FormLabelStyled = styled.label`
  display: block;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: left;
`;
