import React, { useRef, useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { Grid, Typography, Link, Divider } from '@mui/material';
import { Toast } from 'primereact/toast';
import Input from '../../../components/form/input';
import Buttons from '../../../components/form/button';
import * as yup from 'yup';
import changeUserPassApi from '../../../services/User/changePassword';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import DeactivePopUp from './DeactivePopUp';
import GoogleLogin from 'react-google-login';
import { socialLoginApi } from '../../../services/Authentication/SocialLogin';
import { useSnackbar } from 'notistack';
import { gapi } from 'gapi-script';
import FacebookLoginRender from 'react-facebook-login/dist/facebook-login-render-props';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  color: 'text.primary',
  backgroundColor: '#1b182b',
  boxShadow: 24,
  p: 4,
  textAlign: 'start',
  borderRadius: 5,
};

export default function ChangePassword() {
  const userData = JSON.parse(localStorage.getItem('userData')) || null;
  const toast = useRef();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [data, setData] = React.useState({
    firstName: '',
    lastName: '',
    email: '',
    accessToken: '',
    imageUrl: '',
  });

  const [isLoading, setisLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const passFormik = useFormik({
    initialValues: {
      currentPassword: '',
      password: '',
      confirmPassword: '',
    },

    enableReinitialize: true,
    validationSchema: () => {
      return yup.object({
        currentPassword: yup
          .string()
          .required('Please add your current password!'),
        password: yup.string().required('Please add new password!'),
        confirmPassword: yup
          .string()
          .required('Please re-enter your new password.'),
      });
    },
    onSubmit: async (values) => {
      setisLoading(true);
      const response = await changeUserPassApi(values, userData?.id);
      setisLoading(false);
      if (response?.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Password Updated!',
          detail: 'Your password is updated successfully!',
          life: 10000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Cannot update your password!',
          detail: `${response?.data?.message}`,
          life: 10000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
    },
  });

  const responseGoogleSuccess = async (response) => {
    const userData = response?.profileObj;
    const googleAuthRes = await socialLoginApi({
      email: userData?.email,
      firstName: userData?.givenName,
      lastName: userData?.familyName,
      accessToken: response?.accessToken,
      platform: 'google',
    });

    if (googleAuthRes?.data?.payload?.existingUser) {
      localStorage.setItem(
        'userToken',
        googleAuthRes?.data?.payload?.token?.token
      );
      localStorage.setItem('user', true);
      window.location.reload(true);
      localStorage.setItem(
        'userData',
        JSON.stringify({
          email: googleAuthRes?.data?.payload?.token?.user?.email,
          firstName: googleAuthRes?.data?.payload?.token?.user?.firstName,
          lastName: googleAuthRes?.data?.payload?.token?.user?.lastName,
          id: googleAuthRes?.data?.payload?.token?.user?.id,
          provider: 'google',
          username: googleAuthRes?.data?.payload?.token?.user?.username,
          isHost: googleAuthRes?.data?.payload?.token?.user?.isHost,
          verified: googleAuthRes?.data?.payload?.token?.user?.verified,
          isFacebookVerified:
            googleAuthRes?.data?.payload?.token?.user?.isFacebookVerified,
          isGoogleVerified:
            googleAuthRes?.data?.payload?.token?.user?.isGoogleVerified,
          isDiamondHost:
            googleAuthRes?.data?.payload?.token?.user?.isDiamondHost,
        })
      );
      enqueueSnackbar('Connected.', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    } else {
      setData({
        email: userData?.email,
        firstName: userData?.givenName,
        lastName: userData?.familyName,
        accessToken: response?.accessToken,
        imageUrl: userData?.imageUrl,
      });
    }
  };

  const responseGoogleError = (response) => {
    enqueueSnackbar('Connecting Eror', {
      variant: 'error',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
    });
  };

  const responseFacebook = async (response) => {
    const fbAuthRes = await socialLoginApi({
      email: response?.email,
      firstName: response?.name.split(' ')[0],
      lastName: response?.name.split(' ')[1],
      accessToken: response?.accessToken,
      platform: 'facebook',
    });
    if (fbAuthRes?.data?.payload?.existingUser) {
      localStorage.setItem('userToken', fbAuthRes?.data?.payload?.token?.token);
      localStorage.setItem('user', true);
      window.location.reload(true);
      localStorage.setItem(
        'userData',
        JSON.stringify({
          email: fbAuthRes?.data?.payload?.token?.user?.email,
          firstName: fbAuthRes?.data?.payload?.token?.user?.firstName,
          lastName: fbAuthRes?.data?.payload?.token?.user?.lastName,
          id: fbAuthRes?.data?.payload?.token?.user?.id,
          provider: 'facebook',
          username: fbAuthRes?.data?.payload?.token?.user?.username,
          isHost: fbAuthRes?.data?.payload?.token?.user?.isHost,
          verified: fbAuthRes?.data?.payload?.token?.user?.verified,
          isFacebookVerified:
            fbAuthRes?.data?.payload?.token?.user?.isFacebookVerified,
          isGoogleVerified:
            fbAuthRes?.data?.payload?.token?.user?.isGoogleVerified,
          isDiamondHost: fbAuthRes?.data?.payload?.token?.user?.isDiamondHost,
        })
      );
      enqueueSnackbar('Successfully logged in.', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    } else {
      setData({
        email: response?.email,
        firstName: response?.name.split(' ')[0],
        lastName: response?.name.split(' ')[1],
        accessToken: response?.accessToken,
        profilePicture: response.picture.data.url,
      });
    }
  };

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId:
          '345233581403-1nhti6o026hbs9d8r0bh06gpk3rjuaif.apps.googleusercontent.com',
        scope: 'email',
      });
    }
    gapi.load('client:auth2', start);
  }, []);
  return (
    <>
      <Toast ref={toast} position='bottom-right' />
      <Typography
        variant='h6'
        style={{ color: 'white', textAlign: 'start', marginBottom: 10 }}
      >
        Login
      </Typography>

      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          <Typography style={{ marginTop: 10 }}>Password</Typography>
        </Grid>

        <Grid item xs={6}>
          <Link>
            <Button onClick={handleOpen}>Edit Password</Button>
          </Link>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          >
            <Box sx={style}>
              <div
                className='col-sm-12 col-md-6 col-lg-12 '
                style={{ alignContent: 'center' }}
              >
                <div className='dasboard-widget-title dbt-mm fl-wrap'>
                  <h5>
                    <i className='fas fa-key'></i>Change Password
                  </h5>
                </div>
                <div className='dasboard-widget-box fl-wrap'>
                  <form onSubmit={passFormik.handleSubmit}>
                    <div className='custom-form'>
                      <div className='pass-Input-wrap fl-wrap'>
                        <label>
                          Current Password
                          <span className='dec-icon'>
                            <i className='far fa-lock-open-alt'></i>
                          </span>
                        </label>
                        <Input
                          type='password'
                          className='pass-Input'
                          placeholder=''
                          value={passFormik.values.currentPassword}
                          name={'currentPassword'}
                          id={'currentPassword'}
                          onChange={passFormik.handleChange}
                        />
                        {passFormik.touched.currentPassword &&
                          Boolean(passFormik.errors.currentPassword) && (
                            <small
                              id='username2-help'
                              className='p-error'
                              style={{
                                display: 'block',
                                color: 'red',
                                textAlign: 'left',
                              }}
                            >
                              {passFormik.errors.currentPassword}
                            </small>
                          )}
                      </div>
                      <div className='pass-Input-wrap fl-wrap'>
                        <label>
                          New Password
                          <span className='dec-icon'>
                            <i className='far fa-lock-alt'></i>
                          </span>
                        </label>
                        <Input
                          type='password'
                          className='pass-Input'
                          placeholder=''
                          value={passFormik.values.password}
                          name={'password'}
                          id={'password'}
                          onChange={passFormik.handleChange}
                        />
                        {passFormik.touched.password &&
                          Boolean(passFormik.errors.password) && (
                            <small
                              id='username2-help'
                              className='p-error'
                              style={{
                                display: 'block',
                                color: 'red',
                                textAlign: 'left',
                              }}
                            >
                              {passFormik.errors.password}
                            </small>
                          )}
                      </div>
                      <div className='pass-Input-wrap fl-wrap'>
                        <label>
                          Confirm New Password
                          <span className='dec-icon'>
                            <i className='far fa-shield-check'></i>{' '}
                          </span>
                        </label>
                        <Input
                          type='password'
                          className='pass-Input'
                          placeholder=''
                          value={passFormik.values.confirmPassword}
                          name={'confirmPassword'}
                          id={'confirmPassword'}
                          onChange={passFormik.handleChange}
                        />
                        {passFormik.touched.confirmPassword &&
                          Boolean(passFormik.errors.confirmPassword) && (
                            <small
                              id='username2-help'
                              className='p-error'
                              style={{
                                display: 'block',
                                color: 'red',
                                textAlign: 'left',
                              }}
                            >
                              {passFormik.errors.confirmPassword}
                            </small>
                          )}
                      </div>
                      <Buttons
                        title=' Save Changes'
                        onSubmit={passFormik.handleSubmit}
                        isLoading={isLoading}
                        style={{ marginTop: '10px', borderRadius: '4px' }}
                      />
                    </div>
                  </form>
                </div>
              </div>
              <Typography
                onClick={handleClose}
                style={{ textAlign: 'end', marginTop: 20, cursor: 'pointer' }}
              >
                Close
              </Typography>
            </Box>
          </Modal>
        </Grid>
      </Grid>
      <Divider sx={{ paddingTop: 3, marginBottom: 2 }} />
      <Typography
        variant='h6'
        style={{ color: 'white', textAlign: 'start', marginBottom: 10 }}
      >
        Social accounts
      </Typography>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={8}>
          <Typography style={{ marginTop: 10 }}>
            Facebook <br />
            {userData.isFacebookVerified ? 'Connected' : 'Not connected'}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          {!userData.isFacebookVerified && (
            <FacebookLoginRender
              appId='381535427375487'
              autoLoad={false}
              fields='name,email,picture'
              callback={responseFacebook}
              render={(renderProps) => (
                <>
                  <Typography style={{ marginTop: 10, cursor: 'pointer' }}>
                    <Link onClick={renderProps.onClick}>Connect</Link>
                  </Typography>
                </>
              )}
            />
          )}
        </Grid>
      </Grid>
      <Divider sx={{ paddingTop: 3, marginBottom: 2 }} />
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={8}>
          <Typography style={{ marginTop: 10 }}>
            Google <br />
            {userData.isGoogleVerified ? 'Connected' : 'Not connected'}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          {!userData.isGoogleVerified && (
            <GoogleLogin
              clientId='345233581403-1nhti6o026hbs9d8r0bh06gpk3rjuaif.apps.googleusercontent.com'
              buttonText='Login'
              onSuccess={responseGoogleSuccess}
              onFailure={responseGoogleError}
              cookiePolicy={'single_host_origin'}
              // isSignedIn={true}
              prompt='select_account'
              render={(renderProps) => (
                <>
                  <Typography style={{ marginTop: 10, cursor: 'pointer' }}>
                    <Link
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                    >
                      Connect
                    </Link>
                  </Typography>
                </>
              )}
            />
          )}
        </Grid>
      </Grid>
      <Divider sx={{ paddingTop: 3, marginBottom: 2 }} />
      <Typography
        variant='h6'
        style={{ color: 'white', textAlign: 'start', marginBottom: 10 }}
      >
        Deactive
      </Typography>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={8}>
          <Typography style={{ marginTop: 10 }}>
            {'Deactive Your Acount'}{' '}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography
            style={{
              marginTop: 10,
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
          >
            <Link>
              <DeactivePopUp />
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
