import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Buttons from '../../../components/form/button';
import ModifyBookingDatePicker from '../../../components/modifyBookingDatePicker';
import { bookingUpdateRequestApi } from '../../../services/booking/bookingUpdateRequest';
import { ModifyBookingGuestCount } from '../../../components/shared/ModifyBookingGuestCount';
import { useEffect } from 'react';
import moment from 'moment';
import { Toast } from 'primereact/toast';

export const ModifyBookingCard = ({
  propertyDetail,
  booking,
  setModifiedValues,
  useWallet,
  useCreditCard,
  noActiveCard,
  card,
  formik,
  propertyPricePolicy,
}) => {
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  let userData = JSON.parse(localStorage.getItem('userData'));
  let userHost = JSON.parse(localStorage.getItem('userHost'));
  // alert(!userHost);
  const basicInfo = useFormik({
    initialValues: {
      booking_id: booking?.id,
      startDate: moment(booking?.endDate),
      endDate: '',
      noOfGuest: booking?.totalGuests,
      noOfAdults: booking?.totalAdults,
      noOfChildren: booking?.totalChildren,
      noOfInfants: booking?.totalInfants,
      noOfPets: booking?.totalPets,
      user_id: userData?.id,
    },
    enableReinitialize: true,
    validationSchema: () => {
      return yup.object({
        startDate: yup.string().required('start date is required is required'),
        endDate: yup.string().required('end date is required is required'),
        noOfGuest: yup
          .number()
          .min(1)
          .required('Please select number of guests'),
      });
    },
    onSubmit: async (values) => {
      if (!useWallet && !useCreditCard && noActiveCard && !userHost) {
        toast.current.show({
          severity: 'info',
          summary: 'No payment method selected',
          detail: 'Please select a payment method to proceed your booking!',
          life: 10000,
        });
      } else {
        //If user only wants to be charged through wallet
        if (useWallet && noActiveCard && !useCreditCard) {
          setLoading(true);
          const response = await bookingUpdateRequestApi({
            user_id: values.user_id,
            booking_id: values.booking_id,
            paymentMethod: 'WALLET',
            startDate: moment(values.startDate).format('YYYY-MM-DD'),
            endDate: values.endDate,
            noOfGuest: values.noOfGuest,
            noOfAdults: values.noOfAdults,
            noOfChildren: values.noOfChildren,
            noOfInfants: values.noOfInfants,
            noOfPets: values.noOfPets,
          });
          if (response.status === 200) {
            setLoading(false);
            toast.current.show({
              severity: 'success',
              summary: 'Booking Done',
              detail: 'Your booking is created successfully!',
              life: 10000,
            });
          } else {
            setLoading(false);
            toast.current.show({
              severity: 'error',
              summary: "Something's wrong!",
              detail: "Sorry couldn't create your booking!",
              life: 10000,
            });
          }
        } else if (noActiveCard && !useWallet && !useCreditCard && !userHost) {
          setLoading(false);
          toast.current.show({
            severity: 'error',
            summary: 'No Payment method selected!',
            detail: 'Please select a payment method to continue.',
            life: 10000,
          });
        }
        //If user only wants to be charged through exisiting card
        else if (card && !useWallet && !useCreditCard) {
          setLoading(true);
          const response = await bookingUpdateRequestApi({
            user_id: values.user_id,
            booking_id: values.booking_id,
            card_id: card?.value?.id,
            paymentMethod: 'CARD',
            startDate: moment(values.startDate).format('YYYY-MM-DD'),
            endDate: values.endDate,
            noOfGuest: values.noOfGuest,
            noOfAdults: values.noOfAdults,
            noOfChildren: values.noOfChildren,
            noOfInfants: values.noOfInfants,
            noOfPets: values.noOfPets,
          });
          if (response.status === 200) {
            setLoading(false);
            toast.current.show({
              severity: 'success',
              summary: 'Booking Done',
              detail: 'Your booking is created successfully!',
              life: 10000,
            });
          } else {
            setLoading(false);
            toast.current.show({
              severity: 'error',
              summary: "Something's wrong!",
              detail: "Sorry couldn't create your booking!",
              life: 10000,
            });
          }
        }
        //If user only wants to be charged by adding new card
        else if (useCreditCard && !useWallet && noActiveCard) {
          setLoading(true);
          const response = await bookingUpdateRequestApi({
            name: formik.values.name,
            number: formik.values.number,
            exp_month: formik.values.exp_month,
            exp_year: formik.values.exp_year,
            cvc: formik.values.cvc,
            user_id: values.user_id,
            booking_id: values.booking_id,
            address1: formik.values.address1,
            address2: formik.values.address2,
            city: formik.values.city,
            state: formik.values.state,
            zipcode: formik.values.zipcode,
            country: formik.values.country?.name,
            paymentMethod: 'CARD',
            startDate: moment(values.startDate).format('YYYY-MM-DD'),
            endDate: values.endDate,
            noOfGuest: values.noOfGuest,
            noOfAdults: values.noOfAdults,
            noOfChildren: values.noOfChildren,
            noOfInfants: values.noOfInfants,
            noOfPets: values.noOfPets,
          });
          if (response.status === 200) {
            setLoading(false);
            toast.current.show({
              severity: 'success',
              summary: 'Booking Done',
              detail: 'Your booking is created successfully!',
              life: 10000,
            });
          } else {
            setLoading(false);
            toast.current.show({
              severity: 'error',
              summary: "Something's wrong!",
              detail: "Sorry couldn't create your booking!",
              life: 10000,
            });
          }
        }
        //If user wants to be charged by through wallet + exisiting card
        else if (useWallet && Object.keys(card).length > 0 && !useCreditCard) {
          setLoading(true);
          const response = await bookingUpdateRequestApi({
            user_id: values.user_id,
            booking_id: values.booking_id,
            card_id: card?.value?.id,
            paymentMethod: 'CARD_PLUS_WALLET',
            startDate: moment(values.startDate).format('YYYY-MM-DD'),
            endDate: values.endDate,
            noOfGuest: values.noOfGuest,
            noOfAdults: values.noOfAdults,
            noOfChildren: values.noOfChildren,
            noOfInfants: values.noOfInfants,
            noOfPets: values.noOfPets,
          });
          if (response.status === 200) {
            setLoading(false);
            toast.current.show({
              severity: 'success',
              summary: 'Booking Done',
              detail: 'Your booking is created successfully!',
              life: 10000,
            });
          } else {
            setLoading(false);
            toast.current.show({
              severity: 'error',
              summary: "Something's wrong!",
              detail: "Sorry couldn't create your booking!",
              life: 10000,
            });
          }
        }
        //If user wants to be charged through wallet + by adding new card
        else if (useWallet && useCreditCard && noActiveCard) {
          setLoading(true);
          const response = await bookingUpdateRequestApi({
            name: formik.values.name,
            number: formik.values.number,
            exp_month: formik.values.exp_month,
            exp_year: formik.values.exp_year,
            cvc: formik.values.cvc,
            user_id: values.user_id,
            booking_id: values.booking_id,
            paymentMethod: 'CARD_PLUS_WALLET',
            address1: formik.values.address1,
            address2: formik.values.address2,
            city: formik.values.city,
            state: formik.values.state,
            zipcode: formik.values.zipcode,
            country: formik.values.country?.name,
            startDate: moment(values.startDate).format('YYYY-MM-DD'),
            endDate: values.endDate,
            noOfGuest: values.noOfGuest,
            noOfAdults: values.noOfAdults,
            noOfChildren: values.noOfChildren,
            noOfInfants: values.noOfInfants,
            noOfPets: values.noOfPets,
          });
          if (response.status === 200) {
            setLoading(false);
            toast.current.show({
              severity: 'success',
              summary: 'Booking Done',
              detail: 'Your booking is created successfully!',
              life: 10000,
            });
          } else {
            setLoading(false);
            toast.current.show({
              severity: 'error',
              summary: "Something's wrong!",
              detail: "Sorry couldn't create your booking!",
              life: 10000,
            });
          }
        }
      }
    },
  });
  const handleGuestCountChange = async (value, count) => {
    basicInfo.setFieldValue('noOfGuest', value);
    basicInfo.setFieldValue('noOfAdults', count.adults);
    basicInfo.setFieldValue('noOfChildren', count.children);
    basicInfo.setFieldValue('noOfInfants', count.infants);
    basicInfo.setFieldValue('noOfPets', count.pets);
  };

  useEffect(() => {
    setModifiedValues(basicInfo.values);
  }, [basicInfo.values]);

  console.log('propertyDetail:', propertyDetail);
  return (
    <ReserveCardStyled>
      <Toast ref={toast} position='bottom-right' />

      <div
        className='box-widget fl-wrap'
        style={{
          width: '40vw',
        }}
      >
        <div className='box-widget-fixed-init fl-wrap output' id='sec-contact'>
          <div className='box-widget-title fl-wrap box-widget-title-color color-bg'>
            Modify Booking
          </div>
          <div className='box-widget-content fl-wrap'>
            <div className='custom-form'>
              <div className='row'>
                <div
                  className='col-md-12'
                  style={{
                    textAlign: 'left',
                  }}
                >
                  <ModifyBookingDatePicker
                    basePrice={propertyDetail?.perNightPrice}
                    propertyPricePolicy={propertyDetail?.propertyPricePolicy}
                    handlers
                    handleDateEnd={(value) =>
                      basicInfo.setFieldValue(
                        'endDate',
                        moment(value).format('YYYY-MM-DD')
                      )
                    }
                    startDate={booking?.startDate}
                    endDate={booking?.endDate}
                    id={propertyDetail?.id}
                  />
                </div>
                <div
                  className='col-md-12'
                  style={{
                    textAlign: 'left',
                    marginTop: '14px',
                  }}
                >
                  <ModifyBookingGuestCount
                    propertyDetail={propertyDetail}
                    handleChange={handleGuestCountChange}
                    adults={basicInfo.values.noOfAdults}
                    children={basicInfo.values.noOfChildren}
                    pets={basicInfo.values.noOfPets}
                    infants={basicInfo.values.noOfInfants}
                  />
                </div>
              </div>

              <Buttons
                style={{ height: 50, marginTop: '20px' }}
                title={'Send Modification Request'}
                onSubmit={basicInfo.handleSubmit}
                isLoading={loading}
              />

              <small
                id='username2-help'
                className='p-error'
                style={{
                  display: 'block',
                  color: 'red',
                  textAlign: 'left',
                }}
              >
                {basicInfo.touched.startDate && basicInfo.errors.startDate}{' '}
                <br />
                {basicInfo.touched.endDate && basicInfo.errors.endDate}
                <br />
                {basicInfo.touched.noOfGuest && basicInfo.errors.noOfGuest}
              </small>
            </div>
          </div>
        </div>
      </div>
    </ReserveCardStyled>
  );
};

const ReserveCardStyled = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: -160px;
  width: 100%;

  .label-field {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 10px;
    display: block;
  }
`;
