import React, { useEffect, useState } from 'react';
import { Grid, Tab, Box } from '@mui/material';
import { Toast } from 'primereact/toast';
import { TabMenu } from 'primereact/tabmenu';
import { Paginator } from 'primereact/paginator';
import Card from '../../../components/dasboard/card';
import InfoBar from '../../../components/dasboard/infoBar';
import Sidebar from '../../../components/dasboard/sidebar';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Buttons from '../../../components/form/button';
import Input from '../../../components/form/input';
import Header from '../../../components/header';
import Property from '../../../components/mainComponents/property';
// import { getBookingApi } from "../../../services/booking/getBooking";
import getBookingOwnerAPI, {
  getBookingOwnerApi,
} from '../../../services/booking/getBookingOwner';
import ErrorMessage from '../../../components/errorMessage';
import SkeletonLoader from '../../../components/skeletonLoader';
import Booking from '../../../components/BookingComponents/Booking';
import { useRef } from 'react';
import { confirmDialog } from 'primereact/confirmdialog';
import moment from 'moment';
import { updateBookingApi } from '../../../services/booking/updateBookingStatus';
// import { sum } from 'mathjs'
import { getCommissionFee } from './../../../services/commissionFee/CommissionFee';

const BookingPendingPage = () => {
  const { getPendingData } = getBookingOwnerAPI();
  const [properties, setProperties] = useState([]);
  const [filteredProperty, setFilteredProperty] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [basicFirst, setBasicFirst] = useState(0);
  const [basicRows, setBasicRows] = useState(10);
  const [commissionFee, setCommissionFee] = useState(0);
  const [value, setValue] = React.useState('Active');
  const [activePropLength, setActivePropLength] = useState([]);
  const [expiredPropLength, setExpiredPropLength] = useState([]);

  const [filterToggle, setFilterToggle] = useState(false);
  const userType = localStorage.getItem('userHost') || true;

  if (!userType) {
    window.location = '/';
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onBasicPageChange = (event) => {
    setBasicFirst(event.first);
    setBasicRows(event.rows);
  };

  const getReturnValues = (countDown) => {
    // calculate time left
    const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

    return [days, hours, minutes, seconds];
  };

  const getPropertyListings = async () => {
    setIsLoading(true);
    try {
      const response = await getPendingData();
      if (response.status === 200) {
        let activeArr = [];
        let expiredArr = [];
        const THREE_DAYS_IN_MS = 3 * 24 * 60 * 60 * 1000;
        response.data?.data.map((item) => {
          const NOW_IN_MS = new Date(item.createdOn).getTime();
          const dateTimeAfterThreeDays = NOW_IN_MS + THREE_DAYS_IN_MS;
          let newwtimeee = dateTimeAfterThreeDays - new Date().getTime();
          const [days, hours, minutes, seconds] = getReturnValues(newwtimeee);
          if (days + hours + minutes + seconds <= 0) {
            expiredArr.push(item);
          } else {
            activeArr.push(item);
          }
        });
        setExpiredPropLength(expiredArr.length);
        setActivePropLength(activeArr.length);
        if (value == 'Active') {
          setProperties(activeArr);
          setFilteredProperty(activeArr);
        } else {
          setProperties(expiredArr);
          setFilteredProperty(expiredArr);
        }
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPropertyListings();
  }, [value]);

  useEffect(() => {
    getCommissionFee()
      .then((res) => {
        setCommissionFee(res.data.data[0].commission_percentage);
      })
      .catch((err) => {});
  }, []);

  const toast = useRef(null);

  const accept = async (id) => {
    try {
      const response = await updateBookingApi(id, 'accepted');
      if (response.status === 200) {
        // setLoading(false);
        toast.current.show({
          severity: 'info',
          summary: 'Booking Accepted',
          life: 3000,
        });
      }
    } catch (err) {
      // setLoading(false);
      toast.current.show({
        severity: 'warn',
        summary: 'Not Accepted',
        detail: 'there seems to be an error right now',
        life: 3000,
      });
    }
  };

  const reject = () => {
    toast.current.show({
      severity: 'warn',
      summary: 'Cancelled',
      detail: 'You have cancelled booking',
      life: 3000,
    });
  };

  const confirm1 = (booking) => {
    confirmDialog({
      message: (
        <div
          style={{
            width: '30vw',
            textAlign: 'left',
            paddingTop: '20px',
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{ overflowY: 'scroll', maxHeight: '250px' }}
          >
            <Grid item xs={6}>
              <div className=''>
                <h1>Name</h1>
                <p>
                  {booking?.owner.firstName + ' ' + booking?.owner.lastName}
                </p>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <h1>PROFILE</h1>
                <img
                  width={50}
                  height={50}
                  src={
                    booking?.owner?.profilePicture !== null
                      ? booking?.owner?.profilePicture
                      : require('../../../assets/images/avatar/5.jpg')
                  }
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <h1>GUEST</h1>
                <p>
                  <i className='fas fa-user'></i>{' '}
                  {booking?.totalAdults +
                    booking?.totalChildren +
                    booking?.totalInfants +
                    booking?.totalPets}
                </p>
                <p>
                  {booking?.totalAdults > 0
                    ? booking?.totalAdults + ' Adults'
                    : ''}
                  {booking?.totalAdults > 0 && booking?.totalChildren > 0
                    ? ' - '
                    : ''}
                  {booking?.totalChildren > 0
                    ? booking?.totalChildren + ' Children'
                    : ''}
                </p>
                <p>
                  {booking?.totalInfants > 0
                    ? booking?.totalInfants + ' Infants'
                    : ''}
                  {booking?.totalInfants > 0 && booking?.totalPets > 0
                    ? ' - '
                    : ''}
                  {booking?.totalPets > 0 ? booking?.totalPets + ' Pets' : ''}
                </p>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <h1>BOOKING DATE</h1>
                <p>{moment(booking?.createdOn).format('DD MMM YYYY')}</p>
              </div>
            </Grid>
            <Grid item xs={12} sx={{ pr: 2 }}>
              <div>
                <h1>PAYMENT INVOICE</h1>
                <div
                  style={{
                    display: 'flex',
                    marginTop: '20px',
                    justifyContent: 'space-between',
                  }}
                >
                  <p>{booking?.numberOfNights} nights </p>
                  <p>
                    {process.env.REACT_APP_CURRENCY}{' '}
                    {booking?.totalAmount?.toLocaleString('en-US')}
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <p>GST 13%</p>
                  <p>
                    {process.env.REACT_APP_CURRENCY}{' '}
                    {booking?.totalGST?.toLocaleString('en-US')}
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <p>Total</p>
                  <p>
                    {process.env.REACT_APP_CURRENCY}{' '}
                    {(booking?.totalAmount + booking?.totalGST)?.toLocaleString(
                      'en-US'
                    )}
                  </p>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      ),
      header: 'Confirmation',
      acceptLabel: 'Accept',
      rejectLabel: 'Decline',
      header: 'Confirmation',
      accept: () => accept(booking.id),
      reject,
    });
  };

  return (
    <>
      <Header />
      <Toast ref={toast} position='bottom-right' />
      <div id='wrapper'>
        <div className='dashboard-menu-overlay' />
        <Sidebar page={'Reservation Requests'} />
        <div className='dashboard-content'>
          <div className='dashboard-menu-btn color-bg'>
            <span>
              <i className='fas fa-bars' />
            </span>
            Dasboard Menu
          </div>
          {/* {isOpen && <Dropdown/>} */}
          <InfoBar label={'Reservation Requests'} />

          <Card>
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList
                    onChange={handleChange}
                    aria-label='lab API tabs example'
                  >
                    <Tab
                      sx={{ fontSize: '14px' }}
                      label='Active'
                      value='Active'
                      icon={
                        <div
                          style={{
                            background: '#0095ff',
                            borderRadius: '50%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '0 5px',
                          }}
                        >
                          <p
                            style={{
                              paddingBottom: 0,
                              color: '#fff',
                            }}
                          >
                            {activePropLength}
                          </p>
                        </div>
                      }
                      iconPosition='end'
                    />
                    <Tab
                      sx={{ fontSize: '14px' }}
                      label='Expired'
                      value='Expired'
                      icon={
                        <div
                          style={{
                            background: '#0095ff',
                            borderRadius: '50%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '0 5px',
                          }}
                        >
                          <p
                            style={{
                              paddingBottom: 0,
                              color: '#fff',
                            }}
                          >
                            {expiredPropLength}
                          </p>
                        </div>
                      }
                      iconPosition='end'
                    />
                  </TabList>
                </Box>

                <TabPanel value='Active'>
                  {isLoading === true ? (
                    <Loader />
                  ) : (
                    <>
                      {properties?.length === 0 ? (
                        <Grid container>
                          <Grid item xs={12}>
                            <ErrorMessage />
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid
                          container
                          rowSpacing={{ xs: 1, sm: 1, md: 1 }}
                          columnSpacing={{ xs: 1, sm: 1, md: 2 }}
                        >
                          {filterToggle
                            ? filteredProperty.map((booking) => {
                                return (
                                  <Grid item xs={12} sm={6} md={12}>
                                    <Booking
                                      booking={booking}
                                      confirm1={confirm1}
                                      getPropertyListings={getPropertyListings}
                                      commissionFee={commissionFee}
                                      pageType='REQUESTS'
                                    />
                                  </Grid>
                                );
                              })
                            : filteredProperty
                                ?.slice(basicFirst, basicRows + basicFirst)
                                .map((booking) => {
                                  return (
                                    <Grid item xs={12} sm={6} md={12}>
                                      <Booking
                                        booking={booking}
                                        confirm1={confirm1}
                                        getPropertyListings={
                                          getPropertyListings
                                        }
                                        commissionFee={commissionFee}
                                        pageType='REQUESTS'
                                      />
                                    </Grid>
                                  );
                                })}
                        </Grid>
                      )}
                    </>
                  )}

                  <Card>
                    <Paginator
                      first={basicFirst}
                      rows={basicRows}
                      totalRecords={properties?.length}
                      rowsPerPageOptions={[5, 10, 20, 30]}
                      onPageChange={onBasicPageChange}
                    ></Paginator>
                  </Card>
                </TabPanel>
                <TabPanel value='Expired'>
                  {isLoading === true ? (
                    <Loader />
                  ) : (
                    <>
                      {properties?.length === 0 ? (
                        <Grid container>
                          <Grid item xs={12}>
                            <ErrorMessage />
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid
                          container
                          rowSpacing={{ xs: 1, sm: 1, md: 1 }}
                          columnSpacing={{ xs: 1, sm: 1, md: 2 }}
                        >
                          {filterToggle
                            ? filteredProperty.map((booking) => {
                                return (
                                  <Grid item xs={12} sm={6} md={12}>
                                    <Booking
                                      booking={booking}
                                      confirm1={confirm1}
                                      getPropertyListings={getPropertyListings}
                                      commissionFee={commissionFee}
                                      pageType='REQUESTS'
                                    />
                                  </Grid>
                                );
                              })
                            : filteredProperty
                                ?.slice(basicFirst, basicRows + basicFirst)
                                .map((booking) => {
                                  return (
                                    <Grid item xs={12} sm={6} md={12}>
                                      <Booking
                                        booking={booking}
                                        confirm1={confirm1}
                                        getPropertyListings={
                                          getPropertyListings
                                        }
                                        commissionFee={commissionFee}
                                        pageType='REQUESTS'
                                      />
                                    </Grid>
                                  );
                                })}
                        </Grid>
                      )}
                    </>
                  )}

                  <Card>
                    <Paginator
                      first={basicFirst}
                      rows={basicRows}
                      totalRecords={properties?.length}
                      rowsPerPageOptions={[5, 10, 20, 30]}
                      onPageChange={onBasicPageChange}
                    ></Paginator>
                  </Card>
                </TabPanel>
              </TabContext>
            </Box>
          </Card>
        </div>
        <div className='dashbard-bg gray-bg' />
      </div>
    </>
  );
};

const Loader = () => {
  return (
    <Grid
      container
      rowSpacing={{ xs: 1, sm: 1, md: 2 }}
      columnSpacing={{ xs: 1, sm: 1, md: 2 }}
    >
      <Grid item xs={12} sm={6} md={6}>
        <SkeletonLoader height={200} />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <SkeletonLoader height={200} />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <SkeletonLoader height={200} />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <SkeletonLoader height={200} />
      </Grid>
    </Grid>
  );
};

export default BookingPendingPage;
