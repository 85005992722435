import React from "react";
import { Link } from "react-router-dom";
const Sidebar = ({ page }) => {
  const userType = localStorage.getItem("userHost") || true;
  return (
    <>
      <div className='dashbard-menu-wrap'>
        <div className='dashbard-menu-close'>
          <i className='fal fa-times'></i>
        </div>
        <div className='dashbard-menu-container'>
          {userType === "true" && (
            <div className='user-profile-menu'>
              <h3>Main</h3>
              <ul className='no-list-style'>
                <li>
                  <Link
                    style={{ textDecoration: "none" }}
                    to='/admin/dashboard'
                    className={page === "Dashboard" ? "user-profile-act" : null}
                  >
                    <i className='fal fa-chart-line'></i>Dashboard
                  </Link>
                </li>
              </ul>
            </div>
          )}
          <div className='user-profile-menu'>
            <h3>Inbox</h3>
            <ul className='no-list-style'>
              <li>
                <Link
                  style={{ textDecoration: "none" }}
                  to='/admin/notifications'
                  className={
                    page === "Notifications" ? "user-profile-act" : null
                  }
                >
                  <i className='fal fa-bell'></i> Notifications{" "}
                </Link>
              </li>
              <li>
                <Link
                  style={{ textDecoration: "none" }}
                  to='/admin/messages'
                  className={page === "Messages" ? "user-profile-act" : null}
                >
                  <i className='fal fa-comment-alt-lines'></i> Messages{" "}
                </Link>
              </li>
            </ul>
          </div>
          {userType === "true" ? (
            <div>
              <div className='user-profile-menu'>
                <h3>Reservations</h3>
                <ul className='no-list-style'>
                  <li>
                    <Link
                      style={{ textDecoration: "none" }}
                      to='/admin/booking-pending'
                      className={
                        page === "Reservation Requests"
                          ? "user-profile-act"
                          : null
                      }
                    >
                      <i className='fal fa-home-heart'></i> Reservation Requests{" "}
                    </Link>
                  </li>
                  <li>
                    <Link
                      style={{ textDecoration: "none" }}
                      to='/admin/bookings'
                      className={
                        page === "Confirmed Reservations"
                          ? "user-profile-act"
                          : null
                      }
                    >
                      <i className='fal fa-home-heart'></i> Confirmed
                      Reservations{" "}
                    </Link>
                  </li>
                  <li>
                    <Link
                      style={{ textDecoration: "none" }}
                      to='/admin/current-reservations'
                      className={
                        page === "Current Reservations"
                          ? "user-profile-act"
                          : null
                      }
                    >
                      <i className='fal fa-calendar-alt'></i> Current
                      Reservations{" "}
                    </Link>
                  </li>
                  <li>
                    <Link
                      style={{ textDecoration: "none" }}
                      to='/admin/upcoming-reservation'
                      className={
                        page === "Upcoming Reservations"
                          ? "user-profile-act"
                          : null
                      }
                    >
                      <i className='fal fa-calendar-check'></i> Upcoming
                      Reservations{" "}
                    </Link>
                  </li>
                  <li>
                    <Link
                      style={{ textDecoration: "none" }}
                      to='/admin/previous-reservation'
                      className={
                        page === "Past Reservations" ? "user-profile-act" : null
                      }
                    >
                      <i className='fal fa-calendar-alt'></i> Past Reservations{" "}
                    </Link>
                  </li>
                </ul>
              </div>
              <div className='user-profile-menu'>
                <h3>Properties</h3>
                <ul className='no-list-style'>
                  <li>
                    <Link
                      style={{ textDecoration: "none" }}
                      to='/admin/property-listing'
                      className={
                        page === "My Listing" ? "user-profile-act" : null
                      }
                    >
                      <i className='fal fa-home-heart'></i> My Listing{" "}
                    </Link>
                  </li>
                  <li>
                    <Link
                      style={{ textDecoration: "none" }}
                      to='/admin/add-property'
                      className={
                        page === "Add New Property" ? "user-profile-act" : null
                      }
                    >
                      <i className='fal fa-file-plus'></i> Add New Property
                    </Link>
                  </li>
                  <li>
                    <Link
                      style={{ textDecoration: "none" }}
                      to='/admin/my-earnings'
                      className={
                        page === "My Earnings" ? "user-profile-act" : null
                      }
                    >
                      <i className='fal fa-credit-card'></i> My Earnings
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          ) : (
            <div className='user-profile-menu'>
              <h3>Your Trips</h3>
              <ul className='no-list-style'>
                <li>
                  <Link
                    style={{ textDecoration: "none" }}
                    className={
                      page === "Pending Trips" ? "user-profile-act" : null
                    }
                    to='/admin/pending-trips'
                  >
                    <i className='fal fa-calendar-exclamation'></i> Pending
                    Trips{" "}
                  </Link>
                </li>

                {/* <li>
                  <a
                    style={{ textDecoration: 'none' }}
                    href="/admin/pending-trips"
                    className={
                      page === 'Pending Trips' ? 'user-profile-act' : null
                    }
                  >
                    <i className="fal fa-calendar-exclamation"></i> Pending
                    Trips{' '}
                  </a>
                </li> */}

                <li>
                  <Link
                    style={{ textDecoration: "none" }}
                    to='/admin/awaiting-trips'
                    className={
                      page === "Awaiting Payment" ? "user-profile-act" : null
                    }
                  >
                    <i className='fal fa-calendar-exclamation'></i> Awaiting
                    Payments{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    style={{ textDecoration: "none" }}
                    to='/admin/current-trips'
                    className={
                      page === "Current Trips" ? "user-profile-act" : null
                    }
                  >
                    <i className='fal fa-calendar-day'></i> Current Trips{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    style={{ textDecoration: "none" }}
                    to='/admin/upcoming-trips'
                    className={
                      page === "Upcoming Trips" ? "user-profile-act" : null
                    }
                  >
                    <i className='fal fa-luggage-cart'></i> Upcoming Trips{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    style={{ textDecoration: "none" }}
                    to='/admin/previous-trips'
                    className={
                      page === "Previous Trips" ? "user-profile-act" : null
                    }
                  >
                    <i className='fal fa-calendar-minus'></i> Previous Trips{" "}
                  </Link>
                </li>
              </ul>
            </div>
          )}
          <div className='user-profile-menu'>
            <h3>Requests</h3>
            <ul className='no-list-style'>
              <li>
                <Link
                  style={{ textDecoration: "none" }}
                  className={
                    page === "Manage Requests" ? "user-profile-act" : null
                  }
                  to='/admin/manage-requests'
                >
                  <i className='fal fa-calendar-exclamation'></i>
                  Manage Requests
                </Link>
              </li>
            </ul>
          </div>
          <div className='user-profile-menu'>
            <h3>Profile</h3>
            <ul className='no-list-style'>
              <li>
                <Link
                  style={{ textDecoration: "none" }}
                  to='/admin/edit-profile'
                  className={
                    page === "Edit Profile" ? "user-profile-act" : null
                  }
                >
                  <i className='fal fa-user-edit'></i> Edit Profile{" "}
                </Link>
              </li>

              <li>
                <Link
                  style={{ textDecoration: "none" }}
                  to='/admin/id-verification'
                  className={
                    page === "Trust & Verification" ? "user-profile-act" : null
                  }
                >
                  <i className='fal fa-badge-check'></i> Trust & Verification{" "}
                </Link>
              </li>
            </ul>
          </div>
          <div className='user-profile-menu'>
            <h3>Account</h3>
            <ul className='no-list-style'>
              <li>
                <Link
                  style={{ textDecoration: "none" }}
                  to='/wallet'
                  className={page === "Wallet" ? "user-profile-act" : null}
                >
                  <i className='fal fa-credit-card'></i>Wallet
                </Link>
              </li>
              {userType === "true" ? (
                <li>
                  <Link
                    style={{ textDecoration: "none" }}
                    to='/admin/bank-account'
                    className={
                      page === "Payout Preference" ? "user-profile-act" : null
                    }
                  >
                    <i className='fal fa-credit-card'></i> Payout Preference{" "}
                  </Link>
                </li>
              ) : (
                <>
                  <li>
                    <Link
                      style={{ textDecoration: "none" }}
                      to='/admin/wishlist'
                      className={
                        page === "Add New Property" ? "user-profile-act" : null
                      }
                    >
                      <i className='fa fa-heart-o'></i> Wishlist
                    </Link>
                  </li>
                  <li>
                    <Link
                      style={{ textDecoration: "none" }}
                      to='/admin/wallet-transaction-history'
                      className={
                        page === "Wallet Transaction History"
                          ? "user-profile-act"
                          : null
                      }
                    >
                      <i className='fal fa-money-check-edit'></i> Wallet
                      Transaction History{" "}
                    </Link>
                  </li>
                  <li>
                    <Link
                      style={{ textDecoration: "none" }}
                      to='/admin/booking-transaction-history'
                      className={
                        page === "Booking Transaction History"
                          ? "user-profile-act"
                          : null
                      }
                    >
                      <i className='fal fa-money-check-edit'></i> Booking
                      Transaction History{" "}
                    </Link>
                  </li>
                  <li>
                    <Link
                      style={{ textDecoration: "none" }}
                      to='/admin/login-and-security'
                      className={
                        page === "Security" ? "user-profile-act" : null
                      }
                    >
                      <i className='fal fa-user-lock'></i> Security{" "}
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
        <div className='dashbard-menu-footer'>
          &#169; © Staywo Inc.USA 2022 . All rights reserved..
        </div>
      </div>
    </>
  );
};

{
  /* <div className="dashbard-menu-wrap">
        <div className="dashbard-menu-close">
          <i className="fal fa-times"></i>
        </div>

        <div className="dashbard-menu-container">
          <div className="user-profile-menu">
            <h3>Main</h3>
            <ul className="no-list-style">
              <li>
                <a href="dashboard.html">
                  <i className="fal fa-chart-line"></i>Dashboard
                </a>
              </li>
              <li>
                <a href="dashboard-myprofile.html">
                  <i className="fal fa-user-edit"></i> Edit profile
                </a>
              </li>
              <li>
                <a href="dashboard-messages.html">
                  <i className="fal fa-envelope"></i> Messages <span>3</span>
                </a>
              </li>
              <li>
                <a href="dashboard-agents.html">
                  <i className="fal fa-users"></i> Agents List
                </a>
              </li>
              <li>
                <a href="#" className="submenu-link">
                  <i className="fal fa-plus"></i>Submenu
                </a>
                <ul className="no-list-style">
                  <li>
                    <a href="#">
                      <i className="fal fa-th-list"></i> Submenu 2{" "}
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fal fa-calendar-check"></i> Submenu 2
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fal fa-comments-alt"></i>Submenu 2
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fal fa-file-plus"></i> Submenu 2
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>

          <div className="user-profile-menu">
            <h3>Listings</h3>
            <ul className="no-list-style">
              <li>
                <a href="dashboard-listing-table.html">
                  <i className="fal fa-th-list"></i> My listigs{" "}
                </a>
              </li>
              <li>
                <a href="dashboard-bookings.html">
                  {" "}
                  <i className="fal fa-calendar-check"></i> Bookings{" "}
                  <span>3</span>
                </a>
              </li>
              <li>
                <a href="dashboard-review.html">
                  <i className="fal fa-comments-alt"></i> Reviews <span>2</span>{" "}
                </a>
              </li>
              <li>
                <a
                  href="dashboard-add-listing.html"
                  className="user-profile-act"
                >
                  <i className="fal fa-file-plus"></i> Add New
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="dashbard-menu-footer">
          &#169; All rights reserved 2022.
        </div>
      </div> */
}

export default Sidebar;
