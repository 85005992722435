import { API_URL } from '../../config';
import { axiosPrivate } from '../../api/axios';

const getUserApi = async (id) => {
  try {
    const response = await axiosPrivate.get(
      API_URL + `users/user-profile/${id}`
    );
    return response.data;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('bad response of get user: ', response);
      return response;
    }
  }
};
export default getUserApi;
