import React, { useRef, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { Clear } from '@mui/icons-material';
import { Toast } from 'primereact/toast';
import getBookingOwnerAPI from '../../../services/booking/getBookingOwner';
function ViewCancel({ open, handleClose, scroll, descriptionElementRef, booking }) {
    const toast = useRef(null);
    const { getCancellationData,getCancellationDataUpdate } = getBookingOwnerAPI()
    const id = booking?.id
    const [cancelData, setCancelData] = useState([])
    const cancelID = cancelData?.id
    const cancellationData = {
        comment: cancelData.comment,
        status: cancelData.status,
        reimbursementSatus: cancelData.reimbursementStatus,
        reimbursementDate: cancelData.reimbursementDate
    }
    const rejectRequest = () => {
        handleClose()
        toast.current.show({
            severity: 'error',
            detail: 'Booking cancelation reject',
            life: 10000,
        });
    }
    const acceptRequest = async () => {
        const responseData = await getCancellationDataUpdate(cancelID, cancellationData)
        if(responseData.status === 200){
            handleClose()
            toast.current.show({
                severity: 'success',
                detail: 'Booking cancelation accept',
                life: 10000,
            });
        }
    }
    const getCancellation = async () => {
        const responseData = await getCancellationData(id)
        if (responseData.status === 200) {
            setCancelData(responseData?.data);
        }
    }
    useEffect(() => {
        getCancellation()
    }, [])
    return (
        <div>
            <Toast ref={toast} position="bottom-right" />
            <Dialog
                open={open}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    sx={{
                        fontFamily: 'Jost',
                        fontSize: '25px',
                        borderBottom: '0.5px solid #0095ff',
                        background: '#17212f',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Typography sx={{ fontFamily: 'Jost', fontSize: '25px' }}>
                            Cancellation Reason
                        </Typography>
                        <Button
                            onClick={rejectRequest}
                            variant="outlined"
                            sx={{ fontFamily: 'Jost', fontSize: '14px' }}
                        >
                            <Clear />
                        </Button>
                    </Box>
                </DialogTitle>
                <DialogContent
                    sx={{ width: '35vw', background: '#1c172b' }}
                >
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                        sx={{ width: '100%', py: 3 }}
                    >
                        <Box>
                            <Typography sx={{ borderBottom: '0.5px solid #0095ff' }}>Reason</Typography>
                            <Typography sx={{ py: 2 }}>{cancelData?.reason}</Typography>
                            {
                                cancelData.comment && (
                                    <>
                                        <Typography sx={{ borderBottom: '0.5px solid #0095ff' }}>Comment</Typography>
                                        <Typography sx={{ py: 2 }}>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</Typography>
                                    </>
                                )
                            }

                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Button onClick={acceptRequest}>Accept</Button>
                            <Button onClick={rejectRequest}>Reject</Button>
                        </Box>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div >
    );
}
export default ViewCancel;
