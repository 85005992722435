import React, { useState } from 'react'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { Link, Grid } from '@mui/material';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    color: 'text.primary',
    backgroundColor: "#1b182b",
    boxShadow: 24,
    p: 4,
    borderRadius: 5,
    textAlign: "start"

};

export default function PayoutModal({ }) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [values, setValues] = React.useState({
        amount: '',
    });

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };
    return (
        <div>
            <div>
                <Link
                    component="button"
                    variant="body2"
                    onClick={handleOpen}>
                    Edit
                </Link>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <Box sx={style} >
                            <Button onClick={handleClose} sx={{ display: 'block' }}> <CloseIcon /> </Button>
                            <Typography variant="h5" component="h4" sx={{ mb: 3 }}>Edit minimum payout amount</Typography>
                            <Typography>A minimum payout amount can help reduce your fees per transaction. If set, your funds will be released after the minimum amount is met. For Prepaid MasterCard, the minimum payout amount must be more than $1 USD and less than $50,000 USD. All payout minimums must be set in USD, but this won’t affect your chosen payout currency.</Typography>
                            <FormControl sx={{ m: 1, marginTop: 5 }} variant="standard">
                                <InputLabel variant="outlined" >Amount</InputLabel>
                                <Input
                                    id="standard-adornment-amount"
                                    value={values.amount}
                                    onChange={handleChange('amount')}
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    placeholder="Enter Amount"
                                />
                            </FormControl>


                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} style={{ marginTop: 10 }}>

                                <Grid item xs={8}>

                                    <Button variant="contained">save</Button>

                                </Grid>
                                <Grid item xs={4}>

                                    <Button variant="contained" onClick={handleClose}>cancel</Button>

                                </Grid>

                            </Grid>
                        </Box>
                    </Fade>
                </Modal>
            </div>
        </div>
    )
}
