import { API_URL } from '../../config';
import { axiosPrivate } from '../../api/axios';

const userStatusUpdate = async () => {
  try {
    const response = await axiosPrivate.put(API_URL + `users/updateHostStatus`);
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('bad response of user status update: ', response);
      return response;
    }
  }
};
export default userStatusUpdate;
