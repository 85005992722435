import { API_URL } from '../../config';
import axios from 'axios';

export const getPropertyPricePolicyApi = async (id) => {
  try {
    const response = await axios.get(
      API_URL + `property_price_policy/all?propertyId=${id}`
    );
    return response.data.data;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of property price policy: ', response);
      return response;
    }
  }
};
