import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { initiateChat } from '../../services/Chat/chat';
import { getPropertyByIdApi } from '../../services/properties/getProperty';

import { confirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';

import DatePickers from '../form/datePicker';
import Buttons from '../form/button';
import { addBookingApi } from '../../services/booking/addBooking';
import { GuestCount } from '../shared/GuestCount';
import { Grid } from '@mui/material';
import { RadioButton } from 'primereact/radiobutton';
import io from 'socket.io-client';
import { RoomsCount } from '../shared/RoomsCount';
import { SOCKET_URL } from '../../config';

let socket = null;
export const RoomsReserveCard = ({ id, isSelect, propertyDetail }) => {
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  const userData = JSON.parse(localStorage.getItem('userData')) || null;
  const [displayResponsive, setDisplayResponsive] = useState(false);
  const [position, setPosition] = useState('center');
  const [displayPosition, setDisplayPosition] = useState(false);
  const user = localStorage.getItem('user');
  const userToken = localStorage.getItem('userToken');
  const [userDetail, setUserDetail] = useState({});
  const dialogFuncMap = {
    displayPosition: setDisplayPosition,
    displayResponsive: setDisplayResponsive,
  };
  const [message, setMessage] = useState('');

  const getPropertyDetail = async () => {
    try {
      const response = await getPropertyByIdApi(id);
      if (response.status === 200) {
        setUserDetail(response.data.data.user);
      } else {
        window.location = '/';
      }
    } catch (err) {
      window.location = '/';
    }
  };

  useEffect(() => {
    socket = io(SOCKET_URL, {
      forceNew: true,
      cors: {
        origin: '*',
      },
      transports: ['websocket'],
    });
  }, []);

  useEffect(() => {
    getPropertyDetail();
  }, [propertyDetail]);

  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);

    if (position) {
      setPosition(position);
    }
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };

  const accept = () => {
    toast.current.show({
      severity: 'info',
      summary: 'Request Sent',
      detail: 'Booking request has been sent to Host',
      life: 10000,
    });
    createBooking();
  };

  const reject = () => {};

  const history = useNavigate();

  const basicInfo = useFormik({
    initialValues: {
      startDate: '',
      endDate: '',
      ownerId: 0,
      noOfGuest: 0,
      noOfAdults: 0,
      noOfChildren: 0,
      noOfInfants: 0,
      noOfPets: 0,
      guestId: userData?.id,
      propertyId: id,
    },
    validationSchema: () => {
      return yup.object({
        startDate: yup.string().required('start date is required is required'),
        endDate: yup.string().required('end date is required is required'),
      });
    },
    onSubmit: (values) => {
      userToken ? confirm1() : history('/login');
    },
  });

  const confirm1 = () => {
    const totalNights = GetDayDifference();
    const totalNightsCost = propertyDetail?.perNightPrice * totalNights;
    const totalGst = (totalNightsCost * 13) / 100;
    const totalAmount =
      totalNightsCost +
      totalGst +
      parseInt(propertyDetail?.propertyPricePolicy[0]?.cleaningFee);
    confirmDialog({
      message: (
        <div
          style={{
            width: '30vw',
            textAlign: 'left',
            paddingTop: '20px',
          }}
        >
          <Grid className='confirmation-popup' container spacing={2}>
            <Grid item xs={6}>
              <div className=''>
                <h1>DURATION</h1>
                <p>
                  {moment(basicInfo.values.startDate).format('DD MMM YYYY')} -{' '}
                  {moment(basicInfo.values.endDate).format('DD MMM YYYY')}
                </p>
                <p>
                  <i className='fas fa-moon'></i> {totalNights} nights
                </p>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <h1>GUEST</h1>
                <p>
                  <i className='fas fa-user'></i> {basicInfo.values.noOfGuest}
                </p>
                <p>
                  {basicInfo.values.noOfAdults > 0
                    ? basicInfo.values.noOfAdults + ' Adults'
                    : ''}
                  {basicInfo.values.noOfAdults > 0 &&
                  basicInfo.values.noOfChildren > 0
                    ? ' - '
                    : ''}
                  {basicInfo.values.noOfChildren > 0
                    ? basicInfo.values.noOfChildren + ' Children'
                    : ''}
                </p>
                <p>
                  {basicInfo.values.noOfInfants > 0
                    ? basicInfo.values.noOfInfants + ' Infants'
                    : ''}
                  {basicInfo.values.noOfInfants > 0 &&
                  basicInfo.values.noOfPets > 0
                    ? ' - '
                    : ''}
                  {basicInfo.values.noOfPets > 0
                    ? basicInfo.values.noOfPets + ' Pets'
                    : ''}
                </p>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div>
                <h1>BOOKING DATE &amp; TIME</h1>
                <p>{moment().format('DD MMM YYYY h:mm:ss a')}</p>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <h1>CHECK-IN</h1>
                <p>{moment().format('DD MMM YYYY h:mm:ss a')}</p>
                <p>{propertyDetail?.propertyPolicy.checkInWindow}</p>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <h1>CHECK-OUT</h1>
                <p>{moment().format('DD MMM YYYY h:mm:ss a')}</p>
                <p>{propertyDetail?.propertyPolicy.checkoutWindow}</p>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div>
                <h1>PAYMENT INVOICE</h1>
                <div
                  style={{
                    display: 'flex',
                    marginTop: '20px',
                    justifyContent: 'space-between',
                  }}
                >
                  <p>{totalNights} nights </p>
                  <p>{process.env.REACT_APP_CURRENCY}{totalNightsCost}</p>
                </div>
                {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <p>Cleaning Fee</p>
                  <p>$ 1,200</p>
                </div> */}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <p>Cleaning Fee</p>
                  <p>
                   {process.env.REACT_APP_CURRENCY}{propertyDetail?.propertyPricePolicy[0]?.cleaningFee}
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <p>GST 13%</p>
                  <p>{process.env.REACT_APP_CURRENCY}{totalGst.toFixed(2)}</p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <p>Total</p>
                  <p>{process.env.REACT_APP_CURRENCY}{totalAmount.toFixed(2)}</p>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      ),
      header: 'Booking Request',
      // icon: "pi pi-exclamation-triangle",
      acceptLabel: 'Send Request',
      rejectLabel: 'Cancel',
      accept,
      reject,
    });
  };

  const GetDayDifference = () => {
    var given = moment(basicInfo.values.startDate);
    var current = moment(basicInfo.values.endDate);
    const diff = current.diff(given);
    const diffDuration = moment.duration(diff);
    return Math.ceil(diffDuration._milliseconds / (1000 * 60 * 60 * 24));
  };

  const createChat = async () => {
    alert('calll');
    try {
      const data = {
        propertyId: propertyDetail?.id,
      };
      const response = await initiateChat(data);
      if (response.status === 201) {
        const chatId = response?.data?.id;
        const messageData = {
          senderId: userData?.id,
          chatId: chatId,
          content:
            message === '' ? 'I am interested to rent your property.' : message,
        };

        let payload = {
          message: messageData,
        };
        socket.emit('incoming-message', payload.message);

        toast.current.show({
          severity: 'info',
          summary: 'Message Sent',
          detail: 'A message have been sent to property owner.',
          life: 10000,
        });
      }
    } catch (err) {
      console.log(err, 'Error');
    }
  };

  const createBooking = async () => {
    let values = basicInfo.values;
    setLoading(true);
    try {
      if (values.noOfGuest < 1) {
        setLoading(false);
        toast.current.show({
          severity: 'warn',
          summary: 'Not Proceeded',
          detail: 'Guest count must be at least one!',
          life: 7000,
        });
      }
      const response = await addBookingApi(values, propertyDetail.user.id);

      if (response.status === 200) {
        setLoading(false);
        toast.current.show({
          severity: 'info',
          summary: 'Booking Proceeded',
          life: 10000,
        });
        createChat();
      }
    } catch (err) {
      setLoading(false);
      toast.current.show({
        severity: 'warn',
        summary: 'Not Proceeded',
        detail: 'there seems to be an error right now',
        life: 10000,
      });
    }
  };

  const handleGuestCountChange = async (value, count) => {
    basicInfo.setFieldValue('noOfGuest', value);
    basicInfo.setFieldValue('noOfAdults', count.adults);
    basicInfo.setFieldValue('noOfChildren', count.children);
    basicInfo.setFieldValue('noOfInfants', count.infants);
    basicInfo.setFieldValue('noOfPets', count.pets);
  };

  const [report, setReport] = useState(null);
  const [screen, setScreen] = useState(0);
  return (
    <RoomsReserveCardStyled>
      <Toast ref={toast} position='bottom-right' />
      <div className='box-widget fl-wrap'>
        <div className='box-widget-fixed-init fl-wrap output' id='sec-contact'>
          <div className='box-widget-title fl-wrap box-widget-title-color color-bg'>
            Choose your room
          </div>
          <div className='box-widget-content fl-wrap'>
            <div className='custom-form'>
              <div className='row'>
                <div
                  className='col-md-12'
                  style={{
                    textAlign: 'left',
                  }}
                >
                  <label className='label-field'>Check In/ Check Out</label>
                  <DatePickers
                    basePrice={propertyDetail.perNightPrice}
                    handlers
                    handleDateStart={(value) =>
                      basicInfo.setFieldValue('startDate', value)
                    }
                    handleDateEnd={(value) =>
                      basicInfo.setFieldValue('endDate', value)
                    }
                    id={propertyDetail?.id}
                  />
                </div>
              </div>
              <div
                className='row'
                style={{
                  textAlign: 'left',
                  marginTop: '20px',
                }}
              >
                <div className='col-md-12'>
                  <label className='label-field'>Persons</label>
                  <GuestCount
                    isSelect={isSelect}
                    propertyDetail={propertyDetail}
                    handleChange={handleGuestCountChange}
                  />
                </div>
              </div>
              {/* No Of Rooms  */}
              <div
                className='row'
                style={{
                  textAlign: 'left',
                  marginTop: '20px',
                }}
              >
                <div className='col-md-12'>
                  <label className='label-field'>Rooms</label>
                  <RoomsCount
                    isSelect={isSelect}
                    propertyDetail={propertyDetail}
                    handleChange={handleGuestCountChange}
                  />
                </div>
              </div>

              <Buttons
                style={{ height: 50, marginTop: '20px' }}
                title={'Apply For All Rooms'}
                onSubmit={basicInfo.handleSubmit}
                isLoading={loading}
              />

              <small
                id='username2-help'
                className='p-error'
                style={{
                  display: 'block',
                  color: 'red',
                  textAlign: 'left',
                }}
              >
                {basicInfo.touched.startDate && basicInfo.errors.startDate}{' '}
                <br />
                {basicInfo.touched.endDate && basicInfo.errors.endDate}
              </small>
            </div>
          </div>
        </div>
      </div>
      {/* report text */}
      <div
        className='box-widget fl-wrap'
        style={{
          marginTop: '20px',
        }}
      >
        <i className='fal fa-flag' style={{ color: 'white' }}></i>
        <div
          onClick={() => onClick('displayResponsive')}
          style={{
            fontSize: '14px',
            color: '#fff',
            marginBottom: '0px',
            textAlign: 'center',
            textDecorationLine: 'underline',
            background: 'none',
            display: 'inline',
            margin: '0px 8px',
            cursor: 'pointer',
          }}
        >
          Report this listing
        </div>
        <Dialog
          header='Report this listing?'
          visible={displayResponsive}
          onHide={() => onHide('displayResponsive')}
          breakpoints={{ '960px': '75vw' }}
          style={{ width: '50vw', fontSize: '15px' }}
        >
          {screen === 0 && (
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'start',
                height: '100%',
                flexDirection: 'column',
              }}
            >
              <h1 style={{ fontSize: '22px', fontWeight: 'bold' }}>
                Why are you reporting this listing?
              </h1>
              <p>This wont be shared with the Host.</p>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'start',
                  justifyContent: 'start',
                  height: '100%',
                  flexDirection: 'column',
                }}
              >
                <div
                  className='field-radiobutton'
                  style={{ margin: '8px 0px' }}
                >
                  <RadioButton
                    inputId='report1'
                    name='report'
                    value='Chicago'
                    onChange={(e) => setReport(e.value)}
                    checked={report === 'Chicago'}
                  />
                  <label
                    htmlFor='report1'
                    style={{ margin: '0px 8px', fontSize: '18px' }}
                  >
                    It’s inaccurate or incorrect
                  </label>
                </div>
                <div
                  className='field-radiobutton'
                  style={{ margin: '8px 0px' }}
                >
                  <RadioButton
                    inputId='report2'
                    name='report'
                    value='It’s not a real place to stay'
                    onChange={(e) => setReport(e.value)}
                    checked={report === ' It’s not a real place to stay'}
                  />
                  <label
                    htmlFor='report2'
                    style={{ margin: '0px 8px', fontSize: '18px' }}
                  >
                    It’s not a real place to stay
                  </label>
                </div>
                <div
                  className='field-radiobutton'
                  style={{ margin: '8px 0px', fontSize: '18px' }}
                >
                  <RadioButton
                    inputId='report3'
                    name='report'
                    value='It’s a scam'
                    onChange={(e) => setReport(e.value)}
                    checked={report === 'It’s a scam'}
                  />
                  <label
                    htmlFor='report3'
                    style={{ margin: '0px 8px', fontSize: '18px' }}
                  >
                    It’s a scam
                  </label>
                </div>
                <div
                  className='field-radiobutton'
                  style={{ margin: '8px 0px', fontSize: '18px' }}
                >
                  <RadioButton
                    inputId='report4'
                    name='report'
                    value=' It’s offensive'
                    onChange={(e) => setReport(e.value)}
                    checked={report === 'It’s offensive'}
                  />
                  <label
                    htmlFor='report4'
                    style={{ margin: '0px 8px', fontSize: '18px' }}
                  >
                    It’s offensive
                  </label>
                </div>
                <div
                  className='field-radiobutton'
                  style={{ margin: '8px 0px', fontSize: '18px' }}
                >
                  <RadioButton
                    inputId='report5'
                    name='report'
                    value='It’s something else'
                    onChange={(e) => setReport(e.value)}
                    checked={report === 'It’s something else'}
                  />
                  <label
                    htmlFor='report5'
                    style={{ margin: '0px 8px', fontSize: '18px' }}
                  >
                    It’s something else
                  </label>
                </div>
              </div>

              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  label='Next'
                  onClick={() => setScreen(screen + 1)}
                  style={{ width: '10rem', height: '3rem', fontSize: '15px' }}
                />
              </div>
            </div>
          )}
          {screen === 1 && (
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'start',
                height: '100%',
                flexDirection: 'column',
              }}
            >
              <h1 style={{ fontSize: '22px', fontWeight: 'bold' }}>
                We got your report.
              </h1>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'start',
                  justifyContent: 'start',
                  height: '100%',
                  flexDirection: 'column',
                }}
              >
                <p style={{ fontSize: '16px' }}>
                  Thanks for taking the time to let us know what’s going on.
                  Reports like yours help keep the staywo community safe and
                  secure.
                </p>
              </div>
            </div>
          )}
        </Dialog>
      </div>
    </RoomsReserveCardStyled>
  );
};

const RoomsReserveCardStyled = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: -160px;
  width: 100%;

  .label-field {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 10px;
    display: block;
  }
`;
