import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { useMediaQuery } from '@mui/material';

const steps = [
  'Basic Info',
  'Details',
  'Location',
  'Amenities',
  'Media',
  'Upload',
  'Price',
];

const Progressbar = ({ step, steps }) => {
  const mobileWidth = useMediaQuery('(max-width:500px)');

  return (
    <>
      <Stepper activeStep={step} alternativeLabel>
        {steps.map((label, index) => (
          <Step
            key={label}
            sx={{
              '& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active': {
                color: '#2e94f7',
                width: mobileWidth === true ? 25 : 30,
                height: mobileWidth === true ? 25 : 30,
              },
              '& .css-zpcwqm-MuiStepConnector-root': {
                height: mobileWidth === true ? 13 : 16,
              },
              '& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root': {
                width: mobileWidth === true ? 25 : 30,
                height: mobileWidth === true ? 25 : 30,
              },
              '& .css-qivjh0-MuiStepLabel-label.MuiStepLabel-alternativeLabel':
                {
                  marginTop: 2,
                },
              '& .css-qivjh0-MuiStepLabel-label.Mui-active': {
                color: 'white',
              },
              '& .css-qivjh0-MuiStepLabel-label.Mui-completed': {
                color: 'white',
              },
              '& .css-qivjh0-MuiStepLabel-label': {
                color: '#83887e',
                fontSize: 5,
              },
              '& .css-hciqmw-MuiStepLabel-label': {
                fontSize: mobileWidth === true ? '0.600rem' : '0.875rem',
              },
            }}
          >
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </>
  );
};

export default Progressbar;
