import { API_URL } from '../../config';
import { axiosPrivate } from '../../api/axios';
const changeUserPassApi = async (data, id) => {
  try {
    const response = await axiosPrivate.put(
      API_URL + `users/password/${id}`,
      data
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('bad response of pass update: ', response);
      return response;
    }
  }
};
export default changeUserPassApi;
