import * as yup from "yup";

export const filtersValidationSchema = yup.object({
  spaceId: yup.string(),
  areaId: yup.string(),
  title: yup.string(),
  noOfAdults: yup.number().min(1, "Number should be greater then 1"),
  noOfChildren: yup.number().min(0, "Number should be greater then 1"),
  isPetAllowed: yup.boolean(),
});
