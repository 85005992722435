import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import DiamondIcon from "@mui/icons-material/Diamond";
import Buttons from "../form/button";
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { Box } from "@mui/material";
import styled from "styled-components";
import { DetailPageCarousel } from "../shared/DetailPageCarousel";

//Transition
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CommercialRoomCard = ({ room, gst }) => {
  const cleaningFee = room?.roomPricePolicy
    ? parseInt(room?.roomPricePolicy[0].cleaningFee)
    : 0;
  const totalCost =
    room?.roomPerNightPrice + room?.roomPerNightPrice * gst + cleaningFee;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <RoomDetails open={open} handleClose={handleClose} room={room} />
      <div
        className="listing-item single_list_card"
        style={{ cursor: "pointer" }}
      >
        <article className="geodir-category-listing fl-wrap">
          <div className="geodir-category-img fl-wrap">
            <a className="geodir-category-img_item" onClick={handleClickOpen}>
              <img
                src={
                  !room.roomImages[0]
                    ? require("../../assets/images/all/3.jpg")
                    : room.roomImages[0].locationUrl
                }
                alt={"Room"}
              />
              <div className="overlay"></div>
            </a>
          </div>
          <div className="geodir-category-content fl-wrap">
            <h3 className="title-sin_item" onClick={handleClickOpen}>
              <a style={{ textDecoration: "none", cursor: "pointer" }}>
                {room?.title}
              </a>
            </h3>
            <p style={{ paddingTop: 0 }}>Room ID: {room?.roomId}</p>

            <div
              className="geodir-category-content-details"
              style={{
                color: "rgba(255, 255, 255, 0.7)",
                textAlign: "start",
              }}
            >
              <div style={{ fontSize: "14px", color: "#fff" }}>
                Room Amenities:
              </div>
              {room?.roomAmenities?.map((amenity) => {
                return (
                  <div key={amenity?.id}>
                    <span style={{ marginRight: "4px" }}>
                      {amenity?.amenity?.icon}
                    </span>
                    {amenity?.amenity.title}
                  </div>
                );
              })}
            </div>
            <div className="geodir-category-footer">
              <div className="geodir-category-content_price">
                {process.env.REACT_APP_CURRENCY}{" "}
                {room?.roomPerNightPrice?.toLocaleString("en-US")}
                <span
                  style={{ color: "rgba(255, 255, 255, 0.7)", paddingTop: 3 }}
                  color="white"
                >
                  Night
                </span>
              </div>
              <div className="geodir-category-content_price">
                {process.env.REACT_APP_CURRENCY}{" "}
                {parseInt(totalCost)?.toLocaleString("en-US")}
                <span
                  style={{ color: "rgba(255, 255, 255, 0.7)", paddingTop: 3 }}
                >
                  Total
                  <span style={{ fontSize: "8px" }}>Inc. cleaning fee</span>
                </span>
              </div>
            </div>
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Buttons
                isLoading={false}
                title="Reserve"
                style={{ width: "95%" }}
              />
            </div>
          </div>
        </article>
      </div>
    </>
  );
};

export default CommercialRoomCard;

const RoomDetails = ({ open, handleClose, room }) => {
  const StyledCounter = styled.div`
    .thumbs-wrapper {
      margin: 0px !important;
    }
    .thumb {
      height: 130px !important;
      padding: 0px !important;
      margin: 0px !important;
    }
    .thumb {
      width: 20% !important;
    }

    img {
      height: 100%;
    }
    .p-galleria-thumbnail-item-content {
      overflow: hidden;
    }
  `;
  const cleaningFee = room?.roomPricePolicy[0]?.cleaningFee
    ? parseInt(room?.roomPricePolicy[0].cleaningFee)
    : 0;
  const totalCost =
    room?.roomPerNightPrice + room?.roomPerNightPrice * 0.13 + cleaningFee;
  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar
            sx={{
              fontFamily: "Jost",
              fontSize: "25px",
              background: "#17212f",
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Room Details
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ background: "#1c172b", width: "100%", height: "100%" }}>
          <Box
            sx={{
              width: "60vw",
              position: "absolute",
              right: "1%",
              top: "1%",
              marginTop: "5%",
            }}
          >
            <StyledCounter id="sec2">
              <DetailPageCarousel propertyDetail={room?.roomImages} />
            </StyledCounter>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "40vw",
              height: "40vw",
              alignItems: "start",
              justifyContent: "start",
              padding: "50px",
            }}
          >
            <Typography
              sx={{ fontSize: "25px", color: "#fff", fontFamily: "Jost" }}
            >
              {room?.title}
            </Typography>
            <div style={{ fontSize: "18px", color: "#fff" }}>
              Room Amenities:
            </div>
            <Box sx={{ textAlign: "start" }}>
              {room?.roomAmenities?.map((amenity) => {
                return (
                  <div
                    key={amenity?.id}
                    style={{
                      color: "rgba(255, 255, 255, 0.7)",
                      fontSize: "16px",
                    }}
                  >
                    <span style={{ marginRight: "4px" }}>
                      {amenity?.amenity?.icon}
                    </span>
                    {amenity?.amenity.title}
                  </div>
                );
              })}
            </Box>
            <Box
              sx={{ textAlign: "start", fontSize: "25px", margin: "30px 0px" }}
            >
              {process.env.REACT_APP_CURRENCY}{totalCost}
            </Box>
            <Box>
              <Buttons
                isLoading={false}
                title="Reserve"
                style={{ width: "200%" }}
              />
            </Box>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
};
