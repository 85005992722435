import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Box } from "@mui/system";

const RadioButton = ({ options }) => {
  return (
    <Box
      justify="flex-start"
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginTop: "1rem",
      }}
    >
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="female"
          name="radio-buttons-group"
        >
          {options?.map((option) => {
            return (
              <FormControlLabel
                checked={option.checked}
                value={option.id}
                control={<Radio />}
                label={option.title}
                sx={{ textAlign: "left", paddingTop: 2 }}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default RadioButton;
