import React from 'react';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { Typography } from '@mui/material';

const NoDataFound = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '200px',
        border: '1px solid #ffffff1a',
      }}
    >
      <div>
        <AssignmentTurnedInIcon style={{ color: '#fff', fontSize: '25px' }} />
        <Typography variant='body2' style={{ textAlign: 'center' }}>
          No Record Found
        </Typography>
      </div>
    </div>
  );
};

export default NoDataFound;
