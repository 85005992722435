import React, { useState, useRef, useEffect } from 'react';
import Header from '../../../components/header';
import Sidebar from '../../../components/dasboard/sidebar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import Button from '@mui/material/Button';
import { Typography, FormControlLabel, Checkbox, Link } from '@mui/material';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import {
  createCoHostApi,
  deleteCoHostApi,
  getCoHostApi,
} from '../../../services/properties/coHost';
import { Toast } from 'primereact/toast';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';

const CoHostPage = () => {
  const [displayResponsive, setDisplayResponsive] = useState(false);
  const [position, setPosition] = useState('center');
  const [displayPosition, setDisplayPosition] = useState(false);
  const [coHost, setCoHost] = useState([]);
  const toast = useRef(null);
  const { id } = useParams();
  const dialogFuncMap = {
    displayPosition: setDisplayPosition,
    displayResponsive: setDisplayResponsive,
  };
  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);

    if (position) {
      setPosition(position);
    }
  };
  function createData(name, status, invitationCode, id) {
    return { name, status, invitationCode, id };
  }

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };

  // Get Co Host Data
  const [rows, setRows] = useState([]);
  useEffect(() => {
    let rows = [];
    coHost?.forEach((item) => {
      rows.push(createData(item.email, 'Active', item.invitationCode, item.id));
      setRows(rows);
    });
  }, [id, coHost]);

  React.useEffect(() => {
    const getCoHostData = async () => {
      const res = await getCoHostApi(id);
      if (res.status === 200) {
        setCoHost(res.data);
      }
    };
    getCoHostData();
  }, [id]);

  const params = window.location.search;
  const title = new URLSearchParams(params).get('propertyTitle');
  const code = new URLSearchParams(params).get('propertyCode');
  const userData = JSON.parse(localStorage.getItem('userData'));

  const formik = useFormik({
    initialValues: {
      propertyId: parseInt(id),
      userId: parseInt(userData?.id),
      email: '',
      bookingDetail: true,
      listingDetail: true,
      pricing: true,
      availability: true,
    },
    onSubmit: async (values) => {
      const res = await createCoHostApi(values);
      if (res.status === 201) {
        toast.current.show({
          severity: 'success',
          summary: 'Request Sent',
          detail: `${res.data.message}`,
          life: 3000,
        });
        window.location.reload();
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Cannot send co host request!',
          detail: `${res.data.message}`,
          life: 3000,
        });
      }
    },
  });

  const deleteCoHost = async (coHostId) => {
    const data = {
      cohostId: parseInt(coHostId),
      userId: parseInt(userData?.id),
      propertyId: parseInt(id),
    };
    const res = await deleteCoHostApi(data);
    if (res.status === 200) {
      toast.current.show({
        severity: 'success',
        summary: 'Co Host Deleted',
        detail: `${res.data.message}`,
        life: 3000,
      });
      window.location.reload();
    } else {
      toast.current.show({
        severity: 'error',
        summary: 'cannot delete Co Host.',
        detail: `${res.data.message}`,
        life: 3000,
      });
    }
  };

  return (
    <>
      <Header />
      <Toast ref={toast} position='bottom-right' />
      <div id='wrapper'>
        <div className='dashboard-menu-overlay' />
        <Sidebar page={'Co Hosts'} />
        <div className='dashboard-content'>
          <div className='dasboard-wrapper fl-wrap no-pag'>
            <div>
              <div className='clearfix' />
              <div className='row'>
                <div className='col-md-12'>
                  <div
                    style={{
                      margin: '10px 0px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        color: 'white',
                        fontFamily: 'Jost',
                        fontSize: '20px',
                        fontWeight: '700',
                        paddingBottom: '0px',
                      }}
                    >
                      CO HOSTS for ({title} - {code})
                    </Typography>
                    <Button
                      variant='contained'
                      onClick={() => onClick('displayResponsive')}
                      sx={{
                        background: '#0095ff',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      Create Co Host
                    </Button>
                  </div>
                  <Typography
                    sx={{
                      color: 'white',
                      fontFamily: 'Jost',
                      fontSize: '20px',
                      fontWeight: '700',
                      paddingBottom: '0px',
                    }}
                  >
                    Listing Co-Hosts
                  </Typography>
                  <p>
                    Add up to 3 Co-Hosts who can help you manage listing
                    details, reservations and Resolution Center requests using
                    their own account. The primary host will be shown as the
                    host on a reservation.{' '}
                    <Link style={{ cursor: 'pointer' }}>Learn more</Link>
                  </p>
                  <div style={{ margin: '30px 0px' }}>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            <TableCell>Email</TableCell>
                            <TableCell align='right'>Status</TableCell>
                            <TableCell align='right'>Invitation Code</TableCell>
                            <TableCell align='right'>Actions</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.length === 0 && (
                            <div style={{ padding: '8px', fontSize: '18px' }}>
                              No Co Hosts!
                            </div>
                          )}
                          {rows.map((row) => (
                            <TableRow
                              key={row.name}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component='th' scope='row'>
                                {row.name}
                              </TableCell>
                              <TableCell align='right'>{row.status}</TableCell>
                              <TableCell align='right'>
                                {row.invitationCode}
                              </TableCell>
                              <TableCell align='right'>
                                {' '}
                                <Button
                                  variant='contained'
                                  sx={{
                                    background: 'red',
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                  onClick={() => deleteCoHost(row.id)}
                                >
                                  <CloseIcon sx={{ fontSize: '18px' }} /> DELETE
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        header='Invite co-host!'
        visible={displayResponsive}
        onHide={() => onHide('displayResponsive')}
        breakpoints={{ '960px': '75vw' }}
        style={{ width: '30vw', fontSize: '15px' }}
      >
        <div style={{ textAlign: 'start' }}>
          <div style={{ marginBottom: 10 }}>
            <span style={{ fontSize: 13, marginBottom: 10 }}>
              When you add a co-host, they can act on your behalf. You’re
              responsible for actions they take when they’re hosting your space.
            </span>
          </div>
          <label>Enter Co-Host email address.</label>
          <InputText
            value={formik.values.email}
            onChange={formik.handleChange}
            id={'email'}
            name={'email'}
          />

          <FormControlLabel
            control={
              <Checkbox
                onChange={() => formik.setFieldValue('agreeSerivce', true)}
              />
            }
            label={`I agree To the co-host Terms of Services `}
          />

          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <button
              className='btn color-bg float-btn'
              style={{
                cursor: 'pointer',
                border: 'none',
                outline: 'none',
              }}
              onClick={formik.handleSubmit}
            >
              Send Request
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default CoHostPage;
