import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import { MultiSelect } from 'primereact/multiselect';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
      width: 250,
    },
  },
};

//Simple dropdown component
const Dropdown = ({
  id,
  name,
  error,
  label,
  value,
  margin,
  options,
  helperText,
  onChange,
  defaultValue,
  multiSelect,
}) => {
  return (
    // <MultiSelect value={selectedCities2} options={cities} onChange={(e) => setSelectedCities2(e.value)} optionLabel="name" placeholder="Select a City" display="chip" />
    <>
      {multiSelect ? (
        <MultiSelect
          display="chip"
          // select
          // fullWidth
          id={id}
          name={name}
          label={label}
          placeholder={label}
          value={value}
          error={error}
          margin={margin}
          onChange={onChange}
          helperText={helperText}
          // MenuProps={MenuProps}
          defaultValue={defaultValue}
          optionLabel="title"
          options={options}
        />
      ) : (
        <TextField
          select
          fullWidth
          id={id}
          name={name}
          label={label}
          value={value}
          error={error}
          margin={margin}
          onChange={onChange}
          helperText={helperText}
          MenuProps={MenuProps}
          defaultValue={defaultValue}
        >
          {options?.map((option) => (
            <MenuItem
              sx={{
                backgroundColor: '#0095ff',
                fontSize: '14px',
              }}
              key={option?.id}
              value={option?.id}
            >
              {option?.title}
            </MenuItem>
          ))}
        </TextField>
      )}
    </>
  );
};

export default Dropdown;
