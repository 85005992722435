import { Typography, Box } from '@mui/material'
import React from 'react'

const PoliciesContent = ({ data }) => {
    const { title, description } = data
    return (
        <Box sx={{ mt: 3 }}>
            <h4>{`${title}`}</h4>
            <p>{description}</p>
        </Box >
    )
}

export default PoliciesContent