import React, { useState } from 'react'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import ListedStatus from './Dialouge';
import { Link } from '@mui/material';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    color: 'text.primary',
    backgroundColor: "#1b182b",
    textAlign: "start",
    boxShadow: 24,
    p: 5,
    borderRadius: 5

};

export default function ChildModal({ data }) {
    const [open, setOpen] = React.useState(false);
    const [reason, setReason] = React.useState('');
    const obj = {
        val: data,
        res: reason
    }

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleReason = (e) => {
        setReason(e.target.value)
    }
    const handleData = () => {
        if (!reason) {

            return;
        }
        handleClose();

    }

    return (
        <>
            <React.Fragment>
                <Typography onClick={handleOpen} style={{ display: "inline" }}>Unlisted</Typography>
                <Modal
                    hideBackdrop
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <Box sx={{ ...style, width: 800 }}>
                        <FormControl >

                            <Typography variant="h5" >Let us know why you want to unlist your listing</Typography>
                            <p style={{ marginTop: 5 }}>Depending on your reason, we may have tips to help you continue hosting.</p>

                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                value={reason}
                                name="radio-buttons-group"
                                onChange={handleReason}

                                style={{ paddingTop: 5 }}
                            >
                                <FormControlLabel value="Hosting isn’t worth the time or the money" control={<Radio color="default" />} label="Hosting isn’t worth the time or the money" />

                                <FormControlLabel value="I only want to host guests at certain times" control={<Radio color='default' />} label="I only want to host guests at certain times" />

                                <FormControlLabel value="I have concerns about guest behavior" control={<Radio color='default' />} label="I have concerns about guest behavior" />


                                <FormControlLabel value="My place isn’t available for guests right now" control={<Radio color='default' />} label="My place isn’t available for guests right now" />

                                <FormControlLabel value="I have other reasons" control={<Radio color='default' />} label="I have other reasons" />


                            </RadioGroup>
                            <Typography onClick={handleData} style={{ textAlign: "end", cursor: "pointer" }}>Save</Typography>

                        </FormControl>


                    </Box>
                </Modal>
            </React.Fragment>
            <span style={{ display: "none" }}>

                {<ListedStatus responce={obj} />}
            </span>

        </>
    )
}
