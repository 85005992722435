import React, { useState, useEffect } from "react";
import Header from "../../../components/header";
import getPage from "../../../services/TermsPages/getPage";
import "./shared.css";

const TermsAndConditions = () => {
  const [termsData, setTermsData] = useState();
  const getTermsData = async () => {
    const data = await getPage("terms-and-conditions");
    if (data) {
      setTermsData(data);
    }
  };
  useEffect(() => {
    getTermsData();
  }, []);

  console.log(termsData);
  return (
    <div className="main_p_and_t">
      <Header />
      <div className="container_p_and_t">
        <h1 className="main_tag_for_p_and_t">{termsData?.name}</h1>
        <p className="main_p_data">{termsData?.content}</p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
