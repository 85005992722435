import { API_URL } from '../../config';
import { axiosPrivate } from '../../api/axios';
const setCardAsDefaultApi = async (data) => {
  try {
    const response = await axiosPrivate.post(
      API_URL + `payment/set-card-as-default`,
      data
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('bad response of set card default: ', response);
      return response;
    }
  }
};
export default setCardAsDefaultApi;
