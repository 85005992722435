import React, { useEffect } from 'react';
import InfoBar from '../../../components/dasboard/infoBar';
import Sidebar from '../../../components/dasboard/sidebar';
import Header from '../../../components/header';
import { Button, Grid, Tooltip, Typography } from '@mui/material';
import { AiFillDelete } from 'react-icons/ai';
import { useParams } from 'react-router-dom';
import { getPropertyDocumentsApi } from '../../../services/properties/getPropertyDocument';
import { useState } from 'react';
import { deletePropertyDocumentApi } from '../../../services/properties/deletePropertyDocuments';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';
import Upload from '../../../components/form/upload';
import Buttons from '../../../components/form/button';
import { addPropertyDocumentsApi } from '../../../services/properties/addPropertyDocument';
import { FaGlasses } from 'react-icons/fa';

const ManagePropertyDocuments = () => {
  const userData = JSON.parse(localStorage.getItem('userData'));
  const [documents, setDocuments] = useState([]);
  const { propertyId } = useParams();

  const [thumbnail, setThumbnail] = useState([]);
  const [thumbnailURLs, setThumbnailURLs] = useState([]);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  //Get property documents
  React.useEffect(() => {
    const getPropertyDocuments = async () => {
      const res = await getPropertyDocumentsApi(propertyId);
      if (res.status === 200) {
        setDocuments(res?.data);
      }
    };
    getPropertyDocuments();
  }, [propertyId]);

  let toast = useRef(null);
  const deleteDocument = async (id) => {
    const res = await deletePropertyDocumentApi(id);
    if (res.status === 200) {
      toast.current.show({
        severity: 'success',
        summary: 'Document Deleted',
        detail: 'Your property document is successfully deleted!',
        life: 10000,
      });
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } else {
      toast.current.show({
        severity: 'error',
        summary: 'Error deleting property document',
        detail: 'Cannot delete property document!',
        life: 10000,
      });
    }
  };

  const onThumbnailChange = (e) => {
    let imgFile = e.target.files[0];
    let img = new Image();
    img.src = window.URL.createObjectURL(imgFile);
    const imgSize = e.target.files[0].size / 1024;
    if (imgSize >= 3024) {
      toast.current.show({
        severity: 'info',
        summary: 'Error',
        detail: 'Please photo upload below 3 mb',
        life: 10000,
      });
    } else {
      setThumbnail((data) => [...data, ...e.target.files]);
    }
  };

  useEffect(() => {
    if (thumbnail.length > 0) {
      const newThumbnailUrls = [];
      thumbnail.forEach((image) =>
        newThumbnailUrls.push(URL.createObjectURL(image))
      );
      setThumbnailURLs(newThumbnailUrls);
    }
  }, [thumbnail]);

  const clearThumbnailImage = (imageSrc) => {
    const filterImage = thumbnailURLs.filter((name) => name !== imageSrc);
    setThumbnailURLs(filterImage);
    const newImageUrls = [];
    filterImage.forEach((image) =>
      newImageUrls.push(URL.createObjectURL(image))
    );
    setThumbnail(newImageUrls);
  };
  const addAllDocs = () => {
    thumbnail.forEach((thumbnail, i) => {
      addPropertyDocument(i);
    });
  };
  const addPropertyDocument = async (i) => {
    setIsButtonLoading(true);
    const formData = new FormData();
    formData.append('file', thumbnail[i]);
    formData.append('propertyId', propertyId);
    const res = await addPropertyDocumentsApi(formData);
    if (res.status === 201) {
      setIsButtonLoading(false);
      toast.current.show({
        severity: 'success',
        summary: 'Document Added',
        detail: 'Your property document is sucessfully added!',
        life: 10000,
      });
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } else {
      setIsButtonLoading(false);
      toast.current.show({
        severity: 'error',
        summary: 'Error adding property document',
        detail: 'Cannot add property document!',
        life: 10000,
      });
    }
  };

  console.log(thumbnail);
  return (
    <>
      <Toast ref={toast} position='bottom-right' />
      <Header />
      <div id='wrapper'>
        <div className='dashboard-menu-overlay' />
        <Sidebar page={`Your Property Documents - ${propertyId}`} />
        <div className='dashboard-content'>
          <div className='dashboard-menu-btn color-bg'>
            <span>
              <i className='fas fa-bars' />
            </span>
            Manage Property Documents
          </div>
          <InfoBar label={`Property Documents - ID ${propertyId}`} />
          <div className='dasboard-wrapper fl-wrap no-pag'>
            <div>
              <div className='clearfix' />
              <div className='row'>
                <div className='col-md-12'>
                  {documents?.length === 0 && (
                    <div style={{ color: '#fff', fontSize: '25px' }}>
                      You haven't added any property documents.
                    </div>
                  )}
                  <Grid container md={12}>
                    {documents?.map((document, i) => {
                      return (
                        <Grid
                          item
                          md={4}
                          key={i}
                          position={'relative'}
                          sx={{
                            border: '1px solid gray',
                            margin: '4px',
                            borderRadius: '8px',
                          }}
                        >
                          <span
                            style={{
                              position: 'absolute',
                              top: 25,
                              left: 25,
                              background: 'gray',
                              padding: '4px',
                              borderRadius: '8px',
                            }}
                          >
                            <Tooltip title='Delete' placement='left' arrow>
                              <Button
                                onClick={() => deleteDocument(document?.id)}
                                style={{ padding: 0, minWidth: 'auto' }}
                              >
                                <AiFillDelete
                                  style={{ fontSize: '25px', color: '#0089eb' }}
                                />
                              </Button>
                            </Tooltip>
                          </span>
                          <span
                            style={{
                              position: 'absolute',
                              bottom: 25,
                              right: 25,
                              ...(document?.status === 'approved' && {
                                background: 'green',
                              }),
                              ...(document?.status === 'rejected' && {
                                background: 'red',
                              }),
                              ...(document?.status === 'pending' && {
                                background: 'yellow',
                              }),
                              padding: '4px',
                              borderRadius: '8px',
                              fontWeight: 'bold',
                            }}
                          >
                            {document?.status === 'approved' && 'APPROVED'}
                            {document?.status === 'rejected' && 'REJECTED'}
                            {document?.status === 'pending' && 'PENDING'}
                          </span>
                          <img
                            src={document?.filename}
                            alt=''
                            width={'auto'}
                            height={300}
                            maxWidth={'500px'}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant={'h6'}
                      sx={{
                        paddingTop: 3,
                        paddingBottom: 3,
                        textAlign: 'initial',
                        color: '#fff',
                      }}
                    >
                      Upload Property Documents
                    </Typography>

                    <Upload
                      label={'Upload Document'}
                      handleChange={(e) => onThumbnailChange(e)}
                      disabled={thumbnail?.length >= 1 ? true : false}
                      multiple={true}
                    />
                    <div style={{ display: 'inline' }}>
                      {thumbnailURLs?.map((thumbnailSrc, i) => (
                        <div className='image-div ' key={i}>
                          <img
                            src={thumbnailSrc}
                            style={{
                              maxWidth: '100%',
                              height: '100%',
                            }}
                            alt=''
                          />
                          <div
                            className='clear-button '
                            onClick={() => clearThumbnailImage(thumbnailSrc)}
                          >
                            <i
                              className='fa fa-times'
                              style={{
                                cursor: 'pointer',
                                color: '#2e94f7',
                              }}
                            ></i>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Grid>
                  <div>
                    <Grid md={12}>
                      {thumbnailURLs?.length > 0 && (
                        <div style={{ width: '100%' }}>
                          <Buttons
                            title='Upload'
                            isLoading={isButtonLoading}
                            onSubmit={addAllDocs}
                            loadingTitle={'Saving'}
                            style={{ width: '40%', margin: '10px' }}
                          />
                        </div>
                      )}
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManagePropertyDocuments;
