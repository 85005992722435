import { API_URL } from "../../config";
import axios from "axios";

export const forgetPassword = async (email) => {
  try {
    const response = await axios.get(
      API_URL + `auth/email/forget-password/${email}`
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log("response: ", response);
      throw response;
    }
  }
};
