import { API_URL } from '../../config';
import axios from 'axios';

export const getBankDetail = async (userId) => {
  try {
    const response = await axios.get(
      API_URL + `user-bank-details/${userId}`
    );
    return response.data;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of get BankDetail  : ', response);
      return response;
    }
  }
};
