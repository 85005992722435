import { API_URL } from '../../config';
import axios from 'axios';

export const addBankDetail = async (data) => {
  try {
    const response = await axios.post(
      API_URL + `user-bank-details`,data
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;

      console.log(
        'user pass verify response is status code',
        response.data.statusCode
      );
      console.log(
        'user pass verify  Error Response : ',
        response.data.message[1]
      );
      throw response;
    }
  }
};
