import React from "react";
import { ConfirmDialog } from "primereact/confirmdialog";
import Buttons from "./form/button";

const ConfirmationDialog = ({ open, onHide,header,message,icon,onConfirm,confirmButtonLoading,confirmButtonColor }) => {

  const renderFooter = () => {
    return(
      <div>
           <Buttons
            title={'No'}
            onSubmit={onHide}
            isLoading={false}
          />
          <Buttons
            title={'Yes'}
            color={confirmButtonColor}
            onSubmit={onConfirm}
            isLoading={confirmButtonLoading}
          />
      </div>
    )
  }
    return(
        <ConfirmDialog visible={open} onHide={onHide} message={message}
      header={header} icon={icon} footer={renderFooter()} />
    )
}

export default ConfirmationDialog;