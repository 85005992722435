import React from "react";
import { ContactContent } from "../../../components/ContactPageComponents/ContactContent";
import { HeroHeader } from "../../../components/ContactPageComponents/HeroHeader";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import NewsLetter from "../../../components/newsLetter";

export default function ContactPage() {
  return (
    <div className="main">
      <Header />
      <div className="wrapper">
        <div className="content"> 
          <HeroHeader
            bg="8"
            title="Our Contacts"
            description="We are happy to answer questions on the application evaluation procedure. Get in touch with us using the contact form or via our service phone."
          />
          <ContactContent />
          <NewsLetter />
          <Footer />
        </div>
      </div>
    </div>
  );
}
