import React from "react";

const ActionCard = ({ title, description, btnText, url, affect }) => {
  return (
    <>
      <div className="action_area">
        <div className="mb-3 action_content">
          <div style={{ maxWidth: "700px" }}>
            <div className="">
              <h3 className="card_title">{title}</h3>
              {/* <button className=" px-2 due_now_btn">Due now</button> */}
            </div>
            <p className="text-muted action_text mb-4 ">{description}</p>
          </div>
          <div className="affect_area">
            <p className="affect_label">What's affected?</p>
            {affect.map((aff, i) => (
              <div key={i++} className="affected_item">
                <p className="payout_text">{aff}</p>
                <button className="restrict_btn">
                  <span>Restricted</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="3"
                    stroke="currentColor"
                    className="restrict_icon"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                    />
                  </svg>
                </button>
              </div>
            ))}
          </div>
        </div>
        <a href={url} className="go_btn">
          {btnText}
        </a>
      </div>
    </>
  );
};

export default ActionCard;
