import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { Link as RLink, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { validationSchema } from './LoginPageValidationSchema';
import { postLoginApi } from '../../../services/Authentication/Login';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Input from '../../../components/form/input';
import CssBaseline from '@mui/material/CssBaseline';
import Buttons from '../../../components/form/button';
import FormControlLabel from '@mui/material/FormControlLabel';
import BACKGROUND from '../../../assets/images/staywo_bg.png';
import LOGO from '../../../assets/images/logo.png';
import Google from '../Social/Google';
import { getToken } from '../../../firebaseInit';
import InputPass from '../../../components/form/inputpass';
import { Toast } from 'primereact/toast';
import FacebookNew from '../Social/FacebookNew';

function Copyright(props) {
  return (
    <Typography
      variant='body2'
      color='text.secondary'
      align='center'
      {...props}
    >
      {'© Staywo Inc.USA 2022 . All rights reserved.'}
      {/* {new Date().getFullYear()} */}
      {'.'}
    </Typography>
  );
}

const Login = () => {
  const history = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isTokenFound, setTokenFound] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [deviceToken, setDeviceToken] = useState('123123');
  useEffect(() => {
    let data;
    async function tokenFunc() {
      data = await getToken(setTokenFound);
      if (data) {
        localStorage.setItem('device_token', data);
        setDeviceToken(data);
      }
      return data;
    }
    tokenFunc();
  }, [setTokenFound]);

  const search = useLocation().search;
  const verified = new URLSearchParams(search).get('verified');
  const toast = React.useRef(null);

  useEffect(() => {
    if (verified === 'true') {
      toast.current.show({
        severity: 'success',
        summary: 'Verified',
        detail: 'Your account is verified successfully!',
        life: 3000,
      });
    } else if (verified === 'false') {
      toast.current.show({
        severity: 'error',
        summary: 'Cannot be verified',
        detail: 'Your account cannot be verified!',
        life: 3000,
      });
    }
  }, [verified]);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      device_token: deviceToken,
      deviceType: 'WEB',
    },
    enableReinitialize: true,
    validationSchema: () => {
      return validationSchema;
    },
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const response = await postLoginApi(values);
        if (response?.status === 201) {
          localStorage.setItem('userToken', response?.data?.data?.token);
          localStorage.setItem('user', true);
          localStorage.setItem('userHost', response?.data?.data?.user?.isHost);
          localStorage.setItem(
            'userData',
            JSON.stringify(response?.data?.data?.user)
          );

          setIsLoading(false);
          enqueueSnackbar('Successfully logged in.', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
          history('/');
        }
      } catch (err) {
        enqueueSnackbar(err.data.message, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
        setIsLoading(false);
      }
    },
  });

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  return (
    <Grid container component='main' sx={{ height: '100vh' }}>
      <CssBaseline />
      <Toast ref={toast} position='bottom-right' />
      <Grid
        item
        xs={false}
        sm={4}
        md={8}
        sx={{
          backgroundImage: `url(${BACKGROUND})`,
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light'
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Grid
        style={{ background: '#1b182b' }}
        item
        xs={12}
        sm={8}
        md={4}
        component={Paper}
        elevation={6}
        square
      >
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
          }}
        >
          <Grid item xs={12} justifyContent='center' display='flex'>
            <a href='/' className='logo-holder'>
              <img
                src={`${LOGO}`}
                width='242px'
                height='100px'
                marginBottom='20px'
                alt=''
              />
            </a>
          </Grid>
          <Typography textAlign='center' component='h1' variant='h5'>
            <h3>Login</h3>
          </Typography>
          <div className='social-median-container'>
            {/* <Apple /> */}
            <FacebookNew />
            <Google />
          </div>
          <Box component='form' noValidate sx={{ mt: 5 }}>
            <Typography>Email</Typography>
            <Input
              sx={{ marginBottom: 8, fontSize: '14px' }}
              id={'email'}
              name={'email'}
              label={'Email'}
              onChange={formik.handleChange}
              value={formik.values.email}
              defaultValue={formik.values.email}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <Box>
              <Typography>Password</Typography>
              <InputPass
                sx={{ mb: 3, fontSize: '14px', position: 'relative' }}
                id={'password'}
                name={'password'}
                label={'Password'}
                onChange={formik.handleChange}
                value={formik.values.password}
                defaultValue={formik.values.password}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
            </Box>

            <Grid
              container
              xs={12}
              justifyContent={'start'}
              alignItems={'start'}
            >
              <Grid item>
                <FormControlLabel
                  control={<Checkbox value='remember' color='primary' />}
                  label={<span style={{ fontSize: '14px' }}>Remember Me.</span>}
                />
              </Grid>
              <Grid item md={12} textAlign='start'>
                <Link
                  href='/email-verification'
                  underline='hover'
                  sx={{ fontSize: '14px' }}
                >
                  {'Forgot Password?'}
                </Link>
              </Grid>
            </Grid>

            <Buttons
              title='Login'
              loadingTitle={'Logging In'}
              isLoading={isLoading}
              style={{ height: 55, mb: 0, mt: 3, fontSize: 14 }}
              onSubmit={formik.handleSubmit}
            />
            <Buttons
              title='Login Through Phone'
              isLoading={false}
              variant={'outlined'}
              style={{ height: 55, mb: 5, mt: 2, fontSize: 14 }}
              onSubmit={() => history('/login-through-phone')}
            />
            <Grid
              container
              justifyContent={'center'}
              alignItems={'center'}
              flexDirection={'column'}
            >
              <Grid item>
                <Link
                  href='/signup'
                  variant='body2'
                  style={{ fontSize: '14px' }}
                >
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
              <Copyright
                sx={{
                  fontSize: '14px',
                  position: 'relative',
                  display: 'block',
                  marginTop: '25px',
                }}
              />
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Login;
