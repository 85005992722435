import { useFormik } from 'formik';
import React, { useState, useRef } from 'react';
import { Toast } from 'primereact/toast';
import { postNewsletterApi } from '../services/Newsletter/postNewsLetter';
import ConfirmationDialog from './ConfirmationDialog';

const NewsLetter = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  let userData;
  if (JSON.parse(localStorage.getItem('userData')) == undefined) {
    userData = null;
  } else {
    userData = JSON.parse(localStorage.getItem('userData')) || null;
  }
  const toast = useRef(null);
  const formik = useFormik({
    initialValues: {
      userId: 0,
      email: '',
    },
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const response = await postNewsletterApi(values);
        if (response?.status === 200) {
          setIsLoading(false);
          setOpen(false);
          toast.current.show({
            severity: 'success',
            summary: 'Subscribed',
            detail: 'You have subscribed to newsletter successfully!',
            life: 10000,
          });
        }
      } catch (err) {
        setOpen(false);
        toast.current.show({
          severity: 'error',
          summary: 'Cannot send newsletter!',
          detail: `${err.data?.message[1]?.message}`,
          life: 10000,
        });
        setIsLoading(false);
      }
    },
  });
  return (
    <div className='subscribe-wrap fl-wrap'>
      <Toast ref={toast} position='bottom-right' />
      <ConfirmationDialog
        message='Are you sure you want to subscribe?'
        header='Confirmation'
        icon='pi pi-exclamation-triangle'
        open={open}
        onHide={() => setOpen(false)}
        onConfirm={formik.handleSubmit}
        confirmButtonLoading={false}
        confirmButtonColor='success'
      />
      <div className='container'>
        <div
          className='subscribe-container fl-wrap color-bg'
          style={{
            backgroundImage: 'url(/water.png)',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        >
          <div className='pwh_bg'></div>
          <div className='mrb_dec mrb_dec3'></div>
          <div className='row'>
            <div className='col-md-6'>
              <div className='subscribe-header'>
                <h4
                  style={{
                    textShadow: '2px 2px 6px black',
                    fontSize: '13px',
                    marginBottom: '-2px',
                  }}
                >
                  newsletter
                </h4>
                <h3
                  style={{
                    textShadow: '2px 3px 5px black',
                  }}
                >
                  Sign up for newsletter and get latest news and update
                </h3>
              </div>
            </div>
            <div className='col-md-1'></div>
            <div className='col-md-5'>
              <div className='footer-widget fl-wrap'>
                <div className='subscribe-widget fl-wrap'>
                  <div className='subcribe-form'>
                    <div id='subscribe'>
                      <input
                        className='enteremail fl-wrap'
                        id={'email'}
                        name={'email'}
                        label={'Email'}
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        defaultValue={formik.values.email}
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                        placeholder='Enter Your Email'
                        spellcheck='false'
                        type='email'
                        required={true}
                      />
                      <button
                        style={{padding: "0px"}}
                        onClick={() => setOpen(true)}
                        id='subscribe-button'
                        className='subscribe-button color-bg'
                      >
                        Subscribe
                      </button>
                      <label
                        for='subscribe-email'
                        className='subscribe-message'
                      ></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsLetter;
