import React, { useState, useEffect } from "react";
import Ratings from "../Ratings";

import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { Link } from "react-router-dom";

const PropertyCard = ({ property, addToWishlist, removeFromWishlist }) => {
  const cleaningFee = property?.propertyPricePolicy
    ? parseInt(property?.propertyPricePolicy?.cleaningFee)
    : 0;
  const totalCost = property?.perNightPrice + cleaningFee;

  const [isInWishlist, setIsInWishlist] = useState(property?.wishList);
  return (
    <>
      <div className='listing-item single_list_card'>
        <article className='geodir-category-listing fl-wrap'>
          <div className='geodir-category-img fl-wrap'>
            <a className='geodir-category-img_item'>
              <img
                src={
                  property?.thumbnailUrl === undefined
                    ? require("../../assets/images/all/3.jpg")
                    : property?.thumbnailUrl
                }
                alt=''
              />
              <div className='overlay'></div>
            </a>
            {property?.wishList !== undefined && (
              <>
                {isInWishlist ? (
                  <a
                    onClick={() => {
                      removeFromWishlist(property?.id);
                      setIsInWishlist(!isInWishlist);
                    }}
                    className='geodir_save-btn'
                    style={{ cursor: "pointer" }}
                  >
                    <FavoriteOutlinedIcon
                      style={{
                        fontSize: 25,
                        color: "#ac1b1b",
                        verticalAlign: "middle",
                      }}
                    />
                  </a>
                ) : (
                  <a
                    onClick={() => {
                      addToWishlist(property?.id);
                      setIsInWishlist(!isInWishlist);
                    }}
                    className='geodir_save-btn'
                    style={{ cursor: "pointer" }}
                  >
                    <FavoriteBorderIcon
                      style={{
                        fontSize: 25,
                        color: "#ac1b1b",
                        verticalAlign: "middle",
                      }}
                    />
                  </a>
                )}
              </>
            )}
            {/* <a className="permiumBucket">
              <DiamondIcon
                style={{
                  fontSize: 25,
                  color: '#0094fe',
                  verticalAlign: 'middle',
                }}
              />
            </a> */}
          </div>
          <Link to={`/property-detail/${property?.id}`}>
            <div className='geodir-category-content fl-wrap'>
              <div className='fixed-location'>
                <a
                  className='single-map-item'
                  style={{ textDecoration: "none", marginBottom: 0 }}
                >
                  <i className='fas fa-map-marker-alt'></i>
                  <span style={{ textAlign: "left" }}>
                    {property?.city},&nbsp;
                    {property?.country}
                  </span>
                </a>
              </div>
              <h3 className='title-sin_item'>
                <a style={{ textDecoration: "none" }}>{property?.title}</a>

                {/* <div className="geodir-category-location"> */}

                {/* </div> */}
              </h3>
              {/* <div className="p-id" style={{ display: 'inline-block' }}> */}
              <p style={{ paddingTop: 0 }}>Property ID: {property?.code}</p>
              {/* </div> */}
              <div
                style={{
                  textAlign: "initial",
                  paddingTop: 0,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Ratings
                  rating={
                    property?.average_rating
                      ? property?.average_rating?.averageRating
                      : 0
                  }
                />
                <span style={{ marginLeft: 3, color: "#c2c1c5", fontSize: 13 }}>
                  (
                  {property?.average_rating
                    ? parseInt(
                        property?.average_rating?.averageRating
                      )?.toFixed(1)
                    : 0}
                  ) (
                  {property?.average_rating
                    ? property?.average_rating?.reviewCount
                    : 0}{" "}
                  Reviews)
                </span>
              </div>

              <div className='geodir-category-content-details'>
                <ul>
                  <li>
                    <i className='fal fa-bed'></i>
                    <span>{property?.propertyCapacity?.noOfBedrooms}</span>
                  </li>
                  <li>
                    <i className='fal fa-bath'></i>
                    <span>{property?.propertyCapacity?.noOfBathrooms}</span>
                  </li>
                  <li>
                    <i className='fal fa-users'></i>
                    <span>
                      {property?.propertyCapacity?.noOfAdults +
                        property?.propertyCapacity?.noOfChildren}
                    </span>
                  </li>
                </ul>
              </div>
              <div className='geodir-category-footer'>
                <div className='geodir-category-content_price'>
                  <small>{process.env.REACT_APP_CURRENCY} </small>{" "}
                  {property?.perNightPrice?.toLocaleString("en-US")}
                  <span
                    style={{ color: "rgba(255, 255, 255, 0.7)", paddingTop: 3 }}
                    color='white'
                  >
                    <small>Night</small>
                  </span>
                </div>
                <div className='geodir-category-content_price'>
                  <small>{process.env.REACT_APP_CURRENCY} </small>{" "}
                  {totalCost?.toLocaleString("en-US")}
                  <span
                    style={{ color: "rgba(255, 255, 255, 0.7)", paddingTop: 3 }}
                  >
                    <span style={{ fontSize: "8px" }}>
                      <small>Inc. cleaning fee</small>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </Link>
        </article>
      </div>
    </>
  );
};

export default PropertyCard;
