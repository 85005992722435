import { API_URL } from "../../config";
import axios from "axios";

export const getHomePagePropertiesApi = async (userId) => {
  let url;
  if (userId) {
    url = `property/homepage-properties?userId=${userId}`;
  } else {
    url = `property/homepage-properties`;
  }
  try {
    const response = await axios.get(API_URL + url);
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log("Bad Response of property : ", response);
      return response;
    }
  }
};
