import React from 'react';
// import TextField from '@mui/material/TextField'
import { InputText } from 'primereact/inputtext';
import { Visibility, VisibilityOff } from '@mui/icons-material';

//Simple input component
const InputPass = ({
  id,
  sx,
  name,
  type,
  label,
  value,
  error,
  margin,
  disabled,
  onChange,
  onClick,
  helperText,
  defaultValue,
}) => {
  const [eyeSee, setEyeSee] = React.useState(false);

  return (
    <>
      <div className="field" style={{ position: 'relative' }}>
        {/* <label htmlFor="username1">{label}</label> */}
        <InputText
          variant="outlined"
          fullWidth
          id={id}
          sx={sx}
          type={eyeSee === false ? 'password' : 'text'}
          inputProps={{
            maxLength: 50,
          }}
          style={{
            minHeight: '55px',
            ...sx,
          }}
          name={name}
          label={label}
          placeholder={label}
          value={value}
          error={error}
          margin={margin}
          disabled={disabled}
          onChange={onChange}
          helperText={helperText}
          defaultValue={defaultValue}
          onClick={onClick}
          className={error && 'p-invalid'}
        />
        <div
          style={{
            position: 'absolute',
            top: '0',
            right: '0',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '12px',
          }}
        >
          {eyeSee === false ? (
            <VisibilityOff
              onClick={() => setEyeSee(true)}
              sx={{ fontSize: '20px', color: '#0095ff', cursor: 'pointer' }}
            />
          ) : (
            <Visibility
              onClick={() => setEyeSee(false)}
              sx={{ fontSize: '20px', color: '#0095ff', cursor: 'pointer' }}
            />
          )}
        </div>
        {error && (
          <small
            id="username2-help"
            className="p-error"
            style={{
              display: 'block',
              color: 'red',
              textAlign: 'left',
            }}
          >
            {helperText}
          </small>
        )}
      </div>
    </>
  );
};

export default InputPass;
