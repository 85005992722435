import React from "react";
import { Map, GoogleApiWrapper, InfoWindow, Marker } from "google-maps-react";

const mapStyles = {
  width: "100%",
  height: "100%",
  borderRadius: 8,
};

export const MapLocation = ({
  google,
  address,
  mapCenter,
  activeMarker,
  onMarkerClick,
  showingInfoWindow,
  onMarkerInfoClose,
}) => {
  return (
    <div id="googleMaps">
      <Map
        zoom={15}
        google={google}
        style={mapStyles}
        initialCenter={mapCenter}
        center={mapCenter}
      >
        <Marker
          position={mapCenter}
          onClick={onMarkerClick}
          name={address}
          //   draggable={true}
          //   onDragend={(t, map, coord) => this.onMarkerDragEnd(coord)}
        />
        <InfoWindow
          marker={activeMarker}
          visible={showingInfoWindow}
          onClose={onMarkerInfoClose}
        >
          <div>
            <i style={{ color: "#0095ff" }} className="fa fa-map-marker"></i>
            <span style={{ fontSize: 13, fontWeight: 500, marginLeft: 5 }}>
              {address}
            </span>
          </div>
        </InfoWindow>
      </Map>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyB53v_rdmTRQm8y9Iqa-g9h-2_qO0G6Fuo",
})(MapLocation);
