import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import { useParams } from "react-router-dom";
import { getPropertyByIdApi } from "../../../services/properties/getProperty";
import { Box, Grid, Rating, Typography } from "@mui/material";
import DUMMYAVATAR from "../../../assets/images/avatar/5.jpg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Buttons from "../../../components/form/button";
import NightlightRoundIcon from "@mui/icons-material/NightlightRound";
import PaymentsIcon from "@mui/icons-material/Payments";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";

import { InputTextarea } from "primereact/inputtextarea";
import { ReserveCardContactHost } from "../PropertyDetailPage/ReserveCardContactHost";
import { addBookingApi } from "../../../services/booking/addBooking";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { RadioButton } from "primereact/radiobutton";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PetsIcon from "@mui/icons-material/Pets";
import ChildCareIcon from "@mui/icons-material/ChildCare";

/*-----------RESERVE-CARD--------------*/
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import moment from "moment";
import { initiateChat } from "../../../services/Chat/chat";
// import io from "socket.io-client";
import { getUserWalletApi } from "../../../services/wallet/getUserWallet";
import { calculateBookingPriceApi } from "../../../services/booking/calculateBookingPrice";

const ContactHostPage = () => {
  const { id } = useParams();
  const userData = JSON.parse(localStorage.getItem("userData")) || null;
  const [propertyDetail, setPropertyDetail] = useState({});
  const [userDetail, setUserDetail] = useState({});
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [convertedContent, setConvertedContent] = useState("");
  const [message, setMessage] = useState("");
  const [chatId, setChatId] = useState(0);
  const [wallet, setWallet] = useState({});
  const [values, setValues] = useState({});

  //Get User Wallet Api
  useEffect(() => {
    const getWallet = async () => {
      const res = await getUserWalletApi(userData?.id);
      if (res.status === 404 || res.status === 401 || res.status === 403) {
        setWallet({});
      } else {
        setWallet(res);
      }
    };
    getWallet();
  }, [userData?.id]);

  /*-------------ReserveCard-------------------*/
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  const [displayResponsive, setDisplayResponsive] = useState(false);
  const [displayPosition, setDisplayPosition] = useState(false);
  const dialogFuncMap = {
    displayPosition: setDisplayPosition,
    displayResponsive: setDisplayResponsive,
  };
  const [position, setPosition] = useState("center");
  const userToken = localStorage.getItem("userToken");
  const [report, setReport] = useState(null);
  const [screen, setScreen] = useState(0);

  //socket
  // const socket = io.connect("wss://api.staywo.com:8001");

  const getpropertyDetailDetail = async () => {
    try {
      const response = await getPropertyByIdApi(id);
      if (response.status === 200) {
        setPropertyDetail(response.data.data);
        setUserDetail(response.data.data.user);
      } else {
        window.location = "/";
      }
    } catch (err) {
      window.location = "/";
    }
  };

  useEffect(() => {
    getpropertyDetailDetail();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const sendMessage = () => {};

  /*------------RESERVE CARD FUNCTION----------------*/

  const basicInfoFormik = useFormik({
    initialValues: {
      startDate: "",
      endDate: "",
      ownerId: 0,
      noOfGuest: 0,
      noOfAdults: 0,
      noOfChildren: 0,
      noOfInfants: 0,
      noOfPets: 0,
      guestId: userData?.id,
      propertyDetailId: id,
    },
    validationSchema: () => {
      return yup.object({
        startDate: yup.string().required("start date is required is required"),
        endDate: yup.string().required("end date is required is required"),
      });
    },
    onSubmit: (values) => {
      userToken ? confirm1() : history("/login");
    },
  });

  //confirm function
  const confirm1 = () => {
    const totalNights = GetDayDifference();
    const totalNightsCost = propertyDetail.perNightPrice * totalNights;
    const totalGst = (totalNightsCost * 13) / 100;
    const totalAmount = totalNightsCost + totalGst;
    confirmDialog({
      message: (
        <div
          style={{
            width: "30vw",
            textAlign: "left",
            paddingTop: "20px",
          }}
        >
          <Grid className='confirmation-popup' container spacing={2}>
            <Grid item xs={6}>
              <div className=''>
                <h1>DURATION</h1>
                <p>
                  {moment(basicInfoFormik.values.startDate).format(
                    "DD MMM YYYY"
                  )}{" "}
                  -{" "}
                  {moment(basicInfoFormik.values.endDate).format("DD MMM YYYY")}
                </p>
                <p>
                  <i className='fas fa-moon'></i> {totalNights} nights
                </p>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <h1>GUEST</h1>
                <p>
                  <i className='fas fa-user'></i>{" "}
                  {basicInfoFormik.values.noOfGuest}
                </p>
                <p>
                  {basicInfoFormik.values.noOfAdults > 0
                    ? basicInfoFormik.values.noOfAdults + " Adults"
                    : ""}
                  {basicInfoFormik.values.noOfAdults > 0 &&
                  basicInfoFormik.values.noOfChildren > 0
                    ? " - "
                    : ""}
                  {basicInfoFormik.values.noOfChildren > 0
                    ? basicInfoFormik.values.noOfChildren + " Children"
                    : ""}
                </p>
                <p>
                  {basicInfoFormik.values.noOfInfants > 0
                    ? basicInfoFormik.values.noOfInfants + " Infants"
                    : ""}
                  {basicInfoFormik.values.noOfInfants > 0 &&
                  basicInfoFormik.values.noOfPets > 0
                    ? " - "
                    : ""}
                  {basicInfoFormik.values.noOfPets > 0
                    ? basicInfoFormik.values.noOfPets + " Pets"
                    : ""}
                </p>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div>
                <h1>BOOKING DATE &amp; TIME</h1>
                <p>{moment().format("DD MMM YYYY h:mm:ss a")}</p>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <h1>CHECK-IN</h1>
                <p>{moment().format("DD MMM YYYY h:mm:ss a")}</p>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <h1>CHECK-OUT</h1>
                <p>{moment().format("DD MMM YYYY h:mm:ss a")}</p>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div>
                <h1>PAYMENT INVOICE</h1>
                <div
                  style={{
                    display: "flex",
                    marginTop: "20px",
                    justifyContent: "space-between",
                  }}
                >
                  <p>{totalNights} nights </p>
                  <p>{process.env.REACT_APP_CURRENCY}{totalNightsCost}</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <p>GST 13%</p>
                  <p>{process.env.REACT_APP_CURRENCY}{totalGst.toFixed(2)}</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <p>Total</p>
                  <p>{process.env.REACT_APP_CURRENCY}{totalAmount.toFixed(2)}</p>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      ),
      header: "Booking Request",
      acceptLabel: "Send Request",
      rejectLabel: "Cancel",
      accept,
      reject,
    });
  };

  const GetDayDifference = () => {
    var given = moment(basicInfoFormik.values.startDate);
    var current = moment(basicInfoFormik.values.endDate);
    const diff = current.diff(given);
    const diffDuration = moment.duration(diff);
    return Math.ceil(diffDuration._milliseconds / (1000 * 60 * 60 * 24));
  };

  const createBooking = async () => {
    let values = basicInfoFormik.values;
    setLoading(true);
    try {
      if (values.noOfGuest < 1) {
        setLoading(false);
        toast.current.show({
          severity: "warn",
          summary: "Not Proceeded",
          detail: "Guest count must be at least one!",
          life: 7000,
        });
      }
      const response = await addBookingApi(values, propertyDetail.user.id);
      if (response.status === 200) {
        createChat();
        setLoading(false);
        toast.current.show({
          severity: "info",
          summary: "Booking Proceeded",
          life: 10000,
        });
      }
    } catch (err) {
      setLoading(false);
      toast.current.show({
        severity: "warn",
        summary: "Not Proceeded",
        detail: "there seems to be an error right now",
        life: 10000,
      });
    }
  };

  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);
    if (position) {
      setPosition(position);
    }
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };

  const accept = () => {
    toast.current.show({
      severity: "info",
      summary: "Request Sent",
      detail: "Booking request has been sent to Host",
      life: 10000,
    });
    createBooking();
  };

  const reject = () => {};
  const history = useNavigate();

  const createChat = async () => {
    const data = {
      propertyDetailId: propertyDetail?.id,
    };
    const response = await initiateChat(data);
    if (response.status === 201) {
      const chatId = response?.data?.id;
      const messageData = {
        senderId: userData?.id,
        chatId: chatId,
        content:
          message === ""
            ? "I am interested to rent your propertyDetail."
            : message,
      };
      // socket.emit(`message-in-chat-${chatId}`, { message: messageData });
      toast.current.show({
        severity: "info",
        summary: "Message Sent",
        detail: "A message have been sent to propertyDetail owner.",
        life: 10000,
      });
    }
  };

  // useEffect(() => {
  //   socket.on("recieve_message", (data) => {
  //     // setMessageRecieved(data.message)
  //   });
  // }, [socket]);
  //Calculate booking prices
  React.useEffect(() => {
    const getBookingCalculatedPrcie = async () => {
      const response = await calculateBookingPriceApi({
        propertyId: id,
        startDate: moment(basicInfoFormik.values.startDate).format(
          "YYYY-MM-DD"
        ),
        endDate: moment(basicInfoFormik.values.endDate).format("YYYY-MM-DD"),
        noOfAdults: parseInt(basicInfoFormik.values.noOfAdults),
        noOfChildren: parseInt(basicInfoFormik.values.noOfChildren),
        noOfInfants: parseInt(basicInfoFormik.values.noOfInfants),
        noOfPets: parseInt(basicInfoFormik.values.noOfPets),
        validate: true,
      });
      setValues(response.data.data);
    };
    if (basicInfoFormik.values.startDate && basicInfoFormik.values.endDate) {
      getBookingCalculatedPrcie();
    }
  }, [id, basicInfoFormik]);
  return (
    <div className='main'>
      <Header />
      <div className='wrapper'>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            margin: "10rem 2rem",
          }}
        >
          <Box sx={{ width: "66%" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
                padding: "20px 0px",
              }}
            >
              <Box>
                <Typography
                  sx={{ color: "#fff", fontSize: "22px", fontFamily: "Jost" }}
                >
                  Contact {userDetail?.firstName}
                </Typography>
                <div
                  style={{
                    color: "#878c9f",
                    marginTop: "-13px",
                    fontSize: "16px",
                  }}
                >
                  Typically responds within a day.
                </div>
              </Box>
              <Box>
                <div
                  className='show-reg-form dashboard-submenu-open'
                  style={{
                    borderLeft: "none",
                    alignSelf: "left",
                  }}
                >
                  <img
                    src={
                      userDetail?.profilePicture
                        ? userDetail?.profilePicture
                        : DUMMYAVATAR
                    }
                    width='50'
                    height='50'
                    alt='alt'
                  />
                </div>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
                padding: "4% 0px",
              }}
            >
              <Box>
                <Typography
                  sx={{ color: "#fff", fontSize: "22px", fontFamily: "Jost" }}
                >
                  Most travelers ask about
                </Typography>
                <Box>
                  <Typography
                    sx={{ color: "#fff", fontSize: "16px", marginTop: "10%" }}
                  >
                    Price & Availability
                  </Typography>
                  <ul
                    style={{
                      listStyle: "initial",
                      color: "#878c9f",
                      textAlign: "start",
                      margin: "0 15px",
                      fontSize: "16px",
                    }}
                  >
                    <li>
                      {userDetail?.firstName}'s home is available from Aug 10th
                      to Dec 14th.
                    </li>
                    <li>Full refund with limited period.</li>
                  </ul>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
                padding: "4% 0px",
              }}
            >
              <Box sx={{ width: "88%" }}>
                <Typography
                  sx={{ color: "#fff", fontSize: "22px", fontFamily: "Jost" }}
                >
                  Still have questions? Message the host.
                </Typography>
                <Box sx={{ width: "100%" }}>
                  <InputTextarea
                    rows={5}
                    cols={30}
                    value={message}
                    defaultValue={message}
                    placeholder='Send message to propertyDetail owner....'
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Buttons
                    onSubmit={basicInfoFormik.handleSubmit}
                    title='Send Message'
                    isLoading={false}
                    variant='contained'
                    style={{ width: "30%", margin: "10px 0px" }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Grid
              // md={5}
              // item
              // sx={{
              //   padding: "100px 25px",
              // }}
              >
                <Box
                  sx={{
                    borderRadius: "8px",
                    border: "1px solid rgba(255, 255, 255, 0.1)",
                    padding: "20px",
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <Box>
                      <img
                        src={propertyDetail?.thumbnailUrl}
                        alt='propertyDetail_image'
                        width={150}
                        height={150}
                      />
                    </Box>
                    <Box sx={{ marginLeft: "10px" }}>
                      <Typography
                        sx={{
                          color: "#fff",
                          fontFamily: "Jost",
                          fontSize: "22px",
                        }}
                      >
                        {propertyDetail?.title &&
                        propertyDetail?.title?.length > 30
                          ? propertyDetail?.title?.slice(0, 30) + "..."
                          : propertyDetail?.title?.slice(0, 30)}
                      </Typography>
                      <Typography sx={{ fontFamily: "Jost", fontSize: "14px" }}>
                        {propertyDetail?.city}, {propertyDetail?.country}
                      </Typography>
                      <Typography sx={{ fontFamily: "Jost", fontSize: "14px" }}>
                        Property ID: {propertyDetail?.code}
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Jost",
                          fontSize: "16px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Rating
                          name='half-rating-read'
                          value={
                            propertyDetail?.average_rating
                              ? propertyDetail?.average_rating?.averageRating
                              : 0
                          }
                          precision={0.5}
                          readOnly
                          sx={{ marginRight: "4px" }}
                        />
                        (
                        {propertyDetail?.average_rating
                          ? propertyDetail?.average_rating?.averageRating?.toFixed(
                              1
                            )
                          : 0}
                        ) (
                        {propertyDetail?.average_rating
                          ? ` ${propertyDetail?.average_rating?.reviewCount} Reviews`
                          : " (0 Reviews)"}
                        )
                      </Typography>
                    </Box>
                  </Box>
                  {basicInfoFormik.values.startDate &&
                    basicInfoFormik.values.endDate && (
                      <Box sx={{ marginTop: "10px" }}>
                        <Typography
                          sx={{
                            color: "#fff",
                            fontFamily: "Jost",
                            fontSize: "25px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <PaymentsIcon
                            sx={{ fontSize: "25px", marginRight: "5px" }}
                          />{" "}
                          Price Details
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#fff",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <NightlightRoundIcon
                              sx={{ fontSize: "18px", marginRight: "5px" }}
                            />{" "}
                            Nightly Price x {values?.noOfNights} (nights)
                          </Typography>
                          <Typography>
                            {process.env.REACT_APP_CURRENCY}{" "}
                            {values?.totalNightsCharges?.toLocaleString(
                              "en-US"
                            )}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#fff",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {" "}
                            <CleaningServicesIcon
                              sx={{ fontSize: "18px", marginRight: "5px" }}
                            />{" "}
                            Cleaning Fee
                          </Typography>
                          <Typography>
                            {process.env.REACT_APP_CURRENCY}{" "}
                            {values?.cleaningFee?.toLocaleString("en-US")}{" "}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "row",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#fff",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <PersonAddIcon
                              sx={{ fontSize: "18px", marginRight: "5px" }}
                            />{" "}
                            Additional Guests Fee
                          </Typography>
                          <Typography>
                            <span style={{ color: "#fff" }}>
                              {values?.totalExtraGuests}
                            </span>{" "}
                            x{" "}
                            <span style={{ color: "#fff" }}>
                              {process.env.REACT_APP_CURRENCY}{" "}
                              {values?.extraGuestFee?.toLocaleString("en-US")}
                            </span>{" "}
                            ={" "}
                            <span style={{ color: "#fff" }}>
                              {" "}
                              {process.env.REACT_APP_CURRENCY}{" "}
                              {values?.totalExtraGuestFee?.toLocaleString(
                                "en-US"
                              )}
                            </span>
                          </Typography>
                        </Box>
                        {propertyDetail?.propertyDetailCapacity?.noOfInfants >
                        0 ? (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#fff",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <ChildCareIcon
                                sx={{ fontSize: "18px", marginRight: "5px" }}
                              />{" "}
                              Infant Fee
                            </Typography>
                            <Typography>No Fee</Typography>
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#fff",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <ChildCareIcon
                                sx={{ fontSize: "18px", marginRight: "5px" }}
                              />{" "}
                              Infant Fee
                            </Typography>
                            <Typography>Not Allowed</Typography>
                          </Box>
                        )}
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#fff",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <PetsIcon
                              sx={{ fontSize: "18px", marginRight: "5px" }}
                            />{" "}
                            Pet Fee
                          </Typography>
                          {propertyDetail?.propertyDetailCapacity?.noOfPets >
                          0 ? (
                            <Typography>
                              {values?.noOfPets} x{" "}
                              {process.env.REACT_APP_CURRENCY}{" "}
                              {values?.petFee?.toLocaleString("en-US")} ={" "}
                              {values?.totalPetFee?.toLocaleString("en-US")}
                            </Typography>
                          ) : (
                            <Typography>Not Allowed</Typography>
                          )}
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#fff",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <AccountBalanceIcon
                              sx={{ fontSize: "18px", marginRight: "5px" }}
                            />{" "}
                            Service Fee ({} %)
                          </Typography>
                          <Typography>
                            {process.env.REACT_APP_CURRENCY}{" "}
                            {values?.totalServiceCharges?.toLocaleString(
                              "en-US"
                            )}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#fff",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <AccountBalanceIcon
                              sx={{ fontSize: "18px", marginRight: "5px" }}
                            />{" "}
                            GST ({} %)
                          </Typography>
                          <Typography>
                            {process.env.REACT_APP_CURRENCY}{" "}
                            {values?.totalGSTFee?.toLocaleString("en-US")}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#fff",
                              fontSize: "19px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <DoneOutlineIcon
                              sx={{ fontSize: "19px", marginRight: "5px" }}
                            />{" "}
                            Total ( {process.env.REACT_APP_CURRENCY} )
                          </Typography>
                          <Typography sx={{ fontSize: "18px", color: "#fff" }}>
                            {process.env.REACT_APP_CURRENCY}{" "}
                            {values?.grossAmount?.toLocaleString("en-US")}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                </Box>
              </Grid>
            </Box>
            <Box sx={{ maxWidth: "30vw", width: "100%" }}>
              <ReserveCardContactHost
                formik={basicInfoFormik}
                propertyDetail={propertyDetail}
                id={id}
                onClick={onClick}
              />
            </Box>
          </Box>
        </Box>
        <Footer />
        <Toast ref={toast} position='bottom-right' />
        <ConfirmDialog />
        <Dialog
          header='Report this listing?'
          visible={displayResponsive}
          onHide={() => onHide("displayResponsive")}
          breakpoints={{ "960px": "75vw" }}
          style={{ width: "50vw", fontSize: "15px" }}
        >
          {screen === 0 && (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "start",
                justifyContent: "start",
                height: "100%",
                flexDirection: "column",
              }}
            >
              <h1 style={{ fontSize: "22px", fontWeight: "bold" }}>
                Why are you reporting this listing?
              </h1>
              <p>This wont be shared with the Host.</p>
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "start",
                  height: "100%",
                  flexDirection: "column",
                }}
              >
                <div
                  className='field-radiobutton'
                  style={{ margin: "8px 0px" }}
                >
                  <RadioButton
                    inputId='report1'
                    name='report'
                    value='Chicago'
                    onChange={(e) => setReport(e.value)}
                    checked={report === "Chicago"}
                  />
                  <label
                    htmlFor='report1'
                    style={{ margin: "0px 8px", fontSize: "18px" }}
                  >
                    It’s inaccurate or incorrect
                  </label>
                </div>
                <div
                  className='field-radiobutton'
                  style={{ margin: "8px 0px" }}
                >
                  <RadioButton
                    inputId='report2'
                    name='report'
                    value='It’s not a real place to stay'
                    onChange={(e) => setReport(e.value)}
                    checked={report === " It’s not a real place to stay"}
                  />
                  <label
                    htmlFor='report2'
                    style={{ margin: "0px 8px", fontSize: "18px" }}
                  >
                    It’s not a real place to stay
                  </label>
                </div>
                <div
                  className='field-radiobutton'
                  style={{ margin: "8px 0px", fontSize: "18px" }}
                >
                  <RadioButton
                    inputId='report3'
                    name='report'
                    value='It’s a scam'
                    onChange={(e) => setReport(e.value)}
                    checked={report === "It’s a scam"}
                  />
                  <label
                    htmlFor='report3'
                    style={{ margin: "0px 8px", fontSize: "18px" }}
                  >
                    It’s a scam
                  </label>
                </div>
                <div
                  className='field-radiobutton'
                  style={{ margin: "8px 0px", fontSize: "18px" }}
                >
                  <RadioButton
                    inputId='report4'
                    name='report'
                    value=' It’s offensive'
                    onChange={(e) => setReport(e.value)}
                    checked={report === "It’s offensive"}
                  />
                  <label
                    htmlFor='report4'
                    style={{ margin: "0px 8px", fontSize: "18px" }}
                  >
                    It’s offensive
                  </label>
                </div>
                <div
                  className='field-radiobutton'
                  style={{ margin: "8px 0px", fontSize: "18px" }}
                >
                  <RadioButton
                    inputId='report5'
                    name='report'
                    value='It’s something else'
                    onChange={(e) => setReport(e.value)}
                    checked={report === "It’s something else"}
                  />
                  <label
                    htmlFor='report5'
                    style={{ margin: "0px 8px", fontSize: "18px" }}
                  >
                    It’s something else
                  </label>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  label='Next'
                  onClick={() => setScreen(screen + 1)}
                  style={{ width: "10rem", height: "3rem", fontSize: "15px" }}
                />
              </div>
            </div>
          )}
          {screen === 1 && (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "start",
                justifyContent: "start",
                height: "100%",
                flexDirection: "column",
              }}
            >
              <h1 style={{ fontSize: "22px", fontWeight: "bold" }}>
                We got your report.
              </h1>
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "start",
                  height: "100%",
                  flexDirection: "column",
                }}
              >
                <p style={{ fontSize: "16px" }}>
                  Thanks for taking the time to let us know what’s going on.
                  Reports like yours help keep the staywo community safe and
                  secure.
                </p>
              </div>
            </div>
          )}
        </Dialog>
      </div>
    </div>
  );
};

export default ContactHostPage;
