import React from 'react';
import USERIMAGE from '../../assets/images/avatar/5.jpg';
//import userImg from 'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg'

const InfoBar = ({ label }) => {
  const userData = JSON.parse(localStorage.getItem('userData')) || null;
  return (
    <div className="dashboard-title fl-wrap">
      <div className="dashboard-title-item">
        <span>{label}</span>
      </div>
      <div className="dashbard-menu-header">
        <div className="dashbard-menu-avatar fl-wrap">
          <img src={userData.profilePicture ? userData.profilePicture : USERIMAGE} alt={`${userData?.firstName} ${userData?.lastName}`} />
          <h4>
            Welcome ,{' '}
            <span>
              {userData?.firstName} {userData?.lastName}
            </span>
          </h4>
        </div>
      </div>
    </div>
  );
};

export default InfoBar;
