import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

const SkeletonLoader = ({ height }) => {
  return (
    <Stack spacing={2}>
      <Skeleton variant="rectangular" width={"100%"} height={height} />
    </Stack>
  );
};

export default SkeletonLoader;
