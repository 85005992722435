import React from "react";

const Breadcrumbs = () => {
  return (<div>
    {/*<div className="breadcrumbs fw-breadcrumbs smpar fl-wrap">
      <div className="container">
        <div className="breadcrumbs-list">
          <a style={{ textDecoration: "none" }} href="#">
            Home
          </a>
          <a style={{ textDecoration: "none" }} href="#">
            Listings
          </a>
          <a style={{ textDecoration: "none" }} href="#">
            New York
          </a>
          <span>Property Single</span>
  </div>*/}
        {/* <div className="show-more-snopt smact">
          <i className="fal fa-ellipsis-v"></i>
        </div>
        <div className="show-more-snopt-tooltip">
          <a href="#sec15" className="custom-scroll-link">
            <i className="fas fa-comment-alt"></i> Write a review
          </a>
          <a href="#">
            <i className="fas fa-exclamation-triangle"></i> Report{" "}
          </a>
        </div>
        <a
          className="print-btn tolt"
          href="javascript:window.print()"
          data-microtip-position="bottom"
          data-tooltip="Print"
        >
          <i className="fas fa-print"></i>
        </a>
        <a
          className="compare-top-btn tolt"
          data-microtip-position="bottom"
          data-tooltip="Compare"
          href="#"
        >
          <i className="fas fa-random"></i>
        </a>
        */}
       {/* <div className="like-btn">
          <i className="fas fa-heart"></i> Save
        </div> 
      </div>
      */}</div>
  );
};

export default Breadcrumbs;
