import ProvideId from "./screens/Website/ProvideId/provideId";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import AddPropertyPage from "./screens/Admin-Panel/AddPropertyPage/AddPropertyPage";
import MyPropertyListPage from "./screens/Admin-Panel/MyPropertyListPage/MyPropertyListPage";
import Login from "./screens/Authentication/LoginPage/login";
import Signup from "./screens/Authentication/SignupPage/Signup";
import HomePage from "./screens/Website/HomePage/HomePage";
import OtpPage from "./screens/Authentication/Otp/OtpPage";
import PhoneNumberLoginPage from "./screens/Authentication/PhoneNumberLoginPage/PhoneNumberLoginPage";
import PropertyDetailWithoutLogin from "./screens/Website/PropertyDetailPage/PropertyDetailWithoutLoginPage";
import VerificationPage from "./screens/Admin-Panel/VerificationPage/VerificationPage";
import PhotoId from "./screens/Website/photoId/photoId";
import ChooseId from "./screens/Website/chooseId/chooseId";
import Retake from "./screens/Website/Retake/retake";
import ContactPage from "./screens/Website/ContactPage/ContactPage";
import EditPropertyPageRevamp from "./screens/Admin-Panel/EditPropertyPage/EditPropertyRevamp";
import FAQPage from "./screens/Website/FAQPage/FAQPage";
import EditProfilePage from "./screens/Admin-Panel/EditProfilePage/EditProfilePage";
import BookingPage from "./screens/Admin-Panel/BookingPage/BookingPage";
import MessagesPage from "./screens/Admin-Panel/MessagesPage/MessagesPage";
import UserProfile from "./screens/Admin-Panel/UserProfile/UserProfile";
import NotificationPage from "./screens/Admin-Panel/NotificationPage/NotificationPage";
import PendingTripsPage from "./screens/Admin-Panel/PendingTripsPage/PendingTripsPage";
import ComingSoon from "./screens/Website/ComingSoon/ComingSoon";
import BookingPendingPage from "./screens/Admin-Panel/BookingPendingPage/BookingPendingPage";
import ForgetPasswordEmailPage from "./screens/ForgetPasswordEmailPage/ForgetPasswordEmailPage";
import ForgetPasswordPage from "./screens/ForgetPasswordPage/ForgetPasswordPage";
import UpcomingReservation from "./screens/Admin-Panel/UpcomingReservation/UpcomingReservation";
import PrivacyPolicy from "./screens/Website/PrviacyandTerms/PrivacyPolicy";
import TermsandConditions from "./screens/Website/PrviacyandTerms/TermsandConditions";
import CheckoutPage from "./screens/Website/CheckoutPage/CheckoutPage";
import PreviousReservation from "./screens/Admin-Panel/PreviousReservation/PreviousReservation";
import CurrentTrips from "./screens/Admin-Panel/CurrentTrips/CurrentTrips";
import Notifications from "./screens/Website/Notifications";
import TransactionHistory from "./screens/Website/TransactionHistory";
import HostDetailPage from "./screens/Website/Host";
import CoHostPage from "./screens/Website/CoHostPage";
import CoHostPermissionsPage from "./screens/Website/CoHostPage/permissions";
import CurrentReservations from "./screens/Admin-Panel/CurrentReservations/CurrentReservations";
import GuestReview from "./screens/Admin-Panel/Review/GuestReview";
import Dashboard from "./screens/Admin-Panel/Dashboard/Dashboard";
import UpcomingTrips from "./screens/Admin-Panel/UpcomingTrips/UpcomingTrips";
import PreviousTrips from "./screens/Admin-Panel/PreviousTrips/PreviousTrips";
import BookingCalendar from "./screens/Admin-Panel/BookingCalendar/BookingCalendar";
import ReservationDetail from "./screens/Website/ReservationDetails/ReservationDetail";
import WishlistProperty from "./screens/Admin-Panel/WishlistProperty/WishlistProperty";
import PropertyInsight from "./screens/Admin-Panel/PropertyInsight/PropertyInsight";
import AuthVerifyPassword from "./screens/Authentication/VerifyPass/AuthVerifyPassword";
import ContactHostPage from "./screens/Website/contactHost/ContactHostPage";
import AdditionalPaymentsPage from "./screens/Website/AdditonalPayments/AdditionalPaymentsPage";
import PaymentRequestsPage from "./screens/Website/PaymentRequests/PaymentRequests";
import CancelReservation from "./components/BookigDetails/CancelReservation";
import PendingCancellationRequest from "./screens/Admin-Panel/PendingCancellationRequest/PendingCancellationRequest";
import ModifyBookingPage from "./screens/Website/ModifyBookingPage/ModifyBookingPage";
import BookingCheckout from "./screens/Website/BookingCheckout/BookingCheckout";
import MyEarningsPage from "./screens/Website/WalletAndMyEarnings/MyEarnings";
import WalletPage from "./screens/Website/WalletAndMyEarnings/Wallet";
import AddBankAccountPage from "./screens/Website/WalletAndMyEarnings/AddBankAccountPage";
import * as React from "react";
import InviteGuestPage from "./screens/Website/InviteGuest/InviteGuestPage";
import TopUpPage from "./screens/Website/WalletAndMyEarnings/TopUpPage";
import BookingDone from "./screens/Website/BookingCheckout/BookingDone";
import AddCardPage from "./screens/Website/WalletAndMyEarnings/AddCardPage";
import YourCardsPage from "./screens/Website/WalletAndMyEarnings/YourCardsPage";
import AuthVerifyEmail from "./screens/Authentication/VerifyEmail/verifyEmail";
import BookingTransactionHistory from "./screens/Website/TransactionHistory/BookingTransactionHistory";
import NewPropertyListingPage from "./screens/Website/PropertyListingPage/NewPropertyListingPage";
import Error404 from "./screens/Website/Error/Error404";
import Account from "./screens/Website/Account/Account";

import PaymentAndPayout from "./screens/Website/paymentAndPayouts/PaymentAndPayout";
import LoginAndSecurity from "./screens/Website/loginAndSecurity/LoginAndSecurity";
import ManagePropertyDocuments from "./screens/Website/ManagePropertyDocuments/ManagePropertyDocuments";
import BookingDetail from "./screens/Website/BookingCheckout/BookingDetail";
import AwaitingPaymentPage from "./screens/Admin-Panel/AwaitingPaymentsPage/AwaitingPaymentsPage";
import VendorWithdrawalHistory from "./screens/Admin-Panel/VendorWithdrawalHistory/VendorWithdrawalHistory";
import HostReview from "./screens/Admin-Panel/Review/HostReview";
import ActionPage from "./screens/Admin-Panel/ActionPage/ActionPage";
import TawkTo from "tawkto-react";
import Donation from "./screens/Website/Donation/Donation";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#0095ff",
    },
  },
});

// const PrivateWrapper = ({ auth: { isAuthenticated } }) => {
//   return localStorage.getItem('userToken') ? <Outlet /> : <Navigate to="/login" />;
// };

function App() {
  React.useEffect(() => {
    var tawk = new TawkTo(
      process.env.REACT_APP_TAWKTO_PROPERTY_ID,
      process.env.REACT_APP_TAWKTO_ID
    );

    tawk.onStatusChange((status) => {
    });
  }, []);
  function PrivateRoute() {
    return localStorage.getItem("userToken") ? (
      <Outlet />
    ) : (
      <Navigate to='/login' />
    );
  }
  const LoginProtectedRoute = () => {
    return localStorage.getItem("userToken") ? <Navigate to='/' /> : <Login />;
  };

  function SignupProtectedRoute() {
    return localStorage.getItem("userToken") ? (
      <Navigate to='/home' />
    ) : (
      <Signup />
    );
  }

  return (
    <>
      <ThemeProvider theme={darkTheme}>
        <Routes>

          <Route element={<PrivateRoute />}>
            <Route path='/admin/actions' element={<ActionPage />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path='/admin/dashboard' element={<Dashboard />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path='/admin/add-property' element={<AddPropertyPage />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route
              path='/admin/property-insight/:id'
              element={<PropertyInsight />}
            />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path='/book/:id' element={<BookingCheckout />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path='/booking/:id' element={<BookingDetail />} />
          </Route>
          <Route path='/booking-done/:bookingId' element={<BookingDone />} />
          <Route element={<PrivateRoute />}>
            <Route
              path='/modify-booking/:bookingId'
              element={<ModifyBookingPage />}
            />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path='/contact-host/:id' element={<ContactHostPage />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path='/wallet/top-up' element={<TopUpPage />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route
              path='/admin/cancel-reservation/:id'
              element={<CancelReservation />}
            />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route
              path='/admin/manage-requests'
              element={<PaymentRequestsPage />}
            />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route
              path='/admin/property-listing'
              element={<MyPropertyListPage />}
            />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route
              path='/admin/pending-cancellation-request'
              element={<PendingCancellationRequest />}
            />
          </Route>

          {/* <Route element={<PrivateRoute />}> */}
          <Route element={<PrivateRoute />}>
            <Route
              path='/admin/edit-property/:id'
              element={<EditPropertyPageRevamp />}
            />
          </Route>

          {/* </Route> */}

          <Route element={<PrivateRoute />}>
            <Route path='/admin/edit-profile' element={<EditProfilePage />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path='/admin/bookings' element={<BookingPage />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path='/admin/messages' element={<MessagesPage />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path='/admin/messages/:id' element={<MessagesPage />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path='/admin/user-profile' element={<UserProfile />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path='/admin/notifications' element={<NotificationPage />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path='/admin/user-profile' element={<UserProfile />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path='/admin/notifications' element={<NotificationPage />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path='/admin/account' element={<Account />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path='/admin/pending-trips' element={<PendingTripsPage />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path='/admin/current-trips' element={<CurrentTrips />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path='/admin/upcoming-trips' element={<UpcomingTrips />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path='/admin/previous-trips' element={<PreviousTrips />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path='/admin/current-trips' element={<CurrentTrips />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path='/admin/upcoming-trips' element={<UpcomingTrips />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path='/admin/previous-trips' element={<PreviousTrips />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route
              path={`/admin/payment/:bookingId`}
              element={<CheckoutPage />}
            />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route
              path='/admin/booking-pending'
              element={<BookingPendingPage />}
            />
          </Route>
          <Route path='/chooseId' element={<ChooseId />} />
          <Route path='/retake' element={<Retake />} />
          <Route path='/about-host/:id' element={<UserProfile />} />

          <Route path='/photoId' element={<PhotoId />} />

          <Route path='/provideId' element={<ProvideId />} />

          <Route
            path='/auth/email/verify-email/:token/:email'
            element={<AuthVerifyEmail />}
          />

          <Route element={<PrivateRoute />}>
            <Route
              path='/admin/upcoming-reservation'
              element={<UpcomingReservation />}
            />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route
              path='/admin/previous-reservation'
              element={<PreviousReservation />}
            />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route
              path='/admin/vendor-withdrawal-history'
              element={<VendorWithdrawalHistory />}
            />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route
              path='/admin/current-reservations'
              element={<CurrentReservations />}
            />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route
              path='/admin/awaiting-trips'
              element={<AwaitingPaymentPage />}
            />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route
              path='/admin/id-verification'
              element={<VerificationPage />}
            />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path='/admin/reviews' element={<GuestReview />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route
              path='/admin/rate-guest/:guestId/:propertyId/:bookingId'
              element={<GuestReview />}
            />
          </Route>
          <Route
            path='/auth/email/verify/:token/:email'
            element={<AuthVerifyPassword />}
          />
          <Route element={<PrivateRoute />}>
            <Route
              path='/rate-host/:hostId/:propertyId/:bookingId'
              element={<HostReview />}
            />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path='/admin/calendar/:id' element={<BookingCalendar />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path='/admin/wishlist/' element={<WishlistProperty />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route
              path='/additional-payments/:bookingId'
              element={<AdditionalPaymentsPage />}
            />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route
              path='/additional-payments/:bookingId'
              element={<AdditionalPaymentsPage />}
            />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path='/admin/my-earnings' element={<MyEarningsPage />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route
              path='/admin/invite-guest/:id'
              element={<InviteGuestPage />}
            />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path='/wallet' element={<WalletPage />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route
              path='/admin/bank-account'
              element={<AddBankAccountPage />}
            />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path='/admin/add-card' element={<AddCardPage />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path='/admin/your-cards' element={<YourCardsPage />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route
              path='/admin/manage-property-documents/:propertyId'
              element={<ManagePropertyDocuments />}
            />
          </Route>

          {/* </Route> */}
          <Route
            path='/property-listing'
            element={<NewPropertyListingPage />}
          />
          <Route
            path='/property-detail/:id'
            element={<PropertyDetailWithoutLogin />}
          />
          <Route path='/contact' element={<ContactPage />} />
          <Route path='/support' element={<FAQPage />} />
          {/* <Route path='/' element={<ComingSoon />} /> */}
          <Route path='/' element={<HomePage />} />
          <Route path='/login' element={<LoginProtectedRoute />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route
            path='/terms-and-conditions'
            element={<TermsandConditions />}
          />
          <Route path='/notifications' element={<Notifications />} />
          <Route path='/host-details/:id' element={<HostDetailPage />} />
          <Route path='/admin/co-hosts/:id' element={<CoHostPage />} />
          <Route path='/reservation-detail' element={<ReservationDetail />} />
          <Route path='/donate' element={<Donation />} />
          <Route
            path='/admin/co-host-permissions/'
            element={<CoHostPermissionsPage />}
          />
          <Route element={<PrivateRoute />}>
            <Route
              path='/admin/booking-transaction-history'
              element={<BookingTransactionHistory />}
            />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route
              path='/admin/wallet-transaction-history'
              element={<TransactionHistory />}
            />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route
              path='/admin/payment-payouts'
              element={<PaymentAndPayout />}
            />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route
              path='/admin/login-and-security'
              element={<LoginAndSecurity />}
            />
          </Route>

          <Route
            path='/login-through-phone'
            element={<PhoneNumberLoginPage />}
          />
          <Route
            path='/email-verification'
            element={<ForgetPasswordEmailPage />}
          />
          <Route path='/forget-password' element={<ForgetPasswordPage />} />
          <Route path='/signup' element={<SignupProtectedRoute />} />
          <Route path='/otp' element={<OtpPage />} />
          {/* If no route matches the request url  */}
          <Route path='*' element={<Error404 />} />
        </Routes>

        {/* <TawkMessengerReact
          propertyId={process.env.REACT_APP_TAWKTO_PROPERTY_ID}
          widgetId={process.env.REACT_APP_TAWKTO_ID}
        /> */}
      </ThemeProvider>
    </>
  );
}

export default App;
