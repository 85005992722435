import React, { useEffect, useRef } from 'react'
import { Grid } from '@mui/material'
import { Typography, Link, Divider } from '@mui/material'
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { GuestContribution } from '../../../services/Activation/ActivationStatus';
import { Toast } from 'primereact/toast';


export default function Guest() {
  const toast = useRef();

  const [checked, setChecked] = React.useState(false);
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleGuestContribution = async () => {

    const GuestContribute = await GuestContribution();
    if (checked === true && GuestContribute.status == 200) {
      toast.current.show({
        severity: 'success',
        summary: 'contributions successfully!',
        detail: 'Guest contributions successfully!',
        life: 10000,
      });

    }
 
    if (GuestContribute.status == 401) {
      toast.current.show({
        severity: 'error',
        summary: 'contributions error!',
        detail: 'Guest contributions error!',
        life: 10000,
      });
      setChecked(false)

    }

  }
  useEffect(() => {
    handleGuestContribution();
  }, [checked])
  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? 'white' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));
  return (
    <>
      <Toast ref={toast} position='bottom-right' />

      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={8}>
          <Typography variant="h5" component="h4" style={{ color: "white", textAlign: "start" }}>Guest contributions</Typography>
          <p>To show their appreciation for great hospitality, guests can send an optional financial contribution to a host after completing a stay or an Airbnb Experience. You can choose to automatically allow or decline future contributions from guests. <Link style={{ cursor: "pointer" }}>Learn more</Link> </p>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ marginTop: 1 }}>Allow contributions</Typography>

        </Grid>
        <Grid item xs={2}>
          <FormControlLabel
            control={<IOSSwitch sx={{ m: 1 }} checked={checked} onChange={handleChange} />}
          />

        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 4 }} />
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={8} sx={{ marginTop: 5 }}>
          <p>100% of all contributions will be deposited into your payout account, unless you have an account balance. Your payout is subject to the Payment Terms of Service. Please note that contributions may not be tax deductible or eligible for tax credits</p>
        </Grid>


      </Grid>
    </>
  )
}
