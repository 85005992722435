import { axiosPrivate } from "../../api/axios";
import { API_URL } from "../../config";

export const propertyPreview = async (data) => {
  try {
    const response = await axiosPrivate.post(
      API_URL + "property-price/create-preview",
      data
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log("Bad Response of booking update request  : ", response);
      return response;
    }
  }
};
