import { Grid, Switch, Typography } from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import ErrorMessage from "../../../components/errorMessage";
import Dropdown from "../../../components/form/dropdown";
import Header from "../../../components/header";
import PropertyCard2 from "../../../components/mainComponents/propertyCard2";
import SkeletonLoader from "../../../components/skeletonLoader";
import {
  getAreaApi,
  getSpaceTypeApi,
} from "../../../services/properties/getAddPropertyDropdownValues";
import { getPropertyApi } from "../../../services/properties/getProperty";
import SliderField from "../../../components/form/Slider";
import { AutoCompletePlacesAdvanced } from "../../../components/shared/AutCompletePlacesAdvanced";
import Buttons from "../../../components/form/button";
import Map from "../../../components/googleMap";
import { filtersValidationSchema } from "./propertyListingFiltersValidationSchema";
import { useFormik } from "formik";
import {
  addToWishlistApi,
  removeFromWishlistApi,
} from "../../../services/properties/add&RemoveWishlist";
import { useRef } from "react";
import { Toast } from "primereact/toast";
import { getAmenitiesGroupApi } from "../../../services/properties/getAmmenities";
import CheckBoxLabel from "../../../components/form/checkbox";
import Input from "../../../components/form/input";
import { useJsApiLoader } from "@react-google-maps/api";
import DatePickerExplore from "./explorePageDatePicker";
import { useNavigate } from "react-router-dom";
import Pagination from "../../../components/Pagination/Pagination";
import { getTypeApi } from "./../../../services/properties/getAddPropertyDropdownValues";
import Select from "react-select";
import moment from "moment";

const allImages = [
  "https://staywobucket.s3.us-west-1.amazonaws.com/website/icons/house_type/apartment.svg",
  "https://staywobucket.s3.us-west-1.amazonaws.com/website/icons/house_type/house.svg",
  "https://staywobucket.s3.us-west-1.amazonaws.com/website/icons/house_type/secondary_unit.svg",
  "https://staywobucket.s3.us-west-1.amazonaws.com/website/icons/house_type/unique_space.svg",
  "https://staywobucket.s3.us-west-1.amazonaws.com/website/icons/house_type/bed_and_breakfast.svg",
  "https://staywobucket.s3.us-west-1.amazonaws.com/website/icons/house_type/boutique_hotel.svg",
  "https://staywobucket.s3.us-west-1.amazonaws.com/website/icons/house_type/beach.svg",
  "https://staywobucket.s3.us-west-1.amazonaws.com/website/icons/house_type/river.svg",
  "https://staywobucket.s3.us-west-1.amazonaws.com/website/icons/house_type/lake.svg",
];

const dropdownOptions = [
  { label: 24, value: 24 },
  { label: 48, value: 48 },
  { label: 72, value: 72 },
];

const PropertyListingPage = () => {
  const navigate = useNavigate();
  //Query Params

  const search = window.location.search;
  const adults = new URLSearchParams(search).get("noOfAdults");
  const children = new URLSearchParams(search).get("noOfChildren");
  const infants = new URLSearchParams(search).get("noOfInfants");
  const pets = new URLSearchParams(search).get("noOfPets");
  const city = new URLSearchParams(search).get("city");
  const country = new URLSearchParams(search).get("country");
  const fRelief = new URLSearchParams(search).get("floodRelief");
  const lat = new URLSearchParams(search).get("lat");
  const lng = new URLSearchParams(search).get("lng");
  const startDate = new URLSearchParams(search).get("startDate");
  const endDate = new URLSearchParams(search).get("endDate");
  const address = new URLSearchParams(search).get("address");

  //States
  const [propertiesData, setPropertiesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [mapValues, setMapValues] = useState([]);
  const [spaceTypeValues, setSpaceTypeValues] = useState([]);
  const [userLng, setUserLg] = useState(0.0);
  const [userLt, setUserLt] = useState(0.0);
  const [filterButtonLoading, setFilterButtonLoading] = useState(false);
  const [areaValues, setAreaValues] = useState([]);
  const [amenitiesValue, setAmenitiesValue] = useState([]);
  const [amenitiesIds, setAmenitiesIds] = useState([]);
  const [advancedSearch, setAdvancedSearch] = useState(false);
  const [priceRange, setPriceRange] = useState([0, 0]);
  const [instantBooking, setInstantBooking] = useState("");
  const [floodRelief, setFloodRelief] = useState(fRelief ? fRelief : "");
  const [amenitiesToggle, setAmenitiesToggle] = useState(false);
  const [activeProperty, setActiveProperty] = useState({});
  const [spaceTypeVal, setSpaceTypeVal] = useState({
    label: "Select Space Type",
    value: [],
  });
  const [areaVal, setAreaVal] = useState({
    label: "Select Area",
    value: [],
  });
  const [activeUrl, setActiveUrl] = useState("");
  const [diamondHost, setDiamondHost] = useState(false);
  const [mapCenter, setMapCenter] = useState({
    lat: 0,
    lng: 0,
  });
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(24);
  const [totalRecords, setTotalRecords] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [zoom, setZoom] = useState(0);
  const [allSpaces, setAllSpaces] = useState([]);
  const [spaceUrl, setSpaceUrl] = useState("");
  const [activeItem, setActiveItem] = useState([]);
  const [activeSwitch, setActiveSwitch] = useState([]);

  //Toast Ref
  const toast = useRef(null);

  useEffect(() => {
    getTypeApi()
      .then((res) => {
        let tempArr = [];
        res.data.data.map((item, index) => {
          tempArr.push({ ...item, image: allImages[index] });
        });
        setAllSpaces(tempArr);
      })
      .catch((err) => { });
  }, []);

  const userData = JSON.parse(localStorage.getItem("userData"));
  //get Properties
  const getProperties = async (url) => {
    try {
      const response = await getPropertyApi(url, userData?.id);
      setTotalRecords(response?.data?.total);
      console.log({ response });
      if (response.status === 200) {
        let properties = [];
        properties = response?.data?.data.map((property) => ({
          lat: +parseFloat(property?.lat),
          lng: +parseFloat(property?.lng),
          address: property?.address,
          city: property?.city,
          country: property?.country,
          desc: property?.description,
          propertyCapacity: property?.property_capacity,
          propertyPrice: property?.price,
          propertyImage: property?.thumbnailUrl,
          propertyTitle: property?.title,
          propertyId: property?.id,
          propertyPricePolicy: property?.property_price_policy,
          perNightPrice: property?.perNightPrice,
          floodRelief: property?.floodRelief,
        }));
        setPropertiesData(response.data?.data);
        setMapValues(properties);
        setIsLoading(false);
        setFilterButtonLoading(false);
        setPropertiesData(response.data?.data ? response.data?.data : []);
      }
    } catch (err) {
      setFilterButtonLoading(false);
      console.log("err", err);
      setIsLoading(false);
    }
  };

  const onCustomPageChange = (event) => {
    let url = `?page=${event.page}&limit=${event.rows}&status=LISTED&`;
    setFirst(event.first);
    setRows(event.rows);
    setActivePage(event.page);
    getProperties(url + activeUrl);
  };

  //getting space type dropdown value from api
  const getSpaceType = async () => {
    const response = await getSpaceTypeApi();
    if (response.status === 200) {
      let tempArr = [];
      response.data.data.map((item) => {
        tempArr.push({
          label: item.title,
          value: item.id,
        });
      });
      setSpaceTypeValues(tempArr);
    }
  };

  //Get User's Location coords
  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };
  function success(pos) {
    var crd = pos.coords;

    setUserLg(crd.longitude);
    setUserLt(crd.latitude);
  }

  function errors(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          if (result.state === "granted") {
            //If granted then you can directly call your function here
            navigator.geolocation.getCurrentPosition(success);
          } else if (result.state === "prompt") {
            navigator.geolocation.getCurrentPosition(success, errors, options);
          } else if (result.state === "denied") {
            //If denied then you have to show instructions to enable location
          }
          result.onchange = function () { };
        });
    } else {
      alert("Sorry Not available!");
    }
  }, []);

  //Func to check if value is defined
  const isDefined = (key) => {
    if (key !== undefined && key !== "" && key !== null) return true;
    else return false;
  };
  //For filters formik and get properties
  const formik = useFormik({
    initialValues: {
      spaceId: "",
      city: city ? city : "",
      country: "",
      spaceTypeId: "",
      areaId: "",
      noOfAdults: adults ? adults : "",
      noOfChildren: children ? children : "",
      noOfPets: pets ? pets : "",
      noOfInfants: infants ? infants : "",
      lat: lat ? lat : +parseFloat(0.0),
      lng: lng ? lng : +parseFloat(0.0),
      startDate: startDate ? startDate : "",
      endDate: endDate ? endDate : "",
    },
    validationSchema: () => {
      return filtersValidationSchema;
    },
    onSubmit: async (values) => {
      if (isDefined(values?.startDate)) {
        if (!isDefined(values?.endDate)) {
          toast.current.show({
            severity: "info",
            summary: "Invalid Date range selection!",
            detail:
              "Please select both start date and end date in order to proceed.",
            life: 10000,
          });
        } else {
          setIsLoading(true);
          setFilterButtonLoading(true);
          const data = {
            spaceId: values?.spaceId.toString(),
            spaceTypeId: spaceTypeVal?.value
              ? [...spaceTypeVal?.value.map((v) => parseInt(v.value))]
              : [],
            areaId: areaVal?.value
              ? [...areaVal?.value.map((v) => parseInt(v.value))]
              : [],
            city: values.city.toString(),
            country: values.country.toString(),
            noOfAdults: values.noOfAdults.toString(),
            noOfPets: values.noOfPets.toString(),
            noOfInfants: values.noOfInfants.toString(),
            noOfChildren: values.noOfChildren.toString(),
            floodRelief: fRelief ? fRelief : floodRelief,
            amenitiesIds: amenitiesIds,
            instantBooking: instantBooking,
            isDiamondHost: diamondHost,
            priceRange:
              priceRange[0] === 0 && priceRange[1] === 0 ? [] : priceRange,
            startDate: values.startDate
              ? moment(values.startDate).format("YYYY-MM-DD")
              : undefined,
            endDate: values.endDate
              ? moment(values.endDate).format("YYYY-MM-DD")
              : undefined,
            lat: values.lat,
            lng: values.lng,
          };
          try {
            let defaultUrl = `?page=${activePage}&limit=${rows}&`;
            let url = ``;
            url += isDefined(data.spaceId) ? `space=${data.spaceId}` : ``;
            url += data.instantBooking
              ? `&instantBooking=${data.instantBooking}`
              : ``;
            url += data.isDiamondHost
              ? `isDiamondHost=${data.isDiamondHost}`
              : ``;
            url += data.floodRelief ? `&floodRelief=${data.floodRelief}` : ``;
            if (isDefined(data.spaceTypeId)) {
              data.spaceTypeId.forEach(
                (item) => (url += `&spaceTypeId[]=${item}`)
              );
            }

            url +=
              url.length > 0
                ? isDefined(data.country)
                  ? `&country=${data.country}`
                  : ``
                : isDefined(data.country)
                  ? `country=${data.country}`
                  : ``;

            url +=
              url.length > 0
                ? data.lat > 0
                  ? `&lat=${data.lat}`
                  : ``
                : data.lat > 0
                  ? `lat=${data.lat}`
                  : ``;
            url +=
              url.length > 0
                ? data.lng > 0
                  ? `&lng=${data.lng}`
                  : ``
                : data.lng > 0
                  ? `lng=${data.lng}`
                  : ``;

            if (isDefined(data.areaId)) {
              data.areaId.forEach((item) => (url += `&areaId[]=${item}`));
            }
            url +=
              url.length > 0
                ? isDefined(data.city)
                  ? `&city=${data.city}`
                  : ``
                : isDefined(data.city)
                  ? `city=${data.city}`
                  : ``;
            url +=
              url.length > 0
                ? isDefined(data.noOfAdults)
                  ? `&noOfAdults=${data.noOfAdults}`
                  : ``
                : isDefined(data.noOfAdults)
                  ? `noOfAdults=${data.noOfAdults}`
                  : ``;
            url +=
              url.length > 0
                ? isDefined(data.noOfPets)
                  ? `&noOfPets=${data.noOfPets}`
                  : ``
                : isDefined(data.noOfPets)
                  ? `noOfPets=${data.noOfPets}`
                  : ``;
            url +=
              url.length > 0
                ? isDefined(data.noOfInfants)
                  ? `&noOfInfants=${data.noOfInfants}`
                  : ``
                : isDefined(data.noOfInfants)
                  ? `noOfInfants=${data.noOfInfants}`
                  : ``;
            url +=
              url.length > 0
                ? isDefined(data.noOfChildren)
                  ? `&noOfChildren=${data.noOfChildren}`
                  : ``
                : isDefined(data.noOfChildren)
                  ? `noOfChildren=${data.noOfChildren}`
                  : ``;
            if (data.lat > 0 && data.lng > 0) {
              url += `&radius=25`;
            }
            if (data?.amenitiesIds.length) {
              data.amenitiesIds.forEach(
                (item) => (url += `&amenitiesIds[]=${item}`)
              );
            }
            url +=
              url.length > 0
                ? data.priceRange.length > 0
                  ? `&minPrice=${data.priceRange[0] === 0 ? 1 : data.priceRange[0]
                  }`
                  : ``
                : data.priceRange.length > 0
                  ? `minPrice=${data.priceRange[0] === 0 ? 1 : data.priceRange[0]
                  }`
                  : ``;
            url +=
              url.length > 0
                ? data.priceRange.length > 0
                  ? `&maxPrice=${data.priceRange[1]}`
                  : ``
                : data.priceRange.length > 0
                  ? `maxPrice=${data.priceRange[1]}`
                  : ``;
            url +=
              url.length > 0
                ? isDefined(data.startDate) > 0
                  ? `&startDate=${data.startDate}`
                  : ``
                : isDefined(data.startDate) > 0
                  ? `&startDate=${data.startDate}`
                  : ``;
            url +=
              url.length > 0
                ? isDefined(data.endDate) > 0
                  ? `&endDate=${data.endDate}`
                  : ``
                : isDefined(data.endDate) > 0
                  ? `&endDate=${data.endDate}`
                  : ``;
            // url += url.length > 0 ? `&status=LISTED` : `status=LISTED`;
            setActiveUrl(url);
            let combineUrl =
              spaceUrl !== "" ? defaultUrl + spaceUrl + url : defaultUrl + url;
            getProperties(combineUrl);
            // setAdvancedSearch(!advancedSearch);
          } catch (err) {
            console.log("response of error: ", err);
            setIsLoading(false);
            setFilterButtonLoading(false);
          }
        }
      } else {
        setIsLoading(true);
        setFilterButtonLoading(true);
        const data = {
          spaceId: values?.spaceId.toString(),
          spaceTypeId: spaceTypeVal?.value
            ? [...spaceTypeVal?.value.map((v) => parseInt(v.value))]
            : [],
          areaId: areaVal?.value
            ? [...areaVal?.value.map((v) => parseInt(v.value))]
            : [],
          city: values.city.toString(),
          country: values.country.toString(),
          noOfAdults: values.noOfAdults.toString(),
          noOfPets: values.noOfPets.toString(),
          noOfInfants: values.noOfInfants.toString(),
          noOfChildren: values.noOfChildren.toString(),
          floodRelief: fRelief ? fRelief : floodRelief,
          amenitiesIds: amenitiesIds,
          instantBooking: instantBooking,
          isDiamondHost: diamondHost,
          priceRange:
            priceRange[0] === 0 && priceRange[1] === 0 ? [] : priceRange,
          startDate: values.startDate
            ? moment(values.startDate).format("YYYY-MM-DD")
            : undefined,
          endDate: values.endDate
            ? moment(values.endDate).format("YYYY-MM-DD")
            : undefined,
          lat: values.lat,
          lng: values.lng,
        };
        try {
          let defaultUrl = `?page=${activePage}&limit=${rows}&`;
          let url = ``;
          url += isDefined(data.spaceId) ? `space=${data.spaceId}` : ``;
          url += data.instantBooking
            ? `&instantBooking=${data.instantBooking}`
            : ``;
          url += data.isDiamondHost
            ? `isDiamondHost=${data.isDiamondHost}`
            : ``;
          url += data.floodRelief ? `&floodRelief=${data.floodRelief}` : ``;
          if (isDefined(data.spaceTypeId)) {
            data.spaceTypeId.forEach(
              (item) => (url += `&spaceTypeId[]=${item}`)
            );
          }

          url +=
            url.length > 0
              ? isDefined(data.country)
                ? `&country=${data.country}`
                : ``
              : isDefined(data.country)
                ? `country=${data.country}`
                : ``;

          url +=
            url.length > 0
              ? data.lat > 0
                ? `&lat=${data.lat}`
                : ``
              : data.lat > 0
                ? `lat=${data.lat}`
                : ``;
          url +=
            url.length > 0
              ? data.lng > 0
                ? `&lng=${data.lng}`
                : ``
              : data.lng > 0
                ? `lng=${data.lng}`
                : ``;

          if (isDefined(data.areaId)) {
            data.areaId.forEach((item) => (url += `&areaId[]=${item}`));
          }
          url +=
            url.length > 0
              ? isDefined(data.city)
                ? `&city=${data.city}`
                : ``
              : isDefined(data.city)
                ? `city=${data.city}`
                : ``;
          url +=
            url.length > 0
              ? isDefined(data.noOfAdults)
                ? `&noOfAdults=${data.noOfAdults}`
                : ``
              : isDefined(data.noOfAdults)
                ? `noOfAdults=${data.noOfAdults}`
                : ``;
          url +=
            url.length > 0
              ? isDefined(data.noOfPets)
                ? `&noOfPets=${data.noOfPets}`
                : ``
              : isDefined(data.noOfPets)
                ? `noOfPets=${data.noOfPets}`
                : ``;
          url +=
            url.length > 0
              ? isDefined(data.noOfInfants)
                ? `&noOfInfants=${data.noOfInfants}`
                : ``
              : isDefined(data.noOfInfants)
                ? `noOfInfants=${data.noOfInfants}`
                : ``;
          url +=
            url.length > 0
              ? isDefined(data.noOfChildren)
                ? `&noOfChildren=${data.noOfChildren}`
                : ``
              : isDefined(data.noOfChildren)
                ? `noOfChildren=${data.noOfChildren}`
                : ``;
          if (data.lat > 0 && data.lng > 0) {
            url += `&radius=25`;
          }
          if (data?.amenitiesIds.length) {
            data.amenitiesIds.forEach(
              (item) => (url += `&amenitiesIds[]=${item}`)
            );
          }
          url +=
            url.length > 0
              ? data.priceRange.length > 0
                ? `&minPrice=${data.priceRange[0] === 0 ? 1 : data.priceRange[0]
                }`
                : ``
              : data.priceRange.length > 0
                ? `minPrice=${data.priceRange[0] === 0 ? 1 : data.priceRange[0]}`
                : ``;
          url +=
            url.length > 0
              ? data.priceRange.length > 0
                ? `&maxPrice=${data.priceRange[1]}`
                : ``
              : data.priceRange.length > 0
                ? `maxPrice=${data.priceRange[1]}`
                : ``;
          url +=
            url.length > 0
              ? isDefined(data.startDate) > 0
                ? `&startDate=${data.startDate}`
                : ``
              : isDefined(data.startDate) > 0
                ? `&startDate=${data.startDate}`
                : ``;
          url +=
            url.length > 0
              ? isDefined(data.endDate) > 0
                ? `&endDate=${data.endDate}`
                : ``
              : isDefined(data.endDate) > 0
                ? `&endDate=${data.endDate}`
                : ``;
          // url += url.length > 0 ? `&status=LISTED` : `status=LISTED`;
          setActiveUrl(url);
          let combineUrl =
            spaceUrl !== "" ? defaultUrl + spaceUrl + url : defaultUrl + url;
          getProperties(combineUrl);
          // setAdvancedSearch(!advancedSearch);
          console.log("add 2");
        } catch (err) {
          console.log("response of error: ", err);
          setIsLoading(false);
          setFilterButtonLoading(false);
        }
      }
    },
  });

  // Add to wishlist
  const addToWishlist = async (id) => {
    const response = await addToWishlistApi(userData?.id, id);
    if (response.status === 200) {
      toast.current.show({
        severity: "success",
        summary: "Wishlist Updated!",
        detail: "Your wishlist is updated successfully!",
        life: 10000,
      });
    }
  };
  const removeFromWishlist = async (id) => {
    const response = await removeFromWishlistApi(userData?.id, id);
    if (response.status === 200) {
      toast.current.show({
        severity: "success",
        summary: "Wishlist Updated!",
        detail: "Your wishlist is updated successfully!",
        life: 10000,
      });
    }
  };
  //Function for handling of amenities checkbox
  const handleCheckbox = (amenityGroupIndex, amenityIndex, e) => {
    let copyAmenitiesValue = [...amenitiesValue];
    let copyAmenitiesIds = [...amenitiesIds];

    //yes checked store id in array else filter that value from array
    if (e.target.checked === true) {
      const { id } =
        copyAmenitiesValue[amenityGroupIndex].amenities[amenityIndex];
      copyAmenitiesIds.push(id);
    } else if (e.target.checked === false) {
      const { id } =
        copyAmenitiesValue[amenityGroupIndex].amenities[amenityIndex];
      copyAmenitiesIds = copyAmenitiesIds.filter(
        (copyAmenitiesId) => copyAmenitiesId !== id
      );
    }

    copyAmenitiesValue[amenityGroupIndex].amenities[amenityIndex].isChecked =
      !copyAmenitiesValue[amenityGroupIndex]?.amenities[amenityIndex]
        ?.isChecked;
    setAmenitiesValue([...copyAmenitiesValue]);
    setAmenitiesIds([...copyAmenitiesIds]);
  };

  //getting area dropdown value from api
  const getArea = async () => {
    const response = await getAreaApi();
    if (response.status === 200) {
      if (response.status === 200) {
        let tempArr = [];
        response.data.data.map((item) => {
          tempArr.push({
            label: item.title,
            value: item.id,
          });
        });
        setAreaValues(tempArr);
      }
    }
  };

  //getting amentites groups from api
  const getAmenitiesGroup = async () => {
    const response = await getAmenitiesGroupApi();
    if (response.status === 200) {
      setAmenitiesValue(response?.data?.data);
    }
  };

  //API Calls
  useEffect(() => {
    if (city || country || fRelief) {
      formik.handleSubmit();
    }
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    getSpaceType();
    getArea();
    getAmenitiesGroup();
  }, [city, country, fRelief]);

  React.useMemo(() => {
    let properties = [];
    properties = propertiesData?.map((property) => ({
      lat: +parseFloat(property?.lat),
      lng: +parseFloat(property?.lng),
      address: property?.address,
      city: property?.city,
      country: property?.country,
      desc: property?.description,
      propertyCapacity: property?.property_capacity,
      propertyPrice: property?.price,
      propertyImage: property?.thumbnailUrl,
      propertyTitle: property?.title,
      propertyId: property?.id,
      perNightPrice: property?.perNightPrice,
      floodRelief: property?.floodRelief,
      propertyPricePolicy: property?.property_price_policy,
      property_Rating: property?.property_rating
    }));

    setMapValues(properties);
  }, [propertiesData]);
  const libraries = ["places"];
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
    libraries,
  });

  useEffect(() => {
    setMapCenter({
      lat: !isNaN(userLt) ? +parseFloat(userLt) : 0,
      lng: !isNaN(userLng) ? +parseFloat(userLng) : 0,
    });
  }, [userLt, userLng]);

  useEffect(() => {
    if (activeProperty) {
      setMapCenter({
        lat: !isNaN(activeProperty?.lat)
          ? +parseFloat(activeProperty?.lat)
          : !isNaN(userLt)
            ? +parseFloat(userLt)
            : 0,
        lng: !isNaN(activeProperty?.lng)
          ? +parseFloat(activeProperty?.lng)
          : !isNaN(userLng)
            ? +parseFloat(userLng)
            : 0,
      });
    }
  }, [activeProperty, userLt, userLng]);

  const handleChangeFloodSwitch = (e) => {
    setFloodRelief(e.target.checked);
    if (e.target.checked) {
      navigate("/property-listing?floodRelief=true");
    } else {
      navigate("/property-listing");
      let defaultUrl = `?page=${activePage}&limit=${rows}&status=LISTED`;
      getProperties(defaultUrl);
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: "none !important",
      minHeight: "55px",
      border: "1px solid rgba(255, 255, 255, 0.3)",
    }),
    option: (provided, state) => ({
      ...provided,
      color: "black",
      fontSize: "16px",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      const color = "#fff";
      return { ...provided, opacity, transition, color };
    },
  };

  let setSpaceVals = (item) => {
    const indexOfItem = activeItem.indexOf(item.id);
    if (indexOfItem > -1) {
      let copyOfArr = [...activeItem];
      copyOfArr.splice(indexOfItem, 1);
      setActiveItem(copyOfArr);
    } else {
      let copyOfArr = [...activeItem];
      copyOfArr.push(item.id);
      setActiveItem(copyOfArr);
    }
  };

  React.useEffect(() => {
    let defaultUrl = `?page=${activePage}&limit=${rows}&status=LISTED&`;
    let url = "";
    activeItem.forEach((item) => (url += `spaceId[]=${parseInt(item)}&`));
    setSpaceUrl(url);
    if (spaceUrl !== "") {
      getProperties(defaultUrl + spaceUrl + activeUrl);
    } else {
      getProperties(defaultUrl + url + activeUrl);
    }
  }, [activePage, activeItem, activeUrl, rows, spaceUrl]);

  const getPropertiesWithCoords = async (e) => {
    let defaultUrl = `?page=${activePage}&limit=${rows}&status=LISTED&`;
    let url = `lat=${e?.center?.lat()}&lng=${e?.center?.lng()}&radius=25&`;
    getProperties(defaultUrl + url);
    navigate(
      `/property-listing?lat=${e?.center?.lat()}&lng=${e?.center?.lng()}&radius=25`
    );
  };
  const getPropertiesBySearch = async (city, country, lat, lng) => {
    let defaultUrl = `?page=${activePage}&limit=${rows}&status=LISTED&radius=25&`;
    let url = `city=${city}&country=${country}&lat=${lat}&lng=${lng}&`;
    navigate("/property-listing");
    getProperties(defaultUrl + url);
  };

  const handleSwitch = (item) => {
    let existItems = [...activeSwitch, item];
    if (activeSwitch.includes(item)) {
      existItems = existItems.filter((value) => value !== item);
    }
    setActiveSwitch(existItems);
    if (existItems.length > 0) {
      setAdvancedSearch(true);
      return;
    }
    setAdvancedSearch(false);
  };

  console.log(address, "address");
  return (
    <div className="main">
      <Toast ref={toast} position="bottom-right" />
      <Header />
      <div id="wrapper">
        {/* content */}
        <div className="content">
          {/* categoties-column */}
          <div className="categoties-column">
            <div className="categoties-column_container cat-list">
              <ul>
                {allSpaces.length > 0 &&
                  allSpaces.map((item) => {
                    return (
                      <li style={{ cursor: "pointer" }}>
                        <a
                          className={
                            activeItem.includes(item.id) ? "act-category" : ""
                          }
                          onClick={() => setSpaceVals(item)}
                        >
                          <img
                            src={item.image}
                            style={{ width: "30px", height: "30px" }}
                            alt="img"
                          />
                          {/* <i className="fal fa-city" /> */}
                          <span>{item.title}</span>
                        </a>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
          {/* categoties-column end */}
          {/* Map */}
          <div className="map-container column-map">
            {/* Map Container */}
            {isLoaded && (
              <Map
                mapValues={mapValues}
                userLong={userLng}
                userLat={userLt}
                mapCenter={mapCenter}
                activeProperty={activeProperty}
                zoom={zoom}
                getPropertiesWithCoords={getPropertiesWithCoords}
              />
            )}
          </div>
          {/* Map end */}
          {/* top-search-content */}
          <div
            className="top-search-content"
            style={{ marginLeft: "-50px", height: "100px" }}
          >
            <div className="custom-form fl-wrap">
              <div className="row">
                {/* listsearch-input-item */}
                <div className="col-sm-2" style={{ marginTop: "10px" }}>
                  {isLoaded && (
                    <AutoCompletePlacesAdvanced
                      label="Search Property...(city, country, address)"
                      id={"title"}
                      name={"title"}
                      formik={formik}
                      setMapCenter={setMapCenter}
                      setZoom={setZoom}
                      address={address}
                      getPropertiesBySearch={getPropertiesBySearch}
                      mapCenter={mapCenter}
                    />
                  )}
                </div>
                {/* listsearch-input-item */}
                {/* listsearch-input-item */}
                <div className="col-sm-2">
                  <div
                    className="listsearch-input-item"
                    style={{
                      marginTop: "23px",
                    }}
                  >
                    <Switch
                      sx={{ fontSize: "18px" }}
                      value={instantBooking}
                      checked={instantBooking}
                      onChange={(e) => {
                        handleSwitch("instantBooking");
                        setInstantBooking(e.target.checked);
                        formik.handleSubmit();
                      }}
                    />
                    <span style={{ color: "#fff", fontSize: "14px" }}>
                      Instant Booking
                    </span>
                  </div>
                </div>
                <div className="col-sm-2">
                  <div
                    className="listsearch-input-item"
                    style={{
                      marginTop: "23px",
                    }}
                  >
                    <Switch
                      sx={{ fontSize: "18px" }}
                      value={diamondHost}
                      checked={diamondHost}
                      onChange={(e) => {
                        handleSwitch("diamondHost");
                        setDiamondHost(e.target.checked);
                        formik.handleSubmit();
                      }}
                    />
                    <span style={{ color: "#fff", fontSize: "14px" }}>
                      Diamond Host
                    </span>
                  </div>
                </div>
                {/* listsearch-input-item */}
                {/* listsearch-input-item */}
                <div className="col-sm-2">
                  <div
                    className="listsearch-input-item"
                    style={{
                      marginTop: "23px",
                    }}
                  >
                    <Switch
                      sx={{ fontSize: "18px" }}
                      value={floodRelief}
                      checked={floodRelief}
                      onChange={handleChangeFloodSwitch}
                    />
                    <span style={{ color: "#fff", fontSize: "14px" }}>
                      Disaster Management
                    </span>
                  </div>
                </div>
                {/* listsearch-input-item */}
                {/* listsearch-input-item */}
                {/* Space type */}

                {/* listsearch-input-item */}
                {/* listsearch-input-item */}
                <div className="col-sm-4">
                  <div className="listsearch-input-item">
                    <div
                      // className="price-rage-item fl-wrap"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <SliderField
                        priceRange={priceRange}
                        setPriceRange={setPriceRange}
                      />
                      <Buttons
                        title={"Apply Filters"}
                        isLoading={filterButtonLoading}
                        onSubmit={formik.handleSubmit}
                        style={{ width: "50%", marginLeft: "30px" }}
                      />
                    </div>
                  </div>
                </div>

                {/* listsearch-input-item */}
              </div>
            </div>

            {/* more-search-opt-wrap */}

            {/* more-search-opt-wrap end */}
          </div>
          {/* top-search-content end */}
          {/* col-list-wrap */}
          <div className="col-list-wrap gray-bg">
            <div className="col-list-wrap_opt fl-wrap">
              <div className="show-hidden-filter col-list-wrap_opt_btn color-bg">
                Show Filters
              </div>
              <div className="show-hidden-map not-vis_lap col-list-wrap_opt_btn color-bg">
                Show Map
              </div>
            </div>

            {/* list-main-wrap-header*/}
            <div className="list-main-wrap-header fl-wrap fixed-listing-header">
              <div className="container">
                {/* list-main-wrap-title*/}
                <div
                  className="list-main-wrap-title"
                  style={{ padding: "15px" }}
                >
                  <h2>
                    Results For : <span>All Properties </span>
                    <strong>{totalRecords ? totalRecords : 0}</strong>
                  </h2>
                </div>
                <div
                  className="more-opt_btn mor-opt-btn_act"
                  style={{ padding: "15px" }}
                  onClick={() => setAdvancedSearch(!advancedSearch)}
                >
                  Advanced search
                  <span>
                    <i className="fas fa-caret-down" />
                  </span>
                </div>
                {advancedSearch && (
                  <div
                    className="more-search-opt-wrap more-hidden_wrap"
                    style={{
                      background: "#17212f",
                      border: "2px solid rgba(255, 255, 255, 0.1)",
                      marginTop: "-40px",
                      display: "block !important",
                    }}
                  >
                    <div
                      className="msotw_title fl-wrap"
                      style={{ fontSize: "18px", color: "#fff" }}
                    >
                      Advanced Search
                    </div>
                    <div
                      className="close_msotw"
                      onClick={() => setAdvancedSearch(!advancedSearch)}
                    >
                      <i className="fal fa-times" />
                    </div>
                    <div className="clearfix" />
                    <div className="row" syle={{ display: "flex" }}>
                      {/* listsearch-input-item */}

                      <div className="col-sm-12">
                        <div className="listsearch-input-item">
                          <div className="col-sm-12">
                            <div
                              className="listsearch-input-item"
                              style={{ marginTop: "10px" }}
                            >
                              <Grid item xs={12}>
                                <label>Select Date Range</label>
                                <DatePickerExplore
                                  handlers
                                  startDate={formik.values.startDate}
                                  endDate={formik.values.endDate}
                                  handleDateStart={(value) =>
                                    formik.setFieldValue("startDate", value)
                                  }
                                  handleDateEnd={(value) =>
                                    formik.setFieldValue("endDate", value)
                                  }
                                />
                              </Grid>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row" syle={{ display: "flex" }}>
                      {/* listsearch-input-item */}

                      <div className="col-sm-6">
                        <div className="listsearch-input-item">
                          <div className="col-sm-12">
                            <div
                              className="listsearch-input-item"
                              style={{ marginTop: "10px" }}
                            >
                              <Grid item xs={11}>
                                <label>Select Space Type</label>
                                <Select
                                  className="override-select-edit"
                                  placeholder="Select Space Type"
                                  options={spaceTypeValues}
                                  // value={spaceTypeVal}
                                  onChange={(option) =>
                                    setSpaceTypeVal({
                                      value: [...option],
                                    })
                                  }
                                  styles={customStyles}
                                  isMulti={true}
                                />
                              </Grid>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="listsearch-input-item">
                          <div className="col-sm-12">
                            <div
                              className="listsearch-input-item"
                              style={{ marginTop: "10px" }}
                            >
                              <Grid item xs={11}>
                                <label>Select Area</label>
                                <Select
                                  className="override-select-edit"
                                  placeholder="Select Area"
                                  options={areaValues}
                                  // value={areaVal}
                                  onChange={(option) =>
                                    setAreaVal({
                                      value: [...option],
                                    })
                                  }
                                  styles={customStyles}
                                  isMulti={true}
                                />
                              </Grid>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="listsearch-input-item">
                          <div className="col-sm-12">
                            <div
                              className="listsearch-input-item"
                              style={{ marginTop: "10px" }}
                            >
                              <Grid item xs={5.5}>
                                <label>Select Number Of Adults</label>
                                <Input
                                  type={"number"}
                                  label={"Adults"}
                                  id={"noOfAdults"}
                                  name={"noOfAdults"}
                                  value={formik?.values.noOfAdults}
                                  onChange={formik?.handleChange}
                                  defaultValue={formik?.values.noOfAdults}
                                  error={
                                    formik?.touched.noOfAdults &&
                                    Boolean(formik?.errors.noOfAdults)
                                  }
                                  helperText={
                                    formik?.touched.noOfAdults &&
                                    formik?.errors.noOfAdults
                                  }
                                  min={0}
                                  step={0}
                                ></Input>
                              </Grid>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="listsearch-input-item">
                          <div className="col-sm-12">
                            <div
                              className="listsearch-input-item"
                              style={{ marginTop: "10px" }}
                            >
                              <Grid item xs={5.5}>
                                <label>Select Number Of Children</label>
                                <Input
                                  type={"number"}
                                  label={"Children"}
                                  id={"noOfChildren"}
                                  name={"noOfChildren"}
                                  onChange={formik?.handleChange}
                                  value={formik?.values.noOfChildren}
                                  defaultValue={formik?.values.noOfChildren}
                                  error={
                                    formik?.touched.noOfChildren &&
                                    Boolean(formik?.errors.noOfChildren)
                                  }
                                  helperText={
                                    formik?.touched.noOfChildren &&
                                    formik?.errors.noOfChildren
                                  }
                                  min={0}
                                  step={0}
                                ></Input>
                              </Grid>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="listsearch-input-item">
                          <div className="col-sm-12">
                            <div
                              className="listsearch-input-item"
                              style={{ marginTop: "10px" }}
                            >
                              <Grid item xs={5.5}>
                                <label>Select Number Of Infants</label>
                                <Input
                                  type={"number"}
                                  label={"Infants"}
                                  id={"noOfInfants"}
                                  name={"noOfInfants"}
                                  onChange={formik?.handleChange}
                                  value={formik?.values.noOfInfants}
                                  defaultValue={formik?.values.noOfInfants}
                                  error={
                                    formik?.touched.noOfInfants &&
                                    Boolean(formik?.errors.noOfInfants)
                                  }
                                  helperText={
                                    formik?.touched.noOfInfants &&
                                    formik?.errors.noOfInfants
                                  }
                                  min={0}
                                  step={0}
                                ></Input>
                              </Grid>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="listsearch-input-item">
                          <div className="col-sm-12">
                            <div
                              className="listsearch-input-item"
                              style={{ marginTop: "10px" }}
                            >
                              <Grid item xs={5.5}>
                                <label>Select Number Of Pets</label>
                                <Input
                                  type={"number"}
                                  label={"Pets"}
                                  id={"noOfPets"}
                                  name={"noOfPets"}
                                  onChange={formik?.handleChange}
                                  value={formik?.values.noOfPets}
                                  defaultValue={formik?.values.noOfPets}
                                  error={
                                    formik?.touched.noOfPets &&
                                    Boolean(formik?.errors.noOfPets)
                                  }
                                  helperText={
                                    formik?.touched.noOfPets &&
                                    formik?.errors.noOfPets
                                  }
                                  min={0}
                                  step={0}
                                ></Input>
                              </Grid>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* listsearch-input-item end*/}
                    </div>

                    <div className="clearfix" />
                    {/* listsearch-input-item*/}
                    <div className="listsearch-input-item clact">
                      <label style={{ color: "#0087e1", fontSize: "24px" }}>
                        Amenities
                      </label>
                      {amenitiesValue
                        ?.slice(0, amenitiesToggle ? amenitiesValue.length : 2)
                        .map((amenity, i) => {
                          return (
                            <Grid
                              item
                              xs={11}
                              style={{
                                border: "1px solid #0087e1",
                                padding: "10px",
                                borderRadius: "10px",
                                marginTop: "10px",
                              }}
                            >
                              <Typography
                                variant="subtitle1"
                                color={"#0087e1"}
                                textAlign={"left"}
                              >
                                {amenity?.title}
                              </Typography>
                              <Grid
                                container
                                direction="row"
                                rowSpacing={{ xs: 2, sm: 2, md: 2 }}
                                columnSpacing={{ xs: 2, sm: 2, md: 2 }}
                                justifyContent={"flex-start"}
                              >
                                {amenity?.amenities?.map(
                                  (amentityCheckbox, j) => {
                                    return (
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <CheckBoxLabel
                                          value={amentityCheckbox.title}
                                          checked={amentityCheckbox.isChecked}
                                          onChange={(e) =>
                                            handleCheckbox(i, j, e)
                                          }
                                        />
                                        <div style={{ marginTop: "3px" }}>
                                          <img
                                            class="amenity-icons"
                                            src={amentityCheckbox?.icon}
                                            style={{
                                              width: "30px",
                                              height: "30px",
                                            }}
                                          />
                                          <span
                                            style={{
                                              color: "white",
                                              fontSize: "15px",
                                              marginLeft: "10px",
                                            }}
                                          >
                                            {amentityCheckbox?.title}
                                          </span>
                                        </div>
                                      </Grid>
                                    );
                                  }
                                )}
                              </Grid>
                            </Grid>
                          );
                        })}

                      {amenitiesToggle ? (
                        <Buttons
                          onSubmit={() => setAmenitiesToggle(false)}
                          title="Show Less"
                          style={{ width: "40%", marginTop: "10px" }}
                          isLoading={false}
                        />
                      ) : (
                        <Buttons
                          onSubmit={() => setAmenitiesToggle(true)}
                          title="Show More"
                          isLoading={false}
                          style={{ width: "40%", marginTop: "10px" }}
                        />
                      )}
                    </div>
                    {/* listsearch-input-item end*/}
                    <div
                      className="msotw_footer"
                      style={{ border: "2px solid rgba(255, 255, 255, 0.1)" }}
                    >
                      <Buttons
                        title={"Apply Filters"}
                        isLoading={filterButtonLoading}
                        onSubmit={() => {
                          setAdvancedSearch(!advancedSearch);
                          formik.handleSubmit();
                        }}
                        style={{ width: "40%" }}
                      />
                    </div>
                  </div>
                )}
                {/* list-main-wrap-title end*/}
              </div>
            </div>
            {/* list-main-wrap-header end*/}
            {/* listing-item-wrap*/}
            <div className="listing-item-container fl-wrap">
              {isLoading === true ? (
                <>
                  <Loader />
                </>
              ) : (
                <>
                  {propertiesData.length === 0 ? (
                    <ErrorMessage />
                  ) : (
                    <Grid container columnSpacing={{ md: 2 }}>
                      {propertiesData?.map((property) => {
                        console.log('property:', property)
                        return (
                          <Grid Grid item xs={12} sm={6} md={4} >
                            <PropertyCard2
                              addToWishlist={addToWishlist}
                              property={property}
                              onHover={() => setActiveProperty(property)}
                              removeFromWishlist={removeFromWishlist}
                            // onLeave={() => setActiveProperty({})}
                            />
                          </Grid>
                        )
                      })}
                    </Grid>
                  )}
                  <Pagination
                    totalRecords={totalRecords}
                    customFirst={first}
                    customRows={rows}
                    onPageChange={onCustomPageChange}
                    type="propertyListing"
                    dropdownOptions={dropdownOptions}
                  />
                </>
              )}
            </div>
            {/* listing-item-wrap end*/}
          </div>
          {/* col-list-wrap end */}
        </div>
        {/* content end */}
      </div>
    </div>
  );
};

const Loader = ({ mapValues }) => {
  return (
    <Grid container columnSpacing={{ md: 2 }}>
      <Grid item xs={12} sm={4} md={4}>
        <SkeletonLoader height={450} />
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <SkeletonLoader height={450} />
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <SkeletonLoader height={450} />
      </Grid>
    </Grid>
  );
};

export default PropertyListingPage;
