import { API_URL } from '../../config';
import axios from 'axios';

export const getPropertyRestrictedDaysApi = async (propertyId) => {
  try {
    const response = await axios.get(
      API_URL +
        `property-restricted-days/byPropertyId/{propertyId}?propertyId=${propertyId}`
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of property : ', response);
      return response;
    }
  }
};
