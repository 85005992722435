import React, { useRef } from 'react';
import { Rating, Tooltip, Typography } from '@mui/material';
import { Toast } from 'primereact/toast';
import { Link } from 'react-router-dom';
import moment from 'moment';

const TabCards = ({ bookingData }) => {
  const toast = useRef(null);

  return (
    <div>
      {bookingData.map((booking) => {
        return (
          <div className='dashboard-listings-item fl-wrap'>
            <Toast ref={toast} position='bottom-right' />
            <div className='dashboard-listings-item_img'>
              <div className='bg-wrap1'>
                <img className='bg' src={booking?.property.thumbnailUrl} />
              </div>
            </div>
            <div className='dashboard-listings-item_content'>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <h4>
                    <Tooltip title={booking?.property.title}>
                      <Link to={`/property-detail/${booking?.id}`}>
                        {booking?.property.title.length > 40 ? (
                          <a
                            onMouseOver={(e) =>
                              (e.target.style.textOverflow = 'ellipsis')
                            }
                          >
                            {booking?.property.title.substring(0, 40)}...
                          </a>
                        ) : (
                          <a
                            onMouseOver={(e) =>
                              (e.target.style.textOverflow = 'ellipsis')
                            }
                          >
                            {booking?.property.title
                              ? booking?.property.title
                              : 'My Property'}
                          </a>
                        )}
                      </Link>
                    </Tooltip>
                  </h4>
                  <div
                    className='geodir-category-location'
                    style={{ marginBottom: '2%' }}
                  >
                    <a style={{ textDecoration: 'none' }}>
                      <i className='fas fa-map-marker-alt'></i>{' '}
                      <span
                        style={{
                          fontSize: '12px',
                          color: '#bbbbbb',
                        }}
                      >
                        {booking?.property.address.slice(0, 50) + '...'}
                        <div></div>
                      </span>
                    </a>
                  </div>
                  <div style={{ textAlign: 'start' }}>
                    <div>
                      <a style={{ textAlign: 'left', color: 'green' }}>
                        {booking?.bookingStatus == 'pending'
                          ? 'Awaiting host approval'
                          : booking?.bookingStatus == 'accepted'
                          ? 'Pending guest payment'
                          : booking?.bookingStatus == 'confirmed'
                          ? 'Booking confirmed'
                          : ''}
                      </a>
                    </div>
                    <div>
                      <a style={{ textAlign: 'start', color: 'white' }}>
                        Property ID # {booking?.property.code}
                      </a>
                    </div>

                    <div style={{ marginBottom: '10px' }}>
                      <a
                        style={{
                          textAlign: 'start',
                          color: 'white',
                        }}
                      >
                        Booking ID # {booking?.code}
                      </a>
                    </div>
                    <div>
                      <Typography
                        sx={{
                          fontFamily: 'Jost',
                          fontSize: '16px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Rating
                          name='half-rating-read'
                          value={
                            booking?.property?.average_rating
                              ? booking?.property?.average_rating?.averageRating
                              : 0
                          }
                          precision={0.5}
                          readOnly
                          sx={{ marginRight: '4px' }}
                        />
                        (
                        {booking?.property?.average_rating
                          ? parseInt(
                              booking?.property?.average_rating?.averageRating
                            )?.toFixed(1)
                          : 0}
                        ) (
                        {booking?.property?.average_rating
                          ? `${booking?.property?.average_rating?.reviewCount} Reviews`
                          : 0}
                        )
                      </Typography>
                    </div>

                    <div>
                      <a style={{ textAlign: 'start', color: 'white' }}>
                        {booking?.startDate && booking?.endDate && (
                          <>
                            From{' '}
                            {moment(booking?.startDate).format('DD MMM YYYY')}{' '}
                            to {moment(booking?.endDate).format('DD MMM YYYY')}
                          </>
                        )}
                      </a>
                    </div>
                    <div>
                      <a
                        style={{
                          textAlign: 'start',
                          color: 'white',
                          fontSize: '18px',
                        }}
                      >
                        {process.env.REACT_APP_CURRENCY}
                        {booking?.grossAmount?.toLocaleString('en-US')}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TabCards;
