import { API_URL } from '../../config';
import axios from 'axios';
//https://api.staywo.com/rating/by-guest?page=1&property_id=4
export const getPropertyAllReviewsApi = async (id, page) => {
  try {
    const response = await axios.get(
      API_URL + `rating/get-property-rating?page=${page}&property_id=${id}`
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of guest review : ', response);
      return response;
    }
  }
};
