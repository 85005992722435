import React from "react";
import moment from "moment";

const ChatItem = ({ item, userData, selectedUser }) => {
  // TIME FORMATTER
  const dateFormatter = (date) => {
    return moment(date).format("LT"); // 10:04 am
  };

  return item ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        margin: "15px 0",
        flexDirection: item.senderId == userData.id ? "row-reverse" : "row",
      }}
    >
      <div style={{ width: "20%" }}>
        {/* Refactor image conditions */}
        <img
          src={
            item.senderId === selectedUser?.guest?.id
              ? selectedUser?.guest?.profilePicture ||
                require("../../../assets/images/avatar/5.jpg")
              : selectedUser?.propertyOwner?.profilePicture ||
                require("../../../assets/images/avatar/5.jpg")
          }
          style={{
            borderRadius: "50%",
            width: "50px",
            height: "50px",
          }}
          alt=''
        />
        <div>
          {/* Refactor chat username conditions */}
          <span className='chat-message-user-name cmun_sm'>
            {item.senderId === selectedUser?.guest?.id
              ? selectedUser.guest.firstName
              : selectedUser.propertyOwner.firstName}
          </span>
        </div>
      </div>
      <div
        style={{
          background: "#292e3a",
          borderRadius: "5px",
          padding: "5px 10px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {item?.chat_attachment?.length > 0 && (
          <div>
            {item?.chat_attachment?.map((attachment) => {
              return (
                <img
                  src={attachment?.locationUrl}
                  alt={attachment?.key}
                  width={200}
                  height={200}
                />
              );
            })}
          </div>
        )}
        <p style={{ paddingBottom: 0 }}>{item.content}</p>
        <p style={{ color: "gray", fontSize: 12, textAlign: "right" }}>
          {dateFormatter(item.createdAt)}
        </p>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default ChatItem;
