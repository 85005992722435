import { axiosPrivate } from '../../api/axios';
import { API_URL } from '../../config';

const updateUserAddressApi = async (id, data) => {
  try {
    let res = await axiosPrivate.put(API_URL + `user-address/${id}`, data);
    return res;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('bad response of user address update: ', response);
      return response;
    }
  }
};
export default updateUserAddressApi;
