import React from "react";

import { HeroHeader } from "../../../components/ContactPageComponents/HeroHeader";
import { FAQContent } from "../../../components/FAQPageComponents/FAQContent";

import Footer from "../../../components/footer";
import Header from "../../../components/header";
import NewsLetter from "../../../components/newsLetter";

export default function FAQPage() {
  return (
    <div className="main">
      <Header />
      <div className="wrapper">
        <div className="content">
          <HeroHeader
            bg="5"
            title="Frequently Asked Questions"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut nec tincidunt arcu, sit amet fermentum sem."
          />
          <FAQContent />
          <NewsLetter />
          <Footer />
        </div>
      </div>
    </div>
  );
}
