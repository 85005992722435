import React from 'react';
import { Grid, Typography } from '@mui/material';
import { getPropertyRatesAndValsApi } from '../../services/properties/getPropertyRatesAndVals';
const Views = ({ propertyDetail }) => {
  const [stats, setStats] = React.useState({});
  React.useEffect(() => {
    const getStats = async () => {
      const res = await getPropertyRatesAndValsApi(propertyDetail?.id);
      setStats(res.data);
    };
    if (propertyDetail?.id) {
      getStats();
    }
  }, [propertyDetail?.id]);
  return (
    <>
      <Grid
        container
        sx={{ mb: 4 }}
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <Grid item xs={12} md={4} lg={4} sm={12}>
          <Typography sx={{ color: '#fff', fontSize: '20px' }}>
            {stats?.property_view?.length || 0}
          </Typography>
          <Typography sx={{ fontSize: '18px' }}>New Views</Typography>
        </Grid>
        <Grid item xs={12} md={4} lg={4} sm={12}>
          <Typography sx={{ color: '#fff', fontSize: '20px' }}>
            {stats?.booking?.length || 0}
          </Typography>
          <Typography sx={{ fontSize: '18px' }}>New Bookings</Typography>
        </Grid>
        <Grid item xs={12} md={4} lg={4} sm={12}>
          <Typography sx={{ color: '#fff', fontSize: '20px' }}>
            {parseInt(stats?.bookingRate).toFixed(2) || 0}%
          </Typography>
          <Typography sx={{ fontSize: '18px' }}>Booking Rate</Typography>
        </Grid>
      </Grid>
    </>
  );
};
export default Views;
