import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Input from '../../components/form/input';
import CssBaseline from '@mui/material/CssBaseline';
import Buttons from '../../components/form/button';
import BACKGROUND from '../../assets/images/forgot.png';
import { loginWithPhoneNumberApi } from '../../services/Authentication/Login';
import { ForgetPasswordValidationSchema } from './ForgetPasswordValidationSchema';
import LOGO from '../../assets/images/logo.png';

function Copyright(props) {
  return (
    <Typography
      variant='body2'
      color='text.secondary'
      align='center'
      {...props}
    >
      {'© Staywo Inc.USA 2022 . All rights reserved.'}
      {/* {new Date().getFullYear()} */}
      {'.'}
    </Typography>
  );
}

const ForgetPasswordPage = () => {
  const history = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: () => {
      return ForgetPasswordValidationSchema;
    },
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        // const response = await loginWithPhoneNumberApi(values)
        // if (response?.status === 201) {
        //   setIsLoading(false)
        //   enqueueSnackbar('Password successfully changed.', {
        //     variant: 'success',
        //     anchorOrigin: {
        //       vertical: 'bottom',
        //       horizontal: 'right',
        //     },
        //   })
        // }
        history('/login');
      } catch (err) {
        if (err?.data?.code === 401)
          enqueueSnackbar('Provided password is invalid.', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        setIsLoading(false);
      }
    },
  });

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <Grid container component='main' sx={{ height: '100vh' }}>
        <CssBaseline />

        <Grid
          style={{ background: '#1b182b' }}
          item
          xs={12}
          sm={8}
          md={4}
          component={Paper}
          elevation={6}
          square
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'left',
            }}
          >
            <Grid item xs={12}>
              <a href='/'>
                <img
                  src={`${LOGO}?w=242&h=100&fit=crop&auto=format`}
                  width='242px'
                  height='100px'
                />
              </a>
            </Grid>
            <Typography textAlign='center' component='h1' variant='h5'>
              Signup
            </Typography>
            <Box component='form' noValidate sx={{ mt: 5 }}>
              <Input
                sx={{ mb: 3, fontSize: '14px', marginBottom: 8 }}
                id={'password'}
                name={'password'}
                label={'Password'}
                type='password'
                onChange={formik.handleChange}
                value={formik.values.password}
                defaultValue={formik.values.password}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
              <Input
                sx={{ mb: 3, fontSize: '14px', marginBottom: 8 }}
                id={'confirmPassword'}
                name={'confirmPassword'}
                label={'Confirm Password'}
                type='password'
                onChange={formik.handleChange}
                value={formik.values.confirmPassword}
                defaultValue={formik.values.confirmPassword}
                error={
                  formik.touched.confirmPassword &&
                  Boolean(formik.errors.confirmPassword)
                }
                helperText={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                }
              />

              <Buttons
                title='Change Password'
                loadingTitle={'Validating Password'}
                isLoading={isLoading}
                style={{ height: 55, mb: 5, mt: '24px', fontSize: '14px' }}
                onSubmit={formik.handleSubmit}
              />
              <Grid container justifyContent={'center'} alignItems={'center'}>
                <Grid item>
                  <Link
                    onClick={() => history('/login')}
                    variant='body2'
                    style={{ fontSize: '14px', cursor: 'pointer' }}
                  >
                    <a>{'Need to use other login options?'}</a>
                  </Link>
                </Grid>
              </Grid>
              <Copyright
                sx={{
                  mt: 2,
                  fontSize: '14px',
                  position: 'relative',
                  display: 'block',
                  marginTop: '25px',
                }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={false}
          sm={4}
          md={8}
          sx={{
            backgroundImage: `url(${BACKGROUND})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light'
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
      </Grid>
    </>
  );
};

export default ForgetPasswordPage;
