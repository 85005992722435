import Header from '../../../components/header';
import Sidebar from '../../../components/dasboard/sidebar';

import React, { useState } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';

const CoHostPermissionsPage = () => {
  const [checked, setChecked] = useState(false);
  const [permissions, setPermissions] = useState([]);

  const onPermissionsChange = (e) => {
    let selectedPermissions = [...permissions];
    if (e.checked) selectedPermissions.push(e.value);
    else selectedPermissions.splice(selectedPermissions.indexOf(e.value), 1);
    setPermissions(selectedPermissions);
  };
  const handeleSubmit = (e) =>{
    e.preventDefault();
  }
  const dummyValues = [
    {
      sr_no: '1',
      property_code: '34212',
      booking_code: '21212',
      transaction_id: '7543212132323a33$rer',
      property_title: 'New House',
      payment_date: '2022-07-12',
      amount: 12000,
      host_name: 'Hassan',
    },
    {
      sr_no: '2',
      property_code: '34212',
      booking_code: '21212',
      transaction_id: '7543212132323a33$rer',
      property_title: 'New House',
      payment_date: '2022-07-12',
      amount: 12000,
      host_name: 'Hassan',
    },
    {
      sr_no: '3',
      property_code: '34212',
      booking_code: '21212',
      transaction_id: '7543212132323a33$rer',
      property_title: 'New House',
      payment_date: '2022-07-12',
      amount: 12000,
      host_name: 'Hassan',
    },
    {
      sr_no: '4',
      property_code: '34212',
      booking_code: '21212',
      transaction_id: '7543212132323a33$rer',
      property_title: 'New House',
      payment_date: '2022-07-12',
      amount: 12000,
      host_name: 'Hassan',
    },
  ];
  return (
    <div>
      <Header />
      <Sidebar page={'Co Host Permissions'} />

    
<div id="wrapper">
        <div className="dashboard-menu-overlay" />
        <Sidebar page={'Co Host Permissions'} />
        <div className="dashboard-content">
          <div className="dasboard-wrapper fl-wrap no-pag">
            <div>
              <div className="clearfix" />
              <div className="row">
                <div className="col-md-12">
                  <div
                    style=
                    {{ margin: "10px 0px",display:"flex",
                    justifyContent:"space-between",alignItems:"center" }}
                  >
                    <Typography
                        sx={{
                        color: 'white',
                        fontFamily: 'Jost',
                        fontSize: '20px',
                        fontWeight: '700',
                        paddingBottom: '0px',
                        }}
                          >
                                CO HOST PERMISSIONS
                      </Typography>
                  </div>
                  <div
                    style={{ margin: "30px 0px" }}
                  >
        <div
          style={{
            width: '100%',
            border: '1px solid gray',
            borderRadius: '4px',
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              background: '#0d3c61',
            }}
          >
            <h1
              style={{
                fontSize: '18px',
                color: 'white',
                fontFamily: 'Jost',
                fontWeight: '400',
                padding: '8px 0px',
              }}
            >
              Co Host Name
            </h1>
            <h1
              style={{
                fontSize: '18px',
                color: 'white',
                fontFamily: 'Jost',
                fontWeight: '400',
              }}
            >
              Co Host Mail
            </h1>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              padding: '14px 0px',
            }}
          >
            <h1
              style={{
                fontSize: '18px',
                color: 'white',
                fontFamily: 'Jost',
                fontWeight: '400',
              }}
            >
              Hassan
            </h1>
            <h1
              style={{
                fontSize: '18px',
                color: 'white',
                fontFamily: 'Jost',
                fontWeight: '400',
              }}
            >
              hassan@test.com
            </h1>
          </div>
        </div>
                  </div>
                  <div
                    style={{ margin: "30px 0px" }}
                  >
                     <div
          style={{
            width: '100%',
            border: '1px solid gray',
            borderRadius: '4px',
            marginTop: '20px',
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              background: '#0d3c61',
            }}
          >
            <h1
              style={{
                fontSize: '18px',
                color: 'white',
                fontFamily: 'Jost',
                fontWeight: '400',
                padding: '8px 0px',
              }}
            >
              Component Name
            </h1>
            <h1
              style={{
                fontSize: '18px',
                color: 'white',
                fontFamily: 'Jost',
                fontWeight: '400',
              }}
            >
              Permission
            </h1>
          </div>
          <form onSubmit={handeleSubmit}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              padding: '14px 0px',
            }}
          >
            <h1
              style={{
                fontSize: '18px',
                color: 'white',
                fontFamily: 'Jost',
                fontWeight: '400',
              }}
            >
              Listing Details
            </h1>
            <h1
              style={{
                fontSize: '18px',
                color: 'white',
                fontFamily: 'Jost',
                fontWeight: '400',
              }}
            >
              <Checkbox
                inputId="listing_details"
                name="permissions"
                value="listing_details"
                onChange={onPermissionsChange}
                checked={permissions.indexOf('listing_details') !== -1}
              />
            </h1>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              padding: '14px 0px',
            }}
          >
            <h1
              style={{
                fontSize: '18px',
                color: 'white',
                fontFamily: 'Jost',
                fontWeight: '400',
              }}
            >
              Booking Details
            </h1>
            <h1
              style={{
                fontSize: '18px',
                color: 'white',
                fontFamily: 'Jost',
                fontWeight: '400',
                marginLeft: '-11px',
              }}
            >
              <Checkbox
                inputId="booking_detils"
                name="permissions"
                value="booking_details"
                onChange={onPermissionsChange}
                checked={permissions.indexOf('booking_details') !== -1}
              />
            </h1>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              padding: '14px 0px',
            }}
          >
            <h1
              style={{
                fontSize: '18px',
                color: 'white',
                fontFamily: 'Jost',
                fontWeight: '400',
              }}
            >
              Pricing
            </h1>
            <h1
              style={{
                fontSize: '18px',
                color: 'white',
                fontFamily: 'Jost',
                fontWeight: '400',
                marginLeft: '56px',
              }}
            >
              <Checkbox
                inputId="pricing"
                name="permissions"
                value="pricing"
                onChange={onPermissionsChange}
                checked={permissions.indexOf('pricing') !== -1}
              />
            </h1>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              padding: '14px 0px',
            }}
          >
            <h1
              style={{
                fontSize: '18px',
                color: 'white',
                fontFamily: 'Jost',
                fontWeight: '400',
              }}
            >
              Availability
            </h1>
            <h1
              style={{
                fontSize: '18px',
                color: 'white',
                fontFamily: 'Jost',
                fontWeight: '400',
                marginLeft: '32px',
              }}
            >
              <Checkbox
                inputId="availability"
                name="permissions"
                value="availability"
                onChange={onPermissionsChange}
                checked={permissions.indexOf('availability') !== -1}
              />
            </h1>
          </div>
          <button
            className="btn color-bg float-btn"
            style={{
              cursor: 'pointer',
              marginBottom: '10px',
              border: 'none',
              outline: 'none',
            }}
          >
            Save
          </button>
          </form>
        </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoHostPermissionsPage;
