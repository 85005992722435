import { API_URL } from '../../config';
import axios from 'axios';

export const confirmPaymentAndAddBookingApi = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'booking/confirm-payment-and-add-booking',
    data: { ...data },
  };
  try {
    const response = await axios(config);
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log(
        'Error Response of confirm-payment-and-add-booking : ',
        response
      );
      return response;
    }
  }
};
