import { FormGroup, FormControlLabel, Checkbox, Box } from '@mui/material';
import React from 'react';

const CheckBoxLabel = ({ label, value, checked, onChange, name, id }) => {
  return (
    <Box
      justify="flex-start"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: '1rem',
      }}
    >
      <FormControlLabel
        label={label}
        sx={{ color: '#fff' }}
        control={
          <Checkbox
            id={id}
            name={name}
            value={value}
            checked={checked}
            onChange={onChange}
          />
        }
      />
    </Box>
  );
};

export default CheckBoxLabel;
