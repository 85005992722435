import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Buttons from '../../../components/form/button';
import LOGO from '../../../assets/images/logo.png';
import { useNavigate } from 'react-router-dom';

const Error404 = () => {
  let navigate = useNavigate();
  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
        }}
      >
        <Container>
          <Grid container>
            <Grid xs={6} md={12}>
              <Box>
                <img src={LOGO} alt='' width='500' />
              </Box>
              <Typography
                variant='h1'
                sx={{ color: '#fff', fontFamily: 'Jost' }}
              >
                404
              </Typography>
              <Typography variant='h6' sx={{ color: '#fff' }}>
                The page you’re looking for doesn’t exist.
              </Typography>
              <Buttons
                variant='contained'
                title={'BACK HOME'}
                isLoading={false}
                onSubmit={() => navigate('/')}
                style={{ margin: '10px', width: '40%' }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
};

export default Error404;
