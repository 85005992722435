import { API_URL } from '../../config';
import axios from 'axios';

export const withDrawOfferApi = async (bookingExtraPaymentId, userId) => {
  try {
    const response = await axios.delete(
      API_URL + 'booking-extra-payment/withdraw',
      { bookingExtraPaymentId, userId }
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of withdraw  : ', response);
      return response;
    }
  }
};
