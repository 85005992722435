import React, { useState, useImperativeHandle } from "react";
import { Dialog } from "primereact/dialog";

const PropertyAmentiesDialog = React.forwardRef(({}, ref) => {
  const [openAmenitiesDialog, setOpenAmenitiesDialog] = useState(false);
  const [dialogData, setDialogData] = useState(null);

  useImperativeHandle(ref, () => ({
    isVisible(params) {
      setDialogData(params);
      setOpenAmenitiesDialog(true);
    },
  }));

  const hideDialog = () => {
    setOpenAmenitiesDialog(false);
  };

  return (
    <Dialog
      visible={openAmenitiesDialog}
      style={{ width: "50%" }}
      header="All Amenities"
      modal
      className="p-fluid"
      onHide={hideDialog}
    >
      <div className="listing-features">
        <ul>
          {dialogData?.map((amenity) => {
            return (
              <li>
                <a>
                  {/* <i className={amenity?.amenity?.icon}></i> */}
                  <img
                    class="amenity-icons"
                    src={amenity?.amenity?.icon}
                  />{" "}
                  {amenity?.amenity?.title}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </Dialog>
  );
});

export default PropertyAmentiesDialog;
