import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Sidebar from '../../../components/dasboard/sidebar';
import Header from '../../../components/header';
import { ModifyBookingCard } from './ModifyBookingCard';
import { getBookingByIdApi } from '../../../services/booking/getBooking';
import moment from 'moment';
import GroupIcon from '@mui/icons-material/Group';
import getUserCardApi from '../../../services/User/getUserCard';
import { useFormik } from 'formik';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PetsIcon from '@mui/icons-material/Pets';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import PaymentsIcon from '@mui/icons-material/Payments';
import NightlightRoundIcon from '@mui/icons-material/NightlightRound';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';
import { calculateBookingPriceApi } from '../../../services/booking/calculateBookingPrice';
import { getGSTValueApi } from '../../../services/system/getGSTValue';
import { getServiceValueApi } from '../../../services/system/getServiceFeeValue';
import Select from 'react-select';
import PaymentIcon from '@mui/icons-material/Payment';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { getUserWalletApi } from '../../../services/wallet/getUserWallet';
import { FaCcMastercard, FaCcVisa } from 'react-icons/fa';
import { Dropdown } from 'primereact/dropdown';
import { options } from './../../../constants/countries';
import * as yup from 'yup';

const ModifyBookingPage = () => {
  const { bookingId } = useParams();
  const userData = JSON.parse(localStorage.getItem('userData')) || null;
  const [useCreditCard, setUseCreditCard] = React.useState(false);
  const [modifiedValues, setModifiedValues] = React.useState({});
  const [userCard, setUserCard] = React.useState(null);
  const [booking, setBooking] = React.useState({});
  const [previousValues, setPreviousValues] = React.useState({});
  const [modifyValues, setModifyValues] = React.useState({});
  const [walletLessAmountError, setWalletLessAmountError] = useState(false);
  const [useWallet, setUseWallet] = useState(true);
  const [wallet, setWallet] = useState({});
  const [card, setCard] = React.useState({});
  let navigate = useNavigate();
  const [userCards, setUserCards] = useState([]);

  //get booking
  React.useEffect(() => {
    const getBooking = async () => {
      const res = await getBookingByIdApi(bookingId);
      setBooking(res);
    };
    getBooking();
  }, [bookingId]);

  const [gst, setGST] = React.useState(0);
  const [serviceFee, setServiceFee] = React.useState(0);

  const toast = useRef(null);
  const userHost = JSON.parse(localStorage.getItem('userHost'));

  React.useEffect(() => {
    const getUserCard = async () => {
      const res = await getUserCardApi(userData?.id);
      setUserCard(res?.data[0]);
    };
    getUserCard();
  }, [userData?.id]);
  let noActiveCard = Object.keys(card).length === 0;

  const formik = useFormik({
    initialValues: {
      number: '',
      name: '',
      exp_month: '',
      exp_year: '',
      cvc: '',
      card_id: card?.id,
      address1: '',
      address2: '',
      city: '',
      zipcode: '',
      state: '',
    },
    enableReinitialize: true,
    ...(noActiveCard &&
      useCreditCard && {
        validationSchema: () => {
          return yup.object({
            name: yup.string().required('Please enter your name!'),
            number: yup
              .string()
              .min(16, 'Card number must be only 16 characters')
              .max(16, 'Card number must be only 16 characters')
              .required('Please enter your card number!'),
            exp_month: yup
              .string()
              .required('Please enter your card expiry month!'),
            exp_year: yup
              .string()
              .required('Please enter your card expiry year!'),
            cvc: yup
              .string()
              .min(3, 'CVC must be at least 3 characters!')
              .max(4, 'CVC can be only 4 characters!')
              .required('Please enter your card CVC!'),
            address1: yup.string().required('Street Address is required!'),
            address2: yup.string().required('Apt or suite number is required!'),
            city: yup.string().required('City is required!'),
            zipcode: yup.string().required('Zip Code is required!'),
            state: yup.string().required('State is required!'),
          });
        },
      }),
    onSubmit: async (values) => {},
  });
  let guests = booking;

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  let totalGuestsPrevious =
    parseInt(guests?.totalAdults) + parseInt(guests?.totalChildren);

  //Previous
  React.useEffect(() => {
    const getBookingCalculatedPrcie = async () => {
      const response = await calculateBookingPriceApi({
        propertyId: booking?.property?.id,
        startDate: moment(booking?.startDate).format('YYYY-MM-DD'),
        endDate: moment(booking?.endDate).format('YYYY-MM-DD'),
        noOfAdults: parseInt(booking?.totalAdults),
        noOfChildren: parseInt(booking?.totalChildren),
        noOfInfants: parseInt(booking?.totalInfants),
        noOfPets: parseInt(booking?.totalPets),
        validate: false,
      });
      setPreviousValues(response.data.data);
    };
    if (!(Object.keys(booking) === 0)) {
      getBookingCalculatedPrcie();
    }
  }, [booking]);
  //Modified

  React.useEffect(() => {
    const getBookingCalculatedPrcie = async () => {
      const response = await calculateBookingPriceApi({
        propertyId: booking?.property?.id,
        startDate: moment(modifiedValues?.startDate).format('YYYY-MM-DD'),
        endDate: moment(modifiedValues?.endDate).format('YYYY-MM-DD'),
        noOfAdults: parseInt(modifiedValues?.noOfAdults),
        noOfChildren: parseInt(modifiedValues?.noOfChildren),
        noOfInfants: parseInt(modifiedValues?.noOfInfants),
        noOfPets: parseInt(modifiedValues?.noOfPets),
        validate: true,
        isModified: true,
      });

      setModifyValues(response.data.data);
    };
    if (!(modifiedValues?.endDate === '')) {
      getBookingCalculatedPrcie();
    }
  }, [modifiedValues, booking?.property?.id]);

  //Get and set GST & serviceFee Value from server
  React.useEffect(() => {
    const getGSTValue = async () => {
      let gstVal = await getGSTValueApi();
      setGST(gstVal);
    };
    const getServiceFeeValue = async () => {
      let serviceFeeVal = await getServiceValueApi();
      setServiceFee(serviceFeeVal);
    };
    getGSTValue();
    getServiceFeeValue();
  }, []);

  //Get User Wallet Api
  React.useEffect(() => {
    const getWallet = async () => {
      const res = await getUserWalletApi(userData?.id);
      if (res.status === 404 || res.status === 401 || res.status === 403) {
        setWallet([]);
      } else {
        setWallet(res);
      }
    };
    getWallet();
  }, [userData?.id]);

  //Set use wallet to false if wallet doesnt exist or wallet amount is 0
  React.useEffect(() => {
    if (parseInt(wallet?.amount ? wallet?.amount : 0) <= 0) {
      setUseWallet(false);
    } else if (parseInt(wallet?.amount ? wallet?.amount : 0) > 0) {
      setUseWallet(true);
    }
  }, [wallet?.amount]);

  //Country options dropdown templates
  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className='country-item country-item-value'>
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className='country-item'>
        <div>{option.name}</div>
      </div>
    );
  };

  //Check If User wallet amount is less than total amount and show prompt
  React.useEffect(() => {
    if (
      parseInt(wallet?.amount ? wallet?.amount : 0) <
      previousValues?.grossAmount
    ) {
      setWalletLessAmountError(
        'Your wallet has insufficient balance for this payment. Use a card instead or select a card to pay the rest of the amount.'
      );
    } else {
      setWalletLessAmountError('');
    }
  }, [wallet?.amount, previousValues?.grossAmount]);

  //Set wallet usage func
  const setUseWalletFunc = () => {
    if (wallet && parseInt(wallet?.amount) > 0) {
      setUseWallet(!useWallet);
    } else {
      setWalletLessAmountError(
        'You dont have enough balance in wallet! Please consider topping up the wallet!'
      );
    }
  };

  //set credit card add usage
  const setUseCreditCardFunc = () => {
    setUseCreditCard(!useCreditCard);
    setCard({});
  };

  //Get User Cards
  React.useEffect(() => {
    const getUserCards = async () => {
      const res = await getUserCardApi(userData?.id);
      let temp = [];
      res?.data.map((item) => {
        return temp.push({
          value: item,
          label: item.last4digit,
          brand: item.brand,
        });
      });
      setUserCards(temp);
    };
    getUserCards();
  }, [userData?.id]);

  //Custom styles for dropdown
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: 'black',
      fontSize: '16px',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      const color = '#fff';
      return { ...provided, opacity, transition, color };
    },
  };
  return (
    <section>
      <Toast ref={toast} position='bottom-right' />

      <Header />
      <div id='wrapper'>
        <div className='dashboard-menu-overlay' />
        <Sidebar page={'Additional Payments'} />
        <div className='dashboard-content'>
          <Box
            sx={{
              width: '100%',
              borderBottom: '1px solid #FFFFFF80',
            }}
          >
            <Typography
              sx={{
                width: '100%',
                fontSize: '25px',
                fontFamily: 'Jost',
                color: '#fff',
                padding: '10px 0px',
              }}
            >
              Modify Booking
              <div style={{ fontSize: '16px', color: '#878c9f' }}>
                Modify your booking, extend the time you want to spend!
              </div>
            </Typography>
          </Box>
          <Box>
            <Box sx={{ display: 'flex', margin: '15px 0px' }}>
              <Box>
                <img
                  src={booking?.property?.thumbnailUrl}
                  alt='property_image'
                  width={150}
                  height={150}
                />
              </Box>
              <Box sx={{ marginLeft: '10px' }}>
                <Typography
                  sx={{ color: '#fff', fontFamily: 'Jost', fontSize: '22px' }}
                >
                  {booking?.property?.title &&
                  booking?.property?.title?.length > 30
                    ? booking?.property?.title?.slice(0, 30) + '...'
                    : booking?.property?.title?.slice(0, 30)}
                </Typography>
                <Typography sx={{ fontFamily: 'Jost', fontSize: '14px' }}>
                  {booking?.property?.city}, {booking?.property?.country}
                </Typography>
                <Typography sx={{ fontFamily: 'Jost', fontSize: '14px' }}>
                  Property ID: {booking?.property?.code}
                </Typography>
                <Typography
                  sx={{ fontFamily: 'Jost', fontSize: '16px', color: '#fff' }}
                >
                  Booking ID: {booking?.code}
                </Typography>
                <Box sx={{ display: 'flex' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Jost',
                      fontSize: '16px',
                      marginRight: '14px',
                    }}
                  >
                    Booking Start Date:{' '}
                    <span style={{ color: '#fff' }}>
                      {' '}
                      {moment(booking?.startDate).format('DD MMM YYYY')}
                    </span>
                  </Typography>
                  <Typography sx={{ fontFamily: 'Jost', fontSize: '16px' }}>
                    Booking End Date:{' '}
                    <span style={{ color: '#fff' }}>
                      {moment(booking?.endDate).format('DD MMM YYYY')}
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              border: '1px solid gray',
              padding: '12px',
              borderRadius: '8px',
            }}
          >
            <Box sx={{ display: 'flex', margin: '15px 0px' }}>
              <Box>
                <Typography
                  sx={{
                    color: '#fff',
                    fontSize: '18px',
                    fontFamily: 'Jost',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <GroupIcon sx={{ fontSize: '22px', marginRight: '5px' }} />
                  Guests
                </Typography>
                <Typography>
                  {totalGuestsPrevious > 0 ? (
                    <>
                      <span style={{ color: '#fff' }}>
                        {totalGuestsPrevious}
                      </span>{' '}
                      guests ({parseInt(guests?.totalAdults)} adults,{' '}
                      {parseInt(guests?.totalPets)} pets,{' '}
                      {parseInt(guests?.totalChildren)} children,{' '}
                      {parseInt(guests?.totalInfants)} infants)
                    </>
                  ) : (
                    <>
                      <span style={{ color: '#fff' }}>
                        {totalGuestsPrevious}
                      </span>{' '}
                      guest
                    </>
                  )}
                </Typography>
              </Box>
            </Box>
          </Box>

          {!userHost && (
            <Box
              sx={{
                border: '1px solid gray',
                padding: '12px',
                borderRadius: '8px',
                marginTop: '8px',
              }}
            >
              <Box sx={{ display: 'flex', margin: '15px 0px' }}>
                <Box>
                  <>
                    <form>
                      <Toast ref={toast} position='bottom-right' />
                      <Typography
                        sx={{
                          color: '#fff',
                          fontSize: '22px',
                          fontFamily: 'Jost',
                          display: 'flex',
                          alignItems: 'center',
                          paddingTop: '15px',
                          paddingBottom: '15px',
                        }}
                      >
                        <PaymentIcon
                          sx={{ fontSize: '22px', marginRight: '5px' }}
                        />{' '}
                        Pay With
                      </Typography>
                      <Typography
                        sx={{
                          color: '#fff',
                          fontSize: '22px',
                          fontFamily: 'Jost',
                          display: 'flex',
                          alignItems: 'center',
                          // margin: '12px 0px',
                          paddingTop: '12px',
                          borderTop: '1px solid rgba(255, 255, 255, 0.1)',
                        }}
                      >
                        Your Wallet
                      </Typography>
                      <Box>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: '25px',
                              color: '#fff',
                            }}
                          >
                            <PaymentsIcon
                              style={{
                                marginRight: '16px',
                                fontSize: '18px',
                                color: '#fff',
                              }}
                            />
                            <span
                              style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                                color: 'rgba(255, 255, 255, 0.7)',
                              }}
                            >
                              {process.env.REACT_APP_CURRENCY}{wallet?.amount
                                ? wallet?.amount?.toLocaleString('en-US')
                                : 0}{' '}
                              
                              <AddCircleIcon
                                style={{
                                  marginRight: '16px',
                                  fontSize: '25px',
                                  color: 'rgba(255, 255, 255, 0.7)',
                                  cursor: 'pointer',
                                  marginLeft: '10px',
                                }}
                                onClick={() => navigate('/wallet/top-up')}
                              />
                            </span>
                          </Typography>
                        </Box>
                        <FormGroup>
                          <FormControlLabel
                            sx={{ color: '#fff' }}
                            control={
                              <Checkbox
                                checked={useWallet ? true : false}
                                value={useWallet}
                                onChange={setUseWalletFunc}
                              />
                            }
                            label='Use your wallet.'
                          />
                        </FormGroup>
                        {walletLessAmountError && (
                          <Typography
                            sx={{
                              color: 'yellow',
                              fontSize: '16px',
                              fontFamily: 'Jost',
                              display: 'flex',
                              alignItems: 'center',
                              marginTop: '12px',
                              fontWeight: '400',
                            }}
                          >
                            <span
                              style={{
                                color: '#fff',
                                fontWeight: 'bold',
                                marginRight: '4px',
                              }}
                            >
                              Info:
                            </span>{' '}
                            {walletLessAmountError}
                          </Typography>
                        )}
                      </Box>

                      <Typography
                        sx={{
                          color: '#fff',
                          fontSize: '22px',
                          fontFamily: 'Jost',
                          display: 'flex',
                          alignItems: 'center',
                          paddingTop: '12px',
                          borderTop: '1px solid rgba(255, 255, 255, 0.1)',
                          marginTop: '12px',
                        }}
                      >
                        Your Card
                      </Typography>
                      {userCards?.length > 0 ? (
                        <div style={{ width: '50%' }}>
                          <Select
                            className='override-select-edit'
                            placeholder='Select Card'
                            options={userCards}
                            value={
                              card.value == undefined
                                ? { label: 'Select Card', value: '' }
                                : card
                            }
                            onChange={(option) => setCard(option)}
                            styles={customStyles}
                            formatOptionLabel={(item) => (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                {item?.brand === 'Visa' && (
                                  <FaCcVisa
                                    style={{
                                      marginRight: '8px',
                                      fontSize: '25px',
                                    }}
                                  />
                                )}
                                {item?.brand === 'MasterCard' && (
                                  <FaCcMastercard
                                    style={{
                                      marginRight: '8px',
                                      fontSize: '25px',
                                    }}
                                  />
                                )}

                                {item?.brand}
                                <span
                                  style={{ marginLeft: '10px' }}
                                >{`${item?.label}`}</span>
                              </div>
                            )}
                          />
                        </div>
                      ) : (
                        <Typography
                          sx={{
                            color: 'white',
                            fontSize: '16px',
                            fontFamily: 'Jost',
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '12px',
                            fontWeight: '400',
                          }}
                        >
                          You dont have any cards! Add one by clicking below.
                        </Typography>
                      )}
                      <Grid
                        container
                        spacing={{ xs: 2, md: 3 }}
                        columns={{ xs: 4, sm: 8, md: 12 }}
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          <Typography
                            onClick={setUseCreditCardFunc}
                            sx={{
                              cursor: 'pointer',
                              color: '#fff',
                              textDecoration: 'underline',
                            }}
                          >
                            {useCreditCard
                              ? 'Use Saved Credit Card'
                              : 'Add Credit Card'}
                          </Typography>
                        </Grid>

                        {useCreditCard && (
                          <>
                            <Grid item xs={12}>
                              <Typography
                                sx={{
                                  color: '#fff',
                                  fontSize: '22px',
                                  fontFamily: 'Jost',
                                  display: 'flex',
                                  alignItems: 'center',
                                  paddingTop: '12px',
                                  borderTop:
                                    '1px solid rgba(255, 255, 255, 0.1)',
                                  marginTop: '12px',
                                }}
                              >
                                Card Details
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                              <Typography sx={{ textAlign: 'left' }}>
                                Card Number
                              </Typography>
                              <TextField
                                sx={{ width: '100%' }}
                                id='number'
                                name='number'
                                number
                                required
                                placeholder='4242424242424242'
                                value={formik.values.number}
                                onChange={formik.handleChange}
                              />
                              {formik.touched.number &&
                                Boolean(formik.errors.number) && (
                                  <small
                                    id='username2-help'
                                    className='p-error'
                                    style={{
                                      display: 'block',
                                      color: 'red',
                                      textAlign: 'left',
                                    }}
                                  >
                                    {formik.errors.number}
                                  </small>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                              <Typography sx={{ textAlign: 'left' }}>
                                Card Holder Name
                              </Typography>
                              <TextField
                                sx={{ width: '100%' }}
                                id='name'
                                name='name'
                                required
                                placeholder='Card Holder Name'
                                value={formik.values.name}
                                onChange={formik.handleChange}
                              />
                              {formik.touched.name &&
                                Boolean(formik.errors.name) && (
                                  <small
                                    id='username2-help'
                                    className='p-error'
                                    style={{
                                      display: 'block',
                                      color: 'red',
                                      textAlign: 'left',
                                    }}
                                  >
                                    {formik.errors.name}
                                  </small>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                              <Typography sx={{ textAlign: 'left' }}>
                                Expiration Month
                              </Typography>
                              <TextField
                                sx={{ width: '100%' }}
                                id='exp_month'
                                name='exp_month'
                                required
                                number
                                placeholder='03'
                                value={formik.values.exp_month}
                                onChange={formik.handleChange}
                              />
                              {formik.touched.exp_month &&
                                Boolean(formik.errors.exp_month) && (
                                  <small
                                    id='username2-help'
                                    className='p-error'
                                    style={{
                                      display: 'block',
                                      color: 'red',
                                      textAlign: 'left',
                                    }}
                                  >
                                    {formik.errors.exp_month}
                                  </small>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                              <Typography sx={{ textAlign: 'left' }}>
                                Expiration Years
                              </Typography>
                              <TextField
                                sx={{ width: '100%' }}
                                id='exp_year'
                                name='exp_year'
                                required
                                number
                                placeholder='10'
                                value={formik.values.exp_year}
                                onChange={formik.handleChange}
                              />
                              {formik.touched.exp_year &&
                                Boolean(formik.errors.exp_year) && (
                                  <small
                                    id='username2-help'
                                    className='p-error'
                                    style={{
                                      display: 'block',
                                      color: 'red',
                                      textAlign: 'left',
                                    }}
                                  >
                                    {formik.errors.exp_year}
                                  </small>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                              <Typography sx={{ textAlign: 'left' }}>
                                CVC
                              </Typography>
                              <TextField
                                sx={{ width: '100%' }}
                                id='cvc'
                                name='cvc'
                                required
                                number
                                placeholder='10'
                                value={formik.values.cvc}
                                onChange={formik.handleChange}
                              />
                              {formik.touched.cvc &&
                                Boolean(formik.errors.cvc) && (
                                  <small
                                    id='username2-help'
                                    className='p-error'
                                    style={{
                                      display: 'block',
                                      color: 'red',
                                      textAlign: 'left',
                                    }}
                                  >
                                    {formik.errors.cvc}
                                  </small>
                                )}
                            </Grid>

                            <Grid item xs={12}>
                              <Typography
                                sx={{
                                  color: '#fff',
                                  fontSize: '22px',
                                  fontFamily: 'Jost',
                                  display: 'flex',
                                  alignItems: 'center',
                                  paddingTop: '12px',
                                  borderTop:
                                    '1px solid rgba(255, 255, 255, 0.1)',
                                  marginTop: '12px',
                                }}
                              >
                                Billing Address
                              </Typography>
                            </Grid>

                            <Grid item xs={12}>
                              <Typography sx={{ textAlign: 'left' }}>
                                Street Address
                              </Typography>
                              <TextField
                                sx={{ width: '100%' }}
                                id='address1'
                                name='address1'
                                required
                                placeholder='Street Address'
                                value={formik.values.address1}
                                onChange={formik.handleChange}
                              />
                              {formik.touched.address1 &&
                                Boolean(formik.errors.address1) && (
                                  <small
                                    id='username2-help'
                                    className='p-error'
                                    style={{
                                      display: 'block',
                                      color: 'red',
                                      textAlign: 'left',
                                    }}
                                  >
                                    {formik.errors.address1}
                                  </small>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                              <Typography sx={{ textAlign: 'left' }}>
                                Apt or suite number
                              </Typography>
                              <TextField
                                sx={{ width: '100%' }}
                                id='address2'
                                name='address2'
                                required
                                type='text'
                                placeholder='Enter Suite Number'
                                value={formik.values.address2}
                                onChange={formik.handleChange}
                              />
                              {formik.touched.address2 &&
                                Boolean(formik.errors.address2) && (
                                  <small
                                    id='username2-help'
                                    className='p-error'
                                    style={{
                                      display: 'block',
                                      color: 'red',
                                      textAlign: 'left',
                                    }}
                                  >
                                    {formik.errors.address2}
                                  </small>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                              <Typography sx={{ textAlign: 'left' }}>
                                City
                              </Typography>
                              <TextField
                                sx={{ width: '100%' }}
                                id='city'
                                name='city'
                                required
                                placeholder='City'
                                value={formik.values.city}
                                onChange={formik.handleChange}
                              />
                              {formik.touched.city &&
                                Boolean(formik.errors.city) && (
                                  <small
                                    id='username2-help'
                                    className='p-error'
                                    style={{
                                      display: 'block',
                                      color: 'red',
                                      textAlign: 'left',
                                    }}
                                  >
                                    {formik.errors.city}
                                  </small>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ textAlign: 'left' }}>
                                State
                              </Typography>
                              <TextField
                                sx={{ width: '100%' }}
                                id='state'
                                name='state'
                                required
                                placeholder='State'
                                value={formik.values.state}
                                onChange={formik.handleChange}
                              />
                              {formik.touched.state &&
                                Boolean(formik.errors.state) && (
                                  <small
                                    id='username2-help'
                                    className='p-error'
                                    style={{
                                      display: 'block',
                                      color: 'red',
                                      textAlign: 'left',
                                    }}
                                  >
                                    {formik.errors.state}
                                  </small>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ textAlign: 'left' }}>
                                Zip Code
                              </Typography>
                              <TextField
                                sx={{ width: '100%' }}
                                id='zipcode'
                                name='zipcode'
                                required
                                type='number'
                                placeholder='Zip Code'
                                value={formik.values.zipcode}
                                onChange={formik.handleChange}
                              />
                              {formik.touched.zipcode &&
                                Boolean(formik.errors.zipcode) && (
                                  <small
                                    id='username2-help'
                                    className='p-error'
                                    style={{
                                      display: 'block',
                                      color: 'red',
                                      textAlign: 'left',
                                    }}
                                  >
                                    {formik.errors.zipcode}
                                  </small>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                              <Dropdown
                                value={formik.values.country}
                                options={options}
                                onChange={formik.handleChange}
                                optionLabel='name'
                                filter
                                showClear
                                filterBy='name'
                                name='country'
                                placeholder='Country/region'
                                valueTemplate={selectedCountryTemplate}
                                itemTemplate={countryOptionTemplate}
                                style={{
                                  width: '100%',
                                  background: 'transparent',
                                  padding: '6px 5px',
                                }}
                              />
                              {formik.touched.country &&
                                Boolean(formik.errors.country) && (
                                  <small
                                    id='username2-help'
                                    className='p-error'
                                    style={{
                                      display: 'block',
                                      color: 'red',
                                      textAlign: 'left',
                                    }}
                                  >
                                    {formik.errors.country}
                                  </small>
                                )}
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </form>
                  </>
                </Box>
              </Box>
            </Box>
          )}

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                border: '1px solid gray',
                padding: '12px',
                borderRadius: '8px',
                margin: '12px 0px',
                width: '50%',
                marginRight: '8px',
              }}
            >
              <Box sx={{ marginTop: '10px' }}>
                <Typography
                  sx={{
                    color: '#fff',
                    fontFamily: 'Jost',
                    fontSize: '25px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <PaymentsIcon sx={{ fontSize: '25px', marginRight: '5px' }} />{' '}
                  Booking Details
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography
                    sx={{
                      color: '#fff',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <NightlightRoundIcon
                      sx={{ fontSize: '18px', marginRight: '5px' }}
                    />{' '}
                    Nightly Price x {previousValues?.noOfNights} (nights)
                  </Typography>
                  <Typography>
                    {process.env.REACT_APP_CURRENCY}{' '}
                    {previousValues?.totalNightsCharges?.toLocaleString(
                      'en-US'
                    )}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography
                    sx={{
                      color: '#fff',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {' '}
                    <CleaningServicesIcon
                      sx={{ fontSize: '18px', marginRight: '5px' }}
                    />{' '}
                    Cleaning Fee
                  </Typography>
                  <Typography>
                    {process.env.REACT_APP_CURRENCY}{' '}
                    {previousValues?.cleaningFee?.toLocaleString('en-US')}{' '}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                  }}
                >
                  <Typography
                    sx={{
                      color: '#fff',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <PersonAddIcon
                      sx={{ fontSize: '18px', marginRight: '5px' }}
                    />{' '}
                    Additional Guests Fee
                  </Typography>
                  <Typography>
                    <span style={{ color: '#fff' }}>
                      {previousValues?.totalExtraGuests}
                    </span>{' '}
                    x{' '}
                    <span style={{ color: '#fff' }}>
                      {process.env.REACT_APP_CURRENCY}{' '}
                      {previousValues?.extraGuestFee?.toLocaleString('en-US')}
                    </span>{' '}
                    ={' '}
                    <span style={{ color: '#fff' }}>
                      {' '}
                      {process.env.REACT_APP_CURRENCY}{' '}
                      {previousValues?.totalExtraGuestFee?.toLocaleString(
                        'en-US'
                      )}
                    </span>
                  </Typography>
                </Box>
                {booking?.property?.propertyCapacity?.noOfInfants > 0 ? (
                  <Box
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Typography
                      sx={{
                        color: '#fff',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <ChildCareIcon
                        sx={{ fontSize: '18px', marginRight: '5px' }}
                      />{' '}
                      Infant Fee
                    </Typography>
                    <Typography>No Fee</Typography>
                  </Box>
                ) : (
                  <Box
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Typography
                      sx={{
                        color: '#fff',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <ChildCareIcon
                        sx={{ fontSize: '18px', marginRight: '5px' }}
                      />{' '}
                      Infant Fee
                    </Typography>
                    <Typography>Not Allowed</Typography>
                  </Box>
                )}
                {booking?.property?.propertyCapacity?.noOfPets > 0 ? (
                  <Box
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Typography
                      sx={{
                        color: '#fff',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <PetsIcon sx={{ fontSize: '18px', marginRight: '5px' }} />{' '}
                      Pet Fee
                    </Typography>
                    <Typography>
                      {previousValues?.noOfPets} x{' '}
                      {process.env.REACT_APP_CURRENCY}{' '}
                      {previousValues?.petFee?.toLocaleString('en-US')} ={' '}
                      {process.env.REACT_APP_CURRENCY}{' '}
                      {previousValues?.totalPetFee?.toLocaleString('en-US')}
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Typography
                      sx={{
                        color: '#fff',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <PetsIcon sx={{ fontSize: '18px', marginRight: '5px' }} />{' '}
                      Pet Fee
                    </Typography>
                    <Typography>Not Allowed</Typography>
                  </Box>
                )}
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography
                    sx={{
                      color: '#fff',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <AccountBalanceIcon
                      sx={{ fontSize: '18px', marginRight: '5px' }}
                    />{' '}
                    Service Fee ({serviceFee} %)
                  </Typography>
                  <Typography>
                    {process.env.REACT_APP_CURRENCY}{' '}
                    {previousValues?.totalServiceCharges?.toLocaleString(
                      'en-US'
                    )}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography
                    sx={{
                      color: '#fff',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <AccountBalanceIcon
                      sx={{ fontSize: '18px', marginRight: '5px' }}
                    />{' '}
                    GST ({gst} %)
                  </Typography>
                  <Typography>
                    {process.env.REACT_APP_CURRENCY}{' '}
                    {previousValues?.totalGSTFee?.toLocaleString('en-US')}
                  </Typography>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography
                    sx={{
                      color: '#fff',
                      fontSize: '19px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <DoneOutlineIcon
                      sx={{ fontSize: '19px', marginRight: '5px' }}
                    />{' '}
                    Total ( {process.env.REACT_APP_CURRENCY} )
                  </Typography>
                  <Typography sx={{ fontSize: '18px', color: '#fff' }}>
                    {process.env.REACT_APP_CURRENCY}{' '}
                    {previousValues?.grossAmount?.toLocaleString('en-US')}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                border: '1px solid gray',
                padding: '14px',
                borderRadius: '8px',
                width: '50%',
                height: '360px',
              }}
            >
              <Typography
                sx={{
                  color: '#fff',
                  fontFamily: 'Jost',
                  fontSize: '25px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <PaymentsIcon sx={{ fontSize: '25px', marginRight: '5px' }} />{' '}
                Modified Price Details
              </Typography>
              {modifiedValues?.endDate ? (
                <Box>
                  <Box
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Typography
                      sx={{
                        color: '#fff',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <NightlightRoundIcon
                        sx={{ fontSize: '18px', marginRight: '5px' }}
                      />{' '}
                      Nightly Price x {modifyValues?.noOfNights} (nights)
                    </Typography>
                    <Typography>
                      {process.env.REACT_APP_CURRENCY}{' '}
                      {modifyValues?.totalNightsCharges?.toLocaleString(
                        'en-US'
                      )}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: 'row',
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#fff',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <PersonAddIcon
                        sx={{ fontSize: '18px', marginRight: '5px' }}
                      />{' '}
                      Additional Guests Fee
                    </Typography>
                    <Typography>
                      <span style={{ color: '#fff' }}>
                        {modifyValues?.totalExtraGuests}
                      </span>{' '}
                      x{' '}
                      <span style={{ color: '#fff' }}>
                        {process.env.REACT_APP_CURRENCY}{' '}
                        {modifyValues?.extraGuestFee?.toLocaleString('en-US')}
                      </span>{' '}
                      ={' '}
                      <span style={{ color: '#fff' }}>
                        {' '}
                        {process.env.REACT_APP_CURRENCY}{' '}
                        {modifyValues?.totalExtraGuestFee?.toLocaleString(
                          'en-US'
                        )}
                      </span>
                    </Typography>
                  </Box>
                  {booking?.property?.propertyCapacity?.noOfInfants > 0 ? (
                    <Box
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <Typography
                        sx={{
                          color: '#fff',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <ChildCareIcon
                          sx={{ fontSize: '18px', marginRight: '5px' }}
                        />{' '}
                        Infant Fee
                      </Typography>
                      <Typography>No Fee</Typography>
                    </Box>
                  ) : (
                    <Box
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <Typography
                        sx={{
                          color: '#fff',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <ChildCareIcon
                          sx={{ fontSize: '18px', marginRight: '5px' }}
                        />{' '}
                        Infant Fee
                      </Typography>
                      <Typography>Not Allowed</Typography>
                    </Box>
                  )}
                  {booking?.property?.propertyCapacity?.noOfPets > 0 ? (
                    <Box
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <Typography
                        sx={{
                          color: '#fff',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <PetsIcon
                          sx={{ fontSize: '18px', marginRight: '5px' }}
                        />{' '}
                        Pet Fee
                      </Typography>
                      <Typography>
                        {process.env.REACT_APP_CURRENCY}{' '}
                        {modifyValues?.totalPetFee?.toLocaleString('en-US')}
                      </Typography>
                    </Box>
                  ) : (
                    <Box
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <Typography
                        sx={{
                          color: '#fff',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <PetsIcon
                          sx={{ fontSize: '18px', marginRight: '5px' }}
                        />{' '}
                        Pet Fee
                      </Typography>
                      <Typography>Not Allowed</Typography>
                    </Box>
                  )}
                  <Box
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Typography
                      sx={{
                        color: '#fff',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <AccountBalanceIcon
                        sx={{ fontSize: '18px', marginRight: '5px' }}
                      />{' '}
                      Service Fee ({serviceFee} %)
                    </Typography>
                    <Typography>
                      {process.env.REACT_APP_CURRENCY}{' '}
                      {modifyValues?.totalServiceCharges?.toLocaleString(
                        'en-US'
                      )}
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Typography
                      sx={{
                        color: '#fff',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <AccountBalanceIcon
                        sx={{ fontSize: '18px', marginRight: '5px' }}
                      />{' '}
                      GST ({gst} %)
                    </Typography>
                    <Typography>
                      {process.env.REACT_APP_CURRENCY}{' '}
                      {modifyValues?.totalGSTFee?.toLocaleString('en-US')}
                    </Typography>
                  </Box>

                  <Box
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Typography
                      sx={{
                        color: '#fff',
                        fontSize: '19px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <DoneOutlineIcon
                        sx={{ fontSize: '19px', marginRight: '5px' }}
                      />{' '}
                      Total ( {process.env.REACT_APP_CURRENCY} )
                    </Typography>
                    <Typography sx={{ fontSize: '18px', color: '#fff' }}>
                      {process.env.REACT_APP_CURRENCY}{' '}
                      {modifyValues?.grossAmount?.toLocaleString('en-US')}
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <Typography
                  sx={{
                    margin: '10px',
                    color: '#fff',
                    fontSize: '18px',
                    textAlign: 'center',
                  }}
                >
                  Please select modified extended date for booking to show its
                  relevant calculations!
                </Typography>
              )}
            </Box>
          </Box>
          <ModifyBookingCard
            id={bookingId}
            propertyDetail={booking?.property}
            booking={booking}
            setModifiedValues={setModifiedValues}
            previousStartDate={booking?.startDate}
            previousEndDate={booking?.endDate}
            userId={userData?.id}
            useWallet={useWallet}
            useCreditCard={useCreditCard}
            noActiveCard={noActiveCard}
            card={card}
            formik={formik}
            propertyPricePolicy={booking?.propertyPricePolicy}
          />
        </div>
      </div>
    </section>
  );
};

export default ModifyBookingPage;
