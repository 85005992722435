import axios from 'axios';
import { API_URL } from '../../config';

const getFAQApi = async () => {
  try {
    const response = await axios.get(
      API_URL + 'faqcategory/all'
    );
    return response.data.data.items;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('bad response of get faq : ', response);
      return response;
    }
  }
};
export default getFAQApi;
