import { API_URL } from "../../config";
import axios from "axios";

// Facility API Declaration Below
export const getFacilityAPI = async (id) => {
  try {
    const response = await axios.get(API_URL + `property/facility/${id}`);
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log("Bad Response of facility : ", response);
      return response;
    }
  }
};

// Accessibility API Declaration below
export const getAccessibilityAPI = async (id) => {
    try {
      const response = await axios.get(API_URL + `property/accessibility/${id}`);
      return response;
    } catch (error) {
      if (error) {
        const { response } = error;
        console.log("Bad Response of accessibility : ", response);
        return response;
      }
    }
  };

// Nearby activity API Declaration below
export const getNearbyActivityAPI = async (id) => {
  try {
    const response = await axios.get(API_URL + `property/nearby-activity/${id}`);
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log("Bad Response of nearby-activity : ", response);
      return response;
    }
  }
};

// Safety consideration API Declaration below
export const getSafetyConsiderationAPI = async (id) => {
    try {
      const response = await axios.get(API_URL + `property/safety-consideration/${id}`);
      return response;
    } catch (error) {
      if (error) {
        const { response } = error;
        console.log("Bad Response of safety-consideration : ", response);
        return response;
      }
    }
};

// Security Feature API Declaration below
export const getSecurityFeatureAPI = async (id) => {
    try {
      const response = await axios.get(API_URL + `property/security-feature/${id}`);
      return response;
    } catch (error) {
      if (error) {
        const { response } = error;
        console.log("Bad Response of security-feature : ", response);
        return response;
      }
    }
};
// Safety Feature API Declaration below
export const getSafetyFeatureAPI = async (id) => {
    try {
      const response = await axios.get(API_URL + `property/safety-feature/${id}`);
      return response;
    } catch (error) {
      if (error) {
        const { response } = error;
        console.log("Bad Response of SafetyFeature : ", response);
        return response;
      }
    }
};
