import { API_URL } from '../../config';
import axios from 'axios';

export const getWishlistPropertyApi = async () => {
  try {
    const response = await axios.get(API_URL + 'wishlist/all');
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of wishlist/saved property : ', response);
      return response;
    }
  }
};
